import { render, staticRenderFns } from "./CategoryPulldown.vue?vue&type=template&id=31c19022&scoped=true&"
import script from "./CategoryPulldown.vue?vue&type=script&lang=js&"
export * from "./CategoryPulldown.vue?vue&type=script&lang=js&"
import style0 from "./CategoryPulldown.vue?vue&type=style&index=0&id=31c19022&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c19022",
  null
  
)

export default component.exports