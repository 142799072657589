<template>
  <v-dialog
    v-model="openCategory"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card>
      <v-card-text>
        <v-row align="center">
          <v-col class="text-left" cols="5" sm="4">小カテゴリ名<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="7" sm="8">
            <v-text-field
              v-model="category.category_secondary_name"
              :error-messages="secondaryNameErrors"
              maxlength="100"
              clearable
              placeholder="入力してください"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="ml-auto">
        <v-btn @click="close" outlined depressed color="light">
          キャンセル
        </v-btn>
        <v-btn depressed @click="createCategory()" color="primary">登録</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import axios from "axios";
import {
  SECONDARY_CATEGORY_API,
} from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC703 } from "../../../constants/fc";
import { debounce_leading } from "@/helpers/helpers";
export default {
  mixins: [validationMixin, backMixin],
  validations: {
    category: {
      category_secondary_name: {
        required: requiredIf((category) => {
          return category.openChildCategory;
        }),
      },
    },
  },
  props: {
    show: Boolean,
    newLargeCategoryId: {
      type: Number,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      required: true
    },
  },
  watch: {
    show: {
      async handler() {
        this.openCategory = true;
        this.isEditing = false;
        this.$v.$reset();
        this.category = { openChildCategory: true, primaryCategory: null };
      },
    },
    category: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  data() {
    return {
      category: { openChildCategory: true },
      openCategory: false,
      parentCategories: [],
      childCategories: [],
      departments: [],
      isEditing: false,
      primaryCategory: null,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("common", ["loginInfo"]),

    secondaryNameErrors() {
      const errors = [];
      if (this.$v.category.category_secondary_name.$dirty) {
        !this.$v.category.category_secondary_name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

  },
  methods: {
    compareValue() {
        return (
          !(
            this.category.category_secondary_name == undefined ||
            this.category.category_secondary_name == ""
          )
        );
    },

    createCategory : debounce_leading(async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        await this.createSubCategory();
      }
    }),

    async createSubCategory() {
      try {
        let params = {
          name: this.category.category_secondary_name,
          primary_id: this.newLargeCategoryId,
          user_id: this.userId,
          group_id: this.groupId,
          is_changeability: 1,
        };
        const { data } = await axios.post(SECONDARY_CATEGORY_API(), params);

        if(this.$root.$refs.monthlySchedulesComponent){
          this.addSmallCategory(data.result.id);
        } else if (this.$root.$refs.monthlyActualsComponent) {
          this.addSmallCategoryByActual(data.result.id);
        }

        this.category.category_secondary_id = data.result.id;
        this.$store.dispatch("save/setChangeFlag", false);
        this.$toast(
          `${this.category.category_secondary_name}を追加しました。`,
          3000
        );
        this.$emit("secondaryCategoryCreated", this.category);
        this.openCategory = false;
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(error.response.data.message);
        } else {
          apiErrorMessages(this, FC703, status);
        }
      }
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openCategory = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
    addSmallCategory(category_secondary_id) {
      const smallCategories = this.$root.$refs.monthlySchedulesComponent.childCategories;
      const items = {
        "changeFrag": null
        , "largeId": this.newLargeCategoryId
        , "text": this.category.category_secondary_name
        , "userId": this.userId
        , "value": category_secondary_id
      };

      smallCategories.push(items);
      this.$root.$refs.monthlySchedulesComponent.childCategories = smallCategories;
    },

    addSmallCategoryByActual(categorySecondaryId) {
      const smallCategories = this.$root.$refs.monthlyActualsComponent.childCategories;
      const items = {
        "changeFrag": null
        , "largeId": this.newLargeCategoryId
        , "text": this.category.category_secondary_name
        , "userId": this.userId
        , "value": categorySecondaryId
      };
      
      smallCategories.push(items);
      this.$root.$refs.monthlyActualsComponent.childCategories = smallCategories;
    }
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style>
</style>
