<template>
  <div>
    <v-list-item class="px-2 mt-4">
      <v-row>
          <v-col cols="12" class="v-center pl-5">月次予定タスクリスト</v-col>
      </v-row>
    </v-list-item>
    <v-list-item class="px-2 mt-4">
      <v-row>
        <v-col cols="12">
          <LargeCategory
            :selectetedObject="selectedCategory"
            :categories="largeCategories"
            @change="changePrimaryCategory"
          >
          </LargeCategory>
        </v-col>
      </v-row>
    </v-list-item>
    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="7">
          <v-text-field
            solo
            placeholder="検索"
            maxlength="32"
            @change="handleSearch"
            clearable
            v-model="search"
          >
            <template>
              <v-icon @click="handleSearch" small slot="prepend-inner">
                $search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-list-item>

    <v-divider></v-divider>

    <div
      class="text-center"
      v-if="
        !(smallCategories && smallCategories.length > 0) &&
        !isEmptySearch(this.search)
      "
    >
      <p class="gray--text">検索条件に一致するタスクは</p>
      <p class="gray--text">見つかりませんでした。</p>
    </div>
    <div class="d-flex justify-center" v-if="isLoading">
      <svg
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        stroke="#000"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
    <div class="content height-custom overflow-y-auto" v-else>
      <v-treeview
        v-if="smallCategories && smallCategories.length > 0"
        class="custom-tree"
        :items="smallCategories"
        item-key="treeId"
        :open="filteredKeys"
        selected-color="indigo"
        open-on-click
        expand-icon="mdi-chevron-down"
      >
        <template v-slot:prepend="{ leaf }">
          <div v-if="leaf">
            <v-icon small>$favoriteinactive</v-icon>
          </div>
        </template>
        <template v-slot:label="{ item, leaf }">
          <div
            v-if="leaf"
            @click="view(item)"
            :class="[{ 'db-task': isAllowDrag() }]"
            :name="item.id"
          >
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="8">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on">
                          <div class="d-flex align-center flex-wrap">
                            <div class="text-truncate mr-1">
                              {{ item.name }}
                              <span v-if="item.detail">_{{item.detail}}</span>
                            </div>
                            <BeginnerIcon v-if="isBeginnerSkill(item)" />
                            <ExpertIcon v-if="isExpertSkill(item)" />
                          </div>
                        </v-list-item-title>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <span>{{ item.cost }}m/回</span></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            {{ item.category_secondary_name }}
          </div>
        </template>
      </v-treeview>

      <MonthlyTask
        :planId="planId"
        :isPlan="isPlan"
        :selectMonthDate="selectMonthDate"
        :show="showMonthlyTask"
        :scheduleTaskMonthId="scheduleTaskMonthId"
        @addToCalendar="addToCalendar($event)"
      ></MonthlyTask>
    </div>
  </div>
</template>
  
<script>
import LargeCategory from "../modal/LargeCategory";
import MonthlyTask from "../modal/MonthlyTask";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { apiErrorMessages } from "../../../helpers/messages";
import { GET_MONTHLY_SCHEDULES_TASK_API } from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import { FC843 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import BeginnerIcon from "@/components/icons/BeginnerIcon.vue";
import ExpertIcon from "@/components/icons/ExpertIcon.vue";
import * as systemAuth from "@/constants/role_code";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: {
    LargeCategory,
    MonthlyTask,
    BeginnerIcon,
    ExpertIcon,
  },
  mixins: [taskMixin],
  props: {
    value: Boolean,
    planId: String,
    selectMonthDate: String,
    isPlan: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
    },
    show: {
      async handler(val) {
        this.$emit("input", val);
      },
    },
    iselectedDate: {
      handler(val) {
        this.selectedDate = val;
      },
    },
  },
  methods: {
    touchstart(event) {
      document.documentElement.style.overflow = "hidden";
      this.dragItem = event.target;
      this.originalTop = event.touches[0].clientY;
      this.dragItem.style.height = this.dragItem.clientHeight;
      this.dragItem.style.width = this.dragItem.clientWidth;
      this.dragItem.style.position = "fixed";
      this.dragItem.style.zIndex = "99999";
    },
    touchmove(event) {
      const x = event.changedTouches[0].clientX;
      const y = event.changedTouches[0].clientY;
      let target = document.elementsFromPoint(x, y);
      if (!target && !target.length <= 0) {
        // Mouse going off screen. Ignore event.
        return;
      }
      if (
        target
          .map((x) => {
            return x.id;
          })
          .some((x) => x === "calendarTab")
      ) {
        this.$emit("activeCalendarTab");
      }
      if (this.dragItem) {
        this.dragItem.style.top = event.changedTouches[0].clientY + "px";
      }
    },

    touchend(event, item) {
      document.documentElement.style.overflow = "auto";
      const x = event.changedTouches[0].clientX; // (relative to the viewport)
      const y = event.changedTouches[0].clientY;
      const target = document.elementsFromPoint(x, y);
      if (!target && !target.length <= 0) {
        return;
      }
      const timeRowInCalendar = [].filter.call(target, (elements) =>
        [].includes.call(elements.classList, "fc-timegrid-slot-lane")
      );
      // Get time
      const time = timeRowInCalendar?.[0]?.dataset?.time;
      if (!isEmpty(time))
        this.$emit("drop-monthly-task-list-tab-item", { time, item });
      if (this.dragItem) {
        // reset our element
        this.dragItem.style.top = this.originalTop;
        this.dragItem.style.left = "";
        this.dragItem.style.height = "";
        this.dragItem.style.width = "";
        this.dragItem.style.position = "";
        this.dragItem.style.zIndex = "";

        this.dragItem = null;
      }
    },
    addToCalendar(item) {
      let time = item.start_time + ":00";
      this.$emit("drop-monthly-task-list-tab-item", { time, item });
      this.$emit("activeCalendarTab");
    },
    handleSearch() {
      const searchConvert = this.convertSearchKey(this.search);
      let categories =
        this.selectedCategory.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) =>
                x.category_primary_id ===
                this.selectedCategory.category_primary_id
            );
      this.smallCategories = categories
        .map((x) => {
          let children = x.children.filter(
            (y) =>
              (y.name.includes(this.search) ||
              isEmpty(this.search)) ||
              (this.convertSearchKey(y.name).includes(searchConvert))
          );
          return {
            ...x,
            ...{ children: children },
          };
        })
        .filter((x) => x.children && x.children.length > 0);

      if (isEmpty(this.search)) {
        this.filteredKeys = [];
        this.filteredKey = [];
      } else {
        this.filteredKeys = this.smallCategories.map((top) => {
          return top.treeId;
        });
      }
    },
    view(item) {
      this.scheduleTaskMonthId = item.id;
      this.showMonthlyTask = !this.showMonthlyTask;
    },
    isEmptySearch() {
      return isEmpty(this.search);
    },
    isBeginnerSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_BEGIN
    },
    isExpertSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_EXPERT
    },
    changePrimaryCategory(item) {
      this.selectedCategory = item;
      this.smallCategories =
        item.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) => x.category_primary_id === item.category_primary_id
            );
    },
    resetFilter() {
      this.changePrimaryCategory(this.selectedCategory);
    },
    submitFilter(filters) {
      let categories =
        this.selectedCategory.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) =>
                x.category_primary_id ===
                this.selectedCategory.category_primary_id
            );

      this.smallCategories = categories
        .map((x) => {
          let children = x.children.filter(!filters.some((g) => g.check)
          );
          return {
            ...x,
            ...{ children: children },
          };
        })
        .filter((x) => x.children && x.children.length > 0);

      if (!filters.some((g) => g.check)) {
        this.filteredKeys = [];
        this.filteredKey = [];
      } else {
        this.filteredKeys = this.smallCategories.map((top) => {
          return top.treeId;
        });
      }
    },
    getMonthlytaskList() {
      return this.monthlytasks;
    },

    getTargetMonth() {
      let dateParts = this.selectMonthDate.split('-');
      let year = dateParts[0];
      let month = dateParts[1];
      let convertedDate = year + month;
      return convertedDate;
    },

    async getData() {
      // this.isLoading = true;
      this.search = "";

      let user_id = this.user.user_id;
      let target_month = this.getTargetMonth();
  
      try {
        const { data, status } = await axios.get(GET_MONTHLY_SCHEDULES_TASK_API(
          user_id,
          target_month,
        ));
        this.monthlytasks = status === STATUS_NO_CONTENT ? [] : data.result;
        let primaryCategories = [
          {
            category_primary_id: 0,
            category_primary_name: "すべてのカテゴリ",
          },
        ];
        let secondaryCategories = [];
        let index = 0;
        this.monthlytasks.forEach((monthlytask) => {
          if (
            !primaryCategories.some((x) => {
              return x.category_primary_id === monthlytask.category_primary_id;
            })
          ) {
            primaryCategories.push({
              category_primary_id: monthlytask.category_primary_id,
              category_primary_name: monthlytask.category_primary_name,
            });
          }

          monthlytask.treeId = index++;
          monthlytask.count = 1;

          if (
            !secondaryCategories.some((x) => {
              return x.category_secondary_id === monthlytask.category_secondary_id;
            })
          ) {
            secondaryCategories.push({
              category_primary_name: monthlytask.category_primary_name,
              category_primary_id: monthlytask.category_primary_id,
              category_secondary_name: monthlytask.category_secondary_name,
              category_secondary_id: monthlytask.category_secondary_id,
              treeId: index++,
              children: [monthlytask],
            });
          } else {
            secondaryCategories
              .filter((x) => {
                return x.category_secondary_id === monthlytask.category_secondary_id;
              })[0]
              .children.push(monthlytask);
          }
        });
        this.smallCategories = secondaryCategories;
        this.smallDbCategories = deepClone(secondaryCategories);
        this.largeCategories = primaryCategories;
        this.selectedCategory = primaryCategories[0];
        this.changePrimaryCategory(this.selectedCategory);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC843, status);
      } finally {
        this.isLoading = false;
      }
    },
    isAllowDrag() {
      if (this.isPlan) {
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      standarId: null,
      search: "",
      show: false,
      showMonthlyTask: false,
      largeCategories: [],
      filters: [],
      smallCategories: [],
      smallDbCategories: [],
      selectedCategory: {},
      monthlytasks: [],
      dragItem: null,
      originalTop: null,
      isLoading: false,
      filteredKeys: [],
      filteredKey: [],
      scheduleTaskMonthId: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  created() {
    this.$root.$refs.scheduleMonthyTabList = this;
  },
};
</script>
<style lang="scss" scoped>
.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 64px - 72px - 60px - 48px - 42px - 45px);
  overflow-x: hidden;
}
.content {
  ::v-deep.custom-tree {
    .v-treeview-node {
      position: relative;
      .v-treeview-node__children {
        .v-treeview-node__content {
          margin-left: -8%;
          padding-top: 5px !important;
          padding-bottom: 12px !important;
          border-bottom: solid 1px #e1e1e1;
          .v-treeview-node__label > div {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .v-treeview-node__prepend {
      min-height: 24px;
    }
    .v-treeview-node__label {
      line-height: 24px;
    }
    > .v-treeview-node {
      > .v-treeview-node__root {
        border-bottom: solid 1px #e1e1e1;
        .v-treeview-node__content {
          border-bottom: unset;
          .v-treeview-node__label {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
