<template>
  <v-dialog
    v-model="openExplain"
    persistent
    content-class="border-dialog"
    max-width="780px"
    scrollable
    v-dialog-drag="{
      dialog: 'border-dialog',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h2 class="py-2 h2-mobile">
          タスク色とアイコンは以下のように設定されています。
        </h2>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: '#EBEBEB',
                maxWidth: '22%',
                marginRight: '8%',
                height: '25px',
              }"
            >
              <v-list-item-title class="ml-2"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>定常業務として登録されているタスク</v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: '#8b8a8a',
                maxWidth: '22%',
                marginRight: '8%',
                height: '25px',
              }"
            >
              <v-list-item-title class="ml-2"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>月次予定に入れていたが実施しなかったタスク</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: '#f38282',
                maxWidth: '22%',
                marginRight: '8%',
                height: '25px',
              }"
            >
              <v-list-item-title class="ml-2"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div>「変更後予定工数/回」の1.2倍以上の工数が</div>
              <div class="mt-2">
                かかってしまった「実績工数/回」
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: '#fff8b7',
                maxWidth: '22%',
                marginRight: '8%',
                height: '25px',
              }"
            >
              <v-list-item-title class="ml-2"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div>月次予定の登録内容から変更があった項目</div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{ maxWidth: '22%', marginRight: '8%' }"
            >
              <v-icon>$remandtask</v-icon>
            </v-list-item-content>
            <v-list-item-content> 差し戻しタスク </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogDragDirective from "../../../directives/dialogDrag";
import {
  COLOR_DB_TASK,
} from "../../../constants/color";
export default {
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  props: {
    show: Boolean,
  },
  watch: {
    show: {
      handler() {
        this.openExplain = true;
      },
    },
  },
  data() {
    return {
      openExplain: this.show,
      COLOR_DB_TASK: COLOR_DB_TASK,
    };
  },
  methods: {
    close() {
      this.openExplain = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .border-dialog {
  .v-card__text {
    padding-top: 10px;
    text-align: center;
    .v-list-item {
      padding: 4px 5px !important;
    }
    .v-list-item__content {
      text-align: left;
      padding: 8px 0 !important;
    }
    .explain {
      padding-left: 30px;
      width: 100%;
      margin: 0 auto;
    }
    .divider {
      padding: 5px 25px 5px 35px;
      hr {
        border-top: 1px solid gray;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
    .v-card__text {
      padding-left: 2px;
      padding-right: 2px;
      .h2-mobile {
        font-size: medium;
      }
      .v-list-item {
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
      }
      .v-list-item__content {
      margin-right: 8px !important;
    }
      .explain {
        padding-left: 0px;
        width: 100%;
        margin: 0 auto;
      }
      .divider {
        padding: 2px 12px 2px 5px;
      }
    }
  }
}
</style>
<style scoped>
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
</style>