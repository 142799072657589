<template>
  <v-container class="container--fluid" :style="cssProps">
    <v-row v-if="$vuetify.breakpoint.mobile">
      <v-col>
        <v-tabs v-model="currentTab" @change="changeTab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab id="calendarTab" href="#mobile-tabs-calendar" class="">
            工数管理
          </v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-monthlylist">月次予定タスク</v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-dblist">DB</v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-todolist">未完了</v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-favoritelist">お気に入り</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <div
      class="content-row"
      :class="[{ 'is-panel-collapsed': !showDrawerRight }]"
    >
      <div
        :class="[
          { 'content-calendar': isSelf() },
          { 'my-column-full': !isSelf() },
        ]"
      >
        <v-tabs-items v-model="currentTab" @change="changeTab">
          <v-tab-item value="mobile-tabs-calendar">
            <v-row v-if="$vuetify.breakpoint.mobile">
              <v-col>
                <v-combobox
                  v-if="expand === 0"
                  class="mr-2"
                  item-text="name"
                  item-value="id"
                  v-model="selectUser"
                  hide-details
                  solo
                  :items="members"
                  :menu-props="{
                    allowOverflow: true,
                  }"
                  @change="membersChange($event)"
                >
                  <template slot="append-outer">
                    <ExtensiveTask
                      :solotDurationOptionsData="slotDurationItems"
                      :slotDurationData="calendarOptions.slotDuration"
                      @reload="getSchedule()"
                      :isHasSplitTask="
                        this.calendarOptions.events.some(
                          (x) => x.is_split_task === 1
                        )
                      "
                      :planId="planId"
                      :isDisable="disableOveride"
                      :isNoTask="
                        this.calendarOptions.events.length === 0 ||
                        (this.calendarOptions.events.some(
                          (x) => x.title === '休憩'
                        ) &&
                          this.calendarOptions.events.length === 1)
                      "
                      :isSelf="isSelf()"
                      @showExplainModal="showExplainModal()"
                      :canSubmit="
                        !(
                          !canSubmit ||
                          !calendarOptions.events.some(
                            (x) => x.color !== COLOR_GRAY
                          ) ||
                          (!overLapTimeError && !alignBlankTimeError.isBlank)
                        )
                      "
                      @reloadSlotDuration="reloadSlotDuration($event)"
                      @arrangeTask="arrangeTask()"
                    >
                    </ExtensiveTask>
                  </template>
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <span>{{ item.name }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
                <div class="py-1" v-if="expand === 0">
                  <v-menu
                    ref="menu"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value="selectedDate"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex align-center custom">
                        <v-btn
                          x-small
                          class="mr-1 direction-btn"
                          @click="moveSelectedDate()"
                        >
                          <v-icon color="#7F7F8F">mdi-menu-left</v-icon>
                        </v-btn>
                        <v-text-field
                          solo
                          width="32"
                          hide-details
                          :value="formatSelectedDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                        <v-btn
                          x-small
                          class="ml-1 direction-btn"
                          @click="moveSelectedDate(true)"
                        >
                          <v-icon color="#7F7F8F">mdi-menu-right</v-icon>
                        </v-btn>
                      </div>                    
                      <div class="v-center pl-2 pt-2 working-time-custom">
                        <span class="font-weight-bold">就業時間： &nbsp;</span>
                        <v-text-field
                          solo
                          width="16"
                          hide-details
                          :value="dspStartWorkTime()"
                          readonly
                          v-bind:disabled="!isInternOrPartTime()"
                          @click="changeWorkingTime()"
                        ></v-text-field>
                        <span>～&nbsp;</span>
                        <v-text-field
                          solo
                          width="16"
                          hide-details
                          :value="dspEndWorkTime()"
                          readonly
                          v-bind:disabled="!isInternOrPartTime()"
                          @click="changeWorkingTime()"
                        ></v-text-field>
                      </div>
                      <div class="v-center pl-2 pt-1">
                        <span class="font-weight-bold">合計： &nbsp;</span>
                        <span class="font-weight-bold blue--text"
                          >{{ total.hours }}h{{ total.minutes }}m</span
                        >
                        <span class="font-weight-bold">/</span>
                        <span class="font-weight-bold sub-hours"
                          >{{ workingTime }}
                        </span>
                      </div>
                    </template>
                    <v-date-picker
                      v-model="selectedDate"
                      @change="changDate"
                      locale="ja"
                      no-title
                      :day-format="
                        (selectedDate) => new Date(selectedDate).getDate()
                      "
                      scrollable
                      @input="menuDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>
                <div
                  class="pb-1 d-flex btn-mobile"
                  v-if="!authorizeCheck && expand === 0"
                >
                  <div class="d-flex btn-box">
                    <v-btn
                      @click="
                        statuses &&
                          statuses.length > 1 &&
                          (showNotification = !showNotification)
                      "
                      depressed
                      :class="{
                        'reject-text': isRejectText,
                      }"
                      class="mr-2"
                      tile
                      >{{ statusText }}</v-btn
                    >
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        isRejectStatus() &&
                        !this.calendarOptions.events.some((x) => x.check)
                      "
                      tile
                      class="font-weight-bold btn-reject"
                      @click="submit(true)"
                      :disabled="
                        submitButton.active || !hasSupervisor
                      "
                    >
                      <v-icon class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span class="text-large">差し戻し</span>
                    </v-btn>
                  </div>
                  <div
                    class="mr-2 btn-box"
                  >
                    <v-btn
                      v-if="(isSupervisor() && !isRejectAll) || isSelf()"
                      tile
                      class="font-weight-bold"
                      @click="submit(false)"
                      :disabled="
                        submitButton.active || !hasSupervisor
                      "
                    >
                      <v-icon
                        v-if="!submitButton.isReject"
                        class="blue--text mr-2"
                        right
                        >$submitorconfirm</v-icon
                      >
                      <v-icon v-else class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span>
                        {{ submitButton.text }}
                      </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                      <span
                        v-if="
                          this.calendarOptions.events.some((x) => x.check) &&
                          isRejectStatus()
                        "
                        class="text-large"
                        >差し戻し</span
                      >
                    </v-btn>
                  </div>
                  <div class="btn-box">
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        !isRejectAll &&
                        this.calendarOptions.events.some((x) => x.check)
                      "
                      class="font-weight-bold"
                      disabled
                    >
                      <v-icon class="blue--text mr-2" right
                        >$submitorconfirm</v-icon
                      >
                      <span> {{ $t("label.buttons.confirm") }} </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-else
              class="header-items"
              :class="{ 'hide-header-items': expand !== 0 }"
            >
              <v-col v-show="expand === 0">
                <div class="d-flex flex-wrap no-padding-print">
                  <div class="py-2">
                    <v-combobox
                      item-text="name"
                      item-value="id"
                      v-model="selectUser"
                      hide-details
                      solo
                      :items="members"
                      @change="membersChange($event)"
                    ></v-combobox>
                  </div>
                  <div class="py-2 pl-3 d-flex align-center">
                    <v-btn
                      x-small
                      class="mr-1 direction-btn"
                      @click="moveSelectedDate()"
                    >
                      <v-icon color="#7F7F8F">mdi-menu-left</v-icon>
                    </v-btn>
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      :return-value="selectedDate"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          solo
                          hide-details
                          :value="formatSelectedDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selectedDate"
                        @change="changDate"
                        locale="ja"
                        no-title
                        :day-format="
                          (selectedDate) => new Date(selectedDate).getDate()
                        "
                        scrollable
                        @input="menuDate = false"
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-btn
                      x-small
                      class="ml-1 direction-btn"
                      @click="moveSelectedDate(true)"
                    >
                      <v-icon color="#7F7F8F">mdi-menu-right</v-icon>
                    </v-btn>
                  </div>
                  <div class="py-2 pl-3">
                    <div class="v-center pt-2">
                      <span class="font-weight-bold">合計： &nbsp;</span>
                      <span class="font-weight-bold blue--text"
                        >{{ total.hours }}h{{ total.minutes }}m</span
                      >
                      <span class="font-weight-bold">/</span>
                      <span class="font-weight-bold sub-hours"
                        >{{ workingTime }}
                      </span>
                    </div>
                  </div>
                  <div class="pa-2 pl-4 no-print">
                    <daily-template
                      @reload="getSchedule()"
                      :selectedDate="selectedDate"
                      :planId="planId"
                      :isDisable="disableOveride"
                      :isNoTask="
                        this.calendarOptions.events.length === 0 ||
                        (this.calendarOptions.events.some(
                          (x) => x.title === '休憩'
                        ) &&
                          this.calendarOptions.events.length === 1)
                      "
                      :isHasSplitTask="
                        this.calendarOptions.events.some(
                          (x) => x.is_split_task === 1
                        )
                      "
                      v-if="isSelf()"
                    ></daily-template>
                  </div>

                  <div class="d-flex align-center custom working-time-custom">
                        <span class="font-weight-bold no-print">就業時間： &nbsp;</span>
                        <v-text-field
                          solo
                          width="16px"
                          hide-details
                          :value="dspStartWorkTime()"
                          readonly
                          v-bind:disabled="!isInternOrPartTime()"
                          @click="changeWorkingTime()"
                          class="no-print"
                        ></v-text-field>
                        <span>～&nbsp;</span>
                        <v-text-field
                          solo
                          width="16px"
                          hide-details
                          :value="dspEndWorkTime()"
                          readonly
                          v-bind:disabled="!isInternOrPartTime()"
                          @click="changeWorkingTime()"
                          class="no-print"
                        ></v-text-field>
                  </div>

                  <div class="pa-2 no-print">
                    <div class="v-center d-lex ml-3">
                      <span class="mr-2">表示切替</span>
                      <v-select
                        width="10"
                        class="mr-2 select"
                        :value="calendarOptions.slotDuration"
                        :items="slotDurationItems"
                        @change="reloadSlotDuration"
                        hide-details
                        solo
                      ></v-select>
                      <v-btn @click="showExplainModal()" depressed class="ml-1">
                        <v-icon>$information</v-icon>
                      </v-btn>
                    </div>
                  </div>

                  <div v-if="!authorizeCheck" class="pa-2 ml-auto">
                    <v-btn
                      v-if="isSelf()"
                      @click="
                        !(
                          !canSubmit ||
                          !calendarOptions.events.some(
                            (x) => x.color !== COLOR_GRAY
                          ) ||
                          (!overLapTimeError && !alignBlankTimeError.isBlank)
                        ) && arrangeTask()
                      "
                      :disabled="
                        !canSubmit ||
                        !calendarOptions.events.some(
                          (x) => x.color !== COLOR_GRAY
                        ) ||
                        (!overLapTimeError && !alignBlankTimeError.isBlank)
                      "
                      depressed
                      class="mr-2 no-print"
                    >
                      整列
                    </v-btn>
                    <v-btn
                      @click="
                        statuses &&
                          statuses.length > 1 &&
                          (showNotification = !showNotification)
                      "
                      depressed
                      :class="{
                        'reject-text': isRejectText,
                      }"
                      tile
                      >{{ statusText }}</v-btn
                    >
                  </div>
                  <div
                    v-if="!authorizeCheck"
                    class="d-flex py-2 no-print"
                  >
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        isRejectStatus() &&
                        !this.calendarOptions.events.some((x) => x.check)
                      "
                      tile
                      class="font-weight-bold no-print mr-2"
                      @click="submit(true)"
                      :disabled="
                        submitButton.active || !hasSupervisor
                      "
                    >
                      <v-icon class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span class="text-large">差し戻し</span>
                    </v-btn>
                    <v-btn
                      v-if="(isSupervisor() && !isRejectAll) || isSelf()"
                      tile
                      class="font-weight-bold no-print"
                      @click="submit(false)"
                      :disabled="
                        submitButton.active || !hasSupervisor
                      "
                    >
                      <v-icon
                        v-if="!submitButton.isReject"
                        class="blue--text mr-2"
                        right
                        >$submitorconfirm</v-icon
                      >
                      <v-icon v-else class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span>
                        {{ submitButton.text }}
                      </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                      <span
                        v-if="
                          this.calendarOptions.events.some((x) => x.check) &&
                          isRejectStatus()
                        "
                        class="text-large"
                        >差し戻し</span
                      >
                    </v-btn>
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        !isRejectAll &&
                        this.calendarOptions.events.some((x) => x.check)
                      "
                      class="ml-2 font-weight-bold no-print"
                      disabled
                    >
                      <v-icon class="blue--text mr-2" right
                        >$submitorconfirm</v-icon
                      >
                      <span> {{ $t("label.buttons.confirm") }} </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-divider class="mb-2"></v-divider>
            <v-row v-if="authorizeCheck">
              <v-col cols="12" class="mx-auto text-center">
                {{ authorizeMessage }}
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                class="print-calendar"
                :class="{
                  'fullcalendar-itokuro-expand':
                    !$vuetify.breakpoint.mobile && expand !== 0,
                  'fullcalendar-itokuro': !$vuetify.breakpoint.mobile,
                  'fullcalendar-itokuro-mobile-expand':
                    $vuetify.breakpoint.mobile && expand !== 0,
                  'fullcalendar-itokuro-mobile': $vuetify.breakpoint.mobile,
                  'fullcalendar-itokuro--five-min':
                    calendarOptions.slotDuration === `00:05:00`,
                  'fullcalendar-itokuro--thirty-min':
                    calendarOptions.slotDuration === `00:30:00`,
                  'fullcalendar-itokuro--fifteen-min':
                    calendarOptions.slotDuration === `00:15:00`,
                  'fullcalendar-itokuro--sixty-min':
                    calendarOptions.slotDuration === `00:60:00`,
                }"
              >
                <FullCalendar
                  ref="fullCalendar"
                  :options="calendarOptions"
                  class="viewCalendar"
                >
                  <template v-slot:eventContent="arg">
                    <v-row>
                      <v-col
                        class="__task-content d-flex"
                        :class="[
                          {
                            normalText:
                              (isDbTask(arg.event.extendedProps) &&
                                isDbTaskByType(arg.event.extendedProps)) ||
                              isFreeTask(arg.event.extendedProps),
                          },
                          {
                            'flex-column': isBreakLineTaskContent(
                              arg.event.extendedProps.cost,
                              arg.event.extendedProps.count,
                              arg.event.extendedProps.unit
                            ),
                          },
                        ]"
                      >
                        <div
                          class="__task-info d-flex align-center"
                          :class="{
                            'w-100': isBreakLineTaskContent(
                              arg.event.extendedProps.cost,
                              arg.event.extendedProps.count,
                              arg.event.extendedProps.unit
                            ),
                          }"
                        >
                          <span
                            v-if="
                              arg.event.extendedProps.is_remand_previous ===
                                1 || arg.event.extendedProps.is_remand === 1
                            "
                            class="__remand"
                          >
                            <v-icon class="remandTaskIcon" small>
                              $remandtask
                            </v-icon>
                          </span>
                          <!-- ./Remand -->
                          <v-checkbox
                            v-if="
                              arg.event.extendedProps.showCheck &&
                              isRejectStatus()
                            "
                            class="mt-0 __checkbox"
                            hide-details
                            :disabled="!canApproveRemand"
                            v-model="
                              calendarOptions.events.filter(
                                (x) => x.id === +arg.event.id
                              )[0].check
                            "
                            @click="handleConfirm($event, +arg.event.id)"
                          ></v-checkbox>
                          <!-- ./Checkbox -->
                          <span class="name-detail text-truncate">
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                            {{ arg.event.title }}
                            {{ arg.event.extendedProps.detail }}
                          </span>
                        </div>
                        <div
                          :class="{
                            'align-self-center':
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count <
                              30,
                          }"
                        >
                          <span
                            v-if="arg.event.extendedProps.unit !== null && isAllDbTask(arg.event.extendedProps)"
                            class="d-flex align-center"
                          >
                            <span
                              class="task-time text-truncate"
                              :class="[
                                (calendarOptions.slotDuration === '00:60:00' ||
                                  calendarOptions.slotDuration ===
                                    '00:30:00') &&
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <=
                                  30
                                  ? arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                    30
                                    ? 'time-text-truncate time-text-margin'
                                    : 'time-text-truncate'
                                  : 'time-break task-time text-truncate',
                              ]"
                            >
                              {{
                                arg.event.extendedProps.cost *
                                arg.event.extendedProps.count
                              }}m ({{ arg.event.extendedProps.cost }}m/{{
                                arg.event.extendedProps.unit }}×{{
                                arg.event.extendedProps.count
                              }})
                            </span>
                            
                            <span v-if="isAllDbTask(arg.event.extendedProps)">
                              <span
                                v-if="isBeginnerSkill(arg.event.extendedProps)"
                              >
                                <v-icon
                                  class="beginnerTaskIcon"
                                  :class="[
                                    {
                                      'position-inside':
                                        calendarOptions.slotDuration ===
                                          '00:60:00' &&
                                        arg.event.extendedProps.cost *
                                          arg.event.extendedProps.count ===
                                          30,
                                    },
                                  ]"
                                >
                                  $beginnertask
                                </v-icon>
                              </span>
                              <span
                                v-if="isExpertSkill(arg.event.extendedProps)"
                              >
                                <v-icon
                                  class="expertTaskIcon"
                                  :class="[
                                    {
                                      'position-inside':
                                        calendarOptions.slotDuration ===
                                          '00:60:00' &&
                                        arg.event.extendedProps.cost *
                                          arg.event.extendedProps.count ===
                                          30,
                                    },
                                  ]"
                                >
                                  $experttask
                                </v-icon>
                              </span>
                            </span>
                          </span>
                          <span v-else class="d-flex align-center">
                            <span
                              v-if="isAllDbTask(arg.event.extendedProps) || isFreeTask(arg.event.extendedProps) "
                              :class="[
                                (calendarOptions.slotDuration === '00:60:00' ||
                                  calendarOptions.slotDuration ===
                                    '00:30:00') &&
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <=
                                  30
                                  ? arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                    30
                                    ? 'time-text-truncate time-text-margin'
                                    : 'time-text-truncate'
                                  : 'time-break task-time text-truncate',
                              ]"
                            >
                              {{
                                arg.event.extendedProps.cost *
                                arg.event.extendedProps.count
                              }}m ({{ arg.event.extendedProps.cost }}m/回×{{
                                arg.event.extendedProps.count
                              }})
                            </span>
                            <span
                              v-else
                              :class="[
                                (calendarOptions.slotDuration === '00:60:00' ||
                                  calendarOptions.slotDuration ===
                                    '00:30:00') &&
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <=
                                  30
                                  ? arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                    30
                                    ? 'time-text-truncate time-text-margin'
                                    : 'time-text-truncate'
                                  : 'time-break task-time text-truncate',
                              ]"
                            >
                              {{
                                arg.event.extendedProps.cost
                              }}m
                            </span>
                            <span v-if="isAllDbTask(arg.event.extendedProps)">
                              <span
                                v-if="isBeginnerSkill(arg.event.extendedProps)"
                              >
                                <v-icon
                                  class="beginnerTaskIcon"
                                  :class="[
                                    {
                                      'position-inside':
                                        calendarOptions.slotDuration ===
                                          '00:60:00' &&
                                        arg.event.extendedProps.cost *
                                          arg.event.extendedProps.count ===
                                          30,
                                    },
                                  ]"
                                >
                                  $beginnertask
                                </v-icon>
                              </span>
                              <span
                                v-if="isExpertSkill(arg.event.extendedProps)"
                              >
                                <v-icon
                                  class="expertTaskIcon"
                                  :class="[
                                    {
                                      'position-inside':
                                        calendarOptions.slotDuration ===
                                          '00:60:00' &&
                                        arg.event.extendedProps.cost *
                                          arg.event.extendedProps.count ===
                                          30,
                                    },
                                  ]"
                                >
                                  $experttask
                                </v-icon>
                              </span>
                            </span>
                          </span>
                        </div>
                      </v-col>
                    </v-row>                   
                  </template>
                </FullCalendar>
                <v-menu
                  v-model="showMenu"
                  :position-x="x"
                  :position-y="y"
                  absolute
                  offset-y
                >
                  <v-list>                    
                    <v-list-item @click="bulkArchiveTasks()">
                      <v-list-item-icon>
                        <v-icon small>$archive</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>アーカイブ</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="bulkDeleteTasks()">
                      <v-list-item-icon>
                        <v-icon small>$delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>削除</v-list-item-title>
                      </v-list-item-content>       
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="cancelContextMenu()">
                      <v-list-item-icon>
                        <v-icon small>$close</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>キャンセル</v-list-item-title>
                      </v-list-item-content>       
                    </v-list-item>
                  </v-list>
                </v-menu>

                <FullCalendar
                  ref="printCalendar"
                  :options="printCalendarOptions"
                  class="printCalendar"
                  v-if="!isMobile"
                >
                  <template v-slot:eventContent="arg">
                    <v-row>
                      <v-col
                        class="__task-content d-flex mt-0"
                        :class="[
                          {
                            normalText:
                              (isDbTask(arg.event.extendedProps) &&
                                isDbTaskByType(arg.event.extendedProps)) ||
                              isFreeTask(arg.event.extendedProps),
                          },
                          {
                            'flex-column': isBreakLineTaskContent(
                              arg.event.extendedProps.cost,
                              arg.event.extendedProps.count,
                              arg.event.extendedProps.unit
                            ),
                          },
                        ]"
                      >
                        <div
                          class="__task-info d-flex align-center"
                          :class="{
                            'w-100': isBreakLineTaskContent(
                              arg.event.extendedProps.cost,
                              arg.event.extendedProps.count,
                              arg.event.extendedProps.unit
                            ),
                          }"
                        >
                          <span
                            v-if="
                              arg.event.extendedProps.is_remand_previous ===
                                1 || arg.event.extendedProps.is_remand === 1
                            "
                            class="__remand"
                          >
                            <v-icon class="remandTaskIcon" small>
                              $remandtask
                            </v-icon>
                          </span>
                          <!-- ./Remand -->
                          <v-checkbox
                            v-if="
                              arg.event.extendedProps.showCheck &&
                              isRejectStatus()
                            "
                            class="mt-0 __checkbox"
                            hide-details
                            :disabled="!canApproveRemand"
                            v-model="
                              calendarOptions.events.filter(
                                (x) => x.id === +arg.event.id
                              )[0].check
                            "
                            @click="handleConfirm($event, +arg.event.id)"
                          ></v-checkbox>
                          <!-- ./Checkbox -->
                          <span class="name-detail text-truncate">
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                            {{ arg.event.title }}
                            {{ arg.event.extendedProps.detail }}
                          </span>
                        </div>
                        <div>
                          <span
                            v-if="arg.event.extendedProps.count === 1"
                            class="d-flex align-center"
                          >
                            <span class="task-time text-truncate">
                              {{ arg.event.extendedProps.cost }}m
                            </span>
                            <span v-if="isDbTask(arg.event.extendedProps)">
                              <span
                                v-if="isBeginnerSkill(arg.event.extendedProps)"
                              >
                                <v-icon class="beginnerTaskIcon">
                                  $beginnertask
                                </v-icon>
                              </span>
                              <span
                                v-if="isExpertSkill(arg.event.extendedProps)"
                              >
                                <v-icon class="expertTaskIcon">
                                  $experttask
                                </v-icon>
                              </span>
                            </span>
                          </span>
                          <span v-else class="d-flex align-center">
                            <span
                              :class="[
                                (calendarOptions.slotDuration === '00:60:00' ||
                                  calendarOptions.slotDuration ===
                                    '00:30:00') &&
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <=
                                  30
                                  ? 'time-text-truncate'
                                  : 'time-break task-time text-truncate',
                              ]"
                            >
                              {{
                                arg.event.extendedProps.cost *
                                arg.event.extendedProps.count
                              }}m({{ arg.event.extendedProps.cost }}m×{{
                                arg.event.extendedProps.count
                              }})
                            </span>
                            <span v-if="isDbTask(arg.event.extendedProps)">
                              <span
                                v-if="isBeginnerSkill(arg.event.extendedProps)"
                              >
                                <v-icon class="beginnerTaskIcon">
                                  $beginnertask
                                </v-icon>
                              </span>
                              <span
                                v-if="isExpertSkill(arg.event.extendedProps)"
                              >
                                <v-icon class="expertTaskIcon">
                                  $experttask
                                </v-icon>
                              </span>
                            </span>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </FullCalendar>

                <BreakTime
                  :show="showBreakTime"
                  :start="startBreakTime"
                  :end="endBreakTime"
                  :planId="planId"
                  :canView="!isSelf() || !isCanSubmit() || !isNoSubmmit()"
                  @changeBreakTime="updateBreakTime($event)"
                  @archiveBreakTime="deleteBreakTime($event)"
                  :isBreakTask="haveBreakTime"
                ></BreakTime>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            value="mobile-tabs-monthlylist"
            v-if="isSelf() && $vuetify.breakpoint.mobile"
          >
            <MonthlyScheduleTaskListTab
              ref="monthlyList"
              :userId="userId"
              :planId="planId"
              :selectMonthDate="selectedDate"
              @drop-monthly-task-list-tab-item="handleDropTaskFromMonthly"
              @activeCalendarTab="activeCalendarTab"
            ></MonthlyScheduleTaskListTab>
          </v-tab-item>
        
          <v-tab-item
            value="mobile-tabs-dblist"
            v-if="isSelf() && $vuetify.breakpoint.mobile"
          >
            <DBTaskListTab
              ref="dbList"
              :userId="userId"
              @drop-db-task-list-tab-item="handleDropTaskFromDB"
              @activeCalendarTab="activeCalendarTab"
            ></DBTaskListTab>
          </v-tab-item>

          <v-tab-item
            value="mobile-tabs-todolist"
            v-if="isSelf() && $vuetify.breakpoint.mobile"
          >
            <TodoListTab
              @drop-todo-list-tab-item="handleDropTaskFromTodo"
              @activeCalendarTab="activeCalendarTab"
              @archiveBreakTime="deleteBreakTime($event)"
              ref="todoList"
              :userId="userId"
              :isBreakTask="haveBreakTime"
            ></TodoListTab>
          </v-tab-item>

          <v-tab-item
            value="mobile-tabs-favoritelist"
            v-if="isSelf() && $vuetify.breakpoint.mobile"
          >
            <FavoriteTaskListTab
              @drop-favorite-list-tab-item="handleDropTaskFromFavorite"
              @activeCalendarTab="activeCalendarTab"
              ref="favoriteList"
              :userId="userId"
              :planId = "planId"
            ></FavoriteTaskListTab>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <!-- Desktop: DB, Task -->
      <div
        v-show="isSelf() && !$vuetify.breakpoint.mobile"
        class="content-db-list ml-2"
      >
        <v-row class="mr-1">
          <v-col :cols="showDrawerRight ? '2' : '10'">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('monthly-task')"
                    >$monthlyscheduletasklist</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('db-task')"
                    >$dbtasklist</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('todo-task')"
                    >$todolist</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('favorite-task')"
                    >$favoriteactive</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="10" id="dragElements">
            <DBTaskList
              ref="dbList"
              @close="showDbTasks = false"
              v-show="showDbTasks"
              v-model="showDrawerRight"
            ></DBTaskList>
            <TodoList
              ref="todoList"
              @close="showTodoList = false"
              v-show="showTodoList"
              v-model="showDrawerRight"
              :isBreakTask="haveBreakTime"
            ></TodoList>
            <FavoriteList
              ref="favoriteList"
              :planId="planId"
              @close="showFavoriteList = false"
              v-show="showFavoriteList"
              v-model="showDrawerRight"
            ></FavoriteList>
            <MonthlyScheduleTaskList
              ref="monthlyList"
              :planId="planId"
              :selectMonthDate="selectedDate"
              @close="showMonthlyList = false"
              v-show="showMonthlyList"
              v-model="showDrawerRight"
            ></MonthlyScheduleTaskList>
          </v-col>
        </v-row>
      </div>
      <!--/ Desktop: DB, Task -->
    </div>

    <DailyComment
      @submit="submitPlan($event)"
      @saveDraft="saveDraftPlan($event)"
      :inputComment="inputComment"
      :selectedDate="selectedDate"
      :show="showDailyComment"
      :notifications="oldComments"
      :showOldComments="showOldComments"
    ></DailyComment>
    <DailyApproval
      @confirm="confirm($event)"
      @saveDraft="saveDraftPlan($event)"
      :inputComment="inputComment"
      :show="showDailyApproval"
      :selectedDate="selectedDate"
      :ownerName="ownerName"
      :notifications="oldComments"
      :showOldComments="showOldComments"
    ></DailyApproval>
    <DailyReject
      inputComment=""
      @reject="reject($event)"
      :show="showDailyReject"
      :items="checkedItems"
      :selectedDate="selectedDate"
      :ownerName="ownerName"
    ></DailyReject>
    <ActionDatabaseTask
      :standarId="standarId"
      :show="showDatabaseTaskEdit"
      :planId="planId"
      @deleteDbTask="deleteDbTask($event)"
      @archiveDbTask="archiveDbTask($event)"
      @archiveDbSplitTask="archiveDbSplitTask($event)"
      @editDbTask="editDbTask($event)"
      @duplicateTask="duplicateDbTask($event)"
      @setFavorite="setFavorite($event)"
    ></ActionDatabaseTask>
    <ActionFreeTask
      :planId="planId"
      :freeId="freeId"
      :show="showFreeTaskEdit"
      @archiveFreeTask="archiveFreeTask($event)"
      @deleteFreeTask="deleteFreeTask($event)"
      @updateFreeTask="updateFreeTask($event)"
      @duplicateTask="duplicateFreeTask($event)"
      @setFavorite="setFreeFavorite($event)"
    ></ActionFreeTask>
    <FreeTask
      :planId="planId"
      :show="showFreeTask"
      :copyFreeData="copyFreeData"
      @registerFreeTask="registerFreeTask($event)"
    ></FreeTask>
    <ActionNewDatabaseTask
      :planId="planId"
      :show="showDbTask"
      :copyDbData="copyDbData"
      @registerDbTask="registerDbTask($event)"
    ></ActionNewDatabaseTask>
    <ViewTask
      :planId="planId"
      :show="showViewTask"
      :taskId="taskId"
      :viewType="'PLAN'"
    ></ViewTask>
    <Notification
      :show="showNotification"
      :notifications="statuses"
      :date="selectedDate"
      @closeNotification="closeNotification"
    >
    </Notification>

    <ExplainTask :show="showExplain"> </ExplainTask>
    <WorkingTime
        :show="showWorkingTime"
        :start="dspStartWorkTime()"
        :end="dspEndWorkTime()"
        :planId="planId"
        :canView="!isSelf() || !isCanSubmit() || !isNoSubmmit() || !isInternOrPartTime()"
        @changeWorkingTime="updateWorkingTime($event)"
      ></WorkingTime>
  </v-container>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import BreakTime from "./modal/BreakTime";
import WorkingTime from "./modal/WorkingTime";
import DBTaskList from "./modal/DBTaskList";
import TodoList from "./modal/TodoList";
import FavoriteList from "./modal/FavoriteList";
import DailyTemplate from "./modal/DailyTemplate";
import DailyComment from "./modal/DailyComment";
import DailyApproval from "./modal/DailyApproval";
import DailyReject from "./modal/DailyReject";
import ActionDatabaseTask from "./modal/ActionDatabaseTask";
import ActionFreeTask from "./modal/ActionFreeTask";
import ActionNewDatabaseTask from "./modal/ActionNewDatabaseTask";
import ExplainTask from "./modal/ExplainTask";
import ViewTask from "./modal/ViewTask";
import FreeTask from "./modal/FreeTask";
import DBTaskListTab from "./tabs/DBTaskListTab";
import TodoListTab from "./tabs/TodoListTab";
import FavoriteTaskListTab from "./tabs/FavoriteTaskListTab";
import ExtensiveTask from "./modal/ExtensiveTask";
import MonthlyScheduleTaskList from "./modal/MonthlyScheduleTaskList.vue";
import MonthlyScheduleTaskListTab from "./tabs/MonthlyScheduleTaskListTab.vue";
import {
  formatDateTime,
  formatTime,
  parseDateTime,
  toHHmm,
  toMinute,
} from "../../helpers/datetime-helpers";
import {
  debounce_leading,
  deepClone,
  isEmpty,
  isEmptyNumber,
  isOverLap,
  orderBy,
} from "../../helpers/helpers";
import {
  GET_SCHEDULE_API,
  GET_MEMBERS_API,
  SCHEDULE_API,
  GET_SCHEDULE_TASK_API,
  TODO_TASK_API,
  SCHEDULE_SORT_API,
  NOTIFICATION_API,
  SCHEDULE_DRAFT_API,
} from "../../constants/api";
import { mapGetters } from "vuex";
import * as Role from "../../constants/role";
import axios from "axios";
import * as SystemConstant from "../../constants/system";
import { apiErrorMessages } from "../../helpers/messages";
import {
  FC108,
  FC802,
  FC816,
  FC817,
  FC807,
  FC821,
  FC502,
  FC827,
  FC803,
  FC808,
  FC501,
} from "../../constants/fc";
import { STATUS_NO_CONTENT } from "../../constants/status";
import {
  HHmm1,
  HHmm2,
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMMddEEEEE3,
  yyyyMMddHHmm1,
  yyyyMMddHHmmss1,
  yyyyMMddHHmmss2,
  HHmm3,
  HHmmss1,
  HHmmss2,
} from "../../constants/df";
import {
  COLOR_GRAY,
  COLOR_FREE_TASK,
  COLOR_DB_TASK,
  COLOR_SELECT_TASK
} from "../../constants/color";
import { adjustMinEnd } from "../../helpers/system";
import Notification from "./modal/Notification.vue";
import { timeValidator } from "@/helpers/validator";
import Cookies from "js-cookie";
import {
  CALENDAR_PRINT_LENGHT_MM,
  DEFAULT_SCALE,
  SLOT_30_SCALE,
  SLOT_60_SCALE,
} from "../../constants/calendar";
import { calendarMixin } from "@/mixins/calendar";
export default {
  layout: "default",
  middleware: Role.AUTH,
  metaInfo() {
    return { title: this.$t("title.daily-plan") };
  },
  components: {
    FullCalendar,
    BreakTime,
    DBTaskList,
    DBTaskListTab,
    TodoList,
    TodoListTab,
    FavoriteList,
    FavoriteTaskListTab,
    DailyTemplate,
    DailyComment,
    DailyApproval,
    DailyReject,
    ActionDatabaseTask,
    ActionFreeTask,
    FreeTask,
    ActionNewDatabaseTask,
    ViewTask,
    Notification,
    ExplainTask,
    ExtensiveTask,
    WorkingTime,
    MonthlyScheduleTaskList,
    MonthlyScheduleTaskListTab,
  },
  mixins: [calendarMixin],
  data() {
    return {
      showMenu:false,
      x: 0,
      y: 0,
      showContextMenu: false,
      COLOR_GRAY: COLOR_GRAY,
      COLOR_SELECT_TASK:COLOR_SELECT_TASK,
      canApproveRemand: false,
      canSubmit: false,
      canSubmitCancel: false,
      slotDurationItems: [
        { value: "00:05:00", text: "5分" },
        { value: "00:15:00", text: "15分" },
        { value: "00:30:00", text: "30分" },
        { value: "00:60:00", text: "60分" },
      ],
      showExplain: false,
      scrollToTime: "",
      expand: 0,
      statuses: [],
      showNotification: false,
      ownerName: "",
      currentTab: "mobile-tabs-calendar",
      showViewTask: false,
      selectUser: "",
      taskId: null,
      freeId: null,
      phaseId: null,
      menuDate: false,
      standarId: null,
      members: [],
      selectedDate: formatDateTime(new Date()),
      userId: "",
      planId: "",
      statusText: "未提出",
      submitButton: {
        text: "提出",
        active: false,
        isReject: false,
      },
      mini: true,
      search: "",
      showDbTasks: false,
      showDrawerRight: false,
      showDrawerMobile: true,
      showTodoList: false,
      showBreakTime: false,
      startBreakTime: "",
      endBreakTime: "",
      startWorkTime: "",
      endWorkTime: "",
      showDatabaseTaskEdit: false,
      showFreeTaskEdit: false,
      showDailyComment: false,
      showDailyApproval: false,
      showDailyReject: false,
      showFreeTask: false,
      showDbTask: false,
      showFavoriteList: false,
      showMonthlyList:false,
      breadcrumbs: [
        {
          text: "",
          href: "",
          disabled: true,
        },
        {
          text: "日次予定",
          href: "",
          disabled: true,
        },
      ],
      canViewComment: false,
      events: [],
      calendarOptions: {
        slotMinTime: "00:00:00",
        slotMaxTime: "22:00:00",
        slotDuration: "00:15:00",
        scrollTime: false,
        loading: this.setLoaded,
        // contentHeight: "auto",
        height: "parent",
        locale: "es",
        plugins: [timeGridPlugin, interactionPlugin],
        headerToolbar: false,
        //timeZone: "UTC",
        allDaySlot: false,
        initialView: "timeGridDay",
        editable: this.isSelf(),
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        dayHeaders: false,
        initialEvents: [],
        events: [],
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventDrop: this.handlelEventDrop,
        droppable: true, // this allows things to be dropped onto the calendar
        drop: this.handlelDrop,
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
        },
        eventOrder: "start_time,id",
        eventResize: function (eventResizeInfo) {
          eventResizeInfo.revert();
        },
        eventDidMount: (arg) => {
          arg.el.addEventListener("contextmenu", (jsEvent)=>{
            this.show(jsEvent, arg);
          })
        }
      },
      copyFreeData: {
        start_time: "",
        end_time: "",
      },
      copyDbData: {},
      authorizeMessage: "",
      authorizeCheck: false,
      openNotification: true,
      scrollTop: null,
      preSlotDuration: "00:15:00",
      inputComment: "",
      min: 5,
      isOverOnePage: false,
      timeGridLength: 0,
      isRejectAll: false,
      showOldComments: false,
      oldComments: [],
      isInDraft: false,
      showDraftText: false,
      showWorkingTime:false,
      haveBreakTime: false,
      listNotification: [],
    };
  },
  methods: {
    /**
     * 選択メニュー表示（削除・アーカイブ）
     * @param {*} e 
     * @param {*} arg 
     */
    show: function(e, arg) {     
        e.preventDefault();
        const isSelected = arg.event.backgroundColor === COLOR_SELECT_TASK;
        if (isSelected){
          this.x = e.clientX;
          this.y = e.clientY;       
          this.$nextTick(() => {
            this.showMenu = !this.showMenu;
          });
        }
        else{
          this.showMenu = false;
        }       
      },
    /**
     * When switching time display
     * @param {string} [value]
     * @return scroll, set time slot duration to browser
     */
    reloadSlotDuration(e) {
      //this.min = (e === "00:30:00" || e === '00:60:00') ? 15 : 5;
      if (e === "00:30:00") {
        this.min = 15;
      } else if (e === "00:60:00") {
        this.min = 18;
      } else {
        this.min = 5;
      }
      this.calendarOptions.events.map((x) => {
        x.start = x.start_real;
        x.end = formatDateTime(
          parseDateTime(
            this.selectedDate +
              " " +
              adjustMinEnd(x.start_time, x.end_time, HHmmss1, this.min), // adjustMinEnd HHmmss1
            yyyyMMddHHmmss1
          ),
          yyyyMMddHHmmss2
        );
      });
      let scrollTopPosition =
        document.getElementsByClassName("fc-scroller")[0].scrollTop;
      switch (true) {
        case this.preSlotDuration === "00:30:00":
          switch (true) {
            case e === "00:05:00":
              this.scrollTop = scrollTopPosition * 6;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition * 3;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 1.1;
              break;
          }
          break;
        case this.preSlotDuration === "00:05:00":
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition / 6;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition / 2;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 6.5;
              break;
          }
          break;
        case this.preSlotDuration === "00:15:00":
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition / 3;
              break;
            case e === "00:05:00":
              this.scrollTop = scrollTopPosition * 2;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 3.2;
              break;
          }
          break;
        // this.preSlotDuration === "00:60:00"
        default:
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition * 1.1;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition * 3.2;
              break;
            // e === "00:05:00"
            default:
              this.scrollTop = scrollTopPosition * 6.5;
              break;
          }
          break;
      }
      this.calendarOptions.slotDuration = e;
      this.preSlotDuration = e;
      if (!isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[0];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }

          this.scrollTop = null;
        }, 0.0005);
      }
      Cookies.set("slotDurationItem" + this.loginInfo?.user_id, e, {
        expires: 365,
      });

      // change slot min max time
      let events = this.calendarOptions.events;
      this.adjustTasks(events, this.selectedDate, this.min, "24:00:00");
      this.changeSlotMinMax(this.calendarOptions, events, this.startWorkTime, this.endWorkTime);
    },

    /**
     * Add event from db list
     * @param {object} [value]
     * @return {void}
     */
    async addEventFromDbList(dbTask) {
      try {
        const { data } = await axios.post(GET_SCHEDULE_TASK_API(this.planId), {
          schedule_tasks: [
            {
              name: dbTask.name,
              description: dbTask.description,
              detail: dbTask.detail,
              cost: +dbTask.cost,
              count: 1,
              unit: dbTask.unit,
              category_secondary_id: +dbTask.category_secondary_id,
              is_favorite: +dbTask.is_favorite,
              start_time: null,
              standard_task_id: dbTask.id,
              standard_task_revision: dbTask.revision,
              // tags: dbTask.tags,
              is_remand: 0,
              skill_level_id: dbTask.skill_level_id || null,
            },
          ],
        });
        //登録時のDBタスクの回数は1回
        dbTask.count = 1;

        let calendarTask = {
          start_time: data.result[0].start_time,
          end_time: data.result[0].end_time,
          title: dbTask.name,
          detail: dbTask.detail,
          start: formatDateTime(
            parseDateTime(
              this.selectedDate + " " + data.result[0].start_time,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          end: formatDateTime(
            parseDateTime(
              this.selectedDate +
                " " +
                adjustMinEnd(
                  data.result[0].start_time,
                  data.result[0].end_time,
                  HHmmss1,
                  this.min
                ), // adjustMinEnd HHmmss1
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          end_real: formatDateTime(
            parseDateTime(
              this.selectedDate + " " + data.result[0].end_time,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          check: false,
          showCheck: false,
          //className: +dbTask.is_need_output === 1 ? "output-task" : "",
          className: this.isDbTaskByType(dbTask) ? "dbTask" : "",
          // color: "#FFFFFF",
          color: this.isDbTaskByType(dbTask) ? COLOR_DB_TASK : COLOR_GRAY,
          task_type_id: dbTask.task_type_id,
          isPlan: false,
          showFile: false,
          cost: +dbTask.cost,
          count: 1,
          unit: dbTask.unit,
          standard_task_id: dbTask.id,
          standard_task_revision: dbTask.revision,
          free_task_id: null,
          actual_free_task_id: null,
          category_secondary_name: dbTask.category_secondary_name,
          category_secondary_id: dbTask.category_secondary_id,
          category_primary_id: dbTask.category_primary_id,
          skill_level_id: dbTask.skill_level_id || null,
          name: dbTask.name,
          is_favorite: +dbTask.is_favorite,
          is_remand: 0,
          is_need_output: 0,
        };
        calendarTask.id = data.result[0].id;
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(
            "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
            {
              width: "600px",
            }
          );
        } else {
          apiErrorMessages(this, FC802, status);
        }
      }
    },

    /**
     * Add break task from todo list
     * @param {object} [value]
     * @return {void}
     */
    async addEventFromTodoListbreak() {
      try {
        this.haveBreakTime = false;
        let startTime = '120000';
        let endTime = '130000';
        await axios.put(GET_SCHEDULE_API(this.planId), {
          start_break_time: startTime,
          end_break_time: endTime,
        });
        let start = formatDateTime(
          parseDateTime(
            this.selectedDate + " " + startTime,
            yyyyMMddHHmmss1
          ),
          yyyyMMddHHmm1
        );
        let end = formatDateTime(
          parseDateTime(
            this.selectedDate +
              " " +
              endTime, // adjustMinEnd HHmmss1
            yyyyMMddHHmmss1
          ),
          yyyyMMddHHmm1
        );
        let endReal = formatDateTime(
          parseDateTime(
            this.selectedDate + " " + endTime,
            yyyyMMddHHmmss1
          ),
          yyyyMMddHHmm1
        );
        let cost = this.diffTime(
            startTime,
            endTime
          );
        // Break task
        let breakTask = {
          title: "休憩",
          start_time: startTime,
          end_time: endTime,
          start: start,
          end: end,
          end_real:endReal,
          color: COLOR_GRAY,
          check: false,
          showCheck: false,
          count: 1,
          cost: cost,
        };
        this.startBreakTime = formatDateTime(breakTask.start, HHmm2);
        this.endBreakTime = formatDateTime(breakTask.end_real, HHmm2);
        this.calendarOptions.events.push(breakTask);
      }
      catch (error) {
          const { status } = error.response;

          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC802, status);
          }
        } // END-TRY-CATCH
    },
    /**
     * Add event from todo list
     * @param {object} [value]
     * @return {void}
     */
    async addEventFromTodoList(todoTask) {
      try {
          let scheduleTasks = [
            {
              name: todoTask.name,
              description: todoTask.description,
              category_secondary_id: todoTask.category_secondary_id,
              task_type_id: todoTask.task_type_id,
              cost: +todoTask.cost,
              count: todoTask.standard_task_id ? 1 : todoTask.count,
              unit: todoTask.unit,
              is_need_output: +todoTask.is_need_output || 0,
              output_detail: todoTask.output_detail,
              is_favorite: +todoTask.is_favorite,
              standard_task_id: todoTask.standard_task_id,
              standard_task_revision: todoTask.standard_task_revision,
              free_task_id: todoTask.free_task_id,
              actual_free_task_id: todoTask.actual_free_task_id,
              start_time: null,
              todo_task_id: todoTask.isSplit ? null : todoTask.id,
              is_remand: 0,
              split_task_id: todoTask.isSplit ? todoTask.id : null,
              detail: todoTask.detail || "",
              skill_level_id: todoTask.skill_level_id || null,
              schedule_month_task_id: todoTask.schedule_month_task_id,
            },
          ];
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );
          //DBタスクの時、回数を1回にする
          todoTask.count = todoTask.standard_task_id ? 1 : todoTask.count

          let calendarTask = {
            start_time: data.result[0].start_time,
            end_time: data.result[0].end_time,
            title: todoTask.name,
            detail: todoTask.detail || "",
            start: formatDateTime(
              parseDateTime(
                this.selectedDate + " " + data.result[0].start_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            end: formatDateTime(
              parseDateTime(
                this.selectedDate +
                  " " +
                  adjustMinEnd(
                    data.result[0].start_time,
                    data.result[0].end_time,
                    HHmmss1,
                    this.min
                  ), // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            end_real: formatDateTime(
              parseDateTime(
                this.selectedDate + " " + data.result[0].end_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            check: false,
            showCheck: false,
            // className: todoTask.is_need_output === 1 ? "output-task" : "",
            className:
              this.isDbTask(todoTask) &&
              !todoTask.isSplit &&
              this.isDbTaskByType(todoTask)
                ? "dbTask"
                : "",
            task_type_id: todoTask.task_type_id,
            isPlan: false,
            showFile: false,
            cost: +todoTask.cost,
            count: todoTask.count,
            unit: todoTask.unit,
            standard_task_id: todoTask.isSplit
              ? todoTask.id
              : todoTask.standard_task_id,
            standard_task_revision: todoTask.isSplit
              ? todoTask.id
              : todoTask.standard_task_revision,
            free_task_id: todoTask.free_task_id,
            actual_free_task_id: todoTask.actual_free_task_id,
            category_secondary_name: todoTask.category_secondary_name,
            category_secondary_id: todoTask.category_secondary_id,
            category_primary_id: todoTask.category_primary_id,
            split_task_id: todoTask.isSplit ? todoTask.id : null,
            skill_level_id: todoTask.skill_level_id || null,
            name: todoTask.name,
            is_favorite: +todoTask.is_favorite,
            is_remand: 0,
            is_need_output: +todoTask.is_need_output || 0,
            schedule_month_task_id: todoTask.schedule_month_task_id,
          };

          calendarTask.color = this.getColor(calendarTask);
          calendarTask.id = data.result[0].id;
          calendarTask.free_task_id = isEmptyNumber(todoTask.free_task_id)
            ? null
            : data.result[0].id;
          !todoTask.isSplit
            ? this.$refs.todoList.removeTodoTask(todoTask.id)
            : this.$refs.todoList.removeSplitTask(todoTask.id);
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;

          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC802, status);
          }
        } // END-TRY-CATCH
    },

    /**
     * 
     * @param {*} favoriteTask 
     */
    async addEventFromFavoriteList(favoriteTask) {
      try {
          let scheduleTasks = [
            {
              name: favoriteTask.name,
              description: favoriteTask.description,
              category_secondary_id: favoriteTask.category_secondary_id,
              task_type_id: favoriteTask.task_type_id,
              cost: +favoriteTask.cost,
              count: +favoriteTask.count,
              is_need_output: +favoriteTask.is_need_output || 0,
              output_detail: favoriteTask.output_detail,
              is_favorite: 1,
              start_time: null,
              is_remand: 0,
              favorite_task_id: favoriteTask.favorite_task_id,
            },
          ];
          const { data } = await axios.post(GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );

          let t = {};
          t.favorite_task_id = data.result[0].favorite_task_id;
          t.start_time = data.result[0].start_time;
          t.end_time = data.result[0].end_time,
          t.cost = +favoriteTask.cost;
          t.count = +favoriteTask.count;
          t.name = favoriteTask.name;
          t.title = favoriteTask.name;
          t.showCheck = false;
          t.check = false;
          t.free_task_id = data.result[0].free_task_id;
          t.id = data.result[0].id;
          t.color = this.getColor(data.result[0]);
          t.start = formatDateTime(
              parseDateTime(
                this.selectedDate + " " + data.result[0].start_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
          t.end = formatDateTime(
              parseDateTime(
                this.selectedDate +
                  " " +
                  adjustMinEnd(
                    data.result[0].start_time,
                    data.result[0].end_time,
                    HHmmss1,
                    this.min
                  ), // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
          t.end_real = formatDateTime(
              parseDateTime(
                this.selectedDate + " " + data.result[0].end_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
          
          //t.className = task.is_need_output ? "outputTask" : "";
          t.className =  "";
          t.category_secondary_name = favoriteTask.category_secondary_name;
          t.category_secondary_id = favoriteTask.category_secondary_id;
          t.category_primary_id = favoriteTask.category_primary_id;
          t.task_type_id = favoriteTask.task_type_id,
          t.name = favoriteTask.name;
          t.is_favorite = 1;
          t.is_remand = 0;
          t.is_need_output = 0;
          this.calendarOptions.events.push(t);
        } catch (error) {
          const { status } = error.response;

          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC802, status);
          }
        } // END-TRY-CATCH
    },
    /**
     * Add event from monthly list
     * @param {object} [value]
     * @return {void}
     */
    async addEventFromMonthlyList(monthlyTask) {
      try {
          let scheduleTasks = [
            {
              name: monthlyTask.name,
              detail: monthlyTask.detail || "",
              description: monthlyTask.description,
              category_secondary_id: monthlyTask.category_secondary_id,
              task_type_id: monthlyTask.task_type_id,
              cost: +monthlyTask.cost,
              count: 1,
              unit: monthlyTask.unit,
              is_need_output: +monthlyTask.is_need_output || 0,
              output_detail: monthlyTask.output_detail,
              is_favorite: 0,
              standard_task_id: monthlyTask.standard_task_id,
              standard_task_revision: monthlyTask.standard_task_revision,
              start_time: null,
              is_remand: 0,
              skill_level_id: monthlyTask.skill_level_id || null,
              schedule_month_task_id: monthlyTask.id,
            },
          ];
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );

          let calendarTask = {
            start_time: data.result[0].start_time,
            end_time: data.result[0].end_time,
            title: monthlyTask.name,
            detail: monthlyTask.detail || "",
            start: formatDateTime(
              parseDateTime(
                this.selectedDate + " " + data.result[0].start_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            end: formatDateTime(
              parseDateTime(
                this.selectedDate +
                  " " +
                  adjustMinEnd(
                    data.result[0].start_time,
                    data.result[0].end_time,
                    HHmmss1,
                    this.min
                  ), // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            end_real: formatDateTime(
              parseDateTime(
                this.selectedDate + " " + data.result[0].end_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            check: false,
            showCheck: false,
            className:
              this.isDbTask(monthlyTask) &&
              this.isDbTaskByType(monthlyTask)
                ? "dbTask"
                : "",
            task_type_id: monthlyTask.task_type_id,
            isPlan: false,
            showFile: false,
            cost: +monthlyTask.cost,
            count: 1,
            unit: monthlyTask.unit,
            standard_task_id: monthlyTask.standard_task_id,
            standard_task_revision: monthlyTask.standard_task_revision,
            category_secondary_name: monthlyTask.category_secondary_name,
            category_secondary_id: monthlyTask.category_secondary_id,
            category_primary_id: monthlyTask.category_primary_id,
            skill_level_id: monthlyTask.skill_level_id || null,
            name: monthlyTask.name,
            is_favorite: 0,
            is_remand: 0,
            is_need_output: +monthlyTask.is_need_output || 0,
            schedule_month_task_id: monthlyTask.id,
          };

          calendarTask.id = data.result[0].id;
          calendarTask.free_task_id = data.result[0].free_task_id;
          calendarTask.color = this.getColor(calendarTask);
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;

          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC802, status);
          }
        } // END-TRY-CATCH
    },

    /**
     * Arrange task
     * @return {void}
     */
    async arrangeTask() {
      const confirmText =
        "タスク同士に隙間がある場合、すべてのタスクが上に詰めて\r\n 配置されますが、本当にタスクを整列しますか?\r\n\n" +
        "▼注意事項\r\n本システムはスケジュールを管理するツールではないため、\r\n 整列することによって、朝会やMTGなどの開始時間が、\r\n正確な開始時間から数分ずれても問題はありません。";
      this.$confirm(confirmText, {
        width: "700px",
        arrangeTask: this.$vuetify.breakpoint.mobile ? "custom-dialog" : "",
      })
        .then(async () => {
          try {
            const { data } = await axios.post(SCHEDULE_SORT_API(this.planId));
            let calendar = data;
            // SET DATA
            let start;
            let end;
            let endReal;
            let cost;
            this.calendarOptions.events = calendar.result.schedule_tasks.map(
              (x) => {
                let event = {
                  ...x,
                  title: x.name,
                  start: formatDateTime(
                    parseDateTime(
                      this.selectedDate + " " + x.start_time,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmmss2
                  ),
                  end: formatDateTime(
                    parseDateTime(
                      this.selectedDate +
                        " " +
                        adjustMinEnd(
                          x.start_time,
                          x.end_time,
                          HHmmss1,
                          this.min
                        ), // adjustMinEnd HHmmss1
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmmss2
                  ),
                  end_real: formatDateTime(
                    parseDateTime(
                      this.selectedDate + " " + x.end_time,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmmss2
                  ),
                  check: !!x.is_remand,
                  showCheck: this.isSupervisor(),
                  //className: x.is_need_output ? "outputTask" : "",
                  className:
                    this.isDbTask(x) && !this.isSplitTask(x) ? "dbTask" : "",
                  color: this.getColor(x),
                };
                return event;
              }
            );
            if (
              calendar.result.start_break_time &&
              calendar.result.end_break_time
            ) {
            start = formatDateTime(
              parseDateTime(
                this.selectedDate + " " + calendar.result.start_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            );
            end = formatDateTime(
              parseDateTime(
                this.selectedDate +
                  " " +
                  adjustMinEnd(
                    calendar.result.start_break_time,
                    calendar.result.end_break_time,
                    HHmmss1,
                    this.min
                  ), // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            );
            endReal = formatDateTime(
              parseDateTime(
                this.selectedDate + " " + calendar.result.end_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            );
            cost = this.diffTime(
              calendar.result.start_break_time,
              calendar.result.end_break_time
            );
            this.startBreakTime = calendar.result.start_break_time;
            this.endBreakTime = calendar.result.end_break_time;
            // Break task
            let breakTask = {
              title: "休憩",
              start_time: calendar.result.start_break_time,
              end_time: calendar.result.end_break_time,
              start: start,
              end: end,
              end_real: endReal,
              color: COLOR_GRAY,
              check: false,
              showCheck: false,
              count: 1,
              cost: cost,
            };
            this.startBreakTime = formatDateTime(breakTask.start, HHmm2);
            this.endBreakTime = formatDateTime(breakTask.end_real, HHmm2);
            if (+breakTask.cost * +breakTask.count !== 0)
              this.calendarOptions.events.push(breakTask);
            }
          } catch (error) {
            const { status } = error.response;
            if (status === 404) {
              this.$alert(
                "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
                {
                  width: "600px",
                }
              );
            } else {
              apiErrorMessages(this, FC821, status);
            }
          } // END-TRY-CATCH
        })
        .catch(() => {});
    },

    /**
     * Show explain modal
     * @return {void}
     */
    showExplainModal() {
      this.showExplain = !this.showExplain;
    },

    /**
     * Toggle expand
     * @return {void}
     */
    toggleExpand() {
      if (this.expand !== 0) {
        this.expand = 0;
      } else {
        this.expand = 1;
      }
    },

    /**
     * Load data when change tab
     * @param {string} [value]
     * @return {void}
     */
    changeTab(number) {
      switch (number) {
        case "mobile-tabs-dblist":
          this.$root.$refs.scheduleDbTabList?.getData();
          break;
        case "mobile-tabs-todolist":
          this.$root.$refs.scheduleTodoTabList?.loadTodoListData();
          break;
        case "mobile-tabs-favoritelist":
          this.$root.$refs.scheduleFavoriteTabList?.loadFavoriteListData();
          break;
        case "mobile-tabs-monthlylist":
          this.$root.$refs.scheduleMonthyTabList?.getData();
          break;
        default:
          window.dispatchEvent(new Event("resize"));
          break;
      }
    },

    /**
     * Active calendar tab
     * @return {void}
     */
    activeCalendarTab() {
      this.currentTab = "mobile-tabs-calendar";
    },

    /**
     * set loaded
     * @param {boolean} [value]
     * @return {void}
     */
    setLoaded(isLoading) {
      if (!isLoading && !isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[0];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }
          this.scrollTop = null;
        }, 0.0005);
      }
    },

    /**
     * Handle drop task from DB
     * @param {string} [time]
     * @param {object} [item]
     * @return {void}
     */
    async handleDropTaskFromDB({ time, item }) {
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let dbTasks = this.$refs.dbList.getDatabaseList();
      let dbTask = dbTasks.find((x) => x.id === parseInt(item.id));
      //登録時のDBタスクの回数は1回
      dbTask.count = 1;

      let endDate = infoDate;
      endDate = new Date(
        endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(infoDate, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      await this.createScheduleDbTask(dbTask, infoDate, endDate);
    },
    /**
     * Handle drop task from todo
     * @param {string} [time]
     * @param {object} [item]
     * @return {void}
     */
    async handleDropTaskFromTodo({ time, item }) {
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let startTime = formatDateTime(infoDate, HHmm1);

      let todoTasks = item.isSplit
        ? this.$refs.todoList.getSplitTask()
        : this.$refs.todoList.getTodoList();
      let todoTask = todoTasks.find((x) => x.id === parseInt(item.id));
      //登録時のDBタスクの回数は1回
      todoTask.count = todoTask.standard_task_id ? 1 : todoTask.count;

      let endDate = infoDate;
        endDate = new Date(
          endDate.getTime() + +todoTask.cost * +todoTask.count * 60000
        );
        if (
          +formatDateTime(endDate, yyyyMMdd1) >
          +formatDateTime(infoDate, yyyyMMdd1)
        ) {
          this.$toast("終了時間が本日を超えるため移動できません。", 3000);
          return;
        }
        let calendarTask = {
          start_time: formatDateTime(infoDate, HHmm1) + "00",
          end_time: formatDateTime(endDate, HHmm1) + "00",
          title: todoTask.name,
          start:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(infoDate, HHmm2),
          end:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            adjustMinEnd(
              formatDateTime(infoDate, HHmm2),
              formatDateTime(endDate, HHmm2),
              HHmm2,
              this.min
            ), // adjustMinEnd HHmm2
          end_real:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(endDate, HHmm2),
          check: false,
          showCheck: false,
          //className: todoTask.is_need_output === 1 ? "output-task" : "",
          className:
            this.isDbTask(todoTask) &&
            !item.isSplit &&
            this.isDbTaskByType(todoTask)
              ? "dbTask"
              : "",
          task_type_id: todoTask.task_type_id,
          // color: this.getColor(todoTask),
          isPlan: false,
          showFile: false,
          cost: +todoTask.cost,
          count: todoTask.count,
          standard_task_id: item.isSplit
            ? todoTask.id
            : todoTask.standard_task_id,
          standard_task_revision: item.isSplit
            ? todoTask.id
            : todoTask.standard_task_revision,
          free_task_id: todoTask.free_task_id,
          actual_free_task_id: todoTask.actual_free_task_id,
          category_secondary_name: todoTask.category_secondary_name,
          category_secondary_id: todoTask.category_secondary_id,
          category_primary_id: todoTask.category_primary_id,
          is_split_task: item.isSplit ? 1 : 0,
          split_task_id: item.isSplit ? todoTask.id : null,
          detail: todoTask.detail || "",
          skill_level_id: todoTask.skill_level_id || null,
          name: todoTask.name,
          is_favorite: +todoTask.is_favorite,
          is_remand: 0,
          is_need_output: +todoTask.is_need_output || 0,
          schedule_month_task_id: todoTask.schedule_month_task_id,
        };
        let scheduleTasks = [
          {
            name: todoTask.name,
            description: todoTask.description,
            category_secondary_id: todoTask.category_secondary_id,
            task_type_id: todoTask.task_type_id,
            cost: +todoTask.cost,
            count: todoTask.count,
            is_need_output: +todoTask.is_need_output || 0,
            output_detail: todoTask.output_detail,
            is_favorite: +todoTask.is_favorite,
            standard_task_id: todoTask.standard_task_id,
            standard_task_revision: todoTask.standard_task_revision,
            start_time: startTime + "00",
            todo_task_id: item.isSplit ? null : todoTask.id,
            is_remand: 0,
            split_task_id: item.isSplit ? todoTask.id : null,
            detail: todoTask.detail || "",
            skill_level_id: todoTask.skill_level_id || null,
            schedule_month_task_id: todoTask.schedule_month_task_id,
          },
        ];

        try {
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );
          item.isSplit
            ? this.$refs.todoList.removeSplitTask(todoTask.id)
            : this.$refs.todoList.removeTodoTask(todoTask.id);
          calendarTask.id = data.result[0].id;
          calendarTask.color = this.getColor(calendarTask);
          calendarTask.free_task_id = isEmptyNumber(todoTask.free_task_id)
            ? null
            : data.result[0].id;
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC802, status);
        } // END-TRY-CATCH
    },

     /**
     * Handle drop task from DB
     * @param {string} [time]
     * @param {object} [item]
     * @return {void}
     */
     async handleDropTaskFromFavorite({ time, item }) {
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      if (item.id){
        let dbTasks = this.$refs.favoriteList.getDbList();
        let dbTask = dbTasks.find((x) => x.standard_task_id === parseInt(item.id));
        dbTask.id = dbTask.standard_task_id;
        dbTask.revision = dbTask.standard_task_revision;
        //登録時のDBタスクの回数は1回
        dbTask.count = 1;

        let endDate = infoDate;
        endDate = new Date(
          endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
        );
        if (
          +formatDateTime(endDate, yyyyMMdd1) >
          +formatDateTime(infoDate, yyyyMMdd1)
        ) {
          this.$toast("終了時間が本日を超えるため移動できません。", 3000);
          return;
        }
        await this.createScheduleDbTask(dbTask, infoDate, endDate);
        return;
      }
      let favoriteTasks = this.$refs.favoriteList.getFavoriteList();
      let favoriteTask = favoriteTasks.find((x) => x.favorite_task_id === parseInt(item.favorite_task_id));
      let endDate = infoDate;
      endDate = new Date(
        endDate.getTime() + +favoriteTask.cost * +favoriteTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(infoDate, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      await this.createScheduleFreeTask(favoriteTask, infoDate, endDate)
    },
    /**
     * Handle drop task from monthly
     * @param {string} [time]
     * @param {object} [item]
     * @return {void}
     */
    async handleDropTaskFromMonthly({ time, item }) {
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let startTime = formatDateTime(infoDate, HHmm1);

      let monthlyTasks = this.$refs.monthlyList.getMonthlytaskList();
      let monthlyTask = monthlyTasks.find((x) => x.id === parseInt(item.id));
      monthlyTask.count = 1;

      let endDate = infoDate;
        endDate = new Date(
          endDate.getTime() + +monthlyTask.cost * +monthlyTask.count * 60000
        );
        if (
          +formatDateTime(endDate, yyyyMMdd1) >
          +formatDateTime(infoDate, yyyyMMdd1)
        ) {
          this.$toast("終了時間が本日を超えるため移動できません。", 3000);
          return;
        }
        let calendarTask = {
          start_time: formatDateTime(infoDate, HHmm1) + "00",
          end_time: formatDateTime(endDate, HHmm1) + "00",
          title: monthlyTask.name,
          start:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(infoDate, HHmm2),
          end:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            adjustMinEnd(
              formatDateTime(infoDate, HHmm2),
              formatDateTime(endDate, HHmm2),
              HHmm2,
              this.min
            ), // adjustMinEnd HHmm2
          end_real:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(endDate, HHmm2),
          check: false,
          showCheck: false,
          className:
            this.isDbTask(monthlyTask) &&
            this.isDbTaskByType(monthlyTask)
              ? "dbTask"
              : "",
          task_type_id: monthlyTask.task_type_id,
          isPlan: false,
          showFile: false,
          cost: +monthlyTask.cost,
          count: 1,
          standard_task_id: monthlyTask.standard_task_id,
          standard_task_revision: monthlyTask.standard_task_revision,
          category_secondary_name: monthlyTask.category_secondary_name,
          category_secondary_id: monthlyTask.category_secondary_id,
          category_primary_id: monthlyTask.category_primary_id,
          detail: monthlyTask.detail || "",
          skill_level_id: monthlyTask.skill_level_id || null,
          name: monthlyTask.name,
          is_favorite: 0,
          is_remand: 0,
          is_need_output: +monthlyTask.is_need_output || 0,
          schedule_month_task_id: monthlyTask.id,
        };
        let scheduleTasks = [
          {
            name: monthlyTask.name,
            description: monthlyTask.description,
            category_secondary_id: monthlyTask.category_secondary_id,
            task_type_id: monthlyTask.task_type_id,
            cost: +monthlyTask.cost,
            count: 1,
            is_need_output: +monthlyTask.is_need_output || 0,
            output_detail: monthlyTask.output_detail,
            is_favorite: 0,
            standard_task_id: monthlyTask.standard_task_id,
            standard_task_revision: monthlyTask.standard_task_revision,
            start_time: startTime + "00",
            is_remand: 0,
            detail: monthlyTask.detail || "",
            skill_level_id: monthlyTask.skill_level_id || null,
            schedule_month_task_id: monthlyTask.id,
          },
        ];

        try {
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );
          calendarTask.id = data.result[0].id;
          calendarTask.free_task_id = data.result[0].free_task_id;
          calendarTask.color = this.getColor(calendarTask);
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC802, status);
        } // END-TRY-CATCH
    },

    /**
     * Authorize Calendar
     * @param {string} [message]
     * @return {void}
     */
    authorizeCalendar(message) {
      this.authorizeCheck = true;
      this.authorizeMessage = message;
    },

    /**
     * Submit plan
     * @param {string} [comment]
     * @return {void}
     */
    async submitPlan(comment) {
      try {
        const { data } = await axios.put(SCHEDULE_API(this.planId), {
          comment: comment,
          phase_type: SystemConstant.PHASE_TYPE_SUBMISSION,
        });

        this.statuses = data.result.statuses;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmit = !!data.result.can_submit;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        let events = this.calendarOptions.events.filter((x) => !!x.is_remand);
        for (let index = 0; index < events.length; index++) {
          const event = events[index];
          event.is_remand = 0;
          event.color = this.getColor(event);
        }
        this.isInDraft = !!data.result.is_in_draft;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC817, status);
      } // END-TRY-CATCH
    },

    /**
     * Save draft
     * @param {string} [comment]
     * @return {void}
     */
    async saveDraftPlan(comment) {
      try {
        const { data } = await axios.post(SCHEDULE_DRAFT_API(this.planId), {
          comment: comment,
        });
        this.$toast(this.$t("label.toast.save_draft_success"), 3000);
        this.statuses = data.result.statuses;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isInDraft = !!data.result.is_in_draft;
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC827, status);
      } // END-TRY-CATCH
    },

    /**
     * Confirm
     * @param {string} [comment]
     * @return {void}
     */
    async confirm(comment) {
      try {
        const { data } = await axios.put(SCHEDULE_API(this.planId), {
          comment: comment,
          phase_type: SystemConstant.PHASE_TYPE_APPROVAL,
        });
        this.statuses = data.result.statuses;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmit = !!data.result.can_submit;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isRejectAll = false;
        this.isInDraft = !!data.result.is_in_draft;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC817, status);
      } // END-TRY-CATCH
    },

    /**
     * Reject
     * @param {string} [comment]
     * @return {void}
     */
    async reject(comment) {
      try {
        if (!this.calendarOptions.events.some((x) => x.check)) {
          if (this.canApproveRemand) {
            this.submitButton = {
              text: `確認`,
              active: !this.canApproveRemand,
              isReject: false,
            };
          }
        }
        const { data } = await axios.put(SCHEDULE_API(this.planId), {
          comment: comment,
          phase_type: SystemConstant.PHASE_TYPE_REJECT,
        });
        let events = this.calendarOptions.events.filter(
          (x) => !!x.is_remand === false && x.color !== COLOR_GRAY
        );

        for (let index = 0; index < events.length; index++) {
          const event = events[index];
          event.is_remand_previous = 0;
        }
        this.statuses = data.result.statuses;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmit = !!data.result.can_submit;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isRejectAll = true;
        this.isInDraft = !!data.result.is_in_draft;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC817, status);
      } // END-TRY-CATCH
    },

    /**
     * Go to selected date
     * @return {void}
     */
    gotoSelectedDate() {
      this.$nextTick(() => {
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(formatDateTime(this.selectedDate), yyyyMMdd2);
        if (!this.isMobile) {
          let printCalendarApi = this.$refs.printCalendar.getApi();
          printCalendarApi.gotoDate(
            formatDateTime(this.selectedDate),
            yyyyMMdd2
          );
        }

        const scrollTime = this.getTimeForScroll(
          this.calendarOptions,
          this.startWorkTime
        );
        calendarApi.scrollToTime(scrollTime);
      });
    },

    /**
     * Delete
     * @param {object} [value]
     * @return {void}
     */
    deleteDbTask(e) {
      let temp = this.calendarOptions.events.find((x) => x.id === e.standarId);
      if (this.isSplitTask(temp)) {
        this.showTodoList && this.$refs.todoList.getSplitData();
      }
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== e.standarId
      );
      // delete split todo task
      if (e.changeTodoList){
        if (this.showTodoList) {
            this.$refs.todoList.loadTodoListData();
        }
      }
    },

    /**
     * Archive DB task
     * @param {object} [value]
     * @return {void}
     */
    async archiveDbTask(e) {
      try {
        const { data, status } = await axios.get(TODO_TASK_API(e.todoTaskId));
        if (status !== STATUS_NO_CONTENT) {
          this.calendarOptions.events = this.calendarOptions.events.filter(
            (x) => x.id !== e.id
          );
          this.$refs.todoList.registerDbTodoTask(data.result[0]);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC807, status);
      }
    },

    /**
     * Archive DB split task
     * @param {object} [value]
     * @return {void}
     */
    async archiveDbSplitTask(e) {
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== e.id
      );
      this.showTodoList && this.$refs.todoList.getSplitData();
    },

    setFavorite(item){
      if (item.is_favorite){
        this.$refs.favoriteList?.addDbTask(item);
      }
      else{
        this.$refs.favoriteList?.removeDbTask(item.standard_task_id);
      }
    },

    setFreeFavorite(item){
      if (item.is_favorite){
        this.$refs.favoriteList?.registerFavoriteTask(item);
      }
      else{
        this.$refs.favoriteList?.deleteFavoriteTask(item);
      }
    },

    /**
     * Delete free task
     * @param {object} [value]
     * @return {void}
     */
    deleteFreeTask(e) {
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== e.freeId
      );

      // delete split todo task
      if (e.changeTodoList){
        if (this.showTodoList) {
            this.$refs.todoList.loadTodoListData();
        }
      }
    },

    /**
     * Archive free task
     * @param {object} [value]
     * @return {void}
     */
    archiveFreeTask(e) {
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== e.id
      );
      e.free_task_id = e.id;
      this.$refs.todoList.registerTodoTask(e);
    },

    /**
     * Handle calendar event click
     * @param {object} [value]
     * @return {void}
     */
    handleEventClick(e) {
      const isBreaktime =
        e.event.backgroundColor === COLOR_GRAY && e.event.id === "";
      
      // ctrl+clickでタスク選択・選択外す
      if (!isBreaktime && e.jsEvent.ctrlKey && this.isSelf()){
        let task = this.calendarOptions.events.find((x) => x.id === +e.event.id);
        // 選択されたタスクの場合、選択外す
        if (task.color === COLOR_SELECT_TASK){
          task.color = this.getColor(task);
        }
        else{
          task.color = COLOR_SELECT_TASK;
        }
        
        return;
      }

      switch (true) {
        case (this.isSelf() || this.isSupervisor()) && isBreaktime:
        case !this.isSelf() && !this.isSupervisor() && isBreaktime:
          this.showBreakTime = !this.showBreakTime; // CASE: 1
          break;
        case (this.isSelf() || this.isSupervisor()) &&
          !this.isCanSubmit() &&
          !isBreaktime:
        case (this.isSelf() || this.isSupervisor()) &&
          this.isCanSubmit() &&
          !this.isNoSubmmit() &&
          !isBreaktime:
        case !this.isSelf() && !this.isSupervisor() && !isBreaktime:
          this.taskId = +e.event.id; // CASE: 2-1
          this.showViewTask = !this.showViewTask; // CASE: 2-2
          break;
        case (this.isSelf() || this.isSupervisor()) &&
          this.isCanSubmit() &&
          this.isNoSubmmit() &&
          (!isEmptyNumber(e.event.extendedProps.free_task_id) || !isEmptyNumber(e.event.extendedProps.actual_free_task_id))&&
          !isBreaktime:
          this.freeId = +e.event.id; // CASE: 3-1
          this.showFreeTaskEdit = !this.showFreeTaskEdit; // CASE: 3-2
          break;
        case (this.isSelf() || this.isSupervisor()) &&
          this.isCanSubmit() &&
          this.isNoSubmmit() &&
          isEmptyNumber(e.event.extendedProps.free_task_id) &&
          isEmptyNumber(e.event.extendedProps.actual_free_task_id) &&
          !isBreaktime:
          this.standarId = +e.event.id; // CASE: 4-1
          this.showDatabaseTaskEdit = !this.showDatabaseTaskEdit; // CASE: 4-2
          break;
        default:
          /* Nothing todo */
          break;
      }
    },

    /**
     * Handle date click
     * @param {object} [value]
     * @return {void}
     */
    handleDateClick(arg) {
      if (this.isCanSubmit() && this.isSelf() && this.isNoSubmmit()) {
        this.copyFreeData = {
          start_time: formatDateTime(arg.date, HHmm2),
          end_time: "",
        };
        this.showFreeTask = !this.showFreeTask;
      } else {
        /** Nothing todo */
      } // END-IF-ELSE
    },

    /**
     * Handle event drop
     * @param {object} [value]
     * @return {void}
     */
    async handlelEventDrop(info) {
      if (this.isCanSubmit() && this.isSelf() && this.isNoSubmmit()) {
        if (
          info.event.backgroundColor === COLOR_GRAY &&
          info.event.title === "休憩"
        ) {
          try {
            let endDate = new Date(info.event.start);
            endDate = new Date(
              endDate.getTime() +
                +info.event.extendedProps.cost *
                  +info.event.extendedProps.count *
                  60000
            );
            if (
              +formatDateTime(endDate, yyyyMMdd1) >
              +formatDateTime(info.event.start, yyyyMMdd1)
            ) {
              this.$toast("終了時間が本日を超えるため移動できません。", 3000);
              info.revert();
              return;
            }
            let startBreakTime = formatDateTime(info.event.start, HHmm3);
            let endBreakTime = formatDateTime(endDate, HHmm3);
            await axios.put(GET_SCHEDULE_API(this.planId), {
              start_break_time: startBreakTime,
              end_break_time: endBreakTime,
            });
            let t = this.calendarOptions.events.find(
              (x) => x.color === COLOR_GRAY && x.title === "休憩"
            );
            t.start_time = startBreakTime;
            t.end_time = endBreakTime;
            t.start = formatDateTime(info.event.start, yyyyMMddHHmmss2);
            t.end = formatDateTime(info.event.end, yyyyMMddHHmmss2);
            t.end_real = formatDateTime(endDate, yyyyMMddHHmmss2);
            this.startBreakTime = formatDateTime(t.start, HHmm2);
            this.endBreakTime = formatDateTime(t.end_real, HHmm2);
          } catch (error) {
            const { status } = error.response;
            info.revert();
            apiErrorMessages(this, FC817, status);
          } // END-TRY-CATCH
        } else {
          if (
            !timeValidator(
              toHHmm(
                this.calculateTime(
                  formatDateTime(info.event.start, HHmm2),
                  +info.event.extendedProps.count,
                  +info.event.extendedProps.cost
                )
              )
            )
          ) {
            this.$toast("終了時間が本日を超えるため移動できません。", 3000);
            info.revert();
            return;
          }

          let scheduleTasks = [
            {
              id: +info.event.id,
              start_time: formatDateTime(info.event.start, HHmm3),
              category_secondary_id: !isEmptyNumber(
                info.event.extendedProps.category_secondary_id
              )
                ? +info.event.extendedProps.category_secondary_id
                : null,
            },
          ];

          try {
            await axios.put(GET_SCHEDULE_TASK_API(this.planId), {
              schedule_tasks: scheduleTasks,
            });

            let event = this.calendarOptions.events.filter(
              (x) =>
                x.id === (!isEmptyNumber(info.event.id) ? +info.event.id : null)
            )[0];
            if (event) {
              let endDate = new Date(info.event.start);
              endDate = new Date(
                endDate.getTime() +
                  +info.event.extendedProps.cost *
                    +info.event.extendedProps.count *
                    60000
              );
              event.start_time = formatDateTime(info.event.start, HHmm3);
              // event.end_time = formatDateTime(info.event.end, HHmm3);
              event.end_time = formatDateTime(endDate, HHmm3);
              event.start = formatDateTime(info.event.start, yyyyMMddHHmmss2);
              event.end = formatDateTime(info.event.end, yyyyMMddHHmmss2);
              // event.end_real = formatDateTime(
              //   info.event.extendedProps.end_real,
              //   yyyyMMddHHmmss2
              // );
              event.end_real = formatDateTime(endDate, yyyyMMddHHmmss2);
            } else {
              /** Nothing todo */
            } // END-IF-ELSE
          } catch (error) {
            const { status } = error.response;
            info.revert();
            apiErrorMessages(this, FC802, status);
          } // END-TRY-CATCH
        } // END-IF-ELSE
      } else {
        /** Nothing todo */
      } // END-IF-ELSE
    },
    calculateTime(startTime, cost, count) {
      let start = toMinute(startTime, ":");
      let plusTime = parseInt(cost) * parseInt(count);
      return start + plusTime;
    },

    /**
     * Handle drop
     * @param {object} [value]
     * @return {void}
     */
    async handlelDrop(info) {
      if (this.isCanSubmit() && this.isSelf() && this.isNoSubmmit()) {
        if (info.draggedEl.className.toString().includes("favorite-db-task")) {
          await this.dropDbFavoriteTask(info);
        } else if (info.draggedEl.className.toString().includes("todo-task")){
          await this.dropTodoTask(info);
        } else if (info.draggedEl.className.toString().includes("db-task")){
          await this.dropDbTask(info);
        } else if (info.draggedEl.className.toString().includes("monthly-task")){
          await this.dropMonthlyTask(info);
        } 
        else{
          await this.dropFavoriteTask(info);
        }
      } else {
        /** Nothing todo */
      } // END-IF-ELSE
    },

    /**
     * Handle event drop Db Task
     * @param {object} [value]
     * @return {void}
     */
    dropDbTask: debounce_leading(async function (info) {
      let dbTasks = this.$refs.dbList.getDatabaseList();
      let dbTaskId = info.draggedEl.getAttribute("name");
      let dbTask = dbTasks.find((x) => x.id === parseInt(dbTaskId));
      //登録時のDBタスクの回数は1回
      dbTask.count = 1;

      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      let startTime = formatDateTime(info.date, HHmm1);
      let calendarTask = {
        start_time: formatDateTime(info.date, HHmm1) + "00",
        end_time: formatDateTime(endDate, HHmm1) + "00",
        title: dbTask.name,
        start:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          formatDateTime(info.date, HHmm2),
        end:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          adjustMinEnd(
            formatDateTime(info.date, HHmm2),
            formatDateTime(endDate, HHmm2),
            HHmm2,
            this.min
          ), // adjustMinEnd HHmm2
        end_real:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          formatDateTime(endDate, HHmm2),
        check: false,
        showCheck: false,
        //className: +dbTask.is_need_output === 1 ? "output-task" : "",
        className: this.isDbTaskByType(dbTask) ? "dbTask" : "",
        color: this.isDbTaskByType(dbTask) ? COLOR_DB_TASK : COLOR_GRAY,
        isPlan: false,
        showFile: false,
        cost: +dbTask.cost,
        count: 1,
        unit: dbTask.unit,
        standard_task_id: dbTask.id,
        standard_task_revision: dbTask.revision,
        free_task_id: null,
        task_type_id: dbTask.task_type_id,
        category_secondary_name: dbTask.category_secondary_name,
        category_secondary_id: dbTask.category_secondary_id,
        category_primary_id: dbTask.category_primary_id,
        detail: dbTask.detail,
        skill_level_id: dbTask.skill_level_id || null,
      };

      let scheduleTasks = [
        {
          name: dbTask.name,
          description: dbTask.description,
          category_secondary_id: dbTask.category_secondary_id,
          cost: +dbTask.cost,
          count: 1,
          unit: dbTask.unit,
          is_need_output: +dbTask.is_need_output || 0,
          output_detail: dbTask.output_detail,
          is_favorite: +dbTask.is_favorite,
          standard_task_id: dbTask.id,
          standard_task_revision: dbTask.revision,
          start_time: startTime + "00",
          is_remand: 0,
          detail: dbTask.detail,
          task_type_id: dbTask.task_type_id,
          skill_level_id: dbTask.skill_level_id || null,
        },
      ];

      try {
        const { data } = await axios.post(GET_SCHEDULE_TASK_API(this.planId), {
          schedule_tasks: scheduleTasks,
        });
        calendarTask.id = data.result[0].id;
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC802, status);
      } // END-SWITCH
    }),

    /**
     * Handle event drop todo Task
     * @param {object} [value]
     * @return {void}
     */
    dropTodoTask: debounce_leading(async function (info) {
      let type = info.draggedEl.getAttribute("data-type");
      let todoTasks =
        type === "split"
          ? this.$refs.todoList.getSplitTask()
          : this.$refs.todoList.getTodoList();
      let todoTaskId = info.draggedEl.getAttribute("name");
      let todoTask = todoTasks.find((x) => x.id === parseInt(todoTaskId));
      //登録時のDBタスクの回数は1回
      todoTask.count = todoTask.standard_task_id ? 1 : todoTask.count

      let endDate = new Date(info.date);
        endDate = new Date(
          endDate.getTime() + +todoTask.cost * +todoTask.count * 60000
        );
        if (
          +formatDateTime(endDate, yyyyMMdd1) >
          +formatDateTime(info.date, yyyyMMdd1)
        ) {
          this.$toast("終了時間が本日を超えるため移動できません。", 3000);
          return;
        }
        let startTime = formatDateTime(info.date, HHmm1);
        let calendarTask = {
          start_time: formatDateTime(info.date, HHmm1) + "00",
          end_time: formatDateTime(endDate, HHmm1) + "00",
          title: todoTask.name,
          start:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(info.date, HHmm2),
          end:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            adjustMinEnd(
              formatDateTime(info.date, HHmm2),
              formatDateTime(endDate, HHmm2),
              HHmm2,
              this.min
            ), // adjustMinEnd HHmm2
          end_real:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(endDate, HHmm2),
          check: false,
          showCheck: false,
          //className: todoTask.is_need_output === 1 ? "output-task" : "",
          className:
            this.isDbTask(todoTask) &&
            type !== "split" &&
            this.isDbTaskByType(todoTask)
              ? "dbTask"
              : "",
          task_type_id: todoTask.task_type_id,
          isPlan: false,
          showFile: false,
          cost: +todoTask.cost,
          count: todoTask.count,
          unit: todoTask.unit,
          standard_task_id:
            type === "split" ? todoTask.id : todoTask.standard_task_id,
          standard_task_revision:
            type === "split" ? todoTask.id : todoTask.standard_task_revision,
          free_task_id: todoTask.free_task_id,
          actual_free_task_id: todoTask.actual_free_task_id,
          category_secondary_name: todoTask.category_secondary_name,
          category_secondary_id: todoTask.category_secondary_id,
          category_primary_id: todoTask.category_primary_id,
          is_split_task: type === "split" ? 1 : 0,
          split_task_id: type === "split" ? todoTask.id : null,
          detail: todoTask.detail || "",
          skill_level_id: todoTask.skill_level_id || null,
          name: todoTask.name,
          is_favorite: +todoTask.is_favorite,
          is_remand: 0,
          is_need_output: +todoTask.is_need_output || 0,
          schedule_month_task_id: todoTask.schedule_month_task_id,
        };
        let scheduleTasks = [
          {
            name: todoTask.name,
            description: todoTask.description,
            category_secondary_id: todoTask.category_secondary_id,
            task_type_id: todoTask.task_type_id,
            cost: +todoTask.cost,
            count: todoTask.count,
            unit: todoTask.unit,
            is_need_output: +todoTask.is_need_output || 0,
            output_detail: todoTask.output_detail,
            is_favorite: +todoTask.is_favorite,
            standard_task_id: todoTask.standard_task_id,
            standard_task_revision: todoTask.standard_task_revision,
            start_time: startTime + "00",
            todo_task_id: type === "split" ? null : todoTask.id,
            is_remand: 0,
            split_task_id: type === "split" ? todoTask.id : null,
            detail: todoTask.detail || "",
            skill_level_id: todoTask.skill_level_id || null,
            schedule_month_task_id: todoTask.schedule_month_task_id,
          },
        ];

        try {
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );
          type !== "split"
            ? this.$refs.todoList.removeTodoTask(todoTask.id)
            : this.$refs.todoList.removeSplitTask(todoTask.id);
          calendarTask.id = data.result[0].id;
          calendarTask.color = this.getColor(calendarTask);
          calendarTask.free_task_id = isEmptyNumber(todoTask.free_task_id)
            ? null
            : data.result[0].id;
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC802, status);
          }
        } // END-TRY-CATCH
    }),
    /**
     * Handle event drop Db Task
     * @param {object} [value]
     * @return {void}
     */
     dropFavoriteTask: debounce_leading(async function (info) {
      let favoriteTasks = this.$refs.favoriteList.getFavoriteList();
      let favoriteTaskId = info.draggedEl.getAttribute("name");
      let favoriteTask = favoriteTasks.find((x) => x.favorite_task_id === parseInt(favoriteTaskId));
      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +favoriteTask.cost * +favoriteTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      await this.createScheduleFreeTask(favoriteTask, info.date, endDate);
    }),

    /**
     * Handle event drop Db Task
     * @param {object} [value]
     * @return {void}
     */
     dropDbFavoriteTask: debounce_leading(async function (info) {
      let dbTasks = this.$refs.favoriteList.getDatabaseList();
      let dbTaskId = info.draggedEl.getAttribute("name");
      let dbTask = dbTasks.find((x) => x.standard_task_id === parseInt(dbTaskId));
      dbTask.id = dbTask.standard_task_id;
      dbTask.revision = dbTask.standard_task_revision;
      //登録時のDBタスクの回数は1回
      dbTask.count = 1;
      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      await this.createScheduleDbTask(dbTask, info.date, endDate);
    }),

    /**
     * Handle event drop monthly Task
     * @param {object} [value]
     * @return {void}
     */
    dropMonthlyTask: debounce_leading(async function (info) {
      let monthlyTasks = this.$refs.monthlyList.getMonthlytaskList();
      let monthlyTaskId = info.draggedEl.getAttribute("name");
      let monthlyTask = monthlyTasks.find((x) => x.id === parseInt(monthlyTaskId));
      let endDate = new Date(info.date);
        endDate = new Date(
          endDate.getTime() + +monthlyTask.cost * +monthlyTask.count * 60000
        );
        if (
          +formatDateTime(endDate, yyyyMMdd1) >
          +formatDateTime(info.date, yyyyMMdd1)
        ) {
          this.$toast("終了時間が本日を超えるため移動できません。", 3000);
          return;
        }
        let startTime = formatDateTime(info.date, HHmm1);
        let calendarTask = {
          start_time: formatDateTime(info.date, HHmm1) + "00",
          end_time: formatDateTime(endDate, HHmm1) + "00",
          title: monthlyTask.name,
          start:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(info.date, HHmm2),
          end:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            adjustMinEnd(
              formatDateTime(info.date, HHmm2),
              formatDateTime(endDate, HHmm2),
              HHmm2,
              this.min
            ), // adjustMinEnd HHmm2
          end_real:
            formatDateTime(this.selectedDate, yyyyMMdd2) +
            " " +
            formatDateTime(endDate, HHmm2),
          check: false,
          showCheck: false,
          className:
            this.isDbTask(monthlyTask) &&
            this.isDbTaskByType(monthlyTask)
              ? "dbTask"
              : "",
          task_type_id: monthlyTask.task_type_id,
          isPlan: false,
          showFile: false,
          cost: +monthlyTask.cost,
          count: 1,
          unit: monthlyTask.unit,
          standard_task_id: monthlyTask.standard_task_id,
          standard_task_revision: monthlyTask.standard_task_revision,
          category_secondary_name: monthlyTask.category_secondary_name,
          category_secondary_id: monthlyTask.category_secondary_id,
          category_primary_id: monthlyTask.category_primary_id,
          detail: monthlyTask.detail || "",
          skill_level_id: monthlyTask.skill_level_id || null,
          name: monthlyTask.name,
          is_favorite: 0,
          is_remand: 0,
          is_need_output: +monthlyTask.is_need_output || 0,
          schedule_month_task_id: monthlyTask.id,
        };
        let scheduleTasks = [
          {
            name: monthlyTask.name,
            description: monthlyTask.description,
            category_secondary_id: monthlyTask.category_secondary_id,
            task_type_id: monthlyTask.task_type_id,
            cost: +monthlyTask.cost,
            count: 1,
            unit: monthlyTask.unit,
            is_need_output: +monthlyTask.is_need_output || 0,
            output_detail: monthlyTask.output_detail,
            is_favorite: 0,
            standard_task_id: monthlyTask.standard_task_id,
            standard_task_revision: monthlyTask.standard_task_revision,
            start_time: startTime + "00",
            is_remand: 0,
            detail: monthlyTask.detail || "",
            skill_level_id: monthlyTask.skill_level_id || null,
            schedule_month_task_id: monthlyTask.id,
          },
        ];

        try {
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: scheduleTasks,
            }
          );
          calendarTask.id = data.result[0].id;
          calendarTask.free_task_id = data.result[0].free_task_id;
          calendarTask.color = this.getColor(calendarTask);
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC802, status);
          }
        } // END-TRY-CATCH
    }),

    async createScheduleDbTask(dbTask, infoDate, endDate){
      let startTime = formatDateTime(infoDate, HHmm1);
      let calendarTask = {
        start_time: formatDateTime(infoDate, HHmm1) + "00",
        end_time: formatDateTime(endDate, HHmm1) + "00",
        title: dbTask.name,
        start:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          formatDateTime(infoDate, HHmm2),
        end:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          adjustMinEnd(
            formatDateTime(infoDate, HHmm2),
            formatDateTime(endDate, HHmm2),
            HHmm2,
            this.min
          ), // adjustMinEnd HHmm2
        end_real:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          formatDateTime(endDate, HHmm2),
        check: false,
        showCheck: false,
        className: this.isDbTaskByType(dbTask) ? "dbTask" : "",
        color: this.isDbTaskByType(dbTask) ? COLOR_DB_TASK : COLOR_GRAY,
        isPlan: false,
        showFile: false,
        cost: +dbTask.cost,
        count: 1,
        unit: dbTask.unit,
        standard_task_id: dbTask.id,
        standard_task_revision: dbTask.revision,
        free_task_id: null,
        actual_free_task_id: null,
        task_type_id: dbTask.task_type_id,
        category_secondary_name: dbTask.category_secondary_name,
        category_secondary_id: dbTask.category_secondary_id,
        category_primary_id: dbTask.category_primary_id,
        detail: dbTask.detail,
        skill_level_id: dbTask.skill_level_id || null,
        name: dbTask.name,
        is_favorite: +dbTask.is_favorite,
        is_remand: 0,
        is_need_output: 0,
      };

      let scheduleTasks = [
        {
          name: dbTask.name,
          description: dbTask.description,
          category_secondary_id: dbTask.category_secondary_id,
          cost: +dbTask.cost,
          count: 1,
          unit: dbTask.unit,
          is_need_output: +dbTask.is_need_output || 0,
          output_detail: dbTask.output_detail,
          is_favorite: +dbTask.is_favorite,
          standard_task_id: dbTask.id,
          standard_task_revision: dbTask.revision,
          start_time: startTime + "00",
          is_remand: 0,
          detail: dbTask.detail,
          task_type_id: dbTask.task_type_id,
          skill_level_id: dbTask.skill_level_id || null,
        },
      ];

      try {
        const { data } = await axios.post(GET_SCHEDULE_TASK_API(this.planId), {
          schedule_tasks: scheduleTasks,
        });
        calendarTask.id = data.result[0].id;
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC802, status);
      } // END-SWITCH
    },

    async createScheduleFreeTask(favoriteTask, infoDate, endDate){
      let startTime = formatDateTime(infoDate, HHmm1);
      let calendarTask = {
        start_time: formatDateTime(infoDate, HHmm1) + "00",
        end_time: formatDateTime(endDate, HHmm1) + "00",
        title: favoriteTask.name,
        start:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          formatDateTime(infoDate, HHmm2),
        end:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          adjustMinEnd(
            formatDateTime(infoDate, HHmm2),
            formatDateTime(endDate, HHmm2),
            HHmm2,
            this.min
          ), // adjustMinEnd HHmm2
        end_real:
          formatDateTime(this.selectedDate, yyyyMMdd2) +
          " " +
          formatDateTime(endDate, HHmm2),
        check: false,
        showCheck: false,
        className: "",
        color: COLOR_GRAY,
        isPlan: false,
        showFile: false,
        cost: +favoriteTask.cost,
        count: +favoriteTask.count,
        unit: favoriteTask.unit,
        free_task_id: null,
        actual_free_task_id: null,
        category_secondary_name: favoriteTask.category_secondary_name,
        category_secondary_id: favoriteTask.category_secondary_id,
        category_primary_id: favoriteTask.category_primary_id,
        task_type_id: favoriteTask.task_type_id,
        name: favoriteTask.name,
        is_favorite: 1,
        is_remand: 0,
      };

      let scheduleTasks = [
        {
          name: favoriteTask.name,
            description: favoriteTask.description,
            category_secondary_id: favoriteTask.category_secondary_id,
            task_type_id: favoriteTask.task_type_id,
            cost: +favoriteTask.cost,
            count: +favoriteTask.count,
            unit: favoriteTask.unit,
            is_need_output: +favoriteTask.is_need_output || 0,
            output_detail: favoriteTask.output_detail,
            is_favorite: 1,
            start_time: startTime + "00",
            is_remand: 0,
            favorite_task_id: favoriteTask.favorite_task_id,
        },
      ];

      try {
        const { data } = await axios.post(GET_SCHEDULE_TASK_API(this.planId), {
          schedule_tasks: scheduleTasks,
        });
        calendarTask.id = data.result[0].id;
        calendarTask.free_task_id = data.result[0].free_task_id;
        calendarTask.favorite_task_id = data.result[0].favorite_task_id;
        calendarTask.color = this.getColor(calendarTask);
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC802, status);
      } // END-SWITCH
    },

    /**
     * Handle confirm
     * @param {object} [event]
     * @param {number} [id]
     * @return {void}
     */
    async handleConfirm(event, id) {
      event.stopPropagation();
      if (this.canApproveRemand) {
        try {
          let returnedTask = this.calendarOptions.events.filter(
            (x) => x.check
          ).length;
          if (returnedTask > 0) {
            this.submitButton = {
              text: `${returnedTask}件`,
              active: !this.canApproveRemand,
              isReject: true,
            };
          } else {
            this.submitButton = {
              text: `確認`,
              active: !this.canApproveRemand,
              isReject: false,
            };
          } // END-IF-ELSE
          this.showDraftText = returnedTask === 0;
          let scheduleTasks = [
            {
              id: id,
              is_remand: +this.calendarOptions.events.find((x) => x.id === id)
                .check,
              category_secondary_id: !isEmptyNumber(
                this.calendarOptions.events.find((x) => x.id === id)
                  .category_secondary_id
              )
                ? +this.calendarOptions.events.find((x) => x.id === id)
                    .category_secondary_id
                : null,
            },
          ];
          await axios.put(GET_SCHEDULE_TASK_API(this.planId), {
            schedule_tasks: scheduleTasks,
          });
          const _event = this.calendarOptions.events.find((x) => x.id === id);
          _event.is_remand = scheduleTasks[0].is_remand;
          _event.color = this.getColor(_event);
        } catch (error) {
          const { status } = error.response;
          this.calendarOptions.events.find((x) => x.id === id).check =
            !this.calendarOptions.events.find((x) => x.id === id).check;
          this.submitButton = {
            text: `確認`,
            active: !this.canApproveRemand,
            isReject: false,
          };
          apiErrorMessages(this, FC802, status);
        } //
      } else {
        /** Nothing todo */
      } // END-IF-ELSE
    },

    /**
     * Submit validatition
     * @return {void}
     */
    submitValidation() {
      if (this.preValidation) {
        let errors = [];
        if (this.diffWorkTimeError) {
          errors.push(this.$t("label.error.missing_working_hours"));
        }
        if (this.blankTimeError.isBlank) {
          if (isEmpty(this.blankTimeError.blankData)) {
            errors.push(
              `${this.$t("label.error.blank_time")} ${formatTime(
                `${this.startWorkTime}`.padStart(6, "0")
              )} ~ ${formatTime(`${this.endWorkTime}`.padStart(6, "0"))}`
            );
          } else {
            errors.push(this.$t("label.error.blank_time"));
          }

          this.blankTimeError.blankData.forEach((x) => {
            errors.push(
              `${formatTime(`${x.start}`.padStart(6, "0"))} ~ ${formatTime(
                `${x.end}`.padStart(6, "0")
              )}`
            );
          });
        }
        if (this.overLapTimeError) {
          errors.push(this.$t("label.error.same_time_task_duplicated"));
        }
        return errors;
      } else {
        //NO CODE
      }
    },

    /**
     * Handle submit
     * @return {void}
     */
    submit: debounce_leading(function (isRejectAll) {
      this.showOldComments = false;
      if (this.isSelf()) {
        switch (this.phaseId) {
          case SystemConstant.PHASE_ID_UNSUBMITTED:
            if (this.canSubmit) {
              this.showDailyComment = !this.showDailyComment;
              this.oldComments = this.statuses.filter(
                (x) =>
                  x.phase_id !== SystemConstant.PHASE_ID_UNSUBMITTED &&
                  x.phase_id !== SystemConstant.PHASE_ID_SUBMISSION_RESET
              );
              this.showOldComments = false;
              if (this.oldComments.length > 0) {
                this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
              }
              let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                (x) => x.comment_user_id === this.userId
              );
              if (lastDraftComments.length > 0){
                this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
              }
            } 
            break;
          case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            if (this.canSubmit) {
              this.showDailyComment = !this.showDailyComment;
              this.oldComments = this.statuses.filter(
                (x) =>
                  x.phase_id !== SystemConstant.PHASE_ID_UNSUBMITTED &&
                  x.phase_id !== SystemConstant.PHASE_ID_SUBMISSION_RESET
              );
              this.showOldComments = false;
              if (this.oldComments.length > 0) {
                this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
              }
              let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                (x) => x.comment_user_id === this.userId
              );
              if (lastDraftComments.length > 0){
                this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
              }else{
                let lastComments = this.statuses[this.statuses.length - 2].comment;
                this.inputComment = lastComments;
              }
            } 
            break;
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            if (this.canSubmit) {
              this.showDailyComment = !this.showDailyComment;
              this.oldComments = deepClone(this.statuses);
              this.showOldComments = this.oldComments.length > 0;
              if (this.oldComments.length > 0) {
                this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
              }
              let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                (x) => x.comment_user_id === this.userId
              );
              if (lastDraftComments.length > 0){
                this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
              }
            }
            break;
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
            this.canSubmitCancel && this.callApiFC817();
            break;
          default:
            /** Nothing todo */
            break;
        } // END-SWITCH
      } else {
        if (this.isFirstApprove()) {
          if (this.canApproveRemand) {
            switch (this.phaseId) {
              case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
              case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
                if (
                  (this.isRejectStatus() && isRejectAll) ||
                  (this.isRejectStatus() &&
                    this.calendarOptions.events.filter((x) => x.check).length >
                      0)
                ) {
                  this.showDailyReject = !this.showDailyReject;
                } else {
                  this.showDailyApproval = !this.showDailyApproval;
                  this.oldComments = deepClone(this.statuses);
                  this.showOldComments = this.oldComments.length > 0;
                  if (this.oldComments.length > 0) {
                    this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
                  }
                  let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                    (x) => x.comment_user_id === this.loginInfo.user_id
                  );
                  if (lastDraftComments.length > 0){
                    this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
                  }
                }
                break;
              // case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              //   this.callApiFC817();
              //   break;
              default:
                /** Nothing todo */
                break;
            } // END-SWITCH
          } else {
            // nothing to do
          }
        } else {
          if (
            this.isSecondApprove() &&
            this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
          ) {
            if (this.canApproveRemand) {
              if (
                (this.isRejectStatus() && isRejectAll) ||
                (this.isRejectStatus() &&
                  this.calendarOptions.events.filter((x) => x.check).length > 0)
              ) {
                this.showDailyReject = !this.showDailyReject;
              } else {
                this.showDailyApproval = !this.showDailyApproval;
                this.oldComments = deepClone(this.statuses);
                this.showOldComments = this.oldComments.length > 0;
                if (this.oldComments.length > 0) {
                  this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
                }
                let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                  (x) => x.comment_user_id === this.loginInfo.user_id
                );
                if (lastDraftComments.length > 0){
                  this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
                }
              }
            }
          } else {
            /** Nothing todo */
          } // END-IF-ELSE
        } // END-IF-ELSE
      }
    }),

    /**
     * Call Api FC817
     * @return {void}
     */
    callApiFC817() {
      if (this.canSubmitCancel) {
        this.$confirm(this.$t("label.confirm.cancelsubmit"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.undo"),
              type: "primary",
            },
          },
          rtl: true,
        })
          .then(async () => {
            try {
              const { data } = await axios.put(SCHEDULE_API(this.planId), {
                phase_type:
                  this.isFirstApprove() && !this.isSelf()
                    ? SystemConstant.PHASE_ID_2_NEXT_REJECT
                    : SystemConstant.PHASE_TYPE_SUBMISSION_RESET,
              });
              this.statuses = data.result.statuses;
              this.canApproveRemand = !!data.result.can_approve_remand;
              this.canSubmit = !!data.result.can_submit;
              this.canSubmitCancel = !!data.result.can_submit_cancel;
              this.statusText = this.getStatusText({
                phase_id: this.statuses[this.statuses.length - 1].phase_id,
              });
              this.submitButton = this.getSubmitButton({
                phase_id: this.statuses[this.statuses.length - 1].phase_id,
              });
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC817, status);
            } // END-TRY-CATCH
          })
          .catch(() => {
            return;
          });
      }
    },

    /**
     * Check if align blank time
     * @return {boolean}
     */
    isAlignBlankTime() {
      if (this.calendarOptions.events.length > 0) {
        let startTime = Number(this.startWorkTime);
        let endTime = Number(this.endWorkTime);
        let prepareData = [];

        this.calendarOptions.events.forEach((event) => {
          prepareData.push({
            start: Number(event.start_time),
            end: Number(event.end_time),
            id: event.id,
          });
        });
        const UpdateOverLap = (events, event) => {
          for (let index = 0; index < events.length; index++) {
            if (event.id === events[index].id) {
              continue;
            }

            if (
              !(
                +events[index].start >= +event.end ||
                +events[index].end <= +event.start
              )
            ) {
              events[index].start = Math.min(
                +events[index].start,
                +event.start
              );
              events[index].end = Math.max(+events[index].end, +event.end);
              return true;
            }
          }
          return false;
        };

        prepareData = orderBy(prepareData, ["start", "end"], ["asc", "asc"]);
        if (isEmpty(prepareData))
          return {
            isBlank: true,
            blankData: [],
          };
        let sortData = [prepareData[0]];
        prepareData.shift();
        prepareData.forEach((x) => {
          if (!UpdateOverLap(sortData, x)) sortData.push(x);
        });
        let isBlank = false;
        let index = 0;
        // Case start
        if (
          !((startTime === 0 && endTime === 0) || startTime === endTime) &&
          sortData[0].start > startTime
        ) {
          isBlank = true;
        }
        while (index < sortData.length - 1 && !isBlank) {
          isBlank = sortData[index].end != sortData[index + 1].start;
          index++;
        }

        // // Case end
        // if (
        //   !((startTime === 0 && endTime === 0) || startTime === endTime) &&
        //   !isBlank &&
        //   sortData[sortData.length - 1].end < endTime
        // ) {
        //   isBlank = true;
        // }
        let blankData = [];
        if (isBlank) {
          if (
            !((startTime === 0 && endTime === 0) || startTime === endTime) &&
            sortData[0].start > startTime
          ) {
            blankData.push({
              start: startTime,
              end: sortData[0].start,
            });
          }
          index = 0;
          while (index < sortData.length - 1) {
            if (sortData[index].end != sortData[index + 1].start)
              blankData.push({
                start: sortData[index].end,
                end: sortData[index + 1].start,
              });
            index++;
          }
          // if (
          //   !((startTime === 0 && endTime === 0) || startTime === endTime) &&
          //   sortData[sortData.length - 1].end < endTime
          // ) {
          //   blankData.push({
          //     start: sortData[sortData.length - 1].end,
          //     end: endTime,
          //   });
          // }
        }
        return {
          isBlank: isBlank,
          blankData: blankData,
        };
      } else {
        return true;
      } // END-IF-ELSE
    },

    /**
     * Check if blank time
     * @return {boolean}
     */
    isBlankTime() {
      if (this.calendarOptions.events.length > 0) {
        let startTime = Number(this.startWorkTime);
        let endTime = Number(this.endWorkTime);
        if ((startTime === 0 && endTime === 0) || startTime === endTime) {
          return {
            isBlank: false,
            blankData: [],
          };
        }
        let prepareData = [];

        this.calendarOptions.events.forEach((event) => {
          if (+event.end_time < startTime || +event.start_time > endTime) {
            // Nothing to do
          } else {
            prepareData.push({
              start: Number(event.start_time),
              end: Number(event.end_time),
              id: event.id,
            });
          }
        });

        const UpdateOverLap = (events, event) => {
          for (let index = 0; index < events.length; index++) {
            if (event.id === events[index].id) {
              continue;
            }

            if (
              !(
                +events[index].start >= +event.end ||
                +events[index].end <= +event.start
              )
            ) {
              events[index].start = Math.min(
                +events[index].start,
                +event.start
              );
              events[index].end = Math.max(+events[index].end, +event.end);
              return true;
            }
          }
          return false;
        };

        prepareData = orderBy(prepareData, ["start", "end"], ["asc", "asc"]);
        if (isEmpty(prepareData))
          return {
            isBlank: true,
            blankData: [],
          };
        let sortData = [prepareData[0]];
        prepareData.shift();
        prepareData.forEach((x) => {
          if (!UpdateOverLap(sortData, x)) sortData.push(x);
        });
        let isBlank = false;
        let index = 0;
        // Case start
        if (sortData[0].start > startTime) {
          isBlank = true;
        }
        while (index < sortData.length - 1 && !isBlank) {
          isBlank = sortData[index].end != sortData[index + 1].start;
          index++;
        }

        // Case end
        if (!isBlank && sortData[sortData.length - 1].end < endTime) {
          isBlank = true;
        }
        let blankData = [];
        if (isBlank) {
          if (sortData[0].start > startTime) {
            blankData.push({
              start: startTime,
              end: sortData[0].start,
            });
          }
          index = 0;
          while (index < sortData.length - 1) {
            if (sortData[index].end != sortData[index + 1].start)
              blankData.push({
                start: sortData[index].end,
                end: sortData[index + 1].start,
              });
            index++;
          }
          if (sortData[sortData.length - 1].end < endTime) {
            blankData.push({
              start: sortData[sortData.length - 1].end,
              end: endTime,
            });
          }
        }
        return {
          isBlank: isBlank,
          blankData: blankData,
        };
      } else {
        return true;
      } // END-IF-ELSE
    },

    /**
     * Get data from API FC816
     * @return {void}
     */
    async getSchedule() {
      try {
        const qs = (params) =>
          Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");

        // rest value
        this.isRejectAll = false;

        //TODO CREATE API LINK
        let apiUrl = "";
        switch (true) {
          case !isEmpty(this.$route.query.taskId) &&
            !isEmpty(this.$route.params.id) &&
            !isEmpty(this.planId):
          case !isEmpty(this.$route.params.id) && !isEmpty(this.planId):
            apiUrl = `${GET_SCHEDULE_API(this.planId)}`;
            break;
          default:
            apiUrl = `${GET_SCHEDULE_API(this.planId)}?${qs({
              user_id: this.userId,
              date: formatDateTime(this.selectedDate, yyyyMMdd1),
            })}`;
            break;
        }
        let mySchedule = await axios.get(apiUrl);
        let data = mySchedule.data;
        let status = mySchedule.status;
        let isMy = true;
        this.authorizeCheck = false;
        this.authorizeMessage = "";
        // Handle data
        switch (true) {
          case status === STATUS_NO_CONTENT && !this.isSelf():
            this.authorizeCalendar(
              this.$t("label.authorize_calendar.schedule_not_created")
            );
            this.calendarOptions.events = [];
            this.statuses = [];
            this.statusText = "";
            this.submitButton = {
              text: "",
              active: true,
              isReject: false,
            };
            return;
          case status === STATUS_NO_CONTENT && this.isSelf(): {
            this.startWorkTime = isEmpty(this.loginInfo.start_time) ? SystemConstant.DEFAULT_START_TIME : this.loginInfo.start_time;
            this.endWorkTime = isEmpty(this.loginInfo.end_time) ? SystemConstant.DEFAULT_END_TIME : this.loginInfo.end_time;
            if (this.isCurrentDay()) {
              const listSchedule = await axios.post(GET_SCHEDULE_API(), {
                start_break_time: SystemConstant.DEFAULT_START_BREAK_TIME,
                end_break_time: SystemConstant.DEFAULT_END_BREAK_TIME,
                date: formatDateTime(this.selectedDate, yyyyMMdd1),
                phase_type: SystemConstant.PHASE_TYPE_UNSUBMITTED,
              });
              data = listSchedule.data;
              isMy = false;
              if (this.showMonthlyList){
                this.showMonthlyList = false;
                this.showDrawerRight = false;
              }
              break;
            } else {
              this.calendarOptions.events = [];
              this.statuses = [];
              this.statusText = "未提出";
              this.canSubmit = false;
              this.canSubmitCancel = false;
              this.submitButton = {
                text: "提出",
                active: true,
                isReject: false,
              };
              this.gotoSelectedDate();
              return;
            }
          }
          default:
            break;
        }
        // SET DATA
        let startTime;
        let endTime;
        let start;
        let end;
        let endReal;
        let cost;
        let planId;
        let statusText;
        let submitButton;
        this.calendarOptions.events = [];
        this.inputComment = "";
        if (isMy) {
          let calendar = data.result[0];
          this.ownerName = calendar.user_name;
          this.selectedDate = formatDateTime(
            parseDateTime(calendar.date, yyyyMMdd1),
            yyyyMMdd2
          );
          this.canViewComment = !!calendar.can_view_comment;
          this.canApproveRemand = !!calendar.can_approve_remand;
          this.canSubmit = !!calendar.can_submit;
          this.canSubmitCancel = !!calendar.can_submit_cancel;
          this.userId = data.result[0].user_id;
          this.selectUser = this.members.find((x) => x.id === this.userId);
          this.isInDraft = !!calendar.is_in_draft;
          this.calendarOptions.events = calendar.schedule_tasks.map((x) => {
            let event = {
              ...x,
              title: x.name,
              start: formatDateTime(
                parseDateTime(
                  this.selectedDate + " " + x.start_time,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmmss2
              ),
              end: formatDateTime(
                parseDateTime(
                  this.selectedDate +
                    " " +
                    adjustMinEnd(x.start_time, x.end_time, HHmmss1, this.min), // adjustMinEnd HHmmss1
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmmss2
              ),
              end_real: formatDateTime(
                parseDateTime(
                  this.selectedDate + " " + x.end_time,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmmss2
              ),
              check: !!x.is_remand,
              showCheck: this.isSupervisor(),
              //className: x.is_need_output ? "outputTask" : "",
              className:
                this.isDbTask(x) && !this.isSplitTask(x) ? "dbTask" : "",
              color: this.getColor(x),
            };
            return event;
          });
          startTime = calendar.start_break_time;
          endTime = calendar.end_break_time;
          if (startTime && endTime) {
          start = formatDateTime(
            parseDateTime(
              this.selectedDate + " " + calendar.start_break_time,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          );
          end = formatDateTime(
            parseDateTime(
              this.selectedDate +
                " " +
                adjustMinEnd(
                  calendar.start_break_time,
                  calendar.end_break_time,
                  HHmmss1,
                  this.min
                ), // adjustMinEnd HHmmss1
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          );
          endReal = formatDateTime(
            parseDateTime(
              this.selectedDate + " " + calendar.end_break_time,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          );
          }
          if(startTime !== null){
            cost = this.diffTime(
              calendar.start_break_time,
              calendar.end_break_time
            );
          }
          else{
            cost = 0;
          }
          
          planId = calendar.id;
          calendar.statuses.forEach((statuses) => {
            if (statuses.issue_tasks && statuses.issue_tasks.length > 0) {
              statuses.issue_tasks.sort(
                (a, b) => Number(a.order_value) - Number(b.order_value)
              );
            }
          });
          this.statuses = calendar.statuses;
          statusText = this.getStatusText(calendar.statuses[calendar.statuses.length - 1]);
          submitButton = this.getSubmitButton(calendar.statuses[calendar.statuses.length - 1]);
          this.startWorkTime = calendar.start_time;
          this.endWorkTime = calendar.end_time;
        } else {
          startTime = SystemConstant.DEFAULT_START_BREAK_TIME;
          endTime = SystemConstant.DEFAULT_END_BREAK_TIME;
          this.startWorkTime = isEmpty(data.result.start_time) ? this.loginInfo.start_time : data.result.start_time;
          this.endWorkTime = isEmpty(data.result.end_time) ? this.loginInfo.end_time : data.result.end_time;
          start = parseDateTime(
            this.selectedDate + " " + SystemConstant.DEFAULT_START_BREAK_TIME,
            yyyyMMddHHmmss1
          );
          end = parseDateTime(
            this.selectedDate +
              " " +
              adjustMinEnd(
                SystemConstant.DEFAULT_START_BREAK_TIME,
                SystemConstant.DEFAULT_END_BREAK_TIME,
                HHmmss1,
                this.min
              ), // adjustMinEnd HHmmss1
            yyyyMMddHHmmss1
          );
          endReal = parseDateTime(
            this.selectedDate + " " + SystemConstant.DEFAULT_END_BREAK_TIME,
            yyyyMMddHHmmss1
          );
          if(startTime !== null){
          cost = this.diffTime(
            SystemConstant.DEFAULT_START_BREAK_TIME,
            SystemConstant.DEFAULT_END_BREAK_TIME
          );
          }
          else{
            cost = 0;
          }
          planId = data.result.id;
          this.statuses = data.result.statuses;
          this.canApproveRemand = !!data.result.can_approve_remand;
          this.canSubmit = !!data.result.can_submit;
          this.canSubmitCancel = !!data.result.can_submit_cancel;
          statusText = this.getStatusText({
            phase_id: data.result.statuses[this.statuses.length - 1].phase_id,
          });
          submitButton = this.getSubmitButton({
            phase_id: data.result.statuses[this.statuses.length - 1].phase_id,
          });
          this.canSubmit = true;
          this.canSubmitCancel = true;
        } // END-IF-ELSE

        // Break task
        let breakTask = {
            title: "休憩",
            start_time: startTime,
            end_time: endTime,
            start: null,
            end: null,
            end_real: null,
            color: COLOR_GRAY,
            check: false,
            showCheck: false,
            count: 1,
            cost: cost,
          };
        if(breakTask.start_time != null){
          breakTask.start = formatDateTime(start, yyyyMMddHHmm1);
          breakTask.end = formatDateTime(end, yyyyMMddHHmm1);
          breakTask.end_real = formatDateTime(endReal, yyyyMMddHHmm1);
        }
        
        this.planId = planId;

        this.startBreakTime = formatDateTime(breakTask.start, HHmm2);
        this.endBreakTime = formatDateTime(breakTask.end_real, HHmm2);
        if (+breakTask.cost * +breakTask.count !== 0)
          this.calendarOptions.events.push(breakTask);
        
        if(this.startBreakTime == null || breakTask.cost == 0) this.haveBreakTime = true;
        else this.haveBreakTime = false;

        if (this.showMonthlyList){
          this.showMonthlyList = false;
          this.showDrawerRight = false;
        }
        this.gotoSelectedDate();
        this.statusText = statusText;
        this.submitButton = submitButton;

        if (this.phaseId === SystemConstant.PHASE_ID_VERIFIED){
          await this.getNotification();
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC816, status);
      } // END-TRY-CATCH
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (item.schedule_id === this.planId && !!item.can_verified && !isEmptyNumber(item.status_notice_id)){
            this.listNotification.push(item);
          }        
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: item.status_notice_id,
            comment_notice_id: null,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    /**
     * Get data from API FC108
     * @return {void}
     */
    async getMembers() {
      try {
        const { data, status } = await axios.get(GET_MEMBERS_API());
        this.members = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC108, status);
      } // END-TRY-CATCH
    },

    /**
     * Handle toggle drawer right
     * @param {string} [typeList]
     * @return {void}
     */
    toggleDrawerRight: debounce_leading(function (typeList) {
      if (this.isSelf()) {
        if (typeList === "db-task") {
          this.showDrawerRight =
            this.showDrawerRight && this.showDbTasks ? false : true;
          this.showDbTasks = this.showDrawerRight ? true : false;
          if (this.showDbTasks) {
            this.$refs.dbList.getData();
          }
          this.showTodoList = false;
          this.showFavoriteList = false;
          this.showMonthlyList = false;
        } else if (typeList === "todo-task") {
          this.showDrawerRight =
            this.showDrawerRight && this.showTodoList ? false : true;
          this.showDbTasks = false;
          this.showFavoriteList = false;
          this.showTodoList = this.showDrawerRight ? true : false;
          this.showMonthlyList = false;
          if (this.showTodoList) {
            this.$refs.todoList.loadTodoListData();
          }
        } else if (typeList === "favorite-task") {
          this.showDrawerRight =
            this.showDrawerRight && this.showFavoriteList ? false : true;
          this.showDbTasks = false;
          this.showTodoList = false;
          this.showFavoriteList = this.showDrawerRight ? true : false;
          this.showMonthlyList = false;
          if (this.showFavoriteList) {
            this.$refs.favoriteList.loadFavoriteListData();
          }
        } else if (typeList === "monthly-task") {
          this.showDrawerRight =
            this.showDrawerRight && this.showMonthlyList ? false : true;
          this.showMonthlyList = this.showDrawerRight ? true : false;
          this.showDbTasks = false;
          this.showTodoList = false;
          this.showFavoriteList = false;
          if (this.showMonthlyList) {
            this.$refs.monthlyList.getData();
          }
        } 
        // END-IF-ELSE
      } else {
        return; // Nothing todo
      } // END-IF-ELSE
    }),

    /**
     * Check if item is DB task
     * @param {object} item
     * @return {boolean}
     */
    isDbTask(item) {
      return (
        isEmptyNumber(item.free_task_id) &&
        isEmptyNumber(item.actual_free_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision) &&
        this.isDbTaskByType(item)
      );
    },

    /**
     * Check if item is DB task (include irregular task)
     * @param {object} item
     * @return {boolean}
     */
     isAllDbTask(item) {
      return (
        isEmptyNumber(item.free_task_id) &&
        isEmptyNumber(item.actual_free_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision) 
      );
    },

    /**
     * Check if item is Free task
     * @param {object} item
     * @return {boolean}
     */
    isFreeTask(item) {
      return !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id);
    },

    /**
     * Check if item is Break task
     * @param {object} item
     * @return {boolean}
     */
    isBreakTask(item) {
      return item.isBreak;
    },

    /**
     * Check if item is DB task by type
     * @param {object} item
     * @return {boolean}
     */
    isDbTaskByType(item) {
      return (
        !item.task_type_id ||
        SystemConstant.DB_TASK_TYPE_LIST.includes(item.task_type_id)
      );
    },

    /**
     * Check if item is Beginner level DB task
     * @param {object} item
     * @return {boolean}
     */
    isBeginnerSkill(item) {
      return item.skill_level_id ? item.skill_level_id === 10 : null;
    },

    /**
     * Check if item is Expert level DB task
     * @param {object} item
     * @return {boolean}
     */
    isExpertSkill(item) {
      return item.skill_level_id ? item.skill_level_id == 30 : null;
    },

    /**
     * Check if item is Split task
     * @param {object} item
     * @return {boolean}
     */
    isSplitTask(item) {
      return (
        !isEmptyNumber(item.split_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision)
      );
    },

    /**
     * Get color for task
     * @param {object} item
     * @return {string}
     */
    getColor(item) {
      switch (true) {
        // case item.is_remand === 1:
        //   return "#002060";
        case !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id):
          return COLOR_FREE_TASK;
        case isEmptyNumber(item.free_task_id) && isEmptyNumber(item.actual_free_task_id) &&
          !isEmptyNumber(item.standard_task_id) &&
          !isEmptyNumber(item.standard_task_revision):
          if (!this.isDbTaskByType(item)) {
            return COLOR_GRAY;
          } else {
            return COLOR_DB_TASK;
          }
        default:
          throw "Color is undefined";
      } // END-SWITCH
    },

    /**
     * Get status text for item
     * @param {object} item
     * @return {string}
     */
    getStatusText(item) {
      this.phaseId = item.phase_id;
      this.calendarOptions.events = this.calendarOptions.events.map((e) => {
        let event = { ...e, ...{ disabled: !this.activeCheckBox() } };
        return event;
      });

      this.calendarOptions.editable =
        this.isSelf() && this.isNoSubmmit() && this.isCanSubmit();

      switch (item.phase_id) {
        case SystemConstant.PHASE_ID_UNSUBMITTED:
          return SystemConstant.PHASE_ID_UNSUBMITTED_TEXT;
        case SystemConstant.PHASE_ID_SUBMISSION_RESET:
          return SystemConstant.PHASE_ID_UNSUBMITTED_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          return SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
          return SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          return SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT;
        case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
          return SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_2_NEXT_REJECT:
          return SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT;
        case SystemConstant.PHASE_ID_VERIFIED:
          return SystemConstant.PHASE_ID_VERIFIED_TEXT;
        default:
          throw "Can not find the status label";
      } // END-SWITCH
    },

    /**
     * Get submit button
     * @param {object} item
     * @return {void}
     */
    getSubmitButton(item) {
      let button = {
        text: "",
        active: false,
        isReject: false,
      };

      if (this.isSelf()) {
        switch (item.phase_id) {
          case SystemConstant.PHASE_ID_UNSUBMITTED /* User can submit */:
          case SystemConstant.PHASE_ID_SUBMISSION_RESET /* User can resubmit */:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT /* User can resubmit */:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            button.text = this.$t("label.buttons.submission");
            button.active = !this.canSubmit;
            button.isReject = false;
            this.showDraftText = true;
            break;
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING /* User can cancel submit before 1st approver's confirmation*/:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET /* User can cancel submit before 1st approver's confirmation*/:
            button.text = this.$t("label.buttons.submission_reset");
            button.active = !this.canSubmitCancel;
            button.isReject = true;
            this.showDraftText = false;
            break;
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING /* User cannot cancel submit */:
            button.text = this.$t("label.buttons.submission_reset");
            button.active = !this.canSubmitCancel;
            button.isReject = true;
            this.showDraftText = false;
            break;
          case SystemConstant.PHASE_ID_VERIFIED /* User cannot cancel submit */:
            button.text = this.$t("label.buttons.submission");
            button.active = !this.canSubmit;
            button.isReject = false;
            this.showDraftText = true;
            break;
          default:
            break;
        } // END-SWITCH
      } else {
        if (this.isNoApprove()) {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED /* User can submit */:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET /* User can resubmit */:
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING /* User can cancel submit before 1st approver's confirmation*/:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET /* User can cancel submit before 1st approver's confirmation*/:
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING /* User cannot cancel submit */:
              button.text = this.$t("label.buttons.submission_reset");
              button.active = true;
              button.isReject = true;
              this.showDraftText = false;
              break;
            case SystemConstant.PHASE_ID_PRIMARY_REJECT /* User can resubmit */:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED /* User cannot cancel submit */:
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              break;
            default:
              break;
          } // END-SWITCH
          button.active = true;
        } else if (this.isFirstApprove()) {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED:
              button.text = this.$t("label.buttons.confirm");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
              {
                let returnedTask = this.calendarOptions.events.filter(
                  (x) => x.check
                );
                if (!isEmpty(returnedTask)) {
                  button = {
                    text: `${returnedTask.length}件`,
                    active: !this.canApproveRemand,
                    isReject: true,
                  };
                  this.showDraftText = false;
                } else {
                  button = {
                    text: this.$t("label.buttons.confirm"),
                    active: !this.canApproveRemand,
                    isReject: false,
                  };
                  this.showDraftText = true;
                } // END-IF-ELSE
              }
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              button.text = this.$t("label.buttons.confirm");
              button.isReject = false;
              button.active = !this.canApproveRemand;
              this.showDraftText = true;
              break;
            default:
              break;
          } // END-SWITCH
        } else {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED:
              button.text = this.$t("label.buttons.confirm");
              button.active = true;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              {
                let returnedTask = this.calendarOptions.events.filter(
                  (x) => x.check
                );
                if (!isEmpty(returnedTask)) {
                  button = {
                    text: `${returnedTask.length}件`,
                    // active: false,
                    active: !this.canApproveRemand,
                    isReject: true,
                  };
                  this.showDraftText = false;
                } else {
                  button = {
                    text: this.$t("label.buttons.confirm"),
                    // active: false,
                    active: !this.canApproveRemand,
                    isReject: false,
                  };
                  this.showDraftText = true;
                } // END-IF-ELSE
              }
              break;
            default:
              break;
          } // END-SWITCH
        } // END-SWITCH
      } // END-IF-ELSE

      return button;
    },

    /**
     * Check if user is self
     * @return {boolean}
     */
    isSelf() {
      return this.userId === this.user?.user_id;
    },

    /**user is suppervisor
     * Check if user is suppervisor
     * @return {boolean}
     */
    isSupervisor() {
      return !!(this.userId !== this.user?.user_id && this.canViewComment);
    },

    /**
     * Check subbmit permission
     * @return {boolean}
     */
    isNoSubmmit() {
      return (
        this.phaseId === SystemConstant.PHASE_ID_UNSUBMITTED ||
        this.phaseId === SystemConstant.PHASE_ID_SUBMISSION_RESET ||
        this.phaseId === SystemConstant.PHASE_ID_2_NEXT_REJECT ||
        this.phaseId === SystemConstant.PHASE_ID_PRIMARY_REJECT
      );
    },

    /**
     * Check first approve permission
     * @return {boolean}
     */
    isFirstApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_PRIMARY_APPROVAL
      );
    },

    /**
     * Check second approve permission
     * @return {boolean}
     */
    isSecondApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_SECONDARY_APPROVAL
      );
    },

    /**
     * Check no approve permission
     * @return {boolean}
     */
    isNoApprove() {
      return (
        this.loginInfo.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_NO_PERMISSION
      );
    },

    /**
     * Change date func
     * @return {void}
     */
    changeSelectedDate() {
      this.planId = "";
      let originalDate = "";
      if (!isEmpty(this.$route.query.date))
        originalDate = this.$route.query.date;
      if (isEmpty(originalDate) || this.selectedDate !== originalDate) {
        if (this.isSelf()) {
          this.$router.replace({
            path: "/schedule",
            query: { date: this.selectedDate },
          });
        } else {
          this.$router.replace({
            path: "/schedule",
            query: { userId: this.userId, date: this.selectedDate },
          });
        }
      }
      this.getSchedule();
    },
    /**
     * Handle change date event
     * @return {void}
     */
    changDate: debounce_leading(function () {
      this.changeSelectedDate();
    }),

    /**
     * Move selected date
     *  @param {boolean} [isNext]
     * @return {void}
     */
    moveSelectedDate(isNext = false) {
      const day = parseDateTime(this.selectedDate, yyyyMMdd2);
      let y = day.getFullYear(),
        m = day.getMonth(),
        d = isNext ? day.getDate() + 1 : day.getDate() - 1;
      this.selectedDate = formatDateTime(new Date(y, m, d), yyyyMMdd2);
      this.changeSelectedDate();
    },

    /**
     * Handle change members event
     * @return {void}
     */
    membersChange(e) {
      if (e.id && this.userId !== e.id) {
        this.$store.dispatch("common/setMember", { selectedMember: e });
        this.userId = e.id;
        this.planId = "";
        if (!this.isSelf()) {
          this.$router.replace({
            path: "/schedule",
            query: { userId: e.id, date: this.selectedDate },
          });
        } else {
          let draggableEl = document.getElementById("dragElements");
          new Draggable(draggableEl, {
            itemSelector: ".db-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".todo-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".favorite-db-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".favorite-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".monthly-task",
          });
          this.$router.replace({
            path: "/schedule",
            query: { date: this.selectedDate },
          });
        }
        this.getSchedule();
      }
    },

    /**
     * Check if before twelve
     * @return {boolean}
     */
    isBeforeTwelve() {
      let now = +formatDateTime(new Date(), yyyyMMdd1);
      let selected = +formatDateTime(
        parseDateTime(`${this.selectedDate}`, yyyyMMdd2),
        yyyyMMdd1
      );

      if (now > selected) {
        return false;
      } else {
        if (now === selected) {
          return +formatDateTime(new Date(), HHmm1) < 1200;
        } else {
          return true;
        } // END-IF-ELSE
      } // END-IF-ELSE
    },

    /**
     * Check if current day
     * @return {boolean}
     */
    isCurrentDay() {
      let now = +formatDateTime(new Date(), yyyyMMdd1);
      let selected = +formatDateTime(
        parseDateTime(`${this.selectedDate}`, yyyyMMdd2),
        yyyyMMdd1
      );

      return now <= selected;
    },

    /**
     * Check if after twelve
     * @return {boolean}
     */
    isAfterTwelveCurrentDay() {
      let now = +formatDateTime(new Date(), yyyyMMdd1);
      let selected = +formatDateTime(
        parseDateTime(`${this.selectedDate}`, yyyyMMdd2),
        yyyyMMdd1
      );

      // if (now < selected) {
      //   return true;
      // }
      if (now === selected) {
        return (
          this.phaseId === SystemConstant.PHASE_ID_UNSUBMITTED &&
          +formatDateTime(new Date(), HHmm1) >= 1200
        );
      }
      return false;
      // END-IF-ELSE
    },

    /**
     * validate submit button on comment modal
     * @return {boolean}
     */
    isPreValidation(){
      return (
        (this.blankTimeError.isBlank ||
          this.overLapTimeError ||
          this.diffWorkTimeError) &&
        this.isSelf() &&
        this.isNoSubmmit()
      );
    },

    /**
     * Check submit permisson
     * @return {boolean}
     */
    isCanSubmit() {
      return this.canSubmit;
    },

    /**
     * Check checkbox is active or not
     * @return {boolean}
     */
    activeCheckBox() {
      if (this.isFirstApprove()) {
        switch (this.phaseId) {
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
            return true;
          default:
            return false;
        } // END-SWITCH
      } else {
        return (
          this.isSecondApprove() &&
          this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
        );
      } // END-IF-ELSE
    },

    /**
     * Update break time
     * @param {object} breakTime
     * @return {void}
     */
    updateBreakTime(breakTime) {
      let t = this.calendarOptions.events.find(
        (x) => x.color === COLOR_GRAY && x.title === "休憩"
      );
      t.start_time = breakTime.start_break_time;
      t.end_time = breakTime.end_break_time;
      t.start = formatDateTime(
        parseDateTime(
          this.selectedDate + " " + breakTime.start_break_time,
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmm1
      );
      t.end = formatDateTime(
        parseDateTime(
          this.selectedDate +
            " " +
            adjustMinEnd(
              breakTime.start_break_time,
              breakTime.end_break_time,
              HHmmss1,
              this.min
            ),
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmm1
      );
      t.end_real = formatDateTime(
        parseDateTime(
          this.selectedDate + " " + breakTime.end_break_time,
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmm1
      );
      t.cost = this.diffTime(
        breakTime.start_break_time,
        breakTime.end_break_time
      );
      this.startBreakTime = formatDateTime(t.start, HHmm2);
      this.endBreakTime = formatDateTime(t.end_real, HHmm2);
    },

    /**
     * delete break time
     * @param {object} breakTime
     * @return {void}
     */
    deleteBreakTime(breakTime) {
      let t = this.calendarOptions.events = this.calendarOptions.events.filter(
      (x) => x.color !== COLOR_GRAY || x.title !== "休憩"
      );
      t.start_time = breakTime.start_break_time;
      t.end_time = breakTime.end_break_time; 
      t.name = "休憩";
      this.haveBreakTime = true;
    },

    /**
     * Edit free task in calendar
     * @return {void}
     */
    updateFreeTask(task) {
      let t = this.calendarOptions.events.find((x) => x.id === task.id);
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== task.id
      );
      t.cost = +task.cost;
      t.count = +task.count;
      t.name = task.name;
      t.title = task.name;
      t.start = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.start_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.end = formatDateTime(
        parseDateTime(
          this.selectedDate +
            " " +
            adjustMinEnd(task.start_time, task.end_time, HHmm2, this.min),
          yyyyMMddHHmm1
        ),
        yyyyMMddHHmmss2
      );
      t.end_real = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.end_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );

      t.start_time = formatDateTime(t.start, HHmm3);
      t.end_time = formatDateTime(t.end_real, HHmm3);
      //t.className = task.is_need_output ? "outputTask" : "";
      t.className = this.isDbTask(t) && !this.isSplitTask(t) ? "dbTask" : "";
      t.category_secondary_id = task.category_secondary_id;
      t.category_secondary_name = task.category_secondary_name;
      t.category_primary_id = task.category_primary_id;
      t.task_type_id = task.task_type_id;
      t.schedule_month_task_id = task.schedule_month_task_id;
      if (+task.is_split_task === 1){
        t.can_split = task.can_split;
        t.is_first_split_task = task.is_first_split_task;
        t.is_last_split_task = task.is_last_split_task;
        t.is_source_completed = task.is_source_completed;
        t.is_split_task = task.is_split_task;
        t.split_task_id = task.split_task_id;
        t.split_task_no = task.split_task_no;
        t.split_task_source_id = task.split_task_source_id;
      }
      this.calendarOptions.events.push(t);

      // delete split todo task
      if (task.changeTodoList){
        if (this.showTodoList) {
            this.$refs.todoList.loadTodoListData();
        }
      }

      // 同親のタスクも更新
      if (task.split_task_source_id){
        this.calendarOptions.events.map((x) => {
          if (+x.split_task_source_id === +task.split_task_source_id && +x.id !== +task.id){
            x.name = task.name;
            x.title = task.name;
          }
        });
      }

      this.callApiFC502(task);
    },

    /**
     * Add free task to calendar
     * @return {void}
     */
    registerFreeTask(task) {
      // let task = deepClone(tsk);
      // this.changeTimeFormat(task);
      let t = {};
      t.cost = +task.cost;
      t.count = +task.count;
      t.name = task.name;
      t.title = task.name;
      t.showCheck = false;
      t.check = false;
      t.free_task_id = task.free_task_id;
      t.favorite_task_id = task.favorite_task_id;
      t.id = task.id;
      t.color = this.getColor(t);
      t.start = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.start_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.end = formatDateTime(
        parseDateTime(
          this.selectedDate +
            " " +
            adjustMinEnd(task.start_time, task.end_time, HHmm2, this.min),
          yyyyMMddHHmm1
        ),
        yyyyMMddHHmmss2
      );
      t.end_real = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.end_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.start_time = formatDateTime(t.start, HHmm3);
      t.end_time = formatDateTime(t.end_real, HHmm3);
      //t.className = task.is_need_output ? "outputTask" : "";
      t.className = this.isDbTask(t) && !this.isSplitTask(t) ? "dbTask" : "";
      t.category_secondary_name = task.category_secondary_name;
      t.category_secondary_id = task.category_secondary_id;
      t.category_primary_id = task.category_primary_id;
      t.task_type_id = task.task_type_id;
      t.schedule_month_task_id = task.schedule_month_task_id;
      if (+task.is_split_task === 1){
        t.can_split = task.can_split;
        t.is_first_split_task = task.is_first_split_task;
        t.is_last_split_task = task.is_last_split_task;
        t.is_source_completed = task.is_source_completed;
        t.is_split_task = task.is_split_task;
        t.split_task_id = task.split_task_id;
        t.split_task_no = task.split_task_no;
        t.split_task_source_id = task.split_task_source_id;
      }
      this.calendarOptions.events.push(t);

      if (task.is_favorite){
        this.$refs.favoriteList?.registerFavoriteTask(task);
      }

      // delete split todo task
      if (+task.is_split_task === 1){
        if (this.showTodoList) {
            this.$refs.todoList.loadTodoListData();
        }
      }
    },

    /**
     * Copy free task to modal
     * @return {void}
     */
    duplicateFreeTask(task) {
      task.id = null;
      this.copyFreeData = {};
      this.copyFreeData = task;
      this.showFreeTask = !this.showFreeTask;
    },

    /**
     * Copy db task to modal
     * @return {void}
     */
    duplicateDbTask(task) {
      task.id = null;
      this.copyDbData = {};
      this.copyDbData = task;
      this.showDbTask = !this.showDbTask;
    },

    /**
     * Edit db task in calendar
     * @return {void}
     */
    editDbTask(task) {
      let t = this.calendarOptions.events.find((x) => x.id === task.id);
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== task.id
      );
      t.cost = +task.cost;
      t.count = +task.count;
      t.name = task.name;
      t.title = task.name;
      t.start = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.start_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.end = formatDateTime(
        parseDateTime(
          this.selectedDate +
            " " +
            adjustMinEnd(task.start_time, task.end_time, HHmm2, this.min),
          yyyyMMddHHmm1
        ),
        yyyyMMddHHmmss2
      );
      t.end_real = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.end_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.start_time = formatDateTime(t.start, HHmm3);
      t.end_time = formatDateTime(t.end_real, HHmm3);
      //t.className = task.is_need_output ? "outputTask" : "";
      t.split_task_id = task.split_task_id;
      t.standard_task_id = task.standard_task_id;
      t.standard_task_revision = task.standard_task_revision;
      //t.className = this.isDbTask(t) ? "dbTask" : "";
      t.className =
        this.isDbTask(t) &&
        (task.is_split_task === false || task.is_split_task === 0)
          ? "dbTask"
          : "";
      t.task_type_id = task.task_type_id;
      t.color = this.getColor(t);
      t.category_secondary_name = task.category_secondary_name;
      t.category_secondary_id = task.category_secondary_id;
      t.category_primary_id = task.category_primary_id;
      t.schedule_month_task_id = task.schedule_month_task_id;
      t.detail = task.detail;
      t.is_split_task = task.is_split_task || 0;
      t.skill_level_id = task.skill_level_id;
      this.calendarOptions.events.push(t);

      // delete split todo task
      if (task.changeTodoList){
        if (this.showTodoList) {
            this.$refs.todoList.loadTodoListData();
        }
      }

      this.callApiFC502(task);
    },

    /**
     * Add db task to calendar
     * @return {void}
     */
    registerDbTask(task) {
      let t = {};
      t.cost = +task.cost;
      t.count = +task.count;
      t.unit = task.unit;
      t.name = task.name;
      t.title = task.name;
      t.showCheck = false;
      t.check = false;
      t.free_task_id = null;
      t.actual_free_task_id = null;
      t.id = task.id;
      t.standard_task_id = task.standard_task_id;
      t.standard_task_revision = task.standard_task_revision;
      t.split_task_id = task.split_task_id;
      t.task_type_id = task.task_type_id;
      t.color = this.getColor(t);
      t.start = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.start_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.end = formatDateTime(
        parseDateTime(
          this.selectedDate +
            " " +
            adjustMinEnd(task.start_time, task.end_time, HHmm2, this.min),
          yyyyMMddHHmm1
        ),
        yyyyMMddHHmmss2
      );
      t.end_real = formatDateTime(
        parseDateTime(this.selectedDate + " " + task.end_time, yyyyMMddHHmm1),
        yyyyMMddHHmmss2
      );
      t.start_time = formatDateTime(t.start, HHmm3);
      t.end_time = formatDateTime(t.end_real, HHmm3);
      t.className =
        this.isDbTask(t) &&
        (task.is_split_task === false || task.is_split_task === 0)
          ? "dbTask"
          : "";
      t.category_secondary_name = task.category_secondary_name;
      t.category_secondary_id = task.category_secondary_id;
      t.category_primary_id = task.category_primary_id;
      t.schedule_month_task_id = task.schedule_month_task_id;
      t.detail = task.detail;
      t.is_split_task = task.is_split_task || 0;
      t.skill_level_id = task.skill_level_id;
      this.calendarOptions.events.push(t);
    },

    /**
     * Get duration time
     */
    diffTime(stime, etime) {
      let shour = stime.substr(0, 2);
      let sminute = stime.substr(2, 2);
      let ehour = etime.substr(0, 2);
      let eminute = etime.substr(2, 2);
      return ehour * 60 + parseInt(eminute) - (shour * 60 + parseInt(sminute));
    },

    /**
     * Check status is reject or not
     * @return {boolean}
     */
    isRejectStatus() {
      if (this.isFirstApprove())
        return (
          this.phaseId === SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET ||
          this.phaseId === SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING
        );
      if (this.isSecondApprove())
        return (
          this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
        );
      return false;
    },

    /**
     * Check close Notification
     * @return {boolean}
     */
    closeNotification() {
      this.showNotification = false;
    },

    /**
     * Check show issue status in schedule
     * @return {boolean}
     */
    isShowNotification() {
      const remandIdList = [
        SystemConstant.PHASE_ID_PRIMARY_REJECT,
        SystemConstant.PHASE_ID_2_NEXT_REJECT,
      ];
      return this.statuses.length > 0 && remandIdList.includes(this.phaseId);
    },

    /**
     * Check show task from notice
     * @return {void}
     */
    showTaskFromNotice() {
      let taskId = this.$route.query.taskId;
      if (taskId && !isEmpty(this.calendarOptions.events)) {
        let event = this.calendarOptions.events.find((x) => +x.id === +taskId);
        if (event) {
          this.handleEventClick({
            event: {
              id: event.id,
              extendedProps: event,
            },
          });
        } else {
          this.$alert("タスクが見つかりません。");
        }
      }
    },

    /**
     * Check show notice status from notice
     * @return {void}
     */
    showNotificationFromNotice() {
      this.showNotification =
        this.$route.query.fromNotice && +this.$route.query.fromNotice === 1
          ? true
          : this.isShowNotification();
    },

    /**
     * Mark as read comment notice
     * @return {void}
     */
    async callApiFC502(task) {
      if (!this.getUnReadList || this.getUnReadList.length === 0) return;

      const commentsList = task.comments || false;
      if (!commentsList) return;

      const managerCommentList =
        commentsList.filter((item) => {
          const itemMarkRead =
            this.getUnReadList.find(
              (notice) => notice.comment_notice_id === item.id
            ) || false;
          const isManagerComment = item.user_id === this.loginInfo.manage_id;
          const isSelfSubmit = this.isSelf();
          return itemMarkRead && isManagerComment && isSelfSubmit;
        }) || [];
      if (managerCommentList.length === 0) return;

      let notices = [];
      managerCommentList.forEach((item) => {
        notices.push({
          status_notice_id: null,
          comment_notice_id: item.id || null,
          is_verified: 1,
        });
      });

      // call Api FC-502 for mark read
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    adjustTasks(events, selectedDate, minSlot, slotMaxTime = "24:00:00") {
      const getAdjustStart = (end, min, slotMaxTime = "24:00:00") => {
        const endDate = formatDateTime(
          parseDateTime(end, yyyyMMddHHmmss2),
          yyyyMMdd2
        );
        const startTime =
          slotMaxTime === "24:00:00"
            ? `23:${this.timeToStrTime(Math.floor(60 - min))}:00`
            : formatDateTime(
                new Date(
                  new Date(`2020-01-01 ${slotMaxTime}`).getTime() -
                    min * 60 * 1000
                ),
                HHmmss2
              );
        return `${endDate} ${startTime}`;
      };
      const getFormat = (dateTimeString) => {
        switch (dateTimeString.length) {
          case 19:
            return yyyyMMddHHmmss2;
          case 16:
            return yyyyMMddHHmm1;
          default:
            throw `format errors ${dateTimeString}`;
        }
      };
      events.forEach((item) => {
        let endRealDate = parseDateTime(
          item.end_real,
          getFormat(item.end_real)
        );

        item.start_real = formatDateTime(
          parseDateTime(selectedDate + " " + item.start_time, yyyyMMddHHmmss1),
          yyyyMMddHHmmss2
        );
        // check min
        const min = item.cost * item.count < 30 ? minSlot : minSlot * 1.65;
        const tomorrowTime =
          Date.parse(`${formatDateTime(endRealDate, yyyyMMdd2)}T00:00:00`) +
          24 * 60 * 60 * 1000;
        let compareStartRealTime =
          parseDateTime(item.start_real, yyyyMMddHHmmss2).getTime() +
          min * 60 * 1000;
        const endRealTime = endRealDate.getTime();
        const isAdjustEnd = compareStartRealTime > tomorrowTime;
        const compareStartHHmmss2 = formatDateTime(
          new Date(compareStartRealTime),
          HHmmss2
        );

        if (
          isAdjustEnd &&
          item.end_time > 230000 &&
          slotMaxTime === "24:00:00"
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} 23:59:59`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (
          compareStartRealTime > endRealTime &&
          compareStartHHmmss2 > slotMaxTime
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} ${slotMaxTime}`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (compareStartRealTime > endRealTime) {
          compareStartRealTime =
            compareStartRealTime >= tomorrowTime
              ? tomorrowTime - 1000
              : compareStartRealTime;
          item.end = formatDateTime(compareStartRealTime, yyyyMMddHHmmss2);
        }
      });
    },

    /**
     * Set time grid length
     * @return {void}
     */
    setTimeGridLength(slotDuration, slotMinTime, slotMaxTime) {
      this.timeGridLength = this.getTimeGridSlotByDuration(
        slotDuration,
        slotMinTime,
        slotMaxTime
      );
    },

    async apiConfirmedNotice(notices) {
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    async confirmNotice() {
      // Confirm this notice
      if (
        this.$route.query.is_verified &&
        this.$route.query.is_verified === "1"
      ) {
        await this.apiConfirmedNotice([
          {
            status_notice_id: this.$route.query.status_notice_id || null,
            comment_notice_id: this.$route.query.comment_notice_id || null,
            is_verified: 1,
          },
        ]);
        this.$store.dispatch("notification/setCount", this.getCount);
      }
    },

    /**
     * 就業時間変更Modal
     * @return {void}
     */
     changeWorkingTime() {
      // インターンとアルバイトのみ変更できる
      if (!this.isInternOrPartTime()) return;
      this.showWorkingTime = !this.showWorkingTime;
    },

    /**
     * 就業時間変更後
     * @param {object} workingTime 
     */
    updateWorkingTime(workingTime) {
      this.startWorkTime = workingTime.start_time;
      this.endWorkTime = workingTime.end_time;
    },

    /**
     * Check if user is intern or parttime
     * @return {boolean}
     */
     isInternOrPartTime() {
      return this.loginInfo?.position_id == 100 || this.loginInfo?.position_id == 200;
    },
    /**
     * 就業時間：開始時間の表示
     */
     dspStartWorkTime() {
      if (isEmpty(this.startWorkTime)) this.startWorkTime = SystemConstant.DEFAULT_START_TIME;
      return formatTime(this.startWorkTime);
    },
    /**
     * 就業時間：終了時間の表示
     */
    dspEndWorkTime() {
      if (isEmpty(this.endWorkTime)) this.endWorkTime = SystemConstant.DEFAULT_END_TIME;
      return formatTime(this.endWorkTime);
    },

    /**
     * Handle delete task event
     * @return {void}
     */
     async bulkDeleteTasks() {
      let selectedTask = this.calendarOptions.events.filter(
              (x) => x.color === COLOR_SELECT_TASK
            );
      let selectedIds = selectedTask.map((x) => {
              return {
                id: x.id,
              };
            });
      // 分割タスクチェック
      let hasSplitTask = selectedTask.find(
                (x) => +x.is_split_task === 1
              );
      if (selectedIds.length > 0){
        this.$confirm(
          selectedIds.length + '件の' + 
          (hasSplitTask
            ? `${this.$t(
                "label.confirm.delete_task"
              )} \r\n分割タスクがあるのでこのタスクは中断となり、生産性が評価できません。`
            : this.$t("label.confirm.delete_task")),
          {
            width: hasSplitTask ? "720px" : "500px",
            buttons: {
              ok: {
                text: this.$t("label.buttons.delete"),
                type: "primary",
              },
              cancel: {
                text: this.$t("label.buttons.cancel"),
                type: "ligth",
              },
            },
            rtl: true,
          }
        )
          .then(async () => {
            try {
              let params = {
                  schedule_tasks: selectedIds,
                };
              await axios.delete(
                GET_SCHEDULE_TASK_API(this.planId),
                {
                  data: params,
                }
              );

              // calendarで削除
              this.calendarOptions.events = this.calendarOptions.events.filter(
                (x) => x.color !== COLOR_SELECT_TASK
              );

              if (hasSplitTask && this.showTodoList){
                this.$refs.todoList.loadTodoListData();
              }
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC803, status);
            }
          })
          .catch(() => {});
      }
      
    },

    /**
     * 右クリックのメニュー非表示
     */
    cancelContextMenu(){
      this.showMenu = false;
    },

    /**
     * Handle archive task event
     * @return {void}
     */
     bulkArchiveTasks: debounce_leading(async function () {
      let selectedTask = this.calendarOptions.events.filter(
              (x) => x.color === COLOR_SELECT_TASK
            );
      let params = [];
      selectedTask.forEach((task) => {
        let param = {
            name: task.name,
            description: task.description,
            cost: task.cost,
            count: task.count,
            category_secondary_id: task.category_secondary_id,
            task_type_id: task.task_type_id,
            schedule_month_task_id: task.schedule_month_task_id,
            is_need_output: task.is_need_output,
            output_detail: task.output_detail,
            is_favorite: task.is_favorite,
            // tags: task.tags,
            comments: task.comments,
            schedule_task_id: task.id,
            detail: task.detail,
          };
        params.push(param);
      });
      if (params.length > 0) {
        this.$confirm(
          params.length + '件の' + this.$t("label.confirm.archive_task"),
          {
            buttons: {
              ok: {
                text: this.$t("label.buttons.archive"),
                type: "primary",
              },
              cancel: {
                text: this.$t("label.buttons.cancel"),
                type: "ligth",
              },
            },
            rtl: true,
          }
        )
          .then(async () => {
            try {
              await axios.post(TODO_TASK_API(), {tasks: params});

              // calendarで削除
              this.calendarOptions.events = this.calendarOptions.events.filter(
                    (x) => x.color !== COLOR_SELECT_TASK
                  );

              if (this.showTodoList) {
                this.$refs.todoList.loadTodoListData();
              }
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC808, status);
            }
          })
          .catch(() => {});
      }
    }),
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo", "isScheduleExpand"]),
    ...mapGetters("notification", ["getCount", "getUnReadList"]),
    /**
     * format css print page
     * @return {object}
     */
    cssProps() {
      if (this.timeGridLength === 0) return;

      const unitPrint = "mm";
      const heightFcTimegridSlotDuration30 =
        (CALENDAR_PRINT_LENGHT_MM + SLOT_30_SCALE[this.timeGridLength]) /
          this.timeGridLength +
        unitPrint;
      const heightFcTimegridSlotDuration60 =
        (CALENDAR_PRINT_LENGHT_MM + SLOT_60_SCALE[this.timeGridLength]) /
          this.timeGridLength +
        unitPrint;
      let heightFcTimegridSlotDurationDefault =
        CALENDAR_PRINT_LENGHT_MM / (13 * 4) + unitPrint;

      if (
        (this.calendarOptions.slotDuration === "00:05:00" ||
          this.calendarOptions.slotDuration === "00:15:00") &&
        this.timeGridLength > 13 * 4
      ) {
        heightFcTimegridSlotDurationDefault =
          (CALENDAR_PRINT_LENGHT_MM * 2 +
            DEFAULT_SCALE[this.timeGridLength / 4]) /
            this.timeGridLength +
          unitPrint;
      }

      return {
        "--height-fc-timegrid-slot-duration-default":
          heightFcTimegridSlotDurationDefault,
        "--height-fc-timegrid-slot-duration-30": heightFcTimegridSlotDuration30,
        "--height-fc-timegrid-slot-duration-60": heightFcTimegridSlotDuration60,
      };
    },
    checkedItems() {
      return this.calendarOptions.events.filter((x) => {
        return x.check;
      });
    },

    /**
     * format date
     * @return {string} yyyy年MM月dd日
     */
    formatSelectedDate() {
      // let date = parseDateTime(this.selectedDate, yyyyMMdd2);
      // let dayOfWeek = getDayOfWeek(date);
      // return `${formatDateTime(date, yyyyMMdd3)}(${dayOfWeek})`;
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },

    /**
     * Get total time
     * @return {object}
     */
    total() {
      let total = this.calendarOptions.events
        .filter((x) => x.id)
        .reduce(function (t, e) {
          return t + e.cost * e.count;
        }, 0);
      return {
        hours: Math.trunc(total / 60),
        minutes: total % 60 < 10 ? `0${total % 60}` : total % 60,
      };
    },

    /**
     * Get working time
     * @return {string}
     */
    workingTime() {
      if (
        isEmpty(this.startWorkTime) ||
        isEmpty(this.endWorkTime) ||
        isEmpty(this.endBreakTime) ||
        isEmpty(this.startBreakTime)
      )
        return `0h0m`;
      let workTime =
        toMinute(this.endWorkTime.replace(":", "")) -
        toMinute(this.startWorkTime.replace(":", ""));
      let breakTime;
      if (
        this.haveBreakTime ||
        +`${this.startBreakTime.replace(":", "")}00` >=
          +this.endWorkTime.replace(":", "") ||
        +`${this.endBreakTime.replace(":", "")}00` <=
          +this.startWorkTime.replace(":", "")
      ) {
        breakTime = 60;
      } else {
        let startBTime = Math.max(
          +this.startWorkTime.replace(":", ""),
          +`${this.startBreakTime.replace(":", "")}00`
        );
        let endBTime = Math.min(
          +this.endWorkTime.replace(":", ""),
          +`${this.endBreakTime.replace(":", "")}00`
        );

        breakTime =
          toMinute(`${endBTime}`.padStart(6, "0")) -
          toMinute(`${startBTime}`.padStart(6, "0"));
      }

      let totalTime = workTime - breakTime;
      if (totalTime < 0){
        totalTime = 0;
      }
      let minute = totalTime % 60 < 10 ? `0${totalTime % 60}` : totalTime % 60;
      return `${Math.trunc(totalTime / 60)}h${minute}m`;
    },

    /**
     * Check if different working time error
     * @return {boolean}
     */
    diffWorkTimeError() {
      let workingTime =
        !isEmpty(this.startWorkTime) && !isEmpty(this.endWorkTime)
          ? toMinute(this.endWorkTime.replace(":", "")) -
            toMinute(this.startWorkTime.replace(":", ""))
          : 0;

      if (workingTime === 0) return false;
      let breakTime;
      if (
        isEmpty(this.startBreakTime) ||
        isEmpty(this.endBreakTime) ||
        +`${this.startBreakTime.replace(":", "")}00` >=
          +this.endWorkTime.replace(":", "") ||
        +`${this.endBreakTime.replace(":", "")}00` <=
          +this.startWorkTime.replace(":", "")
      ) {
        breakTime = 0;
      } else {
        let startBTime = Math.max(
          +this.startWorkTime.replace(":", ""),
          +`${this.startBreakTime.replace(":", "")}00`
        );
        let endBTime = Math.min(
          +this.endWorkTime.replace(":", ""),
          +`${this.endBreakTime.replace(":", "")}00`
        );

        breakTime =
          toMinute(`${endBTime}`.padStart(6, "0")) -
          toMinute(`${startBTime}`.padStart(6, "0"));
      }
      let planTime = +this.total.hours * 60 + +this.total.minutes;

      return planTime < workingTime - breakTime;
    },

    /**
     * Check if blank time error
     * @return {boolean}
     */
    blankTimeError() {
      return this.isBlankTime();
    },

    /**
     * Check if align blank time error
     * @return {boolean}
     */
    alignBlankTimeError() {
      return this.isAlignBlankTime();
    },

    /**
     * Check if overlap time error
     * @return {boolean}
     */
    overLapTimeError() {
      return isOverLap(this.calendarOptions.events);
    },

    /**
     * Check if has Suppervisor
     * @return {boolean}
     */
    hasSupervisor() {
      if (this.isSelf()) {
        return !isEmpty(this.loginInfo?.manage_id);
      } else return true;
    },

    /**
     * pre validation
     * @return {boolean}
     */
    preValidation() {
      return (
        (this.blankTimeError.isBlank ||
          this.overLapTimeError ||
          this.diffWorkTimeError) &&
        this.isSelf() &&
        this.isNoSubmmit()
      );
    },

    /**
     * Check if is reject text
     * @return {boolean}
     */
    isRejectText() {
      return (
        this.statusText === SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT ||
        this.statusText === SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT
      );
    },

    /**
     * Check if disable overide
     * @return {boolean}
     */
    disableOveride() {
      return (
        this.statusText ===
          SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT ||
        this.statusText ===
          SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING_TEXT ||
        this.statusText === SystemConstant.PHASE_ID_VERIFIED_TEXT
      );
    },

    isMobile() {
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
    },

    /**
     * Set option print Calendar
     * @return {object}
     */
    printCalendarOptions() {
      if (this.isMobile) {
        return {};
      }
      let printCalendarOptions = deepClone(this.calendarOptions);
      this.changeSlotMinMaxModePrint(printCalendarOptions);
      this.setTimeGridLength(
        printCalendarOptions.slotDuration,
        printCalendarOptions.slotMinTime,
        printCalendarOptions.slotMaxTime
      );

      const minSlot = this.getMinSlotByDurationModePrint(
        printCalendarOptions.slotDuration,
        this.timeGridLength
      );
      this.adjustTasks(
        printCalendarOptions.events,
        this.selectedDate,
        minSlot,
        printCalendarOptions.slotMaxTime
      );

      return {
        ...printCalendarOptions,
        ...{
          slotDuration: this.getSlotDuration(printCalendarOptions.slotDuration),
        },
      };
    },
  },

  watch: {
    isScheduleExpand(val) {
      this.expand = val;
    },
    showDrawerRight: {
      handler: function () {
        this.$nextTick(() => {
          if (this.showDrawerRight) {
            let custom = document.querySelector(
              ".position-custom .v-snack__wrapper"
            );
            if (custom) custom.style.setProperty("right", "500px");
          } else {
            let custom = document.querySelector(
              ".position-custom .v-snack__wrapper"
            );
            if (custom) custom.style.setProperty("right", "100px");
          }
          let fullCalendarHeader = document.querySelector('.fullcalendar-itokuro .fc-col-header') || null
          fullCalendarHeader && (fullCalendarHeader.style.width = '100%')
          let fullCalendarBody = document.querySelector('.fullcalendar-itokuro .fc-timegrid-body') || null
          fullCalendarBody && (fullCalendarBody.style.width = '100%')
        });
      },
    },
    statuses() {
      this.showNotification = this.isShowNotification();
    },
    "calendarOptions.events": {
      deep: true,
      handler(val) {
        this.adjustTasks(val, this.selectedDate, this.min, "24:00:00");
        this.changeSlotMinMax(this.calendarOptions, val, this.startWorkTime, this.endWorkTime);
      },
    },
  },

  created() {
    this.$root.$refs.scheduleComponent = this;
  },

  async mounted() {
    await this.confirmNotice();
    this.userId = this.$route.query.userId || this.user?.user_id;
    this.planId = this.$route.params.id || null;
    if (!isEmpty(this.$route.query.date))
      this.selectedDate = this.$route.query.date;

    if (this.isSelf()) {
      if (!this.$vuetify.breakpoint.mobile) {
        let draggableEl = document.getElementById("dragElements");
        new Draggable(draggableEl, {
          itemSelector: ".db-task",
        });
        new Draggable(draggableEl, {
          itemSelector: ".todo-task",
        });
        new Draggable(draggableEl, {
          itemSelector: ".favorite-db-task",
        });
        new Draggable(draggableEl, {
          itemSelector: ".favorite-task",
        });
        new Draggable(draggableEl, {
            itemSelector: ".monthly-task",
        });
      } // END-IF
    } // END-IF
    this.calendarOptions.slotDuration = Cookies.get(
      "slotDurationItem" + this.user?.user_id
    )
      ? Cookies.get("slotDurationItem" + this.user?.user_id)
      : this.slotDurationItems[1].value;
    this.preSlotDuration = this.calendarOptions.slotDuration;
    //this.min = (this.calendarOptions.slotDuration === "00:30:00" || this.calendarOptions.slotDuration === "00:60:00") ? 15 : 5;
    if (this.calendarOptions.slotDuration === "00:30:00") {
      this.min = 15;
    } else if (this.calendarOptions.slotDuration === "00:60:00") {
      this.min = 18;
    } else {
      this.min = 5;
    }
    await this.getMembers();
    await this.getSchedule();
    this.selectUser = this.members.find((x) => x.id === this.userId);
    const userStore = { id: this.userId };
    this.$store.dispatch("common/setMember", { selectedMember: userStore });

    this.showTaskFromNotice();
    this.showNotificationFromNotice();
  },

  destroyed() {
    this.$store.dispatch("common/isScheduleExpand", 0);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_calendar.scss";
.time-break {
  word-break: break-all;
}
.time-text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.dbText {
  color: #1e90ff;
}
.normalText {
  color: #222222;
}
.whiteText {
  color: white;
}
.select {
  width: 100px;
}
.v-text-field.v-text-field--solo {
  .v-input__control {
    min-height: 36px !important;
  }
}

.v-center {
  display: flex;
  align-items: center;
}

.sub-hours {
  font-size: 0.8rem;
}

.content-row {
  display: flex;
}

.content-calendar {
  width: 70%;
  padding-left: 0;
  .v-breadcrumbs {
    padding-left: 0;
  }
  .v-breadcrumbs li:nth-child(2n) {
    padding: 0;
  }
}
.my-column-full {
  width: 100% !important;
}
.content-db-list {
  width: 30%;
  border-left: 1px solid #e1e1e1;
}
.content-db-list > .row {
  height: 100%;
  margin: 0;
  padding-bottom: 0;
}
.content-db-list #dragElements {
  padding: 0;
}
.is-panel-collapsed {
  .content-calendar {
    width: 97%;
  }

  .content-db-list {
    width: 3%;
  }
}
.flow-root {
  display: flow-root !important;
}

.dialogTest{
  position: absolute;
  top: 0;
  bottom: 0;
}

.outputTask:before {
  background-color: #222222;
  display: block;
  content: " ";
  position: absolute;
  height: 100%;
  width: 8px;
  border-radius: 3px 0 0 3px;
  top: 0;
  left: -1px;
}
.outputTask {
  .fc-event-main {
    margin-left: 8px;
  }
}
.v-tabs {
  a {
    font-weight: 700;
  }
}
.text-large {
  font-size: 1rem;
  font-weight: 900;
}
.reject-text {
  color: #dc143c !important;
}

.header-items button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.header-items button:disabled {
  box-shadow: none;
}
.viewCalendar {
  display: flex;
  .viewCalendar {
    .fc-view-harness {
      .fc-event-main {
        .row {
          .col {
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" src="@/assets/scss/_calendar.scss" scoped></style>
<style lang="scss" scoped>
::v-deep .v-input__append-outer {
  margin-top: 0 !important;
}
.flatbtn {
  box-shadow: none !important;
  background-color: unset !important;
}

.name-detail {
  word-break: break-word;
}

.time-text-margin {
  margin-top: -0.2rem;
}

.print-calendar {
  ::v-deep.fc-timegrid-event-harness {
    .fc-timegrid-event {
      overflow: hidden;
      min-width: 20px;
    }
  }
  ::v-deep .fc-timegrid-event-harness:last-child {
    .fc-timegrid-event {
      min-width: unset !important;
    }
  }
  .__task-content {
    padding-bottom: 0px;
    .__task-info {
      min-width: 10px;
    }
    .__remand {
      margin-right: 2px;
      height: 28px;
    }
    .__checkbox {
      height: 28px;
    }
    .task-time {
      min-width: 20px;
      overflow: hidden;
      flex-shrink: 1;
      // line-height: 28px;
    }
  }
}
// Config print
$--font-size-default: 0.7em;
$--font-size-duration-30: 0.7em;
$--font-size-duration-60: 0.7em;

.printCalendar {
  height: 100%;
}

// Print for duration 5', 15'
.fullcalendar-itokuro--five-min,
.fullcalendar-itokuro--fifteen-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-default;
    }
    .fc-scroller-harness.fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-default;
        height: var(--height-fc-timegrid-slot-duration-default);
      }
    }
  }
}

// Print for duration 30'
.fullcalendar-itokuro--thirty-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-duration-30;
    }
    .fc-scroller-harness,
    .fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-duration-30;
        height: var(--height-fc-timegrid-slot-duration-30);
      }
    }
  }
}

// Print for duration 60'
.fullcalendar-itokuro--sixty-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-duration-60;
    }
    .fc-scroller-harness.fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-duration-60;
        height: var(--height-fc-timegrid-slot-duration-60);
      }
    }
  }
}

.working-time-custom ::v-deep .v-input {
  width: 80px !important;
}
</style>
