<template>
  <v-container fill-height fluid class="pa-0">
    <v-row class="align-center">
      <v-col cols="4" md="8">
        <v-btn
          class="my-2 mr-2"
          tile
          depressed
          v-if="isMaster()"
          @click="newTask()"
          color="primary"
        >
          登録
        </v-btn>

        <v-btn
          outlined
          v-if="checkMaster && !$vuetify.breakpoint.mobile"
          @click="importCsv()"
          :loading="isSelecting"
          class="my-2 mr-2"
        >
          <v-icon small dark class="mr-2">$csvimport</v-icon>
          CSVインポート
        </v-btn>
        <input
          v-if="checkMaster"
          ref="uploader"
          class="d-none"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="fileChanged"
        />

        <v-btn
          outlined
          class="my-2 mr-2"
          @click="exportCsv()"
          v-if="checkMaster && !$vuetify.breakpoint.mobile"
        >
          <v-icon small dark class="mr-2">$csvexport</v-icon>
          CSVエクスポート
        </v-btn>
      </v-col>
      <v-col cols="8" sm="6" md="4" class="text-right">
        <v-btn elevation="2" @click="showSearchBox()">
          タスクを検索
          <v-icon right small>$search</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="noData">
      <v-col col="12" class="text-center">
        検索条件に一致するタスクは見つかりませんでした。再度検索してみてください。
      </v-col>
    </v-row>

    <v-row v-else class="mt-2">
      <v-col cols="12" class="contentTable height-custom">
        <v-data-table
          :headers="headers"
          class="fix-layout h-100"
          mobile-breakpoint="0"
          fixed-header
          height="100%"
          :items="dbTaskList"
          :hide-default-footer="true"
          :key="anIncreasingNumber"
          disable-pagination
          v-sort-table-header="{
            onEnd: sortTheHeadersAndUpdateTheKey,
            allowChangeOrder: allowChangeOrder,
            length: headers.length,
          }"
          v-resize-table-row="{ allowChangeGap: allowChangeGap, threeDot: [] }"
          @dblclick:row="showDetail"
          @click:row="$vuetify.breakpoint.mobile && gotoTasksInMobile($event)"
        >
          <template v-slot:[`header.no`]="{}"> No </template>
          <template v-slot:[`header.categories`]="{}">
            <v-row>
              <v-col cols="6">大カテゴリ</v-col>
              <v-col class="" cols="6">小カテゴリ</v-col>
            </v-row>
          </template>
          <template v-slot:[`header.taskName`]="{}"> タスク名 </template>
          <template v-slot:[`header.skillLevels`]="{}">
            <v-row>
              <v-col cols="12 pb-0" class="text-center"> 標準工数(分) </v-col>
              <v-col cols="4">ビギナー</v-col>
              <v-col cols="4">スタンダード</v-col>
              <v-col class="" cols="4">エキスパート</v-col>
            </v-row>
          </template>
          <template v-slot:[`header.taskTypeName`]="{}">
            <v-row class="text-center">
              <v-col>{{ labelType }}</v-col>
            </v-row>
          </template>
          <template v-slot:[`header.taskUsual`]="{}">定常/非定常</template>
          <template v-slot:[`header.taskTiming`]="{}">実施頻度</template>
          <template v-slot:[`header.order`]="{}">
            <SortSetting
              v-if="!$vuetify.breakpoint.mobile"
              @allowChangeOrder="allowChangeOrder = true"
              @allowChangeGap="allowChangeGap = true"
            ></SortSetting>
          </template>

          <template v-slot:[`item.no`]="{ index }">{{ index + 1 }}</template>
          <template v-slot:[`item.categories`]="{ item }">
            <v-row class="align-center text-left">
              <v-col cols="6">{{ item.category_primary_name }}</v-col>
              <v-col cols="6">{{ item.category_secondary_name }}</v-col>
            </v-row>
          </template>
          <template v-slot:[`item.taskName`]="{ item }">
            <div>{{ item.name }}</div>
          </template>
          <template v-slot:[`item.taskTypeName`]="{ item }">
            <div class="text-center">{{ item.task_type_name || "-" }}</div>
          </template>
          <template v-slot:[`item.taskUsual`]="{ item }">
            {{ item.task_usual ? "定常" : "非定常" }}
          </template>
          <template v-slot:[`item.skillLevels`]="{ item }">
            <v-row class="align-center text-left">
              <v-col cols="4">{{ +item.cost_beginner || "-" }}</v-col>
              <v-col cols="4">{{ +item.cost || "-" }}</v-col>
              <v-col cols="4">{{ +item.cost_expert || "-" }}</v-col></v-row
            >
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <div>{{ item.unit }}</div>
          </template>
          <template v-slot:[`item.taskTiming`]="{ item }">
            <div>{{ item.task_timing_name }}</div>
          </template>
          <template v-slot:[`item.output`]="{ item }">
            <v-row class="align-center text-left">
              <v-col cols="3">{{
                !!item.is_need_output ? "要" : "不要"
              }}</v-col>
              <v-col cols="9">{{ item.output_detail || "-" }}</v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <Condition
      ref="condition"
      :show="showConditionBox"
      :taskTypeItems="taskTypeItems"
      :taskTiming="taskTiming"
      @search="search($event)"
    ></Condition>
    <Task
      ref="task"
      :taskId="selectedTaskId"
      :taskTiming="taskTiming"
      @updateTaskSuccess="updateTask($event)"
      @taskDeleted="deleteTaskSuccess($event)"
      :show="openTask"
    ></Task>
    <CreateTask
      ref="createTask"
      :show="openNewTask"
      :taskTypeItems="taskTypeItems"
      :taskTiming="taskTiming"
      @taskCreated="createTaskSuccess($event)"
    ></CreateTask>
    <UpdateTask
      ref="updateTask"
      :show="openUpdateTask"
      :selectedTask="editedTask"
      :taskTypeItems="taskTypeItems"
      :taskTiming="taskTiming"
      @taskUpdated="updateTaskSuccess($event)"
    ></UpdateTask>
    <Export
      ref="export"
      :show="openExport"
      :inputCondition="searchCondition"
    ></Export>
  </v-container>
</template>

<script>
import * as Role from "../../constants/role";
import * as systemAuth from "../../constants/role_code";
import Condition from "./components/Condition";
import Task from "./components/Task";
import CreateTask from "./components/CreateTask";
import UpdateTask from "./components/UpdateTask";
import SortSetting from "../../components/SortSetting";
import Export from "./components/Export";
import { mapGetters } from "vuex";
import { debounce_leading, deepClone, isEmpty } from "../../helpers/helpers";
import {
  DB_STANDARD_API,
  IMPORT_DB_STANDARD_API,
} from "../../constants/api";
import { FC705, FC710 } from "../../constants/fc";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { apiErrorMessages } from "../../helpers/messages";
import axios from "axios";
import SortTableHeaderDirective from "../../directives/sortTableColumn";
import ResizeTableRowDirective from "../../directives/resizeTableRow";
import { getFileExtension } from "../../helpers/helpers";
import { CSV_EXTENSION, TASK_TYPE_LIST ,TASK_TIMING_LIST} from "../../constants/system";
import { queryString } from "../../helpers/qs";
import { taskMixin } from "@/mixins/task.js";
import { dbMasterMixin } from "@/mixins/dbMaster.js";
export default {
  middleware: Role.AUTH,
  components: {
    Condition,
    Task,
    CreateTask,
    UpdateTask,
    Export,
    SortSetting,
  },
  directives: {
    "sort-table-header": SortTableHeaderDirective,
    "resize-table-row": ResizeTableRowDirective,
  },
  mixins: [taskMixin, dbMasterMixin],
  metaInfo() {
    return { title: this.$t("title.dbmaster") };
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: "標準工数一覧",
          disabled: false,
          href: "tasks",
        },
      ],
      dbTaskList: [],
      allowChangeOrder: false,
      allowChangeGap: false,
      showConditionBox: false,
      openTask: false,
      openNewTask: false,
      openUpdateTask: false,
      openExport: false,
      noData: false,
      isSelecting: false,
      selectedFile: null,
      anIncreasingNumber: 1,
      headers: [],
      selectedTaskId: null,
      editedTask: null,
      searchCondition: null,
      taskTypeItems: TASK_TYPE_LIST,
      taskTiming:TASK_TIMING_LIST,
    };
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),

    checkMaster() {
      return this.isMaster();
    },
  },

  created() {
    this.$root.$refs.dbMasterComponent = this;
  },

  async mounted() {
    await this.getData();
    this.headers = this.isMaster()
      ? [
          {
            text: "No",
            value: "no",
            width: "50px",
            sortable: false,
            class: "border-right",
          },
          {
            text: "Categories",
            value: "categories",
            sortable: false,
            class: "border-right",
            width: "17%",
          },
          {
            text: "Task name",
            value: "taskName",
            sortable: false,
            width: "12%",
            class: "border-right",
          },
          {
            text: "Task type",
            value: "taskTypeName",
            sortable: false,
            class: "border-right",
            width: "7.5%",
          },
          {
            text: "Task usual",
            value: "taskUsual",
            sortable: false,
            class: "border-right",
            width: "9%",
          },
          {
            text: "Cost and count",
            value: "skillLevels",
            width: "23%",
            sortable: false,
            class: "border-right",
          },
          {
            text: "標準工数/回の業務量定義（件数など）",
            value: "unit",
            width: "11.5%",
            sortable: false,
            class: "border-right",
          },
          {
            text: "Task Timing",
            value: "taskTiming",
            sortable: false,
            class: "border-right",
            width: "10%",
          },
          {
            text: "",
            value: "order",
            sortable: false,
            width: "50px",
            align: "end",
            class: "d-none d-lg-table-cell d-xl-table-cell",
          },
        ]
      : [
          {
            text: "No",
            value: "no",
            width: "50px",
            sortable: false,
            class: "border-right",
          },
          {
            text: "Categories",
            value: "categories",
            sortable: false,
            class: "border-right",
            width: "17%",
          },
          {
            text: "Task name",
            value: "taskName",
            sortable: false,
            width: "12%",
            class: "border-right",
          },
          {
            text: "Task type",
            value: "taskTypeName",
            sortable: false,
            class: "border-right",
            width: "7%",
          },
          {
            text: "Task usual",
            value: "taskUsual",
            sortable: false,
            class: "border-right",
            width: "9%",
          },
          {
            text: "Cost and count",
            value: "skillLevels",
            width: "23%",
            sortable: false,
            class: "border-right",
          },
          {
            text: "標準工数/回の業務量定義（件数など）",
            value: "unit",
            width: "11.5%",
            sortable: false,
            class: "border-right",
          },
          {
            text: "Task Timing",
            value: "taskTiming",
            sortable: false,
            class: "border-right",
            width: "10%",
          },
          {
            text: "",
            value: "order",
            sortable: false,
            width: "50px",
            align: "end",
            class: "d-none d-lg-table-cell d-xl-table-cell",
          },
        ];
  },

  methods: {
    showSearchBox() {
      this.$refs.condition.showDialog(true);
    },

    isMaster() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MASTER;
    },
    showDetail(e, item) {
      this.$refs.task.showDialog(true, item.item.id);
    },
    gotoTasksInMobile(e) {
      this.selectedTaskId = e.id;
      this.openTask = !this.openTask;
    },

    newTask() {
      this.$refs.createTask.showDialog(true);
    },

    importCsv() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    exportCsv() {
      this.$refs.export.showDialog(true);
    },

    async fileChanged(e) {
      if (!e.target.files) return;
      this.selectedFile = e.target.files[0];
      let fileSize = this.selectedFile.size / 1024 / 1024;
      if (fileSize > 10) {
        this.$toast(this.$t("label.toast.uploadable_file_10mb_until"), 3000);
        this.$refs.uploader.value = null;
        return;
      }

      if (getFileExtension(this.selectedFile.name) !== CSV_EXTENSION) {
        this.$toast(this.$t("label.toast.csv_invalid_format"), 3000);
        this.$refs.uploader.value = null;
        return;
      }

      try {
        let formData = new FormData();
        formData.append("file", this.selectedFile);
        await axios.post(IMPORT_DB_STANDARD_API(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$refs.uploader.value = null;
        await this.getData();
      } catch (error) {
        this.$refs.uploader.value = null;
        const { status } = error.response;
        if (status === 401) {
          this.$alert(
            [
              this.$t("label.alert.upload_file_error"),
              error.response.data.message,
            ],
            {
              width: "850px",
            }
          );
        } else {
          apiErrorMessages(this, FC710, status);
        }
      }
    },

    async getData() {
      try {
        const { data, status } = this.$route.query.secondary_id
          ? await axios.get(
              `${DB_STANDARD_API()}?secondary_id=${
                this.$route.query.secondary_id
              }`
            )
          : await axios.get(DB_STANDARD_API());
        this.dbTaskList =
          status === STATUS_NO_CONTENT ? [] : this.mapData(data.result);
        this.noData = isEmpty(this.dbTaskList);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC705, status);
      }
    },

    updateTask(task) {
      this.editedTask = deepClone(task);
      // this.openUpdateTask = !this.openUpdateTask;
      this.$refs.updateTask.showDialog(true);
    },

    updateTaskSuccess(task) {
      let objIndex = this.dbTaskList.findIndex((obj) => obj.id === +task.id);
      task = this.updateInfoTask(task);
      if (objIndex === 0) {
        this.dbTaskList = [task, ...this.dbTaskList.slice(1)];
      } else {
        if (objIndex === this.dbTaskList.length) {
          this.dbTaskList = [...this.dbTaskList.slice(0, objIndex - 1), task];
        } else {
          this.dbTaskList = [
            ...this.dbTaskList.slice(0, objIndex),
            task,
            ...this.dbTaskList.slice(objIndex + 1),
          ];
        }
      }
    },
    createTaskSuccess(task) {
      task = this.updateInfoTask(task);
      this.dbTaskList.push(task);
    },

    deleteTaskSuccess(task) {
      this.dbTaskList = this.dbTaskList.filter((x) => x.id !== +task.id);
    },

    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },

    search: debounce_leading(async function (condition) {
      condition.is_target_beginner = condition.is_target_beginner ? 1 : 0;
      condition.is_target_expert = condition.is_target_expert ? 1 : 0;
      if (condition.is_target_skill_level !== 1) {
        delete condition.is_target_beginner;
        delete condition.is_target_expert;
      }
      this.searchCondition = condition;

      try {
        const qs = queryString.stringify(queryString.clean(condition));
        //if (isEmpty(qs)) return;
        const { data, status } = await axios.get(`${DB_STANDARD_API()}?${qs}`);
        this.dbTaskList =
          status === STATUS_NO_CONTENT ? [] : this.mapData(data.result);
        this.noData = isEmpty(this.dbTaskList);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC705, status);
      }
    }),

    getTaskTypeNameById(taskTypeId) {
      if (!taskTypeId) return "";

      const taskType = TASK_TYPE_LIST.find(
        (taskType) => taskType.value === taskTypeId
      );
      return taskType && taskType.text;
    },
    getTaskTimingNameById(taskTimingId) {
      if (!taskTimingId) return "";

      const taskTiming = TASK_TIMING_LIST.find(
        (taskTiming) => taskTiming.value === taskTimingId
      );
      return taskTiming && taskTiming.text;
    },
    updateInfoTask(task) {
      return {
        ...task,
        id: +task.id,
        task_type_name: this.getTaskTypeNameById(task.task_type_id),
        task_usual : task.task_usual,
        task_timing_name: this.getTaskTimingNameById(task.task_timing_id),
      };
    },
  },
};
</script>

<style lang="scss" scoped >
::v-deep .contentTable {
  .border-right {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }

  th {
    color: #598ccc !important;
  }

  .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #fcfcdc;
  }
  @media only screen and (max-width: 1600px) {
    .w-175 {
      width: 175px !important;
      min-width: 175px !important;
    }
  }
}
::v-deep .fix-layout .v-data-table__wrapper table {
  table-layout: fixed;
  min-width: 1440px;
  tbody tr {
    height: 41px;
    min-height: 41px;
    td {
      height: auto;
      div {
        height: inherit;
        /* white-space: nowrap; */
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
  @media only screen and (max-width: 1264px) {
    min-width: 1343px;
    .text-end {
      display: none;
    }
  }
}

.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 64px - 88px);
}
.h-100 {
  height: 100%;
}
</style>
<style lang="scss">
.v-input__slot .v-label {
  font-size: 14px;
}
</style>
