<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
  >
    <v-card class="hidden">
      <v-card-title class="card-header py-2">
        <v-btn @click="openDbTask = false" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="py-4">
          <span class="ml-9 mb-n4"> {{ item.category_secondary_name }}</span>
          <v-col cols="12" class="d-flex">
            <v-icon small class="yellow--text"
              >$favoriteinactive</v-icon
            >
            <div class="taskNameContainer">
              <span class="ml-2 work-break">{{ item.name }}</span>
              <span class="ml-2 work-break">{{ item.detail }}</span>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-2">
          <v-col class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ item.description }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ item.category_primary_name }}</span>
            </div>
            <div class="mt-2 ml-5 category">
              <span class="ml-2"> {{ item.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ item.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="align-center">
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">標準工数</span>
              <span class="mx-2">{{ item.cost }}m/回</span>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ item.unit || "" }}</span>
            </div>
          </v-col>
        </v-row>

        <v-card-actions v-if="$vuetify.breakpoint.mobile && isAllowDrag()">
          <v-btn
            tile
            depressed
            class="ml-auto"
            color="primary"
            @click="showMonthlyEditTask = !showMonthlyEditTask"
          >
            カレンダーに配置
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <MonthlyEditTask
      :planId="planId"
      :isPlan="isPlan"
      :selectMonthDate="selectMonthDate"
      :show="showMonthlyEditTask"
      :scheduleTaskMonthId="scheduleTaskMonthId"
      @addToCalendar="addToCalendar($event)"
    ></MonthlyEditTask>
  </v-dialog>
</template>

<script>
import { GET_MONTHLY_SCHEDULES_TASK_API } from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC843 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import MonthlyEditTask from "./MonthlyEditTask";
import { isEmpty } from "@/helpers/helpers";
import { taskMixin } from '@/mixins/task.js';
export default {
  components: { MonthlyEditTask },
  name: "MonthlyTask",
  props: {
    show: Boolean,
    scheduleTaskMonthId: Number,
    planId: String,
    selectMonthDate: String,
    isPlan: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [taskMixin],
  data() {
    return {
      item: {},
      openDbTask: false,
      showMonthlyEditTask: false,
    };
  },
  watch: {
    async show() {
      this.openDbTask = true;
      await this.getData();
    },
  },

  methods: {
    isDetailEmpty() {
      return isEmpty(this.item.detail);
    },
    isAllowDrag() {
      if (this.isPlan){
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },
    addToCalendar(item) {
      this.openDbTask = false;
      this.$emit("addToCalendar", item);
    },

    getTargetMonth() {
      let dateParts = this.selectMonthDate.split('-');
      let year = dateParts[0];
      let month = dateParts[1];
      let convertedDate = year + month;
      return convertedDate;
    },

    async getData() {
      let user_id = this.user.user_id;
      let target_month = this.getTargetMonth();
      let schedule_task_month_id = this.scheduleTaskMonthId;

      try {
        const { data, status } = await axios.get(GET_MONTHLY_SCHEDULES_TASK_API(
          user_id,
          target_month,
          schedule_task_month_id,
        ));
        let monthlyTask = status === STATUS_NO_CONTENT ? [] : data.result[0];
        monthlyTask.count = 1;
        this.item = monthlyTask;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC843, status);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  word-break: break-all;
}
.taskNameContainer {
  display: flex;
  flex-direction: column;
}
</style>
