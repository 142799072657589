<template>
  <v-container fill-height fluid class="pa-0">
    <v-row class="mt-0" align="baseline">
      <v-col cols="4" md="8">
        <v-btn
          tile
          depressed
          v-if="isMaster"
          @click="newCategory()"
          color="primary"
        >
          登録
        </v-btn>
        <v-btn
          v-if="isMaster && !$vuetify.breakpoint.mobile"
          @click="importCsv()"
          :loading="isSelecting"
          outlined
          class="ml-4"
        >
          <v-icon small dark class="mr-2">$csvimport</v-icon>
          CSVインポート
        </v-btn>
        <input
          v-if="isMaster"
          ref="uploader"
          class="d-none"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="fileChanged"
        />
        <v-btn
          class="ml-4"
          @click="exportCsv()"
          outlined
          v-if="isMaster && !$vuetify.breakpoint.mobile"
        >
          <v-icon small dark class="mr-2">$csvexport</v-icon>
          CSVエクスポート
        </v-btn>
      </v-col>
      <v-col cols="8" md="4" class="text-right pt-0">
        <v-text-field
          v-model="searchText"
          @keydown.enter="throttleSearch()"
          placeholder="カテゴリを検索"
          hide-details="auto"
        >
          <template v-slot:prepend>
            <v-icon @click="throttleSearch()" small>$search</v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="noData">
      <v-col cols="12" class="text-center">
        検索条件に一致する業務カテゴリは見つかりませんでした。再度検索してみてください。
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" class="height-custom pb-0">
        <v-data-table
          class="fix-layout h-100"
          :headers="headers"
          height="100%"
          mobile-breakpoint="0"
          :items="categories"
          :hide-default-footer="true"
          fixed-header
          disable-pagination
          :key="anIncreasingNumber"
          v-sort-table-header="{
            onEnd: sortTheHeadersAndUpdateTheKey,
            allowChangeOrder: allowChangeOrder,
            length: headers.length,
          }"
          v-resize-table-row="{
            allowChangeGap: allowChangeGap,
            threeDot: [3, 4],
          }"
          @dblclick:row="gotoTasks"
          @click:row="$vuetify.breakpoint.mobile && gotoTasksInMobile($event)"
        >
          <template v-slot:[`header.order`]="{}">
            <SortSetting
              v-if="!$vuetify.breakpoint.mobile"
              @allowChangeOrder="allowChangeOrder = true"
              @allowChangeGap="allowChangeGap = true"
            ></SortSetting>
          </template>
          <template v-slot:[`item.no`]="{ index }">{{ ++index }}</template>
          <template v-slot:[`item.parentCategory`]="{ item }">
            <a
              v-if="$vuetify.breakpoint.mobile"
              :href="moveTo(item)"
              class="black--text text-decoration-none"
            >
              {{ item.category_primary_name }}
            </a>
            <span v-else>{{ item.category_primary_name }}</span>
            <v-btn icon @click="editCategory($event, item)">
              <v-icon small>$edit</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.childCategory`]="{ item }">
            {{ item.category_secondary_name }}
          </template>
          <template v-slot:[`item.changeability`]="{ item }">
            {{ item.is_changeability ? "変更可" : "変更不可"}}
          </template>
          <template v-slot:[`item.department`]="{ item }">
            <v-menu max-width="auto" bottom right offset-x>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="black--text"
                  :class="{ 'three-dot': !isEmptyDepartment(item) }"
                >
                  {{ joinDepartment(item) }}
                </div>
              </template>
              <v-list v-if="!isEmptyDepartment(item)">
                <v-virtual-scroll
                  :items="getUnique(item.belongs, 'department_id')"
                  height="200"
                  max-height="200"
                  item-height="30"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.department_id">
                      <v-list-item-title>
                        {{ item.department_name }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.group`]="{ item }">
            <v-menu max-width="auto" bottom right offset-x>
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="{ 'three-dot': !isEmptyGroup(item) }"
                  v-bind="attrs"
                  v-on="on"
                  class="black--text"
                >
                  {{ joinGroup(item) }}
                </div>
              </template>

              <v-list v-if="!isEmptyGroup(item)">
                <v-virtual-scroll
                  :items="getUnique(item.belongs, 'group_id')"
                  height="500"
                  max-height="500"
                  item-height="30"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.group_id">
                      <v-list-item-title>
                        {{ item.group_name }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex align-center text-left">
              {{ item.task_count || 0 }}
            </div>
          </template>
          <template v-slot:[`item.order`]="{ item }">
            <v-btn icon @click="deleteCategory($event, item)">
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <Export :inputCondition="searchCondtion" :show="openExport"></Export>
    <UpdateCategory
      :show="openEditCategory"
      :inputCategory="selectedCategory"
      @categoryUpdated="updateCategorySuccess($event)"
      @categoryPrimaryUpdated="updateCategoryPrimarySuccess($event)"
    ></UpdateCategory>
    <CreateCategory
      @primaryCategoryCreated="createPrimaryCategorySuccess($event)"
      @secondaryCategoryCreated="createSecondaryCategorySuccess($event)"
      :show="openNewCategory"
    ></CreateCategory>
  </v-container>
</template>

<script>
import * as Role from "../../constants/role";
import * as systemAuth from "../../constants/role_code";
import { mapGetters } from "vuex";
import {
  debounce_leading,
  deepClone,
  isEmpty,
  isEmptyNumber,
  orderBy,
} from "../../helpers/helpers";
import Export from "./components/Export";
import UpdateCategory from "./components/UpdateCategory";
import CreateCategory from "./components/CreateCategory";
import SortSetting from "../../components/SortSetting";
import SortTableHeaderDirective from "../../directives/sortTableColumn";
import ResizeTableRowDirective from "../../directives/resizeTableRow";
import {
  CATEGORIES_API,
  PRIMARY_SUB_CATEGORY_API,
  SEARCH_CATEGORIES_API,
  IMPORT_CATEGORY_API,
} from "../../constants/api";
import { FC701, FC704, FC715 } from "../../constants/fc";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { apiErrorMessages } from "../../helpers/messages";
import axios from "axios";
import { getFileExtension } from "../../helpers/helpers";
import { CSV_EXTENSION } from "../../constants/system";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
export default {
  middleware: Role.AUTH,
  components: { Export, UpdateCategory, CreateCategory, SortSetting },
  directives: {
    "sort-table-header": SortTableHeaderDirective,
    "resize-table-row": ResizeTableRowDirective,
  },
  metaInfo() {
    return { title: this.$t("title.category") };
  },
  mixins: [queryRouterMixin],
  data() {
    return {
      categories: [],
      anIncreasingNumber: 0,
      noData: false,
      isSelecting: false,
      openExport: false,
      allowChangeOrder: false,
      allowChangeGap: false,
      openEditCategory: false,
      openNewCategory: false,
      initCategories: [],
      headers: this.$vuetify.breakpoint.mobile
        ? [
            {
              text: "No",
              value: "no",
              sortable: false,
              class: "border-right",
              width: "40px",
            },
            {
              text: "大カテゴリ",
              width: "17%",
              value: "parentCategory",
              sortable: false,
              class: "border-right",
            },
            {
              text: "小カテゴリ",
              value: "childCategory",
              sortable: false,
              class: "border-right",
              width: "17%",
            },
            {
              text: "カテゴリ変更可否",
              value: "changeability",
              sortable: false,
              class: "border-right",
              width: "10%",
            },
            {
              text: "対象部署",
              value: "department",
              width: "17%",
              sortable: false,
              class: "border-right",
            },
            {
              text: "対象Gr",
              value: "group",
              sortable: false,
              width: "17%",
              class: "border-right",
            },
            {
              text: "タスク数",
              value: "action",
              sortable: false,
              width: "10%",
            },
          ]
        : [
            {
              text: "No",
              value: "no",
              sortable: false,
              class: "border-right",
              width: "40px",
            },
            {
              text: "大カテゴリ",
              width: "17%",
              value: "parentCategory",
              sortable: false,
              class: "border-right",
            },
            {
              text: "小カテゴリ",
              value: "childCategory",
              sortable: false,
              class: "border-right",
              width: "18%",
            },
            {
              text: "カテゴリ変更可否",
              value: "changeability",
              sortable: false,
              class: "border-right",
              width: "10%",
            },
            {
              text: "対象部署",
              value: "department",
              width: "18%",
              sortable: false,
              class: "border-right",
            },
            {
              text: "対象Gr",
              value: "group",
              sortable: false,
              width: "18%",
              class: "border-right",
            },
            {
              text: "タスク数",
              value: "action",
              sortable: false,
              width: "10%",
            },
            {
              text: "",
              value: "order",
              sortable: false,
              width: "5%",
              align: "center",
            },
          ],
      searchText: "",
      selectedCategory: null,
      searchCondtion: null,
    };
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),

    isMaster() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MASTER;
    },
  },
  async created() {
    await this.getCategories();
  },
  methods: {
    isEmptyDepartment(item) {
      return (
        item.belongs &&
        item.belongs.filter((x) => !isEmpty(x.department_name)).length <= 0
      );
    },
    isEmptyGroup(item) {
      return (
        item.belongs &&
        item.belongs.filter(
          (x) => !isEmpty(x.department_name) && !isEmpty(x.group_name)
        ).length <= 0
      );
    },
    getUnique(items, key) {
      const arrayUniqueByKey = [
        ...new Map(items.map((item) => [item[key], item])).values(),
      ];
      return arrayUniqueByKey;
    },
    async fileChanged(e) {
      if (!e.target.files) return;
      this.selectedFile = e.target.files[0];
      let fileSize = this.selectedFile.size / 1024 / 1024;
      if (fileSize > 10) {
        this.$toast(this.$t("label.toast.uploadable_file_10mb_until"), 3000);
        this.$refs.uploader.value = null;
        return;
      }
      if (getFileExtension(this.selectedFile.name) !== CSV_EXTENSION) {
        this.$toast(this.$t("label.toast.csv_invalid_format"), 3000);
        this.$refs.uploader.value = null;
        return;
      }
      try {
        let formData = new FormData();
        formData.append("file", this.selectedFile);
        await axios.post(IMPORT_CATEGORY_API(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        await this.getCategories();
        this.$refs.uploader.value = null;
      } catch (error) {
        this.$refs.uploader.value = null;
        const { status } = error.response;
        if (status === 401) {
          this.$alert(
            [
              this.$t("label.alert.upload_file_error"),
              error.response.data.message,
            ],
            {
              width: "850px",
            }
          );
        } else {
          apiErrorMessages(this, FC715, status);
        }
      }
    },

    importCsv() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    exportCsv() {
      this.openExport = !this.openExport;
    },

    editCategory(event, item) {
      event.stopPropagation();
      this.selectedCategory = deepClone(item);
      this.selectedCategory.categories = this.initCategories;
      this.openEditCategory = !this.openEditCategory;
    },

    updateCategorySuccess(event) {
      this.resetCategoryId(); // reset category id
      let newCategories = deepClone(this.categories);
      let addNewcategoryFlg = false;
      let isEdit = false;

      // Add secondary
      if (isEmptyNumber(event.oldCategory.category_secondary_id)) {
        // Change primary category (by id)
        if (
          event.oldCategory.category_primary_id !==
          event.newCategory.category_primary_id
        ) {
          // Clear this
          let newCategoriesId = newCategories?.findIndex(
            (x) => x.id === event.oldCategory.id
          );
          newCategories[newCategoriesId].belongs = [];
          newCategories[newCategoriesId].category_secondary_id = null;
          newCategories[newCategoriesId].category_secondary_name = null;
          newCategories[newCategoriesId].is_changeability = 0;
          newCategories[newCategoriesId].task_count = 0;
          // Add new
          addNewcategoryFlg = true;
          // No change primary category
        } else {
          let categoryIndex = newCategories.findIndex(
            (x) =>
              x.category_primary_id === event.newCategory.category_primary_id
          );
          newCategories[categoryIndex] = { ...event.newCategory };
        }

        // Edit Secondary
      } else {
        isEdit = true;
        newCategories?.map((x) => {
          if (
            x.category_secondary_id === event.newCategory.category_secondary_id
          ) {
            // Change primary category
            if (
              x.category_primary_id !== event.newCategory.category_primary_id
            ) {
              // remove group of department
              x.belongs = [];
              x.category_secondary_id = null;
              x.category_secondary_name = null;
              x.task_count = 0;
              addNewcategoryFlg = true;
              // No change primary category
            } else {
              x.category_secondary_name =
                event.newCategory.category_secondary_name; // edit secondary name
              x.is_changeability = event.newCategory.is_changeability;
              x.belongs = event.newCategory.belongs;
              x.task_count = event.newCategory.task_count;
            }
          }
          return x;
        });
      }
      // Add or Edit primary category
      if (addNewcategoryFlg) {
        let categoryIndex = newCategories?.findIndex(
          (x) =>
            x.category_primary_id === event.newCategory.category_primary_id &&
            isEmptyNumber(x.category_secondary_id)
        );
        // Primary categories empty <= edit category
        if (categoryIndex > -1) {
          newCategories[categoryIndex].belongs = event.newCategory.belongs;
          newCategories[categoryIndex].category_secondary_id =
            event.newCategory.category_secondary_id;
          newCategories[categoryIndex].category_secondary_name =
            event.newCategory.category_secondary_name;
          newCategories[categoryIndex].is_changeability =
            event.newCategory.is_changeability;
          newCategories[categoryIndex].task_count =
            event.newCategory.task_count;

          // Primary categories not empty <= add category
        } else {
          newCategories.push(event.newCategory);
        }

        // Remove if this has more 1 record
        if (isEdit) {
          let countCategoryPrimary = newCategories.filter(
            (x) =>
              x.category_primary_id === event.oldCategory.category_primary_id
          )?.length;

          if (countCategoryPrimary > 1)
            newCategories.splice(event.oldCategory.id, 1);
        }
      }

      newCategories.forEach((element) => {
        element.secondary_count =
          this.categories.filter(
            (x) => x.category_primary_id === +element.category_primary_id
          ).length || 0;
      });
      // assign to categories list
      this.categories = newCategories;
      // Sort categories list again
      this.categories = orderBy(
        this.categories,
        ["category_primary_id", "category_secondary_id"],
        ["asc", "asc"]
      );
      this.resetCategoryId(); // reset category id
    },

    updateCategoryPrimarySuccess(event) {
      let oldCategories = deepClone(this.categories);
      oldCategories.map((x) => {
        if (x.category_primary_id === event.category_primary_id) {
          x.category_primary_name = event.category_primary_name;
        }
        return x;
      });
      this.categories = oldCategories;
    },

    newCategory() {
      this.openNewCategory = !this.openNewCategory;
    },

    async createPrimaryCategorySuccess(category) {
      category.belongs = [];
      category.task_count = 0;
      category.secondary_count = 0;
      category.id = "";
      this.categories.push(category);
      this.categories = orderBy(
        this.categories,
        ["category_primary_id", "category_secondary_id"],
        ["asc", "asc"]
      );
      this.initCategories.push({
        id: category.category_primary_id,
        name: category.category_primary_name,
        secondaries: [],
      });
      this.resetCategoryId(); // reset category id
    },

    async createSecondaryCategorySuccess(category) {
      let emptyPrimaryCategory = this.categories.find(
        (x) =>
          x.category_primary_id === +category.category_primary_id &&
          isEmptyNumber(x.category_secondary_id)
      );
      if (emptyPrimaryCategory) {
        emptyPrimaryCategory.category_secondary_id =
          +category.category_secondary_id;
        emptyPrimaryCategory.category_secondary_name =
          category.category_secondary_name;
        emptyPrimaryCategory.task_count = 0;
      } else {
        category.belongs = [];
        category.secondary_count = 1;
        category.task_count = 0;
        category.id = "";
        this.categories.push(category);
      }
      this.categories.forEach((element) => {
        if (element.category_primary_id === +category.category_primary_id) {
          element.secondary_count =
            this.categories.filter(
              (x) => x.category_primary_id === +category.category_primary_id
            ).length || 0;
        }
      });
      this.categories = orderBy(
        this.categories,
        ["category_primary_id", "category_secondary_id"],
        ["asc", "asc"]
      );
      await this.getCategories();
      this.resetCategoryId(); // reset category id
      let existItem = this.initCategories.find(
        (x) => x.id === category.category_primary_id
      );
      if (this.existItem) {
        existItem.secondaries.push({
          id: category.category_primary_id,
          name: category.category_primary_name,
        });
      } else {
        this.initCategories.push({
          id: category.category_primary_id,
          name: category.category_primary_name,
          secondaries: [],
        });
      }
    },

    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());
        let rawCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.initCategories = deepClone(rawCategories);
        this.categories = this.flatten(rawCategories);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    deleteCategory(event, item) {
      event.stopPropagation();

      if (item.task_count > 0) {
        this.notDeleteCategory();
      } else {
        if (item.secondary_count === 0) {
          this.$confirm(this.$t("label.confirm.delete_category"), {
            buttons: {
              ok: {
                text: this.$t("label.buttons.delete"),
                type: "primary",
              },
              cancel: {
                text: this.$t("label.buttons.cancel"),
                type: "ligth",
              },
            },
            rtl: true,
          })
            .then(async () => {
              try {
                await axios.delete(
                  PRIMARY_SUB_CATEGORY_API(item.category_primary_id)
                );
                this.categories = this.categories.filter(
                  (x) => x.category_primary_id !== item.category_primary_id
                );
                this.categories.forEach((element) => {
                  if (
                    element.category_primary_id === +item.category_primary_id
                  ) {
                    element.secondary_count =
                      this.categories.filter(
                        (x) =>
                          x.category_primary_id === +item.category_primary_id
                      ).length || 0;
                  }
                });
                this.initCategories = this.initCategories.filter(
                  (x) => x.id !== item.category_primary_id
                );
                this.$toast(
                  `${item.category_primary_name}を削除しました。`,
                  3000
                );
                this.resetCategoryId(); // reset category
              } catch (error) {
                const { status } = error.response;
                if (status === 404) {
                  this.$alert(error.response.data.message, {
                    width: "820px",
                  });
                } else {
                  apiErrorMessages(this, FC704, status);
                }
              }
            })
            .catch(() => {});
        } else {
          if (item.secondary_count < 2) {
            this.deletePrimaryCategory(item);
          } else {
            this.$confirm(this.$t("label.confirm.delete_category"), {
              buttons: {
                ok: {
                  text: this.$t("label.buttons.delete"),
                  type: "primary",
                },
                cancel: {
                  text: this.$t("label.buttons.cancel"),
                  type: "ligth",
                },
              },
              rtl: true,
            })
              .then(async () => {
                try {
                  await axios.delete(
                    PRIMARY_SUB_CATEGORY_API(
                      item.category_primary_id,
                      item.category_secondary_id
                    )
                  );
                  this.categories = this.categories.filter(
                    (x) =>
                      !(
                        x.category_primary_id === item.category_primary_id &&
                        x.category_secondary_id === item.category_secondary_id
                      )
                  );
                  this.categories.forEach((element) => {
                    if (
                      element.category_primary_id === +item.category_primary_id
                    ) {
                      element.secondary_count =
                        this.categories.filter(
                          (x) =>
                            x.category_primary_id === +item.category_primary_id
                        ).length || 0;
                    }
                  });
                  this.$toast(
                    `${item.category_secondary_name}を削除しました。`,
                    3000
                  );
                  this.resetCategoryId(); // reset category
                } catch (error) {
                  const { status } = error.response;
                  if (status === 404) {
                    this.$alert(error.response.data.message, {
                      width: "820px",
                    });
                  } else {
                    apiErrorMessages(this, FC704, status);
                  }
                }
              })
              .catch(() => {});
          }
        }
      }
    },

    notDeleteCategory() {
      this.$alert([this.$t("label.confirm.not_delete_category.line1")])
        .then(() => {})
        .catch(() => {});
    },

    deletePrimaryCategory(item) {
      this.$confirm(
        [
          this.$t("label.confirm.delete_both_category.line1", {
            categoryName: item.category_primary_name,
          }),
          "",
        ],
        {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
          },
          width: "600px",
          preWrapText: true,
          rtl: true,
        }
      )
        .then(async () => {
          try {
            await axios.delete(
              PRIMARY_SUB_CATEGORY_API(
                item.category_primary_id,
                item.category_secondary_id
              )
            );
            await axios.delete(
              PRIMARY_SUB_CATEGORY_API(item.category_primary_id)
            );
            this.categories = this.categories.filter(
              (x) =>
                !(
                  x.category_primary_id === item.category_primary_id &&
                  x.category_secondary_id === item.category_secondary_id
                )
            );
            this.categories.forEach((element) => {
              if (element.category_primary_id === +item.category_primary_id) {
                element.secondary_count =
                  this.categories.filter(
                    (x) => x.category_primary_id === +item.category_primary_id
                  ).length || 0;
              }
            });
            this.initCategories = this.initCategories.filter(
              (x) => x.id !== item.category_primary_id
            );
            this.$toast(
              `${item.category_primary_name} と ${item.category_secondary_name}を削除しました。`,
              3000
            );
            this.resetCategoryId(); // reset category
          } catch (error) {
            const { status } = error.response;
            if (status === 404) {
              this.$alert(error.response.data.message, {
                width: "820px",
              });
            } else {
              apiErrorMessages(this, FC704, status);
            }
          }
        })
        .catch(() => {});
    },

    gotoTasks(e, item) {
      e.preventDefault();
      if (item.item.task_count && item.item.task_count > 0) {
        if (e.ctrlKey) {
          let routeData = this.$router.resolve({
            path: "/tasks",
            query: { secondary_id: item.item.category_secondary_id },
          });
          this.openNewBackgroundTab(routeData.href);
        } else {
          this.$router.push({
            name: "tasks",
            query: { secondary_id: item.item.category_secondary_id },
          });
        }
      }
    },
    gotoTasksInMobile(e) {
      if (e.task_count && e.task_count > 0) {
        this.$router.push({
          name: "tasks",
          query: { secondary_id: e.category_secondary_id },
        });
      }
    },
    moveTo(e) {
      if (e.task_count && e.task_count > 0) {
        return `/tasks?secondary_id=${e.category_secondary_id}`;
      }
    },
    resetCategoryId() {
      this.categories.map((element, index) => {
        element.id = index;
      });
    },

    async search() {
      try {
        let params = {
          vague_name: this.searchText,
        };
        this.searchCondtion = {
          vague_name: this.searchText,
        };
        const { data, status } = await axios.get(SEARCH_CATEGORIES_API(params));
        let rawCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.noData = isEmpty(rawCategories);
        this.initCategories = rawCategories;
        this.categories = this.flatten(rawCategories);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    throttleSearch: debounce_leading(async function () {
      await this.search();
    }, 1500),
    flatten(source) {
      let destination = [];
      let index = 0;
      for (const primary of source) {
        if (!isEmpty(primary.secondaries)) {
          for (const secondary of primary.secondaries) {
            let newSecondary = {
              ...secondary,
              id: index,
              category_primary_id: primary.id,
              category_primary_name: primary.name,
              category_secondary_id: secondary.id,
              category_secondary_name: secondary.name,
              secondary_count: primary.secondary_count,
            };
            destination.push(newSecondary);
            index++;
          }
        }
      }
      return destination;
    },

    joinGroup(item) {
      if (isEmpty(item.belongs)) return "";
      return this.getUnique(item.belongs, "group_id")
        .map((x) => x.group_name)
        .join("\r\n ");
    },

    joinDepartment(item) {
      if (isEmpty(item.belongs)) return "";
      return this.getUnique(item.belongs, "department_id")
        .filter((x) => !isEmpty(x.department_name))
        .map((x) => x.department_name)
        .join("\r\n ");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .fix-layout .v-data-table__wrapper table {
  table-layout: fixed;
  min-width: 1440px;
  tbody tr {
    height: 40px;
    min-height: 41px;
    td {
      height: inherit;
      div {
        position: relative;
        height: inherit;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .three-dot {
        &:before {
          content: "...";
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 64px - 62px - 54px);
}
.h-100 {
  height: 100%;
}
</style>

