<template>
  <div :class="[{ 'is-menu-collapsed': showDrawer }]">
    <Navbar @click="showDrawer = !showDrawer" style="z-index: 200"></Navbar>
    <v-main>
      <child />
    </v-main>
    <Footer />
    <v-navigation-drawer
      class="sidebar-wrapper"
      v-model="showDrawer"
      absolute
      style="width: 295px"
    >
      <v-btn @click="showDrawer = !showDrawer" icon class="float-right">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
      <v-list dense class="mt-15" style="height: 650px">
        <v-list-item-group v-model="index" color="primary">
          <v-list-item
            v-for="item in menuItems"
            :key="item.id"
            link
            :href="editPath(item)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// resize vh
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { GET_ALL_MEMNUS_API } from "../constants/api";
import axios from "axios";
import { apiErrorMessages } from "../helpers/messages";
import { FC601 } from "../constants/fc";
import { STATUS_NO_CONTENT } from "../constants/status";
import { mapGetters } from "vuex";
import { isEmpty } from "../helpers/helpers";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
export default {
  name: "DefaultLayout",
  components: {
    Navbar,
    Footer,
  },
  mixins: [queryRouterMixin],
  computed: {
    ...mapGetters("common", ["selectedMember"]),
    ...mapGetters("common", ["selectedDate"]),
  },
  watch: {
    $route() {
      this.resetRouteIndex();
    },
    showDrawer: {
      handler(val) {
        this.$store.dispatch("common/isShowDrawer", val);
        let fullCalendarHeader = document.querySelector('.fullcalendar-itokuro .fc-col-header') || null
        fullCalendarHeader && (fullCalendarHeader.style.width = '100%')
        let fullCalendarBody = document.querySelector('.fullcalendar-itokuro .fc-timegrid-body') || null
        fullCalendarBody && (fullCalendarBody.style.width = '100%')
      },
    },
  },
  async created() {
    await this.getMenu();
    this.resetRouteIndex();
    this.$store.dispatch("common/isShowDrawer", this.showDrawer);
  },
  data() {
    return {
      showDrawer: false,
      menuItems: [],
      showLogoutDialog: false,
      index: 0,
      profile: {},
    };
  },
  methods: {
    resetRouteIndex() {
      const route = this.$route;
      const path = route.path.split("/")[1];
      this.index = this.menuItems.findIndex((item) => item.path === path);
    },

    editPath(item) {
      let routeData = null;

      if (item.path === "schedule") {
        routeData = this.$router.resolve({
          path: `/${item.path}`,
          query: !isEmpty(this.$route.query.date)
            ? {
                userId: this.selectedMember?.id,
                date: this.$route.query.date,
              }
            : { userId: this.selectedMember?.id },
        });
        return routeData.href;
      }
      if (item.path === "actual") {
        routeData = this.$router.resolve({
          path: `/${item.path}`,
          query: !isEmpty(this.$route.query.date)
            ? {
                uid: this.selectedMember?.id,
                date: this.$route.query.date,
              }
            : {
                uid: this.selectedMember?.id,
              },
        });
        return routeData.href;
      }
      if (item.path === "weekly-schedules" || item.path === "weekly-actuals" ||
          item.path === "monthly-schedules") {
        routeData = this.$router.resolve({
          path: `/${item.path}`,
          query: !isEmpty(this.$route.query.startDate) && `/${item.path}` === this.$route.path
            ? {
                userId: this.selectedMember?.id,
                startDate: this.$route.query.startDate,
                endDate: this.$route.query.endDate,
              }
            : {
                userId: this.selectedMember?.id,
              },
        });
        return routeData.href;
      }
      const menuArr = [
        "user-skills",
        "status-logs",
        "productivity",
        "profiles",
        "status-schedules",
        "status-actuals"
      ];
      if (menuArr.includes(item.path)) {
        if (`/${item.path}` === this.$route.path) {
          routeData = this.$router.resolve({
            path: `/${item.path}`,
            query: this.setQuery(this.$route.query),
          });
          return routeData.href;
        } else {
          return `${window.location.origin}/${item.path}`;
        }   
      }

      return `${window.location.origin}/${item.path}`;
    },

    async getMenu() {
      try {
        const { data, status } = await axios.get(GET_ALL_MEMNUS_API());
        this.menuItems =
          status === STATUS_NO_CONTENT
            ? []
            : data.result?.sort((a, b) => a.order_value - b.order_value);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC601, status);
      }
    },
  },
};
</script>
<style lang="scss">
.sidebar-wrapper {
  z-index: 199 !important;
}
</style>
<style lang="scss" scoped>
@media (min-width: 767px) {
  .is-menu-collapsed {
    .v-main {
      margin-left: 295px;
    }
  }
}
.breadcrumb-custom {
  a {
    color: black !important;
  }
}
::v-deep .v-breadcrumbs {
  padding-left: 0 !important;

  li:nth-child(2) {
    padding-left: 0 !important;
  }
}
::v-deep .v-main__wrap {
  padding: 12px;
}
</style>
