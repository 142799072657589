<template>
  <v-navigation-drawer v-model="show" right width="auto">
    <v-list-item class="px-2 mt-4">
      <v-row>
        <v-col cols="10" class="v-center pl-5">お気に入り</v-col>

        <v-col cols="2">
          <v-btn
            icon
            @click.stop="
              show = false;
              $emit('close');
            "
          >
            <v-icon small>$close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="7">
          <v-text-field
            solo
            placeholder="検索"
            maxlength="32"
            @change="handleSearch"
            v-model="search"
          >
            <template>
              <v-icon @click="handleSearch" small slot="prepend-inner">
                $search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-list-item>
    <v-divider></v-divider>

    <div>
      <v-btn text @click="addFavoriteTask">
        <v-icon small class="blue--text" left> $addtodolist </v-icon>
        タスクの追加
      </v-btn>
    </div>
    <v-divider></v-divider>

    <div
      class="text-center"
      v-if="
        !(dbTasks && dbTasks.length > 0) &&
        !(favoriteTasks && favoriteTasks.length > 0) &&
        !isEmptySearch(this.search)
      "
    >
      <p class="gray--text">検索条件に一致するタスクは</p>
      <p class="gray--text">見つかりませんでした。</p>
    </div>

    <div class="content overflow-y-auto">
      <div class="d-flex justify-center" v-if="isLoading">
        <svg
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          stroke="#000"
          class="sidebar-loader"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      <v-list v-else class="custom-tree">
        <v-list-group
          v-if="dbTasks && dbTasks.length > 0"
          sub-group
          v-model="favoriteModel"
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-row>
              <v-col cols="12">
                <v-list-item-content>
                  <v-list-item-title>標準工数タスク</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="12" class="pd0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
          <v-list-item
            :name="item.standard_task_id"
            v-for="(item) in dbTasks"
            :key="item.standard_task_id" 
            @click.stop
          >
            <v-list-item-icon @click="isFavorite(item)">
              <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
              <v-icon small v-else>$favoriteinactive</v-icon>
            </v-list-item-icon>
            <DoubleClick
              :draggable="isAllowDrag()"
              tag="v-list-item-content"
              @single-click="editDbTask(item)"
              @double-click="registerTaskOnClick(item)"
              @ctrl-click="registerTaskOnCtrlClick(item, item.standard_task_id)"
              @dragstart="registerTaskOnDrag(item)"
              @dragend="registerTaskOnDragEnd()"
              :key="item.standard_task_id" 
            >
              <v-row style="width: 100%">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="8">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-title v-bind="attrs" v-on="on">
                            <div class="d-flex align-center flex-wrap">
                              <div class="text-truncate mr-1">
                                {{ item.name }}
                              </div>
                              <BeginnerIcon v-if="isBeginnerSkill(item)" />
                              <ExpertIcon v-if="isExpertSkill(item)" />
                            </div>
                          </v-list-item-title>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <span>{{ item.cost }}m/回</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </DoubleClick>
          </v-list-item>
        </v-list-group>

        <v-list-group
          sub-group
          v-model="dbModel"
          v-if="favoriteTasks && favoriteTasks.length > 0"
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-row>
              <v-col cols="12">
                <v-list-item-content>
                  <v-list-item-title>標準工数以外タスク</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="12" class="pd0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
          <v-list-item
            :name="item.favorite_task_id"
            v-for="(item) in favoriteTasks"
            :key="item.favorite_task_id"
            @click.stop
          >
            <v-list-item-icon @click="isFavorite(item)">
              <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
              <v-icon small v-else>$favoriteinactive</v-icon>
            </v-list-item-icon>
            <DoubleClick
              :draggable="isAllowDrag()"
              tag="v-list-item-content"
              @single-click="editFavoriteTask(item)"
              @double-click="registerTaskOnClick(item)"
              @ctrl-click="registerTaskOnCtrlClick(item, item.favorite_task_id)"
              @dragstart="registerTaskOnDrag(item)"
              @dragend="registerTaskOnDragEnd()"
              :key="item.favorite_task_id" 
            >
              <v-row style="width: 100%">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="8">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-title v-bind="attrs" v-on="on">
                            <div class="d-flex align-center flex-wrap">
                              <div class="text-truncate mr-1">
                                {{ item.name }}
                              </div>
                              <BeginnerIcon v-if="isBeginnerSkill(item)" />
                              <ExpertIcon v-if="isExpertSkill(item)" />
                            </div>
                          </v-list-item-title>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <span>{{ item.cost }}m/回</span>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </DoubleClick>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>

    <FavoriteTask
      @registerFavoriteTask="registerFavoriteTask($event)"
      @deleteFavoriteTask="deleteFavoriteTask($event)"
      :planId="planId"
      :show="showFavoriteCreate"
      :taskId="favoriteTaskId"
      :isFavorite="true"
      :isPlan="isPlan"
    ></FavoriteTask>
    <DatabaseTask
      :show="showDatabaseTask"
      :standarId="standarId"
      :isPlan="isPlan"
      @isFavorite="setFavorite($event)"
    ></DatabaseTask>
  </v-navigation-drawer>
</template>

<script>
import FavoriteTask from "./FavoriteTask";
import DatabaseTask from "./DatabaseTask";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { FAVORITE_TASK_API, UPDATE_DB_TASKS_API } from "../../../constants/api";
import { mapGetters } from "vuex";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC833, FC709, FC831 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DoubleClick from "../../../components/DoubleClick";
import BeginnerIcon from "@/components/icons/BeginnerIcon.vue";
import ExpertIcon from "@/components/icons/ExpertIcon.vue";
import * as systemAuth from "@/constants/role_code";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: {
    FavoriteTask,
    DatabaseTask,
    DoubleClick,
    BeginnerIcon,
    ExpertIcon,
  },
  mixins: [taskMixin],
  props: {
    value: Boolean,
    planId: String,
    actualId: {
      type: String,
      default: null,
    },
    isPlan: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("loading", ["isPartialLoading"]),
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
    },
    show: {
      async handler(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    isAllowDrag() {
      if (this.isPlan) {
        return this.$root.$refs.monthlySchedulesComponent.isSelf()
               && this.$root.$refs.monthlySchedulesComponent.isNoSubmmit()
               && this.$root.$refs.monthlySchedulesComponent.isCanSubmit();
      }
      return this.$root.$refs.monthlySchedulesComponent.isNoSubmmit()
             && this.$root.$refs.monthlySchedulesComponent.isCanSubmit();
    },
    getDatabaseList() {
      return this.dbTasks;
    },
    getFavoriteList() {
      return this.favoriteTasks;
    },
    registerTaskOnCtrlClick(e,id) {
      if (this.isAllowDrag()) {
        const element = document.querySelector(`[name="${id}"]`);

        if (element) {
          if (element.classList.contains('selected')) {
            element.classList.remove('selected');
          } else {
            element.classList.add('selected');
          }
        }
        this.$root.$refs.monthlySchedulesComponent.addEventOnCtrlClick(e);
      }
    },
    registerTaskOnDrag (e) {
      if (this.isAllowDrag()) {
        this.$root.$refs.monthlySchedulesComponent.addEventOnDrag(e);
      }
    },
    registerTaskOnDragEnd () {
      const selectedElements = document.querySelectorAll('.selected');
      selectedElements.forEach(item => {
        item.classList.remove('selected');
      });
      this.$root.$refs.monthlySchedulesComponent.handleDragEnd()
    },

    setFavorite(item) {
      this.removeDbTask(item.id);
    },

    addDbTask(item) {
      this.dbTasks = this.dbTasks.filter(
        (x) => x.standard_task_id !== item.standard_task_id
      );
      this.originDbTasks = this.originDbTasks.filter(
        (x) => x.standard_task_id !== item.standard_task_id
      );
      this.dbTasks.push(item);
      this.originDbTasks.push(item);
    },

    registerTaskOnClick(e) {
      if (this.isPlan && this.isAllowDrag()) {
        this.$root.$refs.monthlySchedulesComponent.addEventOnClick(e);
      }
    },

    registerFavoriteTask(task) {
      task.isSplit = false;
      this.originFavoriteTasks = this.originFavoriteTasks.filter(
        (x) => x.favorite_task_id != task.favorite_task_id
      );
      this.favoriteTasks = this.favoriteTasks.filter(
        (x) => x.favorite_task_id != task.favorite_task_id
      );
      this.favoriteTasks.push(task);
      this.originFavoriteTasks.push(task);
      this.sortFavoriteTasks();
    },

    deleteFavoriteTask(task) {
      this.originFavoriteTasks = this.originFavoriteTasks.filter(
        (x) => x.favorite_task_id != task.favorite_task_id
      );
      this.favoriteTasks = this.favoriteTasks.filter(
        (x) => x.favorite_task_id != task.favorite_task_id
      );
      this.sortFavoriteTasks();
    },

    sortFavoriteTasks() {
      this.favoriteTasks.sort(
        (a, b) => a.name.localeCompare(b.name) || b.id - a.id
      );
    },
    sortDbTasks() {
      this.dbTasks.sort((a, b) => a.name.localeCompare(b.name) || b.id - a.id);
    },
    async isFavorite(item) {
      if (!item.standard_task_id) {
        try {
          await axios.delete(FAVORITE_TASK_API(item.favorite_task_id));
          this.removeFavoriteTask(item.favorite_task_id);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC833, status);
        }
      } else {
        try {
          await axios.put(
            UPDATE_DB_TASKS_API(item.user_id, item.standard_task_id),
            {
              is_favorite: +!item.is_favorite,
            }
          );
          this.removeDbTask(item.standard_task_id);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC709, status);
        }
      }
    },

    handleSearch() {
      const searchConvert = this.convertSearchKey(this.search);
      this.dbTasks = this.originDbTasks.filter(
        (y) =>
          isEmpty(this.search) ||
          this.convertSearchKey(y.name).includes(searchConvert)
      );
      this.favoriteTasks = this.originFavoriteTasks.filter(
        (y) =>
          isEmpty(this.search) ||
          this.convertSearchKey(y.name).includes(searchConvert)
      );
      this.sortDbTasks();
      this.sortFavoriteTasks();
    },

    isEmptySearch() {
      return isEmpty(this.search);
    },

    isBeginnerSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_BEGIN;
    },

    isExpertSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_EXPERT;
    },

    resetFilter() {
      this.dbTasks = this.originDbTasks;
      this.favoriteTasks = this.originFavoriteTasks;
    },

    submitFilter(filters) {
      this.dbTasks = this.originDbTasks.filter(
          !filters.some((g) => g.check)
      );
      this.favoriteTasks = this.originFavoriteTasks.filter(
          !filters.some((g) => g.check)
      );
      this.sortDbTasks();
      this.sortFavoriteTasks();
    },

    addFavoriteTask() {
      this.favoriteTaskId = null;
      this.showFavoriteCreate = !this.showFavoriteCreate;
    },

    editFavoriteTask(item) {
      this.favoriteTaskId = +item.favorite_task_id;
      this.showFavoriteCreate = !this.showFavoriteCreate;
    },

    editDbTask(item) {
      this.standarId = item.standard_task_id;
      this.showDatabaseTask = !this.showDatabaseTask;
    },

    removeFavoriteTask(id) {
      this.favoriteTasks = this.favoriteTasks.filter(
        (x) => x.favorite_task_id !== id
      );
      this.originFavoriteTasks = this.originFavoriteTasks.filter(
        (x) => x.favorite_task_id !== id
      );
    },

    removeDbTask(id) {
      this.dbTasks = this.dbTasks.filter((x) => x.standard_task_id !== id);
      this.originDbTasks = this.originDbTasks.filter(
        (x) => x.standard_task_id !== id
      );
    },

    async loadFavoriteListData() {
      await this.getData();
    },

    async getData() {
      this.search = "";
      try {
        this.dbTasks = [];
        this.favoriteTasks = [];
        const { data, status } = await axios.get(`${FAVORITE_TASK_API()}`);
        let resultTasks = status === STATUS_NO_CONTENT ? [] : data.result;
        resultTasks.forEach((dbTask) => {
          if (dbTask.standard_task_id) {
            this.dbTasks.push(dbTask);
          } else {
            this.favoriteTasks.push(dbTask);
          }
        });
        this.sortDbTasks();
        this.sortFavoriteTasks();
        this.originDbTasks = deepClone(this.dbTasks);
        this.originFavoriteTasks = deepClone(this.favoriteTasks);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC831, status);
      } finally {
        this.isLoading = false;
      }
    },
  },

  data() {
    return {
      search: "",
      show: false,
      showFavoriteCreate: false,
      favoriteTaskId: null,
      showDatabaseTask: false,
      dbTasks: [], // Display task
      originDbTasks: [], // database search result
      filters: [],
      taskId: null,
      standarId: null,
      favoriteTasks: [], // Display task
      originFavoriteTasks: [], // database search result
      isLoading: false,
      favoriteModel: true,
      dbModel: true,
      isSplit: false,
    };
  },
  created() {
    this.$root.$refs.scheduleFavoriteList = this;
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: calc(
    (var(--vh, 1vh) * 100) - 50px - 60px - 48px - 66px - 37px - 24px
  );
  overflow-x: hidden;
  ::v-deep .v-list-group__header {
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.87);
    .v-list-group__header__prepend-icon {
      margin-right: 0;
    }
  }
  ::v-deep
    .v-list-group--sub-group
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(-90deg);
  }
  ::v-deep
    .v-list-group--sub-group.v-list-group--active
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(0deg);
  }
  .selected {
    background:#2196F3;
    opacity: 0.7;
  }
}
.pd0 {
  padding: 0;
}
.custom-tree {
  ::v-deep .v-list-group__items {
    width: 100%;
  }
  .v-list-item__content {
    align-items: start;
    flex-wrap: nowrap;
    flex: 1 1;
    overflow: hidden;
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid #e1e1e1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
  }
  .v-list-item__icon:first-child {
    margin-right: 2px;
  }
  .v-list-item__icon {
    align-self: center;
    margin: 0;
  }
}

.sidebar-loader {
  margin-top: 10px;
}
</style>
