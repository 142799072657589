<template>
  <v-container class="container--fluid" :style="cssProps">
    <v-row v-if="$vuetify.breakpoint.mobile">
      <v-col>
        <v-tabs v-model="currentTab" @change="changeTab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab id="calendarTab" href="#mobile-tabs-calendar" class=""
            >工数管理
          </v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-monthlylist">月次予定タスク</v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-dblist" class="">DB</v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-todolist" class="">
            未完了
          </v-tab>
          <v-tab v-if="isSelf()" href="#mobile-tabs-favoritelist">お気に入り</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <div
      class="content-row"
      :class="[{ 'is-panel-collapsed': !showDrawerRight }]"
    >
      <div
        :class="[
          { 'content-calendar': isSelf() },
          { 'my-column-full': !isSelf() },
        ]"
      >
        <v-tabs-items v-model="currentTab" @change="changeTab">
          <v-tab-item value="mobile-tabs-calendar">
            <v-row v-if="$vuetify.breakpoint.mobile">
              <v-col cols="12">
                <v-combobox
                  v-if="expand === 0"
                  item-text="name"
                  item-value="id"
                  v-model="selectedUser"
                  hide-details
                  solo
                  :items="members"
                  @change="changeMember($event)"
                  :menu-props="{
                    allowOverflow: true,
                  }"
                >
                  <template slot="append-outer">
                    <ExtensiveTask
                      :solotDurationOptionsData="slotDurationItems"
                      :slotDurationData="calendarOptions.slotDuration"
                      :isSelf="isSelf()"
                      @showExplainModal="showExplainModal()"
                      @reloadSlotDuration="reloadSlotDuration($event)"
                      :canSubmit="
                        !(
                          !canSubmit ||
                          !calendarOptions.events.some(
                            (x) => x.color !== COLOR_GRAY
                          ) ||
                          (!overLapTimeError && !alignBlankTimeError.isBlank)
                        )
                      "
                      @arrangeTask="arrangeTask()"
                    >
                    </ExtensiveTask>
                  </template>
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <span>{{ item.name }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
                <div class="py-1" v-if="expand === 0">
                  <v-menu
                    ref="menu"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="selectedDate"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex align-center custom">
                        <v-btn
                          x-small
                          class="mr-1 direction-btn"
                          @click="moveSelectedDate()"
                        >
                          <v-icon color="#7F7F8F">mdi-menu-left</v-icon>
                        </v-btn>
                        <v-text-field
                          solo
                          hide-details
                          v-model="formatSelectedDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                        <v-btn
                          x-small
                          class="ml-1 direction-btn"
                          @click="moveSelectedDate(true)"
                        >
                          <v-icon color="#7F7F8F">mdi-menu-right</v-icon>
                        </v-btn>
                      </div>
                      <div class="v-center pl-2 pt-1">
                        <span class="font-weight-bold">合計：&nbsp;</span>
                        <span
                          class="font-weight-bold"
                          :class="{
                            'blue--text': !overTotalPlan,
                            'red--text': overTotalPlan,
                          }"
                          >{{ totalTime }}</span
                        >
                        <span class="font-weight-bold">/</span>
                        <span class="font-weight-bold sub-hours">{{
                          workingTime
                        }}</span>
                      </div>
                      <!-- </div> -->
                    </template>
                    <v-date-picker
                      v-model="selectedDate"
                      locale="ja"
                      no-title
                      :day-format="
                        (selectedDate) => new Date(selectedDate).getDate()
                      "
                      scrollable
                      @change="changeDate()"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>

                <div
                  class="pb-1 d-flex btn-mobile"
                  v-if="!authorizeCheck && expand === 0"
                >
                  <div class="d-flex btn-box">
                    <v-btn
                      @click="
                        statuses &&
                          statuses.length > 1 &&
                          (showNotification = !showNotification)
                      "
                      depressed
                      :class="{
                        'reject-text': isRejectText,
                      }"
                      class="mr-2"
                      tile
                      >{{ statusText }}</v-btn
                    >
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        isRejectStatus() &&
                        !this.calendarOptions.events.some((x) => !x.isPlan && !x.isBreak && x.check)
                      "
                      tile
                      class="font-weight-bold btn-reject"
                      @click="submitCalendar(true)"
                      :disabled="submitButton.active || !hasSupervisor"
                    >
                      <v-icon class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span class="text-large">差し戻し</span>
                    </v-btn>
                  </div>
                  <div
                    class="mr-2 btn-box"
                    v-if="!authorizeCheck"
                    style="position: relative"
                  >
                    <v-btn
                      v-if="(isSupervisor() && !isRejectAll) || isSelf()"
                      tile
                      @click="submitCalendar(false)"
                      :disabled="submitButton.active || !hasSupervisor"
                      class="font-weight-bold"
                    >
                      <v-icon
                        v-if="!submitButton.isReject"
                        class="blue--text mr-2"
                        right
                        >$submitorconfirm</v-icon
                      >
                      <v-icon v-else class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span>
                        {{ submitButton.text }}
                      </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                      <span
                        v-if="
                          this.calendarOptions.events.some((x) => !x.isPlan && !x.isBreak && x.check) &&
                          isRejectStatus()
                        "
                        class="text-large"
                        >差し戻し</span
                      >
                    </v-btn>
                  </div>
                  <div class="btn-box">
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        !isRejectAll &&
                        this.calendarOptions.events.some((x) => !x.isPlan && !x.isBreak && x.check)
                      "
                      class="font-weight-bold"
                      disabled
                    >
                      <v-icon class="blue--text mr-2" right
                        >$submitorconfirm</v-icon
                      >
                      <span> {{ $t("label.buttons.confirm") }} </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-else
              class="header-items"
              :class="{ 'hide-header-items': expand !== 0 }"
            >
              <v-col cols="12" v-show="expand === 0">
                <div
                  class="d-flex flex-wrap no-padding-print"
                  color="grey lighten-2"
                  flat
                  tile
                >
                  <div class="py-2">
                    <v-combobox
                      item-text="name"
                      item-value="id"
                      v-model="selectedUser"
                      hide-details
                      solo
                      :items="members"
                      @change="changeMember($event)"
                    ></v-combobox>
                  </div>
                  <div class="py-2 pl-3 d-flex align-center">
                    <v-btn
                      x-small
                      class="mr-1 direction-btn"
                      @click="moveSelectedDate()"
                    >
                      <v-icon color="#7F7F8F">mdi-menu-left</v-icon>
                    </v-btn>
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      :return-value.sync="selectedDate"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          solo
                          hide-details
                          v-model="formatSelectedDate"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selectedDate"
                        locale="ja"
                        no-title
                        :day-format="
                          (selectedDate) => new Date(selectedDate).getDate()
                        "
                        scrollable
                        @change="changeDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-btn
                      x-small
                      class="ml-1 direction-btn"
                      @click="moveSelectedDate(true)"
                    >
                      <v-icon color="#7F7F8F">mdi-menu-right</v-icon>
                    </v-btn>
                  </div>
                  <div class="py-2 pl-3">
                    <div class="v-center pt-2">
                      <span class="font-weight-bold">合計：&nbsp;</span>
                      <span
                        class="font-weight-bold"
                        :class="{
                          'blue--text': !overTotalPlan,
                          'red--text': overTotalPlan,
                        }"
                        >{{ totalTime }}</span
                      >
                      <span class="font-weight-bold">/</span>
                      <span class="font-weight-bold sub-hours">{{
                        workingTime
                      }}</span>
                    </div>
                  </div>
                  <div class="pa-2 no-print">
                    <div class="v-center d-lex ml-3">
                      <span class="mr-2">表示切替</span>
                      <v-select
                        width="30"
                        class="mr-2 select"
                        :value="calendarOptions.slotDuration"
                        :items="slotDurationItems"
                        hide-details
                        @change="reloadSlotDuration"
                        solo
                      ></v-select>
                      <v-btn @click="showExplainModal()" depressed class="ml-1">
                        <v-icon>$information</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div class="pa-2 ml-auto" v-if="!authorizeCheck">
                    <v-btn
                      v-if="isSelf()"
                      @click="
                        !(
                          !canSubmit ||
                          !calendarOptions.events.some(
                            (x) => x.color !== COLOR_GRAY
                          ) ||
                          (!overLapTimeError && !alignBlankTimeError.isBlank)
                        ) && arrangeTask()
                      "
                      :disabled="
                        !canSubmit ||
                        !calendarOptions.events.some(
                          (x) => x.color !== COLOR_GRAY
                        ) ||
                        (!overLapTimeError && !alignBlankTimeError.isBlank)
                      "
                      depressed
                      class="mr-2 no-print"
                    >
                      整列
                    </v-btn>
                    <v-btn
                      @click="
                        statuses &&
                          statuses.length > 1 &&
                          (showNotification = !showNotification)
                      "
                      depressed
                      :class="{
                        'reject-text': isRejectText,
                      }"
                      tile
                      >{{ statusText }}</v-btn
                    >
                  </div>
                  <div
                    v-if="!authorizeCheck"
                    style="position: relative"
                    class="py-2 no-print"
                  >
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        isRejectStatus() &&
                        !this.calendarOptions.events.some((x) => !x.isPlan && !x.isBreak && x.check)
                      "
                      tile
                      class="font-weight-bold mr-2"
                      @click="submitCalendar(true)"
                      :disabled="submitButton.active || !hasSupervisor"
                    >
                      <v-icon class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span class="text-large">差し戻し</span>
                    </v-btn>
                    <v-btn
                      v-if="(isSupervisor() && !isRejectAll) || isSelf()"
                      tile
                      @click="submitCalendar(false)"
                      :disabled="submitButton.active || !hasSupervisor"
                      class="font-weight-bold"
                    >
                      <v-icon
                        v-if="!submitButton.isReject"
                        class="blue--text mr-2"
                        right
                        >$submitorconfirm</v-icon
                      >
                      <v-icon v-else class="blue--text mr-2" small right
                        >$return</v-icon
                      >
                      <span>
                        {{ submitButton.text }}
                      </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                      <span
                        v-if="
                          this.calendarOptions.events.some((x) => !x.isPlan && !x.isBreak && x.check) &&
                          isRejectStatus()
                        "
                        class="text-large"
                        >差し戻し</span
                      >
                    </v-btn>
                    <v-btn
                      v-if="
                        isSupervisor() &&
                        !isRejectAll &&
                        this.calendarOptions.events.some((x) => !x.isPlan && !x.isBreak && x.check)
                      "
                      class="ml-2 font-weight-bold no-print"
                      disabled
                    >
                      <v-icon class="blue--text mr-2" right
                        >$submitorconfirm</v-icon
                      >
                      <span> {{ $t("label.buttons.confirm") }} </span>
                      <span v-if="showDraftText && isInDraft" class="ml-1 draft-text">
                        {{ $t("label.buttons.is_in_draft") }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>

            <v-row v-if="authorizeCheck">
              <v-col cols="12" class="text-center mx-auto">
                {{ authorizeMessage }}
              </v-col>
            </v-row>
            <v-row
              v-else
              id="calendar-full"
              :class="{
                'over-one-page': isOverOnePage,
              }"
            >
              <v-col
                cols="12"
                class="print-calendar"
                :class="{
                  'fullcalendar-itokuro-expand':
                    !$vuetify.breakpoint.mobile && expand !== 0,
                  'fullcalendar-itokuro': !$vuetify.breakpoint.mobile,
                  'fullcalendar-itokuro-mobile-expand':
                    $vuetify.breakpoint.mobile && expand !== 0,
                  'fullcalendar-itokuro-mobile': $vuetify.breakpoint.mobile,
                  'fullcalendar-itokuro--five-min':
                    calendarOptions.slotDuration === `00:05:00`,
                  'fullcalendar-itokuro--thirty-min':
                    calendarOptions.slotDuration === `00:30:00`,
                  'fullcalendar-itokuro--fifteen-min':
                    calendarOptions.slotDuration === `00:15:00`,
                  'fullcalendar-itokuro--sixty-min':
                    calendarOptions.slotDuration === `00:60:00`,
                }"
              >
                <FullCalendar
                  ref="fullCalendar"
                  :options="calendarOptions"
                  class="viewCalendar"
                >
                  <template v-slot:eventContent="arg">
                    <section class="m-0" v-if="arg.event.extendedProps.isPlan">
                      <v-row>
                        <v-col
                          v-bind:class="{
                            'normal-text': isBlackColor(
                              arg.event.backgroundColor
                            ),
                          }"
                          cols="12"
                        >
                          <div class="head">
                            <span v-if="isSelf()">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-simple-checkbox
                                    :disabled="!calendarOptions.editable || !canImplementation(arg.event.id)"
                                    :ripple="false"
                                    class="mt-0 d-inline-block"
                                    hide-details
                                    light
                                    @click="
                                      calendarOptions.editable && canImplementation(arg.event.id) &&
                                        compareActualTask(
                                          arg.event.id,
                                          $event,
                                          calendarOptions.events.find(
                                            (x) =>
                                              (x.id === +arg.event.id &&
                                                x.isPlan) ||
                                              (x.isBreak && x.isPlan)
                                          )
                                        )
                                    "
                                    v-model="
                                      calendarOptions.events.find(
                                        (x) =>
                                          (x.id === +arg.event.id &&
                                            x.isPlan) ||
                                          (x.isBreak && x.isPlan)
                                      ).isCompleted
                                    "
                                    v-on="on"
                                  ></v-simple-checkbox>
                                </template>
                                <span>実施</span>
                              </v-tooltip>
                            </span>
                            <span
                              class="name-detail text-truncate"
                              :class="{
                                'min-w-100':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                            >
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                              {{ arg.event.title }}
                              {{ arg.event.extendedProps.detail }}
                            </span>
                            <span
                              class="d-flex align-center"
                              v-if="
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <
                                30
                              "
                            >
                              <span
                                class="time-text-truncate"
                                :class="{
                                  'min-w-48':
                                    getTaskLength(
                                      arg.event.title,
                                      arg.event.extendedProps.detail
                                    ) > 30,
                                }"
                              >
                                {{ arg.event.extendedProps.timeText }}
                              </span>
                              <span v-if="isDbTask(arg.event.extendedProps)">
                                <span
                                  v-if="
                                    isBeginnerSkill(arg.event.extendedProps)
                                  "
                                >
                                  <v-icon
                                    class="beginnerTaskIcon"
                                    :class="[
                                      {
                                        'position-inside':
                                          calendarOptions.slotDuration ===
                                            '00:60:00' &&
                                          arg.event.extendedProps.cost *
                                            arg.event.extendedProps.count ===
                                            30,
                                      },
                                    ]"
                                  >
                                    $beginnertask
                                  </v-icon>
                                </span>
                                <span
                                  v-if="isExpertSkill(arg.event.extendedProps)"
                                >
                                  <v-icon
                                    class="expertTaskIcon"
                                    :class="[
                                      {
                                        'position-inside':
                                          calendarOptions.slotDuration ===
                                            '00:60:00' &&
                                          arg.event.extendedProps.cost *
                                            arg.event.extendedProps.count ===
                                            30,
                                      },
                                    ]"
                                  >
                                    $experttask
                                  </v-icon>
                                </span>
                              </span>
                            </span>
                          </div>
                          <span
                            class="d-flex align-center"
                            v-if="
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30
                            "
                          >
                            <span class="time-text-truncate time-text-margin">
                              {{ arg.event.extendedProps.timeText }}
                            </span>
                            <span v-if="isDbTask(arg.event.extendedProps)">
                              <span
                                v-if="isBeginnerSkill(arg.event.extendedProps)"
                              >
                                <v-icon
                                  class="beginnerTaskIcon"
                                  :class="[
                                    {
                                      'position-inside':
                                        calendarOptions.slotDuration ===
                                          '00:60:00' &&
                                        arg.event.extendedProps.cost *
                                          arg.event.extendedProps.count ===
                                          30,
                                    },
                                  ]"
                                >
                                  $beginnertask
                                </v-icon>
                              </span>
                              <span
                                v-if="isExpertSkill(arg.event.extendedProps)"
                              >
                                <v-icon
                                  class="expertTaskIcon"
                                  :class="[
                                    {
                                      'position-inside':
                                        calendarOptions.slotDuration ===
                                          '00:60:00' &&
                                        arg.event.extendedProps.cost *
                                          arg.event.extendedProps.count ===
                                          30,
                                    },
                                  ]"
                                >
                                  $experttask
                                </v-icon>
                              </span>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </section>
                    <section v-else>
                      <v-row v-if="isSelf()">
                        <v-col
                          :class="{
                            'normal-text': isBlackColor(
                              arg.event.backgroundColor
                            ),
                          }"
                        >
                          <div class="head">
                            <span
                              class="icon-inline"
                              v-if="
                                arg.event.extendedProps.is_remand_previous ===
                                  1 || arg.event.extendedProps.is_remand === 1
                              "
                            >
                              <v-icon small> $remandtask </v-icon>
                            </span>
                            <span
                              class="name-detail text-truncate"
                              :class="{
                                'min-w-100':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                            >
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                              {{ arg.event.title }}
                              {{ arg.event.extendedProps.detail }}
                            </span>

                            <span
                              class="time-text-truncate"
                              :class="{
                                'min-w-48':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                              v-if="
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <
                                30
                              "
                            >
                              {{ arg.event.extendedProps.timeText }}
                            </span>
                          </div>
                          <span
                            class="time-text-truncate time-text-margin"
                            v-if="
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30
                            "
                          >
                            {{ arg.event.extendedProps.timeText }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col
                          :class="{
                            'normal-text': isBlackColor(
                              arg.event.backgroundColor
                            ),
                          }"
                        >
                          <div class="head">
                            <span
                              class="icon-inline"
                              v-if="
                                arg.event.extendedProps.is_remand_previous ===
                                  1 || arg.event.extendedProps.is_remand === 1
                              "
                            >
                              <v-icon small> $remandtask </v-icon>
                            </span>
                            <span
                              v-if="
                                arg.event.extendedProps.showCheck &&
                                isRejectStatus
                              "
                              style="
                                display: inline-block;
                                vertical-align: middle;
                              "
                            >
                              <v-simple-checkbox
                                :ripple="false"
                                class="mt-0"
                                hide-details
                                v-if="
                                  !arg.event.extendedProps.isBreak &&
                                  arg.event.id &&
                                  isRejectStatus()
                                "
                                @click="
                                  checkActualTask(
                                    $event,
                                    !canApproveRemand,
                                    +arg.event.id
                                  )
                                "
                                :disabled="!canApproveRemand"
                                v-model="
                                  calendarOptions.events.find(
                                    (x) =>
                                      !x.isBreak &&
                                      x.id == arg.event.id &&
                                      !x.isPlan
                                  ).check
                                "
                              ></v-simple-checkbox>
                            </span>

                            <span
                              class="name-detail text-truncate"
                              :class="{
                                'min-w-100':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                            >
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                              {{ arg.event.title }}
                              {{ arg.event.extendedProps.detail }}
                            </span>
                            <span
                              class="time-text-truncate"
                              :class="{
                                'min-w-48':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                              v-if="
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <
                                30
                              "
                            >
                              {{ arg.event.extendedProps.timeText }}
                            </span>
                          </div>
                          <span
                            class="time-text-truncate time-text-margin"
                            v-if="
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30
                            "
                          >
                            {{ arg.event.extendedProps.timeText }}
                          </span>
                        </v-col>
                      </v-row>
                    </section>
                  </template>
                </FullCalendar>
                <FullCalendar
                  ref="printCalendar"
                  :options="printCalendarOptions"
                  class="printCalendar"
                  v-if="!isMobile"
                >
                  <template v-slot:eventContent="arg">
                    <section class="m-0" v-if="arg.event.extendedProps.isPlan">
                      <v-row>
                        <v-col
                          v-bind:class="{
                            'normal-text': isBlackColor(
                              arg.event.backgroundColor
                            ),
                          }"
                          cols="12"
                        >
                          <div class="head">
                            <span v-if="isSelf()">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-simple-checkbox
                                    :disabled="!calendarOptions.editable || !canImplementation(arg.event.id)"
                                    :ripple="false"
                                    class="mt-0 d-inline-block"
                                    hide-details
                                    light
                                    @click="
                                      calendarOptions.editable && canImplementation(arg.event.id) &&
                                        compareActualTask(
                                          arg.event.id,
                                          $event,
                                          calendarOptions.events.find(
                                            (x) =>
                                              (x.id === +arg.event.id &&
                                                x.isPlan) ||
                                              (x.isBreak && x.isPlan)
                                          )
                                        )
                                    "
                                    v-model="
                                      calendarOptions.events.find(
                                        (x) =>
                                          (x.id === +arg.event.id &&
                                            x.isPlan) ||
                                          (x.isBreak && x.isPlan)
                                      ).isCompleted
                                    "
                                    v-on="on"
                                  ></v-simple-checkbox>
                                </template>
                                <span>実施</span>
                              </v-tooltip>
                            </span>
                            <span
                              class="name-detail text-truncate"
                              :class="{
                                'min-w-80':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                            >
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                              {{ arg.event.title }}
                              {{ arg.event.extendedProps.detail }}
                            </span>
                            <span
                              class="d-flex align-center"
                              v-if="
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <
                                30
                              "
                            >
                              <span
                                class="time-text-truncate"
                                :class="{
                                  'min-w-28':
                                    getTaskLength(
                                      arg.event.title,
                                      arg.event.extendedProps.detail
                                    ) > 30,
                                }"
                              >
                                {{ arg.event.extendedProps.timeText }}
                              </span>
                              <span v-if="isDbTask(arg.event.extendedProps)">
                                <span
                                  v-if="
                                    isBeginnerSkill(arg.event.extendedProps)
                                  "
                                >
                                  <v-icon class="beginnerTaskIcon">
                                    $beginnertask
                                  </v-icon>
                                </span>
                                <span
                                  v-if="isExpertSkill(arg.event.extendedProps)"
                                >
                                  <v-icon class="expertTaskIcon">
                                    $experttask
                                  </v-icon>
                                </span>
                              </span>
                            </span>
                          </div>
                          <span
                            class="d-flex align-center"
                            v-if="
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30
                            "
                          >
                            <span
                              :class="{
                                'time-text-truncate': isTimeTextTruncate(
                                  arg.event.extendedProps.cost,
                                  arg.event.extendedProps.count
                                ),
                                'wrap-text': isWrapText(
                                  arg.event.extendedProps.cost,
                                  arg.event.extendedProps.count
                                ),
                              }"
                            >
                              {{ arg.event.extendedProps.timeText }}
                            </span>
                            <span v-if="isDbTask(arg.event.extendedProps)">
                              <span
                                v-if="isBeginnerSkill(arg.event.extendedProps)"
                              >
                                <v-icon class="beginnerTaskIcon">
                                  $beginnertask
                                </v-icon>
                              </span>
                              <span
                                v-if="isExpertSkill(arg.event.extendedProps)"
                              >
                                <v-icon class="expertTaskIcon">
                                  $experttask
                                </v-icon>
                              </span>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </section>
                    <section v-else>
                      <v-row v-if="isSelf()">
                        <v-col
                          :class="{
                            'normal-text': isBlackColor(
                              arg.event.backgroundColor
                            ),
                          }"
                        >
                          <div class="head">
                            <span
                              class="icon-inline"
                              v-if="
                                arg.event.extendedProps.is_remand_previous ===
                                  1 || arg.event.extendedProps.is_remand === 1
                              "
                            >
                              <v-icon small> $remandtask </v-icon>
                            </span>

                            <span
                              class="name-detail text-truncate"
                              :class="{
                                'min-w-80':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                            >
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                              {{ arg.event.title }}
                              {{ arg.event.extendedProps.detail }}
                            </span>

                            <span
                              class="time-text-truncate"
                              :class="{
                                'min-w-28':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                              v-if="
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <
                                30
                              "
                            >
                              {{ arg.event.extendedProps.timeText }}
                            </span>
                          </div>
                          <span
                            :class="{
                              'time-text-truncate': isTimeTextTruncate(
                                arg.event.extendedProps.cost,
                                arg.event.extendedProps.count
                              ),
                              'wrap-text': isWrapText(
                                arg.event.extendedProps.cost,
                                arg.event.extendedProps.count
                              ),
                            }"
                            v-if="
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30
                            "
                          >
                            {{ arg.event.extendedProps.timeText }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col
                          :class="{
                            'normal-text': isBlackColor(
                              arg.event.backgroundColor
                            ),
                          }"
                        >
                          <div class="head">
                            <span
                              class="icon-inline"
                              v-if="
                                arg.event.extendedProps.is_remand_previous ===
                                  1 || arg.event.extendedProps.is_remand === 1
                              "
                            >
                              <v-icon small> $remandtask </v-icon>
                            </span>
                            <span
                              v-if="
                                arg.event.extendedProps.showCheck &&
                                isRejectStatus
                              "
                              style="
                                display: inline-block;
                                vertical-align: middle;
                              "
                            >
                              <v-simple-checkbox
                                :ripple="false"
                                class="mt-0"
                                hide-details
                                v-if="
                                  !arg.event.extendedProps.isBreak &&
                                  arg.event.id &&
                                  isRejectStatus()
                                "
                                @click="
                                  checkActualTask(
                                    $event,
                                    !canApproveRemand,
                                    +arg.event.id
                                  )
                                "
                                :disabled="!canApproveRemand"
                                v-model="
                                  calendarOptions.events.find(
                                    (x) =>
                                      !x.isBreak &&
                                      x.id == arg.event.id &&
                                      !x.isPlan
                                  ).check
                                "
                              ></v-simple-checkbox>
                            </span>

                            <span
                              class="name-detail text-truncate"
                              :class="{
                                'min-w-80':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                            >
                            <span v-if="arg.event.extendedProps.category_secondary_name">
                              {{ arg.event.extendedProps.category_secondary_name }}_
                            </span>
                              {{ arg.event.title }}
                              {{ arg.event.extendedProps.detail }}
                            </span>
                            <span
                              class="time-text-truncate"
                              :class="{
                                'min-w-28':
                                  getTaskLength(
                                    arg.event.title,
                                    arg.event.extendedProps.detail
                                  ) > 30,
                              }"
                              v-if="
                                arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count <
                                30
                              "
                            >
                              {{ arg.event.extendedProps.timeText }}
                            </span>
                          </div>
                          <span
                            :class="{
                              'time-text-truncate': isTimeTextTruncate(
                                arg.event.extendedProps.cost,
                                arg.event.extendedProps.count
                              ),
                              'wrap-text': isWrapText(
                                arg.event.extendedProps.cost,
                                arg.event.extendedProps.count
                              ),
                            }"
                            v-if="
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30
                            "
                          >
                            {{ arg.event.extendedProps.timeText }}
                          </span>
                        </v-col>
                      </v-row>
                    </section>
                  </template>
                </FullCalendar>
              </v-col>

              <file-list
                :inputFiles="selectedFiles"
                :show="openFileDialog"
              ></file-list>

              <free-task
                :show="showFreeTask"
                :inputTask="copyFreeTask"
                :istartTime="startFreeTime"
                :iendTime="endFreeTime"
                :iactualId="actualId"
                @createTaskSuccess="createTaskSuccess($event)"
              ></free-task>

              <break-time
                :actualId="actualId"
                :start="actualStartBreakTime"
                :end="actualEndBreakTime"
                :show="showBreakTime"
                :isPlan=false
                :can-view="!isSelf() || !this.isNoSubmmit()"
                @changeBreakTime="updateBreakTime($event)"
              ></break-time>

              <action-free-task
                @duplicateTask="duplicateFreeTask($event)"
                @archiveTask="archiveFreeTask($event)"
                @updateTaskSuccess="updateTaskSuccess($event)"
                @deleteTask="deleteTaskSuccess($event)"
                :show="showFreeTaskEdit"
                :scheduleIdList="scheduleTaskIdList"
                :scheduleComments="scheduleComments"
                @deleteComment="deleteComment($event)"
                @setFavorite="setFreeFavorite($event)"
                :actualId="actualId"
                :actualTaskId="selectedActualTask"
                :planId="planId"
              ></action-free-task>

              <view-task
                :show="showViewTask"
                :scheduleComments="scheduleComments"
                @deleteComment="deleteComment($event)"
                :actualId="actualId"
                :actualTaskId="selectedActualTask"
                :planId="planId"
                :viewType="'ACTUAL'"
                @updateTaskSuccess="updateTaskSuccess($event)"
              ></view-task>

              <action-database-task
                :actualId="actualId"
                :actualTaskId="selectedActualTask"
                :scheduleComments="scheduleComments"
                @deleteComment="deleteComment($event)"
                :show="showDatabaseTaskEdit"
                :scheduleIdList="scheduleTaskIdList"
                @duplicateTask="duplicateDbTask($event)"
                @archiveTask="archiveDbTask($event)"
                @archiveDbSplitTask="archiveDbSplitTask($event)"
                @updateTaskSuccess="updateTaskSuccess($event)"
                @deleteTask="deleteTaskSuccess($event)"
                @setFavorite="setFavorite($event)"
                :planId="planId"
              ></action-database-task>

              <new-database-task
                :show="showNewDbTask"
                :iactualId="actualId"
                :inputTask="copyDbTask"
                :istartTime="startFreeTime"
                :iendTime="endFreeTime"
                @createTaskSuccess="createTaskSuccess($event)"
              ></new-database-task>

              <plan-break-time
                :can-view="true"
                :show="showPlanBreakTime"
                :start="planStartBreakTime"
                :end="planEndBreakTime"
                :isPlan=false
              ></plan-break-time>

              <plan-task
                :show="showPlanTask"
                :planId="planId"
                :taskId="selectedPlanTask"
                :canViewComment="canViewComment"
                @deleteComment="deletePlanComment($event)"
                :viewType="'ACTUAL'"
              ></plan-task>
            </v-row>
          </v-tab-item>
          <v-tab-item
            value="mobile-tabs-monthlylist"
            v-if="isSelf() && $vuetify.breakpoint.mobile"
          >
            <MonthlyScheduleTaskListTab
              ref="monthlyList"
              :userId="userId"
              :planId="planId"
              :isPlan=false
              :selectMonthDate="selectedDate"
              @drop-monthly-task-list-tab-item="handleDropTaskFromMonthly"
              @activeCalendarTab="activeCalendarTab"
            ></MonthlyScheduleTaskListTab>
          </v-tab-item>

          <v-tab-item
            value="mobile-tabs-dblist"
            v-if="$vuetify.breakpoint.mobile && isSelf()"
          >
            <DBTaskListTab
              @drop-db-task-list-tab-item="handleDropTaskFromDB"
              @activeCalendarTab="activeCalendarTab"
              :userId="userId"
              ref="dbTaskList"
            ></DBTaskListTab>
          </v-tab-item>

          <v-tab-item
            value="mobile-tabs-todolist"
            v-if="$vuetify.breakpoint.mobile && isSelf()"
          >
            <TodoListTab
              @drop-todo-task-list-tab-item="handleDropTaskFromTodo"
              @activeCalendarTab="activeCalendarTab"
              :userId="userId"
              :isBreakTask="haveBreakTime"
              ref="todoTaskList"
            ></TodoListTab>
          </v-tab-item>

          <v-tab-item
            value="mobile-tabs-favoritelist"
            v-if="isSelf() && $vuetify.breakpoint.mobile"
          >
            <FavoriteTaskListTab
              @drop-favorite-list-tab-item="handleDropTaskFromFavorite"
              @activeCalendarTab="activeCalendarTab"
              ref="favoriteList"
              :userId="userId"
              :planId = "planId"
              :isPlan=false
            ></FavoriteTaskListTab>
          </v-tab-item>

        </v-tabs-items>
      </div>

      <div
        v-show="!$vuetify.breakpoint.mobile && isSelf()"
        class="content-db-list ml-2"
      >
        <v-row class="mr-1">
          <v-col :cols="showDrawerRight ? '2' : '10'">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('monthly-task')"
                    >$monthlyscheduletasklist</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('db-task')"
                    >$dbtasklist</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('todo-task')"
                    >$todolist</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon small @click="toggleDrawerRight('favorite-task')"
                    >$favoriteactive</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col cols="10" id="dragElements">
            <DBTaskList
              v-show="showDbTasks"
              v-model="showDrawerRight"
              @close="showDbTasks = false"
              :userId="userId"
              ref="dbTaskList"
            ></DBTaskList>
            <TodoList
              v-show="showTodoList"
              :isBreakTask="haveBreakTime"
              @close="showTodoList = false"
              v-model="showDrawerRight"
              :userId="userId"
              ref="todoTaskList"
            ></TodoList>
            <FavoriteList
              ref="favoriteList"
              :planId="planId"
              :isPlan=false
              @close="showFavoriteList = false"
              v-show="showFavoriteList"
              v-model="showDrawerRight"
            ></FavoriteList>
            <MonthlyScheduleTaskList
              ref="monthlyList"
              :planId="planId"
              :isPlan=false
              :selectMonthDate="selectedDate"
              @close="showMonthlyList = false"
              v-show="showMonthlyList"
              v-model="showDrawerRight"
            ></MonthlyScheduleTaskList>
          </v-col>
        </v-row>
      </div>
    </div>

    <DailyComment
      @submit="submitActual($event)"
      @saveDraft="saveDraftActual($event)"
      :show="showDailyComment"
      :inputComment="inputComment"
      :inputSubComment="inputSubComment"
      :selectedDate="selectedDate"
      :tasks="missingTasks"
      :addTasks="addTasks"
      :activeTask="activeTask"
      :notifications="oldComments"
      :showOldComments="showOldComments"
    ></DailyComment>
    <DailyApproval
      @confirm="confirm($event)"
      @saveDraft="saveDraftActual($event)"
      :selectedDate="selectedDate"
      :show="showDailyApproval"
      :inputComment="inputComment"
      :ownerName="ownerName"
      :notifications="oldComments"
      :showOldComments="showOldComments"
    ></DailyApproval>
    <DailyReject
      @reject="reject($event)"
      :selectedDate="selectedDate"
      :show="showDailyReject"
      :items="checkedActualItems"
      :ownerName="ownerName"
    ></DailyReject>
    <Notification
      :show="showNotification"
      :notifications="statuses"
      :date="selectedDate"
      @closeNotification="closeNotification"
    >
    </Notification>
    <MissingTask
      @close="openMissingTask = false"
      :show="openMissingTask"
      :tasks="missingTasks"
      :addTasks="addTasks"
      :subComment="subComment"
    ></MissingTask>
    <ChangeCompletedTask
      @submit="changeCompleted($event)"
      @close="openChangeCompletedTask = false"
      :show="openChangeCompletedTask"
      :selectedDate="selectedDate"
      :tasks="changeCompletedTasks"
      :inputComment="changeCompletedComment"
    ></ChangeCompletedTask>
    <ExplainTask :show="showExplain"> </ExplainTask>
  </v-container>
</template>

<script>
import DBTaskList from "./modal/DBTaskList";
import DBTaskListTab from "./tabs/DBTaskListTab";
import TodoList from "./modal/TodoList";
import TodoListTab from "./tabs/TodoListTab";
import FavoriteList from "../schedule/modal/FavoriteList";
import FavoriteTaskListTab from "../schedule/tabs/FavoriteTaskListTab";
import DailyComment from "./modal//DailyComment";
import DailyApproval from "./modal/DailyApproval";
import DailyReject from "./modal/DailyReject";
import { Draggable } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import BreakTime from "./modal/BreakTime";
import FreeTask from "./modal/FreeTask";
import ActionDatabaseTask from "./modal/ActionDatabaseTask";
import ActionFreeTask from "./modal/ActionFreeTask";
import ViewTask from "./modal/ViewTask";
import PlanBreakTime from "../schedule/modal/BreakTime";
import PlanTask from "../schedule/modal/ViewTask";
import FileList from "./modal/FileList";
import MissingTask from "./modal/MissingTask";
import ChangeCompletedTask from "./modal/ChangeCompletedTask";
import Notification from "./modal/Notification.vue";
import ExplainTask from "./modal/ExplainTask";
import ExtensiveTask from "./modal/ExtensiveTask";
import MonthlyScheduleTaskList from "../schedule/modal/MonthlyScheduleTaskList.vue";
import MonthlyScheduleTaskListTab from "../schedule/tabs/MonthlyScheduleTaskListTab.vue";
import * as Role from "../../constants/role";
import axios from "axios";
import {
  formatDateTime,
  parseDateTime,
  addDays,
  toMinute,
  toHHmm,
  formatTime,
  getDayOfWeek,
} from "../../helpers/datetime-helpers";
import {
  GET_MEMBERS_API,
  GET_SCHEDULE_API,
  ACTUAL_TASK_API,
  ACTUAL_API,
  TODO_TASK_API,
  ACTUAL_SORT_API,
  GET_OR_DEL_ACTUAL_TASK_API,
  NOTIFICATION_API,
  INCOMPLETE_TASK_API,
  ACTUAL_DRAFT_API,
} from "../../constants/api";
import { mapGetters } from "vuex";
import {
  debounce_leading,
  deepClone,
  isEmpty,
  isEmptyNumber,
  isOverLap,
  orderBy,
} from "../../helpers/helpers";
import { STATUS_NO_CONTENT } from "../../constants/status";
import NewDatabaseTask from "./modal/NewDatabaseTask.vue";
import * as SystemConstant from "../../constants/system";
import { apiErrorMessages } from "../../helpers/messages";
import {
  FC108,
  FC805,
  FC816,
  FC818,
  FC819,
  FC807,
  FC822,
  FC806,
  FC502,
  FC828,
  FC501
} from "../../constants/fc";
import {
  HHmm1,
  HHmm2,
  HHmm3,
  HHmmss1,
  HHmmss2,
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMMdd3,
  yyyyMMddHHmm1,
  yyyyMMddHHmm3,
  yyyyMMddHHmmss1,
  yyyyMMddHHmmss2,
} from "../../constants/df";
import { adjustMinEnd } from "../../helpers/system";
import {
  COLOR_DB_TASK_COMPLETE,
  COLOR_DB_TASK_NOT_COMPLETE,
  COLOR_DEFAULT,
  COLOR_FREE_TASK_COMPLETE,
  COLOR_FREE_TASK_NOT_COMPLETE,
  COLOR_GRAY,
  COLOR_FREE_TASK,
  // COLOR_RED,
  //  COLOR_REMAND,
  MARK_OUPUT_COMPLETE,
  MARK_OUPUT_DEFAULT,
  MARK_OUPUT_NOT_COMPLETE,
  TIME_TEXT_ZERO,
  COLOR_DB_TASK_WITHIN_1_2,
  COLOR_FREE_TASK_WITHIN_1_2,
  COLOR_DB_TASK_GREATER_1_2,
  COLOR_FREE_TASK_GREATER_1_2,
} from "../../constants/color";
import {
  CALENDAR_PRINT_LENGHT_MM,
  ACTUAL_DEFAULT_SCALE,
  ACTUAL_DEFAULT_SCALE_1_PAGE,
  ACTUAL_SLOT_30_SCALE,
  ACTUAL_SLOT_60_SCALE,
} from "../../constants/calendar";
import { timeValidator } from "@/helpers/validator";
import Cookies from "js-cookie";
import { calendarMixin } from "@/mixins/calendar";

export default {
  middleware: Role.AUTH,
  metaInfo() {
    return { title: this.$t("title.daily-actual") };
  },
  components: {
    DBTaskList,
    DBTaskListTab,
    TodoList,
    TodoListTab,
    DailyComment,
    DailyApproval,
    FullCalendar,
    FileList,
    BreakTime,
    FreeTask,
    ActionDatabaseTask,
    NewDatabaseTask,
    ActionFreeTask,
    PlanBreakTime,
    PlanTask,
    DailyReject,
    ViewTask,
    MissingTask,
    ChangeCompletedTask,
    Notification,
    ExplainTask,
    ExtensiveTask,
    FavoriteList,
    FavoriteTaskListTab,
    MonthlyScheduleTaskList,
    MonthlyScheduleTaskListTab,
  },
  mixins: [calendarMixin],
  data() {
    return {
      COLOR_GRAY: COLOR_GRAY,
      canSubmitCancel: false,
      canApproveRemand: false,
      canSubmit: false,
      hasCompletedChange: false,
      slotDurationItems: [
        { value: "00:05:00", text: "5分" },
        { value: "00:15:00", text: "15分" },
        { value: "00:30:00", text: "30分" },
        { value: "00:60:00", text: "60分" },
      ],
      showExplain: false,
      scrollToTime: "",
      expand: 0,
      showNotification: false,
      statuses: [],
      ownerName: "",
      currentTab: "mobile-tabs-calendar",
      menuDate: false,
      selectedDate: formatDateTime(new Date()),
      mini: true,
      search: "",
      showDbTasks: false,
      showDrawerRight: false,
      members: [],
      showTodoList: false,
      showMonthlyList:false,
      breadcrumbs: [
        {
          text: "",
          href: "",
          disabled: true,
        },
        {
          text: "日次実績",
          href: "",
          disabled: true,
        },
      ],
      calendarOptions: {
        slotMinTime: "08:00:00",
        slotMaxTime: "22:00:00",
        scrollTime: false,
        slotDuration: "00:15:00",
        loading: this.setLoaded,
        height: "parent",
        locale: "es",
        plugins: [timeGridPlugin, interactionPlugin],
        initialView: "timeGridTwoDay",
        headerToolbar: false,
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        allDaySlot: false,
        dayHeaders: true,
        dayHeaderContent: this.changeDayHeaderContent,
        dayHeaderClassNames: this.changeDayHeaderClassNames,
        views: {
          timeGridTwoDay: {
            type: "timeGrid",
            dayCount: 2,
          },
        },
        initialEvents: [],
        events: [],
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        drop: this.handlelDrop,
        eventAllow: this.allowEventDrop,
        eventDrop: this.calendarDrop,
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
        },
        eventOrder: "start_time,id",
        eventResize: function (eventResizeInfo) {
          eventResizeInfo.revert();
        },
        datesSet: this.getTotalTime,
      },
      startFreeTime: "",
      endFreeTime: "",
      openFileDialog: false,
      showFreeTaskEdit: false,
      showBreakTime: false,
      showDatabaseTaskEdit: false,
      showFreeTask: false,
      showPlanTask: false,
      showPlanBreakTime: false,
      userId: "",
      planId: "",
      actualId: "",
      selectedTask: {},
      copyDbTask: {},
      copyFreeTask: {},
      planTasks: [],
      actuaTasks: [],
      planStartBreakTime: "",
      planEndBreakTime: "",
      actualStartBreakTime: "",
      actualEndBreakTime: "",
      scheduleTaskIdList: [],
      selectedActualTask: null,
      selectedPlanTask: null,
      statusText: "未提出",
      submitButton: {
        text: "提出",
        active: false,
        isReject: false,
      },
      selectedFiles: [],
      authorizeMessage: "",
      authorizeCheck: false,
      showNewDbTask: false,
      selectedUser: "",
      scheduleComments: [],
      showDailyComment: false,
      showDailyApproval: false,
      showDailyReject: false,
      showViewTask: false,
      canViewComment: false,
      isRenderCompleted: false,
      startWorkTime: "",
      endWorkTime: "",
      originalDate: "",
      planPhaseId: "",
      openMissingTask: false,
      openChangeCompletedTask: false,
      issueTasks: [],
      changeCompletedTasks: [],
      subComment: "",
      changeCompletedComment: "",
      scrollTop: null,
      preSlotDuration: "00:15:00",
      inputComment: "",
      inputSubComment: "",
      min: 5,
      unCompletedPlans: [],
      isOverOnePage: false,
      timeGridLength: 0,
      activeTask: false,
      isRejectAll: false,
      showOldComments: false,
      oldComments: [],
      isInDraft: false,
      showDraftText: false,
      showFavoriteList: false,
      haveBreakTime: false, // 休憩タスクアーカイブ確認
      listNotification: [],
    };
  },
  methods: {
    /**
     * reload slot duration
     * @param {event} [e]
     */
    reloadSlotDuration(e) {
      this.printCalendarOptions.slotDuration = e;
      if (e === "00:30:00") {
        this.min = 15;
      } else if (e === "00:60:00") {
        this.min = 18;
      } else {
        this.min = 5;
      }
      this.calendarOptions.events
        .filter((y) => y.isPlan)
        .map((x) => {
          x.start = x.start_real;
          x.end = formatDateTime(
            parseDateTime(
              this.selectedDate +
                " " +
                adjustMinEnd(x.start_time, x.end_time, HHmmss1, this.min), // adjustMinEnd HHmmss1
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          );
        });
      this.calendarOptions.events
        .filter((y) => !y.isPlan)
        .map((x) => {
          x.start = x.start_real;
          x.end = formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                x.start_time,
                x.end_time,
                HHmmss1,
                this.min
              )}`, // adjustMinEnd HHmmss1
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          );
        });

      let scrollTopPosition =
        document.getElementsByClassName("fc-scroller")[1].scrollTop;
      switch (true) {
        case this.preSlotDuration === "00:30:00":
          switch (true) {
            case e === "00:05:00":
              this.scrollTop = scrollTopPosition * 6;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition * 3;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 1.1;
              break;
          }
          break;
        case this.preSlotDuration === "00:05:00":
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition / 6;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition / 2;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 6.5;
              break;
          }
          break;
        case this.preSlotDuration === "00:15:00":
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition / 3;
              break;
            case e === "00:05:00":
              this.scrollTop = scrollTopPosition * 2;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 3.2;
              break;
          }
          break;
        // this.preSlotDuration === "00:60:00"
        default:
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition * 1.1;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition * 3.2;
              break;
            // e === "00:05:00"
            default:
              this.scrollTop = scrollTopPosition * 6.5;
              break;
          }
          break;
      }
      this.calendarOptions.slotDuration = e;
      this.preSlotDuration = e;
      if (!isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[1];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }

          this.scrollTop = null;
        }, 0.0005);
      }
      Cookies.set("slotDurationItem" + this.loginInfo?.user_id, e, {
        expires: 365,
      });

      // change slot min max time
      let events = this.calendarOptions.events;
      this.adjustTasks(events, this.selectedDate, this.min);
      this.changeSlotMinMax(this.calendarOptions, events, this.startWorkTime, this.endWorkTime);
    },
    /**
     * Add event from DB list
     * @param {object} [dbTask]
     */
    async addEventFromDbList(dbTask) {
      try {
        let actualTasks = [
          {
            schedule_task_id: dbTask.schedule_task_id,
            name: dbTask.name,
            description: dbTask.description,
            category_secondary_id: dbTask.category_secondary_id,
            cost: +dbTask.cost,
            count: 1,
            is_need_output: +dbTask.is_need_output || 0,
            output_detail: dbTask.output_detail,
            is_favorite: +dbTask.is_favorite,
            comments: [],
            files: [],
            standard_task_id: dbTask.id,
            standard_task_revision: dbTask.revision,
            start_time: null,
            is_completed: 0,
            is_remand: 0,
            detail: dbTask.detail || "",
            skill_level_id: dbTask.skill_level_id || null,
          },
        ];
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        //登録時のDBタスクの回数は1回
        dbTask.count = 1;

        let calendarTask = {
          standard_task_id: dbTask.id,
          standard_task_revision: dbTask.revision,
          schedule_task_id: dbTask.schedule_task_id,
          title: dbTask.name,
          start: formatDateTime(
            addDays(
              `${this.selectedDate} ${data.result[0].start_time}`,
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                data.result[0].start_time,
                data.result[0].end_time,
                HHmm3,
                this.min
              )}`,
              1,
              yyyyMMddHHmmss1
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmmss2
          ),

          end_real: formatDateTime(
            addDays(
              `${this.selectedDate} ${data.result[0].end_time}`,
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          check: false,
          showCheck: this.isSupervisor(),
          isCompleted: false,
          //className: this.getMarkOutput(dbTask),
          // className: "db-task",
          className: this.isDbTaskByType(dbTask) ? "actual-unPlan-task" : "",
          color: this.isDbTaskByType(dbTask)
            ? COLOR_DB_TASK_NOT_COMPLETE
            : COLOR_GRAY,
          isPlan: false,
          markText: this.isMark(dbTask),
          timeText: this.getWorkTime(dbTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: dbTask.cost,
          count: 1,
          is_need_output: dbTask.is_need_output || 0,
          is_completed: 0,
          category_secondary_name: dbTask.category_secondary_name,
          category_secondary_id: dbTask.category_secondary_id,
          category_primary_id: dbTask.category_primary_id,
          is_remand: 0,
          is_remand_previous: 0,
          actual_free_task_id: null,
          detail: dbTask.detail || "",
          is_source_completed: 0,
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(
            "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
            {
              width: "600px",
            }
          );
        } else {
          apiErrorMessages(this, FC805, status);
        }
      }
    },

    /**
     * Add break task from todo list
     * @param {object} [value]
     * @return {void}
     */
     async addEventFromTodoListbreak() {
      try {
        const { data } = await axios.put(ACTUAL_API(this.actualId), {
          start_break_time: null,
          end_break_time: null,
          date: formatDateTime(this.selectedDate, yyyyMMdd1),
          schedule_id: this.planId,
          phase_type: SystemConstant.PHASE_TYPE_UNSUBMITTED,
        });
        this.haveBreakTime = false;
        if (this.showTodoList){
          this.$root.$refs.actualTodoList.isBreakTask = false;
        }
        
        let t = {
          title: "休憩",
          start: formatDateTime(
            addDays(
              `${this.selectedDate} ${data.result.start_break_time}`,
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                data.result.start_break_time,
                data.result.end_break_time,
                HHmmss1,
                this.min
              )}`, // adjustMinEnd HHmmss1
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(
              `${this.selectedDate} ${data.result.end_break_time}`,
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          ),
          color: COLOR_GRAY,
          isBreak: true,
          isPlan: false,
          isCompleted: false,
          count: 1,
          cost: this.diffTime(
            data.result.start_break_time,
            data.result.end_break_time
          ),
          timeText: this.getWorkTime({
            count: 1,
            cost: this.diffTime(
              data.result.start_break_time,
              data.result.end_break_time
            ),
            unit: "break",
          }),
          disabled: false,
        };
        t.start_time = formatDateTime(t.start, HHmm3);
        t.end_time = formatDateTime(t.end_real, HHmm3);
        this.calendarOptions.events.push(t);
        this.actualId = data.result.id;
        this.actualStartBreakTime = formatDateTime(t.start, HHmm2);
        this.actualEndBreakTime = formatDateTime(t.end_real, HHmm2);
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(
            "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
            {
              width: "600px",
            }
          );
        } else {
          apiErrorMessages(this, FC819, status);
        }
      }
    },

    /**
     * Add event from Todo list
     * @param {object} [todoTask]
     */
    async addEventFromTodoList(todoTask) {
      let isOriginalDbTask = isEmptyNumber(todoTask.free_task_id) && isEmptyNumber(todoTask.actual_free_task_id);
        let relatedTaskScheduleId = this.calendarOptions.events.some(
          (x) => x.isPlan && !x.isBreak && x.id === +todoTask.schedule_task_id
        )
          ? todoTask.schedule_task_id
          : null;
        let actualTask = {
          schedule_task_id: todoTask.schedule_task_id,
          name: todoTask.name,
          description: todoTask.description,
          category_secondary_id: todoTask.category_secondary_id,
          task_type_id: todoTask.task_type_id,
          cost: +todoTask.cost,
          count: todoTask.standard_task_id ? 1 : todoTask.count,
          is_need_output: +todoTask.is_need_output || 0,
          output_detail: todoTask.output_detail,
          is_favorite: +todoTask.is_favorite,
          comments: [],
          files: [],
          todo_task_id: todoTask.isSplit ? null : +todoTask.id,
          start_time: null,
          is_completed: 0,
          is_remand: 0,
          standard_task_id: todoTask.standard_task_id,
          standard_task_revision: todoTask.standard_task_revision,
          category_primary_id: todoTask.category_primary_id,
          detail: todoTask.detail || "",
          is_remand_previous: 0,
          split_task_id: todoTask.isSplit ? todoTask.id : null,
          skill_level_id: todoTask.skill_level_id || null,
          schedule_month_task_id: todoTask.schedule_month_task_id,
        };

        if (isOriginalDbTask) {
          actualTask = {
            ...actualTask,
            standard_task_id: todoTask.standard_task_id,
            standard_task_revision: todoTask.standard_task_revision,
          };
        }

        let actualTasks = [];
        actualTasks.push(actualTask);
        try {
          const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
            actual_tasks: actualTasks,
          });
          //登録時のDBタスクの回数は1回
          todoTask.count = todoTask.standard_task_id ? 1 : todoTask.count;

          let calendarTask = {
            split_task_source_id: todoTask.source_id,
            standard_task_id: todoTask.standard_task_id,
            standard_task_revision: todoTask.standard_task_revision,
            actual_free_task_id: isOriginalDbTask
              ? null
              : todoTask.free_task_id,
            schedule_task_id: relatedTaskScheduleId,
            title: todoTask.name,
            start: formatDateTime(
              addDays(
                `${this.selectedDate} ${data.result[0].start_time}`,
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            end: formatDateTime(
              addDays(
                `${this.selectedDate} ${adjustMinEnd(
                  data.result[0].start_time,
                  data.result[0].end_time,
                  HHmm3,
                  this.min
                )}`,
                1,
                yyyyMMddHHmmss1
              ), // adjustMinEnd HHmm1
              yyyyMMddHHmmss2
            ),
            end_real: formatDateTime(
              addDays(
                `${this.selectedDate} ${data.result[0].end_time}`,
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            check: false,
            isCompleted: false,
            showCheck: this.isSupervisor(),
            //className: this.getMarkOutput(todoTask),
            // className:
            //   this.isDbTask(todoTask) && !todoTask.isSplit ? "db-task" : "",
            className:
              (this.isDbTask(todoTask) && this.isDbTaskByType(todoTask)) ||
              // todoTask.isSplit ||
              this.isPlanFreeTask(todoTask)
                ? "actual-unPlan-task"
                : "",
            color:
              isOriginalDbTask &&
              // !todoTask.isSplit &&
              this.isDbTaskByType(todoTask)
                ? COLOR_DB_TASK_NOT_COMPLETE
                : !this.isDbTaskByType(todoTask)
                ? COLOR_GRAY
                : COLOR_FREE_TASK_NOT_COMPLETE,
            isPlan: false,
            markText: this.isMark(todoTask),
            timeText: this.getWorkTime(todoTask),
            isBreak: false,
            id: data.result[0].id,
            disabled: false,
            cost: +todoTask.cost,
            count: todoTask.count,
            is_need_output: todoTask.is_need_output || 0,
            is_completed: 0,
            category_primary_id: todoTask.category_primary_id,
            category_secondary_name: todoTask.category_secondary_name,
            category_secondary_id: todoTask.category_secondary_id,
            task_type_id: todoTask.task_type_id,
            schedule_month_task_id: todoTask.schedule_month_task_id,
            detail: todoTask.detail || "",
            split_task_id: todoTask.isSplit ? todoTask.id : null,
          };
          calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
          calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);

          todoTask.isSplit
            ? this.$refs.todoTaskList.getSplitData()
            : this.$refs.todoTaskList.removeTodoTask(todoTask.id);
          this.calendarOptions.events.push(calendarTask);

          // 予定タスクIDが存在したら、実施チェックボックスをチェック
        let scheduleTask = this.calendarOptions.events.find(
            (x) => x.id === +todoTask.schedule_task_id && x.isPlan && !x.isBreak
          );
        if (scheduleTask){
          scheduleTask.className  = "";
          scheduleTask.is_completed = 1;
          scheduleTask.isCompleted = true;
        }
        } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC805, status);
          }
        }
    },

    /**
     * Add event from DB list
     * @param {object} [dbTask]
     */
     async addEventFromFavoriteList(favoriteTask) {
      try {
        let actualTasks = [
          {
            schedule_task_id: favoriteTask.schedule_task_id,
            name: favoriteTask.name,
            description: favoriteTask.description,
            category_secondary_id: favoriteTask.category_secondary_id,
            task_type_id: favoriteTask.task_type_id,
            cost: +favoriteTask.cost,
            count: +favoriteTask.count,
            is_need_output: +favoriteTask.is_need_output || 0,
            output_detail: favoriteTask.output_detail,
            is_favorite: 1,
            comments: [],
            files: [],
            start_time: null,
            is_completed: 0,
            is_remand: 0,
            favorite_task_id: favoriteTask.favorite_task_id,
          },
        ];
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        favoriteTask.id = data.result[0].id;
        favoriteTask.actual_free_task_id = data.result[0].actual_free_task_id;
        let calendarTask = {
          schedule_task_id: favoriteTask.schedule_task_id,
          title: favoriteTask.name,
          start: formatDateTime(
            addDays(
              `${this.selectedDate} ${data.result[0].start_time}`,
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                data.result[0].start_time,
                data.result[0].end_time,
                HHmm3,
                this.min
              )}`,
              1,
              yyyyMMddHHmmss1
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmmss2
          ),

          end_real: formatDateTime(
            addDays(
              `${this.selectedDate} ${data.result[0].end_time}`,
              1,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmmss2
          ),
          check: false,
          showCheck: this.isSupervisor(),
          isCompleted: false,
          className: "actual-unPlan-task",
          color: this.setTaskConfiguration(favoriteTask).color,
          isPlan: false,
          markText: this.isMark(favoriteTask),
          timeText: this.getWorkTime(favoriteTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: favoriteTask.cost,
          count: favoriteTask.count,
          is_need_output: favoriteTask.is_need_output || 0,
          is_completed: 0,
          category_secondary_name: favoriteTask.category_secondary_name,
          category_secondary_id: favoriteTask.category_secondary_id,
          category_primary_id: favoriteTask.category_primary_id,
          task_type_id: favoriteTask.task_type_id,
          is_remand: 0,
          is_remand_previous: 0,
          actual_free_task_id: data.result[0].actual_free_task_id,
          favorite_task_id: data.result[0].favorite_task_id,
          detail: "",
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(
            "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
            {
              width: "600px",
            }
          );
        } else {
          apiErrorMessages(this, FC805, status);
        }
      }
    },
    /**
     * Add event from monthly list
     * @param {object} [monthlyTask]
     */
    async addEventFromMonthlyList(monthlyTask) {

        let actualTasks = [
          {
            schedule_task_id: monthlyTask.schedule_task_id,
            name: monthlyTask.name,
            description: monthlyTask.description,
            category_secondary_id: monthlyTask.category_secondary_id,
            task_type_id: monthlyTask.task_type_id,
            cost: +monthlyTask.cost,
            count: 1,
            is_need_output: +monthlyTask.is_need_output || 0,
            output_detail: monthlyTask.output_detail,
            is_favorite: 0,
            comments: [],
            files: [],
            start_time: null,
            is_completed: 0,
            is_remand: 0,
            standard_task_id: monthlyTask.standard_task_id,
            standard_task_revision: monthlyTask.standard_task_revision,
            category_primary_id: monthlyTask.category_primary_id,
            detail: monthlyTask.detail || "",
            is_remand_previous: 0,
            skill_level_id: monthlyTask.skill_level_id || null,
            schedule_month_task_id: monthlyTask.id,
          }
        ];

        try {
          const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
            actual_tasks: actualTasks,
          });
          monthlyTask.count = 1;

          let calendarTask = {
            standard_task_id: monthlyTask.standard_task_id,
            standard_task_revision: monthlyTask.standard_task_revision,
            actual_free_task_id: data.result[0].actual_free_task_id,
            schedule_task_id: monthlyTask.schedule_task_id,
            title: monthlyTask.name,
            start: formatDateTime(
              addDays(
                `${this.selectedDate} ${data.result[0].start_time}`,
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            end: formatDateTime(
              addDays(
                `${this.selectedDate} ${adjustMinEnd(
                  data.result[0].start_time,
                  data.result[0].end_time,
                  HHmm3,
                  this.min
                )}`,
                1,
                yyyyMMddHHmmss1
              ), // adjustMinEnd HHmm1
              yyyyMMddHHmmss2
            ),
            end_real: formatDateTime(
              addDays(
                `${this.selectedDate} ${data.result[0].end_time}`,
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            ),
            check: false,
            isCompleted: false,
            showCheck: this.isSupervisor(),
            className:
              (this.isDbTask(monthlyTask) && this.isDbTaskByType(monthlyTask)) ||
              this.isPlanFreeTask(monthlyTask)
                ? "actual-unPlan-task"
                : "",
            color: this.setTaskConfiguration(monthlyTask).color,
            isPlan: false,
            markText: this.isMark(monthlyTask),
            timeText: this.getWorkTime(monthlyTask),
            isBreak: false,
            id: data.result[0].id,
            disabled: false,
            cost: +monthlyTask.cost,
            count: 1,
            is_need_output: monthlyTask.is_need_output || 0,
            is_completed: 0,
            category_primary_id: monthlyTask.category_primary_id,
            category_secondary_name: monthlyTask.category_secondary_name,
            category_secondary_id: monthlyTask.category_secondary_id,
            task_type_id: monthlyTask.task_type_id,
            schedule_month_task_id: monthlyTask.id,
            detail: monthlyTask.detail || "",
          };
          calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
          calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
          this.calendarOptions.events.push(calendarTask);
        } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.$alert(
              "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
              {
                width: "600px",
              }
            );
          } else {
            apiErrorMessages(this, FC805, status);
          }
        }
    },
    /**
     * Arrange task
     */
    async arrangeTask() {
      const confirmText =
        "タスク同士に隙間がある場合、すべてのタスクが上に詰めて\r\n 配置されますが、本当にタスクを整列しますか?\r\n\n" +
        "▼注意事項\r\n本システムはスケジュールを管理するツールではないため、\r\n 整列することによって、朝会やMTGなどの開始時間が、\r\n正確な開始時間から数分ずれても問題はありません。";
      this.$confirm(confirmText, {
        width: "700px",
        arrangeTask: this.$vuetify.breakpoint.mobile ? "custom-dialog" : "",
      })
        .then(async () => {
          try {
            const { data } = await axios.post(ACTUAL_SORT_API(this.actualId));
            let calendar = data.result;
            let actualCalendar = calendar.actual_tasks.map((x) => {
              //TODO SETTING COLOR TASK WHEN INITIAL VIEW
              x.isPlan = false;
              x.isBreak = false;
              let setting = this.setTaskConfiguration(x);
              let event = {
                ...x,
                ...{
                  title: x.name,
                  start: formatDateTime(
                    addDays(
                      `${this.selectedDate} ${x.start_time}`,
                      1,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmm1
                  ),
                  end: formatDateTime(
                    addDays(
                      `${this.selectedDate} ${adjustMinEnd(
                        x.start_time,
                        x.end_time,
                        HHmmss1,
                        this.min
                      )}`, // adjustMinEnd HHmmss1
                      1,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmm1
                  ),
                  end_real: formatDateTime(
                    addDays(
                      `${this.selectedDate} ${x.end_time}`,
                      1,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmm1
                  ),
                  isCompleted: !!x.is_completed,
                  check: !!x.is_remand,
                  showCheck: this.isSupervisor(),
                  isBreak: false,
                  isPlan: false,
                  timeText: setting.timeText,
                  //className: setting.className,
                  // className:
                  //   this.isDefaultColor(setting.color) &&
                  //   this.isDbTask(x) &&
                  //   this.isDbTaskByType(x) &&
                  //   !this.isSplitTask(x)
                  //     ? "db-task"
                  //     : "",
                  className: !this.isCompleteTask(x)
                    ? this.isDbTask(x)
                      ? this.isDbTaskByType(x)
                        ? "actual-unPlan-task"
                        : ""
                      : "actual-unPlan-task"
                    : "",
                  markText: setting.markText,
                  color: setting.color,
                  disabled: false,
                },
              };
              return event;
            });

            //merge actuat list
            this.calendarOptions.events = this.calendarOptions.events
              .filter((x) => x.isPlan)
              .concat(actualCalendar);
            if (calendar.start_break_time && calendar.end_break_time) {
            let breakTask = {
              title: "休憩",
              start: formatDateTime(
                addDays(
                  `${this.selectedDate} ${calendar.start_break_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              end: formatDateTime(
                addDays(
                  `${this.selectedDate} ${adjustMinEnd(
                    calendar.start_break_time,
                    calendar.end_break_time,
                    HHmmss1,
                    this.min
                  )}`, // adjustMinEnd HHmmss1
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              end_real: formatDateTime(
                addDays(
                  `${this.selectedDate} ${calendar.end_break_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              color: COLOR_GRAY,
              isBreak: true,
              isPlan: false,
              isCompleted: false,
              start_time: calendar.start_break_time,
              end_time: calendar.end_break_time,
              count: 1,
              cost: this.diffTime(
                calendar.start_break_time,
                calendar.end_break_time
              ),
              timeText: this.getWorkTime({
                count: 1,
                cost: this.diffTime(
                  calendar.start_break_time,
                  calendar.end_break_time
                ),
                unit: "break",
              }),
              disabled: false,
            };

            this.actualStartBreakTime = formatDateTime(breakTask.start, HHmm2);
            this.actualEndBreakTime = formatDateTime(breakTask.end_real, HHmm2);
            if (breakTask.timeText !== TIME_TEXT_ZERO)
              this.calendarOptions.events.push(breakTask);
            }

            this.gotoSelectedDate();
          } catch (error) {
            const { status } = error.response;
            if (status === 404) {
              this.$alert(
                "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
                {
                  width: "600px",
                }
              );
            } else {
              apiErrorMessages(this, FC822, status);
            }
          } // END-TRY-CATCH
        })
        .catch(() => {});
    },
    /**
     * Show explain modal
     */
    showExplainModal() {
      this.showExplain = !this.showExplain;
    },
    /**
     * Toggle expand
     */
    toggleExpand() {
      if (this.expand !== 0) {
        this.expand = 0;
      } else {
        this.expand = 1;
      }
    },
    /**
     * Change tab
     * @param {number} [number]
     */
    changeTab(number) {
      switch (number) {
        case "mobile-tabs-dblist":
          this.$root.$refs.actualDbTabList?.getData();
          break;
        case "mobile-tabs-todolist":
          this.$root.$refs.actualTodoTabList?.loadTodoListData();
          break;
        case "mobile-tabs-favoritelist":
          this.$root.$refs.scheduleFavoriteTabList?.loadFavoriteListData();
          break;
        case "mobile-tabs-monthlylist":  
          this.$root.$refs.scheduleMonthyTabList?.getData();
          break;
        default:
          window.dispatchEvent(new Event("resize"));
          break;
      }
    },
    /**
     * Active calendar tab
     */
    activeCalendarTab() {
      this.currentTab = "mobile-tabs-calendar";
    },
    /**
     * Set loaded
     * @param {boolean} [isLoading]
     */
    setLoaded(isLoading) {
      this.isLoaded = !isLoading;
      let calendarApi = this.$refs.fullCalendar.getApi();
      if (!isLoading && !isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[1];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }
          this.scrollTop = null;
        }, 0.0005);
      }
      if (!calendarApi) return;
      this.isRenderCompleted = false;
      let currentDate = calendarApi.getDate();
      calendarApi.gotoDate(formatDateTime(this.selectedDate), yyyyMMdd2);
      let date = parseDateTime(this.selectedDate, yyyyMMdd2);
      date = date.setDate(date.getDate() - 1);
      if (
        currentDate &&
        +formatDateTime(date, yyyyMMdd1) ===
          +formatDateTime(currentDate, yyyyMMdd1)
      ) {
        // jump out of range date to set active date
        calendarApi.next();
        calendarApi.prev();
      }
    },
    /**
     * Handle drop task from DB
     * @param {Time} [time]
     * @param {Object} [item]
     */
    async handleDropTaskFromDB({ time, item }) {
      let dbTasks = this.$refs.dbTaskList.getDatabaseList();
      let dbTask = dbTasks.find((x) => x.id === parseInt(item.id));
      //登録時のDBタスクの回数は1回
      dbTask.count = 1;
      this.dropTaskFromDb(dbTask, time);
    },

    async dropTaskFromDb(dbTask, time){
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let startTime = formatDateTime(infoDate, HHmm1);

      let endDate = infoDate;

      endDate = new Date(
        endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
      );
      let endTime = formatDateTime(endDate, HHmm1);
      await this.createDbTaskActual(dbTask, startTime, endTime);     
    },

    /**
     * Handle drop task from todo
     * @param {Time} [time]
     * @param {Object} [item]
     */
    async handleDropTaskFromTodo({ time, item }) {
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let startTime = formatDateTime(infoDate, HHmm1);

      let endDate = infoDate;
      let todoTasks = item.isSplit
        ? this.$refs.todoTaskList.getSplitTask()
        : this.$refs.todoTaskList.getTodoList();
      let todoTask = todoTasks.find((x) => x.id === parseInt(item.id));
      //登録時のDBタスクの回数は1回
      todoTask.count = todoTask.standard_task_id ? 1 : todoTask.count;
      endDate = new Date(
        endDate.getTime() + +todoTask.cost * +todoTask.count * 60000
      );
      let endTime = formatDateTime(endDate, HHmm1);
      let isOriginalDbTask = isEmptyNumber(todoTask.free_task_id) && isEmptyNumber(todoTask.actual_free_task_id);
      let relatedTaskScheduleId = this.calendarOptions.events.some(
        (x) => x.isPlan && !x.isBreak && x.id === +todoTask.schedule_task_id
      )
        ? todoTask.schedule_task_id
        : null;
      let actualTask = {
        schedule_task_id: todoTask.schedule_task_id,
        name: todoTask.name,
        description: todoTask.description,
        category_secondary_id: todoTask.category_secondary_id,
        task_type_id: todoTask.task_type_id,
        cost: +todoTask.cost,
        count: todoTask.standard_task_id ? 1 : todoTask.count,
        is_need_output: +todoTask.is_need_output || 0,
        output_detail: todoTask.output_detail,
        is_favorite: +todoTask.is_favorite,
        comments: [],
        files: [],
        todo_task_id: item.isSplit ? null : +todoTask.id,
        start_time: startTime + "00",
        is_completed: 0,
        is_remand: 0,
        standard_task_id: todoTask.standard_task_id,
        standard_task_revision: todoTask.standard_task_revision,
        category_primary_id: todoTask.category_primary_id,
        split_task_id: item.isSplit ? todoTask.id : null,
        detail: todoTask.detail || "",
        skill_level_id: todoTask.skill_level_id || null,
        schedule_month_task_id: todoTask.schedule_month_task_id,
      };

      if (isOriginalDbTask) {
        actualTask = {
          ...actualTask,
          standard_task_id: todoTask.standard_task_id,
          standard_task_revision: todoTask.standard_task_revision,
        };
      }

      let actualTasks = [];
      actualTasks.push(actualTask);
      try {
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        let calendarTask = {
          split_task_source_id: todoTask.source_id,
          standard_task_id: todoTask.standard_task_id,
          standard_task_revision: todoTask.standard_task_revision,
          actual_free_task_id: isOriginalDbTask
            ? null
            : todoTask.free_task_id,
          schedule_task_id: relatedTaskScheduleId,
          title: todoTask.name,
          start: formatDateTime(
            addDays(`${this.selectedDate} ${startTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                startTime,
                endTime,
                HHmm1,
                this.min
              )}`,
              1,
              yyyyMMddHHmm3
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(`${this.selectedDate} ${endTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          check: false,
          isCompleted: false,
          showCheck: this.isSupervisor(),
          //className: this.getMarkOutput(todoTask),
          // className:
          //   this.isDbTask(todoTask) && !item.isSplit ? "db-task" : "",
          className: this.isDbTask(todoTask)
            ? this.isDbTaskByType(todoTask)
              ? "actual-unPlan-task"
              : ""
            : "actual-unPlan-task",
          color:
            isOriginalDbTask && this.isDbTaskByType(todoTask)
              ? COLOR_DB_TASK_NOT_COMPLETE
              : !this.isDbTaskByType(todoTask)
              ? COLOR_GRAY
              : COLOR_FREE_TASK_NOT_COMPLETE,
          isPlan: false,
          markText: this.isMark(todoTask),
          timeText: this.getWorkTime(todoTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: +todoTask.cost,
          count: todoTask.count,
          is_need_output: todoTask.is_need_output || 0,
          is_completed: 0,
          category_primary_id: todoTask.category_primary_id,
          category_secondary_id: todoTask.category_secondary_id,
          category_secondary_name: todoTask.category_secondary_name,
          task_type_id: todoTask.task_type_id,
          is_split_task: item.isSplit ? 1 : 0,
          split_task_id: item.isSplit ? todoTask.id : null,
          detail: todoTask.detail || "",
          schedule_month_task_id: todoTask.schedule_month_task_id,
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        item.isSplit
          ? this.$refs.todoTaskList.getSplitData()
          : this.$refs.todoTaskList.removeTodoTask(todoTask.id);
        this.calendarOptions.events.push(calendarTask);

        // 予定タスクIDが存在したら、実施チェックボックスをチェック
        let scheduleTask = this.calendarOptions.events.find(
            (x) => x.id === +todoTask.schedule_task_id && x.isPlan && !x.isBreak
          );
        if (scheduleTask){
          scheduleTask.className  = "";
          scheduleTask.is_completed = 1;
          scheduleTask.isCompleted = true;
        }

      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC805, status);
      }
    },

    /**
     * Handle drop task from Favorite
     * @param {Time} [time]
     * @param {Object} [item]
     */
     async handleDropTaskFromFavorite({ time, item }) {
      if (item.id){
        let dbTasks = this.$refs.favoriteList.getDbList();
        let dbTask = dbTasks.find((x) => x.standard_task_id === parseInt(item.id));
        dbTask.id = dbTask.standard_task_id;
        dbTask.revision = dbTask.standard_task_revision;
        //登録時のDBタスクの回数は1回
        dbTask.count = 1;

        this.dropTaskFromDb(dbTask, time);
        return;
      }
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let startTime = formatDateTime(infoDate, HHmm1);

      let endDate = infoDate;

      let favoriteTasks = this.$refs.favoriteList.getFavoriteList();
      let favoriteTask = favoriteTasks.find((x) => x.favorite_task_id === parseInt(item.favorite_task_id));
      endDate = new Date(
        endDate.getTime() + +favoriteTask.cost * +favoriteTask.count * 60000
      );
      let endTime = formatDateTime(endDate, HHmm1);

      await this.createFreeTaskActual(favoriteTask, startTime, endTime);
    },
    /**
     * Handle drop task from monthly
     * @param {Time} [time]
     * @param {Object} [item]
     */
    async handleDropTaskFromMonthly({ time, item }) {
      const infoDate = parseDateTime(
        `${this.selectedDate} ${time}`,
        yyyyMMddHHmmss2
      );

      let startTime = formatDateTime(infoDate, HHmm1);

      let endDate = infoDate;
      let monthlyTasks = this.$refs.monthlyList.getMonthlytaskList();
      let monthlyTask = monthlyTasks.find((x) => x.id === parseInt(item.id));
      monthlyTask.count = 1;
      endDate = new Date(
        endDate.getTime() + +monthlyTask.cost * +monthlyTask.count * 60000
      );
      let endTime = formatDateTime(endDate, HHmm1);
      let actualTasks = [
        {
          schedule_task_id: monthlyTask.schedule_task_id,
          name: monthlyTask.name,
          description: monthlyTask.description,
          category_secondary_id: monthlyTask.category_secondary_id,
          task_type_id: monthlyTask.task_type_id,
          cost: +monthlyTask.cost,
          count: 1,
          is_need_output: +monthlyTask.is_need_output || 0,
          output_detail: monthlyTask.output_detail,
          is_favorite: 0,
          comments: [],
          files: [],
          start_time: startTime + "00",
          is_completed: 0,
          is_remand: 0,
          standard_task_id: monthlyTask.standard_task_id,
          standard_task_revision: monthlyTask.standard_task_revision,
          category_primary_id: monthlyTask.category_primary_id,
          detail: monthlyTask.detail || "",
          skill_level_id: monthlyTask.skill_level_id || null,
          schedule_month_task_id: monthlyTask.id,
        },
      ];

      try {
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        let calendarTask = {
          standard_task_id: monthlyTask.standard_task_id,
          standard_task_revision: monthlyTask.standard_task_revision,
          actual_free_task_id: data.result[0].actual_free_task_id,
          schedule_task_id: monthlyTask.schedule_task_id,
          title: monthlyTask.name,
          start: formatDateTime(
            addDays(`${this.selectedDate} ${startTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                startTime,
                endTime,
                HHmm1,
                this.min
              )}`,
              1,
              yyyyMMddHHmm3
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(`${this.selectedDate} ${endTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          check: false,
          isCompleted: false,
          showCheck: this.isSupervisor(),
          className:
            (this.isDbTask(monthlyTask) && this.isDbTaskByType(monthlyTask)) ||
            this.isPlanFreeTask(monthlyTask)
              ? "actual-unPlan-task"
              : "",
          color: this.setTaskConfiguration(monthlyTask).color,
          isPlan: false,
          markText: this.isMark(monthlyTask),
          timeText: this.getWorkTime(monthlyTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: +monthlyTask.cost,
          count: 1,
          is_need_output: monthlyTask.is_need_output || 0,
          is_completed: 0,
          category_primary_id: monthlyTask.category_primary_id,
          category_secondary_id: monthlyTask.category_secondary_id,
          category_secondary_name: monthlyTask.category_secondary_name,
          task_type_id: monthlyTask.task_type_id,
          detail: monthlyTask.detail || "",
          schedule_month_task_id: monthlyTask.id,
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        this.calendarOptions.events.push(calendarTask);

      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC805, status);
      }
    },

    /**
     * 標準工数タスクの実績タスク作成
     * @param {*} dbTask 
     * @param {*} startTime 
     * @param {*} endTime 
     */
    async createDbTaskActual(dbTask, startTime, endTime){
      let actualTasks = [
        {
          schedule_task_id: dbTask.schedule_task_id,
          name: dbTask.name,
          description: dbTask.description,
          category_secondary_id: dbTask.category_secondary_id,
          cost: +dbTask.cost,
          count: 1,
          is_need_output: +dbTask.is_need_output || 0,
          output_detail: dbTask.output_detail,
          is_favorite: +dbTask.is_favorite,
          comments: [],
          files: [],
          standard_task_id: dbTask.id,
          standard_task_revision: dbTask.revision,
          start_time: startTime + "00",
          is_completed: 0,
          is_remand: 0,
          detail: dbTask.detail,
          skill_level_id: dbTask.skill_level_id || null,
        },
      ];
      try {
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        dbTask.count = 1;
        let calendarTask = {
          standard_task_id: dbTask.id,
          standard_task_revision: dbTask.revision,
          schedule_task_id: dbTask.schedule_task_id,
          title: dbTask.name,
          start: formatDateTime(
            addDays(`${this.selectedDate} ${startTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                startTime,
                endTime,
                HHmm1,
                this.min
              )}`,
              1,
              yyyyMMddHHmm3
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(`${this.selectedDate} ${endTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          check: false,
          showCheck: this.isSupervisor(),
          isCompleted: false,
          //className: this.getMarkOutput(dbTask),
          // className: "db-task",
          className: this.isDbTaskByType(dbTask) ? "actual-unPlan-task" : "",
          color: this.isDbTaskByType(dbTask)
            ? COLOR_DB_TASK_NOT_COMPLETE
            : COLOR_GRAY,
          isPlan: false,
          markText: this.isMark(dbTask),
          timeText: this.getWorkTime(dbTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: dbTask.cost,
          count: 1,
          is_need_output: dbTask.is_need_output || 0,
          is_completed: 0,
          category_secondary_name: dbTask.category_secondary_name,
          category_secondary_id: dbTask.category_secondary_id,
          category_primary_id: dbTask.category_primary_id,
          detail: dbTask.detail || "",
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC805, status);
      }
    },

    /**
     * フリータスクの実績タスク作成
     * @param {*} favoriteTask 
     * @param {*} startTime 
     * @param {*} endTime 
     */
    async createFreeTaskActual(favoriteTask, startTime, endTime){
      let actualTasks = [
        {
          schedule_task_id: favoriteTask.schedule_task_id,
          name: favoriteTask.name,
          description: favoriteTask.description,
          category_secondary_id: favoriteTask.category_secondary_id,
          task_type_id: favoriteTask.task_type_id,
          cost: +favoriteTask.cost,
          count: +favoriteTask.count,
          is_need_output: +favoriteTask.is_need_output || 0,
          output_detail: favoriteTask.output_detail,
          is_favorite: 1,
          comments: [],
          files: [],
          start_time: startTime + "00",
          is_completed: 0,
          is_remand: 0,
          favorite_task_id: favoriteTask.favorite_task_id,
        },
      ];
      try {
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        let calendarTask = {
          schedule_task_id: favoriteTask.schedule_task_id,
          title: favoriteTask.name,
          start: formatDateTime(
            addDays(`${this.selectedDate} ${startTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                startTime,
                endTime,
                HHmm1,
                this.min
              )}`,
              1,
              yyyyMMddHHmm3
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(`${this.selectedDate} ${endTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          check: false,
          showCheck: this.isSupervisor(),
          isCompleted: false,
          className: "actual-unPlan-task",
          color: COLOR_FREE_TASK,
          isPlan: false,
          markText: this.isMark(favoriteTask),
          timeText: this.getWorkTime(favoriteTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: favoriteTask.cost,
          count: favoriteTask.count,
          is_need_output: favoriteTask.is_need_output || 0,
          is_completed: 0,
          task_type_id: favoriteTask.task_type_id,
          category_secondary_name: favoriteTask.category_secondary_name,
          category_secondary_id: favoriteTask.category_secondary_id,
          category_primary_id: favoriteTask.category_primary_id,
          actual_free_task_id : data.result[0].actual_free_task_id,
          favorite_task_id: favoriteTask.favorite_task_id,
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC805, status);
      }
    },

    /**
     * Change date func
     * @return {void}
     */
    async changeSelectedDate() {
      if (!isEmpty(this.$route.query.date))
        this.originalDate = this.$route.query.date;
      this.menuDate = false;
      this.$refs.menu.save(this.selectedDate);
      this.planId = "";
      this.actualId = "";
      if (
        isEmpty(this.originalDate) ||
        this.selectedDate !== this.originalDate
      ) {
        if (!this.isSelf()) {
          this.$router.replace({
            path: "/actual",
            query: { uid: this.userId, date: this.selectedDate },
          });
        } else {
          this.$router.replace({
            path: "/actual",
            query: { date: this.selectedDate },
          });
        }
      }
      await this.getSchedule();
    },
    /**
     * Handle change date event
     * @return {void}
     */
    async changeDate() {
      await this.changeSelectedDate();
    },

    /**
     * Move selected date
     *  @param {boolean} [isNext]
     * @return {void}
     */
    async moveSelectedDate(isNext = false) {
      const day = parseDateTime(this.selectedDate, yyyyMMdd2);
      let y = day.getFullYear(),
        m = day.getMonth(),
        d = isNext ? day.getDate() + 1 : day.getDate() - 1;
      this.selectedDate = formatDateTime(new Date(y, m, d), yyyyMMdd2);
      await this.changeSelectedDate();
    },
    /**
     * toggleDrawerRight
     *  @param {string} [typeList]
     */
    toggleDrawerRight: debounce_leading(function (typeList) {
      if (this.isSelf()) {
        if (typeList === "db-task") {
          this.showDrawerRight = !this.showDrawerRight || !this.showDbTasks;
          this.showDbTasks = this.showDrawerRight;
          this.showTodoList = false;
          this.showFavoriteList = false;
          this.showMonthlyList = false;
          if (this.showDbTasks) {
            this.$refs.dbTaskList.getData();
          }
        } else if (typeList === "todo-task") {
          this.showDrawerRight = !this.showDrawerRight || !this.showTodoList;
          this.showDbTasks = false;
          this.showFavoriteList = false;
          this.showTodoList = this.showDrawerRight;
          this.showMonthlyList = false;
          if (this.showTodoList) {
            this.$refs.todoTaskList.loadTodoListData();
          }
        } else if (typeList === "favorite-task") {
          this.showDrawerRight =
            this.showDrawerRight && this.showFavoriteList ? false : true;
          this.showDbTasks = false;
          this.showTodoList = false;
          this.showFavoriteList = this.showDrawerRight ? true : false;
          this.showMonthlyList = false;
          if (this.showFavoriteList) {
            this.$refs.favoriteList.loadFavoriteListData();
          }
        } else if (typeList === "monthly-task") {
          this.showDrawerRight = !this.showDrawerRight || !this.showMonthlyList;
          this.showMonthlyList = this.showDrawerRight;
          this.showDbTasks = false;
          this.showTodoList = false;
          this.showFavoriteList = false;
          if (this.showMonthlyList) {
            this.$refs.monthlyList.getData();
          }
        } // END-IF-ELSE
      } else {
        return; // nothing todo
      }
    }),
    /**
     * is supervisor
     */
    isSupervisor() {
      return !this.isSelf() && !!this.canViewComment;
    },
    /**
     * is not submit yet
     */
    isNoSubmmit() {
      return (
        this.phaseId === SystemConstant.PHASE_ID_UNSUBMITTED ||
        this.phaseId === SystemConstant.PHASE_ID_SUBMISSION_RESET ||
        this.phaseId === SystemConstant.PHASE_ID_2_NEXT_REJECT ||
        this.phaseId === SystemConstant.PHASE_ID_PRIMARY_REJECT
      );
    },

    /**
     * validate submit button on comment modal
     * @return {boolean}
     */
    isPreValidation() {
      return (
        (this.blankTimeError.isBlank ||
          this.overLapTimeError ||
          this.diffWorkTimeError) &&
        this.isSelf() &&
        this.isNoSubmmit()
      );
    },

    /**
     * is can submit
     */
    isCanSubmit() {
      return this.canSubmit;
    },

    /**
     * is not submit yet
     */
     isVefified() {
      return (
        this.phaseId === SystemConstant.PHASE_ID_VERIFIED
      );
    },

    /**
     * is hide missing task
     */
    isHideMissingTask() {
      return (
        this.phaseId === SystemConstant.PHASE_ID_UNSUBMITTED ||
        this.phaseId === SystemConstant.PHASE_ID_SUBMISSION_RESET
      );
    },
    /**
     * is first approve
     */
    isFirstApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_PRIMARY_APPROVAL
      );
    },
    /**
     * is second approve
     */
    isSecondApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_SECONDARY_APPROVAL
      );
    },
    /**
     * is no approve
     */
    isNoApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_NO_PERMISSION
      );
    },
    adjustTasks(events, selectedDate, minSlot, slotMaxTime = "24:00:00") {
      const getAdjustStart = (end, min, slotMaxTime = "24:00:00") => {
        const endDate = formatDateTime(
          parseDateTime(end, yyyyMMddHHmmss2),
          yyyyMMdd2
        );
        const startTime =
          slotMaxTime === "24:00:00"
            ? `23:${this.timeToStrTime(Math.floor(60 - min))}:00`
            : formatDateTime(
                new Date(
                  new Date(`2020-01-01 ${slotMaxTime}`).getTime() -
                    min * 60 * 1000
                ),
                HHmmss2
              );
        return `${endDate} ${startTime}`;
      };
      const getFormat = (dateTimeString) => {
        switch (dateTimeString.length) {
          case 19:
            return yyyyMMddHHmmss2;
          case 16:
            return yyyyMMddHHmm1;
          default:
            throw `format errors ${dateTimeString}`;
        }
      };

      events.forEach((item) => {
        item.end_real =
          getFormat(item.end_real) === yyyyMMddHHmm1
            ? `${item.end_real}:00`
            : item.end_real;

        let endRealDate = parseDateTime(item.end_real, yyyyMMddHHmmss2);

        const date = item.isPlan
          ? selectedDate
          : formatDateTime(addDays(`${selectedDate}`, 1, yyyyMMdd2), yyyyMMdd2);

        if (!item.start_time) {
          item.start_time = formatDateTime(
            parseDateTime(item.start, yyyyMMddHHmm1),
            HHmmss1
          );
        }
        if (!item.end_time) {
          item.end_time = formatDateTime(
            parseDateTime(item.end, yyyyMMddHHmm1),
            HHmmss1
          );
        }

        item.start_real = formatDateTime(
          parseDateTime(date + " " + item.start_time, yyyyMMddHHmmss1),
          yyyyMMddHHmmss2
        );
        // check min
        const min = item.cost * item.count < 30 ? minSlot : minSlot * 1.65;
        const tomorrowTime =
          Date.parse(`${formatDateTime(endRealDate, yyyyMMdd2)}T00:00:00`) +
          24 * 60 * 60 * 1000;
        let compareStartRealTime =
          parseDateTime(item.start_real, yyyyMMddHHmmss2).getTime() +
          min * 60 * 1000;
        const endRealTime = endRealDate.getTime();
        const isAdjustEnd = compareStartRealTime > tomorrowTime;
        const compareStartHHmmss2 = formatDateTime(
          new Date(compareStartRealTime),
          HHmmss2
        );
        if (
          isAdjustEnd &&
          item.end_time > 230000 &&
          slotMaxTime === "24:00:00"
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} 23:59:59`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (
          compareStartRealTime > endRealTime &&
          compareStartHHmmss2 > slotMaxTime
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} ${slotMaxTime}`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (compareStartRealTime > endRealTime) {
          compareStartRealTime =
            compareStartRealTime >= tomorrowTime
              ? tomorrowTime - 1000
              : compareStartRealTime;
          item.end = formatDateTime(compareStartRealTime, yyyyMMddHHmmss2);
        }
      });
    },

    /**
     * go to selected date
     */
    gotoSelectedDate() {
      this.$nextTick(() => {
        let calendarApi = this.$refs.fullCalendar.getApi();
        this.isRenderCompleted = false;
        let currentDate = calendarApi.getDate();
        calendarApi.gotoDate(formatDateTime(this.selectedDate), yyyyMMdd2);
        let date = parseDateTime(this.selectedDate, yyyyMMdd2);
        date = date.setDate(date.getDate() - 1);
        if (
          currentDate &&
          +formatDateTime(date, yyyyMMdd1) ===
            +formatDateTime(currentDate, yyyyMMdd1)
        ) {
          // jump out of range date to set active date
          calendarApi.next();
          calendarApi.prev();
          // printCalendarApi.next();
          // printCalendarApi.prev();
        }
        if (!this.isMobile) {
          let printCalendarApi = this.$refs.printCalendar.getApi();
          printCalendarApi.gotoDate(
            formatDateTime(this.selectedDate),
            yyyyMMdd2
          );
          if (
            currentDate &&
            +formatDateTime(date, yyyyMMdd1) ===
              +formatDateTime(currentDate, yyyyMMdd1)
          ) {
            // jump out of range date to set active date
            printCalendarApi.next();
            printCalendarApi.prev();
          }
        }

        this.isRenderCompleted = true;

        const scrollTime = this.getTimeForScroll(
          this.calendarOptions,
          this.startWorkTime
        );
        calendarApi.scrollToTime(scrollTime);
      });
    },
    /**
     * show output file
     */
    showOuputFile(taskId, event) {
      event.stopPropagation();
      let actualTask = this.calendarOptions.events.find(
        (x) => x.id === +taskId && !x.isPlan
      );
      this.selectedFiles = actualTask.files;
      this.openFileDialog = !this.openFileDialog;
    },
    /**
     * after morning
     */
    afterMorning() {
      let now = +formatDateTime(new Date(), yyyyMMdd1);
      let selected = +formatDateTime(
        parseDateTime(`${this.selectedDate}`, yyyyMMdd2),
        yyyyMMdd1
      );

      if (now > selected) {
        return true;
      } else {
        if (now === selected) {
          return +formatDateTime(new Date(), HHmm1) > 1200;
        } else {
          return false;
        } // END-IF-ELSE
      } // END-IF-ELSE
    },
    /**
     * is seft
     */
    isSelf() {
      return this.userId === this.user?.user_id;
    },
    /**
     * change day header content
     * @param {object} [arg]
     */
    changeDayHeaderContent(arg) {
      let planDate = formatDateTime(this.selectedDate, yyyyMMdd1);
      let eventDate = formatDateTime(arg.date, yyyyMMdd1);
      arg.text = planDate === eventDate ? "予定" : "実績";
    },
    /**
     * change day header class names
     * @param {object} [arg]
     */
    changeDayHeaderClassNames(arg) {
      let planDate = formatDateTime(this.selectedDate, yyyyMMdd1);
      let eventDate = formatDateTime(arg.date, yyyyMMdd1);
      return planDate === eventDate ? "plan-header" : "actual-header";
    },

    handleEventClick(e) {
      let isPlan = e.event._def.extendedProps.isPlan;
      const isBreakTask = e.event.id === "";
      if (isPlan) {
        if (isBreakTask) {
          this.showPlanBreakTime = !this.showPlanBreakTime;
          return;
        } else {
          this.selectedPlanTask = parseInt(e.event.id);
          this.showPlanTask = !this.showPlanTask;
          return;
        }
      } else {
        if (isBreakTask) {
          this.showBreakTime = !this.showBreakTime;
          return;
        }
        let planTask = this.calendarOptions.events.find(
          (x) => x.id == e.event._def.extendedProps.schedule_task_id && x.isPlan
        );

        if (planTask && planTask.comments) {
          this.scheduleComments = planTask.comments;
        } else {
          this.scheduleComments = [];
        } // END-IF-ELSE

        if (
          (this.isSelf() || this.isSupervisor()) &&
          this.isNoSubmmit() &&
          this.canSubmit
        ) {
          if (!isEmptyNumber(e.event._def.extendedProps.standard_task_id)) {
            this.selectedActualTask = parseInt(e.event.id);
            this.showDatabaseTaskEdit = !this.showDatabaseTaskEdit;
            return;
          } else {
            this.selectedActualTask = parseInt(e.event.id);
            this.showFreeTaskEdit = !this.showFreeTaskEdit;
            return;
          } // END-IF-ELSE
        } else {
          this.selectedActualTask = parseInt(e.event.id);
          this.showViewTask = !this.showViewTask;
          return;
        } // END-IF-ELSE
      } // END-IF-ELSE
    },
    /**
     * handle date click
     * @param {object} [arg]
     */
    handleDateClick(arg) {
      if (this.isSelf() && this.isNoSubmmit() && this.canSubmit) {
        let planDate = formatDateTime(this.selectedDate, yyyyMMdd1);
        let eventDate = formatDateTime(arg.date, yyyyMMdd1);
        let isPlan = planDate === eventDate;

        if (!isPlan) {
          this.startFreeTime = formatDateTime(arg.date, HHmm2);
          this.endFreeTime = "";
          this.copyFreeTask = null;
          this.showFreeTask = !this.showFreeTask;
        }
      }
      return false;
    },
    /**
     * change member
     * @param {object} [member]
     */
    async changeMember(member) {
      if (member.id && this.userId !== member.id) {
        this.$store.dispatch("common/setMember", { selectedMember: member });
        this.userId = member.id;
        this.planId = "";
        this.actualId = "";
        if (!this.isSelf()) {
          this.$router.replace({
            path: "/actual",
            query: { uid: member.id, date: this.selectedDate },
          });
        } else {
          let draggableEl = document.getElementById("dragElements");
          new Draggable(draggableEl, {
            itemSelector: ".db-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".todo-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".favorite-db-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".favorite-task",
          });
          new Draggable(draggableEl, {
            itemSelector: ".monthly-task",
          });
          this.$router.replace({
            path: "/actual",
            query: { date: this.selectedDate },
          });
        }
        await this.getSchedule();
      }
    },
    /**
     * get member
     */
    async getMembers() {
      try {
        const { data, status } = await axios.get(GET_MEMBERS_API());
        this.members = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC108, status);
      } // END-TRY-CATCH
    },
    /**
     * check actual task
     * @param {event} [event]
     * @param {boolean} [isDisable]
     * @param {int} [id]
     */
    async checkActualTask(event, isDisable, id) {
      if (!isDisable) {
        event.stopPropagation();
        if (this.canApproveRemand) {
          try {
            let returnedTask = this.calendarOptions.events.filter(
              (x) => x.check && !x.isPlan
            );
            if (!isEmpty(returnedTask)) {
              this.submitButton = {
                text: `${returnedTask.length}件`,
                active: !this.canApproveRemand,
                isReject: true,
              };
            } else {
              this.submitButton = {
                text: `確認`,
                active: !this.canApproveRemand,
                isReject: false,
              };
            } // END-IF-ELSE
            this.showDraftText = isEmpty(returnedTask);
            let actualTasks = [
              {
                id: id,
                is_remand: +this.calendarOptions.events.find(
                  (x) => x.id === id && !x.isPlan && !x.isBreak
                ).check,
                category_secondary_id: !isEmptyNumber(
                  this.calendarOptions.events.find(
                    (x) => x.id === id && !x.isPlan && !x.isBreak
                  ).category_secondary_id
                )
                  ? +this.calendarOptions.events.find(
                      (x) => x.id === id && !x.isPlan && !x.isBreak
                    ).category_secondary_id
                  : null,
              },
            ];

            await axios.put(ACTUAL_TASK_API(this.actualId), {
              actual_tasks: actualTasks,
            });

            const _event = this.calendarOptions.events.find(
              (x) => x.id === id && !x.isPlan && !x.isBreak
            );
            _event.is_remand = actualTasks[0].is_remand;
            //TODO UPDATE SETTING PLAN TASK AND ACTUAL TASK
            let setting = this.setTaskConfiguration(_event);
            _event.color = setting.color;
            _event.markText = setting.markText;
            _event.timeText = setting.timeText;
            // _event.className = setting.className;
            // _event.className =
            //   this.isDefaultColor(setting.color) &&
            //   this.isDbTask(_event) &&
            //   this.isDbTaskByType(_event) &&
            //   !this.isSplitTask(_event)
            //     ? "db-task"
            //     : "";
            _event.className = !this.isCompleteTask(_event)
              ? this.isDbTask(_event)
                ? this.isDbTaskByType(_event)
                  ? "actual-unPlan-task"
                  : ""
                : "actual-unPlan-task"
              : "";
          } catch (error) {
            const { status } = error.response;
            this.calendarOptions.events.find(
              (x) => x.id === id && !x.isPlan && !x.isBreak
            ).check = !this.calendarOptions.events.find(
              (x) => x.id === id && !x.isPlan && !x.isBreak
            ).check;
            this.submitButton = {
              text: `確認`,
              active: !this.canApproveRemand,
              isReject: false,
            };
            apiErrorMessages(this, FC805, status);
          } // END-TRY-CATCH
        }
      } // END-IF
    },
    /**
     * compare actual task
     * @param {int} [taskId]
     * @param {event} [event]
     * @param {object} [task]
     */
    async compareActualTask(taskId, event, task) {
      const isBreakTask = !task.id;
      event.stopPropagation();
      if (!isBreakTask) {
        if (task.isCompleted) {
          let _event = this.calendarOptions.events.find(
            (x) => x.id === +taskId && x.isPlan && !x.isBreak
          );
          try {
            let actualTasks = [
              {
                schedule_task_id: task.id,
                start_time: null,
                name: task.name,
                description: task.description,
                category_secondary_id: task.category_secondary_id,
                task_type_id: task.task_type_id,
                cost: task.cost,
                count: task.count,
                is_need_output: task.is_need_output || 0,
                output_detail: task.output_detail,
                is_favorite: task.is_favorite || 0,
                standard_task_id: task.standard_task_id,
                standard_task_revision: task.standard_task_revision,
                is_remand: 0,
                free_task_id: task.free_task_id,
                detail: task.detail || "",
                skill_level_id: task.skill_level_id || null,
                schedule_month_task_id: task.schedule_month_task_id,
              },
            ];

            const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
              actual_tasks: actualTasks,
            });
            if (this.showTodoList && this.isSplitTask(_event)) {
              this.$root.$refs.actualTodoTabList?.getSplitData();
            }

            if (this.showTodoList) {
              this.$refs.todoTaskList.loadTodoListData();
            }

            //TODO UPDATE SETTING TASK COLOR
            _event.is_completed = !!task.isCompleted == true ? 1 : 0;
            let x = deepClone(_event);
            x.id = data.result[0].id;
            x.schedule_task_id = task.id;

            x.isPlan = false;
            x.isBreak = false;
            x.is_remand = 0;
            x.is_remand_previous = 0;
            x.actual_free_task_id = task.id;
            x.start_time = data.result[0].start_time;
            x.end_time = data.result[0].end_time;
            x.start = formatDateTime(
              addDays(
                `${this.selectedDate} ${data.result[0].start_time}`,
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            );
            x.end = formatDateTime(
              addDays(
                `${this.selectedDate} ${adjustMinEnd(
                  data.result[0].start_time,
                  data.result[0].end_time,
                  HHmmss1,
                  this.min
                )}`, // adjustMinEnd HHmmss1
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            );
            x.end_real = formatDateTime(
              addDays(
                `${this.selectedDate} ${data.result[0].end_time}`,
                1,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            );
            let setting = this.setTaskConfiguration(x);

            this.calendarOptions.events.push({
              ...x,
              ...{
                title: x.name,
                isCompleted: !!x.is_completed || +x.is_source_completed === 1,
                check: !!x.is_remand,
                showCheck: this.isSupervisor(),
                isBreak: false,
                isPlan: false,
                timeText: setting.timeText,
                className: "",
                markText: setting.markText,
                color: setting.color,
                disabled: false,
              },
            });
          } catch (error) {
            const { status } = error.response;
            if (status === 404) {
              this.$alert(
                "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。"
              );
            } else {
              apiErrorMessages(this, FC805, status);
            }
            _event.isCompleted = false;
          } // END-TRY-CATCH
        } else {
          let actualTask = this.calendarOptions.events.find(
            (x) => x.schedule_task_id === +taskId && !x.isPlan
          );
          if (
            +actualTask.cost * +actualTask.count !==
            +task.cost * +task.count
          ) {
            this.$confirm("実績を編集済みですが、本当に削除しますか？", {
              buttons: {
                ok: {
                  text: this.$t("label.buttons.delete"),
                  type: "primary",
                },
                cancel: {
                  text: this.$t("label.buttons.cancel"),
                  type: "ligth",
                },
              },
              rtl: true,
            })
              .then(async () => {
                try {
                  await axios.delete(
                    GET_OR_DEL_ACTUAL_TASK_API(this.actualId, actualTask.id)
                  );
                  this.calendarOptions.events =
                    this.calendarOptions.events.filter(
                      (x) => !(x.id === actualTask.id && !x.isPlan)
                    );
                  let _event = this.calendarOptions.events.find(
                    (x) => x.id === +taskId && x.isPlan && !x.isBreak
                  );
                  if (this.showTodoList && this.isSplitTask(_event)) {
                    this.$root.$refs.actualTodoTabList?.getSplitData();
                    this.$refs.todoTaskList &&
                      this.$refs.todoTaskList.getSplitData();
                  }
                  _event.is_completed = 0;
                  let setting = this.setTaskConfiguration(_event);
                  _event.color = setting.color;
                  _event.timeText = setting.timeText;
                  _event.markText = setting.markText;
                  _event.className = this.isDbTask(_event)
                    ? this.isDbTaskByType(_event)
                      ? "plan-unComplete-task"
                      : ""
                    : "plan-unComplete-task";

                    let childTask = this.calendarOptions.events.filter(
                      (x) => !!x.is_split_task
                              && x.split_task_source_id === _event.split_task_source_id 
                              && x.isPlan
                              && +x.split_task_no > +_event.split_task_no
                    );
                    if (childTask && childTask.length > 0){
                      childTask.map(
                        x => {
                          x.isCompleted = false;
                          x.is_source_completed = 0;
                          x.className = "";
                        }
                      );
                    }
                    await this.deleteTodoTask();
                } catch (error) {
                  const { status } = error.response;
                  apiErrorMessages(this, FC806, status);
                  let _event = this.calendarOptions.events.find(
                    (x) => x.id === +taskId && x.isPlan && !x.isBreak
                  );
                  _event.isCompleted = true;
                }
              })
              .catch(() => {
                let _event = this.calendarOptions.events.find(
                  (x) => x.id === +taskId && x.isPlan && !x.isBreak
                );
                _event.isCompleted = true;
              });
          } else {
            try {
              await axios.delete(
                GET_OR_DEL_ACTUAL_TASK_API(this.actualId, actualTask.id)
              );
              this.calendarOptions.events = this.calendarOptions.events.filter(
                (x) => !(x.id === actualTask.id && !x.isPlan)
              );
              let _event = this.calendarOptions.events.find(
                (x) => x.id === +taskId && x.isPlan && !x.isBreak
              );
              if (this.showTodoList && this.isSplitTask(_event)) {
                this.$root.$refs.actualTodoTabList?.getSplitData();
                this.$refs.todoTaskList &&
                  this.$refs.todoTaskList.getSplitData();
              }
              _event.is_completed = 0;
              let setting = this.setTaskConfiguration(_event);
              _event.color = setting.color;
              _event.timeText = setting.timeText;
              _event.markText = setting.markText;
              _event.className = this.isDbTask(_event)
                ? this.isDbTaskByType(_event)
                  ? "plan-unComplete-task"
                  : ""
                : "plan-unComplete-task";
                let childTask = this.calendarOptions.events.filter(
                      (x) => !!x.is_split_task
                              && x.split_task_source_id === _event.split_task_source_id 
                              && x.isPlan
                              && +x.split_task_no > +_event.split_task_no
                    );
                    if (childTask && childTask.length > 0){
                      childTask.map(
                        x => {
                          x.isCompleted = false;
                          x.is_source_completed = 0;
                          x.className = "";
                        }
                      );
                    }
                await this.deleteTodoTask();
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC806, status);
              let _event = this.calendarOptions.events.find(
                (x) => x.id === +taskId && x.isPlan && !x.isBreak
              );
              _event.isCompleted = true;
            }
          }
        }
      } else {
        // Todo for breakTask
        if (task.isCompleted) {
          let planBreakTask = this.calendarOptions.events.find(
            (x) => x.isBreak && x.isPlan
          );
          try {
            const { data } = await axios.put(ACTUAL_API(this.actualId), {
              start_break_time: null,
              end_break_time: null,
              date: formatDateTime(this.selectedDate, yyyyMMdd1),
              schedule_id: this.planId,
              phase_type: SystemConstant.PHASE_TYPE_UNSUBMITTED,
            });
            planBreakTask.is_completed = !!task.isCompleted == true ? 1 : 0;

            let t = {
              title: "休憩",
              start: formatDateTime(
                addDays(
                  `${this.selectedDate} ${data.result.start_break_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              end: formatDateTime(
                addDays(
                  `${this.selectedDate} ${adjustMinEnd(
                    data.result.start_break_time,
                    data.result.end_break_time,
                    HHmmss1,
                    this.min
                  )}`, // adjustMinEnd HHmmss1
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              end_real: formatDateTime(
                addDays(
                  `${this.selectedDate} ${data.result.end_break_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              color: COLOR_GRAY,
              isBreak: true,
              isPlan: false,
              isCompleted: false,
              count: 1,
              cost: this.diffTime(
                data.result.start_break_time,
                data.result.end_break_time
              ),
              start_time: formatDateTime(
                parseDateTime(planBreakTask.start, yyyyMMddHHmm1),
                HHmm3
              ),
              end_time: formatDateTime(
                parseDateTime(planBreakTask.end_real, yyyyMMddHHmm1),
                HHmm3
              ),
              timeText: this.getWorkTime({
                count: 1,
                cost: this.diffTime(
                  data.result.start_break_time,
                  data.result.end_break_time
                ),
                unit: "break",
              }),
              disabled: false,
            };
            t.start_time = formatDateTime(t.start, HHmm3);
            t.end_time = formatDateTime(t.end_real, HHmm3);
            this.calendarOptions.events.push(t);
            this.actualId = data.result.id;
            this.actualStartBreakTime = formatDateTime(t.start, HHmm2);
            this.actualEndBreakTime = formatDateTime(t.end_real, HHmm2);
          } catch (error) {
            const { status } = error.response;
            if (status === 404) {
              this.$alert(
                "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
                {
                  width: "600px",
                }
              );
            } else {
              apiErrorMessages(this, FC819, status);
            }
            planBreakTask.isCompleted = false;
          }

          //
        } else {
          //
          let actualBreakTask = this.calendarOptions.events.find(
            (x) => x.isBreak && !x.isPlan
          );
          if (
            +actualBreakTask.cost * +actualBreakTask.count !==
            +task.cost * +task.count
          ) {
            this.$confirm("実績を編集済みですが、本当に削除しますか？", {
              buttons: {
                ok: {
                  text: this.$t("label.buttons.delete"),
                  type: "primary",
                },
                cancel: {
                  text: this.$t("label.buttons.cancel"),
                  type: "ligth",
                },
              },
              rtl: true,
            })
              .then(async () => {
                try {
                  const { data } = await axios.put(ACTUAL_API(this.actualId), {
                    start_break_time: "000000",
                    end_break_time: "000000",
                    date: formatDateTime(this.selectedDate, yyyyMMdd1),
                  });
                  this.calendarOptions.events =
                    this.calendarOptions.events.filter(
                      (x) => !(x.isBreak && !x.isPlan)
                    );
                  this.actualId = data.result.id;
                  this.actualStartBreakTime = "00:00";
                  this.actualEndBreakTime = "00:00";
                } catch (error) {
                  const { status } = error.response;
                  apiErrorMessages(this, FC819, status);
                  let planBreakTask = this.calendarOptions.events.find(
                    (x) => x.isBreak && x.isPlan
                  );
                  planBreakTask.isCompleted = true;
                }
              })
              .catch(() => {
                let planBreakTask = this.calendarOptions.events.find(
                  (x) => x.isBreak && x.isPlan
                );
                planBreakTask.isCompleted = true;
              });
          } else {
            try {
              const { data } = await axios.put(ACTUAL_API(this.actualId), {
                start_break_time: "000000",
                end_break_time: "000000",
                date: formatDateTime(this.selectedDate, yyyyMMdd1),
              });
              this.calendarOptions.events = this.calendarOptions.events.filter(
                (x) => !(x.isBreak && !x.isPlan)
              );
              this.actualId = data.result.id;
              this.actualStartBreakTime = "00:00";
              this.actualEndBreakTime = "00:00";
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC819, status);
              let planBreakTask = this.calendarOptions.events.find(
                (x) => x.isBreak && x.isPlan
              );
              planBreakTask.isCompleted = true;
            }
          }
        }
      }
    },
    /**
     * handle drop: drag drop from sidebar to calendar
     * @param {object} [info]
     */
    async handlelDrop(info) {
      let planDate = formatDateTime(this.selectedDate, yyyyMMdd1);
      let eventDate = formatDateTime(info.date, yyyyMMdd1);
      if (planDate === eventDate || !this.isNoSubmmit() || !this.canSubmit)
        return;
      if (info.draggedEl.className.toString().includes("favorite-db-task")) {
        await this.dropDbFavoriteTask(info);
      } else if (info.draggedEl.className.toString().includes("todo-task")){
        await this.dropTodoTask(info);
      } else if (info.draggedEl.className.toString().includes("db-task")){
        await this.dropDbTask(info);
      } else if (info.draggedEl.className.toString().includes("monthly-task")){
        await this.dropMonthlyTask(info);
      }    
      else{
        await this.dropFavoriteTask(info);
      }
    },
    /**
     * drag drop from DB task list
     * @param {object} [info]
     */
    dropDbTask: debounce_leading(async function (info) {
      let dbTasks = this.$refs.dbTaskList.getDatabaseList();
      let dbTaskId = info.draggedEl.getAttribute("name");
      let dbTask = dbTasks.find((x) => x.id === parseInt(dbTaskId));
      //登録時のDBタスクの回数は1回
      dbTask.count = 1;

      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      let startTime = formatDateTime(info.date, HHmm1);
      let endTime = formatDateTime(endDate, HHmm1);

      await this.createDbTaskActual(dbTask, startTime, endTime);
    }),

    /**
     * drag drop from todo task list
     * @param {object} [info]
     */
    dropTodoTask: debounce_leading(async function (info) {
      let type = info.draggedEl.getAttribute("data-type");
      let todoTasks =
        type === "split"
          ? this.$refs.todoTaskList.getSplitTask()
          : this.$refs.todoTaskList.getTodoList();
      let todoTaskId = info.draggedEl.getAttribute("name");
      let todoTask = todoTasks.find((x) => x.id === parseInt(todoTaskId));
      //登録時のDBタスクの回数は1回
      todoTask.count = todoTask.standard_task_id ? 1 : todoTask.count;

      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +todoTask.cost * +todoTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      let startTime = formatDateTime(info.date, HHmm1);
      let endTime = formatDateTime(endDate, HHmm1);
      let isOriginalDbTask = isEmptyNumber(todoTask.free_task_id) && isEmptyNumber(todoTask.actual_free_task_id);
      let relatedTaskScheduleId = this.calendarOptions.events.some(
        (x) => x.isPlan && !x.isBreak && x.id === +todoTask.schedule_task_id
      )
        ? todoTask.schedule_task_id
        : null;
      let actualTask = {
        schedule_task_id: todoTask.schedule_task_id,
        name: todoTask.name,
        description: todoTask.description,
        category_secondary_id: todoTask.category_secondary_id,
        task_type_id: todoTask.task_type_id,
        cost: +todoTask.cost,
        count: todoTask.count,
        is_need_output: +todoTask.is_need_output || 0,
        output_detail: todoTask.output_detail,
        is_favorite: +todoTask.is_favorite,
        comments: [],
        files: [],
        todo_task_id: type === "split" ? null : +todoTask.id,
        start_time: startTime + "00",
        is_completed: 0,
        is_remand: 0,
        standard_task_id: todoTask.standard_task_id,
        standard_task_revision: todoTask.standard_task_revision,
        category_primary_id: todoTask.category_primary_id,
        split_task_id: type === "split" ? todoTask.id : null,
        detail: todoTask.detail || "",
        skill_level_id: todoTask.skill_level_id || null,
        schedule_month_task_id: todoTask.schedule_month_task_id,
      };

      if (isOriginalDbTask) {
        actualTask = {
          ...actualTask,
          standard_task_id: todoTask.standard_task_id,
          standard_task_revision: todoTask.standard_task_revision,
        };
      }
      let actualTasks = [];
      actualTasks.push(actualTask);
      try {
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        let calendarTask = {
          split_task_source_id: todoTask.source_id,
          standard_task_id: todoTask.standard_task_id,
          standard_task_revision: todoTask.standard_task_revision,
          actual_free_task_id: isOriginalDbTask
            ? null
            : todoTask.free_task_id,
          schedule_task_id: relatedTaskScheduleId,
          title: todoTask.name,
          start: formatDateTime(
            addDays(`${this.selectedDate} ${startTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                startTime,
                endTime,
                HHmm1,
                this.min
              )}`,
              1,
              yyyyMMddHHmm3
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(`${this.selectedDate} ${endTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          check: false,
          isCompleted: false,
          showCheck: this.isSupervisor(),
          // className: this.getMarkOutput(todoTask),
          // className:
          //   this.isDbTask(todoTask) && type !== "split" ? "db-task" : "",
          className: this.isDbTask(todoTask)
            ? this.isDbTaskByType(todoTask)
              ? "actual-unPlan-task"
              : ""
            : "actual-unPlan-task",
          color:
            isOriginalDbTask &&
            this.isDbTaskByType(todoTask)
              ? COLOR_DB_TASK_NOT_COMPLETE
              : !this.isDbTaskByType(todoTask)
              ? COLOR_GRAY
              : COLOR_FREE_TASK_NOT_COMPLETE,
          isPlan: false,
          markText: this.isMark(todoTask),
          timeText: this.getWorkTime(todoTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: +todoTask.cost,
          count: todoTask.count,
          is_need_output: todoTask.is_need_output || 0,
          is_completed: 0,
          task_type_id: todoTask.task_type_id,
          category_primary_id: todoTask.category_primary_id,
          category_secondary_id: todoTask.category_secondary_id,
          category_secondary_name: todoTask.category_secondary_name,
          is_split_task: type === "split" ? 1 : 0,
          split_task_id: type === "split" ? todoTask.id : null,
          detail: todoTask.detail || "",
          schedule_month_task_id: todoTask.schedule_month_task_id,
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        type === "split"
          ? this.$refs.todoTaskList.getSplitData()
          : this.$refs.todoTaskList.removeTodoTask(todoTask.id);
        this.calendarOptions.events.push(calendarTask);
        // 予定タスクIDが存在したら、実施チェックボックスをチェック
        let scheduleTask = this.calendarOptions.events.find(
            (x) => x.id === +todoTask.schedule_task_id && x.isPlan && !x.isBreak
          );
        if (scheduleTask){
          scheduleTask.className  = "";
          scheduleTask.is_completed = 1;
          scheduleTask.isCompleted = true;
        }
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(
            "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
            {
              width: "600px",
            }
          );
        } else {
          apiErrorMessages(this, FC805, status);
        }
      }
    }),

    /**
     * drag drop from favorite list
     * @param {object} [info]
     */
     dropFavoriteTask: debounce_leading(async function (info) {
      let favoriteTasks = this.$refs.favoriteList.getFavoriteList();
      let favoriteTaskId = info.draggedEl.getAttribute("name");
      let favoriteTask = favoriteTasks.find((x) => x.favorite_task_id === parseInt(favoriteTaskId));

      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +favoriteTask.cost * +favoriteTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      let startTime = formatDateTime(info.date, HHmm1);
      let endTime = formatDateTime(endDate, HHmm1);
      await this.createFreeTaskActual(favoriteTask, startTime, endTime);
    }),
    /**
     * drag drop dbtask from favorite list
     * @param {object} [info]
     */
     dropDbFavoriteTask: debounce_leading(async function (info) {
      let dbTasks = this.$refs.favoriteList.getDatabaseList();
      let dbTaskId = info.draggedEl.getAttribute("name");
      let dbTask = dbTasks.find((x) => x.standard_task_id === parseInt(dbTaskId));
      dbTask.id = dbTask.standard_task_id;
      dbTask.revision = dbTask.standard_task_revision;
      //登録時のDBタスクの回数は1回
      dbTask.count = 1;

      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +dbTask.cost * +dbTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      let startTime = formatDateTime(info.date, HHmm1);
      let endTime = formatDateTime(endDate, HHmm1);

      await this.createDbTaskActual(dbTask, startTime, endTime);
    }),
    /**
     * drag drop from monthly task list
     * @param {object} [info]
     */
    dropMonthlyTask: debounce_leading(async function (info) {
      let monthlyTasks = this.$refs.monthlyList.getMonthlytaskList();
      let monthlyTaskId = info.draggedEl.getAttribute("name");
      let monthlyTask = monthlyTasks.find((x) => x.id === parseInt(monthlyTaskId));
      monthlyTask.count = 1;

      let endDate = new Date(info.date);
      endDate = new Date(
        endDate.getTime() + +monthlyTask.cost * +monthlyTask.count * 60000
      );
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(info.date, yyyyMMdd1)
      ) {
        this.$toast("終了時間が本日を超えるため移動できません。", 3000);
        return;
      }
      let startTime = formatDateTime(info.date, HHmm1);
      let endTime = formatDateTime(endDate, HHmm1);
      let actualTasks = [
        {
          schedule_task_id: monthlyTask.schedule_task_id,
          name: monthlyTask.name,
          description: monthlyTask.description,
          category_secondary_id: monthlyTask.category_secondary_id,
          task_type_id: monthlyTask.task_type_id,
          cost: +monthlyTask.cost,
          count: 1,
          is_need_output: +monthlyTask.is_need_output || 0,
          output_detail: monthlyTask.output_detail,
          is_favorite: 0,
          comments: [],
          files: [],
          start_time: startTime + "00",
          is_completed: 0,
          is_remand: 0,
          standard_task_id: monthlyTask.standard_task_id,
          standard_task_revision: monthlyTask.standard_task_revision,
          category_primary_id: monthlyTask.category_primary_id,
          detail: monthlyTask.detail || "",
          skill_level_id: monthlyTask.skill_level_id || null,
          schedule_month_task_id: monthlyTask.id,
        },
      ];

      try {
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });
        let calendarTask = {
          standard_task_id: monthlyTask.standard_task_id,
          standard_task_revision: monthlyTask.standard_task_revision,
          actual_free_task_id: data.result[0].actual_free_task_id,
          schedule_task_id: monthlyTask.schedule_task_id,
          title: monthlyTask.name,
          start: formatDateTime(
            addDays(`${this.selectedDate} ${startTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            addDays(
              `${this.selectedDate} ${adjustMinEnd(
                startTime,
                endTime,
                HHmm1,
                this.min
              )}`,
              1,
              yyyyMMddHHmm3
            ), // adjustMinEnd HHmm1
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            addDays(`${this.selectedDate} ${endTime}`, 1, yyyyMMddHHmm3),
            yyyyMMddHHmm1
          ),
          check: false,
          isCompleted: false,
          showCheck: this.isSupervisor(),
          className:
            (this.isDbTask(monthlyTask) && this.isDbTaskByType(monthlyTask)) ||
            this.isPlanFreeTask(monthlyTask)
              ? "actual-unPlan-task"
              : "",
          color: this.setTaskConfiguration(monthlyTask).color,
          isPlan: false,
          markText: this.isMark(monthlyTask),
          timeText: this.getWorkTime(monthlyTask),
          isBreak: false,
          id: data.result[0].id,
          disabled: false,
          cost: +monthlyTask.cost,
          count: 1,
          is_need_output: monthlyTask.is_need_output || 0,
          is_completed: 0,
          task_type_id: monthlyTask.task_type_id,
          category_primary_id: monthlyTask.category_primary_id,
          category_secondary_id: monthlyTask.category_secondary_id,
          category_secondary_name: monthlyTask.category_secondary_name,
          detail: monthlyTask.detail || "",
          schedule_month_task_id: monthlyTask.id,
        };
        calendarTask.start_time = formatDateTime(calendarTask.start, HHmm3);
        calendarTask.end_time = formatDateTime(calendarTask.end_real, HHmm3);
        this.calendarOptions.events.push(calendarTask);
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(
            "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
            {
              width: "600px",
            }
          );
        } else {
          apiErrorMessages(this, FC805, status);
        }
      }
    }),

    /**
     * allow event drop
     * @param {object} [info]
     */
    allowEventDrop(info) {
      let planDate = formatDateTime(this.selectedDate, yyyyMMdd1);
      let eventDate = formatDateTime(info.start, yyyyMMdd1);
      return planDate !== eventDate;
    },
    /**
     * calendar drop
     * @param {object} [info]
     */
    async calendarDrop(info) {
      if (info.oldEvent._def.extendedProps.isPlan) {
        if (!info.oldEvent._def.extendedProps.isBreak) {
          // 実装できない
          if (!this.canImplementation(info.oldEvent.id)){
            info.revert();
            return;
          }
          
          if (
            !this.calendarOptions.events.some(
              (x) =>
                x.schedule_task_id === +info.oldEvent.id &&
                !x.isPlan &&
                !x.isBreak
            )
          ) {
            let _event = this.calendarOptions.events.find(
              (x) => x.id === +info.oldEvent.id && x.isPlan && !x.isBreak
            );
            if (
              !timeValidator(
                toHHmm(
                  this.calculateTime(
                    formatDateTime(info.event.start, HHmm2),
                    +info.event.extendedProps.count,
                    +info.event.extendedProps.cost
                  )
                )
              )
            ) {
              this.$toast("終了時間が本日を超えるため移動できません。", 3000);
              info.revert();
              return;
            }
            try {
              let actualTasks = [
                {
                  schedule_task_id: +info.oldEvent.id,
                  start_time: formatDateTime(info.event.start, HHmm3),
                  name: info.oldEvent._def.extendedProps.name,
                  description: info.oldEvent._def.extendedProps.description,
                  category_secondary_id:
                    info.oldEvent._def.extendedProps.category_secondary_id,
                  task_type_id: info.oldEvent._def.extendedProps.task_type_id,
                  cost: info.oldEvent._def.extendedProps.cost,
                  count: info.oldEvent._def.extendedProps.count,
                  is_need_output:
                    info.oldEvent._def.extendedProps.is_need_output || 0,
                  output_detail: info.oldEvent._def.extendedProps.output_detail,
                  is_favorite:
                    info.oldEvent._def.extendedProps.is_favorite || 0,
                  standard_task_id:
                    info.oldEvent._def.extendedProps.standard_task_id,
                  standard_task_revision:
                    info.oldEvent._def.extendedProps.standard_task_revision,
                  is_remand: 0,
                  free_task_id: info.oldEvent._def.extendedProps.free_task_id,
                  detail: info.oldEvent._def.extendedProps.detail || "",
                  skill_level_id:
                    info.oldEvent._def.extendedProps.skill_level_id || null,
                  schedule_month_task_id:
                    info.oldEvent._def.extendedProps.schedule_month_task_id,
                },
              ];
              const { data } = await axios.post(
                ACTUAL_TASK_API(this.actualId),
                {
                  actual_tasks: actualTasks,
                }
              );
              if (this.showTodoList && this.isSplitTask(_event)) {
                this.$root.$refs.actualTodoTabList?.getSplitData();
                this.$refs.todoTaskList &&
                  this.$refs.todoTaskList.getSplitData();
              }
              _event.isCompleted = true;
              _event.is_completed = 1;
              let x = deepClone(_event);
              x.id = data.result[0].id;
              x.schedule_task_id = +info.oldEvent.id;
              x.actual_free_task_id = +info.oldEvent.id;
              // let setting = this.setTaskConfiguration(_event);
              // _event.color = setting.color;
              // _event.timeText = setting.timeText;
              // _event.markText = setting.markText;
              // _event.className = setting.className;

              x.isPlan = false;
              x.isBreak = false;
              x.is_remand = 0;
              x.is_remand_previous = 0;
              x.start_time = data.result[0].start_time;
              x.end_time = data.result[0].end_time;
              x.start = formatDateTime(
                addDays(
                  `${this.selectedDate} ${data.result[0].start_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              );
              x.end = formatDateTime(
                addDays(
                  `${this.selectedDate} ${adjustMinEnd(
                    data.result[0].start_time,
                    data.result[0].end_time,
                    HHmmss1,
                    this.min
                  )}`, // adjustMinEnd HHmmss1
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              );
              x.end_real = formatDateTime(
                addDays(
                  `${this.selectedDate} ${data.result[0].end_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              );
              let setting = this.setTaskConfiguration(x);

              this.calendarOptions.events.push({
                ...x,
                ...{
                  title: x.name,
                  check: !!x.is_remand,
                  showCheck: this.isSupervisor(),
                  isBreak: false,
                  isPlan: false,
                  timeText: setting.timeText,
                  // className: setting.className,
                  // className:
                  //   this.isDefaultColor(setting.color) &&
                  //   this.isDbTask(x) &&
                  //   this.isDbTaskByType(x) &&
                  //   !this.isSplitTask(x)
                  //     ? "db-task"
                  //     : "",
                  className: !this.isCompleteTask(x)
                    ? this.isDbTask(x)
                      ? this.isDbTaskByType(x)
                        ? "actual-unPlan-task"
                        : ""
                      : "actual-unPlan-task"
                    : "",
                  markText: setting.markText,
                  color: setting.color,
                  disabled: false,
                },
              });
            } catch (error) {
              _event.isCompleted = false;
              _event.is_completed = 0;
              const { status } = error.response;
              if (status === 404) {
                this.$alert(
                  "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
                  {
                    width: "600px",
                  }
                );
              } else {
                apiErrorMessages(this, FC805, status);
              }
              info.revert();
            } // END-TRY-CATCH
          } else {
            info.revert();
          }
        } else {
          if (
            this.actualStartBreakTime === "00:00" &&
            this.actualEndBreakTime === "00:00"
          ) {
            // Todo for breakTask
            let planBreakTask = this.calendarOptions.events.find(
              (x) => x.isBreak && x.isPlan
            );
            planBreakTask.isCompleted = true;
            planBreakTask.is_completed = 1;
            let endDate = new Date(info.event.start);
            endDate = new Date(
              endDate.getTime() +
                +info.event.extendedProps.cost *
                  +info.event.extendedProps.count *
                  60000
            );
            if (
              +formatDateTime(endDate, yyyyMMdd1) >
              +formatDateTime(info.event.start, yyyyMMdd1)
            ) {
              this.$toast("終了時間が本日を超えるため移動できません。", 3000);
              info.revert();
              return;
            }
            let startBreakTime = formatDateTime(info.event.start, HHmm3);
            let endBreakTime = formatDateTime(endDate, HHmm3);
            try {
              const { data } = await axios.put(ACTUAL_API(this.actualId), {
                start_break_time: startBreakTime,
                end_break_time: endBreakTime,
                date: formatDateTime(this.selectedDate, yyyyMMdd1),
                schedule_id: this.planId,
                phase_type: SystemConstant.PHASE_TYPE_UNSUBMITTED,
              });

              let t = {
                title: "休憩",
                start: formatDateTime(
                  addDays(
                    `${this.selectedDate} ${data.result.start_break_time}`,
                    1,
                    yyyyMMddHHmmss1
                  ),
                  yyyyMMddHHmm1
                ),
                end: formatDateTime(
                  addDays(
                    `${this.selectedDate} ${adjustMinEnd(
                      data.result.start_break_time,
                      data.result.end_break_time,
                      HHmmss1,
                      this.min
                    )}`, // adjustMinEnd HHmmss1
                    1,
                    yyyyMMddHHmmss1
                  ),
                  yyyyMMddHHmm1
                ),
                end_real: formatDateTime(
                  addDays(
                    `${this.selectedDate} ${data.result.end_break_time}`,
                    1,
                    yyyyMMddHHmmss1
                  ),
                  yyyyMMddHHmm1
                ),
                color: COLOR_GRAY,
                isBreak: true,
                isPlan: false,
                isCompleted: false,
                count: 1,
                cost: this.diffTime(
                  data.result.start_break_time,
                  data.result.end_break_time
                ),
                start_time: formatDateTime(
                  parseDateTime(planBreakTask.start, yyyyMMddHHmm1),
                  HHmm3
                ),
                end_time: formatDateTime(
                  parseDateTime(planBreakTask.end_real, yyyyMMddHHmm1),
                  HHmm3
                ),
                timeText: this.getWorkTime({
                  count: 1,
                  cost: this.diffTime(
                    data.result.start_break_time,
                    data.result.end_break_time
                  ),
                  unit: "break",
                }),
                disabled: false,
              };
              t.start_time = formatDateTime(t.start, HHmm3);
              t.end_time = formatDateTime(t.end_real, HHmm3);
              this.calendarOptions.events.push(t);
              this.actualId = data.result.id;
              this.actualStartBreakTime = formatDateTime(t.start, HHmm2);
              this.actualEndBreakTime = formatDateTime(t.end_real, HHmm2);
            } catch (error) {
              planBreakTask.isCompleted = false;
              planBreakTask.is_completed = 0;
              const { status } = error.response;
              if (status === 404) {
                this.$alert(
                  "23:59を超える時間にタスクを配置できません。\r\n工数・回数を変更してください。",
                  {
                    width: "600px",
                  }
                );
              } else {
                apiErrorMessages(this, FC819, status);
              }
            }
          } else {
            info.revert();
          }
        }
      } else {
        let taskId = !isEmptyNumber(info.event.id) ? +info.event.id : null;
        if (info.event.extendedProps.isBreak) {
          let endDate = new Date(info.event.start);
          endDate = new Date(
            endDate.getTime() +
              +info.event.extendedProps.cost *
                +info.event.extendedProps.count *
                60000
          );
          if (
            +formatDateTime(endDate, yyyyMMdd1) >
            +formatDateTime(info.event.start, yyyyMMdd1)
          ) {
            this.$toast("終了時間が本日を超えるため移動できません。", 3000);
            info.revert();
            return;
          }
          let startBreakTime = formatDateTime(info.event.start, HHmm3);
          let endBreakTime = formatDateTime(endDate, HHmm3);
          try {
            await axios.put(ACTUAL_API(this.actualId), {
              start_break_time: startBreakTime,
              end_break_time: endBreakTime,
            });

            let existsEvent = this.calendarOptions.events.find(
              (x) => !x.isPlan && x.isBreak
            );
            if (existsEvent) {
              existsEvent.start_time = startBreakTime;
              existsEvent.end_time = endBreakTime;
              existsEvent.start = formatDateTime(
                info.event.start,
                yyyyMMddHHmmss2
              );
              existsEvent.end = formatDateTime(info.event.end, yyyyMMddHHmmss2);
              existsEvent.end_real = formatDateTime(endDate, yyyyMMddHHmmss2);
              this.actualStartBreakTime = formatDateTime(
                existsEvent.start,
                HHmm2
              );
              this.actualEndBreakTime = formatDateTime(
                existsEvent.end_real,
                HHmm2
              );
            }
          } catch (error) {
            const { status } = error.response;
            info.revert();
            apiErrorMessages(this, FC819, status);
          }
        } else {
          try {
            let actualTasks = [
              {
                id: taskId,
                start_time: formatDateTime(info.event.start, HHmm3),
                category_secondary_id: !isEmptyNumber(
                  info.event.extendedProps.category_secondary_id
                )
                  ? +info.event.extendedProps.category_secondary_id
                  : null,
              },
            ];
            if (
              !timeValidator(
                toHHmm(
                  this.calculateTime(
                    formatDateTime(info.event.start, HHmm2),
                    +info.event.extendedProps.count,
                    +info.event.extendedProps.cost
                  )
                )
              )
            ) {
              this.$toast("終了時間が本日を超えるため移動できません。", 3000);
              info.revert();
              return;
            }

            await axios.put(ACTUAL_TASK_API(this.actualId), {
              actual_tasks: actualTasks,
            });

            let existsEvent = this.calendarOptions.events.find(
              (x) => !x.isPlan && x.id === taskId
            );
            if (existsEvent) {
              let endDate = new Date(info.event.start);
              endDate = new Date(
                endDate.getTime() +
                  +info.event.extendedProps.cost *
                    +info.event.extendedProps.count *
                    60000
              );
              existsEvent.start_time = formatDateTime(info.event.start, HHmm3);
              // existsEvent.end_time = formatDateTime(info.event.end, HHmm3);
              existsEvent.end_time = formatDateTime(endDate, HHmm3);
              existsEvent.start = formatDateTime(
                info.event.start,
                yyyyMMddHHmmss2
              );
              existsEvent.end = formatDateTime(info.event.end, yyyyMMddHHmmss2);
              // existsEvent.end_real = formatDateTime(
              //   info.event.extendedProps.end_real,
              //   yyyyMMddHHmmss2
              // );
              existsEvent.end_real = formatDateTime(endDate, yyyyMMddHHmmss2);
            }
          } catch (error) {
            const { status } = error.response;
            info.revert();
            apiErrorMessages(this, FC805, status);
          }
        }
      }
    },
    /**
     * calculate time
     * @param {Time} [startTime]
     * @param {int} [cost]
     * @param {int} [count]
     */
    calculateTime(startTime, cost, count) {
      let start = toMinute(startTime, ":");
      let plusTime = parseInt(cost) * parseInt(count);
      return start + plusTime;
    },
    /**
     * duplicate free task
     * @param {object} [task]
     */
    duplicateFreeTask(task) {
      this.copyFreeTask = {};
      this.copyFreeTask = deepClone(task);
      this.copyFreeTask.id = null;
      //this.copyFreeTask.start_time = "";
      //this.copyFreeTask.end_time = "";
      this.copyFreeTask.schedule_task_id = null;
      this.showFreeTask = !this.showFreeTask;
    },
    /**
     * duplicate DB task
     * @param {object} [task]
     */
    duplicateDbTask(task) {
      this.copyDbTask = {};
      this.copyDbTask = deepClone(task);
      this.copyDbTask.id = null;
      //this.copyDbTask.start_time = "";
      //this.copyDbTask.end_time = "";
      this.copyDbTask.schedule_task_id = null;
      this.showNewDbTask = !this.showNewDbTask;
    },
    /**
     * archive free task
     * @param {object} [task]
     */
    archiveFreeTask: debounce_leading(function (task) {
      let _t = deepClone(task);
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== _t.id || x.isPlan || x.isBreak
      );

      //TODO RESET SETTING FOR RELATED PLAN TASK
      if (this.isRelatedTask(_t)) {
        let planTask = this.calendarOptions.events.find(
          (x) => x.id === _t.schedule_task_id && x.isPlan
        );
        this.resetPlanTaskSetting(planTask);
        let childTask = this.calendarOptions.events.filter(
                      (x) => !!x.is_split_task
                              && x.split_task_source_id === planTask.split_task_source_id 
                              && x.isPlan
                              && +x.split_task_no > +planTask.split_task_no
                    );
                    if (childTask && childTask.length > 0){
                      childTask.map(
                        x => {
                          x.isCompleted = false;
                          x.is_source_completed = 0;
                          x.className = "plan-unComplete-task";
                        }
                      );
                    }
      }
      _t.free_task_id = _t.id;
      _t.actual_free_task_id = _t.id;
      delete _t.schedule_task_id;
      this.$refs.todoTaskList.registerTodoTask(_t);
    }),
    /**
     * archive DB task
     * @param {object} [task]
     */
    archiveDbTask: debounce_leading(async function (task) {
      try {
        let _t = deepClone(task);
        const { data, status } = await axios.get(TODO_TASK_API(_t.todoTaskId));
        if (status !== STATUS_NO_CONTENT) {
          this.calendarOptions.events = this.calendarOptions.events.filter(
            (x) => x.id !== _t.id || x.isPlan || x.isBreak
          );
          //TODO RESET SETTING FOR RELATED PLAN TASK
          if (this.isRelatedTask(_t)) {
            let planTask = this.calendarOptions.events.find(
              (x) => x.id === _t.schedule_task_id && x.isPlan
            );
            this.resetPlanTaskSetting(planTask);
            let childTask = this.calendarOptions.events.filter(
                      (x) => !!x.is_split_task
                              && x.split_task_source_id === planTask.split_task_source_id 
                              && x.isPlan
                              && +x.split_task_no > +planTask.split_task_no
                    );
                    if (childTask && childTask.length > 0){
                      childTask.map(
                        x => {
                          x.isCompleted = false;
                          x.is_source_completed = 0;
                          x.className = "plan-unComplete-task";
                        }
                      );
                    }
          }
          this.$refs.todoTaskList.registerDbTodoTask(data.result[0]);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC807, status);
      }
    }),
    /**
     * archive DB split task
     * @param {object} [task]
     */
    archiveDbSplitTask(task) {
      let _t = deepClone(task);
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== _t.id || x.isPlan || x.isBreak
      );
      //TODO RESET SETTING FOR RELATED PLAN TASK
      if (this.isRelatedTask(_t)) {
        let planTask = this.calendarOptions.events.find(
          (x) => x.id === _t.schedule_task_id && x.isPlan
        );
        this.resetPlanTaskSetting(planTask);
      }
      this.showTodoList && this.$refs.todoTaskList.getSplitData();
    },

    setFavorite(item){
      if (item.is_favorite){
        this.$refs.favoriteList?.addDbTask(item);
      }
      else{
        this.$refs.favoriteList?.removeDbTask(item.standard_task_id);
      }
    },

    setFreeFavorite(item){
      if (item.is_favorite){
        this.$refs.favoriteList?.registerFavoriteTask(item);
      }
      else{
        this.$refs.favoriteList?.deleteFavoriteTask(item);
      }
    },

    /**
     * get schedule
     */
    async getSchedule() {
      this.isRenderCompleted = false;
      try {
        const qs = (params) =>
          Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");

        // rest value
        this.isRejectAll = false;

        //TODO CREATE API LINK
        let apiUrl = "";
        switch (true) {
          case !isEmpty(this.$route.query.sid) &&
            !isEmpty(this.$route.query.taskId) &&
            !isEmpty(this.planId):
          case !isEmpty(this.$route.query.sid) && !isEmpty(this.planId):
            apiUrl = `${GET_SCHEDULE_API(this.planId)}`;
            break;
          default:
            apiUrl = `${GET_SCHEDULE_API(this.planId)}?${qs({
              user_id: this.userId,
              date: formatDateTime(this.selectedDate, yyyyMMdd1),
            })}`;
            break;
        }

        const { data, status } = await axios.get(apiUrl);

        this.originalDate = !this.$route.query.date
          ? this.selectedDate
          : this.originalDate;
        this.authorizeCheck = false;
        this.authorizeMessage = "";
        this.calendarOptions.events = [];

        switch (true) {
          case status === STATUS_NO_CONTENT:
            await this.getActual(null, null, null);
            return;
        }
        let calendar = data.result[0];
        let scheduleSelectedDate = formatDateTime(
          parseDateTime(calendar.date, yyyyMMdd1),
          yyyyMMdd2
        );
        let scheduleCalendarEvents = calendar.schedule_tasks.map((x) => {
          x.isPlan = true;
          x.isBreak = false;
          x.isCompleted = !!x.is_completed || +x.is_source_completed === 1;
          let event = {
            ...x,
            title: x.name,
            start: formatDateTime(
              parseDateTime(
                `${scheduleSelectedDate} ${x.start_time}`,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            ),
            end: formatDateTime(
              parseDateTime(
                `${scheduleSelectedDate} ${adjustMinEnd(
                  x.start_time,
                  x.end_time,
                  HHmmss1,
                  this.min
                )}`, // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            ),
            end_real: formatDateTime(
              parseDateTime(
                `${scheduleSelectedDate} ${x.end_time}`,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmm1
            ),
            isPlan: true,
            isBreak: false,
            className: !this.isCompleteTask(x)
              ? this.isDbTask(x)
                ? this.isDbTaskByType(x)
                  ? "plan-unComplete-task"
                  : ""
                : "plan-unComplete-task"
              : "",
            color: this.getTaskColor(x),
            timeText: this.getTaskTimeText(x, null),
            markText: false,
          };
          if (event.isCompleted){
                event.className = "";
              }
              else{
                event.className = "plan-unComplete-task";
              }
          return event;
        });

        let breakTask = {}
        if (calendar.start_break_time && calendar.end_break_time) {
          breakTask = {
          title: "休憩",
          start: formatDateTime(
            parseDateTime(
              `${scheduleSelectedDate} ${calendar.start_break_time}`,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          ),
          end: formatDateTime(
            parseDateTime(
              `${scheduleSelectedDate} ${adjustMinEnd(
                calendar.start_break_time,
                calendar.end_break_time,
                HHmmss1,
                this.min
              )}`, // adjustMinEnd HHmmss1
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          ),
          end_real: formatDateTime(
            parseDateTime(
              `${scheduleSelectedDate} ${calendar.end_break_time}`,
              yyyyMMddHHmmss1
            ),
            yyyyMMddHHmm1
          ),
          start_time: calendar.start_break_time,
          end_time: calendar.end_break_time,
          color: COLOR_GRAY,
          isPlan: true,
          isBreak: true,
          count: 1,
          cost: this.diffTime(
            calendar.start_break_time,
            calendar.end_break_time
          ),
          timeText: this.getWorkTime({
            count: 1,
            cost: this.diffTime(
              calendar.start_break_time,
              calendar.end_break_time
            ),
            unit: "break",
          }),
          isCompleted: false,
        };
        this.planStartBreakTime = formatDateTime(breakTask.start, HHmm2);
        this.planEndBreakTime = formatDateTime(breakTask.end_real, HHmm2);
          this.haveBreakTime = false;
        }
        else{
          this.haveBreakTime = true;
        }

        this.planId = calendar.id;
        this.planTasks = calendar.schedule_tasks;
        this.scheduleTaskIdList = this.planTasks.map((x) => x.id);
        this.startWorkTime = calendar.start_time;
        this.endWorkTime = calendar.end_time;
        this.planPhaseId = calendar.statuses[calendar.statuses.length - 1].phase_id;
        //TODO GET ACTUAL CALENDAR
        await this.getActual(
          calendar.schedule_tasks,
          breakTask,
          scheduleCalendarEvents
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC816, status);
      }
    },
    /**
     * get Actual
     * @param {object} [scheduleTasks]
     * @param {object} [scheduleBreakTask]
     * @param {event} [scheduleCalendarEvents]
     */
    async getActual(scheduleTasks, scheduleBreakTask, scheduleCalendarEvents) {
      try {
        const qs = (params) =>
          Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");
        //TODO CREATE API LINK
        let apiUrl = "";
        switch (true) {
          case !isEmpty(this.$route.query.taskId) &&
            !isEmpty(this.planId) &&
            !isEmpty(this.actualId):
          case !isEmpty(this.actualId) && !isEmpty(this.planId):
            apiUrl = `${ACTUAL_API(this.actualId)}`;
            break;
          default:
            apiUrl = `${ACTUAL_API(this.actualId)}?${qs({
              user_id: this.userId,
              date: formatDateTime(this.selectedDate, yyyyMMdd1),
            })}`;
            break;
        }

        const { data, status } = await axios.get(apiUrl);
        this.authorizeCheck = false;
        this.authorizeMessage = "";
        this.inputComment = "";
        this.inputSubComment = "";
        this.unCompletedPlans = [];
        switch (true) {
          case status === STATUS_NO_CONTENT && !this.isSelf():
            this.authorizeCalendar(
              this.$t("label.authorize_calendar.actual_not_created")
            );
            this.statusText = "";
            this.submitButton = {
              text: "",
              active: true,
              isReject: false,
            };
            this.statuses = [];
            return;
          case status === STATUS_NO_CONTENT && this.isSelf():
            //TODO ADD PLAN CALENDAR
            if (!isEmpty(scheduleCalendarEvents))
              this.calendarOptions.events = deepClone(scheduleCalendarEvents);
            else this.calendarOptions.events = [];

            //TODO ADD PLAN BREAK TASK
            if (!isEmpty(scheduleBreakTask)) {
              if (scheduleBreakTask.timeText !== TIME_TEXT_ZERO)
                this.calendarOptions.events.push(scheduleBreakTask);
            }
            await this.createActual();
            break;
          default: {
            let calendar = data.result[0];
            this.canViewComment = !!calendar.can_view_comment;
            this.canApproveRemand = !!calendar.can_approve_remand;
            this.canSubmitCancel = !!calendar.can_submit_cancel;
            this.actualId = calendar.id;
            this.userId = calendar.user_id;
            this.ownerName = calendar.user_name;
            this.canSubmit = !!calendar.can_submit;
            this.hasCompletedChange = !!calendar.has_completed_change;
            this.isInDraft = !!calendar.is_in_draft;
            this.selectedDate = formatDateTime(
              parseDateTime(calendar.date, yyyyMMdd1),
              yyyyMMdd2
            );
            //TODO ADD PLAN CALENDAR
            if (!isEmpty(scheduleCalendarEvents))
              this.calendarOptions.events = deepClone(scheduleCalendarEvents);
            else this.calendarOptions.events = [];

            if (!isEmpty(scheduleBreakTask)) {
              if (scheduleBreakTask.timeText !== TIME_TEXT_ZERO)
                this.calendarOptions.events.push(scheduleBreakTask);
            }

            let actualCalendar = calendar.actual_tasks.map((x) => {
              //TODO SETTING COLOR TASK WHEN INITIAL VIEW
              x.isPlan = false;
              x.isBreak = false;
              let setting = this.setTaskConfiguration(x);
              let event = {
                ...x,
                ...{
                  title: x.name,
                  start: formatDateTime(
                    addDays(
                      `${this.selectedDate} ${x.start_time}`,
                      1,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmm1
                  ),
                  end: formatDateTime(
                    addDays(
                      `${this.selectedDate} ${adjustMinEnd(
                        x.start_time,
                        x.end_time,
                        HHmmss1,
                        this.min
                      )}`, // adjustMinEnd HHmmss1
                      1,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmm1
                  ),
                  end_real: formatDateTime(
                    addDays(
                      `${this.selectedDate} ${x.end_time}`,
                      1,
                      yyyyMMddHHmmss1
                    ),
                    yyyyMMddHHmm1
                  ),
                  isCompleted: !!x.is_completed,
                  check: !!x.is_remand,
                  showCheck: this.isSupervisor(),
                  isBreak: false,
                  isPlan: false,
                  timeText: setting.timeText,
                  className: !this.isCompleteTask(x)
                    ? this.isDbTask(x)
                      ? this.isDbTaskByType(x)
                        ? "actual-unPlan-task"
                        : ""
                      : "actual-unPlan-task"
                    : "",
                  markText: setting.markText,
                  color: setting.color,
                  disabled: false,
                },
              };
              return event;
            });

            //merge actuat list
            this.calendarOptions.events =
              this.calendarOptions.events.concat(actualCalendar);
            if (calendar.start_break_time && calendar.end_break_time) {
              let breakTask = {
                title: "休憩",
                start: formatDateTime(
                  addDays(
                    `${this.selectedDate} ${calendar.start_break_time}`,
                    1,
                    yyyyMMddHHmmss1
                  ),
                  yyyyMMddHHmm1
                ),
                end: formatDateTime(
                  addDays(
                    `${this.selectedDate} ${adjustMinEnd(
                      calendar.start_break_time,
                      calendar.end_break_time,
                      HHmmss1,
                      this.min
                    )}`, // adjustMinEnd HHmmss1
                    1,
                    yyyyMMddHHmmss1
                  ),
                  yyyyMMddHHmm1
                ),
                end_real: formatDateTime(
                  addDays(
                    `${this.selectedDate} ${calendar.end_break_time}`,
                    1,
                    yyyyMMddHHmmss1
                  ),
                  yyyyMMddHHmm1
                ),
                color: COLOR_GRAY,
                isBreak: true,
                isPlan: false,
                isCompleted: false,
                start_time: calendar.start_break_time,
                end_time: calendar.end_break_time,
                count: 1,
                cost: this.diffTime(
                  calendar.start_break_time,
                  calendar.end_break_time
                ),
                timeText: this.getWorkTime({
                  count: 1,
                  cost: this.diffTime(
                    calendar.start_break_time,
                    calendar.end_break_time
                  ),
                  unit: "break",
                }),
                disabled: false,
              };
              this.actualStartBreakTime = formatDateTime(breakTask.start, HHmm2);
              this.actualEndBreakTime = formatDateTime(breakTask.end_real, HHmm2);
              if (breakTask.timeText !== TIME_TEXT_ZERO) {
                let planBreakTask = this.calendarOptions.events.find(
                  (x) => x.isBreak && x.isPlan
                );
                if (planBreakTask){
                  planBreakTask.isCompleted = true;
                }
                this.calendarOptions.events.push(breakTask);
                this.haveBreakTime = false;
              }
            }

            this.selectedUser = this.members.find((x) => x.id === this.userId);
            
            if (this.showMonthlyList){
              this.showMonthlyList = false;
              this.showDrawerRight = false;
            }
            this.gotoSelectedDate();
            this.actuaTasks = calendar.actual_tasks;
            this.changeCompletedTasks = this.actuaTasks.filter(
              (x) => x.is_completed_change === 1
            );
            calendar.statuses.forEach((statuses) => {
              if (statuses.issue_tasks && statuses.issue_tasks.length > 0) {
                statuses.issue_tasks.sort(
                  (a, b) => Number(a.order_value) - Number(b.order_value)
                );
              }
            });
            this.statuses = calendar.statuses;
            this.statusText = this.getStatusText(calendar.statuses[calendar.statuses.length - 1]);
            this.submitButton = this.getSubmitButton(calendar.statuses[calendar.statuses.length - 1]);
            let issueTasksList = calendar.statuses.filter(
              (x) =>
                x.phase_id ===
                  SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING ||
                x.phase_id ===
                  SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
            );
            let issueTasks = issueTasksList.length > 0 ? issueTasksList[issueTasksList.length - 1] : [];
            this.openMissingTask = this.checkOpenMissingTask(issueTasks);
            if (this.openMissingTask) {
              this.issueTasks = issueTasks.issue_tasks;
              this.issueTasks.sort((a, b) => a.order_value - b.order_value);
              this.subComment = issueTasks.sub_comment;
            }
            this.activeTask =
              calendar.statuses[calendar.statuses.length - 1].phase_id !==
              SystemConstant.PHASE_ID_UNSUBMITTED;

            let isGettedUncompleted = false;
            if (calendar.statuses.length > 1) {
              let secondStatus = calendar.statuses[1];
              //this.inputComment = secondStatus.comment;
              if (
                !isEmpty(secondStatus.issue_tasks) &&
                secondStatus.issue_tasks.length > 0
              ) {
                this.inputSubComment = secondStatus.sub_comment;
                await this.getUncompletedPlans();
                isGettedUncompleted = true;
              }
            }

            if (!isEmpty(this.missingTasks) && this.missingTasks.length > 0 &&
              this.isInDraft && !isGettedUncompleted){
                await this.getUncompletedPlans();
                isGettedUncompleted = true;
            }

            if (!isEmpty(this.missingTasks) && this.missingTasks.length > 0 &&
              !isGettedUncompleted && this.isNoSubmmit()){
                await this.getUncompletedPlans();
                isGettedUncompleted = true;
            }

            if (this.isVefified()){
              await this.getNotification();
            }
            
            break;
          }
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC818, status);
      }
    },
    /**
     * create actual
     */
    async createActual() {
      try {
        //TODO CREATE ACTUAL FROM BREAK TIME DEFAULT

        const { data } = await axios.post(ACTUAL_API(), {
          start_break_time: "000000",
          end_break_time: "000000",
          date: formatDateTime(this.selectedDate, yyyyMMdd1),
          schedule_id: this.planId,
          phase_type: SystemConstant.PHASE_TYPE_UNSUBMITTED,
        });

        this.startWorkTime = this.loginInfo?.start_time;
        this.endWorkTime = this.loginInfo?.end_time;
        this.selectedUser = this.members.find((x) => x.id === this.userId);
        this.actualId = data.result.id;
        this.actualStartBreakTime = "00:00";
        this.actualEndBreakTime = "00:00";

        this.statuses = data.result.statuses;
        this.canSubmit = !!data.result.can_submit;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        if (this.showMonthlyList){
          this.showMonthlyList = false;
          this.showDrawerRight = false;
        }
        this.gotoSelectedDate();
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC819, status);
      }
    },
    /**
     * update actual
     * @param {object} [scheduleTasks]
     */
    async updateActual(scheduleTasks) {
      try {
        //TODO COPY PLAN TASK TO ACTUAL TASK WHEN NEW ACTUAL CALENDAR
        let actualTasks = scheduleTasks.map((x) => {
          return {
            schedule_task_id: x.id,
            name: x.name,
            description: x.description,
            category_secondary_id: x.category_secondary_id,
            task_type_id: x.task_type_id,
            cost: x.cost,
            count: x.count,
            is_need_output: x.is_need_output || 0,
            output_detail: x.output_detail,
            is_favorite: x.is_favorite,
            standard_task_id: x.standard_task_id,
            standard_task_revision: x.standard_task_revision,
            start_time: x.start_time,
            end_time: x.end_time,
            is_completed: 0,
            is_remand: 0,
            free_task_id: x.free_task_id,
          };
        });
        const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: actualTasks,
        });

        //TODO CREATE ACTUAL CALENDAR WHEN NEW ACTUAL
        let actualCalendar = actualTasks.map((x, index) => {
          x.actual_free_task_id = x.free_task_id;
          x.isPlan = false;
          x.isBreak = false;
          let event = {
            ...x,
            ...{
              title: x.name,
              start: formatDateTime(
                addDays(
                  `${this.selectedDate} ${x.start_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              end: formatDateTime(
                addDays(
                  `${this.selectedDate} ${adjustMinEnd(
                    x.start_time,
                    x.end_time,
                    HHmmss1,
                    this.min
                  )}`, // adjustMinEnd HHmmss1
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              end_real: formatDateTime(
                addDays(
                  `${this.selectedDate} ${x.end_time}`,
                  1,
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmm1
              ),
              isCompleted: false,
              showCheck: this.isSupervisor(),
              isBreak: false,
              //className: this.getMarkOutput(x),
              // className:
              //   this.isDefaultColor(this.getTaskColor(x)) &&
              //   this.isDbTask(x) &&
              //   this.isDbTaskByType(x) &&
              //   !this.isSplitTask(x)
              //     ? "db-task"
              //     : "",
              className: !this.isCompleteTask(x)
                ? this.isDbTask(x)
                  ? this.isDbTaskByType(x)
                    ? "actual-unPlan-task"
                    : ""
                  : "actual-unPlan-task"
                : "",
              markText: this.isMark(x),
              color: this.getTaskColor(x),
              isPlan: false,
              timeText: this.getTaskTimeText(null, x),
              check: false,
              disabled: false,
              id: data.result[index]?.id,
            },
          };
          return event;
        });
        this.calendarOptions.events =
          this.calendarOptions.events.concat(actualCalendar);
        this.actuaTasks = actualTasks;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC805, status);
      }
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (item.actual_id === this.actualId && !!item.can_verified && !isEmptyNumber(item.status_notice_id)){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: item.status_notice_id,
            comment_notice_id: null,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    /**
     * create task success
     * @param {object} [task]
     */
    createTaskSuccess(task) {
      // let task = deepClone(tsk);
      // this.changeTimeFormat(task);
      let t = deepClone(task);
      if (task.is_split_task_completed === 0 && this.showTodoList) {
        this.$root.$refs.actualTodoTabList?.getSplitData();
        this.$refs.todoTaskLis && this.$refs.todoTaskList.getSplitData();
      }
      t.title = t.name;
      t.isCompleted = false;
      t.showCheck = this.isSupervisor();
      t.isBreak = false;
      t.isPlan = false;
      t.count = +t.count;
      t.cost = +t.cost;
      t.check = false;
      t.is_completed = 0;
      t.is_need_output = !!t.is_need_output === true ? 1 : 0;
      t.start = formatDateTime(
        addDays(`${this.selectedDate} ${t.start_time}`, 1, yyyyMMddHHmm1),
        yyyyMMddHHmm1
      );
      t.end = formatDateTime(
        addDays(
          `${this.selectedDate} ${adjustMinEnd(
            t.start_time,
            t.end_time,
            HHmm2,
            this.min
          )}`,
          1,
          yyyyMMddHHmm1
        ), // adjustMinEnd HHmm2
        yyyyMMddHHmm1
      );
      t.end_real = formatDateTime(
        addDays(`${this.selectedDate} ${t.end_time}`, 1, yyyyMMddHHmm1),
        yyyyMMddHHmm1
      );
      t.start_time = formatDateTime(t.start, HHmm3);
      t.end_time = formatDateTime(t.end_real, HHmm3);

      //TODO update setting new color
      let setting = this.setTaskConfiguration(t);
      t.color = setting.color;
      t.timeText = setting.timeText;
      t.markText = setting.markText;
      //t.className = setting.className;
      // t.className =
      //   this.isDefaultColor(setting.color) &&
      //   this.isDbTask(t) &&
      //   this.isDbTaskByType(t) &&
      //   !this.isSplitTask(t)
      //     ? "db-task"
      //     : "";
      t.className = this.isDbTask(t)
        ? this.isDbTaskByType(t)
          ? "actual-unPlan-task"
          : ""
        : "actual-unPlan-task";
      t.category_secondary_id = task.category_secondary_id;
      t.category_primary_id = task.category_primary_id;
      this.calendarOptions.events.push(t);

      if (formatDateTime(t.end, HHmm3) > 220000) {
        this.calendarOptions.slotMaxTime = this.getSlotMaxTime(
          this.calendarOptions.slotDuration,
          t.end
        );
      }

      if (task.is_favorite && task.actual_free_task_id){
        this.$refs.favoriteList?.registerFavoriteTask(task);
      }
    },
    /**
     * update task success
     * @param {object} [task]
     */
    updateTaskSuccess(task) {
      if (task.is_split_task_completed === 0 && this.showTodoList) {
        this.$root.$refs.actualTodoTabList?.getSplitData();
        this.$refs.todoTaskLis && this.$refs.todoTaskList.getSplitData();
      }

      if (task.has_completed_change === 1){
        this.hasCompletedChange = true;
        let updatedTask = this.actuaTasks.find(
            (x) => x.id === task.id
          );
        updatedTask.is_completed_change = 1;
        this.changeCompletedTasks.push(updatedTask);
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
      }

      let _event = deepClone(task);
      _event.cost = +_event.cost;
      _event.count = +_event.count;
      _event.title = _event.name;
      _event.start = formatDateTime(
        addDays(`${this.selectedDate} ${_event.start_time}`, 1, yyyyMMddHHmm1),
        yyyyMMddHHmm1
      );
      _event.end = formatDateTime(
        addDays(
          `${this.selectedDate} ${adjustMinEnd(
            _event.start_time,
            _event.end_time,
            HHmm2,
            this.min
          )}`,
          1,
          yyyyMMddHHmm1
        ), // adjustMinEnd HHmm2
        yyyyMMddHHmm1
      );
      _event.end_real = formatDateTime(
        addDays(`${this.selectedDate} ${_event.end_time}`, 1, yyyyMMddHHmm1),
        yyyyMMddHHmm1
      );
      _event.start_time = formatDateTime(_event.start, HHmm3);
      _event.end_time = formatDateTime(_event.end_real, HHmm3);
      _event.isCompleted = true;
      _event.isPlan = false;
      _event.isBreak = false;
      _event.is_completed = 1;
      _event.is_need_output = !!_event.is_need_output == true ? 1 : 0;
      //TODO UPDATE TASK SETTING
      let setting = this.setTaskConfiguration(_event);

      _event.color = setting.color;
      _event.timeText = setting.timeText;
      _event.markText = setting.markText;
      _event.className = !this.isCompleteTask(_event)
        ? this.isDbTask(_event)
          ? this.isDbTaskByType(_event)
            ? "actual-unPlan-task"
            : ""
          : "actual-unPlan-task"
        : "";
      _event.category_secondary_id = task.category_secondary_id;
      _event.category_primary_id = task.category_primary_id;
      let originalTask = this.calendarOptions.events.find(
        (x) => +x.id === +task.id
      );
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== task.id || x.isPlan || x.isBreak
      );
      if (
        this.showTodoList &&
        (this.isSplitTask(task) ||
          (originalTask && this.isSplitTask(originalTask)))
      ) {
        this.$root.$refs.actualTodoTabList?.getSplitData();
        this.$refs.todoTaskList && this.$refs.todoTaskList.getSplitData();
      }

      this.calendarOptions.events.push(_event);

      // 分割タスクの完了を変更された場合、
      if (_event.split_task_source_id && originalTask.is_split_task_completed !== _event.is_split_task_completed){
          this.calendarOptions.events.forEach((item) => {
            if (item.split_task_source_id === _event.split_task_source_id && item.split_task_no > _event.split_task_no){
              // 元のタスクが完了かどうか設定
              item.is_source_completed = _event.is_split_task_completed;
              if (+item.is_source_completed === 1) {
                item.isCompleted = true;
                item.className = "";
              }
              else{
                item.isCompleted = false;
                item.className = "plan-unComplete-task";
              }
            }
          });
      }

      if (originalTask && +originalTask.is_split_task === 1 && +_event.is_split_task === 0){
        originalTask.is_split_task_completed = 1;
          this.calendarOptions.events.forEach((item) => {
            if (item.split_task_source_id === originalTask.split_task_source_id && item.split_task_no > originalTask.split_task_no){
              // 元のタスクが完了かどうか設定
              item.is_source_completed = 1;
              item.isCompleted = true;
              item.className = "";
            }
          });
      }

      // 同親のタスクも更新
      if (task.split_task_source_id && task.actual_free_task_id){
        this.calendarOptions.events.map((x) => {
          if (+x.split_task_source_id === +task.split_task_source_id && +x.id !== +task.id){
            x.name = task.name;
            x.title = task.name;
          }
        });
      }

      this.callApiFC502(task);
    },
    /**
     * update break time
     * @param {object} [breakTime]
     */
    updateBreakTime(breakTime) {
      let t = this.calendarOptions.events.find((x) => x.isBreak && !x.isPlan);
      t.start = formatDateTime(
        addDays(
          `${this.selectedDate} ${breakTime.start_break_time}`,
          1,
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmm1
      );
      t.end = formatDateTime(
        addDays(
          `${this.selectedDate} ${adjustMinEnd(
            breakTime.start_break_time,
            breakTime.end_break_time,
            HHmmss1,
            this.min
          )}`, // adjustMinEnd HHmmss1
          1,
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmm1
      );
      t.end_real = formatDateTime(
        addDays(
          `${this.selectedDate} ${breakTime.end_break_time}`,
          1,
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmm1
      );
      t.timeText = this.getWorkTime({
        count: 1,
        cost: this.diffTime(
          breakTime.start_break_time,
          breakTime.end_break_time
        ),
        unit: "break",
      });
      t.count = 1;
      t.cost = this.diffTime(
        breakTime.start_break_time,
        breakTime.end_break_time
      );
      t.start_time = formatDateTime(t.start, HHmm3);
      t.end_time = formatDateTime(t.end_real, HHmm3);
      this.actualStartBreakTime = formatDateTime(t.start, HHmm2);
      this.actualEndBreakTime = formatDateTime(t.end_real, HHmm2);
    },
    /**
     * delete task success
     * @param {object} [task]
     */
    async deleteTaskSuccess(task) {
      // this.changeTimeFormat(task);
      let event = this.calendarOptions.events.find((x) => x.id === task.id);
      if (this.isSplitTask(event)) {
        this.showTodoList && this.$refs.todoTaskList.getSplitData();
      }
      let _t = deepClone(task);
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (x) => x.id !== _t.id || x.isPlan || x.isBreak
      );

      //TODO RESET SETTING FO RELATED PLAN TASK WHERE DELETE ACTUAL TASK
      if (this.isRelatedTask(_t)) {
        let planTask = this.calendarOptions.events.find(
          (x) => x.id === _t.schedule_task_id && x.isPlan
        );
        this.resetPlanTaskSetting(planTask);
      }

      let originalTask = this.calendarOptions.events.find(
        (x) => +x.id === +task.schedule_task_id
      );

      if (originalTask){
        let childTask = this.calendarOptions.events.filter(
          (x) => !!x.is_split_task
                  && x.split_task_source_id === originalTask.split_task_source_id 
                  && x.isPlan
                  && +x.split_task_no > +originalTask.split_task_no
        );
        if (childTask && childTask.length > 0){
          childTask.map(
            x => {
              x.isCompleted = false;
              x.is_source_completed = 0;
              x.className = "plan-unComplete-task";
            }
          );
        }
      }
      

      await this.deleteTodoTask();
    },
    /**
     * update comment
     * @param {object} [task]
     */
    // changeTimeFormat(task) {
    //   let hasColon = task.start_time.indexOf(":") >= 0;
    //   if (!hasColon) {
    //     task.start_time = toHHmm(toMinute(task.start_time, ""));
    //     task.end_time = toHHmm(toMinute(task.end_time, ""));
    //   }
    // },
    deleteComment(task) {
      let planTask = this.calendarOptions.events.find(
        (x) => x.id == task.schedule_task_id && x.isPlan
      );
      if (planTask) {
        planTask.comments = planTask.comments.filter(
          (x) => x.id !== task.comment_id
        );
      }
    },
    /**
     * update plan comment
     * @param {object} [task]
     */
    deletePlanComment(task) {
      let planTask = this.calendarOptions.events.find(
        (x) => x.id == task.id && x.isPlan
      );
      if (planTask) {
        planTask.comments = planTask.comments.filter(
          (x) => x.id !== task.comment_id
        );
      }
    },
    /**
     * is related task
     * @param {object} [task]
     */
    //TODO SETTING TASK CONFIGURATION
    isRelatedTask(task) {
      return (
        !isEmptyNumber(task.schedule_task_id) &&
        this.calendarOptions.events.some(
          (x) => x.id === task.schedule_task_id && x.isPlan && !x.isBreak
        )
      );
    },
    /**
     * is free task
     * @param {object} [task]
     */
    isFreeTask(task) {
      return !isEmptyNumber(task.actual_free_task_id) || !isEmptyNumber(task.free_task_id);
    },
    /**
     * is plan free task
     * @param {object} [task]
     */
    isPlanFreeTask(task) {
      return !isEmptyNumber(task.free_task_id) || !isEmptyNumber(task.actual_free_task_id);
    },
    /**
     * is DB task
     * @param {object} [task]
     */
    isDbTask(task) {
      return (
        !isEmptyNumber(task.standard_task_id) &&
        !isEmptyNumber(task.standard_task_revision)
      );
    },

    /**
     * Check if item is DB task by type
     * @param {object} task
     * @return {boolean}
     */
    isDbTaskByType(task) {
      return (
        !task.task_type_id ||
        SystemConstant.DB_TASK_TYPE_LIST.includes(task.task_type_id)
      );
    },

    /**
     * Check if item is Beginner level DB task
     * @param {object} item
     * @return {boolean}
     */
    isBeginnerSkill(item) {
      return item.skill_level_id ? item.skill_level_id === 10 : false;
    },

    /**
     * Check if item is Expert level DB task
     * @param {object} item
     * @return {boolean}
     */
    isExpertSkill(item) {
      return item.skill_level_id ? item.skill_level_id == 30 : false;
    },

    /**
     * is default color
     * @param {string} [color]
     */
    isDefaultColor(color) {
      // return color !== COLOR_PINK && color !== COLOR_RED;
      return (
        color !== COLOR_FREE_TASK_WITHIN_1_2 &&
        color !== COLOR_FREE_TASK_GREATER_1_2 &&
        color !== COLOR_DB_TASK_WITHIN_1_2 &&
        color !== COLOR_DB_TASK_GREATER_1_2
      );
    },

    /**
     * is default color
     * @param {string} [color]
     */
    isBlackColor(color) {
      // return color !== COLOR_PINK && color !== COLOR_RED;
      return (
        color !== COLOR_FREE_TASK_WITHIN_1_2 &&
        color !== COLOR_FREE_TASK_GREATER_1_2 &&
        color !== COLOR_DB_TASK_WITHIN_1_2 &&
        color !== COLOR_DB_TASK_GREATER_1_2 &&
        color !== COLOR_GRAY
      );
    },
    /**
     * is plan task
     * @param {object} [task]
     */
    isPlanTask(task) {
      return !!task.isPlan;
    },
    /**
     * is remand
     * @param {object} [task]
     */
    isRemand(task) {
      return !!task.is_remand;
    },
    /**
     * is mark
     * @param {object} [task]
     */
    isMark(task) {
      return this.isCompleteTask(task); //!!task.is_completed === true && !this.isRemand(task);
    },
    /**
     * is complete task
     * @param {object} [task]
     */
    isCompleteTask(task) {
      if (task.isPlan) {
        return !!task.is_completed;
      } else {
        let planTask = this.calendarOptions.events.find(
          (x) => x.isPlan && x.id === +task.schedule_task_id
        );
        return planTask ? !!planTask.is_completed : false;
      }
    },
    /**
     * is color change with same cost
     * @param {object} [planTask]
     * @param {object} [actualTask]
     */
    isColorChangeWithSameCost(planTask, actualTask) {
      return (
        planTask.cost === actualTask.cost &&
        actualTask.count < planTask.count &&
        this.isCompleteTask(actualTask)
      );
    },
    /**
     * is color change with less than cost
     * @param {object} [planTask]
     * @param {object} [actualTask]
     */
    isColorChangeWithLessThanCost(planTask, actualTask) {
      return (
        actualTask.cost < planTask.cost &&
        actualTask.count < planTask.count &&
        this.isCompleteTask(actualTask)
      );
    },
    /**
     * is color change with greater than cost
     * @param {object} [planTask]
     * @param {object} [actualTask]
     */
    isColorChangeWithGreaterThanCost1_2(planTask, actualTask) {
      if (
        (planTask.is_split_task === true || planTask.is_split_task === 1) &&
        (actualTask.is_split_task === false || actualTask.is_split_task === 0)
      ) {
        return (
          actualTask.cost > planTask.total_cost &&
          actualTask.cost / planTask.total_cost <= 1.2 &&
          this.isCompleteTask(actualTask)
        );
      } else {
        return (
          actualTask.cost > planTask.cost &&
          actualTask.cost / planTask.cost <= 1.2 &&
          this.isCompleteTask(actualTask)
        );
      }
    },
    /**
     * is color change with greater than cost over
     * @param {object} [planTask]
     * @param {object} [actualTask]
     */
    isColorChangeWithGreaterThanCostOver1_2(planTask, actualTask) {
      if (
        (planTask.is_split_task === true || planTask.is_split_task === 1) &&
        (actualTask.is_split_task === false || actualTask.is_split_task === 0)
      ) {
        return (
          actualTask.cost > planTask.total_cost &&
          actualTask.cost / planTask.total_cost > 1.2 &&
          this.isCompleteTask(actualTask)
        );
      } else {
        return (
          actualTask.cost > planTask.cost &&
          actualTask.cost / planTask.cost > 1.2 &&
          this.isCompleteTask(actualTask)
        );
      }
    },
    /**
     * get work time
     * @param {object} [task]
     */
    getWorkTime(task) {
      if (task.unit === "break") return `${task.cost}m`;
      else if (!task.unit) return `${task.cost * task.count}m (${task.cost}m/回×${task.count})`;
      else {
        return `${task.cost * task.count}m (${task.cost}m/${task.unit}×${task.count})`;
      }
    },
    /**
     * get mark ouput
     * @param {object} [task]
     * @param {boolean} [spotTask]
     */
    getMarkOutput(task, spotTask = false) {
      if (!!task.is_need_output == true)
        return this.isCompleteTask(task) && !spotTask
          ? MARK_OUPUT_COMPLETE
          : MARK_OUPUT_NOT_COMPLETE;
      else return MARK_OUPUT_DEFAULT;
    },
    /**
     * get task time text
     * @param {object} [planTask]
     * @param {object} [actualTask]
     */
    getTaskTimeText(planTask, actualTask) {
      switch (true) {
        case !isEmpty(planTask) && isEmpty(actualTask):
          return !planTask.unit
            ? `${planTask.cost * planTask.count}m (${planTask.cost}m/回×${
                planTask.count
              })`
            : `${planTask.cost * planTask.count}m (${planTask.cost}m/${planTask.unit}×${
                planTask.count
              })`;
        case isEmpty(planTask) && !isEmpty(actualTask):
          return !actualTask.unit ? `${actualTask.cost * actualTask.count}m (${actualTask.cost}m/回×${
                actualTask.count
              })`
            : `${actualTask.cost * actualTask.count}m (${actualTask.cost}m/${actualTask.unit}×${
                actualTask.count
              })`;
        case !isEmpty(planTask) && !isEmpty(actualTask):
          if (
            JSON.stringify({ count: planTask.count, cost: planTask.cost }) !==
            JSON.stringify({ count: actualTask.count, cost: actualTask.cost })
          ) {
            let planText =
              !planTask.unit
                ? `${planTask.cost * planTask.count}m (${planTask.cost}m/回×${
                planTask.count
                })`
                : `${planTask.cost * planTask.count}m (${planTask.cost}m/${planTask.unit}×${
                    planTask.count
                  })`;

            let actualText =
              !actualTask.unit
                ? `${actualTask.cost * actualTask.count}m (${actualTask.cost}m/回×${
                actualTask.count
                })`
                : `${actualTask.cost * actualTask.count}m (${actualTask.cost}m/${actualTask.unit}×${
                  actualTask.count
                })`;
            return `${planText}➝${actualText}`;
          } else {
            return !actualTask.unit
              ? `${actualTask.cost * actualTask.count}m (${actualTask.cost}m/回×${
                actualTask.count
              })`
              : `${actualTask.cost * actualTask.count}m (${actualTask.cost}m/${actualTask.unit}×${
                actualTask.count
              })`;
          }
        default:
          return "";
      }
    },
    /**
     * is split task
     * @param {object} [task]
     */
    isSplitTask(task) {
      return (
        !isEmptyNumber(task.split_task_id) &&
        (task.is_split_task === 1 || task.is_split_task === true)
      );
    },
    /**
     * get task color
     * @param {object} [task]
     * @param {boolean} [spotTask]
     */
    getTaskColor(task, spotTask = false) {
      switch (true) {
        // case !this.isPlanTask(task) && this.isRemand(task) && !skipRemand:
        //   return COLOR_REMAND;
        case !this.isPlanTask(task) &&
          this.isDbTask(task) &&
          this.isDbTaskByType(task):
          return this.isCompleteTask(task) && !spotTask
            ? COLOR_DB_TASK_COMPLETE
            : COLOR_DB_TASK_NOT_COMPLETE;
        case !this.isPlanTask(task) &&
          this.isDbTask(task) &&
          !this.isDbTaskByType(task):
          return COLOR_GRAY;
        case !this.isPlanTask(task) && this.isFreeTask(task):
          return this.isCompleteTask(task)
            ? COLOR_FREE_TASK_COMPLETE
            : COLOR_FREE_TASK_NOT_COMPLETE;
        case this.isPlanTask(task) &&
          this.isDbTask(task) &&
          this.isDbTaskByType(task):
          return this.isCompleteTask(task)
            ? COLOR_DB_TASK_COMPLETE
            : COLOR_DB_TASK_NOT_COMPLETE;
        case this.isPlanTask(task) &&
          this.isDbTask(task) &&
          !this.isDbTaskByType(task):
          return COLOR_GRAY;
        case this.isPlanTask(task) && this.isPlanFreeTask(task):
          return this.isCompleteTask(task)
            ? COLOR_FREE_TASK_COMPLETE
            : COLOR_FREE_TASK_NOT_COMPLETE;
        default:
          return COLOR_DEFAULT;
      }
    },
    getTaskLength(title, detail) {
      return isEmpty(detail) ? title.length : title.length + detail.length + 1;
    },
    /**
     * set task configuration
     * @param {object} [actualTask]
     */
    setTaskConfiguration(actualTask) {
      if (this.isRelatedTask(actualTask)) {
        let planTask = this.calendarOptions.events.find(
          (x) => x.id === actualTask.schedule_task_id && x.isPlan && !x.isBreak
        );
        let newColor = "",
          newMarkText = false,
          newClassName = "",
          newTimeText = "";
        if (this.isDbTask(actualTask) && !this.isDbTaskByType(actualTask)) {
          newColor = this.getTaskColor(actualTask);
          newTimeText = this.getTaskTimeText(planTask, actualTask);
          planTask.color = this.getTaskColor(planTask);
        } else if (
          this.isFreeTask(actualTask) ||
          (this.isDbTask(actualTask) && this.isDbTaskByType(actualTask))
        ) {
          switch (true) {
            case this.isColorChangeWithSameCost(planTask, actualTask):
              // newColor = COLOR_PINK;
              switch (true) {
                case this.isDbTask(actualTask):
                  newColor = COLOR_DB_TASK_WITHIN_1_2;
                  break;
                case this.isFreeTask(actualTask):
                  newColor = COLOR_FREE_TASK_WITHIN_1_2;
                  break;
              }
              newMarkText = false;
              newClassName = this.getMarkOutput(actualTask);
              newTimeText = this.getTaskTimeText(planTask, actualTask);
              planTask.color = newColor;
              planTask.markText = newMarkText;
              planTask.className = "";
              break;

            case this.isColorChangeWithLessThanCost(planTask, actualTask):
              // newColor = COLOR_PINK;
              switch (true) {
                case this.isDbTask(actualTask):
                  newColor = COLOR_DB_TASK_WITHIN_1_2;
                  break;
                case this.isFreeTask(actualTask):
                  newColor = COLOR_FREE_TASK_WITHIN_1_2;
                  break;
              }
              newMarkText = false;
              newClassName = this.getMarkOutput(actualTask);
              newTimeText = this.getTaskTimeText(planTask, actualTask);
              planTask.color = newColor;
              planTask.markText = newMarkText;
              planTask.className = "";
              break;

            case this.isColorChangeWithGreaterThanCost1_2(planTask, actualTask):
              {
                let compareColor = "";
                switch (true) {
                  case actualTask.count >= planTask.count:
                    switch (true) {
                      case this.isDbTask(actualTask):
                        compareColor = COLOR_DB_TASK_WITHIN_1_2;
                        break;
                      case this.isFreeTask(actualTask):
                        compareColor = COLOR_FREE_TASK_WITHIN_1_2;
                        break;
                    }
                    break;
                  case actualTask.count < planTask.count:
                    switch (true) {
                      case this.isDbTask(actualTask):
                        compareColor = COLOR_DB_TASK_GREATER_1_2;
                        break;
                      case this.isFreeTask(actualTask):
                        compareColor = COLOR_FREE_TASK_GREATER_1_2;
                        break;
                    }
                    break;
                  default:
                    compareColor = COLOR_DEFAULT;
                    break;
                }

                newColor = compareColor;
                newMarkText = false;
                newClassName = this.getMarkOutput(actualTask);
                newTimeText = this.getTaskTimeText(planTask, actualTask);
                planTask.color = newColor;
                planTask.markText = newMarkText;
                // planTask.className =
                //   this.isDbTask(planTask) &&
                //   this.isDbTaskByType(planTask) &&
                //   !this.isSplitTask(planTask) &&
                //   this.isDefaultColor(planTask.color)
                //     ? "db-task"
                //     : "";
                planTask.className = "";
              }
              break;

            case this.isColorChangeWithGreaterThanCostOver1_2(
              planTask,
              actualTask
            ):
              // newColor = COLOR_RED;
              switch (true) {
                case this.isDbTask(actualTask) &&
                  this.isDbTaskByType(actualTask):
                  newColor = COLOR_DB_TASK_GREATER_1_2;
                  break;
                case this.isFreeTask(actualTask):
                  newColor = COLOR_FREE_TASK_GREATER_1_2;
                  break;
              }
              newMarkText = false;
              newClassName = this.getMarkOutput(actualTask);
              newTimeText = this.getTaskTimeText(planTask, actualTask);
              planTask.color = newColor;
              planTask.markText = newMarkText;
              planTask.className = "";

              break;

            default:
              newColor = this.getTaskColor(actualTask);
              newMarkText = this.isDbTask(actualTask)
                ? this.isSplitTask(actualTask)
                  ? this.isMark(actualTask)
                  : false
                : this.isMark(actualTask);
              if (!this.isDbTaskByType(actualTask)) {
                newMarkText = false;
              }
              newClassName = "";
              newTimeText = this.getTaskTimeText(planTask, actualTask);
              planTask.color = this.getTaskColor(planTask);
              planTask.markText = this.isDbTask(planTask)
                ? this.isSplitTask(planTask)
                  ? this.isMark(planTask)
                  : false
                : this.isMark(planTask);
              if (!this.isDbTaskByType(planTask)) {
                newMarkText = false;
              }
              planTask.className = "";
              break;
          }
        }
        return {
          color: newColor,
          markText: newMarkText,
          className: newClassName,
          timeText: newTimeText,
        };
      } else {
        const color = this.getTaskColor(actualTask, true);
        return {
          color: color,
          markText: false,
          className: "",
          timeText: this.getTaskTimeText(null, actualTask),
        };
      }
    },
    /**
     * reset plan task setting
     * @param {object} [planTask]
     */
    resetPlanTaskSetting(planTask) {
      planTask.isCompleted = false;
      planTask.is_completed = 0;
      if (isEmpty(planTask)) return;
      planTask.color = this.getTaskColor(planTask, true);
      planTask.markText = false;
      planTask.className = this.isDbTask(planTask)
        ? this.isDbTaskByType(planTask)
          ? "plan-unComplete-task"
          : ""
        : "plan-unComplete-task";
    },

    //TODO END
    /**
     * toHHmm
     * @param {Time} [time]
     */
    toHHmm(time) {
      let minute = time % 60 < 10 ? `0${time % 60}` : time % 60;
      let hours = `${Math.trunc(time / 60)}h${minute}m`;
      return hours;
    },
    /**
     * different time
     * @param {Time} [stime]
     * @param {Time} [etime]
     */
    diffTime(stime, etime) {
      let shour = stime.substr(0, 2);
      let sminute = stime.substr(2, 2);
      let ehour = etime.substr(0, 2);
      let eminute = etime.substr(2, 2);
      return ehour * 60 + parseInt(eminute) - (shour * 60 + parseInt(sminute));
    },
    /**
     * get total time
     * @param {Date} [dateInfo]
     */
    getTotalTime(dateInfo) {
      if (formatDateTime(dateInfo.start, yyyyMMdd2) === this.selectedDate) {
        let atotalTime = this.calendarOptions.events
          .filter((x) => !x.isBreak && !x.isPlan)
          .reduce(function (total, e) {
            return total + e.cost * e.count;
          }, 0);
        let ptotalTime = this.calendarOptions.events
          .filter((x) => !x.isBreak && x.isPlan)
          .reduce(function (total, e) {
            return total + e.cost * e.count;
          }, 0);
        const addCustomeDays = (date, days) => {
          var result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        };

        const removeAllChildNodes = (parent) => {
          while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
          }
        };
        let plan = document.querySelectorAll(
          `.fc-col-header-cell[data-date='${formatDateTime(
            this.selectedDate,
            yyyyMMdd2
          )}'] div`
        )[0];
        let actual = document.querySelectorAll(
          `.fc-col-header-cell[data-date='${formatDateTime(
            addCustomeDays(this.selectedDate, 1),
            yyyyMMdd2
          )}'] div`
        )[0];
        let atime = this.toHHmm(atotalTime);
        let ptime = this.toHHmm(ptotalTime);
        let atext = actual.getElementsByClassName(
          "fc-col-header-cell-cushion"
        )[0];
        let ptext = plan.getElementsByClassName(
          "fc-col-header-cell-cushion"
        )[0];
        let anode = document.createElement(`span`);
        anode.setAttribute("id", "actual-time");
        let atextNode = document.createTextNode(`${atime}`);
        anode.appendChild(atextNode);

        let pnode = document.createElement(`span`);
        pnode.setAttribute("id", "plan-time");
        let ptextNode = document.createTextNode(`${ptime}`);
        pnode.appendChild(ptextNode);
        removeAllChildNodes(plan);
        plan.appendChild(ptext);
        plan.appendChild(pnode);
        removeAllChildNodes(actual);
        actual.appendChild(atext);
        actual.appendChild(anode);
      }
    },
    /**
     * get print total time
     */
    getPrintTotalTime() {
      let events = this.calendarOptions.events.filter((x) => !x.isBreak);
      let atotalTime = 0;
      let ptotalTime = 0;
      const getFormat = (dateTimeString) => {
        switch (dateTimeString.length) {
          case 19:
            return yyyyMMddHHmmss2;
          case 16:
            return yyyyMMddHHmm1;
          default:
            throw `format errors ${dateTimeString}`;
        }
      };
      if (events && events.length !== 0) {
        let event0 = events[0].isPlan
          ? events[0].start
          : addDays(events[0].start, -1, getFormat(events[0].start));
        if (formatDateTime(event0, yyyyMMdd2) === this.selectedDate) {
          atotalTime = events
            .filter((x) => !x.isPlan)
            .reduce(function (total, e) {
              return total + e.cost * e.count;
            }, 0);
          ptotalTime = events
            .filter((x) => x.isPlan)
            .reduce(function (total, e) {
              return total + e.cost * e.count;
            }, 0);
        }
      }
      const addCustomeDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      };
      const removeAllChildNodes = (parent) => {
        while (parent.firstChild) {
          parent.removeChild(parent.firstChild);
        }
      };
      let plan = document.querySelectorAll(
        `.printCalendar .fc-col-header-cell[data-date='${formatDateTime(
          this.selectedDate,
          yyyyMMdd2
        )}'] div`
      )[0];
      if (plan) {
        let ptime = this.toHHmm(ptotalTime);
        let ptext = plan.getElementsByClassName(
          "fc-col-header-cell-cushion"
        )[0];
        let pnode = document.createElement(`span`);
        pnode.setAttribute("id", "plan-time");
        let ptextNode = document.createTextNode(`${ptime}`);
        pnode.appendChild(ptextNode);
        removeAllChildNodes(plan);
        plan.appendChild(ptext);
        plan.appendChild(pnode);
      }
      let actual = document.querySelectorAll(
        `.printCalendar .fc-col-header-cell[data-date='${formatDateTime(
          addCustomeDays(this.selectedDate, 1),
          yyyyMMdd2
        )}'] div`
      )[0];
      if (actual) {
        let atime = this.toHHmm(atotalTime);
        let atext = actual.getElementsByClassName(
          "fc-col-header-cell-cushion"
        )[0];
        let anode = document.createElement(`span`);
        anode.setAttribute("id", "actual-time");
        let atextNode = document.createTextNode(`${atime}`);
        anode.appendChild(atextNode);

        removeAllChildNodes(actual);
        actual.appendChild(atext);
        actual.appendChild(anode);
      }
    },
    /**
     * set editable calendar
     */
    setEditableCalendar() {
      this.calendarOptions.editable =
        this.isSelf() && this.isNoSubmmit() && this.canSubmit;
    },
    /**
     * get submit button
     * @param {object} [item]
     */
    getSubmitButton(item) {
      let button = {
        text: "",
        active: false,
        isReject: false,
      };
      if (this.isSelf()) {
        switch (item.phase_id) {
          case SystemConstant.PHASE_ID_UNSUBMITTED /* User can submit */:
          case SystemConstant.PHASE_ID_SUBMISSION_RESET /* User can resubmit */:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT /* User can resubmit */:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            button.text = this.$t("label.buttons.submission");
            button.active = !this.canSubmit;
            button.isReject = false;
            this.showDraftText = true;
            break;
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING /* User can cancel submit before 1st approver's confirmation*/:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET /* User can cancel submit before 1st approver's confirmation*/:
            button.text = this.$t("label.buttons.submission_reset");
            button.active = !this.canSubmitCancel;
            button.isReject = true;
            this.showDraftText = false;
            break;
          case SystemConstant.PHASE_ID_VERIFIED /* User cannot cancel submit */:
            if (!this.hasCompletedChange){
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canSubmit;
              button.isReject = false;
            }
            else{
              button.text = this.$t("label.buttons.submission");
              button.active = false;
              button.isReject = false;
            }
            this.showDraftText = true;
            break;
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING /* User cannot cancel submit */:
            button.text = this.$t("label.buttons.submission_reset");
            button.active = !this.canSubmitCancel;
            button.isReject = true;
            this.showDraftText = false;
            break;
          default:
            break;
        }
      } else {
        if (this.isNoApprove()) {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED /* User can submit */:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET /* User can resubmit */:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT /* User can resubmit */:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING /* User can cancel submit before 1st approver's confirmation*/:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET /* User can cancel submit before 1st approver's confirmation*/:
              button.text = this.$t("label.buttons.submission_reset");
              button.active = false;
              button.isReject = true;
              this.showDraftText = false;
              break;
            case SystemConstant.PHASE_ID_VERIFIED /* User cannot cancel submit */:
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING /* User cannot cancel submit */:
              button.text = this.$t("label.buttons.submission_reset");
              button.active = true;
              button.isReject = true;
              this.showDraftText = false;
              break;
            default:
              break;
          }
          button.active = true;
        } else if (this.isFirstApprove()) {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED:
              button.text = this.$t("label.buttons.confirm");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
              {
                let returnedTask = this.calendarOptions.events.filter(
                  (x) => x.check && !x.isPlan
                );
                if (!isEmpty(returnedTask)) {
                  button = {
                    text: `${returnedTask.length}件`,
                    active: !this.canApproveRemand,
                    isReject: true,
                  };
                  this.showDraftText = false;
                } else {
                  button = {
                    text: this.$t("label.buttons.confirm"),
                    active: !this.canApproveRemand,
                    isReject: false,
                  };
                  this.showDraftText = true;
                } // END-IF-ELSE
              }
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              button.text = this.$t("label.buttons.confirm");
              button.isReject = false;
              button.active = !this.canApproveRemand;
              this.showDraftText = true;
              break;
            default:
              break;
          }
        } else {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED:
              button.text = this.$t("label.buttons.confirm");
              button.active = true;
              button.isReject = false;
              this.showDraftText = true;
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              {
                let returnedTask = this.calendarOptions.events.filter(
                  (x) => x.check && !x.isPlan
                );
                if (!isEmpty(returnedTask)) {
                  button = {
                    text: `${returnedTask.length}件`,
                    // active: false,
                    active: !this.canApproveRemand,
                    isReject: true,
                  };
                  this.showDraftText = false;
                } else {
                  button = {
                    text: this.$t("label.buttons.confirm"),
                    // active: false,
                    active: !this.canApproveRemand,
                    isReject: false,
                  };
                  this.showDraftText = true;
                } // END-IF-ELSE
              }
              break;
            default:
              break;
          }
        }
      }

      return button;
    },
    /**
     * active checkBox
     */
    activeCheckBox() {
      if (this.isFirstApprove()) {
        switch (this.phaseId) {
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
            return true;
          default:
            return false;
        }
      } else {
        return (
          this.isSecondApprove() &&
          this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
        );
      }
    },
    /**
     * get status text
     * @param {object} [item]
     */
    getStatusText(item) {
      this.phaseId = item.phase_id;
      this.setEditableCalendar();
      this.calendarOptions.events = this.calendarOptions.events.map((e) => {
        let event = { ...e, ...{ disabled: !this.activeCheckBox() } };
        return event;
      });

      switch (item.phase_id) {
        case SystemConstant.PHASE_ID_UNSUBMITTED:
          return SystemConstant.PHASE_ID_UNSUBMITTED_TEXT;
        case SystemConstant.PHASE_ID_SUBMISSION_RESET:
          return SystemConstant.PHASE_ID_UNSUBMITTED_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          return SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
          return SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          return SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT;
        case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
          return SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_2_NEXT_REJECT:
          return SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT;
        case SystemConstant.PHASE_ID_VERIFIED:
          return SystemConstant.PHASE_ID_VERIFIED_TEXT;
        default:
          break;
      }
    },
    /**
     * submit actual
     * @param {object} [commentItem]
     */
    async submitActual(commentItem) {
      try {
        let params = {};
        if (!isEmpty(this.missingTasks) && this.missingTasks.length > 0) {
          params = {
            comment: commentItem.comment,
            sub_comment: commentItem.sub_comment,
            phase_type: SystemConstant.PHASE_TYPE_SUBMISSION,
          };
        } else {
          params = {
            comment: commentItem.comment,
            phase_type: SystemConstant.PHASE_TYPE_SUBMISSION,
          };
        }
        const { data } = await axios.put(ACTUAL_API(this.actualId), params);
        this.statuses = data.result.statuses;
        this.canSubmit = !!data.result.can_submit;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isInDraft = !!data.result.is_in_draft;

        if (!isEmpty(this.missingTasks) && this.missingTasks.length > 0) {
          if (!isEmpty(commentItem.unCompleted_ids)) {
            let listTask = commentItem.unCompleted_ids.map((x) => {
              return {
                id: x.id,
                is_checked: +x.is_checked
              };
            });
            await axios.post(
              INCOMPLETE_TASK_API(),
              {
                schedule_tasks: listTask,
              }
            );

            if (this.showTodoList) {
              this.$refs.todoTaskList.loadTodoListData();
            }

            this.missingTasks.forEach((task) => {
                if (listTask.filter((x) => +x.id === +task.id).length > 0){
                  task.check = listTask.find((x) => +x.id === +task.id).is_checked;
                }
              });
          }
        }

        // let events = this.calendarOptions.events.filter(
        //   (x) => !!x.is_remand && !x.isPlan && !x.isBreak
        // );

        // for (let index = 0; index < events.length; index++) {
        //   const event = events[index];
        //   event.is_remand = 0;
        //   let settting = this.setTaskConfiguration(event);
        //   event.color = settting.color;
        //   event.markText = settting.markText;
        //   event.className =
        //     this.isDefaultColor(event.color) &&
        //     this.isDbTask(event) &&
        //     !this.isSplitTask(event)
        //       ? "db-task"
        //       : "";
        //   event.timeText = settting.timeText;
        // }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC819, status);
      }
    },
    /**
     * confirm
     * @param {string} [comment]
     */
    async confirm(comment) {
      try {
        const { data } = await axios.put(ACTUAL_API(this.actualId), {
          comment: comment,
          phase_type: SystemConstant.PHASE_TYPE_APPROVAL,
        });
        this.statuses = data.result.statuses;
        this.canSubmit = !!data.result.can_submit;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isRejectAll = false;
        this.isInDraft = !!data.result.is_in_draft;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC819, status);
      }
    },

    /**
     * Save draft
     * @param {string} [commentItem]
     * @return {void}
     */
    async saveDraftActual(commentItem) {
      try {
        let params = {};
        let subComment = null;
        if (!isEmpty(this.missingTasks) && this.missingTasks.length > 0 && this.isSelf()) {
          params = {
            comment: commentItem.comment,
            sub_comment: commentItem.sub_comment,
          };
          subComment = commentItem.sub_comment;
        } else {
          params = {
            comment: commentItem.comment
          };
        }
        const { data } = await axios.post(ACTUAL_DRAFT_API(this.actualId), params);
        this.$toast(this.$t("label.toast.save_draft_success"), 3000);
        this.statuses = data.result.statuses;
        this.statuses[this.statuses.length - 1].sub_comment = subComment;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isInDraft = !!data.result.is_in_draft;
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });

        // チェックされた未実施タスクでアーカイブする
        if (!isEmpty(this.missingTasks) && this.missingTasks.length > 0 && this.isSelf()) {
          if (!isEmpty(commentItem.unCompleted_ids)) {
            let listTask = commentItem.unCompleted_ids.map((x) => {
              return {
                id: x.id,
                is_checked: +x.is_checked
              };
            });
            await axios.post(
              INCOMPLETE_TASK_API(),
              {
                schedule_tasks: listTask,
              }
            );

            if (this.showTodoList) {
              this.$refs.todoTaskList.loadTodoListData();
            }

            this.missingTasks.forEach((task) => {
                if (listTask.filter((x) => +x.id === +task.id).length > 0){
                  task.check = listTask.find((x) => +x.id === +task.id).is_checked;
                }
              });
            }
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC828, status);
      } // END-TRY-CATCH
    },
    /**
     * reject
     * @param {string} [comment]
     */
    async reject(comment) {
      try {
        if (!this.calendarOptions.events.some((x) => x.check)) {
          if (this.canApproveRemand) {
            this.submitButton = {
              text: `確認`,
              active: !this.canApproveRemand,
              isReject: false,
            };
          }
        } // END-IF

        const { data } = await axios.put(ACTUAL_API(this.actualId), {
          comment: comment,
          phase_type: SystemConstant.PHASE_TYPE_REJECT,
        });
        let events = this.calendarOptions.events.filter(
          (x) => !!x.is_remand === false && !x.isPlan && !x.isBreak
        );

        for (let index = 0; index < events.length; index++) {
          const event = events[index];
          event.is_remand_previous = 0;
        }
        this.statuses = data.result.statuses;
        this.canSubmit = !!data.result.can_submit;
        this.canApproveRemand = !!data.result.can_approve_remand;
        this.canSubmitCancel = !!data.result.can_submit_cancel;
        this.statusText = this.getStatusText({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        this.isRejectAll = true;
        this.isInDraft = !!data.result.is_in_draft;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC819, status);
      }
    },

    /**
     * reject
     * @param {string} [comment]
     */
     async changeCompleted(comment) {
      try {
        const { data } = await axios.put(ACTUAL_API(this.actualId), {
          comment: comment,
          phase_type: SystemConstant.PHASE_TYPE_UPDATE_AFTER_APPROVAL,
        });
        let events = this.actuaTasks.filter(
          (x) => x.is_completed_change
        );

        for (let index = 0; index < events.length; index++) {
          const event = events[index];
          event.is_completed_change = 0;
        }
        this.hasCompletedChange = !!data.result.has_completed_change;
        this.submitButton = this.getSubmitButton({
          phase_id: this.statuses[this.statuses.length - 1].phase_id,
        });
        await this.getSchedule();
        if (this.showTodoList) {
          this.$refs.todoTaskList.loadTodoListData();
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC819, status);
      }
    },

    /**
     * submit validation
     */
    submitValidation() {
      let errors = [];
      if (this.diffWorkTimeError) {
        errors.push(this.$t("label.error.missing_working_hours"));
      }

      if (this.blankTimeError.isBlank) {
        if (isEmpty(this.blankTimeError.blankData)) {
          errors.push(
            `${this.$t("label.error.blank_time")} ${formatTime(
              `${this.startWorkTime}`.padStart(6, "0")
            )} ~ ${formatTime(`${this.endWorkTime}`.padStart(6, "0"))}`
          );
        } else {
          errors.push(this.$t("label.error.blank_time"));
        }
        this.blankTimeError.blankData.forEach((x) => {
          errors.push(
            `${formatTime(`${x.start}`.padStart(6, "0"))} ~ ${formatTime(
              `${x.end}`.padStart(6, "0")
            )}`
          );
        });
      }

      if (this.overLapTimeError) {
        errors.push(this.$t("label.error.same_time_task_duplicated"));
      }
      return errors;
    },

    /**
     * submit calendar
     */
    submitCalendar: debounce_leading(function (isRejectAll) {
      this.showOldComments = false;
      if (this.isSelf()) {
        switch (this.phaseId) {
          case SystemConstant.PHASE_ID_UNSUBMITTED:
            if (this.canSubmit) {
                this.showDailyComment = !this.showDailyComment;
                this.inputSubComment = this.statuses[this.statuses.length - 1].sub_comment;
                this.oldComments = this.statuses.filter(
                  (x) =>
                    x.phase_id !== SystemConstant.PHASE_ID_UNSUBMITTED &&
                    x.phase_id !== SystemConstant.PHASE_ID_SUBMISSION_RESET
                );
                this.showOldComments = false;
                if (this.oldComments.length > 0) {
                  this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
                }
                let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                  (x) => x.comment_user_id === this.userId
                );
                if (lastDraftComments.length > 0){
                  this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
                }
              } 
            break;
          case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            if (this.canSubmit) {
                this.showDailyComment = !this.showDailyComment;
                this.inputSubComment = this.statuses[this.statuses.length - 1].sub_comment;
                this.oldComments = this.statuses.filter(
                  (x) =>
                    x.phase_id !== SystemConstant.PHASE_ID_UNSUBMITTED &&
                    x.phase_id !== SystemConstant.PHASE_ID_SUBMISSION_RESET
                );
                this.showOldComments = false;
                if (this.oldComments.length > 0) {
                  this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
                }
                let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                  (x) => x.comment_user_id === this.userId
                );
                if (lastDraftComments.length > 0){
                  this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
                }else{
                  let lastComments = this.statuses[this.statuses.length - 2].comment;
                  let lastSubComments = this.statuses[this.statuses.length - 2].sub_comment;
                  this.inputComment = lastComments;
                  this.inputSubComment = lastSubComments;
                }
              } 
            break;
          case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT: {
            if (this.canSubmit) {
              this.showDailyComment = !this.showDailyComment;
              this.inputSubComment = this.statuses[this.statuses.length - 1].sub_comment;
              this.oldComments = deepClone(this.statuses);
              this.showOldComments = this.oldComments.length > 0;
              if (this.oldComments.length > 0) {
                this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
              }
              let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                (x) => x.comment_user_id === this.userId
              );
              if (lastDraftComments.length > 0){
                this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
              }else{
                let lastSubComments = this.statuses[this.statuses.length - 2].sub_comment;
                this.inputSubComment = lastSubComments;
              }
            }
            return;
          }
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
            if (this.canSubmitCancel) {
              this.$confirm(this.$t("label.confirm.cancelsubmit"), {
                buttons: {
                  ok: {
                    text: this.$t("label.buttons.undo"),
                    type: "primary",
                  },
                },
                rtl: true,
              })
                .then(async () => {
                  try {
                    const { data } = await axios.put(
                      ACTUAL_API(this.actualId),
                      {
                        phase_type: SystemConstant.PHASE_ID_PRIMARY_REJECT,
                      }
                    );
                    this.statuses = data.result.statuses;
                    this.canSubmit = !!data.result.can_submit;
                    this.canApproveRemand = !!data.result.can_approve_remand;
                    this.canSubmitCancel = !!data.result.can_submit_cancel;
                    this.statusText = this.getStatusText({
                      phase_id: this.statuses[this.statuses.length - 1].phase_id,
                    });
                    this.submitButton = this.getSubmitButton({
                      phase_id: this.statuses[this.statuses.length - 1].phase_id,
                    });
                    this.activeTask =
                      this.statuses[this.statuses.length - 1].phase_id !==
                      SystemConstant.PHASE_ID_UNSUBMITTED;
                    let secondStatus = data.result.statuses[1];
                    this.inputComment = secondStatus.comment;
                    this.inputSubComment = secondStatus.sub_comment;
                    if (
                      !isEmpty(secondStatus.issue_tasks) &&
                      secondStatus.issue_tasks.length > 0
                    ) {
                      this.inputSubComment = this.statuses[1].sub_comment;

                      // get missing tasks
                      await this.getUncompletedPlans();
                    }
                  } catch (error) {
                    const { status } = error.response;
                    apiErrorMessages(this, FC819, status);
                  }
                })
                .catch(() => {
                  return;
                });
            }
            return;
          case SystemConstant.PHASE_ID_VERIFIED:
            this.openChangeCompletedTask = !this.openChangeCompletedTask;
            return;
        } // END-SWITCH
      } else {
        if (this.isFirstApprove()) {
          switch (this.phaseId) {
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
              if (this.canApproveRemand) {
                if (
                  (this.isRejectStatus() && isRejectAll) ||
                  (this.isRejectStatus() &&
                    this.calendarOptions.events.filter((x) => x.check).length >
                      0)
                ) {
                  this.showDailyReject = !this.showDailyReject;
                } else {
                  this.showDailyApproval = !this.showDailyApproval;
                  this.oldComments = deepClone(this.statuses);
                  this.showOldComments = this.oldComments.length > 0;
                  if (this.oldComments.length > 0) {
                    this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
                  }
                  let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                    (x) => x.comment_user_id === this.loginInfo.user_id
                  );
                  if (lastDraftComments.length > 0){
                    this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
                  }
                }
              }
              return;
          }
          return;
        } else {
          if (
            this.isSecondApprove() &&
            this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
          ) {
            if (this.canApproveRemand) {
              if (
                (this.isRejectStatus() && isRejectAll) ||
                (this.isRejectStatus() &&
                  this.calendarOptions.events.filter((x) => x.check).length > 0)
              ) {
                this.showDailyReject = !this.showDailyReject;
              } else {
                this.showDailyApproval = !this.showDailyApproval;
                this.oldComments = deepClone(this.statuses);
                this.showOldComments = this.oldComments.length > 0;
                if (this.oldComments.length > 0) {
                  this.oldComments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
                }
                let lastDraftComments = this.statuses[this.statuses.length - 1].draft_comments.filter(
                  (x) => x.comment_user_id === this.loginInfo.user_id
                );
                if (lastDraftComments.length > 0){
                  this.inputComment = lastDraftComments[lastDraftComments.length - 1].comment;
                }
              }
            }
          }
        }
      } // END-IF-ELSE
    }),
    /**
     * is blank time
     */
    isBlankTime() {
      let actuals = this.calendarOptions.events.filter((x) => !x.isPlan);
      if (!isEmpty(actuals)) {
        let startTime = Number(this.startWorkTime);
        let endTime = Number(this.endWorkTime);
        if ((startTime === 0 && endTime === 0) || startTime === endTime)
          return {
            isBlank: false,
            blankData: [],
          };
        let prepareData = [];
        actuals.forEach((event) => {
          if (+event.end_time < startTime || +event.start_time > endTime) {
            // Nothing to do
          } else {
            prepareData.push({
              start: Number(event.start_time),
              end: Number(event.end_time),
              id: event.id,
            });
          }
        });
        const UpdateOverLap = (events, event) => {
          for (let index = 0; index < events.length; index++) {
            if (event.id === events[index].id) {
              continue;
            }

            if (
              !(
                +events[index].start >= +event.end ||
                +events[index].end <= +event.start
              )
            ) {
              events[index].start = Math.min(
                +events[index].start,
                +event.start
              );
              events[index].end = Math.max(+events[index].end, +event.end);
              return true;
            }
          }
          return false;
        };
        prepareData = orderBy(prepareData, ["start", "end"], ["asc", "asc"]);
        if (isEmpty(prepareData))
          return {
            isBlank: true,
            blankData: [],
          };
        let sortData = [prepareData[0]];
        prepareData.shift();
        prepareData.forEach((x) => {
          if (!UpdateOverLap(sortData, x)) sortData.push(x);
        });

        let isBlank = false;
        let index = 0;

        if (isEmpty(sortData))
          return {
            isBlank: true,
            blankData: [],
          };

        // Case start
        if (sortData[0].start > startTime) {
          isBlank = true;
        }

        while (index < sortData.length - 1 && !isBlank) {
          isBlank = sortData[index].end != sortData[index + 1].start;
          index++;
        }

        // Case end
        if (!isBlank && sortData[sortData.length - 1].end < endTime) {
          isBlank = true;
        }

        let blankData = [];
        if (isBlank) {
          if (sortData[0].start > startTime) {
            blankData.push({
              start: startTime,
              end: sortData[0].start,
            });
          }
          index = 0;
          while (index < sortData.length - 1) {
            if (sortData[index].end != sortData[index + 1].start)
              blankData.push({
                start: sortData[index].end,
                end: sortData[index + 1].start,
              });
            index++;
          }
          if (sortData[sortData.length - 1].end < endTime) {
            blankData.push({
              start: sortData[sortData.length - 1].end,
              end: endTime,
            });
          }
        }
        return {
          isBlank: isBlank,
          blankData: blankData,
        };
      } else {
        return true;
      } // END-IF-ELSE
    },
    /**
     * is align blank time
     */
    isAlignBlankTime() {
      let actuals = this.calendarOptions.events.filter((x) => !x.isPlan);
      if (!isEmpty(actuals)) {
        let startTime = Number(this.startWorkTime);
        let endTime = Number(this.endWorkTime);
        let prepareData = [];
        actuals.forEach((event) => {
          prepareData.push({
            start: Number(event.start_time),
            end: Number(event.end_time),
            id: event.id,
          });
        });
        const UpdateOverLap = (events, event) => {
          for (let index = 0; index < events.length; index++) {
            if (event.id === events[index].id) {
              continue;
            }

            if (
              !(
                +events[index].start >= +event.end ||
                +events[index].end <= +event.start
              )
            ) {
              events[index].start = Math.min(
                +events[index].start,
                +event.start
              );
              events[index].end = Math.max(+events[index].end, +event.end);
              return true;
            }
          }
          return false;
        };
        prepareData = orderBy(prepareData, ["start", "end"], ["asc", "asc"]);
        if (isEmpty(prepareData))
          return {
            isBlank: true,
            blankData: [],
          };
        let sortData = [prepareData[0]];
        prepareData.shift();
        prepareData.forEach((x) => {
          if (!UpdateOverLap(sortData, x)) sortData.push(x);
        });

        let isBlank = false;
        let index = 0;

        if (isEmpty(sortData))
          return {
            isBlank: true,
            blankData: [],
          };

        // Case start
        if (
          !((startTime === 0 && endTime === 0) || startTime === endTime) &&
          sortData[0].start > startTime
        ) {
          isBlank = true;
        }

        while (index < sortData.length - 1 && !isBlank) {
          isBlank = sortData[index].end != sortData[index + 1].start;
          index++;
        }

        // // Case end
        // if (
        //   !((startTime === 0 && endTime === 0) || startTime === endTime) &&
        //   !isBlank &&
        //   sortData[sortData.length - 1].end < endTime
        // ) {
        //   isBlank = true;
        // }

        let blankData = [];
        if (isBlank) {
          if (
            !((startTime === 0 && endTime === 0) || startTime === endTime) &&
            sortData[0].start > startTime
          ) {
            blankData.push({
              start: startTime,
              end: sortData[0].start,
            });
          }
          index = 0;
          while (index < sortData.length - 1) {
            if (sortData[index].end != sortData[index + 1].start)
              blankData.push({
                start: sortData[index].end,
                end: sortData[index + 1].start,
              });
            index++;
          }
          // if (
          //   !((startTime === 0 && endTime === 0) || startTime === endTime) &&
          //   sortData[sortData.length - 1].end < endTime
          // ) {
          //   blankData.push({
          //     start: sortData[sortData.length - 1].end,
          //     end: endTime,
          //   });
          // }
        }
        return {
          isBlank: isBlank,
          blankData: blankData,
        };
      } else {
        return true;
      } // END-IF-ELSE
    },
    /**
     * authorize calendar
     * @param {string} [message]
     */
    authorizeCalendar(message) {
      this.authorizeCheck = true;
      this.authorizeMessage = message;
    },
    /**
     * is reject status
     */
    isRejectStatus() {
      if (this.isFirstApprove())
        return (
          this.phaseId === SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET ||
          this.phaseId === SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING
        );
      if (this.isSecondApprove())
        return (
          this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
        );
      return false;
    },
    /**
     * check open missing task
     * @param {object} [item]
     */
     checkOpenMissingTask(item) {
      return (
        !this.isHideMissingTask() && (
        (!isEmpty(item.issue_tasks) &&
        item.issue_tasks.length > 0)
        || (!isEmpty(this.addTasks) &&
        this.addTasks.length > 0)
        )
        
      );
    },

    async getUncompletedPlans() {
      if (this.$refs.todoTaskList) {
        await this.$refs.todoTaskList.loadTodoListData();
        let splitTasks = this.$refs.todoTaskList.getSplitTask();
        let todoTasks = this.$refs.todoTaskList.getTodoList();

        let unCompletedTodoIds = !isEmpty(todoTasks)
          ? todoTasks
              .filter((x) => x.schedule_task_id !== null)
              .map((x) => x.schedule_task_id)
          : [];

        let unCompletedSplitIds = !isEmpty(splitTasks)
          ? splitTasks
              .filter((x) => x.schedule_task_id !== null)
              .map((x) => x.schedule_task_id)
          : [];
        let splitSourceIds = !isEmpty(splitTasks)
          ? splitTasks.map((x) => x.source_id)
          : [];

        let unCompletedIds = unCompletedTodoIds.concat(unCompletedSplitIds);

        if (!isEmpty(unCompletedIds)) {
          await this.missingTasks
            .filter((x) => unCompletedIds.includes(x.id))
            .map((y) => (y.check = true));
        }
        if (!isEmpty(splitSourceIds)) {
          await this.missingTasks
            .filter((x) => splitSourceIds.includes(x.split_task_source_id))
            .map((y) => (y.check = true));
        }

        await this.missingTasks
            .filter((x) => +x.is_source_completed === 1)
            .map((y) => (y.check = true));
        this.unCompletedPlans = this.missingTasks;
      }
    },

    deleteTodoTask(){
      if (this.$refs.todoTaskList){
        let splitTasks = this.$refs.todoTaskList.getSplitTask();
        let todoTasks = this.$refs.todoTaskList.getTodoList();

        let unCompletedTodoIds = !isEmpty(todoTasks)
          ? todoTasks
              .filter((x) => x.schedule_task_id !== null)
              .map((x) => x.schedule_task_id)
          : [];

        let unCompletedSplitIds = !isEmpty(splitTasks)
          ? splitTasks
              .filter((x) => x.schedule_task_id !== null)
              .map((x) => x.schedule_task_id)
          : [];
        
        let splitSourceIds = !isEmpty(splitTasks)
          ? splitTasks.map((x) => x.source_id)
          : [];
        let unCompletedIds = unCompletedTodoIds.concat(unCompletedSplitIds);

        this.missingTasks.map((y) => (y.check = false));
        if (!isEmpty(unCompletedIds)) {
          this.missingTasks
            .filter((x) => unCompletedIds.includes(x.id))
            .map((y) => (y.check = true));
        }
        if (!isEmpty(splitSourceIds)) {
          this.missingTasks
            .filter((x) => splitSourceIds.includes(x.split_task_source_id))
            .map((y) => (y.check = true));
        }
        this.unCompletedPlans = this.missingTasks;
      }
    },
    /**
     * Check close Notification
     * @return {boolean}
     */
    closeNotification() {
      this.showNotification = false;
    },

    /**
     * Check show issue status in actual
     * @return {boolean}
     */
    isShowNotification() {
      const isFromNotice =
        this.$route.query.fromNotice && +this.$route.query.fromNotice === 1;
      const remandIdList = [
        SystemConstant.PHASE_ID_PRIMARY_REJECT,
        SystemConstant.PHASE_ID_2_NEXT_REJECT,
      ];
      return (
        this.statuses.length > 0 &&
        (remandIdList.includes(this.statuses[this.statuses.length - 1].phase_id) || isFromNotice)
      );
    },

    /**
     * Check show task from notice
     * @return {void}
     */
    showTaskFromNotice() {
      let taskId = this.$route.query.taskId;
      if (taskId && !isEmpty(this.calendarOptions.events)) {
        let event = this.calendarOptions.events.find(
          (x) => +x.id === +taskId && !x.isPlan
        );
        if (event) {
          this.handleEventClick({
            event: {
              id: event.id,
              _def: {
                extendedProps: event,
              },
            },
          });
        } else {
          this.$alert("タスクが見つかりません。");
        }
      }
    },

    /**
     * Check show notice status from notice
     * @return {void}
     */
    showNotificationFromNotice() {
      this.showNotification =
        this.$route.query.fromNotice && +this.$route.query.fromNotice === 1
          ? true
          : this.isShowNotification();
    },

    /**
     * Mark as read comment notice
     * @return {void}
     */
    async callApiFC502(task) {
      if (!this.getUnReadList || this.getUnReadList.length === 0) return;

      const commentsList = task.comments || false;
      if (!commentsList) return;

      const managerCommentList =
        commentsList.filter((item) => {
          const itemMarkRead =
            this.getUnReadList.find(
              (notice) => notice.comment_notice_id === item.id
            ) || false;
          const isManagerComment = item.user_id === this.loginInfo.manage_id;
          const isSelfSubmit = this.isSelf();
          return itemMarkRead && isManagerComment && isSelfSubmit;
        }) || [];
      if (managerCommentList.length === 0) return;

      let notices = [];
      managerCommentList.forEach((item) => {
        notices.push({
          status_notice_id: null,
          comment_notice_id: item.id || null,
          is_verified: 1,
        });
      });

      // call Api FC-502 for mark read
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },
    /**
     * Set time grid length
     * @return {void}
     */
    setTimeGridLength(slotDuration, slotMinTime, slotMaxTime) {
      this.timeGridLength = this.getTimeGridSlotByDuration(
        slotDuration,
        slotMinTime,
        slotMaxTime
      );
    },
    isTimeTextTruncate(cost, count) {
      return (
        (this.calendarOptions.slotDuration === "00:30:00" ||
          this.calendarOptions.slotDuration === "00:60:00") &&
        cost * count < 70
      );
    },
    isWrapText(cost, count) {
      return (
        ((this.calendarOptions.slotDuration === "00:05:00" ||
          this.calendarOptions.slotDuration === "00:15:00") &&
          cost * count < 70) ||
        cost * count >= 70
      );
    },
    async apiConfirmedNotice(notices) {
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    async confirmNotice() {
      // Confirm this notice
      if (
        this.$route.query.is_verified &&
        this.$route.query.is_verified === "1"
      ) {
        await this.apiConfirmedNotice([
          {
            status_notice_id: this.$route.query.status_notice_id || null,
            comment_notice_id: this.$route.query.comment_notice_id || null,
            is_verified: 1,
          },
        ]);
        this.$store.dispatch("notification/setCount", this.getCount);
      }
    },

    /**
     * 実装できるかをチェック
     * 分割タスクで先の分割分が完了された場合、実装できない
     */
    canImplementation(arg){
      let scheduleTask = this.calendarOptions.events.find(
        (x) => x.id === +arg && x.isPlan && !!x.is_split_task
      );
      if (!scheduleTask){
        return true;
      }

      if (scheduleTask && scheduleTask.is_source_completed && +scheduleTask.is_source_completed === 1 && +scheduleTask.is_completed !== 1){
        return false;
      }

      // タスクが削除できない
      let childTask = this.calendarOptions.events.find(
        (x) => !!x.is_split_task
                && x.split_task_source_id === scheduleTask.split_task_source_id 
                && x.isPlan
                && !!x.is_completed
                && +x.split_task_no > +scheduleTask.split_task_no
      );
      if (!!scheduleTask.is_completed && childTask){
        return false;
      }

      let parentTask = this.calendarOptions.events.find(
        (x) => !!x.is_split_task
                && x.split_task_source_id === scheduleTask.split_task_source_id 
                && x.isPlan
                && +x.is_completed === 0
                && +x.split_task_no < +scheduleTask.split_task_no
      );

      if (parentTask){
        return false;
      }
      
      return true;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo", "isActualExpand"]),

    formatSelectedDate() {
      let date = parseDateTime(this.selectedDate, yyyyMMdd2);
      let dayOfWeek = getDayOfWeek(date);
      return `${formatDateTime(date, yyyyMMdd3)}(${dayOfWeek})`;
    },

    totalTime() {
      if (isEmptyNumber(this.actualTotalTime)) return "";
      else return this.toHHmm(this.actualTotalTime);
    },

    overTotalPlan() {
      if (isEmptyNumber(this.actualTotalTime)) return false;
      else return this.actualTotalTime > this.planTotalTime;
    },

    checkedActualItems() {
      return this.calendarOptions.events.filter((x) => {
        return x.check && !x.isPlan;
      });
    },

    actualTotalTime() {
      return this.calendarOptions.events
        .filter((x) => !x.isBreak && !x.isPlan)
        .reduce(function (total, e) {
          return total + e.cost * e.count;
        }, 0);
    },

    planTotalTime() {
      return this.calendarOptions.events
        .filter((x) => !x.isBreak && x.isPlan)
        .reduce(function (total, e) {
          return total + e.cost * e.count;
        }, 0);
    },

    workingTime() {
      if (
        isEmpty(this.startWorkTime) ||
        isEmpty(this.endWorkTime) ||
        isEmpty(this.actualEndBreakTime) ||
        isEmpty(this.actualStartBreakTime)
      )
        return `0h0m`;
      let workTime =
        toMinute(this.endWorkTime.replace(":", "")) -
        toMinute(this.startWorkTime.replace(":", ""));
      let breakTime;
      if (
        this.haveBreakTime ||
        +`${this.actualStartBreakTime.replace(":", "")}00` >=
          +this.endWorkTime.replace(":", "") ||
        +`${this.actualEndBreakTime.replace(":", "")}00` <=
          +this.startWorkTime.replace(":", "")
      ) {
        breakTime = 60;
      } else {
        let startBTime = Math.max(
          +this.startWorkTime.replace(":", ""),
          +`${this.actualStartBreakTime.replace(":", "")}00`
        );
        let endBTime = Math.min(
          +this.endWorkTime.replace(":", ""),
          +`${this.actualEndBreakTime.replace(":", "")}00`
        );

        breakTime =
          toMinute(`${endBTime}`.padStart(6, "0")) -
          toMinute(`${startBTime}`.padStart(6, "0"));
      }
      let totalTime = workTime - breakTime;
      if (totalTime < 0){
        totalTime = 0;
      }
      let minute = totalTime % 60 < 10 ? `0${totalTime % 60}` : totalTime % 60;
      return `${Math.trunc(totalTime / 60)}h${minute}m`;
    },

    diffWorkTimeError() {
      let workingTime =
        !isEmpty(this.startWorkTime) && !isEmpty(this.endWorkTime)
          ? toMinute(this.endWorkTime.replace(":", "")) -
            toMinute(this.startWorkTime.replace(":", ""))
          : 0;
      if (workingTime === 0) return false;
      let breakTime;
      if (
        isEmpty(this.actualStartBreakTime) ||
        isEmpty(this.actualEndBreakTime) ||
        +`${this.actualStartBreakTime.replace(":", "")}00` >=
          +this.endWorkTime.replace(":", "") ||
        +`${this.actualEndBreakTime.replace(":", "")}00` <=
          +this.startWorkTime.replace(":", "")
      ) {
        breakTime = 0;
      } else {
        let startBTime = Math.max(
          +this.startWorkTime.replace(":", ""),
          +`${this.actualStartBreakTime.replace(":", "")}00`
        );
        let endBTime = Math.min(
          +this.endWorkTime.replace(":", ""),
          +`${this.actualEndBreakTime.replace(":", "")}00`
        );

        breakTime =
          toMinute(`${endBTime}`.padStart(6, "0")) -
          toMinute(`${startBTime}`.padStart(6, "0"));
      }
      let realTime = workingTime - breakTime;
      return this.actualTotalTime < realTime;
    },

    blankTimeError() {
      return this.isBlankTime();
    },
    alignBlankTimeError() {
      return this.isAlignBlankTime();
    },
    overLapTimeError() {
      return isOverLap(this.calendarOptions.events.filter((x) => !x.isPlan));
    },

    hasSupervisor() {
      if (this.isSelf()) {
        return !isEmpty(this.loginInfo?.manage_id);
      } else return true;
    },

    notComplete() {
      return this.calendarOptions.events.some(
        (x) => !x.isPlan && !x.isBreak && !!x.is_completed === false
      );
    },

    notOutput() {
      return this.calendarOptions.events.some(
        (x) =>
          !x.isPlan &&
          !x.isBreak &&
          !!x.is_need_output === true &&
          isEmpty(x.files)
      );
    },

    preValidation() {
      return (
        (this.blankTimeError.isBlank ||
          this.overLapTimeError ||
          this.diffWorkTimeError) &&
        this.isSelf() &&
        this.isNoSubmmit()
      );
    },

    isRejectText() {
      return (
        this.statusText === SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT ||
        this.statusText === SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT
      );
    },

    missingTasks() {
      let executeIds = this.calendarOptions.events
        .filter((x) => !x.isPlan && !x.isBreak)
        .map((x) => x.schedule_task_id);
      let notExecuteTasks = this.calendarOptions.events.filter(
        (x) => x.isPlan && !x.isBreak && !executeIds.includes(x.id) && this.canImplementation(x.id)
      );
      notExecuteTasks.sort(
        (a, b) => toMinute(a.start_time) - toMinute(b.start_time)
      );
      if (!isEmpty(this.unCompletedPlans) && this.unCompletedPlans.length > 0) {
        // return this.unCompletedPlans;
        notExecuteTasks
          .filter((x) => this.unCompletedPlans.includes(x.id))
          .map((y) => (y.check = true));
      }

      // notExecuteTasks.map((x) => (x.check = false));
      return notExecuteTasks;
    },

    addTasks() {
      let addTasks = this.calendarOptions.events.filter(
        (x) => !x.isPlan && !x.isBreak && !x.schedule_task_id
      );
      let addFromArchiveTasks = this.calendarOptions.events.filter(
        (x) => !x.isPlan && !x.isBreak && x.schedule_task_id
        && this.calendarOptions.events.filter(
          (y) => y.isPlan && y.id === x.schedule_task_id
        ).length === 0
      );
      if (addFromArchiveTasks.length > 0){
        addTasks = addTasks.concat(addFromArchiveTasks);
      }
      if (addTasks && addTasks.length > 0) {
        addTasks.sort(
          (a, b) => toMinute(a.start_time) - toMinute(b.start_time)
        );
      }
      return addTasks;
    },

    isMobile() {
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
    },

    printCalendarOptions() {
      if (this.isMobile) {
        return {};
      }
      let printCalendarOptions = deepClone(this.calendarOptions);
      if (this.$refs.printCalendar) {
        let printCalendarApi = this.$refs.printCalendar.getApi();
        printCalendarApi.gotoDate(formatDateTime(this.selectedDate), yyyyMMdd2);
        this.getPrintTotalTime();
      }

      this.changeSlotMinMaxModePrint(printCalendarOptions);
      this.setTimeGridLength(
        printCalendarOptions.slotDuration,
        printCalendarOptions.slotMinTime,
        printCalendarOptions.slotMaxTime
      );

      const minSlot = this.getMinSlotByDurationModePrint(
        printCalendarOptions.slotDuration,
        this.timeGridLength
      );
      this.adjustTasks(
        printCalendarOptions.events,
        this.selectedDate,
        minSlot,
        printCalendarOptions.slotMaxTime
      );
      return {
        ...printCalendarOptions,
        ...{
          slotDuration: this.getSlotDuration(printCalendarOptions.slotDuration),
        },
      };
    },

    /**
     * format css print page
     * @return {object}
     */
    cssProps() {
      if (this.timeGridLength === 0) return;
      const unitPrint = "mm";
      const heightHeader = 5.7;
      const heightFcTimegridSlotDuration30 =
        (CALENDAR_PRINT_LENGHT_MM +
          ACTUAL_SLOT_30_SCALE[this.timeGridLength] -
          heightHeader) /
          this.timeGridLength +
        unitPrint;
      const heightFcTimegridSlotDuration60 =
        (CALENDAR_PRINT_LENGHT_MM +
          ACTUAL_SLOT_60_SCALE[this.timeGridLength] -
          heightHeader) /
          this.timeGridLength +
        unitPrint;
      let heightFcTimegridSlotDurationDefault =
        (CALENDAR_PRINT_LENGHT_MM +
          ACTUAL_DEFAULT_SCALE_1_PAGE[this.timeGridLength / 4] -
          heightHeader) /
          (13 * 4) +
        unitPrint;

      if (
        (this.calendarOptions.slotDuration === "00:05:00" ||
          this.calendarOptions.slotDuration === "00:15:00") &&
        this.timeGridLength > 13 * 4
      ) {
        heightFcTimegridSlotDurationDefault =
          (CALENDAR_PRINT_LENGHT_MM * 2 +
            ACTUAL_DEFAULT_SCALE[this.timeGridLength / 4]) /
            this.timeGridLength +
          unitPrint;
      }

      return {
        "--height-fc-timegrid-slot-duration-default":
          heightFcTimegridSlotDurationDefault,
        "--height-fc-timegrid-slot-duration-30": heightFcTimegridSlotDuration30,
        "--height-fc-timegrid-slot-duration-60": heightFcTimegridSlotDuration60,
      };
    },
  },

  watch: {
    isActualExpand(val) {
      this.expand = val;
    },
    showDrawerRight: {
      handler: function () {
        this.$nextTick(() => {
          if (this.showDrawerRight) {
            let custom = document.querySelector(
              ".position-custom .v-snack__wrapper"
            );
            if (custom) custom.style.setProperty("right", "500px");
          } else {
            let custom = document.querySelector(
              ".position-custom .v-snack__wrapper"
            );
            if (custom) custom.style.setProperty("right", "100px");
          }
          let fullCalendarHeader = document.querySelector('.fullcalendar-itokuro .fc-col-header') || null
          fullCalendarHeader && (fullCalendarHeader.style.width = 'calc(100% - 18px)')
          let fullCalendarBody = document.querySelector('.fullcalendar-itokuro .fc-timegrid-body') || null
          fullCalendarBody && (fullCalendarBody.style.width = '100%')
        });
      },
    },
    statuses() {
      this.showNotification = this.isShowNotification();
    },
    "calendarOptions.events": {
      deep: true,
      handler(val) {
        this.adjustTasks(val, this.selectedDate, this.min);
        this.changeSlotMinMax(this.calendarOptions, val, this.startWorkTime, this.endWorkTime);
      },
    },
  },

  created() {
    this.$root.$refs.actualComponent = this;
  },

  async mounted() {
    await this.confirmNotice();
    this.userId = isEmpty(this.$route.query.uid)
      ? this.user?.user_id
      : this.$route.query.uid;

    this.planId = this.$route.query.sid || "";
    this.actualId = this.$route.params.id || "";

    if (!isEmpty(this.$route.query.date))
      this.selectedDate = this.$route.query.date;

    if (this.isSelf()) {
      let draggableEl = document.getElementById("dragElements");
      new Draggable(draggableEl, {
        itemSelector: ".db-task",
      });
      new Draggable(draggableEl, {
        itemSelector: ".todo-task",
      });
      new Draggable(draggableEl, {
        itemSelector: ".favorite-db-task",
      });
      new Draggable(draggableEl, {
        itemSelector: ".favorite-task",
      });
      new Draggable(draggableEl, {
        itemSelector: ".monthly-task",
      });
    }
    this.calendarOptions.slotDuration = Cookies.get(
      "slotDurationItem" + this.user?.user_id
    )
      ? Cookies.get("slotDurationItem" + this.user?.user_id)
      : this.slotDurationItems[1].value;
    this.preSlotDuration = this.calendarOptions.slotDuration;
    //this.min = (this.calendarOptions.slotDuration === "00:30:00" || this.calendarOptions.slotDuration === "00:60:00") ? 15 : 5;
    if (this.calendarOptions.slotDuration === "00:30:00") {
      this.min = 15;
    } else if (this.calendarOptions.slotDuration === "00:60:00") {
      this.min = 18;
    } else {
      this.min = 5;
    }
    await this.getSchedule();
    await this.getMembers();
    this.selectedUser = this.members.find((x) => x.id === this.userId);
    const userStore = { id: this.userId };
    this.$store.dispatch("common/setMember", { selectedMember: userStore });

    this.showTaskFromNotice();
  },

  destroyed() {
    this.$store.dispatch("common/isActualExpand", 0);
  },
};
</script>

<style lang="scss" src="@/assets/scss/_calendar.scss" scoped></style>
<style lang="scss">
.select {
  width: 100px;
}
.v-text-field.v-text-field--solo {
  .v-input__control {
    min-height: 36px !important;
  }
}
::v-deep .expertTaskIcon > img.v-icon__component {
  width: 30px;
}
::v-deep .beginnerTaskIcon > img.v-icon__component {
  width: 38px;
  height: 28px;
}
.v-center {
  display: flex;
  align-items: center;
}

.sub-hours {
  font-size: 0.8rem;
}

.content-row {
  display: flex;
}

.content-calendar {
  width: 70%;
  padding-left: 0;
  .v-breadcrumbs {
    padding-left: 0;
  }
  .v-breadcrumbs li:nth-child(2n) {
    padding: 0;
  }
}
.content-db-list {
  width: 30%;
  border-left: 1px solid #e1e1e1;
}
.content-db-list > .row {
  height: 100%;
  margin: 0;
  padding-bottom: 0;
}
.content-db-list #dragElements {
  padding: 0;
}
.my-column-full {
  width: 100% !important;
}
.is-panel-collapsed {
  .content-calendar {
    width: 97%;
  }
  .content-db-list {
    width: 3%;
  }
}
.flow-root {
  display: flow-root !important;
}

.v-tabs {
  a {
    font-weight: 700;
  }
}
.plan-header {
  background-color: #1e90ff;
  padding-right: 2px;
  padding-left: 2px;
  div {
    display: flex;
    align-items: center;
    a {
      color: white !important;
    }
    span {
      color: white !important;
      margin-left: auto;
    }
  }
}

.actual-header {
  background-color: #dc143c;
  padding-right: 2px;
  padding-left: 2px;
  div {
    display: flex;
    align-items: center;
    a {
      color: white !important;
    }
    span {
      color: white !important;
      margin-left: auto;
    }
  }
}

.fc-day-today:not(.plan-header):not(.actual-header) {
  background-color: inherit !important;
}
.custom-content {
  cursor: pointer;
}

.db-text {
  color: #1e90ff;
}
.normal-text {
  color: #222222;
}
.db-text-completed {
  color: gray;
}
.plan-unComplete-task {
  border-color: #ff0000 !important;
}
.actual-unPlan-task {
  border-color: #2e75b6 !important;
}
.output-task:before {
  background-color: #222222;
  display: block;
  content: " ";
  position: absolute;
  height: 100%;
  width: 8px;
  border-radius: 3px 0 0 3px;
  top: 0;
  left: -1px;
}
.output-task {
  .fc-event-main {
    margin-left: 8px;
  }
}

.output-task-completed:before {
  background-color: #bfbfbf;
  display: block;
  content: " ";
  position: absolute;
  height: 100%;
  width: 8px;
  border-radius: 3px 0 0 3px;
  top: 0;
  left: -1px;
}
.output-task-completed {
  .fc-event-main {
    margin-left: 8px;
  }
}
.text-custom {
  color: #b8860b;
}

.text-large {
  font-size: 1rem;
  font-weight: 900;
}
.reject-text {
  color: #dc143c !important;
}

.header-items button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.header-items button:disabled {
  box-shadow: none;
}
</style>
<style lang="scss" scoped>
::v-deep .head {
  display: flex;
  align-items: center;
  .v-simple-checkbox {
  }
}
::v-deep .v-input__append-outer {
  margin-top: 0 !important;
}
.flatbtn {
  box-shadow: none !important;
  background-color: unset !important;
}

.icon-inline {
  display: inline-block;
}
.name-detail {
  word-break: break-word;
}
.min-w-100 {
  min-width: 120px;
}
.min-w-48 {
  min-width: 48px;
}
.min-w-80 {
  min-width: 80px;
}
.min-w-28 {
  min-width: 28px;
}
.time-text {
  display: inline-block;
}
.time-text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.time-text-margin {
  margin-top: -0.2rem;
}
.wrap-text {
  word-break: break-all;
  white-space: normal;
}
::v-deep .viewCalendar {
  .fc-event-main {
    .row {
      .col {
        padding-bottom: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
// Config print
$--font-size-default: 0.7em;
$--font-size-duration-30: 0.7em;
$--font-size-duration-60: 0.7em;

.printCalendar {
  height: 100%;
}

.print-calendar {
  ::v-deep.fc-timegrid-event-harness {
    .fc-timegrid-event {
      overflow: hidden;
      min-width: 20px;
    }
  }
  ::v-deep .fc-timegrid-event-harness:last-child {
    .fc-timegrid-event {
      min-width: unset !important;
    }
  }
}

// Print for duration 5', 15'
.fullcalendar-itokuro--five-min,
.fullcalendar-itokuro--fifteen-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-default;
    }
    .fc-scroller-harness.fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-default;
        height: var(--height-fc-timegrid-slot-duration-default);
      }
    }
  }
}

// Print for duration 30'
.fullcalendar-itokuro--thirty-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-duration-30;
    }
    .fc-scroller-harness,
    .fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-duration-30;
        height: var(--height-fc-timegrid-slot-duration-30);
      }
    }
  }
}

// Print for duration 60'
.fullcalendar-itokuro--sixty-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-duration-60;
    }
    .fc-scroller-harness.fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-duration-60;
        height: var(--height-fc-timegrid-slot-duration-60);
      }
    }
  }
}
</style>
