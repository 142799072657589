<template>
  <v-dialog persistent :value="openDialog" max-width="300px">
    <v-card class="py-3">
      <v-card class="mx-5 py-0" color="primary" dark>
        <v-card-title>CSVエクスポート設定 </v-card-title>
      </v-card>
      <v-card-text class="mt-5">
        <v-radio-group v-model="radioExport">
          <v-radio value="0" :label="'表示されている期間'"></v-radio>
        </v-radio-group>
        <span>※最長3ヶ月まで出力可能です。</span>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn tile color="ligth darken-1" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          tile
          :disabled="isDisable"
          color="primary darken-1"
          @click="submit()"
        >
          エクスポート
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { STATUS_LOGS_CSV_EXPORT_API } from "../../../constants/api";
import { FC903 } from "../../../constants/fc";
import { apiErrorMessages } from "../../../helpers/messages";
import { debounce_leading, exportShiftJIS } from "../../../helpers/helpers";
import axios from "axios";
import { formatDateTime, parseDateTime } from "@/helpers/datetime-helpers";
import { yyyyMMdd1, yyyyMMdd2 } from "@/constants/df";
export default {
  props: {
    show: Boolean,
    queryStatusLogs: {},
  },
  watch: {
    show: {
      handler() {
        this.radioExport = "3";
        this.openDialog = true;
      },
    },
  },
  data() {
    return {
      openDialog: false,
      radioExport: "3",
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth?.user,
    }),
    isDisable() {
      return (
        +this.radioExport === 3 &&
        +this.radioExport !== 0 &&
        +this.radioExport !== 1
      );
    },
  },
  methods: {
    submit :debounce_leading(async function() {
      let endDate = parseDateTime(
        this.queryStatusLogs.real_end_date,
        yyyyMMdd2
      );
      endDate.setMonth(endDate.getMonth() - 12);
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(this.queryStatusLogs.real_start_date, yyyyMMdd1)
      ) {
        this.$alert(
          "エクスポートできる期間は最長1年までです。表示期間を再設定してください。",
          {
            width: "800px",
          }
        );
      } else {
        try {
          const { data, headers } = await axios.get(
            STATUS_LOGS_CSV_EXPORT_API(this.radioExport, {
              calendar_type: this.queryStatusLogs.calendar_type,
              start_date: formatDateTime(
                this.queryStatusLogs.real_start_date,
                yyyyMMdd1
              ),
              end_date: formatDateTime(
                this.queryStatusLogs.real_end_date,
                yyyyMMdd1
              ),
            }),
            {
              responseType: "blob",
            }
          );
          const headerval = headers["content-disposition"];
          let filename = headerval
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");
          // filename = decodeURI(filename).substring("utf-8''".length);
          exportShiftJIS(data, filename);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC903, status);
        } finally {
          this.close();
        }
      }
    }),
    close() {
      this.openDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
