<template>
  <v-menu
    v-model="openMenu"
    auto
    transition="scale-transition"
    bottom
    offset-y
    :close-on-content-click="!(isDisable || !isCanSubmit())"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-blur
        tile
        v-bind="attrs"
        v-on="on"
        @click="handleOnClick()"
        >テンプレート</v-btn
      >
    </template>
    <v-list>
      <ChildTemplate
        :templates="templates"
        :isDisable="isDisable || !isCanSubmit()"
        @selectedTemplate="!isDisable && selectTemplate($event)"
        @editedTemplate="editTemplate($event)"
        :name="'テンプレートの利用'"
        :isEdit="true"
      ></ChildTemplate>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-menu
            v-model="sopenMenu"
            bottom
            right
            nudge-right="50"
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row class="v-center" v-bind="attrs" v-on="on">
                <v-icon class="mx-3" small>$savetemplate</v-icon>

                <v-list-item-content class="ml-7">
                  <v-list-item-title
                    >予定をテンプレートとして保存</v-list-item-title
                  >
                </v-list-item-content>
              </v-row>
            </template>
            <v-list :class="{ 'scroll-mobile': $vuetify.breakpoint.mobile }">
              <child-template
                :templates="templates"
                :name="'テンプレートの上書き'"
                @selectedTemplate="saveTemplate($event)"
              ></child-template>
              <v-divider></v-divider>
              <v-list-item @click="newTemplate">
                <v-list-item-content>
                  <v-list-item-title
                    >新しいテンプレートとして保存
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-menu
            v-model="dopenMenu"
            bottom
            right
            offset-x
            nudge-right="120"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row class="v-center" v-bind="attrs" v-on="on">
                <v-icon class="mx-3" small>$delete</v-icon>
                <v-list-item-content class="ml-7">
                  <v-list-item-title>テンプレートを削除 </v-list-item-title>
                </v-list-item-content>
              </v-row>
            </template>
            <v-list>
              <child-template
                :templates="templates"
                :name="'削除するテンプレートを選んでください'"
                @selectedTemplate="deleteTemplate($event)"
              ></child-template>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from "axios";
import {
  MONTHS_TEMPLATE_API,
  UPDATE_MONTHLY_SCHEDULES_API,
} from "../../../constants/api";
import ChildTemplate from "../../../components/ChildTemplate.vue";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC844,
  FC846,
  FC847,
  FC849,
  FC835,
} from "../../../constants/fc";
import { mapGetters } from "vuex";
import { debounce_leading } from "@/helpers/helpers";
export default {
  components: { ChildTemplate },
  name: "MonthlyDailyTemplate",
  props: {
    isNoTask: Boolean,
    userId: String,
    targetMonth: String,
    scheduleMonthId: String,
    isDisable: Boolean,
    isHasSplitTask: Boolean,
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
  },
  data() {
    return {
      openMenu: false,
      sopenMenu: false,
      dopenMenu: false,
      templates: [],
    };
  },
  watch: {
    openMenu: {
      handler(val) {
        if (val) {
          this.getTemplates();
        }
      },
    },
  },
  methods: {
    handleOnClick() {
      this.$emit("handleOnClick");
    },
    isBeforeTwelve() {
      return this.$root.$refs.monthlySchedulesComponent.isBeforeTwelve();
    },
    isCanSubmit() {
      return this.$root.$refs.monthlySchedulesComponent.isCanSubmit();
    },
    getTemplates: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(MONTHS_TEMPLATE_API());
        this.templates = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC846, status);
      }
    }),
    saveTemplate(template) {
      let title = this.isHasSplitTask
        ? [
            "テンプレートを上書きしてもよろしいですか？\r\n分割したタスクはテンプレートとして保存されません。\r\nそれ以外のタスクをテンプレートとして保存します。",
          ]
        : ["テンプレートを上書きしてもよろしいですか？"];

      this.$confirm(title, {
        width: "700px",
      })
        .then(async () => {
          await this.saveRealTemplate(template.name, template.id, this.scheduleMonthId);
        })
        .catch(() => {
          return;
        });
    },
    selectTemplate(template) {
      if (!this.isNoTask) {
        this.$confirm(
          "テンプレートを挿入すると現在登録されているタスクが全て消去されます。\r\nタスクを上書きしてもよろしいですか？",
          {
            buttons: {
              ok: {
                text: "上書きする",
                type: "primary",
              },
            },
            width: "720px",
          }
        )
          .then(() => {
            this.overideTemplate(template);
          })
          .catch(() => {
            return;
          });
      } else {
        this.overideTemplate(template);
      }
    },
    async overideTemplate(template) {
      try {
        let scheduleTasksMonth = template.template_tasks.map((x) => {
          let task = {
            schedule_task_month_id: x.id,
            standard_task_id: x.standard_task_id,
            standard_task_revision: x.standard_task_revision,
            free_task_id: null,
            name: x.name,
            detail: x.detail,
            category_secondary_id: x.category_secondary_id,
            category_secondary_name: x.category_secondary_name,
            task_type_id: x.task_type_id,
            cost: +x.cost,
            count: +x.count,
            unit: x.unit,
            task_timing_id: x.task_timing_id,
            task_usual: x.task_usual,
            is_remand: 0,
            is_completed: 0,
            skill_level_id: x.skill_level_id || null,
            index_no: x.index_no,
           
          };
          return task;
        });
        try {
          await axios.put(UPDATE_MONTHLY_SCHEDULES_API(
            this.userId, 
            this.targetMonth,
            this.scheduleMonthId
          ), {
            working_days: template.working_days,
            working_hours: Number(template.working_hours),
            total_min: template.total_min,
            schedule_tasks_month:scheduleTasksMonth,
          });
          this.$emit("reload");
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC835, status);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC844, status);
      }
    },
    deleteTemplate(template) {
      this.$confirm(["テンプレートを本当に削除しますか？"], {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
        },
      })
        .then(async () => {
          try {
            await axios.delete(MONTHS_TEMPLATE_API(template.id));
            this.templates = this.templates.filter((x) => x.id !== template.id);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC849, status);
          }
        })
        .catch(() => {
          return;
        });
    },
    newTemplate() {
      if (this.isLimitTemplate()) {
        this.$alert(
          "テンプレートの保存件数上限に達しています。\r\n不要なテンプレートを削除してください。"
        );
        return;
      }

      if (this.isHasSplitTask) {
        this.$confirm(
          "分割したタスクはテンプレートとして保存されません。\r\nそれ以外のタスクをテンプレートとして保存します。",
          {
            width: "700px",
          }
        )
          .then(async () => {
            this.$prompt("新しいテンプレート名を入力", {
              maxlength: 64,
              validations: [(v) => !!v || this.$t("label.error.required")],
              dbData: this.templates,
              duplicatedError: this.$t("label.error.template_name_duplicated")
            })
              .then(async (x) => {
                await this.saveRealTemplate(x, null,this.scheduleMonthId);
              })
              .catch(() => {});
          })
          .catch(() => {
            return;
          });
      } else {
        this.$prompt("新しいテンプレート名を入力", {
          maxlength: 64,
          validations: [(v) => !!v || this.$t("label.error.required")],
          dbData: this.templates,
          duplicatedError: this.$t("label.error.template_name_duplicated")
        })
          .then(async (x) => {
            await this.saveRealTemplate(x, null,this.scheduleMonthId);
          })
          .catch(() => {});
      }
    },
    editTemplate(template) {
      this.$prompt("新しいテンプレート名を入力", {
        maxlength: 64,
        validations: [(v) => !!v || this.$t("label.error.required")],
        defaultValue: template.name,
        dbData: this.templates,
        duplicatedError: this.$t("label.error.template_name_duplicated")
      })
        .then(async (x) => {
          await this.saveRealTemplate(x, template.id,this.scheduleMonthId);
        })
        .catch(() => {});
    },
    async saveRealTemplate(templateName, templateId, monthScheduleId) {
      try {
          if (templateId) {
            await axios.put(MONTHS_TEMPLATE_API(templateId), {
              name: templateName,
              schedule_month_id: monthScheduleId,
            });
          } else {
            await axios.post(MONTHS_TEMPLATE_API(), {
              name: templateName,
              schedule_month_id: monthScheduleId,
            });
          }
          await this.getTemplates();
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(error.response.data.message);
        } else apiErrorMessages(this, FC847, status);
      }
    },

    isLimitTemplate() {
      return this.templates?.length == 10;
    },
  },
};
</script>
<style>
.scroll-mobile {
  max-height: 430px;
  overflow-y: auto;
}
</style>