<template>
  <v-dialog v-model="openCategory" persistent max-width="600px" scrollable>
    <v-card>
      <v-card-text class="card-content">
        <v-row align="center">
          <v-col class="text-left" cols="4">大カテゴリ<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="8">
            <v-combobox
              ref="myComboBox"
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              @change="primaryChange($event)"
              @update:search-input="searchInput"
              :error-messages="primaryCategoryErrors"
              :items="parentCategories"
              v-model="primaryCategory"
              clearable
              :menu-props="{
                maxWidth: 360,
              }"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item>
                  <v-list-item-content v-bind="attrs" v-on="on">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on">{{
                          item.name
                        }}</v-list-item-title>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu right offset-x bottom :nudge-width="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                          <v-icon class="grey--text">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="editPrimaryCategory(item)">
                          <v-list-item-content>{{
                            `${item.name}を編集`
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content
                            >他の小カテゴリと紐づくこの大カテゴリの名前も変更されます。</v-list-item-content
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row align="center">
          <v-col class="text-left" cols="4">小カテゴリ<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="8">
            <v-text-field
              maxlength="100"
              v-model="category.category_secondary_name"
              :error-messages="secondaryNameErrors"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row align="center">
          <v-col class="text-left" cols="4">カテゴリ変更可否<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="8">
            <v-select
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="changeability"
              v-model="category.is_changeability"
              :error-messages="changeabilityErrors"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="pt-2" align="center">
          <v-col class="text-left" cols="4">紐づき部署・Gr編集</v-col>
          <v-col class="text-right" cols="8">
            <v-menu
              :close-on-content-click="false"
              v-model="openMenu"
              open-on-click
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined> 編集</v-btn>
              </template>
              <DepartmentCheckList
                :items="departments"
                v-model="selectedDepartments"
                display="name"
                id="id"
                check="check"
              ></DepartmentCheckList>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="ml-auto">
        <v-btn @click="close" outlined depressed color="light">
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" @click="save()"> 変更 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DepartmentCheckList from "../../../components/DepartmentCheckList";
import axios from "axios";
import {
  GET_BELONG_API,
  SECONDARY_CATEGORY_API,
  PRIMARY_CATEGORY_API,
} from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC301, FC702, FC703 } from "../../../constants/fc";
import {
  debounce_leading,
  deepClone,
  isEmpty,
  isEmptyNumber,
} from "@/helpers/helpers";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin, backMixin],
  validations: {
    category: {
      category_secondary_name: { required },
      category_primary_id: { required },
      is_changeability: { required },
    },
    primaryCategory: {
      required,
    },
  },
  props: {
    show: Boolean,
    inputCategory: Object,
  },
  components: { DepartmentCheckList },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),

    secondaryNameErrors() {
      const errors = [];
      if (this.$v.category.category_secondary_name.$dirty) {
        !this.$v.category.category_secondary_name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    primaryCategoryErrors() {
      const errors = [];
      if (this.$v.primaryCategory.$dirty) {
        !this.$v.primaryCategory.required &&
          errors.push(this.$t("label.error.required"));
      }
      if (this.$v.category.category_primary_id.$dirty) {
        !this.$v.category.category_primary_id.required &&
          errors.push("登録されている大カテゴリのみ選択できます。");
      }

      return errors;
    },
    changeabilityErrors() {
      const errors = [];
      if (this.$v.category.is_changeability.$dirty) {
        !this.$v.category.is_changeability.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
  },
  watch: {
    show: {
      handler() {
        this.isEditing = false;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
        this.openCategory = true;
        this.category = {};
        this.$v.$reset();
      },
    },
    inputCategory: {
      async handler(val) {
        this.parentCategories = val.categories;
        this.oldCategory = deepClone(val);
        this.category = val;
        this.primaryCategory =
          this.parentCategories.find((x) => x.id === val.category_primary_id) ||
          {};
        await this.getBelong();
        this.isEditing = false;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    category: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    selectedDepartments: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  data() {
    return {
      category: {},
      openCategory: false,
      parentCategories: [],
      departments: [],
      groupSelecteds: [],
      selectedDepartments: [],
      oldCategory: {},
      primaryCategory: {},
      openMenu: false,
      isEditing: false,
      changeability: [
        { id: 1, name: "変更可" },
        { id: 0, name: "変更不可" },
      ],
    };
  },
  methods: {
    searchInput(s) {
      if (this.primaryCategory.name !== s) {
        this.category.category_primary_id = null;
      } else {
        this.category.category_primary_id = this.primaryCategory.id;
      }
    },
    compareValue() {
      let groups = [];
      let trueDepartments = this.selectedDepartments.filter((x) => x.check);
      for (const department of trueDepartments) {
        if (department.groups) {
          groups = groups.concat(
            department.groups
              .filter((x) => x.check)
              .map((x) => {
                return { group_id: x.id };
              })
          );
        }
      }
      return (
        JSON.stringify({
          category_primary_id: this.category.category_primary_id,
          category_secondary_name: this.category.category_secondary_name,
          is_changeability:this.category.is_changeability,
          departments: this.oldCategory.belongs.map((x) => {
            return x.group_id;
          }),
        }) !==
        JSON.stringify({
          category_primary_id: this.oldCategory.category_primary_id,
          category_secondary_name: this.oldCategory.category_secondary_name,
          is_changeability:this.oldCategory.is_changeability,
          departments: groups,
        })
      );
    },
    primaryChange(e) {
      this.category.category_primary_id = e ? e.id : null;
    },
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openCategory = false;
          }, 100);
        },
        () => {}
      );
    },

    async getBelong() {
      try {
        const { data } = await axios.get(GET_BELONG_API());
        this.departments = data.result.departments;

        if (!isEmpty(this.category.belongs)) {
          let departmentBelongs = this.category.belongs.map(
            (x) => x.department_id
          );

          let groupBelongs = this.category.belongs.map((x) => x.group_id);

          this.departments = this.departments.map((x) => {
            if (!isEmpty(x.groups)) {
              x.groups = x.groups.map((s) => {
                return {
                  ...s,
                  check: groupBelongs.includes(s.id),
                };
              });
            }
            return {
              ...x,
              check: departmentBelongs.includes(x.id),
            };
          });
          this.selectedDepartments = this.departments;
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },

    save: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        //
      } else {
        try {
          let groups = [];
          let trueDepartments = this.selectedDepartments.filter((x) => x.check);
          for (const department of trueDepartments) {
            if (department.groups) {
              groups = groups.concat(
                department.groups
                  .filter((x) => x.check)
                  .map((x) => {
                    return {
                      group_id: x.id,
                      group_name: x.name,
                      department_id: department.id,
                      department_name: department.name,
                    };
                  })
              );
            }
          }
          let params = {
            name: this.category.category_secondary_name,
            primary_id: this.category.category_primary_id,
            is_changeability: this.category.is_changeability,
            groups: groups.map((x) => {
              return {
                id: x.group_id,
              };
            }),
          };
          let new_category_secondary_id;
          if (this.category.category_secondary_id) {
            await axios.put(
              SECONDARY_CATEGORY_API(this.category.category_secondary_id),
              params
            );
          } else {
            const { data } = await axios.post(SECONDARY_CATEGORY_API(), params);
            new_category_secondary_id = data.result.id;
          }

          this.category.belongs = groups;

          let category_primary_name = this.parentCategories.find(
            (x) => x.id === this.category.category_primary_id
          )?.name;
          this.$toast(
            !this.category.category_secondary_id
              ? `${this.category.category_secondary_name}を追加しました。`
              : `${category_primary_name} の ${this.category.category_secondary_name}を編集しました。`,
            3000
          );
          this.category.category_primary_name = category_primary_name;
          if (isEmptyNumber(this.category.category_secondary_id)) {
            this.category.category_secondary_id = new_category_secondary_id;
          }
          this.$emit("categoryUpdated", {
            newCategory: this.category,
            oldCategory: this.oldCategory,
          });
          this.openCategory = false;
        } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.$alert(error.response.data.message);
          } else {
            apiErrorMessages(this, FC703, status);
          }
        }
      }
    }),
    async editPrimaryCategory(item) {
      this.$prompt(`大カテゴリ名を編集`, {
        maxlength: 100,
        validations: [(v) => !!v || this.$t("label.error.required")],
        defaultValue: item.name,
        button: {
          ok: {
            text: "保存",
            type: "primary",
          },
        },
      })
        .then(async (x) => {
          try {
            let params = {
              name: x,
            };
            await axios.put(PRIMARY_CATEGORY_API(item.id), params);
            this.$toast(`${x}を編集しました。`, 3000);
            let category = this.parentCategories.find((x) => x.id === item.id);
            category.name = x;
            this.category.category_primary_id = item.id;
            this.$refs.myComboBox.reset();
            this.$refs.myComboBox.setValue(category);
            this.primaryCategory = category;
            this.$emit("categoryPrimaryUpdated", {
              category_primary_id: item.id,
              category_primary_name: x,
            });
          } catch (error) {
            const { status } = error.response;
            if (status === 404) {
              this.$alert(error.response.data.message);
            } else {
              apiErrorMessages(this, FC702, status);
            }
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>
