var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"dialog-drag",rawName:"v-dialog-drag",value:({
    dialog: 'border-dialog',
  }),expression:"{\n    dialog: 'border-dialog',\n  }"}],attrs:{"persistent":"","content-class":"border-dialog","max-width":"780px","scrollable":"","overlay-color":"transparent","overlay-opacity":"1"},model:{value:(_vm.openExplain),callback:function ($$v) {_vm.openExplain=$$v},expression:"openExplain"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-header py-2"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","color":"gray"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$close")])],1)],1),_c('v-card-text',[_c('h2',{staticClass:"py-2 h2-mobile"},[_vm._v(" タスク色とアイコンは以下のように設定されています。 ")]),_c('v-list',{staticClass:"explain"},[_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: '#EBEBEB',
              maxWidth: '22%',
              marginRight: '8%',
              height: '25px',
            })},[_c('v-list-item-title',{staticClass:"ml-2"})],1),_c('v-list-item-content',[_vm._v("定常業務として登録されているタスク")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: '#8b8a8a',
              maxWidth: '22%',
              marginRight: '8%',
              height: '25px',
            })},[_c('v-list-item-title',{staticClass:"ml-2"})],1),_c('v-list-item-content',[_vm._v("月次予定に入れていたが実施しなかったタスク")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: '#f38282',
              maxWidth: '22%',
              marginRight: '8%',
              height: '25px',
            })},[_c('v-list-item-title',{staticClass:"ml-2"})],1),_c('v-list-item-content',[_c('div',[_vm._v("「変更後予定工数/回」の1.2倍以上の工数が")]),_c('div',{staticClass:"mt-2"},[_vm._v(" かかってしまった「実績工数/回」 ")])])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: '#fff8b7',
              maxWidth: '22%',
              marginRight: '8%',
              height: '25px',
            })},[_c('v-list-item-title',{staticClass:"ml-2"})],1),_c('v-list-item-content',[_c('div',[_vm._v("月次予定の登録内容から変更があった項目")])])],1)],1),_c('div',{staticClass:"divider"},[_c('v-divider')],1),_c('v-list',{staticClass:"explain"},[_c('v-list-item',[_c('v-list-item-content',{style:({ maxWidth: '22%', marginRight: '8%' })},[_c('v-icon',[_vm._v("$remandtask")])],1),_c('v-list-item-content',[_vm._v(" 差し戻しタスク ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }