<template>
  <v-navigation-drawer right width="auto" class="MonthlyCheckListMain">
    <v-list-item class="px-2 mt-4 title-container">
      <v-row>
        <v-col cols="12" class="icon-title">
          <v-btn icon>
            <v-icon large>$check</v-icon>
          </v-btn>
          <div class="title">
            {{ isSelf() ? '提出前チェックリスト' : '承認前チェックリスト' }}
          </div>
        </v-col>
      </v-row>
    </v-list-item>
    <div class="checklist-container">
      <ul>
        <li v-for="(item) in monthlyWording" :key="item.item_no">
          <div>{{ item.wording }}</div>
          <div class="checkbox-container">
            <div v-for="(check) in item.checklist" :key="`${item.item_no}-${check.index_no}`">
              <input
                type="checkbox"
                :id="`${item.item_no}-${check.index_no}`"
                :value="check.index_no"
                :checked="check.selected"
                @change="updateMonthlyWording(item.item_no, check.index_no, $event.target.checked)"
              />
              <label :for="`${item.item_no}-${check.index_no}`">
                {{ check.wording }}
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="comment-container">
      <v-textarea
        v-model="localComment"
        class="comment"
        clearable
        no-resize
        clear-icon="mdi-close"
        placeholder="コメントを追加"
        rows="4"
        maxlength="512"
        counter
        @input="checkCharacterCount()"
      ></v-textarea>
    </div>

    <div class="button-container">
      <button @click="confirm()">キャンセル</button>
      <button class="bold" @click="submit(1)">下書きとして保存</button>

      <v-tooltip top :disabled="!isButtonDisabled">
        <template v-slot:activator="{ on, bind }">
          <div class="submit-button-container" v-bind="bind" v-on="on">
            <button :disabled="isButtonDisabled" @click="submit(0)">
              <v-icon class="blue--text">
                $submitorconfirm
              </v-icon>
              {{ isSelf() ? '提出' : '確認' }}
            </button>
          </div>
        </template>
        <div>
          チェックリストが確認されていません
        </div>
      </v-tooltip>
    </div>
  </v-navigation-drawer>
</template>

<script>
import * as SystemConstant from "../../../constants/system";

export default {
  props: {
    phaseId: {
      type: Number,
      required: true
    },
    monthlyWording: {
      type: Array,
      required: true
    },
    comment: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      isButtonDisabled: true,
      newInput: false,
      originalComment: null,
      localComment: this.comment || '',
    }
  },
  watch: {
    comment(newComment) {
      this.localComment = newComment;
    }
  },
  updated() {
    if (!this.originalComment) {
      this.originalComment = this.localComment;
    }
  },
  methods: {
    isSelf() {
      return this.$root.$refs.monthlySchedulesComponent.isSelf();
    },
    scrollToTopOfClass(className) {
      const elements = document.getElementsByClassName(className);
      if (elements.length > 0) {
        const element = elements[0];
        element.scrollTop = 0;
      }
    },
    initializeSelected() {
      this.monthlyWording.forEach((item) => {
        if ('selected_index' in item) {
          delete item.selected_index;
        }
        item.checklist.forEach((check) => {
          if ('selected' in check) {
            this.$set(check, 'selected', false);
          }
        });
      });
      this.scrollToTopOfClass('checklist-container');
      this.isButtonDisabled = true;
      this.newInput = false;
      this.originalComment = null;
      this.localComment = '';
    },
    updateMonthlyWording(item_no, index_no, checked) {
      this.monthlyWording.forEach((item) => {
        if (item.item_no === item_no) {
          if (checked) {
            item.selected_index = index_no;
          } else {
            delete item.selected_index;
          }

          item.checklist.forEach((check) => {
            this.$set(check, 'selected', check.index_no === index_no);
          });
        }
      });
      this.controlButton();
      this.$emit('update-monthly-wording', this.monthlyWording);
    },
    controlButton() {
      const isAllChecked = this.monthlyWording.every(item => typeof item.selected_index !== 'undefined');
      this.isButtonDisabled = !isAllChecked;
    },
    checkCharacterCount() {
      this.newInput = !!this.localComment;
    },
    confirm() {
      if(this.newInput){
        this.$confirm(this.$t("label.confirm.unsave"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.discard"),
              type: "primary",
            },
          },
        })
        .then(() => {
          this.close();
        })
        .catch(() => {});
      } else {
        this.close();
      }
    },
    close() {
      this.initializeSelected();
      this.localComment = this.comment;
      this.$emit("close");
    },
    submit(draft_flag) {
      const getPhaseType = (phaseId) => {
        switch (phaseId) {
          case SystemConstant.PHASE_ID_UNSUBMITTED:
          case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            return SystemConstant.PHASE_TYPE_SUBMISSION;
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            return SystemConstant.PHASE_TYPE_APPROVAL;
          default:
            return "";
        }
      };

      let params = {
        phase_type: getPhaseType(this.phaseId),
        comment: this.localComment,
        draft_flag: draft_flag,
      }
      this.initializeSelected();
      this.$emit("submit", params);
    }
  }
}
</script>

<style>
.MonthlyCheckListMain {
  font-size: 14px;
  padding: 0 10px;
}

.MonthlyCheckListMain .title {
  font-size: 16px;
}

.MonthlyCheckListMain .title-container {
  margin-bottom: 15px;
}

.MonthlyCheckListMain .icon-title {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.MonthlyCheckListMain .comment-container {
  display: flex;
  flex-direction: column;
  color: #7F7F7F;
}

.MonthlyCheckListMain .comment {
  margin-left: 24px;
  resize: none;
}

.MonthlyCheckListMain .checklist-container {
  height: calc(
    (var(--vh, 1vh) * 100) - 400px
  );
  overflow-x: hidden;
  margin-bottom: 20px;
}

.MonthlyCheckListMain .checkbox-container {
  display: flex;
  column-gap: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.MonthlyCheckListMain .checkbox-container input[type="checkbox"] {
  margin-right: 3px;
}

.MonthlyCheckListMain .button-container {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
}

.MonthlyCheckListMain .button-container > button {
  border: solid 1px #7F7F7F;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 12px;
}

.MonthlyCheckListMain .submit-button-container {
  border: solid 1px #7F7F7F;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 12px;
}

.MonthlyCheckListMain .bold {
  font-weight: 700;
}

.MonthlyCheckListMain button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
</style>
