<template>
  <v-dialog persistent :value="openDialog" max-width="300px">
    <v-card class="py-3">
      <v-card class="mx-5 py-0" color="primary" dark>
          <v-card-title>CSVエクスポート設定 </v-card-title>
      </v-card>
      <v-card-text class="mt-5">
        <v-radio-group v-model="radioExport">
        <v-radio value="2" :label="'全てのレポート'"></v-radio>
        <v-radio value="1" :label="'サマリーデータ'"></v-radio>
        <v-radio value="0" :label="'タスク別工数'"></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn tile color="ligth darken-1" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          tile
          :disabled="isDisable"
          color="primary darken-1"
          @click="submit()"
          >エクスポート</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { MONTH_ACTUAL_CSV_EXPORT_API } from "../../../constants/api";
import { FC850 } from "../../../constants/fc";
import { apiErrorMessages } from "../../../helpers/messages";
import { debounce_leading, exportShiftJIS } from "../../../helpers/helpers";
import axios from "axios";
export default {
  props: {
    show: Boolean,
    groupId: String,
    userId: String,
    targetMonth: String,
    actualMonthId: String,
    workingHours: String,												
    changedMonthlyPlannedManHours: String,												
    monthlyActualManHours: String,												
    standardTaskRate: String,												
    planExecutionEate: String,												
    productivity: String,												
    isCompleted: String,												
  },
  watch: {
    openDialog: {
      handler() {
        // this.openDialog = true;
        this.radioExport = "3";
      },
    },
  },
  data() {
    return {
      openDialog: false,
      radioExport: "3",
    };
  },
  computed: {
    isDisable() {
      return !(+this.radioExport === 2 || +this.radioExport === 1 || +this.radioExport === 0);
    },
  },
  methods: {
    showDialog(value) {
      this.openDialog = value;
    },
    submit: debounce_leading(async function () {

      const query =
        {
          user_id: this.userId,
          target_month: this.targetMonth,
          working_hours: this.workingHours,											
          changed_monthly_planned_man_hours: this.changedMonthlyPlannedManHours,											
          monthly_actual_man_hours: this.monthlyActualManHours,											
          standard_task_rate: this.standardTaskRate,											
          plan_execution_rate: this.planExecutionEate,											
          productivity: this.productivity,											
          is_completed: this.isCompleted,											
        }
        switch(this.radioExport){
          case "2": 
            query.is_all = 1;
            break;
          case "1": 
            query.is_summary = 1;
            break;
          case "0": 
            query.is_man_hours = 1;
            break;
        }
      try {
        const { data, headers } = await axios.get(
          MONTH_ACTUAL_CSV_EXPORT_API(query),
          {
            responseType: "blob",
          }
        );
        const headerval = headers["content-disposition"];
        let filename = headerval
          .split(";")[2]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");
          filename = decodeURI(filename).substring("utf-8''".length);
        exportShiftJIS(data, filename);
        this.openDialog = false;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC850, status);
      }
    }),
    close() {
      this.openDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
