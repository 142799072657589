<template>
  <v-navigation-drawer v-model="show" right width="auto">
    <v-row class="px-2 mt-4">
      <v-col cols="12" class="v-center pl-5">月次予定タスク</v-col>
    </v-row>
    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="10">
          <LargeCategory
            :selectetedObject="selectedCategory"
            :categories="largeCategories"
            @change="changePrimaryCategory"
          >
          </LargeCategory>
        </v-col>

        <v-col cols="2">
          <v-btn icon @click="close()">
            <v-icon small>$close</v-icon>
          </v-btn></v-col
        >
      </v-row>
    </v-list-item>
    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="7">
          <v-text-field
            solo
            placeholder="検索"
            maxlength="32"
            @change="handleSearch"
            clearable
            v-model="search"
          >
            <template>
              <v-icon @click="handleSearch" small slot="prepend-inner">
                $search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-list-item>

    <v-divider></v-divider>

    <div
      class="text-center"
      v-if="
        !(smallCategories && smallCategories.length > 0) &&
        !isEmptySearch(this.search)
      "
    >
      <p class="gray--text">検索条件に一致するタスクは</p>
      <p class="gray--text">見つかりませんでした。</p>
    </div>

    <div class="d-flex justify-center" v-if="isLoading">
      <svg
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        stroke="#000"
        class="sidebar-loader"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
    <div class="content overflow-y-auto" v-else>
      <v-treeview
        v-if="smallCategories && smallCategories.length > 0"
        class="custom-tree"
        id="unfavoriteTree"
        ref="unfavoriteTree"
        :items="smallCategories"
        item-key="treeId"
        :open="filteredKeys"
        selected-color="indigo"
        open-on-click
        expand-icon="mdi-chevron-down"
      >
        <template v-slot:label="{ item, leaf }">
          <DoubleClick
            v-if="leaf"
            @double-click="isAllowDrag() && registerTask(item)"
            @single-click="view(item)"
            :class="[{ 'monthly-task': isAllowDrag() }]"
            :name="item.id"
          >
            <v-row>
              <v-col cols="12"
                :class="[{'archiveTask-class': item.is_archive}]"
                @mouseover="showIcon(item.id)" 
                @mouseleave="hideIcon()"
              >
                <v-row>
                  <v-col cols="8">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on">
                          <div class="d-flex align-center flex-wrap">
                            <div class="text-truncate mr-1">
                              {{ item.name }}
                              <span v-if="item.detail">
                              _{{item.detail}}
                            </span>
                            </div>
                            <BeginnerIcon v-if="isBeginnerSkill(item) && !item.is_archive" />
                            <ExpertIcon v-if="isExpertSkill(item) && !item.is_archive" />
                          </div>
                        </v-list-item-title>
                      </template>
                      <span>{{ item.name }}</span>
                      <span v-if="item.detail">_{{item.detail}}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <div v-if="isHovered(item.id)" class="icons">
                      <v-icon small
                        @click="changeTaskArchive(item)"
                        > $archive </v-icon>
                      <v-icon small 
                        @click="archiveCancel(item)"
                        > $remandtask </v-icon>
                    </div>
                    <span>{{ item.cost }}m/回</span></v-col
                  >
                </v-row>

              </v-col>
            </v-row>
          </DoubleClick>

          <div v-else>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  item.category_secondary_name
                }}</span>
              </template>
              <span>{{ item.category_secondary_name }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-treeview>

      <MonthlyTask
        :planId="planId"
        :selectMonthDate="selectMonthDate"
        :show="showMonthlyTask"
        :scheduleTaskMonthId="scheduleTaskMonthId"
      ></MonthlyTask>
    </div>
  </v-navigation-drawer>
</template>
  
<script>
import LargeCategory from "./LargeCategory";
import MonthlyTask from "./MonthlyTask";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { apiErrorMessages } from "../../../helpers/messages";
import { GET_MONTHLY_SCHEDULES_TASK_API, UPDATE_MONTHLY_SCHEDULES_TASK_API } from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import { FC843, FC844} from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DoubleClick from "../../../components/DoubleClick";
import BeginnerIcon from "@/components/icons/BeginnerIcon.vue";
import ExpertIcon from "@/components/icons/ExpertIcon.vue";
import * as systemAuth from "@/constants/role_code";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: {
    LargeCategory,
    MonthlyTask,
    DoubleClick,
    BeginnerIcon,
    ExpertIcon,
  },
  mixins: [taskMixin],
  props: {
    value: Boolean,
    planId: String,
    selectMonthDate: String,
    isPlan: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
    },
    show: {
      async handler(val) {
        this.$emit("input", val);
      },
    },
    iselectedDate: {
      handler(val) {
        this.selectedDate = val;
      },
    },
  },
  methods: {
    registerTask(e) {
      e.count = 1;
      if (this.isPlan) {
        this.$root.$refs.scheduleComponent.addEventFromMonthlyList(e);
      } else {
        this.$root.$refs.actualComponent.addEventFromMonthlyList(e);
      }
    },
    isBeginnerSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_BEGIN;
    },
    isExpertSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_EXPERT;
    },
    handleSearch() {
      const searchConvert = this.convertSearchKey(this.search);
      let categories =
        this.selectedCategory.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) =>
                x.category_primary_id ===
                this.selectedCategory.category_primary_id
            );
      this.smallCategories = categories
        .map((x) => {
          let children = x.children.filter(
            (y) =>
              (y.name.includes(this.search) ||
              isEmpty(this.search)) ||
              (this.convertSearchKey(y.name).includes(searchConvert))
          );
          return {
            ...x,
            ...{ children: children },
          };
        })
        .filter((x) => x.children && x.children.length > 0);

      if (isEmpty(this.search)) {
        this.filteredKeys = [];
        this.filteredKey = [];
      } else {
        this.filteredKeys = this.smallCategories.map((top) => {
          return top.treeId;
        });
      }
    },
    view(item) {
      this.scheduleTaskMonthId = item.id;
      this.showMonthlyTask = !this.showMonthlyTask;
    },
    isEmptySearch() {
      return isEmpty(this.search);
    },
    changePrimaryCategory(item) {
      this.selectedCategory = item;
      this.smallCategories =
        item.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) => x.category_primary_id === item.category_primary_id
            );
    },
    resetFilter() {
      this.changePrimaryCategory(this.selectedCategory);
    },
    submitFilter(filters) {
      let categories =
        this.selectedCategory.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) =>
                x.category_primary_id ===
                this.selectedCategory.category_primary_id
            );
      this.smallCategories = categories
        .map((x) => {
          let children = x.children.filter(!filters.some((g) => g.check)
          );
          return {
            ...x,
            ...{ children: children },
          };
        })
        .filter((x) => x.children && x.children.length > 0);

      if (!filters.some((g) => g.check)) {
        this.filteredKeys = [];
        this.filteredKey = [];
      } else {
        this.filteredKeys = this.smallCategories.map((top) => {
          return top.treeId;
        });
      }
    },

    getMonthlytaskList() {
      return this.monthlytasks;
    },

    getTargetMonth() {
      let dateParts = this.selectMonthDate.split('-');
      let year = dateParts[0];
      let month = dateParts[1];
      let convertedDate = year + month;
      return convertedDate;
    },

    async getData() {
      // this.isLoading = true;
      this.search = "";

      let user_id = this.user.user_id;
      let target_month = this.getTargetMonth();

      try {
        const { data, status } = await axios.get(GET_MONTHLY_SCHEDULES_TASK_API(
          user_id,
          target_month,
        ));
        this.monthlytasks = status === STATUS_NO_CONTENT ? [] : data.result;
        let primaryCategories = [
          {
            category_primary_id: 0,
            category_primary_name: "すべてのカテゴリ",
          },
        ];
        let secondaryCategories = [];
        let index = 0;
        this.monthlytasks.forEach((monthlytask) => {
          if (
            !primaryCategories.some((x) => {
              return x.category_primary_id === monthlytask.category_primary_id;
            })
          ) {
            primaryCategories.push({
              category_primary_id: monthlytask.category_primary_id,
              category_primary_name: monthlytask.category_primary_name,
            });
          }

          monthlytask.treeId = index++;
          monthlytask.count = 1;

          if (
            !secondaryCategories.some((x) => {
              return x.category_secondary_id === monthlytask.category_secondary_id;
            })
          ) {
            secondaryCategories.push({
              category_primary_name: monthlytask.category_primary_name,
              category_primary_id: monthlytask.category_primary_id,
              category_secondary_name: monthlytask.category_secondary_name,
              category_secondary_id: monthlytask.category_secondary_id,
              treeId: index++,
              children: [monthlytask],
            });
          } else {
            secondaryCategories
              .filter((x) => {
                return x.category_secondary_id === monthlytask.category_secondary_id;
              })[0]
              .children.push(monthlytask);
          }
        });
        this.smallCategories = secondaryCategories;
        this.smallDbCategories = deepClone(secondaryCategories);
        this.largeCategories = primaryCategories;
        this.selectedCategory = primaryCategories[0];
        this.changePrimaryCategory(this.selectedCategory);
        if (this.$refs.unfavoriteTree)
          this.$refs.unfavoriteTree.updateAll(false);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC843, status);
      } finally {
        this.isLoading = false;
      }
    },
    isAllowDrag() {
      if (this.isPlan) {
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },
    
    close() {
      this.show = false;
      this.$emit("close");
    },

    showIcon(taskId) {
      this.taskIdkey = taskId;
    },
    hideIcon() {
      this.taskIdkey = "";
    },
    isHovered(taskId) {
      if (this.taskIdkey === taskId) {
        return true;
      }else {
        return false;
      }
    },

    async changeTaskArchive(item) {
      if (item.is_archive){
        event.stopPropagation();
        return;
      }

      try {
        event.stopPropagation();
        await axios.put(UPDATE_MONTHLY_SCHEDULES_TASK_API(item.schedule_id ,item.id),{
          is_archive: 1,
        });
        this.getData();
        
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC844, status);
      }
    },
    async archiveCancel(item) {
      if(!item.is_archive) {
        event.stopPropagation();
        return;
      }

      try {
        event.stopPropagation();
        await axios.put(UPDATE_MONTHLY_SCHEDULES_TASK_API(item.schedule_id ,item.id),{
          is_archive: 0,
        });
        this.getData();

      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC844, status);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      standarId: null,
      search: "",
      show: false,
      showMonthlyTask: false,
      largeCategories: [],
      filters: [],
      smallCategories: [],
      smallDbCategories: [],
      selectedCategory: {},
      monthlytasks: [],
      isLoading: false,
      filteredKeys: [],
      filteredKey: [],
      taskIdkey:[],
      scheduleTaskMonthId: null,
    };
  },
  created() {
    this.$root.$refs.scheduleMonthlyList = this;
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: calc((var(--vh, 1vh) * 100) - 50px - 60px - 48px - 66px - 24px - 25px);
  overflow-x: hidden;
  ::v-deep.custom-tree {
    .v-treeview-node {
      position: relative;
      .v-treeview-node__children {
        .v-treeview-node__content {
          margin-left: -8%;
          padding-top: 5px !important;
          padding-bottom: 12px !important;
          border-bottom: solid 1px #e1e1e1;
          .v-treeview-node__label > div {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .v-treeview-node__prepend {
      min-height: 24px;
    }
    .v-treeview-node__label {
      line-height: 24px;
    }
    > .v-treeview-node {
      > .v-treeview-node__root {
        border-bottom: solid 1px #e1e1e1;
        .v-treeview-node__content {
          border-bottom: unset;
          .v-treeview-node__label {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.sidebar-loader {
  margin-top: 10px;
}

.icons {
  position: absolute;
  display: flex;
  right: 60px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 5px;
  width: 55px;
  min-width: 55px;
  max-width: 55px;
}

.archiveTask-class{
  position:  absolute;
  background-color: #D9D9D9;
  text-indent: 10px;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}
</style>
