<template>
  <v-dialog v-model="openCategory" persistent max-width="600px" scrollable>
    <v-card>
      <v-card-text class="card-content">
        <v-row align="center">
          <v-col class="text-left" cols="4">小カテゴリ<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="8">
            <v-text-field
              maxlength="100"
              v-model="category.category_secondary_name"
              :error-messages="secondaryNameErrors"
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="ml-auto">
        <v-btn @click="close" outlined depressed color="light">
          キャンセル
        </v-btn>
        <v-btn depressed color="primary" @click="save()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {
  SECONDARY_CATEGORY_API,
} from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC703 } from "../../../constants/fc";
import {
  debounce_leading,
  isEmptyNumber,
} from "@/helpers/helpers";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin, backMixin],
  validations: {
    category: {
      category_secondary_name: { required },
    },
  },
  props: {
    show: Boolean,
    largeCategoryId: {
      type: Number,
      required: true
    },
    smallCategoryName: {
      type: String,
      required: true
    },
    smallCategoryId: {
      type: Number,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),

    secondaryNameErrors() {
      const errors = [];
      if (this.$v.category.category_secondary_name.$dirty) {
        !this.$v.category.category_secondary_name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
  },
  watch: {
    show: {
      handler() {
        this.isEditing = false;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
        this.openCategory = true;
        this.category = {};
        this.category = this.oldCategory;
        this.$v.$reset();
      },
    },
    smallCategoryName: {
      deep: true,
      async handler() {
        this.oldCategory = {
          category_secondary_name: this.smallCategoryName,
        };
        this.category = {
          category_secondary_name: this.smallCategoryName,
        };
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    category: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  data() {
    return {
      category: {},
      openCategory: false,
      parentCategories: [],
      departments: [],
      groupSelecteds: [],
      selectedDepartments: [],
      oldCategory: {},
      openMenu: false,
      isEditing: false,
    };
  },
  methods: {
    compareValue() {
      return (
        JSON.stringify({
          category_secondary_name: this.oldCategory.category_secondary_name,
        }) !==
        JSON.stringify({
          category_secondary_name: this.category.category_secondary_name,
        })
      );
    },
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openCategory = false;
          }, 100);
        },
        () => {}
      );
    },

    save: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          let params = {
            name: this.category.category_secondary_name,
            primary_id: this.largeCategoryId,
            user_id: this.userId,
            group_id: this.groupId,
            is_changeability: 1,
          };
          if (this.smallCategoryId) {
            await axios.put(
              SECONDARY_CATEGORY_API(this.smallCategoryId),
              params
            );
          }

          if(this.$root.$refs.monthlySchedulesComponent){
            this.updateSmallCategory();
          } else if (this.$root.$refs.monthlyActualsComponent) {
            this.updateSmallCategoryByActual();
          }
          
          this.$toast(
            `${this.category.category_secondary_name}に変更しました。`
          );
          if (isEmptyNumber(this.category.category_secondary_id)) {
            this.category.category_secondary_id = this.smallCategoryId;
          }
          this.openCategory = false;
          this.$emit("closeEvent" ,this.category);
      } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.$alert(error.response.data.message);
          } else {
            apiErrorMessages(this, FC703, status);
          }
        }
      }
    }),

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openCategory = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
    updateSmallCategory() {
      const smallCategories = this.$root.$refs.monthlySchedulesComponent.childCategories;
      const index = smallCategories.findIndex(
        (e) => e.value === this.smallCategoryId
      );

      smallCategories[index].text = this.category.category_secondary_name;
      this.$root.$refs.monthlySchedulesComponent.childCategories = smallCategories;
    },

    updateSmallCategoryByActual() {
      const smallCategories = this.$root.$refs.monthlyActualsComponent.childCategories;
      const index = smallCategories.findIndex(
        (e) => e.value === this.smallCategoryId
      );

      smallCategories[index].text = this.category.category_secondary_name;
      this.$root.$refs.monthlyActualsComponent.childCategories = smallCategories;
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style>
</style>
