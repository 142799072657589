<template>
  <v-dialog persistent :value="openDialog" max-width="500px">
    <v-card v-if="!isInputComment" class="py-3">
      <v-card-title>提出を取り消しますか？ </v-card-title>

      <v-card-actions class="justify-end">
        <v-btn tile color="ligth darken-1" @click="close()">
          {{ $t("label.buttons.cancel") }}
        </v-btn>
        <v-btn
          tile
          color="primary darken-1"
          @click="submit()"
        >
          取り消す
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="py-3">
      <v-card-title>月次予定 </v-card-title>

      <div class="MonthlyCancelSubmit">
        <div>
          <div class="comment-container">
            <label>▼提出を取り消す理由</label>
            <v-textarea
              v-model="comment"
              class="comment"
              clearable
              no-resize
              clear-icon="mdi-close"
              placeholder="コメントを追加"
              rows="4"
              maxlength="512"
              counter
              @input="checkCharacterCount($event)" 
            ></v-textarea>
          </div>
        </div>
        <div class="button-container">
          <v-btn @click="confirm()">キャンセル</v-btn>
          <v-btn 
            :disabled="isButtonDisabled"
            @click="submitConfirm()"> 
            <v-icon class="blue--text">$submitorconfirm</v-icon>
            提出
          </v-btn>
        </div>
      </div>
    </v-card>

    
  </v-dialog>
</template>

<script>
import * as SystemConstant from "../../../constants/system";
export default {
  props: {
    show: Boolean,
    isInputComment: Boolean,
  },
  data() {
    return {
      openDialog: true,
      isButtonDisabled: true,
      comment: '',
      newInput: false,
    };
  },
  methods: {
    checkCharacterCount(value) {
      this.isButtonDisabled = value.length === 0;
      this.newInput = true;
    },
    showDialog(value) {
      this.openDialog = value;
    },
    close() {
      this.$emit("close");
    },
    submit() {
      let params = {
        phase_type: SystemConstant.PHASE_TYPE_SUBMISSION_RESET,
        comment: this.comment,
        draft_flag: 0,
      }
      this.$emit("submit", params);
    },
    submitConfirm() {
      if(this.comment.length === 0){
        this.isButtonDisabled = true
      } else {
        this.submit();
      }
    },
    confirm() {
      if(this.newInput){
        this.$confirm(this.$t("label.confirm.unsave"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.discard"),
              type: "primary",
            },
          },
        })
        .then(() => {
          this.close();
        })
        .catch(() => {});
      } else {
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.MonthlyCancelSubmit{
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  padding: 5px 25px;
}
.MonthlyCancelSubmit .comment-container {
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.MonthlyCancelSubmit .counter {
  text-align: right;
}
.MonthlyCancelSubmit .button-container {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
}
.MonthlyCancelSubmit .button-container > button {
  background: #FFFFFF;
  border: solid 1px #7F7F7F;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 12px;
}

.MonthlyCancelSubmit .comment {
  resize: none;
}

.MonthlyCancelSubmit .red-text {
  color: red;
}

</style>
