<template>
  <v-navigation-drawer right class="MonthlyCommentsHistoryMain">
    <v-list-item class="px-2 mt-4 title-container">
      <v-row>
        <v-col cols="12" class="icon-title">
          <v-btn icon>
            <v-icon large>$comment</v-icon>
          </v-btn>
          <div class="title" >コメント履歴</div>
        </v-col>
      </v-row>
    </v-list-item>
    <div 
      :class=" isInputComment ? 'comments-history-container' : 'comments-history-container-not-add-comments'"
      ref="commentsContainer"
    >
      <div v-for="(item, itemIdx) in commentsHistory.comment" :key="`comment-${itemIdx}`">
        <div v-if="item.draft_flag !== 1" class="container">
          <v-btn icon>
            <v-icon large>$accountfornoimage</v-icon>
          </v-btn>
          <div class="body">
            <div class="user">
              <div>{{ item.comment_user_name }}さん</div>
              <div>{{ formatDateTime(item.comment_date) }}</div>
            </div>
            <v-btn 
              class="phaseName"
              :class="[{ 'red-text': isReject(item.phase_id) }]"

            >
              {{ item.phase_name }}
            </v-btn>

            <template v-if="isConfimation(item.phase_id)">
              <template v-if="item.all_tasks_history && item.all_tasks_history">
                <div v-if="item.isChangeTask" class="comment-title">▼月次予定変更履歴</div>
                <template v-for="(history) in item.all_tasks_history">
                  <template v-for="(scheduleTask) in history.ScheduleTaskMonth">
                    <template v-if="scheduleTask.history && scheduleTask.history.length === 2">
                      <template 
                        v-for="(changedPartText,textIdx) in 
                          getChangedPartTexts(item,scheduleTask.history[0],scheduleTask.history[1])" 
                      >
                        <div :key="`${scheduleTask.index_no}-${textIdx}-name`">
                          {{ scheduleTask.index_no }}.{{ scheduleTask.category_secondary_name }}_{{ scheduleTask.name }}
                        </div>
                        <div class="childComment" :key="`${scheduleTask.index_no}-${textIdx}-comment`">
                          {{ changedPartText }}
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
            
            <template>
              <template v-if="item.comment">
                <v-textarea
                  v-model="item.comment"
                  rows="1"
                  auto-grow
                  readonly
                  maxlength="512"
                  class="comment-box"
                ></v-textarea>
              </template>
              <template v-if="item.fix_comment">
                <v-textarea
                  v-model="item.fix_comment"
                  rows="1"
                  auto-grow
                  readonly
                  maxlength="512"
                  class="comment-box"
                ></v-textarea>
              </template>
            </template>
            
            <template v-if="isReject(item.phase_id)">
              <template v-if="item.remand_comment">
                <div class="comment-title">▼全体に対する差し戻し</div>
                <div class="comments">
                  <v-textarea
                    v-model="item.remand_comment"
                    rows="1"
                    auto-grow
                    readonly
                    maxlength="512"
                    class="comment-box"
                  ></v-textarea>
                </div>
              </template>
              
              <template v-if="item.individual_tasks_comments && item.individual_tasks_comments.length !== 0">
                  <div class="comment-title">▼個別タスクに対する差し戻し</div>
                  <template v-for="(individualTask) in item.individual_tasks_comments">
                    <div v-for="(scheduleTask) in individualTask.ScheduleTaskMonth" 
                      :key="`task-${scheduleTask.index_no}`"
                    >
                      <div>
                        <div>{{ scheduleTask.index_no }}.{{ scheduleTask.category_secondary_name }}_{{ scheduleTask.name }}</div>
                        <div v-if="individualTask.comment && individualTask.comment.length !== 0" class="childComment">
                          <div class="comments">
                            <v-textarea
                              v-model="individualTask.comment"
                              rows="1"
                              auto-grow
                              readonly
                              maxlength="512"
                              class="comment-box"
                            ></v-textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
              </template>
            </template>
          </div>
        </div>
      </div>
      <template v-if="isInputComment && isSelf() && isReject(lastComment.phase_id)">
        <div class="container">
          <v-btn icon>
            <v-icon large>$accountfornoimage</v-icon>
          </v-btn>
          <div class="body">
            <div class="user">
              <div>
                {{ commentsHistory.comment[0].comment_user_name }}さん 
              </div>
              <div>{{ getNow() }}</div>
            </div>
            <v-btn class="phaseName">{{ getNextPahse(lastComment.phase_id) }}</v-btn>
            <div class="comment-title">▼月次予定変更履歴</div>
            <template v-if="lastComment.all_tasks_history">
              <template v-for="(history) in lastComment.all_tasks_history">
                <template v-for="(scheduleTask) in history.ScheduleTaskMonth">
                  <template v-if="scheduleTask.history && scheduleTask.history.length === 2">
                    <template 
                      v-for="(changedPartText,textIdx) in 
                        getChangedPartTexts(lastComment,scheduleTask.history[0],scheduleTask.history[1])" 
                    >
                      <div :key="`${scheduleTask.index_no}-${textIdx}-name`">
                        {{ scheduleTask.index_no }}.{{ scheduleTask.category_secondary_name }}_{{ scheduleTask.name }}
                      </div>
                      <div class="childComment" :key="`${scheduleTask.index_no}-${textIdx}-comment`">
                        {{ changedPartText }}
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div v-if="isInputComment">
      <div class="comment-container">
        <v-textarea
          v-model="fix_comment"
          class="comment"
          clearable
          no-resize
          clear-icon="mdi-close"
          placeholder="上長への伝達事項があれば記入"
          rows="4"
          maxlength="512"
          counter
          @input="checkCharacterCount($event)" 
        ></v-textarea>
      </div>
    </div>
    <div class="button-container">
      <button @click="confirm()">キャンセル</button>
      <button  v-if="isInputComment" class="bold" @click="submit(1)">下書きとして保存</button>
      <button 
        v-if="isInputComment" 
        @click="submit(0)"> 
        <v-icon class="blue--text">$submitorconfirm</v-icon>
        提出
      </button>
    </div>
  </v-navigation-drawer>
</template>

<script>
import * as SystemConstant from "../../../constants/system";
export default {
  props: {
    commentsHistory: {
      type: Object,
      required: true
    },
    isInputComment: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      isButtonDisabled: true,
      fix_comment: '',
      lastComment: {},
      newInput: false,
      originalcomment: null,
    }
  },
  watch: {
    commentsHistory: {
      handler(newVal) {
        this.updateLastComment(newVal);
        this.sclrollToBottom();
      },
      deep: true,
      immediate: true
    }
  },
  updated() {
    if (!this.originalcomment) {
      this.originalcomment = this.fix_comment;
    }
  },
  methods: {
    isSelf() {
      return this.$root.$refs.monthlySchedulesComponent.isSelf();
    },
    updateLastComment(commentsHistory) {
      if (commentsHistory.comment && commentsHistory.comment.length > 0) {
        this.lastComment = commentsHistory.comment[commentsHistory.comment.length - 1];
        this.fix_comment = this.lastComment.fix_comment;
      }
    },
    sclrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          const container = this.$refs.commentsContainer;
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        }, 100); 
      });
    },
    isConfimation(phase_id){
      return phase_id === SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING || phase_id === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
    },
    isReject(phase_id){
      return phase_id === SystemConstant.PHASE_ID_PRIMARY_REJECT || phase_id === SystemConstant.PHASE_ID_2_NEXT_REJECT
    },
    getChangedPartTexts(comment, oldHistory, newHistory){
      const texts = [];
      if(oldHistory.cost !== newHistory.cost){
        texts.push(`工数を${oldHistory.cost}から${newHistory.cost}に変更しました。`)
      }
      if(oldHistory.count !== newHistory.count){
        texts.push(`回数を${oldHistory.count}から${newHistory.count}に変更しました。`)
      }
      if(
        oldHistory.category_secondary_id !== newHistory.category_secondary_id || 
        oldHistory.category_secondary_name !== newHistory.category_secondary_name
      ){
        texts.push(`小カテゴリ名称を${oldHistory.category_secondary_name}から${newHistory.category_secondary_name}に変更しました。`)
      }
      if(oldHistory.name !== newHistory.name){
        texts.push(`タスク名称を${oldHistory.name}から${newHistory.name}に変更しました。`)
      }
      this.$set(comment, 'isChangeTask', comment.isChangeTask ? true : texts.length > 0);
      return texts;
    },
    getNextPahse(phase_id){
      if(phase_id === SystemConstant.PHASE_ID_PRIMARY_REJECT){
        return '1次確認待ち';
      }else if(phase_id === 35){
        return '2次確認待ち';
      }
      return;
    },
    getNow(){
      // 現在の日時をUTCで取得
      const now = new Date();
      // 日本のタイムゾーンに変換 (UTC+9)
      const isoString = new Date(now.getTime() + 9 * 60 * 60 * 1000).toISOString().slice(0, 19).replace('T', ' ');
      return this.formatDateTime(isoString);
    },
    formatDateTime(dateTimeString) {
      // 文字列をDateオブジェクトに変換
      const date = new Date(dateTimeString.replace(' ', 'T'));

      // 年、月、日、時間、分を取得
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      // 曜日を取得し、対応する日本語の略称に変換
      const weekDays = ['日', '月', '火', '水', '木', '金', '土'];
      const dayOfWeek = weekDays[date.getDay()];

      // フォーマットされた日時を作成
      const formattedDateTime = `${year}/${month}/${day}(${dayOfWeek}) ${hours}:${minutes}`;

      return formattedDateTime;
    },

    checkCharacterCount(value) {
      this.newInput = false;

      if ((this.fix_comment !== this.originalcomment || this.fix_comment === this.originalcomment) && this.fix_comment) {
        this.newInput = true;  
      } else {
        this.newInput = false;
      }

      this.isButtonDisabled = value.length === 0;
    },
    confirm() {
      if(this.newInput && this.isInputComment){
        this.$confirm(this.$t("label.confirm.unsave"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.discard"),
              type: "primary",
            },
          },
        })
        .then(() => {
          this.close();
        })
        .catch(() => {});
      } else {
        this.close();
      }
    },
    close() {
      this.newInput = false;
      this.originalcomment = null;
      this.$emit("close");
    },
    submit(draft_flag) {
      let params = {
        phase_type: SystemConstant.PHASE_TYPE_SUBMISSION,
        fix_comment: this.fix_comment,
        draft_flag: draft_flag,
      }
      this.newInput = false;
      this.originalcomment = null;
      this.$emit("submit", params);
    },
  }
}
</script>

<style lang="scss">
.MonthlyCommentsHistoryMain {
  font-size: 14px;
  padding: 0 10px;
  width: 100% !important;
  div {
    color: black;
  }
  .title {
    font-size: 16px;
  }
  .title-container {
    margin-bottom: 15px;
  }
  .phaseName {
    width: 150px;
  }
  .icon-title {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  .comments-history-container {
    height: calc(
      (var(--vh, 1vh) * 100) - 400px
    );
    overflow-x: hidden;
    margin-bottom: 20px;
  }
  .comments-history-container-not-add-comments {
    height: calc(
      (var(--vh, 1vh) * 100) - 64px - 72px - 60px - 48px - 30px
    );
    overflow-x: hidden;
    margin-bottom: 20px;
  }
  .container {
    display: flex;
    color: #7F7F7F;
    column-gap: 15px;
  }
  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: black;
  }
  .comment-title {
    color: black;
    font-weight: 700;
  }
  .user {
    display: flex;
    flex-direction: column;
    
  }
  .comment,
  .childComment {
    margin-left: 24px;
    resize: none;
  }
  .counter {
    text-align: right;
  }
  .comment-container {
    display: flex;
    flex-direction: column;
    column-gap: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    margin-top: 10px;
  }
  .button-container > button {
    border: solid 1px #7F7F7F;
    border-radius: 4px;
    padding: 2px 10px;
    font-size: 12px;
  }
  .red-text {
    color: red;
  }
  .bold {
    font-weight: 700;
  }
  button[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  .comment-box {
    font-size: 14px;
    pointer-events: none;
  }
  .comment-box .v-input__control .v-input__slot::before {
    border: none !important;
  }
  .comments {
    white-space: pre-line;
    overflow: visible;
    word-break: break-word;
    font-size: 14px;
    pointer-events: none;
  }
}
</style>
