<template>
  <div class="popup">
    <div class="popup-content">
      <!-- ポップアップのコンテンツをここに追加 -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  beforeDestroy() {
    // コンポーネントが破棄されるときにイベントリスナーを削除する
    document.removeEventListener('click', this.closePopup);
  },
  methods: {
    closePopup(event) {
      // ポップアップの外側をクリックしたらポップアップを閉じる
      if (!this.$el.contains(event.target)) {
        this.$emit('close');
      }
    }
  }
}
</script>

<style scoped>
.popup {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.popup-content {
  max-height: 304px;
  max-width: 80%;
  position: absolute;
  background-color: #ffffff;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: #000000;
  font-size: large;
  overflow-y: scroll;
}
</style>