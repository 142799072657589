<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
  >
    <v-card class="hidden">
      <v-card-title class="card-header py-2">
        <v-btn @click="openDbTask = false" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="py-4">
          <span class="ml-9 mb-n4"> {{ item.category_secondary_name }}</span>
          <v-col cols="12 d-flex align-center">
            <div>
              <v-icon v-if="item.is_favorite" small class="yellow--text"
                >$favoriteactive</v-icon
              >
              <v-icon v-else small class="yellow--text"
                >$favoriteinactive</v-icon
              >
            </div>
            <span class="ml-2 work-break">{{ item.name }}</span>
            <span :class="[{ detail: !isDetailEmpty() }]">{{
              item.detail
            }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-2">
          <v-col class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ item.description }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ item.category_primary_name }}</span>
            </div>
            <div class="mt-2 ml-5 category">
              <span class="ml-2"> {{ item.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <!--<v-row class="py-0 my-0 d-flex align-baseline">
          <v-col cols="3" class="py-0">
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2 w-120">日次実績</span></v-col
          >
          <v-col cols="9" class="py-0 d-flex align-baseline">
            <v-text-field
              class="w-95"
              :error-messages="costErrors"
              suffix="m/回"
              type="number"
              max="999"
              min="1"
              readonly
              v-model="item.cost"
            >
            </v-text-field>
            <span class="mx-2">×</span>
            <v-text-field
              class="w-60"
              type="number"
              max="999"
              min="1"
              :error-messages="countErrors"
              suffix="回"
              readonly
              v-model="item.count"
            >
            </v-text-field>
            <span class="mx-2">=</span>
            <v-text-field
              class="w-95"
              readonly
              type="number"
              :value="item.cost * item.count"
              suffix="m"
            ></v-text-field
          ></v-col> -->
        <v-row>
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ item.task_type_name || '' }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="align-center">
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">標準工数</span>
              <div class="ml-3 d-flex align-center">
                <span>{{ item.cost }}m/回</span>
                <span class="mx-2">×</span>
                <span>{{ item.count }}回</span>
                <span class="mx-2">=</span>
                <span>{{ item.cost * item.count }}m</span>
              </div>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ item.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <v-text-field
                v-model="item.start_time"
                :error-messages="startTimeErrors"
                maxlength="5"
                type="text"
                @input="changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
              >
              </v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                v-model="item.end_time"
                :error-messages="endTimeErrors"
                maxlength="5"
                readonly
                type="text"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="py-0">
            <div class="header d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="ml-2">アウトプット</span>
              <v-checkbox
                v-model="item.is_need_output"
                class="ml-3"
                :label="!!item.is_need_output ? '必要' : '不要'"
                readonly
              ></v-checkbox>
            </div>
            <div class="mt-2 ml-2 category" v-if="!!item.is_need_output">
              アウトプット内容： {{ item.output_detail }}
            </div>
          </v-col>
        </v-row> -->

        <!--<v-row class="py-2">
          <v-col cols="12">
            <v-icon small class="mr-2">$tag</v-icon>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-container justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in item.tags" :key="tag.tag_id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        close
                        @click:close="deleteTag(tag, item)"
                        close-icon="mdi-close"
                      >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.tag_name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.tag_name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>-->
        <v-card-actions v-if="$vuetify.breakpoint.mobile">
          <v-btn
            tile
            depressed
            class="ml-auto"
            color="primary"
            @click="addToCalendar()"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <!--<tag-list
      v-model="openTagDialog"
      :items="tagList"
      :existItems="existItems"
      @selectTag="selectTagModal"
      @deleteTag="deleteTagModal"
      @updateTag="updateTagModal"
    ></tag-list>-->
  </v-dialog>
</template>

<script>
import {
  GET_DB_TASKS_API,
  UPDATE_DB_TASKS_API,
  //TAG_API,
} from "../../../constants/api";
import axios from "axios";
//import TagList from "../../schedule/modal/TagList.vue";
import { mapGetters } from "vuex";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC708, FC709, /*FC815*/ } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import {
  toHHmm,
  toHHmmFormat,
  toMinuteForHHmm,
  toMinute,
} from "@/helpers/datetime-helpers";
import { validationMixin } from "vuelidate";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import { required } from "vuelidate/lib/validators";
import { isEmpty } from "@/helpers/helpers";
import { taskMixin } from '@/mixins/task.js';
export default {
  //components: { TagList },
  mixins: [validationMixin, taskMixin],
  props: {
    show: Boolean,
    standarId: Number,
  },
  validations: {
    item: {
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      item: {},
      //openTagDialog: false,
      openDbTask: false,
      //tagList: [],
      existItems: [],
    };
  },
  watch: {
    show() {
      this.openDbTask = true;
      this.getData();
    },
  },

  methods: {
    isDetailEmpty() {
      return isEmpty(this.item.detail);
    },
    changeStartTime() {
      if (
        !this.$v.item.count.$invalid &&
        !this.$v.item.cost.$invalid &&
        !this.$v.item.start_time.$invalid
      ) {
        let hasColon = this.item.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    formatStartTime($event) {
      if (!this.$v.item.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.item.start_time = toHHmmFormat(value);
        }
      }
    },

    addToCalendar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        //
        this.$emit("addToCalendar", this.item);
        this.openDbTask = false;
      }
    },
    calculateTime() {
      let startTime = toMinute(this.item.start_time, ":");
      let endTime = toMinute(this.item.end_time, ":");
      if (this.item.cost && this.item.count) {
        let plusTime = parseInt(this.item.cost) * parseInt(this.item.count);
        if (!this.item.start_time && !this.item.end_time) return;
        if (this.item.start_time) {
          endTime = startTime + plusTime;
          this.item.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.item.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },
    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.item.start_time);
      let endTime = toMinuteForHHmm(this.item.end_time);
      if (this.item.cost && this.item.count) {
        let plusTime = parseInt(this.item.cost) * parseInt(this.item.count);
        if (!this.item.start_time && !this.item.end_time) return;
        if (this.item.start_time) {
          endTime = startTime + plusTime;
          this.item.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.item.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },
    async getData() {
      try {
        const { data, status } = await axios.get(
          `${GET_DB_TASKS_API()}` +
            `?user_id=${this.user.user_id}&standard_task_id=${this.standarId}`
        );
        let dbTask = status === STATUS_NO_CONTENT ? {} : data.result[0];
        dbTask.start_time = "";
        dbTask.end_time = "";
        dbTask.count = 1;
        this.item = dbTask;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC708, status);
      }
    },
    async isFavorite(item) {
      try {
        item.is_favorite = !item.is_favorite;
        await axios.put(
          UPDATE_DB_TASKS_API(this.user.user_id, this.standarId),
          {
            is_favorite: +item.is_favorite,
          }
        );

        this.$emit("isFavorite", item);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },
    /*async deleteTag(tag, item) {
      try {
        await axios.delete(TAG_API(tag.tag_id), {
          data: {
            standard_task_id: item.id,
          },
        });
        item.tags = item.tags.filter((x) => x.tag_id !== tag.tag_id);
        this.$emit("updateTag", item);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC815, status);
      }
    },
    deleteTagModal(tag) {
      this.item.tags = this.item.tags.filter((x) => x.tag_id !== tag.id);
    },
    updateTagModal(tag) {
      let t = this.item.tags.filter((x) => x.tag_id === tag.id)[0];
      if (t) t.tag_name = tag.name;
    },
    async selectTagModal(items) {
      try {
        await axios.put(UPDATE_DB_TASKS_API(this.user.user_id, this.item.id), {
          tags: items.map((x) => {
            return {
              id: x.id,
              name: x.name,
            };
          }),
        });

        this.item.tags = this.item.tags.concat(
          items.map((x) => {
            return {
              tag_id: x.id,
              tag_name: x.name,
            };
          })
        );
        this.$emit("updateTag", this.item);
        this.openTagDialog = false;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },*/
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    costErrors() {
      const errors = [];
      if (this.$v.item.cost.$dirty) {
        !this.$v.item.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.item.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.item.count.$dirty) {
        !this.$v.item.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.item.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.item.start_time.$dirty) {
        !this.$v.item.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.item.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.item.end_time.$dirty) {
        !this.$v.item.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.item.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.item.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.w-90 {
  width: 90px;
}
.w-120 {
  width: 120px;
}
.w-90 {
  width: 90px;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60 {
  .v-text-field__details {
    width: 100%;
  }
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  word-break: break-all;
}
</style>
