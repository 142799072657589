<template>
  <v-dialog
    v-model="openTodoTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="hidden">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deleteTask"
              v-bind="attrs"
              v-on="on"
              class="ml-auto"
              icon
              color="gray"
            >
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
          <span>タスクを削除</span>
        </v-tooltip>
        <v-btn @click="close()" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <template v-if="isFreeTask">
          <v-row class="py-0 my-0" v-if="!isSplit">
          <span class="ml-9 mb-n4 mt-1">
            <span v-if="secondaryName">
              {{ secondaryName }}
            </span>
            <span v-else-if="!task.category_primary_id">
              {{ "未分類" }}
            </span>
          </span>
            <v-col cols="12" class="py-0 d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon v-if="task.is_favorite" small
                >$favoriteactive</v-icon
              >
              <v-icon v-else small
                >$favoriteinactive</v-icon
              >
              <v-text-field
                class="ml-2"
                v-model="task.name"
                :error-messages="nameErrors"
                maxlength="100"
                placeholder="タスク名を追加"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-4" v-else>
            <span class="ml-9 mb-n4">
              <span v-if="secondaryName">
                {{ secondaryName }}
              </span>
              <span v-else-if="!task.category_primary_id">
                {{ "未分類" }}
              </span>
            </span>
            <v-col cols="12" class="d-flex align-center">
              <v-icon v-if="task.is_favorite" small
                >$favoriteactive</v-icon
              >
              <v-icon v-else small 
                >$favoriteinactive</v-icon
              >
              <span class="ml-2 work-break">{{ task.name }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-0 my-0" v-if="!isSplit">
            <v-col class="py-0 d-flex align-center">
              <v-icon small>$taskexplanation</v-icon>
              <v-textarea
                rows="1"
                class="ml-2"
                auto-grow
                maxlength="512"
                v-model="task.description"
                placeholder="説明を追加"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="py-2" v-else>
            <v-col class="d-flex align-center">
              <v-icon small>$taskexplanation</v-icon>
              <span class="ml-2 work-break">{{ task.description }}</span>
            </v-col>
          </v-row>

          <v-row class="py-0 my-0" v-if="!isSplit && !isMonthlyTask() && isChangeCategory()">
            <v-col cols="12" class="py-0">
              <div class="header">
                <span style="margin-left: -16px" class="red--text mr-2">*</span>
                <v-icon small>$taskcategory</v-icon>
                <dropdown-button
                  :id="'id'"
                  :display="'name'"
                  :name="primaryName || '大カテゴリ名'"
                  :items="parentCategories"
                  :type="'text'"
                  :has-total="false"
                  :showMenu="true"
                  @dropdownSelected="primaryCategoryChange($event)"
                ></dropdown-button>
              </div>
              <div class="mt-2 ml-4" ref="popupContainer">
                <v-btn
                  v-if="showSecondCategory"
                  text color="ligth"
                  class="pt-0"
                  v-bind="task.category_secondary_id"
                  @click="changeShowPopup()"
                >
                  <span>{{ task.category_secondary_name || '小カテゴリ名'}}</span>
                  <v-icon large right>mdi-menu-down</v-icon>
                </v-btn>
                <category-pulldown v-if="isPulldownopen" class="category-pulldown"> 
                  <div class="add-small-category">
                    <v-text-field
                      v-model="filteredKey"
                      placeholder="小カテゴリ名検索"
                      prepend-inner-icon="mdi-magnify"
                      variant="solo"
                      clearable
                      maxlength="100"
                    />
                  </div>
                  <div
                    class="add-small-category"
                    @click="newCategory(task.category_primary_id)"
                  >
                    +新しい小カテゴリを作成する
                  </div>
                  <div
                    v-for="(item, index) in secondariesFilter(filteredKey)" :key="index"
                    class="category-add"
                    @click="secondaryCategoryChange(item)"
                    @mouseover="showPulldownIcon(item)"
                    @mouseleave="hidePulldownIcon()"
                  >
                    <span>{{item.name}}</span>
                    <span
                      v-if="collectPulldownIcon(item , isPulldownIcon) && item.user_id"
                      class="pulldown-right-element"
                    >
                    <v-icon
                      small class="blue--text"
                      @click="editCategory($event, item)"
                    > 
                      $edit 
                    </v-icon>
                    <v-icon
                      small class="blue--text"
                      @click="deleteCategory($event, item)"
                    > 
                      $delete 
                    </v-icon>
                    </span>
                  </div>
                </category-pulldown>
                <div
                  v-if="
                    !this.$v.task.category_secondary_id.required &&
                    this.$v.task.category_secondary_id.$dirty
                  "
                  class="v-text-field__details"
                >
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ $t("label.error.required") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col class="py-0 d-flex align-center" cols="3">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskType</v-icon>
              <span class="ml-2 work-break"
              >{{ labelType }} : </span>
            </v-col>
            <v-col class="py-0 d-flex align-center" cols="6">
              <v-select
              no-data-text="-"
              :items="taskTypeItems"
              v-model="task.task_type_id"
              :error-messages="taskTypeErrors"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ task.category_primary_name }}</span>
            </v-col>
            <v-col cols="12">
              <span class="ml-7"> {{ task.category_secondary_name }}</span>
            </v-col>
            <v-col cols="12" class="py-0">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span>
          </v-col>
          </v-row>
          <v-row class="py-0 my-0 align-baseline" v-if="!isSplit">
            <v-col cols="3" class="py-0 align-self-start mt-5">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">工数</span></v-col
            >
            <v-col cols="9" class="py-0 d-flex align-start align-self-start">
              <v-text-field
                type="number"
                max="999"
                min="1"
                @keydown="checkMaxLength('cost', $event)"
                @input.native="updateCost"
                :error-messages="costErrors"
                v-model="task.cost"
                suffix="m/回"
                class="w-90"
              >
              </v-text-field>
              <span class="mx-2 mt-5">×</span>
              <v-text-field
                class="w-60 align-self-start"
                type="number"
                max="999"
                min="1"
                @keydown="checkMaxLength('count', $event)"
                @input.native="updateCount"
                :error-messages="countErrors"
                suffix="回"
                v-model="task.count"
              >
              </v-text-field>
              <span class="mx-2 mt-5">=</span>
              <v-text-field
                class="w-95 align-self-start"
                readonly
                min="1"
                :value="task.cost * task.count"
                suffix="m"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col class="d-flex align-center col-12">
              <v-icon small class="yellow--text">$taskworktime</v-icon>
              <span class="ml-2">工数</span>
              <span class="mx-2">{{ task.cost }}m/回</span>
            </v-col>
          </v-row>
          <template v-if="isSplit">
            <div>
              <v-row>
                <v-col class="pb-0">
                  <div class="d-flex">
                    <span class="ml-7">累計実績</span>
                    <span class="ml-1">{{ task.total_actual_cost }} m</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="task.is_split_task_completed">
                    <v-radio
                      v-bind:value="0"
                      :label="'タスクを一部着手して後日継続予定'"
                    >
                    </v-radio>
                    <span class="ml-8">
                      ※チェックを付けて保存した場合、「未完了タスク」に登録されます
                    </span>
                    <br />
                    <v-radio
                      v-bind:value="1"
                      :label="'タスクが全て完了'"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
          </template>

        </template>
        <template v-else>
          <v-row class="py-4">
            <span class="ml-8 mb-n4">
            <span v-if="task.category_secondary_name">
              {{  task.category_secondary_name }}
            </span>
            <span v-else-if="!task.category_primary_id">
              {{ "未分類" }}
            </span>
          </span>
            <v-col cols="12" class="d-flex align-center">
              <v-icon v-if="task.is_favorite" small
                >$favoriteactive</v-icon
              >
              <v-icon v-else small
                >$favoriteinactive</v-icon
              >
              <span class="ml-2 work-break">{{ task.name }}</span>
              <div class="detail">
                <v-text-field
                  class="ml-2"
                  v-model="task.detail"
                  maxlength="100"
                  placeholder="タスク詳細を追加"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-3 my-0">
            <v-col class="d-flex align-center">
              <v-icon small>$taskexplanation</v-icon>
              <span class="ml-2 work-break">{{ task.description }}</span>
            </v-col>
          </v-row>

          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ task.category_primary_name }}</span>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0 my-0">
              <span class="ml-7"> {{ task.category_secondary_name }}</span>
            </v-col>
          </v-row>

          <v-row class="py-1" v-if="task.task_type_id">
            <v-col class="d-flex align-center">
              <v-icon small>$taskType</v-icon>
              <span class="ml-2 work-break"
                >{{ labelType }} : {{ task.task_type_name || "" }}</span
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center col-12">
              <v-icon small class="yellow--text">$taskworktime</v-icon>
              <span class="ml-2">標準工数</span>
              <span class="mx-2">{{ task.cost }}m/回</span>
            </v-col>
            <v-col class="col-12 pb-1 pt-0 pl-10" v-if="!isSplit">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="text-break-all">{{ task.unit || '' }}</span>
            </v-col>
          </v-row>
          <template v-if="isSplit">
            <div>
              <v-row>
                <v-col class="pb-0">
                  <div class="d-flex">
                    <span class="ml-7">累計実績</span>
                    <span class="ml-1">{{ task.total_actual_cost }} m</span>
                    <span class="ml-1 mr-1">/</span>
                    <span class="">{{ task.total_cost }} m</span>
                  </div>
                </v-col>
                <v-col class="col-12 pb-1 pl-10">
                  <span>※標準工数/回の業務量定義（件数など）：</span>
                  <span class="text-break-all">{{ task.unit || '' }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="task.is_split_task_completed">
                    <v-radio
                      v-bind:value="0"
                      :label="'タスクを一部着手して後日継続予定'"
                    >
                    </v-radio>
                    <span class="ml-8">
                      ※チェックを付けて保存した場合、「未完了タスク」に登録されます
                    </span>
                    <br />
                    <v-radio
                      v-bind:value="1"
                      :label="'タスクが全て完了'"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
          </template>
        </template>

        <!--<v-row class="py-0 my-0 mt-6">
          <v-col cols="12" class="py-0">
            <v-icon small class="mr-2">$tag</v-icon>
            <v-btn tile color="ligth" @click="createTag()"> タグを追加 </v-btn>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-container justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        close
                        @click:close="deleteTag(tag)"
                        close-icon="mdi-close"
                      >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>-->

        <v-row class="py-0 my-0">
          <v-col v-if="!showComment" class="py-0">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col cols="12" class="d-flex align-items-baseline py-0" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!isEditing"
          tile
          depressed
          class="ml-auto"
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mobile && isAllowDrag()"
          tile
          depressed
          color="primary"
          @click="showTodoEditTask = !showTodoEditTask"
        >
          カレンダーに配置
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--<tag-list
      v-model="openTagDialog"
      :items="tagList"
      :existItems="existItems"
      @selectTag="selectTagModal"
      @deleteTag="deleteTagModal"
      @updateTag="updateTagModal"
    ></tag-list>-->
    <ActionTodoEditTask
      :show="showTodoEditTask"
      :taskId="taskId"
      @addToCalendar="addToCalendar($event)"
      :isSplit="isSplit"
    ></ActionTodoEditTask>
    <AddSmallCategory
      :show="openNewCategory"
      :newLargeCategoryId="newLargeCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @secondaryCategoryCreated="categorySort()"
    ></AddSmallCategory>
    <UpdateSmallCategory
      :show="openEditCategory"
      :largeCategoryId="updateCategoryPrimaryId"
      :smallCategoryName="updateSecondaryCategoryName"
      :smallCategoryId="updateSecondaryCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @closeEvent="categorySort()"
    ></UpdateSmallCategory>
  </v-dialog>
</template>

<script>
import CategoryPulldown from '../../schedule/modal/CategoryPulldown.vue';
import AddSmallCategory from '../../schedule/modal/AddSmallCategory.vue';
import UpdateSmallCategory from "../../schedule/modal/UpdateSmallCategory.vue";
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { required, requiredIf } from "vuelidate/lib/validators";
import DropdownButton from "../../../components/DropdownButton.vue";
import {
  CATEGORIES_API,
  PRIMARY_SUB_CATEGORY_API,
  TODO_TASK_API,
  //TAG_API,
  DELETE_COMMENT_API,
  SPLIT_TASK_API,
} from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import {
  debounce_leading,
  deepClone,
  isEmptyNumber,
  orderBy,
} from "../../../helpers/helpers";
import { workTimeValidator } from "../../../helpers/validator";
import { isEmpty } from "../../../helpers/helpers";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
// TagList from "../../schedule/modal/TagList.vue";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC701,
  FC704,
  FC807,
  FC808,
  FC809,
  //FC813,
  //FC815,
  FC820,
  FC823,
  FC824,
  FC825,
} from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import ActionTodoEditTask from "./ActionTodoEditTask";
import { FREE_TASK_TYPE_LIST } from "../../../constants/system";
export default {
  components: { 
    DropdownButton,
    CategoryPulldown,
    AddSmallCategory,
    UpdateSmallCategory, 
    //TagList,
    ActionTodoEditTask,
  },
  props: {
    show: Boolean,
    taskId: Number,
    isSplit: Boolean,
  },
  mixins: [validationMixin, backMixin, taskMixin],
  validations: {
    task: {
      name: { required },
      output_detail: {
        required: requiredIf((task) => {
          return task.is_need_output;
        }),
      },
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id != null;
        }),
      },
      task_type_id: {
        required,
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
    },
  },
  data() {
    return {
      filteredKey: "",
      showTodoEditTask: false,
      isFavorite: true,
      openTodoTask: false,
      //openTagDialog: false,
      showComment: false,
      parentCategories: [],
      childCategories: [],
      primaryCategory: {},
      //tagList: [],
      existItems: [],
      userComment: "",
      originalTask: {},
      taskTypeItems: FREE_TASK_TYPE_LIST,
      task: {
        name: "",
        description: "",
        cost: null,
        count: "",
        category_secondary_id: null,
        is_need_output: false,
        output_detail: "",
        start_time: "",
        end_time: "",
        is_favorite: false,
        //tags: [],
        comments: [],
        task_type_id: null,
      },
      isEditing: false,
      isPulldownopen: false,
      isPulldownIcon: false,
      openNewCategory: false,
      openEditCategory: false,
      updateCategoryPrimaryId: 0,
      updateSecondaryCategoryName: "",
      updateSecondaryCategoryId: 0,
      newLargeCategoryId: 0,
      userId: "",
      groupId: "",
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
    // ポップアップ外をクリックした際にポップアップを閉じるイベント
    window.addEventListener("click", this.leaveSelect);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    taskTypeErrors() {
      const errors = [];
      if (this.$v.task.task_type_id.$dirty) {
        !this.$v.task.task_type_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "未分類";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
    isSpotTask() {
      return isEmptyNumber(this.task.schedule_task_id);
    },
    isFreeTask() {
      return !isEmptyNumber(this.task.free_task_id) || !isEmptyNumber(this.task.actual_free_task_id);
    },
  },
  watch: {
    async show() {
      this.openTodoTask = true;
      this.$v.$reset();
      await this.getData();
      this.showComment = false;
      this.userComment = "";
    },
    task: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler(val) {
        this.isEditing = !isEmpty(val);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  methods: {
    secondariesFilter(filteredKey){
      let items = this.childCategories.filter(item => {
        if(filteredKey){
          if(item.name.includes(filteredKey)){
            return item
          }
        } else {
          return item
        }
      })
      return items;
    },
    clearFilteredKey(){
      this.filteredKey = "";
    },
    isAllowDrag() {
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },
    addToCalendar(task) {
      this.openTodoTask = false;
      this.$emit("addToCalendar", task);
    },
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    isOwner(item) {
      return item.user_id === this.loginInfo.user_id;
    },

    /**
     * Check if monthlyTask
     * @return {boolean}
     */
    isMonthlyTask() {
      return this.task.schedule_month_task_id ? true : false;
    },

    /**
     * Check if changeCategory
     * @return {boolean}
     */
    isChangeCategory() {
      if (this.task.is_changeability != null){
        return this.task.is_changeability ? true : false;
      }
      return true;
    },

    deleteComment(item) {
      if (!this.isOwner(item)) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            this.originalTask.comments = this.originalTask.comments.filter(
              (x) => x.id !== item.id
            );
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    /*async deleteTag(item) {
      try {
        let param = {};
        if (this.isSplit) {
          param = { split_task_id: this.task.id };
        } else {
          if (!this.isFreeTask) {
            param = { standard_task_id: this.task.standard_task_id };
          } else {
            param = { free_task_id: this.task.free_task_id };
          }
        }

        await axios.delete(TAG_API(item.id), {
          data: param,
        });
        this.task.tags = this.task.tags.filter((x) => x.id !== item.id);
        this.originalTask.tags = this.originalTask.tags.filter(
          (x) => x.id !== item.id
        );
        this.$emit("updateTag", this.task);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC815, status);
      }
    },

    deleteTagModal(tag) {
      this.task.tags = this.task.tags.filter((x) => x.id !== tag.id);
      this.originalTask.tags = this.originalTask.tags.filter(
        (x) => x.id !== tag.id
      );
    },

    updateTagModal(tag) {
      let t = this.task.tags.filter((x) => x.id === tag.id)[0];
      let ot = this.originalTask.tags.filter((x) => x.id === tag.id)[0];
      if (t) t.name = tag.name;
      if (ot) ot.name = tag.name;
    },

    selectTagModal(items) {
      this.task.tags = this.task.tags.concat(
        items.map((x) => {
          return {
            id: x.id,
            name: x.name,
          };
        })
      );
      this.openTagDialog = false;
    },

    createTag: debounce_leading(async function () {
      try {
        let { data, status } = await axios.get(
          `${TAG_API()}` + `?user_id=${this.user.user_id}`
        );
        this.tagList =
          status === STATUS_NO_CONTENT
            ? []
            : data.result.map((x) => {
                return { ...x, ...{ check: false } };
              });
        this.existItems = this.task.tags.map((x) => {
          return {
            tag_id: x.id,
            tag_name: x.name,
          };
        });
        this.openTagDialog = true;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC813, status);
      }
    }),*/

    setFavorite(value) {
      this.task.is_favorite = value;
    },

    primaryCategoryChange(e) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === e.id
      )?.secondaries;

      this.primaryCategory = e;
      this.task.category_primary_id = e.id;
      this.task.category_secondary_id = null;
      this.task.category_secondary_name  = null;
    },

    secondaryCategoryChange(e) {
      this.task.category_secondary_id = e.id;
      this.task.category_secondary_name = e.name;
      this.isPulldownopen = false;
      this.clearFilteredKey();
    },

    updateComment() {
      this.showComment = true;
    },

    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // type: something
      } else {
        if (this.isSplit) {
          if (this.task.is_split_task_completed === 1) {
            this.$confirm(
              "このタスクを完了しますか？\r\n※未完了タスク上から分割タスクが削除され、\r\nこの操作を取り消しすることはできません。"
            )
              .then(async () => {
                try {
                  await axios.put(SPLIT_TASK_API(this.taskId), {
                    detail: this.task.detail,
                    is_favorite: +this.task.is_favorite,
                    is_split_task_completed: +this.task.is_split_task_completed,
                    //tags: this.task.tags,
                    comments:
                      !isEmpty(this.userComment) && this.showComment
                        ? [
                            {
                              user_id: this.loginInfo.user_id,
                              comment: this.userComment,
                            },
                          ]
                        : [],
                  });
                  this.$store.dispatch("save/setChangeFlag", false);
                  this.openTodoTask = false;
                  this.$emit("deleteTodoTask", this.task);
                } catch (error) {
                  const { status } = error.response;
                  apiErrorMessages(this, FC824, status);
                }
              })
              .catch(() => {});
          } else {
            try {
              await axios.put(SPLIT_TASK_API(this.taskId), {
                detail: this.task.detail,
                is_favorite: +this.task.is_favorite,
                is_split_task_completed: +this.task.is_split_task_completed,
                //tags: this.task.tags,
                comments:
                  !isEmpty(this.userComment) && this.showComment
                    ? [
                        {
                          user_id: this.loginInfo.user_id,
                          comment: this.userComment,
                        },
                      ]
                    : [],
              });
              this.$store.dispatch("save/setChangeFlag", false);
              this.openTodoTask = false;
              this.$emit("updateTask", this.task);
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC824, status);
            }
          }
        } else {
          try {
            await axios.put(TODO_TASK_API(this.taskId), {
              tasks: [{
                name: this.task.name,
                description: this.task.description,
                detail: this.task.detail,
                task_type_id: this.task.task_type_id,
                cost: +this.task.cost,
                count: +this.task.count,
                is_need_output: +this.task.is_need_output,
                output_detail:
                  +this.task.is_need_output === 0
                    ? null
                    : this.task.output_detail,
                category_secondary_id: this.task.category_secondary_id
                  ? +this.task.category_secondary_id
                  : null,
                is_favorite: +this.task.is_favorite,
                schedule_month_task_id: this.task.schedule_month_task_id,
                //tags: this.task.tags,
                comments:
                  !isEmpty(this.userComment) && this.showComment
                    ? [
                        {
                          user_id: this.loginInfo.user_id,
                          comment: this.userComment,
                        },
                      ]
                    : [],
              }]
          });

            this.openTodoTask = false;
            this.$store.dispatch("save/setChangeFlag", false);
            this.$emit("updateTask", this.task);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC808, status);
          }
        }
        this.openDbTask = false;
      }
    }),

    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    async getCategories() {
      try {
        this.groupId = this.loginInfo.group_id;
        this.userId = this.loginInfo.user_id;
        const { data, status } = await axios.get(CATEGORIES_API(
          this.groupId,
          this.userId
        ));

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.childCategories = this.parentCategories.find(
            (x) => x.id === this.task.category_primary_id
          )?.secondaries;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    getData: debounce_leading(async function () {
      try {
        const { data, status } = !this.isSplit
          ? await axios.get(TODO_TASK_API(this.taskId))
          : await axios.get(SPLIT_TASK_API(this.taskId));
        this.task =
          status === STATUS_NO_CONTENT ? {} : deepClone(data.result[0]);
        this.originalTask =
          status === STATUS_NO_CONTENT ? {} : deepClone(data.result[0]);
        if (this.isSplit) {
          this.task.count = 1;
          this.originalTask.count = 1;
          this.task.isSplit = this.isSplit;
          this.originalTask.isSplit = this.isSplit;
        }
        this.task.comments = orderBy(
          this.task.comments,
          ["updated_at"],
          ["desc"]
        );
        this.originalTask.comments = orderBy(
          this.originalTask.comments,
          ["updated_at"],
          ["desc"]
        );
        await this.getCategories();
        this.parentCategories.push({
          id: null,
          name: "未分類",
          secondaries: [],
        });

      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, this.isSplit ? FC823 : FC807, status);
      }
    }),

    async deleteTask() {
      this.$confirm(
        this.isSplit
          ? `${this.$t(
              "label.confirm.delete_task"
            )} \r\nこのタスクは中断となり、生産性が評価できません。`
          : this.$t("label.confirm.delete_task"),
        {
          width: "600px",
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        }
      )
        .then(async () => {
          if (this.isSplit) {
            try {
              await axios.delete(SPLIT_TASK_API(this.taskId));
              this.openTodoTask = false;
              this.$emit("deleteTodoTask", this.task);
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC825, status);
            }
          } else {
            try {
              await axios.delete(TODO_TASK_API(this.taskId));
              this.openTodoTask = false;
              this.$emit("deleteTodoTask", this.task);
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC809, status);
            }
          }
        })
        .catch(() => {});
    },

    compareValue() {
      return (
        JSON.stringify({
          is_favorite: this.task.is_favorite,
          name: this.task.name,
          description: this.task.description,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          category_primary_id: this.task.category_primary_id,
          category_secondary_id: this.task.category_secondary_id,
          task_type_id: this.task.task_type_id,
          userComment: "",
          files: this.task.files,
          cost: this.task.cost,
          count: this.task.count,
          //tags: this.task.tags,
          detail: this.task.detail,
          is_split_task_completed: this.task.is_split_task_completed,
        }) !==
        JSON.stringify({
          is_favorite: this.originalTask.is_favorite,
          name: this.originalTask.name,
          description: this.originalTask.description,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          category_primary_id: this.originalTask.category_primary_id,
          category_secondary_id: this.originalTask.category_secondary_id,
          task_type_id: this.originalTask.task_type_id,
          userComment: "",
          files: this.originalTask.files,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          //tags: this.originalTask.tags,
          detail: this.originalTask.detail,
          is_split_task_completed: this.originalTask.is_split_task_completed,
        })
      );
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openTodoTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openTodoTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    /**
     * Handle control icon View
     * @return {boolean}
     */
    showPulldownIcon(pulldownOption) {
      this.isPulldownIcon = pulldownOption;
    },

    hidePulldownIcon(){
      this.isPulldownIcon = "";
    },

    collectPulldownIcon(str1 , str2) {
      if(str1 === str2) return true;
      else return false;
    },

    /**
     * Handle show category pulldown
     * @return {boolean}
     */
    changeShowPopup(){
      this.isPulldownopen = !this.isPulldownopen;
      this.clearFilteredKey();
    },

    leaveSelect(event) {
      const popupContainer = this.$refs.popupContainer;
      
      // popupContainer が存在し、クリックされた要素が popupContainer の外側の場合
      if (popupContainer && !popupContainer.contains(event.target)) {
        this.isPulldownopen = false; // ポップアップを閉じる
        this.clearFilteredKey(); // フィルターをクリア

      }
    },

    /**
     * Handle update edit category name
     * @param {object} event
     * @return {boolean}
     */
    editCategory(event, item) {
      event.stopPropagation();
      this.updateCategoryPrimaryId = this.task.category_primary_id;
      this.updateSecondaryCategoryName = item.name;
      this.updateSecondaryCategoryId = item.id;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openEditCategory = !this.openEditCategory;
    },

    newCategory(categoryPrimaryId) {
      this.newLargeCategoryId = categoryPrimaryId;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openNewCategory = !this.openNewCategory;
    },

    /**
     * Handle delete category event
     * @param {object} event
     * @return {boolean}
     */
    deleteCategory(event, item) {
      event.stopPropagation();

      /** 登録されている小カテゴリの場合 */
      let origincategory = this.originalTask.category_secondary_id
      let selectcategory = this.task.category_secondary_id
      if (origincategory === item.id || selectcategory === item.id) {
        this.notDeleteCategory();
      } else {
        this.$confirm(this.$t("label.confirm.delete_secondary_category"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        })
          .then(async () => {
            try {
              await axios.delete(
                PRIMARY_SUB_CATEGORY_API(
                  this.task.category_primary_id,
                  item.id,
                  item.user_id
                )
              );

              this.$toast(
                `${item.name}を削除しました。`,
                3000
              );
              this.categorySort()
            } catch (error) {
              const { status } = error.response;
              if (status === 404) {
                this.$alert(error.response.data.message, {
                  width: "820px",
                });
              } else {
                apiErrorMessages(this, FC704, status);
              }
            }
          })
          .catch(() => {});
      }
    },

    notDeleteCategory() {
      this.$alert([this.$t("label.confirm.not_delete_category.line1")])
        .then(() => {})
        .catch(() => {});
    },

    categorySort() {
      this.getCategories();
      this.changeShowPopup();
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
    window.removeEventListener("click", this.leaveSelect);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.text-number {
  width: 200px;
}
.w-120 {
  width: 120px;
}
.w-90 {
  width: 90px;
}
.align-items-baseline {
  align-items: baseline;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  white-space: pre-wrap;
}
.category-pulldown {
  z-index:999;
}
.category-add{
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 15%;
  font-size : 15px;
}
.category-add:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.add-small-category{
  height: 48px;
  line-height: 48px;
  position: relative;
  color: gray;
  font-size : 15px;
}
.add-small-category:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.pulldown-right-element {
  position: absolute;
  right: 5%;
}
</style>
