<template>
  <v-container class="container--fluid" :style="cssProps">
    <div
      class="content-row"
      :class="[{ 'is-panel-collapsed': !showDrawerRight }]"
    >
      <div
        :class="[
          { 'content-calendar': isSelf() && !$vuetify.breakpoint.mobile },
          { 'my-column-full': !(isSelf() && !$vuetify.breakpoint.mobile) },
        ]"
      >
        <v-tabs-items v-model="currentTab">
          <v-tab-item value="mobile-tabs-calendar">
            <v-row
              ref="header"
              class="header-items"
              :class="{ 'hide-header-items': expand !== 0 }"
              :inert="isOverlay"
            >
              <v-col v-show="expand === 0">

                <!-- ユーザー情報 -->
                <div
                  class="d-flex flex-wrap no-padding-print"
                  id="headerItem"
                >
                  <div class="py-2">
                    <v-combobox
                      item-text="name"
                      item-value="id"
                      v-model="selectUser"
                      hide-details
                      solo
                      :items="members"
                      @change="membersChange($event)"
                    ></v-combobox>
                  </div>

                  <!-- カレンダー -->
                  <div class="py-2 pl-3 d-flex align-center">
                    <v-btn
                      x-small
                      class="mr-1 direction-btn"
                      @click="decrementMonth()"
                    >
                      <v-icon color="#7F7F8F">mdi-menu-left</v-icon>
                    </v-btn>
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      :return-value="selectedDate"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          solo
                          hide-details
                          :value="dispTargetMonth"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="dispTargetMonth"
                        @change="selectCalendarMonth($event)"
                        locale="ja"
                        no-title
                        scrollable
                        @input="menuDate = false"
                        type="month"
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-btn
                      x-small
                      class="ml-1 direction-btn"
                      @click="incrementMonth()"
                    >
                      <v-icon color="#7F7F8F">mdi-menu-right</v-icon>
                    </v-btn>
                  </div>


                  <!-- CSVエクスポート -->
                  <div class="pa-2 no-print" v-if="!$vuetify.breakpoint.mobile && !othersFrag && !isSelectUserError">
                    <div class="v-center d-lex ml-3">
                      <v-btn
                        class="ml-3"
                        @click="exportCsv()"
                        tile
                        outlined
                        color="ligth"
                      >
                      <v-icon small left dark>$csvexport</v-icon>
                        CSVエクスポート
                      </v-btn>
                    </div>
                  </div>

                  <!-- インフォメーション -->
                  <div class="pa-2 no-print" v-if="!$vuetify.breakpoint.mobile && !othersFrag && !isSelectUserError">
                    <v-btn @click="showExplainModal()" depressed class="ml-1">
                      <v-icon>$information</v-icon>
                    </v-btn>
                  </div>
                  
                  <template v-if="!$vuetify.breakpoint.mobile && !othersFrag && !isSelectUserError">

                    <!-- 保存(メンバ)・ステータスボタン -->
                    <div v-if="!authorizeCheck" class="pa-2 ml-auto">
                      <v-btn
                        v-if="isSelf()"
                        depressed
                        class="mr-2 no-print"
                        v-bind:disabled="!storageFrag"
                        @click="openConfirmSaveDialog()"
                      >
                        保存
                      </v-btn>

                      <v-btn
                        depressed
                        :class="{'reject-text': isRejectText}"
                        @click="rejectOnClick(false)"
                        tile
                      >
                      {{ statusText }}
                      </v-btn>
                    </div>

                    <!-- 差し戻しボタン(上長) -->
                    <div
                      v-if="!authorizeCheck"
                      class="d-flex py-2 no-print"
                    >
                      <v-btn
                        v-if="
                          isSupervisor() &&
                          isRejectStatus() &&
                          remandTask.individual_tasks_comments &&
                          remandTask.individual_tasks_comments.length === 0
                        "
                        tile
                        class="font-weight-bold no-print mr-2"
                        @click="submit(true)"
                        :disabled="submitButton.active || !hasSupervisor"
                      >
                        <v-icon class="blue--text mr-2" small right>$return</v-icon>
                        <span class="text-large">
                          差し戻し
                        </span>
                        <span v-if="rejectDraft && isInDraft" class="ml-1 draft-text">
                          {{ $t("label.buttons.is_in_draft") }}
                        </span>
                      </v-btn>

                      <!-- 提出(メンバ)・確認(上長)ボタン -->
                      <v-btn
                        v-if="isSupervisor() || isSelf()"
                        tile
                        class="font-weight-bold no-print"
                        @click="submitValidation(submitButton.isReject)"
                        :disabled="submitButton.active || !hasSupervisor"
                      >
                        <v-icon
                          v-if="!submitButton.isReject"
                          class="blue--text mr-2"
                          right
                        >
                          $submitorconfirm
                        </v-icon>
                        <v-icon 
                          v-else 
                          class="blue--text mr-2" 
                          small 
                          right
                        >
                          $return
                        </v-icon>
                        <span>
                          {{ submitButton.text }}
                        </span>
                        <span
                          v-if="
                            remandTask.individual_tasks_comments &&
                            remandTask.individual_tasks_comments.length !== 0 &&
                            isRejectStatus()
                          "
                          class="text-large"
                        >
                          差し戻し
                        </span>
                        <template v-if="!submitButton.isReject">
                          <span v-if="confirmDraft && isInDraft" class="ml-1 draft-text">
                          {{ $t("label.buttons.is_in_draft") }}
                          </span>
                        </template>
                        <template v-else>
                          <span v-if="rejectDraft && isInDraft" class="ml-1 draft-text">
                          {{ $t("label.buttons.is_in_draft") }}
                          </span>
                        </template>
                      </v-btn>
                    </div>
                  </template>
                  <!-- ステータスボタン(モバイル版) -->
                  <div v-else-if="!othersFrag && !isSelectUserError" class="pa-2 ml-auto">
  
                    <v-btn
                      depressed
                      :class="{'reject-text': isRejectText}"
                      tile
                    >
                    {{ statusText }}
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>


            <v-divider class="mb-2"></v-divider>
            <v-row v-if="authorizeCheck">
              <v-col cols="12" class="mx-auto text-center">
                {{ authorizeMessage }}
              </v-col>
            </v-row>
            <div 
              v-else
              class="print-calendar main-scroll"
              :style="scrollHeight"
              ref="mainScroll"
            >
              <div v-if="othersFrag" class="center-text">{{$t("label.monthly_calendar.monthly_actual_not_created")}}</div>
              <div v-else-if="isSelectUserError" class="center-text">{{$t("label.error.no_select_user")}}</div>

              <!-- スクロールバー計算初期値 -->
              <div
                v-else
                id="section1"
              >
                <template v-if="!$vuetify.breakpoint.mobile">
                  <v-row dense>
                    <!-- 承認済みの月次予定工数/当月の勤務時間 -->
                    <v-col cols="4" class="border-box">
                      <div class="__header font-weight-bold header-height py-3 pl-3">
                        {{ $t("label.monthly_data.assessment_worktime") }}
                      </div>
                      <span class="total-class py-10">
                        <span class="font-weight-bold v-text-field__suffix total-actuals-monthly-text-size1">
                          <span>
                            {{ categoryTotalTime(approved_monthly_scheduled_cost)}}
                          </span>
                          <span class="ml-5">/</span>
                          <span class="total-plan-monthly-text-size pl-3">
                            {{ categoryTotalTime(total_min) }}
                          </span>
                        </span>
                      </span>
                    </v-col>

                    <!-- 変更後の月次予定工数 -->
                    <v-col cols="4" class="border-box">
                      <div class="__header font-weight-bold header-height py-3 pl-3">
                        {{ $t("label.monthly_data.assessment_change_monthly") }}
                      </div>
                      <span class="total-class py-5">
                        <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size1">
                          <div>
                            {{ scheduleTotalHours }}h{{ padZero(scheduleTotalMinutes) }}m
                          </div>

                          <!-- 生産性評価対象 -->
                          <div class="glay-color with-line-break">
                            <span class="total-cost">
                              {{ $t("label.monthly_data.assessment_productivity") }}
                            </span>
                            <span class="total-cost ml-5 total-cost-padding">
                              {{ scheduleEvaluationTargetHours }}h{{ padZero(scheduleEvaluationTargetMinutes) }}m
                            </span>
                          </div>

                          <!-- 生産性評価対象外 -->
                          <div class="glay-color">
                            <span class="total-cost">
                              {{ $t("label.monthly_data.assessment_not_productivity") }}
                            </span>
                            <span class="total-cost ml-5">
                              {{ scheduleEvaluationNotTargetHours }}h{{ padZero(scheduleEvaluationNotTargetMinutes) }}m
                            </span>
                          </div>
                        </span>
                      </span>
                    </v-col>

                    <!-- 月次実績工数 -->
                    <v-col cols="4" class="border-box">
                      <div class="__header font-weight-bold header-height py-3 pl-3">
                        {{ $t("label.monthly_data.assessment_actual_cost") }}
                      </div>
                      <span class="total-class py-5">
                        <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size1">
                          <div>
                            {{ actualTotalHours }}h{{ padZero(actualTotalMinutes) }}m
                          </div>

                          <!-- 生産性評価対象 -->
                          <div class="glay-color with-line-break">
                            <span class="total-cost">
                              {{ $t("label.monthly_data.assessment_productivity") }}
                            </span>
                            <span class="total-cost ml-5 total-cost-padding">
                              {{ actualEvaluationTargetHours }}h{{ actualEvaluationTargetMinutes }}m
                            </span>
                          </div>

                          <!-- 生産性評価対象外 -->
                          <div class="glay-color">
                            <span class="total-cost">
                              {{ $t("label.monthly_data.assessment_not_productivity") }}
                            </span>
                            <span class="total-cost ml-5">
                              {{ actualEvaluationNotTargetHours }}h{{ actualEvaluationNotTargetMinutes }}m
                            </span>
                          </div>
                        </span>
                      </span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!-- 標準工数タスク率 -->
                    <v-col cols="3" class="border-box">
                      <div class="__header font-weight-bold header-height-center py-2 pl-1">
                        {{ $t("label.monthly_data.assessment_standard") }}
                      </div>
                      <span class="total-class py-5">
                        <div class="font-weight-bold d-flex align-baseline rate-detail">
                          <span>
                            {{ standardTaskEveluationRate }}%
                          </span>
                        </div>
                      </span>
                    </v-col>

                    <!-- 計画実行率 -->
                    <v-col cols="3" class="border-box">
                      <div class="__header font-weight-bold header-height-center py-2 pl-1">
                        {{ $t("label.monthly_data.assessment_plan") }}
                      </div>
                      <span class="total-class py-5">
                        <div class="font-weight-bold d-flex align-baseline rate-detail">
                          <span>
                            {{ planActualRate }}%
                          </span>
                        </div>
                      </span>
                    </v-col>

                    <!-- 生産性 -->
                    <v-col cols="3" class="border-box">
                      <div class="__header font-weight-bold header-height-center py-2 pl-1">
                        {{ $t("label.monthly_data.assessment_product") }}
                      </div>
                      <span class="total-class row py-5">
                        <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size">
                          <v-tooltip bottom color="transparent">
                            <template v-slot:activator="{on}">
                              <div v-if="productivityRate >= 100" v-on="on" class="py-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35"
                                  height="19"
                                  viewBox="0 0 14 16"
                                >
                                  <circle
                                    cx="7"
                                    cy="7"
                                    r="6"
                                    transform="translate(2 2)"
                                    fill="none"
                                    stroke="#66DAFF"
                                    stroke-width="2"
                                  />
                                </svg>
                                <span class="blue--text">
                                  {{ productivityRate }}%
                                </span>
                              </div>
                              <div v-else v-on="on" class="py-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="17"
                                  viewBox="0 0 16 12"
                                >
                                  <g transform="translate(-1.351 -0.437)">
                                    <path
                                      d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                      transform="translate(11.899 -0.423) rotate(45)"
                                      fill="#FF0000"
                                  />
                                    <path
                                      d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                      transform="translate(1.351 2.354) rotate(-45)"
                                      fill="#FF0000"
                                    />
                                  </g>
                                </svg>
                                <span class="red--text">
                                  {{ productivityRate }}%
                                </span>
                              </div>
                            </template>
                            <v-card>
                              <v-card-text class="pt-5">
                                <v-list>
                                  <div class="d-flex align-center">
                                    【評価の基準について】
                                  </div>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <div class="d-flex align-center">
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                          >
                                            <circle
                                              cx="6"
                                              cy="6"
                                              r="6"
                                              transform="translate(2 2)"
                                              fill="none"
                                              stroke="#66DAFF"
                                              stroke-width="2"
                                            />
                                          </svg>
                                        </div>
                                        <span>・・・100%以上</span>
                                      </div>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <div class="d-flex align-center">
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <g transform="translate(-1.351 -0.437)">
                                              <path
                                                d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                                transform="translate(11.899 -0.423) rotate(45)"
                                                fill="#FF0000"
                                            />
                                              <path
                                                d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                                transform="translate(1.351 2.354) rotate(-45)"
                                                fill="#FF0000"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                        <span>・・・100%未満</span>
                                      </div>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-col>

                    <!-- 生産性評価対象外業務(定常業務)を予定工数内で出来たか -->
                    <v-col cols="3" class="border-box">
                      <div class="__header font-weight-bold header-height-center py-2 pl-1">
                        {{ $t("label.monthly_data.assessment_usual") }}
                      </div>
                      <span class="total-class row py-5">
                        <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size">
                          <v-tooltip bottom color="transparent">
                            <template v-slot:activator="{on}">
                              <div v-if="taskUsualRate >= 100" v-on="on" class="py-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35"
                                  height="19"
                                  viewBox="0 0 14 16"
                                >
                                  <circle
                                    cx="7"
                                    cy="7"
                                    r="6"
                                    transform="translate(2 2)"
                                    fill="none"
                                    stroke="#66DAFF"
                                    stroke-width="2"
                                  />
                                </svg>
                                <span class="blue--text"> {{ taskUsualRate }}%</span>
                              </div>
                              <div v-else v-on="on" class="py-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="17"
                                  viewBox="0 0 16 12"
                                >
                                  <g transform="translate(-1.351 -0.437)">
                                    <path
                                      d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                      transform="translate(11.899 -0.423) rotate(45)"
                                      fill="#FF0000"
                                  />
                                    <path
                                      d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                      transform="translate(1.351 2.354) rotate(-45)"
                                      fill="#FF0000"
                                    />
                                  </g>
                                </svg>
                                <span class="red--text">{{ taskUsualRate }}%</span>
                              </div>
                            </template>
                            <v-card>
                              <v-card-text class="pt-5">
                                <v-list>
                                  <div class="d-flex align-center">
                                    【評価の基準について】
                                  </div>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <div class="d-flex align-center">
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                          >
                                            <circle
                                              cx="6"
                                              cy="6"
                                              r="6"
                                              transform="translate(2 2)"
                                              fill="none"
                                              stroke="#66DAFF"
                                              stroke-width="2"
                                            />
                                          </svg>
                                        </div>
                                        <span>・・・100%以上</span>
                                      </div>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <div class="d-flex align-center">
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <g transform="translate(-1.351 -0.437)">
                                              <path
                                                d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                                transform="translate(11.899 -0.423) rotate(45)"
                                                fill="#FF0000"
                                            />
                                              <path
                                                d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                                transform="translate(1.351 2.354) rotate(-45)"
                                                fill="#FF0000"
                                              />
                                            </g>
                                          </svg>
                                        </div>
                                        <span>・・・100%未満</span>
                                      </div>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <!-- 承認済みの月次予定工数/当月の勤務時間 -->
                  <div class="border-box">
                    <div class="__header font-weight-bold header-height py-3 pl-3">
                      {{ $t("label.monthly_data.assessment_worktime") }}
                    </div>
                    <span class="total-class py-10">
                      <span class="font-weight-bold v-text-field__suffix total-actuals-monthly-text-size1">
                        <span>
                          {{ categoryTotalTime(approved_monthly_scheduled_cost)}}
                        </span>
                        <span class="ml-5">/</span>
                        <span class="total-plan-monthly-text-size pl-3">
                          {{ categoryTotalTime(total_min) }}
                        </span>
                      </span>
                    </span>
                  </div>

                  <!-- 変更後の月次予定工数 -->
                  <div class="border-box">
                    <div class="__header font-weight-bold header-height py-3 pl-3">
                      {{ $t("label.monthly_data.assessment_change_monthly") }}
                    </div>
                    <span class="total-class py-5">
                      <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size1">
                        <div>
                          {{ scheduleTotalHours }}h{{ padZero(scheduleTotalMinutes) }}m
                        </div>

                        <!-- 生産性評価対象 -->
                        <div class="glay-color with-line-break">
                          <span class="total-cost">
                            {{ $t("label.monthly_data.assessment_productivity") }}
                          </span>
                          <span class="total-cost ml-5 total-cost-padding">
                            {{ scheduleEvaluationTargetHours }}h{{ padZero(scheduleEvaluationTargetMinutes) }}m
                          </span>
                        </div>

                        <!-- 生産性評価対象外 -->
                        <div class="glay-color">
                          <span class="total-cost">
                            {{ $t("label.monthly_data.assessment_not_productivity") }}
                          </span>
                          <span class="total-cost ml-5">
                            {{ scheduleEvaluationNotTargetHours }}h{{ padZero(scheduleEvaluationNotTargetMinutes) }}m
                          </span>
                        </div>
                      </span>
                    </span>
                  </div>

                  <!-- 月次実績工数 -->
                  <div class="border-box">
                    <div class="__header font-weight-bold header-height py-3 pl-3">
                      {{ $t("label.monthly_data.assessment_actual_cost") }}
                    </div>
                    <span class="total-class py-5">
                      <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size1">
                        <div>
                          {{ actualTotalHours }}h{{ padZero(actualTotalMinutes) }}m
                        </div>

                        <!-- 生産性評価対象 -->
                        <div class="glay-color with-line-break">
                          <span class="total-cost">
                            {{ $t("label.monthly_data.assessment_productivity") }}
                          </span>
                          <span class="total-cost ml-5 total-cost-padding">
                            {{ actualEvaluationTargetHours }}h{{ actualEvaluationTargetMinutes }}m
                          </span>
                        </div>

                        <!-- 生産性評価対象外 -->
                        <div class="glay-color">
                          <span class="total-cost">
                            {{ $t("label.monthly_data.assessment_not_productivity") }}
                          </span>
                          <span class="total-cost ml-5">
                            {{ actualEvaluationNotTargetHours }}h{{ actualEvaluationNotTargetMinutes }}m
                          </span>
                        </div>
                      </span>
                    </span>
                  </div>
                  <!-- 標準工数タスク率 -->
                  <div class="border-box">
                    <div class="__header font-weight-bold py-2 pl-1">
                      {{ $t("label.monthly_data.assessment_standard") }}
                    </div>
                    <span class="total-class py-5">
                      <div class="font-weight-bold d-flex align-baseline rate-detail">
                        <span>
                          {{ standardTaskEveluationRate }}%
                        </span>
                      </div>
                    </span>
                  </div>

                  <!-- 計画実行率 -->
                  <div class="border-box">
                    <div class="__header font-weight-bold py-2 pl-1">
                      {{ $t("label.monthly_data.assessment_plan") }}
                    </div>
                    <span class="total-class py-5">
                      <div class="font-weight-bold d-flex align-baseline rate-detail">
                        <span>
                          {{ planActualRate }}%
                        </span>
                      </div>
                    </span>
                  </div>

                  <!-- 生産性 -->
                  <div class="border-box">
                    <div class="__header font-weight-bold py-2 pl-1">
                      {{ $t("label.monthly_data.assessment_product") }}
                    </div>
                    <span class="total-class row py-5">
                      <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size">
                        <v-tooltip bottom color="transparent">
                          <template v-slot:activator="{on}">
                            <div v-if="productivityRate >= 100" v-on="on" class="py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="19"
                                viewBox="0 0 14 16"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6"
                                  transform="translate(2 2)"
                                  fill="none"
                                  stroke="#66DAFF"
                                  stroke-width="2"
                                />
                              </svg>
                              <span class="blue--text">
                                {{ productivityRate }}%
                              </span>
                            </div>
                            <div v-else v-on="on" class="py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="17"
                                viewBox="0 0 16 12"
                              >
                                <g transform="translate(-1.351 -0.437)">
                                  <path
                                    d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                    transform="translate(11.899 -0.423) rotate(45)"
                                    fill="#FF0000"
                                />
                                  <path
                                    d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                    transform="translate(1.351 2.354) rotate(-45)"
                                    fill="#FF0000"
                                  />
                                </g>
                              </svg>
                              <span class="red--text">
                                {{ productivityRate }}%
                              </span>
                            </div>
                          </template>
                          <v-card>
                            <v-card-text class="pt-5">
                              <v-list>
                                <div class="d-flex align-center">
                                  【評価の基準について】
                                </div>
                                <v-list-item>
                                  <v-list-item-content>
                                    <div class="d-flex align-center">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="6"
                                            cy="6"
                                            r="6"
                                            transform="translate(2 2)"
                                            fill="none"
                                            stroke="#66DAFF"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                      <span>・・・100%以上</span>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <div class="d-flex align-center">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="13"
                                          viewBox="0 0 13 13"
                                        >
                                          <g transform="translate(-1.351 -0.437)">
                                            <path
                                              d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                              transform="translate(11.899 -0.423) rotate(45)"
                                              fill="#FF0000"
                                          />
                                            <path
                                              d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                              transform="translate(1.351 2.354) rotate(-45)"
                                              fill="#FF0000"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                      <span>・・・100%未満</span>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-tooltip>
                      </span>
                    </span>
                  </div>

                  <!-- 生産性評価対象外業務(定常業務)を予定工数内で出来たか -->
                  <div class="border-box">
                    <div class="__header font-weight-bold py-2 pl-1">
                      {{ $t("label.monthly_data.assessment_usual") }}
                    </div>
                    <span class="total-class row py-5">
                      <span class="font-weight-bold  v-text-field__suffix total-actuals-monthly-text-size">
                        <v-tooltip bottom color="transparent">
                          <template v-slot:activator="{on}">
                            <div v-if="taskUsualRate >= 100" v-on="on" class="py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="19"
                                viewBox="0 0 14 16"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6"
                                  transform="translate(2 2)"
                                  fill="none"
                                  stroke="#66DAFF"
                                  stroke-width="2"
                                />
                              </svg>
                              <span class="blue--text"> {{ taskUsualRate }}%</span>
                            </div>
                            <div v-else v-on="on" class="py-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="17"
                                viewBox="0 0 16 12"
                              >
                                <g transform="translate(-1.351 -0.437)">
                                  <path
                                    d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                    transform="translate(11.899 -0.423) rotate(45)"
                                    fill="#FF0000"
                                />
                                  <path
                                    d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                    transform="translate(1.351 2.354) rotate(-45)"
                                    fill="#FF0000"
                                  />
                                </g>
                              </svg>
                              <span class="red--text">{{ taskUsualRate }}%</span>
                            </div>
                          </template>
                          <v-card>
                            <v-card-text class="pt-5">
                              <v-list>
                                <div class="d-flex align-center">
                                  【評価の基準について】
                                </div>
                                <v-list-item>
                                  <v-list-item-content>
                                    <div class="d-flex align-center">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="6"
                                            cy="6"
                                            r="6"
                                            transform="translate(2 2)"
                                            fill="none"
                                            stroke="#66DAFF"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                      <span>・・・100%以上</span>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <div class="d-flex align-center">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="13"
                                          viewBox="0 0 13 13"
                                        >
                                          <g transform="translate(-1.351 -0.437)">
                                            <path
                                              d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                              transform="translate(11.899 -0.423) rotate(45)"
                                              fill="#FF0000"
                                          />
                                            <path
                                              d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                              transform="translate(1.351 2.354) rotate(-45)"
                                              fill="#FF0000"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                      <span>・・・100%未満</span>
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-tooltip>
                      </span>
                    </span>
                  </div>
                </template>

                <v-row dense :inert="isOverlay">
                  <v-col class="border-box">
                    <div class="__header font-weight-bold py-2 pl-3 print-font-adjust">
                      {{ $t("label.monthly_data.assessment_comment") }}
                    </div>
                    <v-container fluid>
                    <v-textarea
                      @blur="updateMonthlyActualsNotices($event)"
                      @input="canSaved()"
                      :value="this.monthlyActualsNotices"
                      rows="0" cols="20"
                      clearable
                      auto-grow
                      clear-icon="mdi-close"
                      placeholder="コメントを追加"
                      :disabled="!isSelf() || !isEditTask()"
                      maxlength="512"
                      counter
                      class="custom-textarea"
                    ></v-textarea>
                    </v-container>
                  </v-col>
                </v-row>
                <div 
                  class="text-show-hide py-0 my-0 col no-print"
                  ref="largeCategotySelector"
                  :inert="isOverlay"
                >
                  <span
                    v-if="isSelf() && isEditTask()"
                    class="relative"
                    @click="showElement = !showElement;"
                  >
                    +大カテゴリを追加する
                  </span>

                  <span v-if="showElement">
                    <category-pulldown
                      class="category-pulldown"
                    >
                      <div
                        v-for="(option, options) in parentCategories" :key="`categorySelect-${options}`"
                        class="category-add"
                        @click="addSelectPulldown(option)"
                      >
                        {{option.text}}
                      </div>
                    </category-pulldown>
                  </span>
                </div>

                <!-- 工数表 -->
                <div v-for="(allCategory, allCategories) in category_primary" :key="`allCategorie-${allCategories}`">
                  <div v-for="(largeCategory, largeCategories) in allCategory" :key="`largeCategorie-${largeCategories}`" class="row py-5">
                    <div class="py-0 my-0 col col-0">
                      <!-- 大カテゴリ -->
                      <div
                        class="__header font-weight-bold py-1 pl-1 mx-0 __content d-flex large-center-child"
                        :class="{ 'yellow-background red-text': !largeCategory.category_primary_id && notCategoryError}"
                        :inert="isOverlay"
                      >
                        <span class="py-1 pl-1 mx-0">{{largeCategory.category_primary_name}}</span>
                        <span class="text-center large-category">
                          <v-icon
                            v-if="largeCategory.category_visible"
                            class="button-cursor"
                            @mousedown="toggleVisibility(largeCategory)"
                          >
                            mdi-chevron-up
                          </v-icon>
                          <v-icon
                            v-else
                            class="button-cursor"
                            @mousedown="toggleVisibility(largeCategory)"
                          >
                            mdi-chevron-down
                          </v-icon>
                        </span>
                        <span class="right-items">
                          {{ largeCategoryActualTotal(largeCategory) }}/
                          {{ largeCategoryScheduleTotal(largeCategory) }}
                        </span>
                      </div>

                      <div v-if="largeCategory.category_visible">

                        <!-- 小カテゴリ -->
                        <div v-for="(smallCategory, smallCategories) in largeCategory.category_secondary"
                          :key="`smallCategorie-${smallCategories}`"
                          :class="[
                            {'py-5':largeCategory.category_secondary.length-1 !== smallCategories},
                            {'py-5-top':largeCategory.category_secondary.length-1 === smallCategories},
                          ]"
                          :id="categoryId(smallCategory.category_secondary_id)"
                        >
                          <div
                            class="font-weight-bold py-1 pl-1 mx-0 __content d-flex center-child border-bottom"
                            :inert="isOverlay"
                            ref="smallCategotySelector" 
                          >
                            <span
                              v-if="largeCategory.category_primary_id && !smallCategory.isMonthlyScheduleAddCategory"
                              class="small-pulldown py-1 pl-1 mx-0"
                              @click="changeShowPopup(smallCategory)"
                            >
                              <span>{{ smallCategory.category_secondary_name }}</span>
                              <v-icon>mdi-menu-down</v-icon>
                            </span>
                            <span
                              v-else
                              class="py-1 pl-1 mx-0"
                            >
                              <span>{{ smallCategory.category_secondary_name }}</span>
                            </span>

                            <span class="text-center large-category">
                              <v-icon
                                v-if="smallCategory.category_visible"
                                class="button-cursor"
                                @mousedown="toggleVisibility(smallCategory)"
                              >
                                mdi-chevron-up
                              </v-icon>
                              <v-icon
                                v-else
                                class="button-cursor"
                                @mousedown="toggleVisibility(smallCategory)"
                              >
                                mdi-chevron-down
                              </v-icon>
                            </span>

                            <span class="right-items">
                              {{smallCategoryActualTotal(smallCategory)}}/
                              {{smallCategoryScheduleTotal(smallCategory)}}
                            </span>
                          </div>

                          <div 
                            v-if="smallCategory.category_change && !isDisabled()"
                            ref="smallCategotySelector" 
                            :inert="isOverlay"
                          >
                            <category-pulldown
                              class="category-pulldown"
                            >
                              <div class="add-small-category">
                                <v-text-field
                                  v-model="filteredKey"
                                  placeholder="小カテゴリ名検索"
                                  prepend-inner-icon="mdi-magnify"
                                  variant="solo"
                                  clearable
                                  maxlength="100"
                                />
                              </div>
                              <div
                                v-if="isSelf() && isEditTask()"
                                class="add-small-category"
                                @click="newCategory(largeCategory.category_primary_id)"
                              >
                                +新しい小カテゴリを作成する
                              </div>
                              <div
                                v-for="(option, options) in secondariesFilter(largeCategory.category_primary_id, filteredKey)" 
                                :key="`smallCategoryAdd-${options}`"
                                class="category-add"
                                @click="handleSelectionChange(option, smallCategory, largeCategory.category_secondary)"
                                @mouseover="showPulldownIcon(options)"
                                @mouseleave="hidePulldownIcon()"
                              >
                                <span>{{option.text}}</span>
                                <span
                                  v-if="collectPulldownIcon(options, isPulldownIcon) && option.userId"
                                  class="pulldown-right-element"
                                >
                                  <v-icon
                                    small class="blue--text"
                                    @click="editCategory($event, option)"
                                  > 
                                    $edit 
                                  </v-icon>
                                  <v-icon
                                    small class="blue--text"
                                    @click="deleteCategory($event, deleteSmallArray(option))"
                                  > 
                                    $delete 
                                  </v-icon>
                                </span>
                              </div>
                            </category-pulldown>
                          </div>
                          <!-- タスクインデックス -->
                          <div class="task-header">
                            <div v-if="smallCategory.category_visible" class="hidden-scroll" ref="tables">
                              <table class="sticky-table" id="dropElement" :inert="isOverlay">
                                <thead>
                                  <tr id="trId"
                                    :key="`task-${-1}`"
                                    :class="{ 'drag-over': isDrag && checkDropPoint(allCategories,largeCategories,smallCategories,-1) }"
                                    @dragover.prevent="throttledHandleDragOver(allCategories,largeCategories,smallCategories,-1)"
                                    @drop="handleDrop(allCategories,largeCategories,smallCategories,-1)" 
                                  >
                                    <th class="table-column th50">タスク名</th>
                                    <th class="table-column th5">
                                      <div>変更後</div>
                                      <div>予定工数/回</div>
                                    </th>
                                    <th class="table-column th5">変更後 回数</th>
                                    <th class="table-column th5">変更後 予定工数</th>
                                    <th class="table-column th5">実績工数/回</th>
                                    <th class="table-column th5">回数</th>
                                    <th class="table-column th5">実績工数</th>
                                    <th class="table-column th5">予定外タスク</th>
                                    <th class="table-column th5">標準工数タスク</th>
                                    <th class="table-column th5">生産性評価</th>
                                    <th class="table-column th5">完了</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          </div>
                          <div v-if="smallCategory.category_visible" ref="tables">
                            <table class="sticky-table" id="dropElement" :inert="isOverlay">
                              <tbody>
                                <!-- 親タスクの行表示 -->
                                <template v-for="(categoryTask, categoryTasks) in smallCategory.tasks">
                                  <tr
                                    :key="`task-${categoryTasks}`"
                                    :draggable="isDraggable(categoryTask)"
                                    :class="{ 
                                      'drag-over': isDrag && checkDropPoint(allCategories, largeCategories, smallCategories, categoryTasks) 
                                        && (categoryTask.task_secondary.length === 0 || !categoryTask.tasks_visible),
                                      'remad-red-out':isRemandTask(categoryTask) && isVerified(),
                                      'not-actual-dark-gray-out':!isRemandTask(categoryTask) && isNotImplementedTask(categoryTask),
                                      'usual-gray-out':!isRemandTask(categoryTask)&& !isNotImplementedTask(categoryTask)  && isUsualTask(categoryTask)
                                    }"
                                    @dragstart="handleDraggStart(allCategories, largeCategories, smallCategories, categoryTasks)" 
                                    @dragend="handleDragEnd()"
                                    @dragover.prevent="throttledHandleDragOver(allCategories, largeCategories, smallCategories, categoryTasks)"
                                    @drop="handleDrop(allCategories, largeCategories, smallCategories, categoryTasks)"
                                    @click="handleClick(allCategories, largeCategories, smallCategories, categoryTasks,$event)"
                                  >

                                    <!-- 親タスク-タスク名 -->
                                    <td 
                                      class="taskName th50"
                                      :class="{ 
                                        'remad-red-out':isRemandTask(categoryTask) && isVerified(),
                                        'not-actual-dark-gray-out':!isRemandTask(categoryTask) && isNotImplementedTask(categoryTask),
                                        'yellow-background': 
                                          (!isRemandTask(categoryTask) && !isNotImplementedTask(categoryTask) && isDetailDiff(categoryTask)) || 
                                          (!categoryTask.standard_task_id
                                          && !isRemandTask(categoryTask) && !isNotImplementedTask(categoryTask) && isNameDiff(categoryTask)), 
                                        'usual-gray-out': !isRemandTask(categoryTask) && !isNotImplementedTask(categoryTask) 
                                          && !isDetailDiff(categoryTask) && isUsualTask(categoryTask),
                                        'white-out': !isRemandTask(categoryTask) && !isNotImplementedTask(categoryTask) 
                                          && !isDetailDiff(categoryTask) && !isUsualTask(categoryTask)
                                      }"
                                    >
                                      <v-tooltip v-if="categoryTask.standard_task_id" bottom>
                                        <!-- 親タスク-タスク名-標準工数タスク -->
                                        <template v-slot:activator="{ on, attrs }" >
                                          <div v-if="categoryTask.task_secondary.length !== 0" v-bind="attrs" v-on="on">
                                            <v-list-item-title>
                                              <!-- 親タスク-タスク名-標準工数タスク-子タスクがある場合 -->
                                              <span>
                                                <v-icon
                                                  v-if="categoryTask.tasks_visible"
                                                  class="button-cursor"
                                                  @mousedown="tasksVisibility(categoryTask)"
                                                > mdi-chevron-up </v-icon>
                                                <v-icon
                                                  v-else
                                                  class="button-cursor"
                                                  @mousedown="tasksVisibility(categoryTask)"
                                                > mdi-chevron-down </v-icon>
                                              </span>
                                              <div class="d-flex justify-center align-center">
                                                <v-icon
                                                  v-if="(categoryTask.is_remand === 1 || categoryTask.is_remand_previous === 1) && isVerified()"
                                                  small
                                                >
                                                  $remandtask
                                                </v-icon>
                                              </div>
                                              <div class="ellipsis">
                                                <!-- 標準タスク(親) -->
                                                {{ categoryTask.index_no }}.{{ categoryTask.after_task_name }}
                                              </div>
                                            </v-list-item-title>
                                          </div>
                                          <div v-else v-bind="attrs" v-on="on">
                                            <v-list-item-title>
                                              <!-- 親タスク-タスク名-標準工数タスク-子タスクがない場合 -->
                                              <div class="d-flex justify-center align-center">
                                                <v-icon
                                                  v-if="(categoryTask.is_remand === 1 || categoryTask.is_remand_previous === 1) && isVerified()"
                                                  small
                                                >
                                                  $remandtask
                                                </v-icon>
                                              </div>
                                              <input 
                                                v-if="isSupervisor() && isRejectStatus()" 
                                                :disabled="!canApproveRemand"
                                                type="checkbox"  
                                                @change="toggleRemand(categoryTask ,$event)"
                                                :checked="(categoryTask.is_remand === 1) && isVerified()" 
                                              >
                                              <div class="ellipsis">
                                                <!-- 標準タスク -->
                                                {{ categoryTask.index_no }}.{{ categoryTask.after_task_name }}
                                              </div>
                                            </v-list-item-title>
                                            <v-list-item-title 
                                              v-if="categoryTask.after_detail && categoryTask.schedule_task_month_id"
                                            >
                                              <div class="ellipsis">
                                                {{categoryTask.after_detail}}
                                              </div>
                                            </v-list-item-title>
                                            <v-list-item-title 
                                              v-if="!categoryTask.schedule_task_month_id"
                                            >
                                              <!-- 予定外タスクの場合 -->
                                              <input 
                                                v-if="categoryTask.after_detail || isEditTask()"
                                                v-model="categoryTask.after_detail"
                                                type="text"
                                                class="default-text-decoration box-size" 
                                                :class="{ 
                                                  'text-underline': isEditTask(),
                                                  'red-text': isFontChange(categoryTask) && !isNotImplementedTask(categoryTask)
                                                }"
                                                placeholder="タスク詳細"
                                                maxlength="100"
                                                oninput="javascript: this.value = this.value.slice(0, 99)"
                                                :disabled="isDisabled()"
                                              />
                                            </v-list-item-title>
                                          </div>
                                        </template>
                                        <div v-if="categoryTask.after_task_name">
                                          {{ categoryTask.after_task_name }}
                                          <span v-if="categoryTask.after_detail">
                                            _{{ categoryTask.after_detail }}
                                          </span>
                                        </div>
                                        <div v-if="(categoryTask.is_remand === 1 || categoryTask.is_remand_previous === 1) && isVerified()">
                                          {{ getRemandComment(categoryTask.id, categoryTask.schedule_task_month_id) }}
                                        </div>
                                      </v-tooltip>
                                      <v-tooltip v-else bottom :disabled="!categoryTask.after_task_name && !taskNameError">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title
                                            v-bind="attrs"
                                            v-on="on"
                                            :class="{'red_text_bold': categoryTask.name_change_frag  && isVerified()}"
                                          >
                                            <!-- 親タスク-タスク名-フリータスク-子タスクがない場合 -->
                                            <div class="d-flex justify-center align-center">
                                              <v-icon
                                                v-if="(categoryTask.is_remand === 1 || categoryTask.is_remand_previous === 1) && isVerified()"
                                                small
                                              >
                                                $remandtask
                                              </v-icon>
                                            </div>
                                            <input 
                                              v-if="isSupervisor() && isRejectStatus()" 
                                              :disabled="!canApproveRemand"
                                              type="checkbox"  
                                              @change="toggleRemand(categoryTask ,$event)"
                                              :checked="(categoryTask.is_remand === 1) && isVerified()" 
                                            />
                                            <div class="alignItemsCenter">
                                              <!-- フリータスク -->
                                              {{ categoryTask.index_no }}.
                                              <template 
                                                v-if="categoryTask.after_task_name && categoryTask.schedule_task_month_id"
                                              >
                                                {{categoryTask.after_task_name}}
                                              </template>
                                              <!-- 予定外タスクの場合 -->
                                              <input 
                                                v-if="!categoryTask.schedule_task_month_id"
                                                v-model="categoryTask.after_task_name"
                                                type="text" 
                                                class="default-text-decoration box-size" 
                                                :class="{ 
                                                  'text-underline': isEditTask(),
                                                  'red-text': isFontChange(categoryTask) && !isNotImplementedTask(categoryTask)
                                                }"
                                                placeholder="タスク名"
                                                @input="inputvalidation($event)"
                                                @change="changeHistoryManagement(
                                                  categoryTask,
                                                  smallCategory.category_secondary_id,
                                                  smallCategory.category_secondary_name
                                                )"
                                                maxlength="100"
                                                oninput="javascript: this.value = this.value.slice(0, 99)"
                                                :disabled="isDisabled()"
                                              />
                                            </div>
                                          </v-list-item-title>
                                        </template>
                                        <div v-if="categoryTask.after_task_name">
                                          {{ categoryTask.after_task_name }}
                                        </div>
                                        <div v-else-if="!categoryTask.after_task_name && taskNameError">
                                          タスク名を入力してください。
                                        </div>
                                        <div v-if="(categoryTask.is_remand === 1 || categoryTask.is_remand_previous === 1) && isVerified()">
                                          {{ getRemandComment(categoryTask.id, categoryTask.schedule_task_month_id) }}
                                        </div>
                                      </v-tooltip>
                                    </td>

                                    <!-- 親タスク-変更後予定工数/回 -->
                                    <td  
                                      class="table-cell th5" 
                                      :class="[
                                        { 
                                          'yellow-background': 
                                            isCostDiff(categoryTask)&&
                                            !isRemandTask(categoryTask) && 
                                            !isNotImplementedTask(categoryTask)
                                        }
                                      ]"
                                    >
                                      <v-tooltip bottom :disabled="!categoryTask.after_cost && !afterCostError">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title
                                            v-bind="attrs"
                                            v-on="on"
                                            :class="{'red_text_bold': categoryTask.schedule_cost_change_frag && isVerified()}"
                                          >
                                            <span v-if="
                                              categoryTask.task_secondary.length === 0 &&
                                              !categoryTask.standard_task_id &&
                                              categoryTask.actual_cost !== 0 && 
                                              categoryTask.actual_count !== 0
                                            ">
                                              <!-- フリータスクの場合 -->
                                              <input
                                                v-model="categoryTask.after_cost"
                                                @input="changeHistoryManagement(
                                                  categoryTask,
                                                  smallCategory.category_secondary_id,
                                                  smallCategory.category_secondary_name
                                                )"
                                                class="text-underline-center" 
                                                type="number"
                                                maxlength="3"
                                                max="999"
                                                min="0"
                                                oninput="this.value = this.value.replace(/^0+/, '').slice(0, 3)"
                                                :disabled="isDisabled()"
                                              >m
                                            </span>
                                            <span v-else>
                                              {{ categoryTask.after_cost }}m
                                            </span>
                                          </v-list-item-title>
                                        </template>
                                        <span v-if="categoryTask.after_cost">
                                          {{ categoryTask.before_cost }}m/回から
                                          {{ categoryTask.after_cost }}m/回に変更
                                        </span>
                                        <span v-else-if="!categoryTask.after_cost && afterCostError">
                                          工数/回を入力してください。
                                        </span>
                                      </v-tooltip>
                                    </td>

                                    <!-- 親タスク-変更後回数 -->
                                    <td
                                      class="table-cell th5"
                                      :class="[
                                        {
                                        'yellow-background': 
                                          isCountDiff(categoryTask) &&
                                          !isRemandTask(categoryTask) && 
                                          !isNotImplementedTask(categoryTask)
                                        }
                                      ]"
                                    >
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title v-bind="attrs" v-on="on">
                                            <span>
                                              {{ categoryTask.after_count }}
                                            </span>
                                          </v-list-item-title>
                                        </template>
                                        <span>
                                          {{ categoryTask.before_count }}回から
                                          {{ categoryTask.after_count }}回に変更
                                        </span>
                                      </v-tooltip>
                                    </td>

                                    <!-- 親タスク-変更後予定工数 -->
                                    <td
                                      class="table-cell th5"
                                      :class="[
                                        {
                                          'yellow-background':
                                            isEstimateDiff(categoryTask) &&
                                            !isRemandTask(categoryTask) &&
                                            !isNotImplementedTask(categoryTask)
                                        },
                                        {'red_text_bold': categoryTask.schedule_cost_change_frag  && isVerified()}
                                      ]"
                                    >
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title v-bind="attrs" v-on="on">
                                            <span>
                                              {{ categoryTask.after_cost * categoryTask.after_count }}m
                                            </span>
                                          </v-list-item-title>
                                        </template>
                                        <span>
                                          {{ categoryTask.before_cost * categoryTask.before_count }}mから
                                          {{ categoryTask.after_cost * categoryTask.after_count }}mに変更
                                        </span>
                                      </v-tooltip>
                                    </td>

                                    <!-- 親タスク-実績工数/回 -->
                                    <td 
                                      class="table-cell th5"
                                      :class="{'red-background': !isRemandTask(categoryTask) && !isNotImplementedTask(categoryTask) 
                                        && isCostIncreasedBy20(categoryTask)}"
                                    >
                                      <span>
                                        {{ categoryTask.actual_cost }}m
                                      </span>
                                    </td>

                                    <!-- 親タスク-実績回数 -->
                                    <td class="table-cell th5">
                                      <span>
                                        {{ categoryTask.actual_count }}
                                      </span>
                                    </td>

                                    <!-- 親タスク-実績工数 -->
                                    <td class="table-cell th5">
                                      <span v-if="categoryTask.actual_cost && categoryTask.actual_count">
                                        {{ categoryTask.actual_total_cost }}m
                                      </span>
                                      <span v-else>0m</span>
                                    </td>

                                    <!-- 親タスク-予定外タスク -->
                                    <td class="table-cell th5">
                                      <span v-if="!categoryTask.schedule_task_month_id">●</span>
                                      <span v-else>-</span>
                                    </td>

                                    <!-- 親タスク-標準工数タスク -->
                                    <td class="table-cell th5">
                                      <span v-if="categoryTask.standard_task_id">●</span>
                                      <span v-else>-</span>
                                    </td>

                                    <!-- 親タスク-生産性評価 -->
                                    <td 
                                      class="table-cell th5"
                                      :class="[
                                        {'yellow-background': !(categoryTask.schedule_task_month_id || categoryTask.standard_task_id)
                                            && categoryTask.before_task_type_id !== categoryTask.after_task_type_id}
                                        ]"
                                    >
                                      <span v-if="categoryTask.schedule_task_month_id || categoryTask.standard_task_id">
                                        {{taskType(categoryTask.after_task_type_id)}}</span>
                                      <span v-else>
                                        <select 
                                          class="text-underline-center default-text-decoration custom-select" 
                                          v-model="categoryTask.after_task_type_id"
                                          :disabled="!isSelf() || !isEditTask()"
                                        >
                                          <option v-for="(option, options) in typeOptions" 
                                            :value="option.value" 
                                            :key="`taskType-${options}`"
                                          >
                                            {{ option.text }}
                                          </option>
                                        </select>
                                      </span>
                                    </td>

                                    <!-- 親タスク-完了 -->
                                    <td class="table-cell th5">
                                      <span>
                                        <select 
                                          class="text-underline-center default-text-decoration custom-select" 
                                          :disabled="!isSelf() || !isEditTask()"
                                          v-model="categoryTask.is_completion"
                                        >
                                          <option v-for="(option, options) in completOptions" 
                                            :value="option.value" 
                                            :key="`completion-${options}`"
                                          >
                                            {{ option.text }}
                                          </option>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>

                                  <!-- 子タスク行表示 -->
                                  <template v-if="categoryTask.tasks_visible">
                                    <template v-for="(categoryTask_secondary, categoryTask_secondarys) in categoryTask.task_secondary">
                                      <tr
                                        :key="`task-${categoryTasks}-${categoryTask_secondarys}`"
                                        :class="{
                                          'drag-over': isDrag && checkDropPoint(allCategories, largeCategories, smallCategories, categoryTasks) 
                                            && categoryTask.task_secondary.length - 1 === categoryTask_secondarys,
                                          'remad-red-out':isRemandTask(categoryTask_secondary) && isVerified(),
                                          'not-actual-dark-gray-out':!isRemandTask(categoryTask_secondary) && isNotImplementedTask(categoryTask_secondary),
                                          'usual-gray-out':!isRemandTask(categoryTask_secondary) && !isNotImplementedTask(categoryTask_secondary)
                                          && isUsualTask(categoryTask_secondary)
                                        }"
                                        @dragover.prevent="throttledHandleDragOver(allCategories, largeCategories, smallCategories, categoryTasks)"
                                        @drop="handleDrop(allCategories, largeCategories, smallCategories, categoryTasks)"
                                      >
                                        <!-- 子タスク-タスク名 -->
                                        <td
                                         class="taskName th50 childTask"
                                         :class="{ 
                                          'remad-red-out':isRemandTask(categoryTask_secondary) && isVerified(),
                                          'not-actual-dark-gray-out':!isRemandTask(categoryTask_secondary) && isNotImplementedTask(categoryTask_secondary),
                                          'yellow-background': (!isRemandTask(categoryTask_secondary) && !isNotImplementedTask(categoryTask_secondary) 
                                            && isDetailDiff(categoryTask_secondary)) || 
                                            (!categoryTask_secondary.standard_task_id && !isRemandTask(categoryTask_secondary)
                                            && !isNotImplementedTask(categoryTask_secondary) && isNameDiff(categoryTask_secondary)), 
                                          'usual-gray-out':!isRemandTask(categoryTask_secondary) && !isNotImplementedTask(categoryTask_secondary) 
                                            && isUsualTask(categoryTask_secondary)
                                         }"
                                        >
                                          <v-tooltip v-if="categoryTask_secondary.standard_task_id" bottom>
                                            <!-- 子タスク-タスク名-標準工数タスク -->
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-list-item-title v-bind="attrs" v-on="on">
                                                <div class="d-flex justify-center align-center">
                                                  <v-icon
                                                    small
                                                    v-if="(categoryTask_secondary.is_remand === 1 || categoryTask_secondary.is_remand_previous === 1) 
                                                      && isVerified()"
                                                  >
                                                    $remandtask
                                                  </v-icon>
                                                </div>
                                                <input 
                                                  v-if="isSupervisor() && isRejectStatus()" 
                                                  :disabled="!canApproveRemand"
                                                  type="checkbox"  
                                                  @change="toggleRemand(categoryTask_secondary ,$event)"
                                                  :checked="categoryTask_secondary.is_remand === 1" 
                                                />
                                                <div class="ellipsis">
                                                  <!-- 標準タスク -->
                                                  {{ categoryTask.index_no }}_{{ categoryTask_secondary.index_branch_no }}
                                                  .{{categoryTask_secondary.after_task_name}}
                                                </div>
                                              </v-list-item-title>
                                              <v-list-item-title 
                                                v-if="categoryTask_secondary.after_detail && categoryTask_secondary.schedule_task_month_id"
                                                v-bind="attrs" v-on="on"
                                              >
                                                <div class="ellipsis">
                                                  {{categoryTask_secondary.after_detail}}
                                                </div>
                                              </v-list-item-title>
                                              <v-list-item-title 
                                                v-if="!categoryTask_secondary.schedule_task_month_id"
                                                v-bind="attrs" v-on="on"
                                              >
                                                <!-- 予定外タスクの場合 -->
                                                <input 
                                                  v-if="categoryTask_secondary.after_detail || isEditTask()"
                                                  v-model="categoryTask_secondary.after_detail"
                                                  type="text"
                                                  class="default-text-decoration box-size" 
                                                  :class="{ 
                                                    'text-underline': isEditTask(),
                                                    'red-text': isFontChange(categoryTask_secondary)
                                                  }"
                                                  placeholder="タスク詳細"
                                                  maxlength="100"
                                                  oninput="javascript: this.value = this.value.slice(0, 99)"
                                                  :disabled="isDisabled()"
                                                  />
                                              </v-list-item-title>
                                            </template>
                                            <div v-if="categoryTask_secondary.after_task_name">
                                              {{ categoryTask_secondary.after_task_name }}
                                              <span v-if="categoryTask_secondary.after_detail">
                                                _{{ categoryTask_secondary.after_detail }}
                                              </span>
                                            </div>
                                            <div
                                              v-if="
                                                (categoryTask_secondary.is_remand === 1 || categoryTask_secondary.is_remand_previous === 1)
                                                  && isVerified()"
                                            >
                                              {{ getRemandComment(categoryTask_secondary.id, categoryTask_secondary.schedule_task_month_id) }}
                                            </div>
                                          </v-tooltip>
                                          <v-tooltip v-else bottom :disabled="!categoryTask_secondary.after_task_name && !taskNameError">
                                            <!-- 子タスク-タスク名-フリータスク -->
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-list-item-title
                                                v-bind="attrs"
                                                v-on="on"
                                                :class="{'red_text_bold': categoryTask_secondary.name_change_frag  && isVerified()}"
                                              >
                                                <div class="ellipsis childTask">
                                                  <!-- フリータスク -->
                                                  {{ categoryTask.index_no }}_{{ categoryTask_secondary.index_branch_no }}.
                                                  <template v-if="categoryTask_secondary.after_task_name && categoryTask_secondary.schedule_task_month_id">
                                                    _{{categoryTask_secondary.after_task_name}}
                                                  </template>
                                                </div>
                                              </v-list-item-title>
                                              <v-list-item-title 
                                                v-if="!categoryTask_secondary.schedule_task_month_id"
                                                v-bind="attrs" v-on="on"
                                              >
                                                <input 
                                                  v-model="categoryTask_secondary.after_task_name"
                                                  type="text" 
                                                  class="default-text-decoration box-size" 
                                                  :class="{ 
                                                    'text-underline': isEditTask(),
                                                    'yellow-background': isDetailDiff(categoryTask_secondary), 
                                                    'red-text': isFontChange(categoryTask_secondary)
                                                  }"
                                                  placeholder="タスク名"
                                                  @input="inputvalidation($event)"
                                                  maxlength="100"
                                                  oninput="javascript: this.value = this.value.slice(0, 99)"
                                                />
                                              </v-list-item-title>
                                            </template>
                                            <div v-if="categoryTask_secondary.after_task_name">
                                              {{ categoryTask_secondary.after_task_name }}
                                            </div>
                                            <div v-else-if="!categoryTask_secondary.after_task_name && taskNameError">
                                              タスク名を入力してください。
                                            </div>
                                            <div
                                              v-if="
                                                (categoryTask_secondary.is_remand === 1 || categoryTask_secondary.is_remand_previous === 1)
                                                && isVerified()"
                                            >
                                              {{ getRemandComment(categoryTask_secondary.id, categoryTask_secondary.schedule_task_month_id) }}
                                            </div>
                                          </v-tooltip>
                                        </td>

                                        <!-- 子タスク-変更後予定工数/回 -->
                                        <td  
                                          class="table-cell th5"
                                          :class="[
                                            {'red_text_bold': categoryTask_secondary.schedule_cost_change_frag  && isVerified()}
                                          ]"
                                        >
                                          <v-list-item-title>
                                            <span
                                              v-if="
                                                !categoryTask_secondary.standard_task_id &&
                                                categoryTask_secondary.actual_cost !== 0 && 
                                                categoryTask_secondary.actual_count !== 0
                                              "
                                            >
                                              <input
                                                v-model="categoryTask_secondary.after_cost"
                                                @input="changeHistoryManagement(
                                                  categoryTask_secondary,
                                                  smallCategory.category_secondary_id,
                                                  smallCategory.category_secondary_name
                                                );"
                                                class="text-underline-center" 
                                                type="number"
                                                maxlength="3"
                                                max="999"
                                                min="0"
                                                oninput="this.value = this.value.replace(/^0+/, '').slice(0, 3)"
                                              >m
                                            </span>
                                            <span v-else>
                                              {{ categoryTask_secondary.after_cost }}m
                                            </span>
                                          </v-list-item-title>
                                        </td>

                                        <!-- 子タスク-変更後回数 -->
                                        <td
                                          class="table-cell th5"
                                        >
                                          <v-list-item-title>
                                            <span>
                                              {{ categoryTask_secondary.after_count }}
                                            </span>
                                          </v-list-item-title>
                                        </td>

                                        <!-- 子タスク-変更後予定工数 -->
                                        <td
                                          class="table-cell th5"
                                          :class="[
                                            {'red_text_bold': categoryTask_secondary.schedule_cost_change_frag  && isVerified()}
                                          ]"
                                        >
                                          <v-list-item-title>
                                            <span>
                                              {{ categoryTask_secondary.after_cost * categoryTask_secondary.after_count }}m
                                            </span>
                                          </v-list-item-title>
                                        </td>

                                        <!-- 子タスク-実績工数/回 -->
                                        <td 
                                          class="table-cell th5"
                                          :class="{'red-background': !isRemandTask(categoryTask_secondary) && !isNotImplementedTask(categoryTask_secondary) 
                                            && isCostIncreasedBy20(categoryTask_secondary)}"
                                        >
                                          <span>
                                            {{ categoryTask_secondary.actual_cost }}m
                                          </span>
                                        </td>

                                        <!-- 子タスク-実績回数 -->
                                        <td class="table-cell th5">
                                          <span>
                                            {{ categoryTask_secondary.actual_count }}
                                          </span>
                                        </td>

                                        <!-- 子タスク-実績工数 -->
                                        <td class="table-cell th5">
                                          <span v-if="categoryTask_secondary.actual_cost && categoryTask_secondary.actual_count">
                                            {{ categoryTask_secondary.actual_total_cost }}m</span>
                                          <span v-else>0m</span>
                                        </td>

                                        <!-- 子タスク-予定外タスク -->
                                        <td class="table-cell th5">
                                          <span v-if="!categoryTask_secondary.schedule_task_month_id">●</span>
                                          <span v-else>-</span>
                                        </td>

                                        <!-- 子タスク-標準工数タスク -->
                                        <td class="table-cell th5">
                                          <span v-if="categoryTask_secondary.standard_task_id">●</span>
                                          <span v-else>-</span>
                                        </td>

                                        <!-- 子タスク-生産性評価 -->
                                        <td 
                                        class="table-cell th5"
                                        :class="[
                                          {'yellow-background': !(categoryTask_secondary.schedule_task_month_id || categoryTask_secondary.standard_task_id)
                                            && categoryTask_secondary.before_task_type_id !== categoryTask_secondary.after_task_type_id}
                                          ]"
                                      >
                                          <span v-if="categoryTask_secondary.schedule_task_month_id || categoryTask_secondary.standard_task_id">
                                            {{taskType(categoryTask_secondary.after_task_type_id)}}
                                          </span>
                                          <span v-else>
                                            <select 
                                            class="text-underline-center default-text-decoration custom-select" 
                                            v-model="categoryTask_secondary.after_task_type_id"
                                            :disabled="!isSelf() || !isEditTask()"
                                          >
                                              <option v-for="(option, options) in typeOptions" :value="option.value" :key="`childTaskType-${options}`">
                                                {{ option.text }}
                                              </option>
                                            </select>
                                          </span>
                                        </td>

                                        <!-- 子タスク-完了 -->
                                        <td class="table-cell th5">
                                          <span>
                                            <select 
                                              class="text-underline-center default-text-decoration custom-select" 
                                              :disabled="!isSelf() || !isEditTask()"
                                              v-model="categoryTask_secondary.is_completion"
                                            >
                                              <option v-for="(option, options) in completOptions" :value="option.value" :key="`childCompletion-${options}`">
                                                {{ option.text }}
                                              </option>
                                            </select>
                                          </span>
                                        </td>
                                      </tr>
                                    </template>
                                  </template>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          v-if="isSelf() && isEditTask()"
                          class="py-5-bottom text-show-hide py-0 my-0 col no-print"
                          ref="smallCategotyModal"
                          :inert="isOverlay"
                        >
                          <span 
                            v-if="largeCategory.category_primary_id"
                            class="relative" 
                            @click="toggleShowPopup(largeCategory)" 
                          >
                            +小カテゴリを追加する
                          </span>
                          <span v-if="largeCategory.category_add">
                            <category-pulldown class="category-pulldown">
                              <div class="add-small-category">
                                <v-text-field
                                  v-model="filteredKey"
                                  placeholder="小カテゴリ名検索"
                                  prepend-inner-icon="mdi-magnify"
                                  variant="solo"
                                  clearable
                                  maxlength="100"
                                />
                              </div>
                              <div
                                v-if="isSelf() && isEditTask()"
                                class="add-small-category"
                                @click="newCategory(largeCategory.category_primary_id)"
                              >
                                +新しい小カテゴリを作成する
                              </div>
                              <div
                                v-for="(option, options) in secondariesFilter(largeCategory.category_primary_id, filteredKey)" 
                                :key="`largeCategoryAdd-${options}`"
                                class="category-add"
                                @click="selectPulldown(option ,largeCategory)"
                                @mouseover="showPulldownIcon(options)"
                                @mouseleave="hidePulldownIcon()"
                              >
                                <span>{{option.text}}</span>
                                <span
                                  v-if="collectPulldownIcon(options, isPulldownIcon) && option.userId"
                                  class="pulldown-right-element"
                                >
                                  <v-icon
                                    small class="blue--text"
                                    @click="editCategory($event, option)"
                                  > 
                                    $edit 
                                  </v-icon>
                                  <v-icon
                                    small class="blue--text"
                                    @click="deleteCategory($event, deleteSmallArray(option))"
                                  > 
                                    $delete 
                                  </v-icon>
                                </span>
                              </div>
                            </category-pulldown>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!-- 工数表終了 -->
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <!-- メッセージを表示 -->
        <div v-if="categoryType" id="snackbar">選択した大カテゴリは既に使用されています</div>
        <div v-if="!categoryType" id="snackbar">選択した小カテゴリは既に使用されています</div>
      </div>

      <!-- Desktop: DB, Task -->
      <div
        class="content-db-list ml-2"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <MonthlyCheckList 
          v-show="showCheckList"
          ref="checkList"
          :phaseId="Number(phaseId)"
          :monthlyWording="monthlyWording"
          :comment="draftComment"
          @update-monthly-wording="updateMonthlyWording"
          @close="toggleDrawerRight()"
          @submit="submitNextPhase($event)"
        />
        <MonthlyCommentsHistory 
          v-show="showCommentsHistory"
          ref="commentsHistory"
          :commentsHistory="commentsHistory"
          :isInputComment="isInputCommentHistory"
          @close="toggleDrawerRight()"
          @submit="submitNextPhase($event)"
        />

        <MonthlyRemandTask
          v-show="showRemandTask"
          ref="remandTask"
          :remandTask="setRemandTaskComment()"
          @close="toggleDrawerRight()"
          @submit="submitNextPhase($event)"
        />
        
        <FreeTaskChangeHistory
          v-show="showFreeTaskChangeHistory"
          ref="freeTaskChangeHistory"
          :changeFreeTasks="monthlyScheduleChangeHistory"
          :unplannedFreeTask="unplannedFreeTasks"
          @data-updated="handleDataUpdate"
          @close="toggleDrawerRight()"
        ></FreeTaskChangeHistory>
                    
        <v-row v-show="!othersFrag && !showCheckList && !showCommentsHistory && !showRemandTask " class="mr-1">
          <v-col :cols="showDrawerRight ? '2' : '10'">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon @click="toggleDrawerRight('comments-history')" v-on="on">
                        $comment
                      </v-icon>
                      </template>
                    <span>コメント履歴</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip left>
                    <template v-slot:activator="{ on}">
                      <v-icon @click="toggleDrawerRight('free-task-change')" v-on="on">
                        $freetaskchangehistory
                      </v-icon>
                    </template>
                    <span>フリータスクの変更履歴</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>
      <!--/ Desktop: DB, Task -->
    </div>
      
    <MonthlyExport
      ref="export"
      v-if="openExport"
      :show="openExport"
      :userId="userId"
      :targetMonth="loadTargetMonth"
      :workingHours="`${approved_monthly_scheduled_cost} / ${total_min !== null ? total_min : 0}`"
      :changedMonthlyPlannedManHours="`${scheduleTotalHours * 60 + scheduleTotalMinutes}`"
      :monthlyActualManHours="`${actualTotalHours * 60 + actualTotalMinutes}`"
      :standardTaskRate="`${standardTaskEveluationRate}%`"
      :planExecutionEate="`${planActualRate}%`"
      :productivity="`${productivityRate}%`"
      :isCompleted="`${taskUsualRate}%`"
    ></MonthlyExport>

    <MonthlyCancelSubmit
      ref="cancel"
      v-if="showCancel"
      :show="showCancel"
      :isInputComment="isInputCommentReset"
      @submit="submitNextPhase($event)"
      @close="toggleDrawerRight()"
    ></MonthlyCancelSubmit>

    <!-- 小カテゴリ新規作成モーダル -->
    <AddSmallCategory
      :show="openNewCategory"
      :newLargeCategoryId="newLargeCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
    ></AddSmallCategory>

    <!-- 小カテゴリ編集モーダル -->
    <UpdateSmallCategory
      :show="openEditCategory"
      :largeCategoryId="updateLargeCategoryId"
      :smallCategoryName="updateSmallCategoryName"
      :smallCategoryId="updateSmallCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @closeEvent="setCategoryName($event)"
    ></UpdateSmallCategory>

    <!-- 月次予定タスク変更情報保存確認ダイアログ -->
    <MonthlyConfirmSave
      v-if="showConfirmSave"
      :show="showConfirmSave"
      @submit="save()"
      @close="toggleDrawerRight()"
    ></MonthlyConfirmSave>

    <!-- 月次予定提出不可ダイアログ -->
    <MonthlySubmissionError
      v-if="showSubmissionError"
      :show="showSubmissionError"
      @close="toggleDrawerRight()"
    ></MonthlySubmissionError>

    <Notification
      :show="showNotification"
      :notifications="statuses"
      :date="selectedDate"
    >
    </Notification>

    <!-- インフォメーションダイアログ -->
    <MonthlyExplainTask :show="showExplain"> </MonthlyExplainTask>

  </v-container>  
</template>

<script>
import MonthlyCheckList from "./modal/MonthlyCheckList";
import MonthlyCommentsHistory from "./modal/MonthlyCommentsHistory";
import MonthlyRemandTask from "./modal/MonthlyRemandTask";
import MonthlyCancelSubmit from "./modal/MonthlyCancelSubmit"
import FreeTaskChangeHistory from "./modal/FreeTaskChangeHistory";
import CategoryPulldown from '../schedule/modal/CategoryPulldown.vue';
import AddSmallCategory from '../schedule/modal/AddSmallCategory.vue';
import UpdateSmallCategory from "../schedule/modal/UpdateSmallCategory.vue";
import MonthlyConfirmSave from "./modal/MonthlyConfirmSave";
import MonthlySubmissionError from "./modal/MonthlySubmissionError";
import MonthlyExplainTask from "./modal/MonthlyExplainTask";

import {
  formatDateTime,
  formatTime,
  parseDateTime,
} from "../../helpers/datetime-helpers";
import {
  debounce_leading,
  isEmpty,
  isEmptyNumber,
} from "../../helpers/helpers";
import {
  GET_MEMBERS_API,
  CATEGORIES_API,
  PRIMARY_SUB_CATEGORY_API,
  GET_MONTHLY_ACTUALS_API,
  GET_ALL_WORDING_API,
  UPDATE_MONTHLY_ACTUALS_API,
  GET_MONTHLY_ACTUALS_COMMENTS_HISTORY_API,
  UPDATE_MONTHLY_ACTUALS_COMMENTS_HISTORY_API,
  NOTIFICATION_API
} from "../../constants/api";
import { mapGetters } from "vuex";
import * as Role from "../../constants/role";
import axios from "axios";
import * as SystemConstant from "../../constants/system";
import { apiErrorMessages } from "../../helpers/messages";
import {
  FC108,
  FC501,
  FC502,
  FC701,
  FC704,
  FC836,
  FC838,
  FC841,
  FC842,
  FC1301
} from "../../constants/fc";
import { STATUS_NO_CONTENT } from "../../constants/status";
import {
  HHmm1,
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMM3,
  yyyyMMddHHmm1,
  yyyyMMddHHmmss1,
  yyyyMMddHHmmss2,
  HHmmss2,
} from "../../constants/df";
import {
  COLOR_GRAY,
  COLOR_FREE_TASK,
  COLOR_DB_TASK
} from "../../constants/color";
import {
  CALENDAR_PRINT_LENGHT_MM,
  SLOT_30_SCALE,
  SLOT_60_SCALE,
} from "../../constants/calendar";
import { calendarMixin } from "@/mixins/calendar";
import MonthlyExport from "./components/MonthlyExport";
import Notification from "./modal/Notification.vue";
export default {
  layout: "default",
  middleware: Role.AUTH,
  metaInfo() {
    return { title: this.$t("title.monthly-actuals") };
  },
  destroyed() {
    this.$store.dispatch("common/isScheduleExpand", 0);
    window.removeEventListener("beforeunload", this.confirmSave);
  },
  components: {
    MonthlyCheckList,
    MonthlyCommentsHistory,
    MonthlyRemandTask,
    MonthlyCancelSubmit,
    FreeTaskChangeHistory,
    MonthlyExport,
    CategoryPulldown,
    AddSmallCategory,
    UpdateSmallCategory,
    MonthlyConfirmSave,
    MonthlySubmissionError,
    Notification,
    MonthlyExplainTask
  },

  mixins: [calendarMixin],
  data() {
    return {
      filteredKey: "",
      completOptions: [
        {value: 1, text: "●"},
        {value: 0, text: "-"}
      ],

      typeOptions: [
        {value: SystemConstant.TASK_TYPE.NORMAL_BUSINESS, text: "●"},
        {value: SystemConstant.TASK_TYPE.WORK_NOT_SUBJECT_TO_PRODUCTIVITY_EVALUATION, text: "-"}
      ],
      COLOR_GRAY: COLOR_GRAY,

      canApproveRemand: false,
      canSubmit: false,
      canSubmitCancel: false,
      wordingId : 40,


      actualMonthId:"",
      monthlyActuals:[],
      monthlyWording:[],
      commentsHistory:{},
      draftComment:"",
      remandTask:{},
      lastComment:{},

      /** フリータスクの変更履歴 */
      monthlyScheduleChangeHistory:[],
      unplannedFreeTasks: [],

      category_primary:[],

      expand: 0,
      statuses: [],
      showNotification: false,
      currentTab: "mobile-tabs-calendar",
      selectUser: "",
      phaseId: null,
      menuDate: false,
      members: [],
      selectedDate: formatDateTime(new Date()),
      userId: "",
      planId: "",
      statusText: "未提出",
      submitButton: {
        text: "提出",
        active: false,
        isReject: false,
      },
      showExplain: false,
      showDrawerRight: false,
      startWorkTime: "",
      endWorkTime: "",
      showRemandTask: false,
      showCheckList: false,
      showCommentsHistory: false,
      showCancel: false,
      showConfirmSave: false,
      showSubmissionError: false,
      showFreeTaskChangeHistory: false,
      isInputCommentHistory: false,
      isInputCommentReset: false,
      canViewComment: false,
      authorizeMessage: "",
      authorizeCheck: false,
      scrollTop: null,
      min: 5,
      timeGridLength: 0,
      isInDraft: false,
      showDraftText: false,
      confirmDraft: false,
      rejectDraft: false,
      listNotification: [],

      // 要素の初期値
      showElement: false,

      // ダブルクリック、ドラッグアンドドロップのフラグ設置
      isCreateLargeCategory: false,
      isCreateSmallCategory: true,

      // 合計月次工数で利用するデータを管理する
      monthlyTotal: 0,
      totalHours:0,
      totalMinutes:0,

      /** 変更後の月次予定工数 */
      scheduleTotal: 0,
      scheduleTargetTaskTypeTotal: 0,
      scheduleNotTargetTaskTypeTotal: 0,
      scheduleTotalHours: 0,
      scheduleTotalMinutes: 0,
      scheduleEvaluationTargetHours: 0,
      scheduleEvaluationTargetMinutes: 0,
      scheduleEvaluationNotTargetHours: 0,
      scheduleEvaluationNotTargetMinutes: 0,

      /** 月次実績工数 */
      actualTotal: 0,
      actualTargetTaskTypeTotal: 0,
      actualNotTargetTaskTypeTotal: 0,
      actualTotalHours: 0,
      actualTotalMinutes: 0,
      actualEvaluationTargetHours: 0,
      actualEvaluationTargetMinutes: 0,
      actualEvaluationNotTargetHours: 0,
      actualEvaluationNotTargetMinutes: 0,

      /** 標準工数タスク率 */
      actualTargetTaskTypeDbTotal: 0,
      completionActualTargetTaskTypeTotal: 0,
      standardTaskEveluationRate: 0,

      /** 計画実行率 */
      scheduleTargetTaskTypeCompletedTotal: 0,
      planActualRate: 0,
      
      /** 生産性 */
      actualTargetTaskTypeCompletedTotal: 0,
      productivityRate: 0,

      /** 生産性評価対象外業務(定常業務)を予定工数内で出来たか */
      scheduleNotTargetTaskTypeDbUsualCompletedTotal: 0,
      actualNotTargetTaskTypeDbUsualCompletedTotal: 0,
      taskUsualRate:0,

      // カテゴリプルダウン初期値
      parentCategories: [],
      childCategories: [],

      // ドラッグデータ
      dragCategoriesList: [],
      removeIndexList: [],
      dragoverIndex: {},
      isDrag: false,
      isMultiSelect: false,

      // 小カテゴリ作成用
      openNewCategory: false,
      newLargeCategoryId: 0,
      groupId: "",

      // 大カテゴリ・小カテゴリ判定用
      categoryType:false,

      isOverlay: false,
      isPulldownIcon: false, // カテゴリアイコンの表示状態
      openEditCategory: false, // カテゴリ編集モーダルの表示状態
      updateLargeCategoryId: 0,
      updateSmallCategoryName: "",
      updateSmallCategoryId: 0,

      loadTargetMonth: "", // 年月日
      dispTargetMonth: "", // yyyy年mm月

      inputWorkingHour: 0,
      inputWorkingMinute: 0,

      othersFrag: false,
      storageFrag: false,
      isSelectUserError: false,

      openExport: true,


      // 工数表
      monthlyActualsNotices: "",
      total_min: 0,
      approved_monthly_scheduled_cost: 0,

      /** 提出時のバリデーションチェック関連 */
      taskNameError: false,
      afterCostError: false,
      notCategoryError: false,

      scrollHeight: {
        height: 'auto'
      },
      resizeObserver: null,

      /** 未保存ダイアログ関連 */
      beforeSelectionUser: "",
      beforeSelectionCalendar: "",
    };
  },
  created() {
    this.$root.$on('printStarted', () => {
      this.openView();
    });

    this.$root.$refs.monthlyActualsComponent = this;

    this.categoryReconstruction();

    // スロットリング関数の作成(0.01秒に１回しか処理をしない)
    this.throttledHandleDragOver = this.throttle(this.handleDragOver, 10);

    window.addEventListener("beforeunload",this.confirmSave);
    
  },
  methods: {
    syncScroll() {
      const tables = this.$refs.tables;

      if (Array.isArray(tables)) {
        tables.forEach((table, index) => {
          // イベントリスナーが重複しないように、最初に削除
          table.removeEventListener('scroll', this.handleScroll);
          // 新しいイベントリスナーを登録
          table.addEventListener('scroll', this.handleScroll.bind(this, index));
        });
      }
    },

    handleScroll(index, event) {
      const tables = this.$refs.tables;
      const scrollLeft = event.target.scrollLeft;

      if (Array.isArray(tables)) {
        tables.forEach((t, tIndex) => {
          if (tIndex !== index) {
            t.scrollLeft = scrollLeft;
          }
        });
      }
    },
    
    initializeFlag() {
      this.storageFrag = false;
      this.taskNameError = false;
      this.afterCostError = false;
      this.notCategoryError = false;
    },

    throttle(func, limit) {
      let inThrottle;
      return function() {
          const args = arguments;
          const context = this;
          if (!inThrottle) {
              func.apply(context, args);
              inThrottle = true;
              setTimeout(() => inThrottle = false, limit);
          }
      };
    },

    setRemandTaskComment() {
      if (Object.keys(this.remandTask).length === 0) {
        return this.remandTask;
      }

      /** Deep Copy */
      const newRemandTask = JSON.parse(JSON.stringify(this.remandTask));
      newRemandTask.individual_tasks_comments.forEach(e => {
        e.comment = e.comment ?? "";
      });

      /** 未保存の場合、コメント入力欄を空にする */
      if (newRemandTask.draft_flag === 0) {
        newRemandTask.remand_comment = "";
        newRemandTask.individual_tasks_comments.forEach(individual_task => {
          individual_task.comment = "";
        });
      }

      return newRemandTask;
    },
    updateMonthlyActualsNotices(e) {
      this.monthlyActualsNotices = e.target.value;
    },
    openConfirmSaveDialog() {
      this.showConfirmSave = true;
    },
    save() {
      this.taskNameError = false;
      this.afterCostError = false;
      this.notCategoryError = false;
      this.showConfirmSave = false;
      this.monthlyPutValidation();
    },
    submitValidation(isReject) {

      /**
      * 下記ステータスの場合、チェックは実施しない
      * 1次確認待ち、2次確認待ち、確認済み
      */
      switch (this.phaseId) {
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
        case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
        case SystemConstant.PHASE_ID_VERIFIED:
          this.submit(isReject);
          break;
        default:
          /** タスク名、変更後予定工数/回のチェック */
          this.validation();

          if (this.taskNameError || this.afterCostError || this.notCategoryError) {
            this.showSubmissionError = true;
          } else {
            this.submit(isReject);
          }
          break;
      }
    },

    validation() {
      this.taskNameError = false;
      this.afterCostError = false;
      this.notCategoryError = false;

      const tasks = [];

      this.category_primary.forEach(primaryCategories => {
        primaryCategories.forEach(primaryCategory => {
          primaryCategory.category_secondary.forEach(secondaryCategory => {
            secondaryCategory.tasks.forEach(task => {
              tasks.push(task);
            })
          })
        })
      });

      // タスク名のチェック、変更後予定工数/回のチェック
      this.taskNameError = tasks.some(e => !e.after_task_name);
      this.afterCostError = tasks.some(e => !e.after_cost);
      this.notCategoryError = tasks.some(e => !e.after_category_secondary_id);
    },

    InitialDisplayCommentHistory () {
      if(
        this.phaseId !== SystemConstant.PHASE_ID_UNSUBMITTED
      ){
        this.toggleDrawerRight('comments-history');
      }
    },

    /*本人以外は変更操作無効にする*/
    isDisabled() {
      if (this.isSelf() && this.isEditTask()) {
        return false;
      } else {
        return true;
      }
    },

    updateMonthlyWording(newMonthlyWording) {
      this.monthlyWording = newMonthlyWording;
    },

    /** fc838(月次実績情報更新)put */
    async monthlyPutValidation(){
      this.storageFrag = false;
      const putMonthlyActualsStatus = await this.putMonthlyActuals(this.selectUser.id, this.loadTargetMonth, this.actualMonthId, this.lastComment.comment_id);
      const getCommentsHistoryStatus = await this.getCommentsHistory(this.selectUser.id, this.loadTargetMonth, this.actualMonthId);
      return putMonthlyActualsStatus === 200 && getCommentsHistoryStatus === 200;
    },

    /** yyyyMMをyyyy年MM月に変換 */
    formatYYYYMM(date) {
      const year = date.slice(0, 4);
      const month = date.slice(4);
      this.dispTargetMonth = `${year}年${month}月`;
    },


    /** 本日の日付をyyyyMM形式で取得する */
    getTargetMonth(){
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const formattedDate = `${year}${month}`;
      this.formatYYYYMM(formattedDate);

      return formattedDate;
    },
    
    /** カレンダーで選択した年月日を取得する */
    async selectCalendarMonth(e) {
      const [year, month] = e.split('-');
      const nextTargetMonth = `${year}年${month}月`;

      if (!this.confirmSaveByCalendar(nextTargetMonth)) {
        return;
      }

      this.initializeFlag();
      this.toggleDrawerRight();
      this.loadTargetMonth = `${year}${month}`;
      this.formatYYYYMM(this.loadTargetMonth);

      await this.getMembers();
      await this.getMonthlyActuals(this.selectUser.id ?? this.userId, this.loadTargetMonth);
      await this.getCommentsHistory(this.selectUser.id ?? this.userId, this.loadTargetMonth, this.actualMonthId);
      this.InitialDisplayCommentHistory();
      this.getCategories();
      this.getMonthlyWording();
      this.calculate();
      this.createFreeTaskChangeHistory();
      this.handleResize();
      this.syncScroll();
      // ポップアップ外をクリックした際にポップアップを閉じるイベント
      window.addEventListener("click", this.closeAddCategory);
    },

    /** 1月戻る */
    async decrementMonth() {
      const year = this.loadTargetMonth.slice(0, 4);
      const month = this.loadTargetMonth.slice(4);
      const date = new Date(Number(year), Number(month) - 2);
      const newYear = date.getFullYear();
      const newMonth = String(date.getMonth() + 1).padStart(2, '0');
      const nextTargetMonth = `${newYear}年${newMonth}月`;

      if (!this.confirmSaveByCalendar(nextTargetMonth)) {
        return;
      }

      this.initializeFlag();
      this.toggleDrawerRight();
      this.loadTargetMonth = `${newYear}${newMonth}`;
      this.formatYYYYMM(this.loadTargetMonth);

      await this.getMembers();
      await this.getMonthlyActuals(this.selectUser?.id ?? this.userId, this.loadTargetMonth);
      await this.getCommentsHistory(this.selectUser?.id ?? this.userId, this.loadTargetMonth, this.actualMonthId);
      this.InitialDisplayCommentHistory();
      this.getCategories();
      this.getMonthlyWording();
      this.calculate();
      this.createFreeTaskChangeHistory();
      this.handleResize();
      this.syncScroll();
      // ポップアップ外をクリックした際にポップアップを閉じるイベント
      window.addEventListener("click", this.closeAddCategory);
    },

    /** 1月進む */
    async incrementMonth() {
      const year = this.loadTargetMonth.slice(0, 4);
      const month = this.loadTargetMonth.slice(4);
      const date = new Date(Number(year), Number(month));
      const newYear = date.getFullYear();
      const newMonth = String(date.getMonth() + 1).padStart(2, '0');
      const nextTargetMonth = `${newYear}年${newMonth}月`;

      if (!this.confirmSaveByCalendar(nextTargetMonth)) {
        return;
      }

      this.initializeFlag();
      this.toggleDrawerRight();
      this.loadTargetMonth = `${newYear}${newMonth}`;
      this.formatYYYYMM(this.loadTargetMonth);

      await this.getMembers();
      await this.getMonthlyActuals(this.selectUser?.id ?? this.userId, this.loadTargetMonth);
      await this.getCommentsHistory(this.selectUser?.id ?? this.userId, this.loadTargetMonth, this.actualMonthId);
      this.InitialDisplayCommentHistory();
      this.getCategories();
      this.getMonthlyWording();
      this.calculate();
      this.createFreeTaskChangeHistory();
      this.handleResize();
      this.syncScroll();
      // ポップアップ外をクリックした際にポップアップを閉じるイベント
      window.addEventListener("click", this.closeAddCategory);
    },
    
    rejectOnClick(isRejectAll){
      if (this.commentsHistory.comment && this.commentsHistory.comment.length > 1) {
        switch(this.phaseId){
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_VERIFIED:
          case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            this.isInputCommentHistory = false;
            this.getCommentsHistory(this.selectUser.id, this.loadTargetMonth, this.actualMonthId);
            this.toggleDrawerRight('comments-history');
            break;
          case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            this.isInputCommentHistory = isRejectAll;
            this.getCommentsHistory(this.selectUser.id, this.loadTargetMonth, this.actualMonthId);
            this.toggleDrawerRight('comments-history');
            break;
          default:
            break;

        }
      } 
    },

    /** 小カテゴリ編集 */
    editCategory(event, option) {
      event.stopPropagation();
      this.updateLargeCategoryId = option.largeId;
      this.updateSmallCategoryName = option.text;
      this.updateSmallCategoryId = option.value;
      this.groupId = this.loginInfo.group_id;
      this.openEditCategory = !this.openEditCategory;
    },

    /** 工数表に選択した小カテゴリが存在するかチェック */
    existenceCheck(deleteCategory){
      let existence;
      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            if(smallCategory.category_secondary_id === deleteCategory) existence = true;
          });
        });
      });
      return existence;
    },

    /** 編集した小カテゴリが工数表に存在するかチェック */
    setCategoryName(newcategory) {
      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            if(smallCategory.category_secondary_id === newcategory.category_secondary_id) {
              /** 小カテゴリ名を編集した名称に変更 */
              smallCategory.category_secondary_name = newcategory.category_secondary_name;
            }
          });
        });
      });
    },

    /** 削除小カテゴリが工数表にあるか確認 */
    deleteSmallArray(option) {
      return {
        category_primary_id: option.largeId ,
        category_secondary_id: option.value ,
        category_secondary_name: option.text ,
        user_id: this.userId ,
        category_existence: this.existenceCheck(option.value),
      }
    },

    /** 小カテゴリ削除 */
    deleteCategory(event, item) {
      event.stopPropagation();

      /** 工数表に登録されている小カテゴリの場合 */
      if (item.category_existence) {
        this.notDeleteCategory();
      } else {
        this.$confirm(this.$t("label.confirm.delete_secondary_category"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        })
          .then(async () => {
            try {
              await axios.delete(
                PRIMARY_SUB_CATEGORY_API(
                  item.category_primary_id,
                  item.category_secondary_id,
                  item.user_id
                )
              );

              this.childCategories = this.childCategories.filter(
                e => e.value !== item.category_secondary_id
              );

              this.$toast(
                `${item.category_secondary_name}を削除しました。`,
                3000
              );
            } catch (error) {
              const status = error.response?.status;
              if (status === 404) {
                this.$alert(error.response.data.message, {
                  width: "820px",
                });
              } else {
                apiErrorMessages(this, FC704, status);
              }
            }
          })
          .catch(() => {});
      }
    },

    /** 小カテゴリが工数表に登録されている場合アラート */
    notDeleteCategory() {
      this.$alert([this.$t("label.confirm.not_delete_category.line1")])
        .then(() => {})
        .catch(() => {});
    },

    /** mouseoverでアイコン表示 */
    showPulldownIcon(pulldownOption) {
      this.isPulldownIcon = pulldownOption;
    },

    /** mouseleaveでアイコン非表示 */
    hidePulldownIcon(){
      this.isPulldownIcon = "";
    },

    isEditTask(){
      if(this.isSelf()){
        switch(this.phaseId){
          case SystemConstant.PHASE_ID_UNSUBMITTED:
          case SystemConstant.PHASE_ID_SUBMISSION_RESET:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            return true;
          default:
            return false;
        }

      }
      return false;
    },
    /** index番号でアイコン表示を制御 */
    collectPulldownIcon(str1, str2) {
      if(str1 === str2) return true;
      else return false;
    },

    /** タスク名用入力チェック用 */
    inputValidation(event) {
      const text = event.target;
      
      if (text.value.length !== 0) {
        text.classList.remove("text-underline-validation");
        text.classList.add("text-underline");
      } else {
        text.classList.remove("text-underline");
        text.classList.add("text-underline-validation");
      }
    },

    /** 工数/回数用入力チェック用 */
    inputCenterValidation(event){
      let text = event.target;
      if(text.value && text.value != 0) text.className="text-underline-center";
      else text.className="text-underline-center-validation"
    },

    /** カテゴリ配列を出力する */
    create_category_primary(){
      this.actualMonthId = this.monthlyActuals[0].id;
      const newData = this.monthlyActuals[0].category_primary;
      this.storageFrag = false;
      return this.assigningIndexNumbers(newData);
    },

    assigningIndexNumbers(data) {
      let index = 0;
      data.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              index++;
              task.index_no = index;
              task.after_category_secondary_id = smallCategory.category_secondary_id;
              task.task_secondary.forEach((taskSecondary, taskSecondaryIdx) => {
                taskSecondary.index_no = index;
                taskSecondary.index_branch_no = taskSecondaryIdx + 1;
                taskSecondary.after_category_secondary_id = smallCategory.category_secondary_id;
              });
            });
          });
        });
      });

      return data;
    },

    /** 実施頻度を出力 */
    taskTiming(taskTimingId){
      switch (taskTimingId){
        case 10:
          return "-";
        case 20:
          return "月次";
        case 30:
          return "週次";
        case 40:
          return "日次";
        default:
          return "-";
      }
    },
    
    /** 実施頻度を出力 */
    taskType(taskTypeId){
      if(taskTypeId === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) {
        return "●";
      } else {
        return "-";
      }
    },

    /** 小カテゴリの合計月次予定工数計算 */
    smallCategoryScheduleTotal(smallCategory){
      let sum = 0;

      const calulationProcess = (task) => {
        if (!task.category_secondary_id){
          sum += (task.after_cost * task.after_count);
        }
      }
      
      smallCategory.tasks.forEach(task => {
        if (task.task_secondary && task.task_secondary.length !== 0) {
          task.task_secondary.forEach(child => {
            calulationProcess(child);
          })
        } else {
          calulationProcess(task);
        }
      });
      return this.categoryTotalTime(sum);
    },

    /** 小カテゴリの合計月次実績工数計算*/
    smallCategoryActualTotal(smallCategory){
      let sum = 0;

      const calulationProcess = (task) => {
        sum += (task.actual_cost * task.actual_count);
      }
      
      smallCategory.tasks.forEach(task => {
        if (task.task_secondary && task.task_secondary.length !== 0) {
          task.task_secondary.forEach(child => {
            calulationProcess(child);
          })
        } else {
         calulationProcess(task);
        }
      });
      return this.categoryTotalTime(sum);
    },

    /** 大カテゴリの合計月次予定工数計算 */
    largeCategoryScheduleTotal(largeCategory){
      let sum = 0;

      const calulationProcess = (task) => {
        if (!task.category_secondary_id){
          sum += (task.after_cost * task.after_count);
        }
      }
      
      largeCategory.category_secondary.forEach(smallCategory => {
        smallCategory.tasks.forEach(task => {
          if (task.task_secondary && task.task_secondary.length !== 0) {
            task.task_secondary.forEach(child => {
              calulationProcess(child)
            })
          } else {
            calulationProcess(task)
          }
        });
      });
      return this.categoryTotalTime(sum);
    },

    /** 大カテゴリの合計月次実績工数計算 */
    largeCategoryActualTotal(largeCategory){
      let sum = 0;

      const calulationProcess = (task) => {
        sum += (task.actual_cost * task.actual_count);
      }
      
      largeCategory.category_secondary.forEach(smallCategory => {
        smallCategory.tasks.forEach(task => {
          if (task.task_secondary && task.task_secondary.length !== 0) {
            task.task_secondary.forEach(child => {
              calulationProcess(child);
            })
          } else {
            calulationProcess(task);
          }
        });
      });
      return this.categoryTotalTime(sum);
    },

    padZero(number){
      return number.toString().padStart(2, '0');
    },

    /** 計算結果をHHhMMm形式に変換 */
    categoryTotalTime(sum){
      let totalHours = Math.floor(sum / 60);
      let totalMinutes = sum % 60;
      let totalTime = totalHours + "h" + this.padZero(totalMinutes) + "m";
      return totalTime;
    },

    /** 小カテゴリ作成モーダル */
    newCategory(largeCategoryId) {
      this.newLargeCategoryId = largeCategoryId;
      this.groupId = this.loginInfo.group_id;
      this.openNewCategory = !this.openNewCategory;
    },

    closeAddCategory(event) { 
      const largeCategotySelectors = this.$refs.largeCategotySelector
      const smallCategotySelectors = this.$refs.smallCategotySelector
      const smallCategotyModals = this.$refs.smallCategotyModal;

      let functionCkeck = false; //保存を活性化するかどうかのフラグ
      const newData = JSON.parse(JSON.stringify(this.category_primary));

      if(largeCategotySelectors){
        if (!largeCategotySelectors.contains(event.target)) {
          this.showElement = false
        }
      }

      // すべての smallCategotyModal に対して処理を実行
      let closeSmallCategotySelector = true;
      let closeSmallCategotyModal = true;

      if (Array.isArray(smallCategotyModals)) {
        smallCategotyModals.forEach(smallCategotyModal => {
          if (smallCategotyModal.contains(event.target)) {
            closeSmallCategotyModal = false; // クリックが内側だった場合
          }
        });
      }

      if (Array.isArray(smallCategotySelectors)) {
        smallCategotySelectors.forEach(smallCategotyModal => {
          if (smallCategotyModal.contains(event.target)) {
            closeSmallCategotySelector = false; // クリックが内側だった場合
          }
        });
      }

      if (closeSmallCategotyModal || closeSmallCategotySelector) {
        newData.forEach(allCategory => {
          allCategory.forEach(largeCategory => {
            if(closeSmallCategotyModal && largeCategory.category_add){
              largeCategory.category_add = false;
              functionCkeck = true;
            }
            if(closeSmallCategotySelector){
              largeCategory.category_secondary.forEach(smallCategory => {
                if(smallCategory.category_change){
                  smallCategory.category_change = false;
                  functionCkeck = true;
                }
              });
            }
          });
        });
      }

      if(functionCkeck){
        this.category_primary = newData;
      }
      this.clearFilteredKey();
    },

    /** 小カテゴリプルダウンでユーザーが選択した値を取得 */
    handleSelectionChange(id, smallCategory, largeCategory) {

      this.founds = false;
      largeCategory.filter(item => {
        if(item.category_secondary_id === id.value) return this.founds = true;
      })

      if(!this.founds){
        // 選択した小カテゴリに変更
        smallCategory.category_secondary_id = id.value;
        smallCategory.category_secondary_name = id.text;
        this.changeShowPopup(smallCategory);
      }else{
        this.indicationPopup();
      }
    },

    /** ゆっくり表示させるポップアップ */
    indicationPopup(){
      const bar = document.getElementById("snackbar");
      bar.className = "show";
      setTimeout(function(){ bar.className = bar.className.replace("show", ""); }, 3000);
    },

    /** プルダウンでクリックした要素を取得する */
    selectPulldown(option, largeCategory) {
      const secondValue = { 
        category_secondary_id: option.value ,
        category_secondary_name: option.text ,
        category_visible: true ,
        category_add: false,
        isMonthlyScheduleAddCategory: false,
        category_change: false,
        tasks:[]
      }

      this.categoryType = false;

      this.addPulldpwn(secondValue, this.category_primary, largeCategory);
      this.clearFilteredKey();
      // DOM 更新が完了した後に syncScroll を呼び出す
      this.$nextTick(() => {
        this.syncScroll();
      });
    },

    /** プルダウンでクリックした大カテゴリを新規で追加する */
    addSelectPulldown(option) {

      // 格納する大カテゴリ値を取得
      const firstValue = { 
        category_primary_id: option.value ,
        category_primary_name: option.text ,
        category_visible:true ,
        category_add:false ,
        category_secondary:[]
      };

      this.categoryType = true;

      this.addLargePulldpwn(firstValue, this.category_primary, option.value);
      this.showElement = false;
    },

    /** 選択した大カテゴリを基に各処理を実施 */
    addLargePulldpwn(firstValue, category_primary, largeId) {
      this.founds = true;
      // 大カテゴリの一致を検索
      category_primary.forEach(element => {
        element.filter(items => {
          if (items.category_primary_id === largeId) {
            this.founds = false;
          }
        });
      });

      // 特定の大カテゴリに選択した小カテゴリを挿入
      if (this.founds) {
        if (category_primary.length === 0) {
          category_primary.push([]);
        }
        category_primary[0].push(firstValue);
      } else {
        this.indicationPopup();
      }
    },

    /** プルダウンから選択したカテゴリを追加する */
    addPulldpwn(secondValue, category_primary, largeCategory) {
      // 大カテゴリの一致を検索
      category_primary[0].forEach(element => {
        // 追加元、追加先の大カテゴリIDが違う場合、小カテゴリの追加は行わない
        if (element.category_primary_id !== largeCategory.category_primary_id) {
          return
        }
        let founds = true;
        // 小カテゴリの一致を検索
        element.category_secondary.forEach(ele => {
          if (typeof ele === 'object' && ele !== null) {
            // 小カテゴリ一致
            if (ele.category_secondary_id === secondValue.category_secondary_id) {
              founds = false; // 小カテゴリ挿入中止フラグ
            }
          }
        });

        // 特定の大カテゴリに選択した小カテゴリを挿入
        if (founds) {
          element.category_secondary.push(secondValue);
          this.toggleShowPopup(largeCategory);
        } else {
          this.indicationPopup();
        } 
      });
    },

    /** 小カテゴリのプルダウン用ポップアップを表示する */
    toggleShowPopup(item){
      const newData = JSON.parse(JSON.stringify(this.category_primary));
      newData.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          if(largeCategory.category_primary_id === item.category_primary_id){
            largeCategory.category_add = !largeCategory.category_add;
          } else {
            largeCategory.category_add = false;
          }
        });
      });
      this.category_primary = newData;
    },

    /** 小カテゴリのプルダウン用ポップアップを表示する */
    changeShowPopup(item){
      const newData = JSON.parse(JSON.stringify(this.category_primary));
      newData.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            if(smallCategory.category_secondary_id === item.category_secondary_id){
              smallCategory.category_change = !smallCategory.category_change;
            } else {
              smallCategory.category_change = false;
            }
          });
        });
      });
      this.category_primary = newData;
    },

    /** FC701 カテゴリ情報を取得 */
    async getCategories() {
      try {
        this.parentCategories = [];
        this.childCategories = [];
        this.groupId = this.loginInfo.group_id;
        const { data, status } = await axios.get(CATEGORIES_API(this.groupId, this.userId));

        let Categories = status === STATUS_NO_CONTENT ? [] : data.result;

        Categories.forEach((category) => {
          this.parentCategories.push({ value: category.id, text: category.name });

          category.secondaries.forEach((secondary) => {
            this.childCategories.push({
              largeId: category.id,
              value: secondary.id,
              text: secondary.name,
              userId: secondary.user_id,
              changeFrag: secondary.is_changeability
            });
          });
        });

      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC701, status);
      }
    },

    /** FC836 月次実績情報を取得 */
    async getMonthlyActuals(userId, targetMonth) {
      this.othersFrag = false;
      try {

        this.$router.replace({
          path: "/monthly-actuals",
          query: { 
            user_id: userId,
            target_month: targetMonth,
          },
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });

        const { data, status } = await axios.get(GET_MONTHLY_ACTUALS_API(
          userId,
          targetMonth,
        ));

        this.monthlyActuals = status === STATUS_NO_CONTENT ? [] : data.result;

        if (status === STATUS_NO_CONTENT && this.isSelf()) {
          await this.postMonthlyActuals(userId, targetMonth);
        } else if (status === STATUS_NO_CONTENT && !this.isSelf()) {
          this.othersFrag = true;
        } else {
          this.category_primary = this.create_category_primary();
        }

        this.handleMonthlyActuals();
      } catch (error) {
        const status = error.response?.status;

        if (status === STATUS_NO_CONTENT && this.isSelf()) {
          await this.postMonthlyActuals(userId, targetMonth);
        } else if (status === STATUS_NO_CONTENT && !this.isSelf()) {
          this.othersFrag = true;
        } else {
          apiErrorMessages(this, FC836, status);
        }
      }
    },
    
    /** FC838 月次予定情報を登録 */
    async postMonthlyActuals(userId, targetMonth) {
      try {

        this.$router.replace({
          path: "/monthly-actuals",
          query: { 
            user_id: userId,
            target_month: targetMonth,
          },
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });

        const params = {}

        const { data, status } = await axios.post(UPDATE_MONTHLY_ACTUALS_API(
          userId,
          targetMonth,
        ), params);

        if (status === STATUS_NO_CONTENT) {
          this.othersFrag = true;
        }

        this.monthlyActuals = status === STATUS_NO_CONTENT ? [] : data.result;
        this.category_primary = this.create_category_primary();
        this.monthlyActualsNotices = this.monthlyActuals[0].special_notes_regarding_evaluation;
        
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC838, status);
      }
    },

    calculateWorkingHours(start, end) {
      const parseTime = (time) => {
        const hours = parseInt(time.substring(0, 2), 10);
        const minutes = parseInt(time.substring(2, 4), 10);
        const seconds = parseInt(time.substring(4, 6), 10);
        return { hours, minutes, seconds };
      };

      const startParsed = parseTime(start);
      const endParsed = parseTime(end);

      const startDate = new Date();
      startDate.setHours(startParsed.hours, startParsed.minutes, startParsed.seconds);

      const endDate = new Date();
      endDate.setHours(endParsed.hours, endParsed.minutes, endParsed.seconds);

      const differenceInMilliseconds = endDate - startDate;

      const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
      const roundedHours = Math.round(differenceInHours * 2) / 2; // 0.5時間単位で丸める
      const minTime = 0 //設定されている就業時間が0時間以下
      const maxTime = 16 //設定されている就業時間が16時間以上

      if (roundedHours <= minTime || maxTime < roundedHours) {
        return this.workTimeDefault; // アカウントに設定されてる就業時間が0以下か16以上の場合
      }

      const breakTime = 10; //休憩1時間 
      return roundedHours * 10 >= 20 ? roundedHours * 10 - breakTime :roundedHours * 10;
    },

    canSaved(){
      this.storageFrag = true;
    },

    updateTotalMin(workDays,workTime){
      const newTotalMin = workDays * workTime * 6;
      this.inputWorkingHour = Math.floor(newTotalMin / 60);
      this.inputWorkingMinute = newTotalMin % 60;
      this.storageFrag = true;
    },

    /** FC838 月次予定情報を更新 */
    async putMonthlyActuals(userId, targetMonth, actualMonthId, commentMonthId) {
      try {

        this.$router.replace({
          path: "/monthly-actuals",
          query: { 
            user_id: userId,
            target_month: targetMonth,
          },
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
        
        const params = {
          comment: this.monthlyActualsNotices,
          revision_array: this.getActualTasksMonthParam(this.category_primary)
        }

        const { data, status } = await axios.put(UPDATE_MONTHLY_ACTUALS_API(
          userId,
          targetMonth,
          actualMonthId,
          commentMonthId
        ), params);

        this.monthlyActuals = status === STATUS_NO_CONTENT ? [] : data.result;
        
        if (status === 200) {
          this.category_primary = this.create_category_primary();
        }

        this.handleMonthlyActuals();

        return status

      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC838, status);
        return status;
      }
    },
    
    handleMonthlyActuals(){
      this.total_min = this.monthlyActuals[0].total_min;
      this.approved_monthly_scheduled_cost = this.monthlyActuals[0].approved_monthly_scheduled_cost;
      this.monthlyActualsNotices = this.monthlyActuals[0].special_notes_regarding_evaluation;
      this.storageFrag = false;
    },

    getInputTotalMin(){
      return Number(this.inputWorkingHour) * 60 + Number(this.inputWorkingMinute);
    },
    
    getActualTasksMonthParam(data){
      const revision_array = []
      data.forEach(result => {
        result.forEach(primary => {
          primary.category_secondary.forEach(secondary => {
            secondary.tasks.forEach(task => {
              if (task.task_secondary.length === 0) {
                revision_array.push({
                    actual_task_id : task.id,
                    schedule_task_month_id : task.schedule_task_month_id,
                    actual_free_task_id : task.actual_free_task_id ?? null,
                    cost: task.after_cost,
                    count: task.after_count,
                    task_type_id: task.after_task_type_id,
                    task_name: task.after_task_name,
                    detail: task.after_detail,
                    category_secondary_id: secondary.category_secondary_id,
                    name: secondary.category_secondary_name,
                    after_is_completion: task.is_completion,
                    index_no: task.index_no,
                    index_branch_no: null,
                    is_remand: task.is_remand === 1 || task.is_remand_previos === 1 ? 1 : 0,
                  });
              } else {
                task.task_secondary.forEach(child => {
                  revision_array.push({
                    actual_task_id : child.id,
                    schedule_task_month_id : child.schedule_task_month_id,
                    actual_free_task_id : task.actual_free_task_id ?? null,
                    cost: child.after_cost,
                    count: child.after_count,
                    task_type_id: child.after_task_type_id,
                    task_name: child.after_task_name,
                    detail: child.after_detail,
                    category_secondary_id: secondary.category_secondary_id,
                    name: secondary.category_secondary_name,
                    after_is_completion: child.is_completion,
                    index_no: child.index_no,
                    index_branch_no: child.index_branch_no,
                    is_remand: child.is_remand === 1 || child.is_remand_previos === 1 ? 1 : 0,
                  });
                })
              }
            })
          })
        })
      });

      return revision_array;
    },


    /** FC841 コメント履歴を取得 */
    async getCommentsHistory(userId, targetMonth, actualMonthId) {
      this.storageFrag = false;
      try {
        const { data, status } = await axios.get(GET_MONTHLY_ACTUALS_COMMENTS_HISTORY_API(
          userId,
          actualMonthId
        ));
        this.commentsHistory = status === STATUS_NO_CONTENT ? [] : data.result;

        let params = {
            phase_type: SystemConstant.PHASE_TYPE_UNSUBMITTED,
            individual_tasks_comments:[]
        }
        if(!data.result.comment && this.isSelf()) await this.postCommentsHistory(userId, targetMonth, actualMonthId, params);

        this.handleCommentsHistory();

        return status;
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC841, status);
        return status;
      }
    },

    /** FC842 コメント履歴を登録 */
    async postCommentsHistory(userId, targetMonth, actualMonthId ,params) {
      try {

        this.$router.replace({
          path: "/monthly-actuals",
          query: { 
            user_id: userId,
            target_month: targetMonth,
          },
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });

        const { data, status } = await axios.post(UPDATE_MONTHLY_ACTUALS_COMMENTS_HISTORY_API(
          userId,
          targetMonth,
          actualMonthId,
        ), params
      );

        this.commentsHistory = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC842, status);
      }
    },

    /** FC842 コメント履歴を取得 */
    async putCommentsHistory(user_id,loadTargetMonth,actualMonthId,commentsHistory) {
      try {
      
        let params = {
            phase_type: commentsHistory.phase_type,
            comment: commentsHistory.comment,
            remand_comment: commentsHistory.remand_comment,
            fix_comment: commentsHistory.fix_comment,
            draft_flag: commentsHistory.draft_flag,
            individual_tasks_comments: commentsHistory.individual_tasks_comments,
            special_notes_regarding_evaluation: this.monthlyActualsNotices
        }

        const { data, status } = await axios.put(
          UPDATE_MONTHLY_ACTUALS_COMMENTS_HISTORY_API(
            user_id, 
            loadTargetMonth, 
            actualMonthId
          ), params);

        this.commentsHistory = status === STATUS_NO_CONTENT ? [] : data.result;
        
        if(commentsHistory.phase_type === SystemConstant.PHASE_TYPE_REJECT) {
          this.initializeIsRemandPrevious();
        }
        this.handleCommentsHistory();
        if(commentsHistory.draft_flag === 1){
          this.$toast('コメントを下書き保存しました。', 3000);
        }
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC841, status);
      }
    },

    initializeIsRemandPrevious() {
      const newData = JSON.parse(JSON.stringify(this.category_primary));
      newData.forEach(allCategory => {
          allCategory.forEach(largeCategory => {
              largeCategory.category_secondary.forEach(smallCategory => {
                  smallCategory.tasks.forEach(task => {
                      task.is_remand_previous = 0;
                  });
              });
          });
      });
      this.category_primary = newData;
    },

    async handleCommentsHistory() {
      this.canViewComment = !!this.commentsHistory.can_view_comment;
      this.canApproveRemand = !!this.commentsHistory.can_approve_remand;
      this.canSubmit = !!this.commentsHistory.can_submit;
      this.canSubmitCancel = !!this.commentsHistory.can_submit_cancel;

      this.isInDraft = !!this.commentsHistory.is_in_draf === 0;
      this.wordingId = this.commentsHistory.wording_id;
      
      this.remandTask = JSON.parse(
        JSON.stringify(this.commentsHistory.comment[this.commentsHistory.comment.length - 1])
      );
      this.lastComment = JSON.parse(
        JSON.stringify(this.commentsHistory.comment[this.commentsHistory.comment.length - 1])
      );
      if(this.remandTask.draft_flag === 0) {
        this.remandTask.individual_tasks_comments = []
      } 
      
      if(!this.remandTask.individual_tasks_comments){
        this.remandTask.individual_tasks_comments = [];
      }
      if(!this.remandTask.remand_comment){
        this.remandTask.remand_comment = '';
      }
      this.isInDraft = this.remandTask.draft_flag === 1;

      this.draftComment = this.remandTask.draft_flag === 1 ? this.remandTask.comment : '';
      await this.createRemandTask();
      this.statusText = this.getStatusText({
        phase_id: this.remandTask.phase_id,
      });
      this.submitButton = this.getSubmitButton({
        phase_id: this.remandTask.phase_id,
      });
    },
    
    /** FC1301 チェックリスト情報を取得 */
    async getMonthlyWording() {
      try {
        const { data, status } = await axios.get(GET_ALL_WORDING_API(this.wordingId));
        this.monthlyWording = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC1301, status);
      } 
    },

    async createRemandTask() {
      if(this.isSelf() || !this.isSupervisor()){
        return;
      }
      const newData = JSON.parse(JSON.stringify(this.category_primary));

      newData.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(categoryTask => {
              if (categoryTask.task_secondary.length === 0) {
                if (categoryTask.is_remand === 1) {
                  categoryTask.category_secondary_name = smallCategory.category_secondary_name;
                  this.addRemandTask(categoryTask);
                } else {
                  this.removeRemandTask(categoryTask);
                }
              } else {
                categoryTask.task_secondary.forEach(categoryTaskSecondary => {
                  if (categoryTaskSecondary.is_remand === 1) {
                    categoryTaskSecondary.category_secondary_name = smallCategory.category_secondary_name;
                    this.addRemandTask(categoryTaskSecondary);
                  } else {
                    this.removeRemandTask(categoryTaskSecondary);
                  }
                });
              }
            });
          });
        });
      });
    },
    
    addRemandTask(categoryTask) {
      const newRemandTask = this.remandTask ? { ...this.remandTask } : { individual_tasks_comments: [] };

      if (!newRemandTask.individual_tasks_comments) {
        this.$set(newRemandTask, 'individual_tasks_comments', []);
      }

      let existingIndex = -1;
      if (categoryTask.id && categoryTask.schedule_task_month_id){
        existingIndex = newRemandTask.individual_tasks_comments.findIndex(
          comment => 
            comment.actual_task_id === categoryTask.id &&
            comment.schedule_task_month_id === categoryTask.schedule_task_month_id
        );
      } else if (categoryTask.id){
        existingIndex = newRemandTask.individual_tasks_comments.findIndex(
          comment => comment.actual_task_id === categoryTask.id
        );
      } else if (categoryTask.schedule_task_month_id){
        existingIndex = newRemandTask.individual_tasks_comments.findIndex(
          comment => comment.schedule_task_month_id === categoryTask.schedule_task_month_id
        );
      }
      
      if (existingIndex === -1) {
        newRemandTask.individual_tasks_comments.push({
          schedule_task_month_id: categoryTask.schedule_task_month_id,
          actual_task_id: categoryTask.id,
          comment: "",
          draft_flag: 0,
          task_info: [{
            name: categoryTask.after_task_name,
            category_secondary_name: categoryTask.category_secondary_name,
            index_no: categoryTask.index_no,
            index_branch_no: categoryTask.index_branch_no,
          }]
        });
        this.$set(this, 'remandTask', newRemandTask);
      }
    },

    removeRemandTask(categoryTask) {
      if (this.remandTask && this.remandTask.individual_tasks_comments) {
        let index = -1;
        if (categoryTask.id && categoryTask.schedule_task_month_id){
          index = this.remandTask.individual_tasks_comments.findIndex(
            comment => 
              comment.actual_task_id === categoryTask.id &&
              comment.schedule_task_month_id === categoryTask.schedule_task_month_id
          );
        } else if (categoryTask.id){
          index = this.remandTask.individual_tasks_comments.findIndex(
            comment => comment.actual_task_id === categoryTask.id
           );
        } else if (categoryTask.schedule_task_month_id){
          index = this.remandTask.individual_tasks_comments.findIndex(
            comment => comment.schedule_task_month_id === categoryTask.schedule_task_month_id
           );
        }
        if (index !== -1) {
          const newRemandTask = { ...this.remandTask };
          newRemandTask.individual_tasks_comments.splice(index, 1);
          this.$set(this, 'remandTask', newRemandTask);
        }
      } else {
        // 配列が存在しない場合はから配列をセット
        this.$set(this.remandTask, 'individual_tasks_comments', []);
      }

    },

    getRemandComment(id, scheduleTaskMonthId) {
      const task = this.lastComment.individual_tasks_comments?.find(
        comment => {
          if(id && scheduleTaskMonthId){
            return comment.actual_task_id === id && comment.schedule_task_month_id === scheduleTaskMonthId;
          } else if(id){
            return comment.actual_task_id === id;
          } else if (scheduleTaskMonthId){
            return comment.schedule_task_month_id === scheduleTaskMonthId;
          }
        }
      );
      if (task) {
        return task.comment;
      }
    },
    
    /** user選択と同一の大カテゴリIDを持つレコードを絞り込み */
    secondariesFilter(largeCategoryId, filteredKey){
      let items = this.childCategories.filter(item => {
        if(item.largeId === largeCategoryId) {
          if(filteredKey){
            if(item.text.includes(filteredKey)){
              return item
            }
          } else {
            return item
          }
        }
      })
      return items;
    },

    clearFilteredKey(){
      this.filteredKey = "";
    },
    
    handleResize() {
      const headerHeight = this.$refs.header.clientHeight;
      const vh = window.innerHeight * 0.01;
      const calculatedHeight = 
      `calc((var(--vh, ${vh}px) * 100) - 12px - 60px - 1px - ${headerHeight}px)`;
      this.scrollHeight.height = calculatedHeight;
      if (this.$vuetify.breakpoint.mobile && this.showDrawerRight) {
        this.toggleDrawerRight();
      }
    },

    /** 合計月次工数の予定時間(分子)を計算する */
    calculateMonthlySum(){
      let sum = 0;

      const calulationProcess = (task) => {
        sum += (task.task * task.count);
      }
      
      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.task_secondary && task.task_secondary.length !== 0) {
                task.task_secondary.forEach(child => {
                  calulationProcess(child);
                })
              } else {
                calulationProcess(task);
              }
            });
          });
        });
      });
      this.monthlyTotal = sum;
    },

    categoryReconstruction() {
      const newData = JSON.parse(JSON.stringify(this.category_primary));
      this.category_primary = this.assigningIndexNumbers(newData);
    },

    openView() {
      let newData = this.category_primary.slice();
      newData.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_visible = true;
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.category_visible = true;
            smallCategory.tasks.forEach(task => {
              task.tasks_visible = true;
            });
          });
        });
      });

      this.category_primary = newData; 
    },

    openViewLargeCategory() {
      let newData = this.category_primary.slice();
      newData.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_visible = true;
        });
      });

      this.category_primary = newData; 
    },

    /** 計算した合計月次工数の予定時間(分子)を hours/minutes で個別に管理する */
    calculateMonthlyTime() {
      this.totalHours = Math.floor(this.monthlyTotal / 60);
      this.totalMinutes = this.monthlyTotal % 60;
    },

   
    /** カテゴリ中央のボタンをClickで内部に格納する値の表示/非表示を切り替える */
    toggleVisibility(item) {
      item.category_visible = !item.category_visible;
      if (item.category_visible) {
        // DOM 更新が完了した後に syncScroll を呼び出す
        this.$nextTick(() => {
          this.syncScroll();
        });
      }
    },

    async toggleRemand(categoryTask, event) {
      const isChecked = event.target.checked ? 1 : 0;
      this.$set(categoryTask, 'is_remand', isChecked);

      await this.createRemandTask();
      this.statusText = this.getStatusText({
        phase_id: this.remandTask.phase_id,
      });
      this.submitButton = this.getSubmitButton({
        phase_id: this.remandTask.phase_id,
      });
    },

    selectInitialize(){
      this.dragCategoriesList = [];
      this.removeIndexList = [];
      this.dragoverIndex = {};
      const selectedElements = document.querySelectorAll('.selected');
      selectedElements.forEach(element => {
        element.classList.remove('selected');
      });
    },

    async handleDrop(allCategories, largeCategories, smallCategories, categoryTasks) {
      let newData = this.category_primary.slice();
      const removedIndexes = []; // 削除する要素のインデックス番号を保持する配列

      // 新しいデータを挿入
      let insertIndex = categoryTasks !== -1 ? categoryTasks + 1 : 0; // 挿入時のオフセット
      this.dragCategoriesList.forEach((replacedData, index) => {
        newData[allCategories][largeCategories].category_secondary[smallCategories].tasks
          .splice(insertIndex + index, 0, replacedData);
      });

      // 削除インデックスリストを逆順にソート
      const sortedRemoveIndexList = this.removeIndexList.slice().sort((a, b) => {
        if (a.allCategories !== b.allCategories) {
          return b.allCategories - a.allCategories;
        } else if (a.largeCategories !== b.largeCategories) {
          return b.largeCategories - a.largeCategories;
        } else if (a.smallCategories !== b.smallCategories) {
          return b.smallCategories - a.smallCategories;
        } else {
          return b.categoryTasks - a.categoryTasks;
        }
      });

      // 削除処理
      sortedRemoveIndexList.forEach((rIdx, removeIndex) => {
        const isMatchSmallCategory = rIdx.allCategories === allCategories &&
          rIdx.largeCategories === largeCategories &&
          rIdx.smallCategories === smallCategories;
        if (isMatchSmallCategory) {
          // 削除するデータより前にデータを入れた場合に、削除用のINDEXをcount upする
          if (rIdx.categoryTasks > categoryTasks) {
              rIdx.categoryTasks += this.dragCategoriesList.length;
          }
        }

        // 削除対象の要素の情報を削除リストに追加
        removedIndexes.push(rIdx);

        // 削除処理
        newData = this.deleteIndex(newData, rIdx.allCategories, rIdx.largeCategories, rIdx.smallCategories, rIdx.categoryTasks);

        // removeIndexListの残りのデータにも適用させる
        for (let i = removeIndex + 1; i < sortedRemoveIndexList.length; i++) {
          const remainingItem = sortedRemoveIndexList[i];
          if (remainingItem.allCategories === rIdx.allCategories &&
              remainingItem.largeCategories === rIdx.largeCategories &&
              remainingItem.smallCategories === rIdx.smallCategories &&
              remainingItem.categoryTasks > rIdx.categoryTasks) {
              remainingItem.categoryTasks--;
          }
          if (remainingItem.allCategories === rIdx.allCategories &&
              remainingItem.largeCategories === rIdx.largeCategories &&
              remainingItem.smallCategories > rIdx.smallCategories) {
              remainingItem.smallCategories--;
          }
          if (remainingItem.allCategories === rIdx.allCategories &&
              remainingItem.largeCategories > rIdx.largeCategories) {
              remainingItem.largeCategories--;
          }
          if (remainingItem.allCategories > rIdx.allCategories) {
              remainingItem.allCategories--;
          }
        }
      });

      // 削除リストから削除
      this.removeIndexList = this.removeIndexList.filter((item) =>
        !removedIndexes.some((index) =>
          index.allCategories === item.allCategories &&
          index.largeCategories === item.largeCategories &&
          index.smallCategories === item.smallCategories &&
          index.categoryTasks === item.categoryTasks
        )
      );

      // 更新したデータを保存
      this.category_primary = newData;
      this.selectInitialize();
      this.categoryReconstruction();
      this.refreshFreeTaskChangeHistory();
    },

    handleDraggStart(allCategories, largeCategories, smallCategories, categoryTasks) {
      const task = this.category_primary[allCategories][largeCategories].category_secondary[smallCategories].tasks[categoryTasks];
      if (!this.isEditTask() || !this.isDraggable(task)) {
        return;
      }
      this.isDrag = true; // ドラッグ中フラグを設定

      if(this.isMultiSelect){
        // dragCategoriesList に引数のデータが存在するか確認
        const index = this.dragCategoriesList.findIndex(item => 
          this.ojbComparison(item, this.category_primary[allCategories][largeCategories]
          .category_secondary[smallCategories].tasks[categoryTasks])
        );

        if(index === -1){
          // 存在しなければ追加
          const selectedElements = document.querySelectorAll('.selected');
          selectedElements.forEach(element => {
            element.classList.remove('selected');
          });
          this.dragCategoriesList = [
            this.category_primary[allCategories][largeCategories].category_secondary[smallCategories].tasks[categoryTasks]
          ];
          this.removeIndexList = [{ allCategories, largeCategories, smallCategories, categoryTasks }];

        }
      } else {
        this.dragCategoriesList.push(
          this.category_primary[allCategories][largeCategories].category_secondary[smallCategories].tasks[categoryTasks]
        )
        this.removeIndexList.push({allCategories, largeCategories, smallCategories, categoryTasks});
      } 
    },

    handleDragEnd() {
      // ドラッグが終了した場合に、リストの内容をクリア
      this.selectInitialize();
      if(this.isDrag){
        this.isDrag = false; // ドラッグ中フラグをリセット
      }
      if(this.isMultiSelect){
        this.isMultiSelect = false; // マルチセレクト中フラグをリセット
      }
    },

    handleDragOver(allCategories, largeCategories, smallCategories, categoryTasks) {
      this.dragoverIndex = {allCategories, largeCategories, smallCategories, categoryTasks};
    },

    handleClick(allCategories, largeCategories, smallCategories, categoryTasks, e) {
      const task = this.category_primary[allCategories][largeCategories].category_secondary[smallCategories].tasks[categoryTasks];

      if (!this.isDraggable(task)) {
        return;
      }

      const isCtrlPressed = e.ctrlKey || e.metaKey;

      if (isCtrlPressed) {
        this.isMultiSelect = true;
        e.currentTarget.classList.toggle("selected");

        this.addOrRemove(
          this.removeIndexList,
          { allCategories, largeCategories, smallCategories, categoryTasks },
          this.dragCategoriesList,
          this.category_primary[allCategories][largeCategories].category_secondary[smallCategories].tasks[categoryTasks]
        );
      } 
    },

    ojbComparison(obj1,obj2){
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    },

    addOrRemove(array, obj, array2 = [], obj2 = {}) {
      // array配列にobjと同じ内容のオブジェクトが含まれているかをチェック
      const foundIndex = array.findIndex((item) => JSON.stringify(item) === JSON.stringify(obj));

      // objが見つかった場合、それを削除して結果を返す
      if (foundIndex !== -1) {
          array.splice(foundIndex, 1);
          array2.splice(foundIndex, 1);
          if(this.dragCategoriesList.length === 0){
            this.isMultiSelect = false;
          }
      }
      // objが見つからなかった場合、objを追加して結果を返す
      else {
          array.push(obj);
          array2.push(obj2);
      }
    },

    deleteIndex(array,allCategories, largeCategories, smallCategories, categoryTasks){
      // 削除処理
      array[allCategories][largeCategories].category_secondary[smallCategories].tasks.splice(categoryTasks, 1);
      // category_secondary 内の tasks が空になった場合
      if (array[allCategories][largeCategories].category_secondary[smallCategories].tasks.length === 0) {
        array[allCategories][largeCategories].category_secondary.splice(smallCategories, 1);
        
        // largeCategories 内の category_secondary が空になった場合
        if (array[allCategories][largeCategories].category_secondary.length === 0) {
          array[allCategories].splice(largeCategories, 1);

          // allCategories 内の largeCategories が空になった場合
          if (array[allCategories].length === 0) {
            array.splice(allCategories, 1);
          }
        }
      }
      return array
    },

    checkDropPoint(allCategories, largeCategories, smallCategories, categoryTasks) {
      if(!this.isEditTask()){
        return false
      }
      if(Object.keys(this.dragoverIndex).length !== 0){
        return this.dragoverIndex.allCategories === allCategories &&
               this.dragoverIndex.largeCategories === largeCategories &&
               this.dragoverIndex.smallCategories === smallCategories &&
               this.dragoverIndex.categoryTasks === categoryTasks 
      }
      return false
    },
    /**
     * Show explain modal
     */
     showExplainModal() {
      this.showExplain = !this.showExplain;
    },
    /**
     * Toggle expand
     * @return {void}
     */
    toggleExpand() {
      if (this.expand !== 0) {
        this.expand = 0;
      } else {
        this.expand = 1;
      }
    },

    /**
     * set loaded
     * @param {boolean} [value]
     * @return {void}
     */
    setLoaded(isLoading) {
      if (!isLoading && !isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[0];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }
          this.scrollTop = null;
        }, 0.0005);
      }
    },

    /**
     * Handle submit
     * @return {void}
     */
    submit: debounce_leading(function (isRejectAll) {
      if (this.$vuetify.breakpoint.mobile) {
        return;
      }
      if (this.isSelf()) {
        switch (this.phaseId) {
          case SystemConstant.PHASE_ID_UNSUBMITTED:
            // 未提出
            if (this.canSubmit) {
              this.toggleDrawerRight('check-list');
            } 
            this.toggleDrawerRight('check-list');
            break;
          case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            // 提出取り消し
            if (this.canSubmit) {
              this.toggleDrawerRight('check-list');
            } 
            break;
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            // 差し戻し(1次・2次)
              this.isInputCommentHistory = true;
              this.toggleDrawerRight('comments-history');
            break;
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
            // 確認待ち(1次・2次)
            this.isInputCommentReset = false;
            this.showCancel = true;
            break;
          case SystemConstant.PHASE_ID_VERIFIED:
            // 確認待ち(1次・2次)
            this.isInputCommentReset = true;
            this.showCancel = true;
            break;
          default:
            /** Nothing todo */
            break;
        } // END-SWITCH
      } else {
        if (this.isFirstApprove()) {
          if (this.canApproveRemand) {
            switch (this.phaseId) {
              case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
                if (
                  (this.isRejectStatus() && isRejectAll) 
                ) {
                  // 1次差し戻し;
                  this.toggleDrawerRight('remand_task');
                } 
                else {
                  // 1次承認
                  this.toggleDrawerRight('check-list');
                }
                break;
              case SystemConstant.PHASE_ID_PRIMARY_REJECT:
                this.isInputCommentHistory = true;
                this.toggleDrawerRight('comments-history');
                break;

              default:
                /** Nothing todo */
                break;
            } // END-SWITCH
          } 
        } else if(this.isSecondApprove()) {
          if (this.canApproveRemand) {
            switch (this.phaseId) {
              case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
                if (
                  (this.isRejectStatus() && isRejectAll)
                ) {
                  // 2次差し戻し
                  this.toggleDrawerRight('remand_task');
                } else {
                  // 2次承認
                  this.toggleDrawerRight('check-list');
                }
                break;
              case SystemConstant.PHASE_ID_2_NEXT_REJECT:
                this.isInputCommentHistory = true;
                this.toggleDrawerRight('comments-history');
                break;
              default:
                /** Nothing todo */
                break;
            }
          } else {
            /** Nothing todo */
          } // END-IF-ELSE
        } // END-IF-ELSE
      }
    }),

    /**
     * Get data from API FC108
     * @return {void}
     */
     async getMembers() {
      try {
        // loadTargetMonth を基にして Date オブジェクトを生成
        const selectDate = new Date(`${this.loadTargetMonth.slice(0, 4)}-${this.loadTargetMonth.slice(4, 6)}-01`);

        // 年と月を抽出
        const year = selectDate.getFullYear();
        const month = String(selectDate.getMonth() + 1).padStart(2, '0');

        // 月初を取得
        const firstDay = new Date(`${year}-${month}-01`);

        // 月末を取得
        const lastDate = new Date(year, month, 0);
        const lastDay = new Date(`${year}-${month}-${String(lastDate.getDate()).padStart(2, '0')}`);

        const { data, status } = await axios.get(GET_MEMBERS_API(), {
          params: {
            has_deleted_user: 2,
            start_date: formatDateTime(firstDay, yyyyMMdd1),
            end_date: formatDateTime(lastDay, yyyyMMdd1),
          },
        });
        this.members = status === STATUS_NO_CONTENT ? [] : data.result;
        this.selectUser = this.members.find((x) => x.id === this.userId);
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC108, status);
      } // END-TRY-CATCH
    },

    async submitNextPhase(params){
      await this.putCommentsHistory(this.userId, this.loadTargetMonth, this.actualMonthId, params);
      this.isInputCommentHistory = false;
      this.toggleDrawerRight('comments-history');
    },

    
    /**
     * Handle toggle drawer right
     * @param {string} [typeList]
     * @return {void}
     */
    toggleDrawerRight: debounce_leading(async function (typeList) {
      this.showDrawerRight = false;
      this.showCommentsHistory = false;
      this.showCheckList = false;
      this.showRemandTask = false;
      this.showCancel = false;
      this.showConfirmSave = false;
      this.showFreeTaskChangeHistory = false;
      this.showSubmissionError = false;

      // データ取得できなかった場合は開かない
      if(this.othersFrag){
        return
      }

      this. selectInitialize();
      // 自分自身にかかわらず開ける
      let isSucsess = true;
      if (typeList === "check-list") {
        isSucsess = await this.monthlyPutValidation()
        if(isSucsess){
          this.showDrawerRight = true;
          this.showCheckList = true;
        }
      } else if (typeList === "remand_task") {
        isSucsess = await this.monthlyPutValidation()
        if(isSucsess){
          this.showDrawerRight = true;
          this.showRemandTask = true;
        }
      } else if (typeList === "comments-history") {
        if(this.isInputCommentHistory){
          isSucsess = await this.monthlyPutValidation();
        }
        if(isSucsess){
          this.showDrawerRight = true;
          this.showCommentsHistory = true;
        }
      } else if (typeList === "free-task-change") {
        this.showDrawerRight = true;
        this.showFreeTaskChangeHistory = true;
      } else {
        this.isInputCommentHistory = false;
      }
      if(isSucsess){
        this.toggleOverlay(typeList);
      }
    }),

    toggleOverlay(typeList) {
      const calendarElement = document.querySelector(
        this.isSelf() ? '.content-calendar' : '.my-column-full'
      );
      if(
        typeList === "check-list" || 
        typeList === "remand_task" || 
        (typeList === "comments-history" && this.isInputCommentHistory)
      ){
        calendarElement.classList.toggle('overlay');
        this.isOverlay = true;
        this.openView();
      }else if(
        !typeList ||
        (typeList === "comments-history" && !this.isInputCommentHistory)
      ){
        calendarElement.classList.remove('overlay');
        this.isOverlay = false;
      }
    },


    /**
     * Get status text for item
     * @param {object} item
     * @return {string}
     */
    getStatusText(item) {
      this.phaseId = item.phase_id;

      // 名前が変わってるので確認する
      switch (item.phase_id) {
        case SystemConstant.PHASE_ID_UNSUBMITTED:
          return SystemConstant.PHASE_ID_UNSUBMITTED_TEXT;
        case SystemConstant.PHASE_ID_SUBMISSION_RESET:
          return SystemConstant.PHASE_ID_UNSUBMITTED_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          return SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
          return SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_PRIMARY_REJECT:
          return SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT;
        case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
          return SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING_TEXT;
        case SystemConstant.PHASE_ID_2_NEXT_REJECT:
          return SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT;
        case SystemConstant.PHASE_ID_VERIFIED:
          return SystemConstant.PHASE_ID_VERIFIED_TEXT;
        default:
          throw "Can not find the status label";
      } // END-SWITCH
    },

    /**
     * Get submit button
     * @param {object} item
     * @return {void}
     */
    getSubmitButton(item) {
      let button = {
        text: "",
        active: false,
        isReject: false,
      };

      if (this.isSelf()) {
        switch (item.phase_id) {
          case SystemConstant.PHASE_ID_UNSUBMITTED /* User can submit */:
          case SystemConstant.PHASE_ID_SUBMISSION_RESET /* User can resubmit */:
          case SystemConstant.PHASE_ID_PRIMARY_REJECT /* User can resubmit */:
          case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            button.text = this.$t("label.buttons.submission");
            button.active = !this.canSubmit;
            button.isReject = false;
            this.showDraftText = true;
            this.checkDraft();
            break;
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING /* User can cancel submit before 1st approver's confirmation*/:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET /* User can cancel submit before 1st approver's confirmation*/:
          case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING /* User cannot cancel submit */:
          case SystemConstant.PHASE_ID_VERIFIED /* User cannot cancel submit */:
            button.text = this.$t("label.buttons.submission_reset");
            button.active = !this.canSubmitCancel;
            button.isReject = true;
            this.showDraftText = false;
            this.confirmDraft = false;
            this.rejectDraft = false;
            break;
          default:
            break;
        } // END-SWITCH
      } else {
        if (this.isNoApprove()) {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED /* User can submit */:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET /* User can resubmit */:
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              this.checkDraft();
              break;
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING /* User can cancel submit before 1st approver's confirmation*/:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET /* User can cancel submit before 1st approver's confirmation*/:
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING /* User cannot cancel submit */:
              button.text = this.$t("label.buttons.submission_reset");
              button.active = true;
              button.isReject = true;
              this.showDraftText = false;
              this.confirmDraft = false;
              this.rejectDraft = false;
              break;
            case SystemConstant.PHASE_ID_PRIMARY_REJECT /* User can resubmit */:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED /* User cannot cancel submit */:
              button.text = this.$t("label.buttons.submission");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              this.checkDraft();
              break;
            default:
              break;
          } // END-SWITCH
          button.active = true;
        } else if (this.isFirstApprove()) {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED:
              button.text = this.$t("label.buttons.confirm");
              button.active = !this.canApproveRemand;
              button.isReject = false;
              this.showDraftText = true;
              this.checkDraft();
              break;
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
              {
                const returnedTask = this.remandTask.individual_tasks_comments;
                if (!isEmpty(returnedTask)) {
                  button = {
                    text: `${returnedTask.length}件`,
                    active: !this.canApproveRemand,
                    isReject: true,
                  };
                  this.checkDraft();
                  this.showDraftText = true;
                  this.checkDraft();
                } else {
                  button = {
                    text: this.$t("label.buttons.confirm"),
                    active: !this.canApproveRemand,
                    isReject: false,
                  };
                  this.showDraftText = true;
                  this.checkDraft();
                } // END-IF-ELSE
              }
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              button.text = this.$t("label.buttons.confirm");
              button.isReject = false;
              button.active = this.canApproveRemand;
              this.showDraftText = true;
              this.checkDraft();
              break;
            default:
              break;
          } // END-SWITCH
        } else {
          switch (item.phase_id) {
            case SystemConstant.PHASE_ID_UNSUBMITTED:
            case SystemConstant.PHASE_ID_SUBMISSION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
            case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
            case SystemConstant.PHASE_ID_PRIMARY_REJECT:
            case SystemConstant.PHASE_ID_2_NEXT_REJECT:
            case SystemConstant.PHASE_ID_VERIFIED:
              button.text = this.$t("label.buttons.confirm");
              button.active = true;
              button.isReject = false;
              this.showDraftText = true;
              this.checkDraft();
              break;
            case SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING:
              {
                const returnedTask = this.remandTask.individual_tasks_comments;
                if (!isEmpty(returnedTask)) {
                  button = {
                    text: `${returnedTask.length}件`,
                    active: !this.canApproveRemand,
                    isReject: true,
                  };
                  this.showDraftText = true;
                  this.checkDraft();
                } else {
                  button = {
                    text: this.$t("label.buttons.confirm"),
                    active: !this.canApproveRemand,
                    isReject: false,
                  };
                  this.showDraftText = true;
                  this.checkDraft();
                } // END-IF-ELSE
              }
              break;
            default:
              break;
          } // END-SWITCH
        } // END-SWITCH
      } // END-IF-ELSE

      return button;
    },
    
    /**
     * Check if disable overide
     * @return {boolean}
     */
     disableOveride() {
      return (
        this.statusText ===
          SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT ||
        this.statusText ===
          SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING_TEXT ||
        this.statusText === SystemConstant.PHASE_ID_VERIFIED_TEXT
      );
    },

    /**
     * Check if user is self
     * @return {boolean}
     */
     isSelf() {
      return this.userId === this.user?.user_id;
    },

    /**user is suppervisor
     * Check if user is suppervisor
     * @return {boolean}
     */
    isSupervisor() {
      return !!(this.userId !== this.user?.user_id && this.canViewComment);
    },

    /**
     * Check subbmit permission
     * @return {boolean}
     */
    isNoSubmmit() {
      return (
        this.phaseId === SystemConstant.PHASE_ID_UNSUBMITTED ||
        this.phaseId === SystemConstant.PHASE_ID_SUBMISSION_RESET ||
        this.phaseId === SystemConstant.PHASE_ID_2_NEXT_REJECT ||
        this.phaseId === SystemConstant.PHASE_ID_PRIMARY_REJECT
      );
    },

    /**
     * Check first approve permission
     * @return {boolean}
     */
    isFirstApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_PRIMARY_APPROVAL
      );
    },

    /**
     * Check second approve permission
     * @return {boolean}
     */
    isSecondApprove() {
      return (
        this.loginInfo?.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_SECONDARY_APPROVAL
      );
    },

    /**
     * Check no approve permission
     * @return {boolean}
     */
    isNoApprove() {
      return (
        this.loginInfo.approval_auth_id ===
        SystemConstant.APPROVAL_AUTH_ID_NO_PERMISSION
      );
    },

    checkDraft(){
      if (!this.remandTask.comment && this.remandTask.remand_comment) {
        this.confirmDraft = false;
        this.rejectDraft = true;
      } else if ((this.remandTask.comment && !this.remandTask.remand_comment) || this.remandTask.fix_comment) {
        this.confirmDraft = true;
        this.rejectDraft = false;
      } else if (this.remandTask.individual_tasks_comments.length > 0) {
        this.confirmDraft = false;
        this.rejectDraft = this.remandTask.individual_tasks_comments.some(e => e.comment);
      } else {
        // コメントが未入力なので「下書き中」の表示はしない
        this.confirmDraft = false;
        this.rejectDraft = false;
      }
    },

    /**
     * Handle change members event
     * @return {void}
     */
    async membersChange(e) {
      if (!this.confirmSaveByMember(e)) {
        return;
      }

      this.initializeFlag();
      this.toggleDrawerRight();
      if (e.id && this.userId !== e.id) {
        this.$store.dispatch("common/setMember", { selectedMember: e });
        this.userId = e.id;
        this.planId = "";
        if (!this.isSelf()) {
          this.userId = e.id;
          this.$router.replace({
            path: "/monthly-actuals",
            query: { 
              user_id: e.id,
              target_month: this.loadTargetMonth,
            },
          }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
              throw err;
          }
        });
        } 
        await this.getMonthlyActuals(this.selectUser.id, this.loadTargetMonth);
        await this.getCommentsHistory(this.selectUser.id, this.loadTargetMonth, this.actualMonthId);
        this.InitialDisplayCommentHistory();
        this.getCategories();
        this.getMonthlyWording();
        this.calculate();
        this.createFreeTaskChangeHistory();
        this.handleResize();
        this.syncScroll();
        // ポップアップ外をクリックした際にポップアップを閉じるイベント
        window.addEventListener("click", this.closeAddCategory);
      }
    },

    /**
     * Check if before twelve
     * @return {boolean}
     */
     isBeforeTwelve() {
      let now = Number(formatDateTime(new Date(), yyyyMMdd1));
      let selected = Number(
        formatDateTime(
          parseDateTime(`${this.selectedDate}`, yyyyMMdd2), yyyyMMdd1
      ));

      if (now > selected) {
        return false;
      } else {
        if (now === selected) {
          return Number(formatDateTime(new Date(), HHmm1)) < 1200;
        } else {
          return true;
        } // END-IF-ELSE
      } // END-IF-ELSE
    },

    /**
     * Check submit permisson
     * @return {boolean}
     */
    isCanSubmit() {
      return this.canSubmit;
    },


    /**
     * Check status is reject or not
     * @return {boolean}
     */
    isRejectStatus() {
      if (this.isFirstApprove())
        return (
          this.phaseId === SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING
        );
      if (this.isSecondApprove())
        return (
          this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
        );
      return false;
    },

    /**
     * Check if it's confirmed
     * @return {boolean}
     */
    isVerified() {
      return this.lastComment.phase_id !== SystemConstant.PHASE_ID_VERIFIED
    },

    adjustTasks(events, selectedDate, minSlot, slotMaxTime = "24:00:00") {
      const getAdjustStart = (end, min, slotMaxTime = "24:00:00") => {
        const endDate = formatDateTime(
          parseDateTime(end, yyyyMMddHHmmss2),
          yyyyMMdd2
        );
        const startTime =
          slotMaxTime === "24:00:00"
            ? `23:${this.timeToStrTime(Math.floor(60 - min))}:00`
            : formatDateTime(
                new Date(
                  new Date(`2020-01-01 ${slotMaxTime}`).getTime() -
                    min * 60 * 1000
                ),
                HHmmss2
              );
        return `${endDate} ${startTime}`;
      };
      const getFormat = (dateTimeString) => {
        switch (dateTimeString.length) {
          case 19:
            return yyyyMMddHHmmss2;
          case 16:
            return yyyyMMddHHmm1;
          default:
            throw `format errors ${dateTimeString}`;
        }
      };
      events.forEach((item) => {
        let endRealDate = parseDateTime(
          item.end_real,
          getFormat(item.end_real)
        );

        item.start_real = formatDateTime(
          parseDateTime(selectedDate + " " + item.start_time, yyyyMMddHHmmss1),
          yyyyMMddHHmmss2
        );
        // check min
        const min = item.cost * item.count < 30 ? minSlot : minSlot * 1.65;
        const tomorrowTime =
          Date.parse(`${formatDateTime(endRealDate, yyyyMMdd2)}T00:00:00`) +
          24 * 60 * 60 * 1000;
        let compareStartRealTime =
          parseDateTime(item.start_real, yyyyMMddHHmmss2).getTime() +
          min * 60 * 1000;
        const endRealTime = endRealDate.getTime();
        const isAdjustEnd = compareStartRealTime > tomorrowTime;
        const compareStartHHmmss2 = formatDateTime(
          new Date(compareStartRealTime),
          HHmmss2
        );

        if (
          isAdjustEnd &&
          item.end_time > 230000 &&
          slotMaxTime === "24:00:00"
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} 23:59:59`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (
          compareStartRealTime > endRealTime &&
          compareStartHHmmss2 > slotMaxTime
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} ${slotMaxTime}`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (compareStartRealTime > endRealTime) {
          compareStartRealTime =
            compareStartRealTime >= tomorrowTime
              ? tomorrowTime - 1000
              : compareStartRealTime;
          item.end = formatDateTime(compareStartRealTime, yyyyMMddHHmmss2);
        }
      });
    },


    /**
     * Check if user is intern or parttime
     * @return {boolean}
     */
     isInternOrPartTime() {
      if(this.loginInfo?.position_id){
        return this.loginInfo?.position_id == 100 || this.loginInfo?.position_id == 200;
      }
      return false
    },

    /**
     * 就業時間：開始時間の表示
     */
     dspStartWorkTime() {
      if (isEmpty(this.startWorkTime)) this.startWorkTime = SystemConstant.DEFAULT_START_TIME;
      return formatTime(this.startWorkTime);
    },
    /**
     * 就業時間：終了時間の表示
     */
    dspEndWorkTime() {
      if (isEmpty(this.endWorkTime)) this.endWorkTime = SystemConstant.DEFAULT_END_TIME;
      return formatTime(this.endWorkTime);
    },

    exportCsv() {
      this.$refs.export.showDialog(true);
    },

    confirmSave(event) {
      if (this.storageFrag) {
        event.preventDefault();
      }
    },

    confirmSaveByMember(e) {
      if (this.storageFrag) {
        if (window.confirm("編集中のものは保存されませんが、よろしいですか？")) {
          this.beforeSelectionUser = e;
          return true;
        } else {
          this.selectUser = JSON.parse(JSON.stringify(this.beforeSelectionUser));
          return false;
        }
      } else {
        this.beforeSelectionUser = e;
        return true;
      }
    },

    confirmSaveByCalendar(e) {
      if (this.storageFrag) {
        if (window.confirm("編集中のものは保存されませんが、よろしいですか？")) {
          this.beforeSelectionCalendar = e;
          return true;
        } else {
          this.dispTargetMonth = JSON.parse(JSON.stringify(this.beforeSelectionCalendar));
          return false;
        }
      } else {
        this.beforeSelectionCalendar = e;
        return true;
      }
    },

    async confirmNotice() {
      // Confirm this notice
      if (
        this.$route.query.is_verified &&
        this.$route.query.is_verified === "1"
      ) {
        await this.apiConfirmedNotice([
          {
            status_notice_id: this.$route.query.status_notice_id || null,
            comment_notice_id: this.$route.query.comment_notice_id || null,
            is_verified: 1,
          },
        ]);
        this.$store.dispatch("notification/setCount", this.getCount);
      }
    },

    async apiConfirmedNotice(notices) {
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC502, status);
      }
    },
    
    /** タスク名左側のボタンをClickで内部に格納する値の表示/非表示を切り替える */
    tasksVisibility(categoryTask) {
      categoryTask.tasks_visible = !categoryTask.tasks_visible;
    },
    
    // 未実施タスクの判定
    isNotImplementedTask(task) {
        return task.actual_cost === 0 && task.actual_count === 0;
    },
    
    // 定常業務の判定
    isUsualTask(task) {
      return task.task_usual === 1 && task.standard_task_id;
    },

    // 差し戻しタスクの判定
    isRemandTask(task) {
      return task.is_remand === 1 || task.is_remand_previous === 1;
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (item.schedule_id === this.planId && !!item.can_verified && !isEmptyNumber(item.status_notice_id)){
            this.listNotification.push(item);
          }        
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: item.status_notice_id,
            comment_notice_id: null,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC502, status);
      }
    },

    /**
     * Check if item is DB task
     * @param {object} item
     * @return {boolean}
     */
    isDbTask(item) {
      return (
        isEmptyNumber(item.free_task_id) &&
        isEmptyNumber(item.actual_free_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision) &&
        this.isDbTaskByType(item)
      );
    },

    /**
     * Check if item is DB task (include irregular task)
     * @param {object} item
     * @return {boolean}
     */
     isAllDbTask(item) {
      return (
        isEmptyNumber(item.free_task_id) &&
        isEmptyNumber(item.actual_free_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision) 
      );
    },

    /**
     * Check if item is Free task
     * @param {object} item
     * @return {boolean}
     */
    isFreeTask(item) {
      return !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id);
    },

    /**
     * Check if item is Break task
     * @param {object} item
     * @return {boolean}
     */
    isBreakTask(item) {
      return item.isBreak;
    },

    /**
     * Check if item is DB task by type
     * @param {object} item
     * @return {boolean}
     */
    isDbTaskByType(item) {
      return (
        !item.after_task_type_id ||
        SystemConstant.DB_TASK_TYPE_LIST.includes(item.after_task_type_id)
      );
    },

    /**
     * Check if item is Beginner level DB task
     * @param {object} item
     * @return {boolean}
     */
    isBeginnerSkill(item) {
      return item.skill_level_id ? item.skill_level_id === 10 : null;
    },

    /**
     * Check if item is Expert level DB task
     * @param {object} item
     * @return {boolean}
     */
    isExpertSkill(item) {
      return item.skill_level_id ? item.skill_level_id === 30 : null;
    },

    /**
     * Check if item is Split task
     * @param {object} item
     * @return {boolean}
     */
    isSplitTask(item) {
      return (
        !isEmptyNumber(item.split_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision)
      );
    },

    /**
     * Get color for task
     * @param {object} item
     * @return {string}
     */
    getColor(item) {
      switch (true) {
        // case item.is_remand === 1:
        //   return "#002060";
        case !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id):
          return COLOR_FREE_TASK;
        case isEmptyNumber(item.free_task_id) && isEmptyNumber(item.actual_free_task_id) &&
          !isEmptyNumber(item.standard_task_id) &&
          !isEmptyNumber(item.standard_task_revision):
          if (!this.isDbTaskByType(item)) {
            return COLOR_GRAY;
          } else {
            return COLOR_DB_TASK;
          }
        default:
          throw "Color is undefined";
      } // END-SWITCH
    },

    /**
     * Change date func
     * @return {void}
     */
    changeSelectedDate() {
      this.planId = "";
      let originalDate = "";
      if (!isEmpty(this.$route.query.date))
        originalDate = this.$route.query.date;
      if (isEmpty(originalDate) || this.selectedDate !== originalDate) {
        if (this.isSelf()) {
          this.$router.replace({
            path: "/monthly-actuals",
            query: { date: this.selectedDate },
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
        } else {
          this.$router.replace({
            path: "/monthly-actuals",
            query: { userId: this.userId, date: this.selectedDate },
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
        }
      }
    },
    /**
     * Handle change date event
     * @return {void}
     */
    changDate: debounce_leading(function () {
      this.changeSelectedDate();
    }),

    /**
     * Move selected date
     *  @param {boolean} [isNext]
     * @return {void}
     */
    moveSelectedDate(isNext = false) {
      const day = parseDateTime(this.selectedDate, yyyyMMdd2);
      let y = day.getFullYear(),
        m = day.getMonth(),
        d = isNext ? day.getDate() + 1 : day.getDate() - 1;
      this.selectedDate = formatDateTime(new Date(y, m, d), yyyyMMdd2);
      this.changeSelectedDate();
    },

    /**
     * Check if current day
     * @return {boolean}
     */
    isCurrentDay() {
      let now = Number(formatDateTime(new Date(), yyyyMMdd1));
      let selected = Number(
        formatDateTime(
          parseDateTime(`${this.selectedDate}`, yyyyMMdd2), yyyyMMdd1
      ));

      return now <= selected;
    },

    /**
     * Check if after twelve
     * @return {boolean}
     */
    isAfterTwelveCurrentDay() {
      let now = Number(formatDateTime(new Date(), yyyyMMdd1));
      let selected = Number(
        formatDateTime(
          parseDateTime(`${this.selectedDate}`, yyyyMMdd2), yyyyMMdd1
      ));

      if (now === selected) {
        return (
          this.phaseId === SystemConstant.PHASE_ID_UNSUBMITTED &&
          Number(formatDateTime(new Date(), HHmm1)) >= 1200
        );
      }
      return false;
      // END-IF-ELSE
    },


    /**
     * Check checkbox is active or not
     * @return {boolean}
     */
    activeCheckBox() {
      if (this.isFirstApprove()) {
        switch (this.phaseId) {
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_PENDING:
          case SystemConstant.PHASE_ID_PRIMARY_CONFIRMATION_RESET:
            return true;
          default:
            return false;
        } // END-SWITCH
      } else {
        return (
          this.isSecondApprove() &&
          this.phaseId === SystemConstant.PHASE_ID_2_NEXT_CONFIRMATION_PENDING
        );
      } // END-IF-ELSE
    },

    /**
     * Get duration time
     */
    diffTime(stime, etime) {
      let shour = stime.substr(0, 2);
      let sminute = stime.substr(2, 2);
      let ehour = etime.substr(0, 2);
      let eminute = etime.substr(2, 2);
      return ehour * 60 + parseInt(eminute) - (shour * 60 + parseInt(sminute));
    },

    /**
     * Check close Notification
     * @return {boolean}
     */
    closeNotification() {
      this.showNotification = false;
    },

    /**
     * Check show issue status in schedule
     * @return {boolean}
     */
    isShowNotification() {
      const remandIdList = [
        SystemConstant.PHASE_ID_PRIMARY_REJECT,
        SystemConstant.PHASE_ID_2_NEXT_REJECT,
      ];
      return this.statuses.length > 0 && remandIdList.includes(this.phaseId);
    },


    /**
     * Check show notice status from notice
     * @return {void}
     */
    showNotificationFromNotice() {
      this.showNotification =
        this.$route.query.fromNotice && Number(this.$route.query.fromNotice) === 1
          ? true
          : this.isShowNotification();
    },

    /**
     * Mark as read comment notice
     * @return {void}
     */
    async callApiFC502(task) {
      if (!this.getUnReadList || this.getUnReadList.length === 0) return;

      const commentsList = task.comments || false;
      if (!commentsList) return;

      const managerCommentList =
        commentsList.filter((item) => {
          const itemMarkRead =
            this.getUnReadList.find(
              (notice) => notice.comment_notice_id === item.id
            ) || false;
          const isManagerComment = item.user_id === this.loginInfo.manage_id;
          const isSelfSubmit = this.isSelf();
          return itemMarkRead && isManagerComment && isSelfSubmit;
        }) || [];
      if (managerCommentList.length === 0) return;

      let notices = [];
      managerCommentList.forEach((item) => {
        notices.push({
          status_notice_id: null,
          comment_notice_id: item.id || null,
          is_verified: 1,
        });
      });

      // call Api FC-502 for mark read
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const status = error.response?.status;
        apiErrorMessages(this, FC502, status);
      }
    },
    
    /**
     * Set time grid length
     * @return {void}
     */
    setTimeGridLength(slotDuration, slotMinTime, slotMaxTime) {
      this.timeGridLength = this.getTimeGridSlotByDuration(
        slotDuration,
        slotMinTime,
        slotMaxTime
      );
    },

    isNameDiff(task){
      return task.after_task_name !== task.before_task_name
    },

    isDetailDiff(task) {
      const nullToStr = (val) => val ?? '';
      return nullToStr(task.after_detail) !== nullToStr(task.before_detail);
    },

    /*差し戻し状態のタスク詳細を変更した際にテキストが赤文字になる*/
    isFontChange(task) {
     return Number(task.is_remand_previous) === 1 && this.isDetailDiff(task)
    },
    
    // 変更後予定工数回の背景色判定(黄)
    isCostDiff(task) {
      return Number(task.before_cost) !== Number(task.after_cost)
    },

    // 変更後回数の背景色判定(黄)
    isCountDiff(task) {
      return task.before_count !== task.after_count
    },

    // 変更後予定工数の背景色判定(黄)
    isEstimateDiff(task) {
      return this.isCostDiff(task) || this.isCountDiff(task)
    },

    // 変更後予定工数回の背景色判定(赤)
    isCostIncreasedBy20(task) {
      return Number(task.actual_cost) >= Number(task.after_cost) * 1.2;
    },

    /*予定外タスクかつ本人のタスクの場合にドラッグできるようにする*/
    isDraggable(categoryTask) {
      return !categoryTask.schedule_task_month_id && 
        categoryTask.task_secondary &&
        categoryTask.task_secondary.length === 0 &&
        this.isSelf();
    },

    /** 変更後の月次予定工数の表示に必要な値を計算する */
    calculateMonthlyScheduleSum(){
      let sum = 0;
      let targetTaskTypeSum = 0;
      let notTargetTaskTypeSum = 0;

      const calulationProcess = (task) => {
        if (task.after_task_type_id === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) {
          targetTaskTypeSum += (task.after_cost * task.after_count);
        } else {
          notTargetTaskTypeSum += (task.after_cost * task.after_count);
        }
        sum += (task.after_cost * task.after_count);
      }

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.after_task_type_id !== SystemConstant.TASK_TYPE.IRREGULAR_ATTENDANCE) {
                if (task.task_secondary && task.task_secondary.length !== 0) {
                  task.task_secondary.forEach(child => {
                    calulationProcess(child);
                  })
                } else {
                  calulationProcess(task);
                }
              }
            })
          })
        })
      });

      this.scheduleTotal = sum;
      this.scheduleTargetTaskTypeTotal = targetTaskTypeSum;
      this.scheduleNotTargetTaskTypeTotal = notTargetTaskTypeSum;
    },

    /** 月次実績工数の表示に必要な値を計算する */
    calculateMonthlyActualSum(){
      let sum = 0;
      let targetTaskTypeSum = 0;
      let notTargetTaskTypeSum = 0;

      const calulationProcess = (task) => {
        if (task.after_task_type_id !== SystemConstant.TASK_TYPE.IRREGULAR_ATTENDANCE) {
          if (task.after_task_type_id === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) { 
            targetTaskTypeSum += (task.actual_cost * task.actual_count);
          } else {
            notTargetTaskTypeSum += (task.actual_cost * task.actual_count);
          }

          sum += (task.actual_cost * task.actual_count);
        }
      }

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.task_secondary && task.task_secondary.length !== 0) {
                task.task_secondary.forEach(child => {
                  calulationProcess(child);
                })
              } else {
                calulationProcess(task);
              }
            })
          })
        })
      });

      this.actualTotal = sum;
      this.actualTargetTaskTypeTotal = targetTaskTypeSum;
      this.actualNotTargetTaskTypeTotal = notTargetTaskTypeSum;
    },

    /** 標準工数タスク率の表示に必要な値を計算する */
    calculateStandardTaskSum() {
      let notSubjectDbToEvaluationSum = 0;
      let notSubjectToEvaluationSum = 0;
      let irregularSum = 0;
      let allSum = 0;
      let completionTaskSum = 0;

      const calulationProcess = (task) => {
        if (task.standard_task_id) {
          allSum += (task.actual_cost * task.actual_count);
        }
        completionTaskSum += (task.actual_cost * task.actual_count);
        if (task.standard_task_id && task.after_task_type_id === SystemConstant.TASK_TYPE.WORK_NOT_SUBJECT_TO_PRODUCTIVITY_EVALUATION) {
          notSubjectDbToEvaluationSum += (task.actual_cost * task.actual_count);
        }
        if (task.after_task_type_id === SystemConstant.TASK_TYPE.WORK_NOT_SUBJECT_TO_PRODUCTIVITY_EVALUATION) {
          notSubjectToEvaluationSum += (task.actual_cost * task.actual_count);
        }
        if (task.after_task_type_id === SystemConstant.TASK_TYPE.IRREGULAR_ATTENDANCE) {
          irregularSum += (task.actual_cost * task.actual_count);
        }
      }

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.task_secondary && task.task_secondary.length !== 0) {
                task.task_secondary.forEach(child => {
                  calulationProcess(child);
                })
              } else {
                calulationProcess(task);
              }
            })
          })
        })
      });

      this.actualTargetTaskTypeDbTotal = (allSum - notSubjectDbToEvaluationSum - irregularSum);
      this.completionActualTargetTaskTypeTotal = (completionTaskSum - notSubjectToEvaluationSum - irregularSum);
    },

    /** 計画実行率の表示に必要な値を計算する */
    calculatePlanActualSum() {
      let taskSum = 0;
      let unfinishedTaskSum = 0;

      const calulationProcess = (task) => {
        if (task.after_task_type_id === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) {
          taskSum += (task.after_cost * task.after_count);
          if (task.is_completion !== 1) {
            unfinishedTaskSum += (task.after_cost * task.after_count);
          }
        }
      }

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.task_secondary && task.task_secondary.length !== 0) {
                task.task_secondary.forEach(child => {
                  calulationProcess(child);
                })
              } else {
                calulationProcess(task);
              }
            })
          })
        })
      });

      this.scheduleTargetTaskTypeCompletedTotal = (taskSum - unfinishedTaskSum);
    },

    /** 生産性の表示に必要な値を計算する */
    calculateProductivitySum() {
      let actualTaskSum = 0;
      let unfinishedActualTaskSum = 0;

      const calulationProcess = (task) => {
        if (task.after_task_type_id === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) {
          actualTaskSum += (task.actual_cost * task.actual_count);

          if (task.is_completion !== 1) {
            unfinishedActualTaskSum += (task.actual_cost * task.actual_count);
          }
        }
      }

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.task_secondary && task.task_secondary.length !== 0) {
                task.task_secondary.forEach(child => {
                  calulationProcess(child);
                })
              } else {
                calulationProcess(task);
              }
            })
          })
        })
      });

      this.actualTargetTaskTypeCompletedTotal = (actualTaskSum - unfinishedActualTaskSum);
    },

    /** 生産性評価対象外業務(定常業務)を予定工数内で出来たか の表示に必要な値を計算する */
    calculateUsualTaskEveluationSum() {
      let scheduleTaskSum = 0;
      let actualTaskSum = 0;

      const calulationProcess = (task) => {
        // 標準工数、定常業務、生産性評価対象外で完了済みのタスク
        if (task.standard_task_id && task.task_usual === 1 && task.is_completion === 1
            && task.after_task_type_id === SystemConstant.TASK_TYPE.WORK_NOT_SUBJECT_TO_PRODUCTIVITY_EVALUATION) {
          // 変更後の予定工数の合計
          scheduleTaskSum += (task.after_cost * task.after_count);

          // 実績工数の合計
          actualTaskSum += (task.actual_cost * task.actual_count);
        }
      }

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if (task.task_secondary && task.task_secondary.length !== 0) {
                task.task_secondary.forEach(child => {
                  calulationProcess(child);
                })
              } else {
                calulationProcess(task);
              }
            })
          })
        })
      });

      // 定常業務の予定工数、定常業務の実績工数
      this.scheduleNotTargetTaskTypeDbUsualCompletedTotal = scheduleTaskSum;
      this.actualNotTargetTaskTypeDbUsualCompletedTotal = actualTaskSum;
    },

    /** 計算した合計月次工数の予定時間(分子)を hours/minutes で個別に管理する */
    calculateMonthlyScheduleTime() {
      this.scheduleTotalHours = Math.floor(this.scheduleTotal / 60);
      this.scheduleTotalMinutes = this.scheduleTotal % 60;
      this.scheduleEvaluationTargetHours = Math.floor(this.scheduleTargetTaskTypeTotal / 60);
      this.scheduleEvaluationTargetMinutes = this.scheduleTargetTaskTypeTotal % 60;
      this.scheduleEvaluationNotTargetHours = Math.floor(this.scheduleNotTargetTaskTypeTotal / 60);
      this.scheduleEvaluationNotTargetMinutes = this.scheduleNotTargetTaskTypeTotal % 60;
      this.calculateStandardTaskEveluation();
      this.calculatePlanActualEveluation();
      this.calculateProductivityEveluation();
      this.calculateUsualTaskEveluation();
    },

    // 完了
    /** 計算した合計月次工数の予定時間(分子)を hours/minutes で個別に管理する */
    calculateMonthlyActualTime() {
      this.actualTotalHours = Math.floor(this.actualTotal / 60);
      this.actualTotalMinutes = this.actualTotal % 60;
      this.actualEvaluationTargetHours = Math.floor(this.actualTargetTaskTypeTotal / 60);
      this.actualEvaluationTargetMinutes = this.actualTargetTaskTypeTotal % 60;
      this.actualEvaluationNotTargetHours = Math.floor(this.actualNotTargetTaskTypeTotal / 60);
      this.actualEvaluationNotTargetMinutes = this.actualNotTargetTaskTypeTotal % 60;
      this.calculateStandardTaskEveluation()
      this.calculatePlanActualEveluation();
      this.calculateProductivityEveluation();
      this.calculateUsualTaskEveluation();
    },

    /** 標準工数タスク率を計算する */
    calculateStandardTaskEveluation() {
      let rate = 0;
      if (this.actualTargetTaskTypeDbTotal > 0 && this.completionActualTargetTaskTypeTotal > 0){
          rate = this.actualTargetTaskTypeDbTotal * 100 / this.completionActualTargetTaskTypeTotal;
      }
      this.standardTaskEveluationRate = rate.toFixed(0);
    },

    /** 計画実行率を計算する */
    calculatePlanActualEveluation() {
      let rate = 0;
      if (this.scheduleTargetTaskTypeCompletedTotal > 0 && this.scheduleTargetTaskTypeTotal > 0){
          rate = this.scheduleTargetTaskTypeCompletedTotal * 100 / this.scheduleTargetTaskTypeTotal;
      }
      this.planActualRate = rate.toFixed(0);
    },

    /** 生産性を計算する */
    calculateProductivityEveluation() {
      let rate = 0;
      if (this.scheduleTargetTaskTypeCompletedTotal > 0 && this.actualTargetTaskTypeCompletedTotal > 0){
          rate = this.scheduleTargetTaskTypeCompletedTotal * 100 / this.actualTargetTaskTypeCompletedTotal;
      }
      this.productivityRate = rate.toFixed(0);
    },

    /** 生産性対象外定常業務を計算する */
    calculateUsualTaskEveluation() {
      let rate = 0;
      if (this.scheduleNotTargetTaskTypeDbUsualCompletedTotal > 0 && this.actualNotTargetTaskTypeDbUsualCompletedTotal > 0){
          rate = this.scheduleNotTargetTaskTypeDbUsualCompletedTotal * 100 / this.actualNotTargetTaskTypeDbUsualCompletedTotal;
      }
      this.taskUsualRate = rate.toFixed(0);
    },

    /** 個別IDの作成 */
    createId(index_no){
      return 'task' + index_no;
    },

     /** 個別IDの作成 */
    categoryId(category_id){
      return 'category'+ category_id;
    },

    createFreeTaskChangeHistory() {
      this.monthlyScheduleChangeHistory = [];
      this.unplannedFreeTasks = [];

      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if(task.task_secondary.length === 0){
                this.changeHistoryManagement(task, smallCategory.category_secondary_id, smallCategory.category_secondary_name)
              } else {
                task.task_secondary.forEach(taskSecondary => {
                  this.changeHistoryManagement(taskSecondary, smallCategory.category_secondary_id, smallCategory.category_secondary_name)
                });
              }
            });
          });
        });
      });

      this.unscheduledFreeTasks();
    },

    /** フリータスクの変更履歴（月次予定から予定工数/回を変更） */
    changeHistoryManagement(task, categorySecondaryId, categorySecondaryName) {
      // 対象がフリータスク、生産性評価対象業務の場合のみ処理を行う
      if (!task.standard_task_id && task.after_task_type_id === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) {
        // 配列内に同一タスクが存在するかを確認
        let index = -1;
        if(task.schedule_task_month_id) {
          // 月次予定定フリータスク
          index = this.monthlyScheduleChangeHistory.findIndex(item => {
            if(task.id && task.schedule_task_month_id){
              return item.actualTaskId === task.id && item.scheduleTaskMonthId === task.schedule_task_month_id;
            } else if(task.id){
              return item.actualTaskId === task.id;
            } else if (task.schedule_task_month_id){
              return item.scheduleTaskMonthId === task.schedule_task_month_id;
            }
          });

          if (index === -1) {
            if (task.before_cost !== Number(task.after_cost)) {
              // 配列に追加する
              this.monthlyScheduleChangeHistory.push({
                indexNo: task.index_no,
                actualTaskId: task.id,
                scheduleTaskMonthId: task.schedule_task_month_id,
                categorySecondaryId: categorySecondaryId,
                categorySecondaryName: categorySecondaryName,
                taskName: task.after_task_name,
                beforeCost: task.before_cost,
                afterCost: task.after_cost,
              });
              this.monthlyScheduleChangeHistory.sort((a, b) => a.indexNo - b.indexNo);
            }
          } else {
            // 変更前後で差分が無くなった場合、配列から削除する
            if (task.before_cost === Number(task.after_cost)) {
              this.monthlyScheduleChangeHistory.splice(index, 1);
            } else {
              // 配列を更新する
              const historyItem = this.monthlyScheduleChangeHistory[index];
              historyItem.afterCost = task.after_cost;
            }
          }
        } else {
          // 予定外フリータスク
          index = this.unplannedFreeTasks.findIndex(item => {
            if(task.id && task.schedule_task_month_id){
              return item.actualTaskId === task.id && item.scheduleTaskMonthId === task.schedule_task_month_id;
            } else if(task.id){
              return item.actualTaskId === task.id;
            } else if (task.schedule_task_month_id){
              return item.scheduleTaskMonthId === task.schedule_task_month_id;
            }
          });

          if (index !== -1) {
            // 配列を更新する
            const historyItem = this.unplannedFreeTasks[index];
            historyItem.taskName = task.after_task_name;
            historyItem.afterCost = task.after_cost;
          }
        }
      }
    },

    /** フリータスクの変更履歴（月次予定外でフリータスクを追加） */
    unscheduledFreeTasks() {
      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              // 対象が予定外のフリータスク、生産性評価対象業務の場合処理を行う
              if (!task.schedule_task_month_id && !task.standard_task_id && 
                  task.after_task_type_id === SystemConstant.TASK_TYPE.NORMAL_BUSINESS) {

                this.unplannedFreeTasks.push({
                  indexNo: task.index_no,
                  actualTaskId: task.id,
                  scheduleTaskMonthId: task.schedule_task_month_id,
                  categorySecondaryId: smallCategory.category_secondary_id,
                  categorySecondaryName: smallCategory.category_secondary_name,
                  taskName: task.after_task_name,
                  afterCost: task.after_cost,
                  afterCount: task.after_count,
                });
                this.unplannedFreeTasks.sort((a, b) => a.indexNo - b.indexNo);
              }
            })
          })
        })
      });
    },

    refreshFreeTaskChangeHistory() {
      this.getNewIndex(this.monthlyScheduleChangeHistory);
      this.getNewIndex(this.unplannedFreeTasks);
    },

    getNewIndex(array) {
      let index = 0;
      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              index++;
              task.index_no = index;

              let existingIndex = -1;
              existingIndex = array.findIndex(
                freeTask => freeTask.actualTaskId === task.id
              );
              
              if (existingIndex !== -1) {
                array[existingIndex].indexNo = task.index_no
                array[existingIndex].categorySecondaryId = smallCategory.category_secondary_id
                array[existingIndex].categorySecondaryName = smallCategory.category_secondary_name
              }
            })
          })
        })
      });
      array.sort((a, b) => a.indexNo - b.indexNo);
    },

    /** 子コンポーネントの値取得 */
    handleDataUpdate(data, index) {
      if(index){
        this.scrollChange(data);
      }else{
        this.returnChangeHistory(data);
      }
    },

    /** 自動スクロール機能 */
    scrollChange(data) {
      this.openViewLargeCategory();
      setTimeout(() => {
        // 指定されたタスクの情報を取得
        const target = document.getElementById(this.categoryId(data.categorySecondaryId));

        if (target) {
          // 指定箇所まで移動
          target.scrollIntoView({ behavior: 'instant' });
        }
      }, 100);
    },

    /** 指定されたタスクの変更を取り消す */
    returnChangeHistory(data){
      this.category_primary.forEach(allCategory => {
        allCategory.forEach(largeCategory => {
          largeCategory.category_secondary.forEach(smallCategory => {
            smallCategory.tasks.forEach(task => {
              if(task.index_no === data.indexNo) {
                task.after_cost = data.beforeCost;
                const newArray = this.monthlyScheduleChangeHistory.filter(
                  changeIndex => changeIndex.indexNo !== data.indexNo
                ); 
                this.monthlyScheduleChangeHistory = newArray;
              }
            });
          });
        });
      });
    },

    /** 画面上部の計算処理 */
    calculate() {
      this.calculateMonthlyScheduleSum();
      this.calculateMonthlyActualSum();
      this.calculateStandardTaskSum();
      this.calculatePlanActualSum();
      this.calculateProductivitySum();
      this.calculateUsualTaskEveluationSum();

      this.calculateMonthlyScheduleTime();
      this.calculateMonthlyActualTime();

    },
},

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo", "isScheduleExpand"]),
    ...mapGetters("notification", ["getCount", "getUnReadList"]),
    /**
     * format css print page
     * @return {object}
     */
    cssProps() {
      if (this.timeGridLength === 0) return;

      const unitPrint = "mm";
      const heightFcTimegridSlotDuration30 =
        (CALENDAR_PRINT_LENGHT_MM + SLOT_30_SCALE[this.timeGridLength]) /
          this.timeGridLength +
        unitPrint;
      const heightFcTimegridSlotDuration60 =
        (CALENDAR_PRINT_LENGHT_MM + SLOT_60_SCALE[this.timeGridLength]) /
          this.timeGridLength +
        unitPrint;
      let heightFcTimegridSlotDurationDefault =
        CALENDAR_PRINT_LENGHT_MM / (13 * 4) + unitPrint;

      return {
        "--height-fc-timegrid-slot-duration-default":
          heightFcTimegridSlotDurationDefault,
        "--height-fc-timegrid-slot-duration-30": heightFcTimegridSlotDuration30,
        "--height-fc-timegrid-slot-duration-60": heightFcTimegridSlotDuration60,
      };
    },

    /**
     * format date
     * @return {string} yyyy年MM月dd日
     */
    formatSelectedDate() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMM3
      );
    },


    /**
     * Check if has Suppervisor
     * @return {boolean}
     */
    hasSupervisor() {
      if (this.isSelf()) {
        return !isEmpty(this.loginInfo?.manage_id);
      } else return true;
    },

    /**
     * Check if is reject text
     * @return {boolean}
     */
    isRejectText() {
      return (
        this.statusText === SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT ||
        this.statusText === SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT
      );
    },
      },

  watch: {
    isScheduleExpand(val) {
      this.expand = val;
    },

    /** 工数表配列の変更を検知 */
    category_primary: {
      deep: true,
      handler() {

        // 合計月次工数の動的計算、画面上部の計算処理
        this.calculateMonthlySum();
        this.calculate();
        this.storageFrag = !this.isOverlay && this.isEditTask();
      },
    },
    selectUser: {
      handler(value) {
        this.isSelectUserError = Boolean(!value);
      },
    },

    /** 合計月次工数の時間変更を検知 */
    monthlyTotal() {
      this.calculateMonthlyTime();
    },

    statuses() {
      this.showNotification = this.isShowNotification();
    },
  },

  async mounted() {
    await this.confirmNotice();
    this.initializeFlag();
    /** urlの有無を判断して対応する値を出力 */
    let params = new URL(window.location.href).searchParams;
    params.get('user_id') ?
      this.userId = params.get('user_id'):
      this.userId = this.$route.query.userId || this.user?.user_id;
    params.get('target_month') ?
      this.loadTargetMonth = params.get('target_month'):
      this.loadTargetMonth = this.getTargetMonth();
    this.formatYYYYMM(this.loadTargetMonth);

    this.planId = this.$route.params.id || null;
    if (!isEmpty(this.$route.query.date))
      this.selectedDate = this.$route.query.date;

    await this.getMembers();
    const userStore = { id: this.userId };
    this.$store.dispatch("common/setMember", { selectedMember: userStore });

    this.showNotificationFromNotice();

    // 836, 841
    await this.getMonthlyActuals(this.selectUser?.id ?? this.userId, this.loadTargetMonth);
    await this.getCommentsHistory(this.selectUser?.id ?? this.userId, this.loadTargetMonth, this.actualMonthId);

    /** 画面上部の計算処理 */
    this.calculate();
    
    this.InitialDisplayCommentHistory();

    this.getMonthlyWording();

    this.getCategories();

    this.handleResize();
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.$refs.header)

    this.syncScroll();

    this.createFreeTaskChangeHistory();

    // ユーザ、カレンダーの情報を保持しておく
    this.beforeSelectionUser = this.selectUser ? JSON.parse(JSON.stringify(this.selectUser)) : "";
    this.beforeSelectionCalendar = JSON.parse(JSON.stringify(this.dispTargetMonth));
    
    // ポップアップ外をクリックした際にポップアップを閉じるイベント
    window.addEventListener("click", this.closeAddCategory);

  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener("click", this.closeAddCategory);

  },
};
</script>
<style lang="scss" src="@/assets/scss/_productivity.scss" scoped></style>
<style lang="scss">
@import "@/assets/scss/_calendar.scss";

.v-text-field.v-text-field--solo {
  .v-input__control {
    min-height: 36px !important;
  }
}

.v-center {
  display: flex;
  align-items: center;
}

.content-row {
  display: flex;
}

.content-calendar {
  width: 70%;
  padding-left: 0;
}

.my-column-full {
  width: 100% !important;
}

.content-db-list {
  width: 30%;
  border-left: 1px solid #e1e1e1;
}
.content-db-list > .row {
  height: 100%;
  margin: 0;
  padding-bottom: 0;
}

.content-db-list #dragElements {
  padding: 0;
}

.is-panel-collapsed {
  .content-calendar {
    width: 97%;
  }

  .content-db-list {
    width: 3%;
  }
}

.border-bottom {
  border-bottom: 1px solid #dddddd !important;
}

.text-large {
  font-size: 1rem;
  font-weight: 900;
}

.reject-text {
  color: #dc143c !important;
}

.header-items button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.header-items button:disabled {
  box-shadow: none;
}
/*特記事項(非活性状態)のフォント調整*/
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}
</style>

<style lang="scss" src="@/assets/scss/_calendar.scss" scoped></style>
<style lang="scss" scoped>

::v-deep .v-input__append-outer {
  margin-top: 0 !important;
}

.working-time-custom ::v-deep .v-input {
  width: 80px !important;
}

.main-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 5px 12px 7px 0;
}


.task-header {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 3;
}

.hidden-scroll {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}


.sticky-table {
  thead th{
    background: #fff;
  }
}

.total-class {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.total-plan-monthly-text-size {
  font-size: 20px;
}

.large-category {
  position: absolute;
  padding-left: 50%;
}

.center-child {
  position: relative;
}

.large-center-child {
  position: relative;
  backdrop-filter: #f0f0f0;
}

.drag-over {
  border-bottom: solid 5px #1e90ff;
}

.selected {
  background: rgba(33,150,243,0.7) !important;
  td, th {
    background: rgba(33,150,243,0.7) !important;
  }
}

.total-actuals-monthly-text-size {
  font-size: 24px;
}

.total-actuals-monthly-text-size1 {
  font-size: 28px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

tr {
  border: solid 1px rgb(221, 221, 221);
  height: 40px;
}

td {
  border: solid 1px rgb(221, 221, 221);
  padding-left:3px;
}

th {
  border: solid 1px rgb(221, 221, 221);
}

.th50 {
  width: 50%;
  max-width: 50%;
  min-width: 50%;
}

.th5 {
  width: 5%;
  max-width: 5%;
  min-width: 5%;
}


.childTask {
  padding-left: 28px;
}

.taskName .v-list-item__title {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
  width: 100%;
}

.table-column {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.table-cell{
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
  text-align: center;
}

.table-cell .v-list-item__title {
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
  text-align: center;
}

.table-column,
.table-cell,  
.taskName,
.table-cell .v-list-item__title,
.taskName .v-list-item__title {
  font-size:12px;
}

@media screen and (max-width: 1440px) {
  .table-column,
  .table-cell,
  .taskName,
  .table-cell .v-list-item__title,
  .taskName .v-list-item__title {
    font-size:10px;
  }
}

@media screen and (max-width: 1074px) {
  .table-column,
  .table-cell,
  .taskName,
  .table-cell .v-list-item__title,
  .taskName .v-list-item__title {
     font-size:9px;
  }
}

@media screen and (max-width: 765px) {
  .table-column,
  .table-cell,
  .taskName,
  .table-cell .v-list-item__title,
  .taskName .v-list-item__title {
    font-size:7px;
  }
}

@media screen and (max-width: 600px) {
  .table-column,
  .table-cell,
  .taskName,
  .table-cell .v-list-item__title,
  .taskName .v-list-item__title {
     font-size:6px;
  }
}

@media print {
  .main-scroll {
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0;
    height: 100% !important;
  }

  .table-column{
     font-size:9px;
  }
  .table-cell,
  .taskName,
  .table-cell .v-list-item__title,
  .taskName .v-list-item__title {
    font-size:9px;
  }

  /*承認済みの月次予定工数/当月の勤務時間、変更後の月次予定工数、標準工数タスク率、計画実行率、生産性評価 値調整*/
  .total-class {
    display: flex;
    justify-content: center;
    font-size: 14px !important;
  }

  /*承認済み月次予定工数/当月の勤務時間、変更後の月次予定工数、月次実績工数 値調整*/
  .header-height {
    height: 50px !important;
    font-size: 12px !important;
  }

  /*承認済みの月次予定工数/当月の勤務時間、変更後の月次予定工数、月次実績工数、生産性、生産性評価対象外の値調整*/
  .total-actuals-monthly-text-size {
    font-size: 15px !important ;
  }

  /*承認済みの月次予定工数/当月の勤務時間*/
  .total-plan-monthly-text-size {
    font-size: 14px !important;
  }

  /*変更後の月次予定工数*/
  .total-cost {
    font-size: 10px !important;
  }

  /*標準工数タスク率、計画実行率、生産性、生産性評価対象外業務のフォント調整*/
  .header-height-center {
    height: 52px !important;
    font-size: 12px !important;
    line-height: 25px;
    text-align: center;
  }

  /*評価に対する特記事項のフォント調整*/
  .print-font-adjust {
    font-size: 12px;
  }

  /*変更後予定工数/回のinputの要素調整*/
  .table-cell input[type="number"] {
   display: inline-block; 
   width: calc(100% - 10px); 
   margin-right: 0px; 
  }

  /*タスク名の調整*/
  .taskName .v-list-item__title {
    max-width: 100%;
    width: 100%;
  }

  /* スピンボタンを非表示にする */
  .table-cell input[type="number"]::-webkit-inner-spin-button,
  .table-cell input[type="number"]::-webkit-outer-spin-button {
   display: none; 
  }

  /*スクロールバーを非表示にする*/
  .width-size {
    width: auto !important;
  } 

  /*特記事項のフォント調整*/
  .custom-textarea {
    font-size: 12px;
  }

  /*「〇」「×」の位置を調整*/
  .row svg {
    height: 9px;
    width: 40%; 
  }

  /*「〇%」の位置を調整*/
  .red--text {
    font-size: 15px !important;
    line-height: 11px;
    text-align: center;
  }

  /*「〇%」の位置を調整*/
  .blue--text {
    font-size: 15px !important;
    line-height: 11px;
    text-align: center;
  }
}

.relative {
  height: 48px;
  line-height: 48px;
  position: relative;
}

.relative:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.category-add {
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 15%;
}

.category-add:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #313131;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 15px 30px; 
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 30px; 
  transform: translateX(-50%);
  box-shadow: 0 3px 9px rgb(0 0 0 / 25%);
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.py-5-top {
  padding-top: 20px !important;
}

.py-5-bottom {
  padding-bottom: 20px !important;
}

.category-pulldown {
  z-index:6;
}

.small-pulldown {
  z-index:5;
}

.small-pulldown:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.add-small-category {
  height: 48px;
  line-height: 48px;
  position: relative;
  color: gray;
}

.add-small-category:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.right-items {
  margin: 0 0 0 auto;
  padding-right: 5%;
  font-size: large;
}

.text-underline {
  text-decoration: underline;
  text-underline-position: under;
}

.text-underline-validation {
  text-decoration: underline;
  text-underline-position: under;
  background-color: #FFE0E0;
}

.text-underline-center {
  text-decoration: underline;
  text-underline-position: under;
  text-align: center;
}

.text-underline-center-validation {
  text-decoration: underline;
  text-underline-position: under;
  text-align: center;
  background-color: #FFE0E0;
}


.button-cursor:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.not-actual-dark-gray-out {
  background-color: #8b8a8a;
}

.usual-gray-out {
  background-color: #EBEBEB;
}

.remad-red-out {
  background-color: #fff5f5;
}

.white-out {
  background-color: #ffffff;
}

.red-background {
  background-color: #f38282;
  font-weight: 700;
}

.yellow-background {
  background-color: #fff8b7;
}

.red-text {
  color: red;
}

.default-text-decoration {
  color: black;
  text-decoration-color: black;
}

.pulldown-right-element {
  position: absolute;
  right: 5%;
}

.center-text {
  text-align: center;
}

.box-size {
  width: 100%;
  height: 20px;
}

.border-box {
  outline: 2px solid #EBEBEB;
  outline-offset: -5px;
}

.header-height {
  font-size: 16px;
  line-height: 25px;
}

.header-height-center {
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}

.total-cost {
  font-size: 14px;
}

.glay-color {
  color: #7F7F7F;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
}

.with-line-break {
  padding-top: 0.2em;
}

.total-cost-padding {
  padding-left: 14px;
}

.red_text_bold,
.red_text_bold input,
.red_text_bold input::placeholder{
  color: red;
  font-weight: 700;
}

.custom-select {
  position: relative;
  display: inline-block;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px;
  -webkit-appearance: menulist;
}

</style>