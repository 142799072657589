<template>
  <v-dialog
    v-model="openFreeTask"
    persistent
    max-width="600px"
    scrollable
    content-class="afreeTask"
    v-dialog-drag="{
      dialog: 'afreeTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-0 my-0">
          <span class="ml-9 mb-n4 mt-1">
            <span v-if="secondaryName">
              {{ secondaryName }}
            </span>
            <span v-else-if="!task.category_primary_id">
              {{ "未分類" }}
            </span>
          </span>
          <v-col cols="12" class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <div v-if="isMonthlyTask()">
              <v-icon small>$favoriteinactive</v-icon>
            </div>
            <div v-else @click="setFavorite()">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <v-text-field
              class="ml-2"
              maxlength="100"
              v-model="task.name"
              :error-messages="nameErrors"
              placeholder="タスク名を追加"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <v-textarea
              rows="1"
              class="ml-2"
              auto-grow
              maxlength="512"
              v-model="task.description"
              placeholder="説明を追加"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0" v-if="!isMonthlyTask() && isChangeCategory()">
          <v-col cols="12" class="py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskcategory</v-icon>
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="primaryName"
              :items="parentCategories"
              :type="'text'"
              :has-total="false"
              :showMenu="true"
              @dropdownSelected="primaryCategoryChange($event)"
            ></dropdown-button>
            <div class="mt-2 ml-4" ref="popupContainer">
              <v-btn
                v-if="showSecondCategory"
                text color="ligth"
                class="pt-0"
                v-bind="task.category_secondary_id"
                @click="changeShowPopup()"
              >
                <span>{{ task.category_secondary_name || '小カテゴリ名'}}</span>
                <v-icon large right>mdi-menu-down</v-icon>
              </v-btn>
              <category-pulldown v-if="isPulldownopen" class="category-pulldown"> 
                <div class="add-small-category">
                  <v-text-field
                    v-model="filteredKey"
                    placeholder="小カテゴリ名検索"
                    prepend-inner-icon="mdi-magnify"
                    variant="solo"
                    clearable
                    maxlength="100"
                  />
                </div>
                <div
                  class="add-small-category"
                  @click="newCategory(task.category_primary_id)"
                >
                  +新しい小カテゴリを作成する
                </div>
                <div
                  v-for="(item, index) in secondariesFilter(filteredKey)" :key="index"
                  class="category-add"
                  @click="secondaryCategoryChange(item)"
                  @mouseover="showPulldownIcon(item)"
                  @mouseleave="hidePulldownIcon()"
                >
                  <span>{{item.name}}</span>
                  <span
                    v-if="collectPulldownIcon(item , isPulldownIcon) && item.user_id"
                    class="pulldown-right-element"
                  >
                  <v-icon
                    small class="blue--text"
                    @click="editCategory($event, item)"
                  > 
                    $edit 
                  </v-icon>
                  <v-icon
                    small class="blue--text"
                    @click="deleteCategory($event, item)"
                  > 
                    $delete 
                  </v-icon>
                  </span>
                </div>
              </category-pulldown>
              <div
                v-if="
                  !this.$v.task.category_secondary_id.required &&
                  this.$v.task.category_secondary_id.$dirty
                "
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="py-0 d-flex align-center" cols="3">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
            >{{ labelType }} : </span>
          </v-col>
          <v-col class="py-0 d-flex align-center" cols="6">
            <v-select
            no-data-text="-"
            :items="taskTypeItems"
            v-model="task.task_type_id"
            :error-messages="taskTypeErrors"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-else class="py-0 my-0">
          <v-col cols="12">
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ primaryName }} </span>
            </div>
            <div v-if="showSecondCategory" class="mt-2 ml-5 category">
              <span class="ml-2"> {{ secondaryName || "小カテゴリ名" }} </span>
            </div>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name }}</span>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="py-0">
            <div class="d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次実績</span>
              <v-text-field
              class="w-130 ml-10"
                type="number"
                max="999"
                min="1"
                :error-messages="costErrors"
                suffix="m/回"
                @keydown="checkMaxLength('cost', $event)"
                @input="changeCost()"
                @input.native="updateCost"
                v-model="task.cost"
              >
              </v-text-field>
              <span v-if="!displayAsSplitTask" class="mx-2 mt-5">×</span>
              <v-text-field v-if="!displayAsSplitTask"
                class="w-60 align-self-start"
                :error-messages="countErrors"
                type="number"
                suffix="回"
                max="999"
                min="1"
                @keydown="checkMaxLength('count', $event)"
                @input.native="updateCount"
                @input="changeCount()"
                v-model="task.count"
              >
              </v-text-field>
              <span v-if="!displayAsSplitTask" class="mx-2 mt-5">=</span>
              <v-text-field v-if="!displayAsSplitTask"
                readonly
                type="number"
                class="w-95 align-self-start"
                :value="task.cost * task.count"
                suffix="m"
              ></v-text-field>
            </div>
            <div  v-if="displayAsSplitTask" class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">0 m</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <v-text-field
                v-model="task.start_time"
                :error-messages="startTimeErrors"
                maxlength="5"
                @input="changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
                type="text"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                v-model="task.end_time"
                :error-messages="endTimeErrors"
                maxlength="5"
                readonly
                type="text"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="d-flex align-center py-0">
            <v-radio-group
              :disabled="task.count && +task.count !== 1"
              v-model="task.is_split_task_completed"
            >
              <v-radio
                v-bind:value="0"
                :label="'タスクを一部着手して後日継続予定'"
              >
              </v-radio>
              <span class="ml-8">
                ※チェックを付けて保存した場合、「未完了タスク」に登録されます
              </span>
              <br />
              <v-radio v-bind:value="1" :label="'タスクが全て完了'"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!--<v-row class="py-0 my-0">
          <v-col class="d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="!hasTag">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>-->

        <!-- <v-row class="py-0 my-0">
          <v-col cols="12" class="d-flex align-center">
            <v-icon small>$outputsignoncalendar</v-icon>
            <v-btn
              @click="attachFile()"
              :loading="isSelecting"
              text
              color="ligth"
            >
              添付ファイルを追加
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              multiple
              @change="fileChanged"
            />
            <span
              >添付ファイル
              <span class="ml-5">{{ task.files.length }}件</span></span
            >
          </v-col>
          <v-col v-if="hasFile" cols="12" class="py-0">
            <v-virtual-scroll :items="task.files" :item-height="40" height="80">
              <template v-slot:default="{ item }">
                <v-list-item class="ml-4">
                  <v-list-item-icon>
                    <v-icon small>$attachedfile</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action @click="deleteAttachFile(item)">
                    <v-icon small>$delete</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row> -->

        <v-row class="py-0 my-0">
          <v-col v-if="!showComment">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
          </v-col>

          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="ml-2 mt-0"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          tile
          class="ml-auto"
          depressed
          color="primary"
          @click="createTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <AddSmallCategory
      :show="openNewCategory"
      :newLargeCategoryId="newLargeCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @secondaryCategoryCreated="categorySort()"
    ></AddSmallCategory>
    <UpdateSmallCategory
      :show="openEditCategory"
      :largeCategoryId="updateCategoryPrimaryId"
      :smallCategoryName="updateSecondaryCategoryName"
      :smallCategoryId="updateSecondaryCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @closeEvent="categorySort()"
    ></UpdateSmallCategory>
  </v-dialog>
</template>

<script>
import CategoryPulldown from '../../schedule/modal/CategoryPulldown.vue';
import AddSmallCategory from '../../schedule/modal/AddSmallCategory.vue';
import UpdateSmallCategory from "../../schedule/modal/UpdateSmallCategory.vue";
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { required, requiredIf } from "vuelidate/lib/validators";
import DropdownButton from "../../../components/DropdownButton.vue";
import { mapGetters } from "vuex";
import {
  CATEGORIES_API,
  PRIMARY_SUB_CATEGORY_API,
  UPLOAD_ATTACH_FILE_API,
  ACTUAL_TASK_API,
} from "../../../constants/api";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import {
  debounce_leading,
  isEmpty,
  isEmptyNumber,
} from "../../../helpers/helpers";
import {
  toMinute,
  toHHmm,
  toMinuteForHHmm,
  toHHmmFormat,
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC1004, FC701, FC704, FC805 } from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";
import { taskMixin } from '@/mixins/task.js';
import { FREE_TASK_TYPE_LIST } from "../../../constants/system";
export default {
  components: { 
    DropdownButton,
    CategoryPulldown,
    AddSmallCategory,
    UpdateSmallCategory, 
  },
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  props: {
    show: Boolean,
    istartTime: String,
    iendTime: String,
    iactualId: String,
    canDuplicate: {
      type: Boolean,
      default: false,
    },
    inputTask: {},
  },
  mixins: [validationMixin, backMixin, taskMixin],
  validations: {
    task: {
      name: { required },
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id !== null;
        }),
      },
      task_type_id: {
        required,
      },
      output_detail: {
        required: requiredIf((task) => {
          return task.is_need_output;
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      filteredKey: "",
      isSelecting: false,
      openFreeTask: this.show,
      showComment: false,
      userComment: "",
      isEditing: false,
      parentCategories: [],
      childCategories: [],
      startTime: this.istartTime,
      endTime: this.iendTime,
      actualId: this.actualId,
      taskTypeItems: FREE_TASK_TYPE_LIST,
      task: {
        category_secondary_id: null,
        is_favorite: false,
        is_need_output: false,
        start_time: this.istartTime,
        end_time: this.iendTime,
        files: [],
        //tags: [],
        comments: [],
        is_split_task_completed: 1,
        schedule_month_task_id: null,
      },
      isPulldownopen: false,
      isPulldownIcon: false,
      openNewCategory: false,
      openEditCategory: false,
      updateCategoryPrimaryId: 0,
      updateSecondaryCategoryName: "",
      updateSecondaryCategoryId: 0,
      newLargeCategoryId: 0,
      userId: "",
      groupId: "",
    };
  },
  async mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
    // ポップアップ外をクリックした際にポップアップを閉じるイベント
    window.addEventListener("click", this.leaveSelect);
  },
  watch: {
    async show() {
      this.openFreeTask = true;
      this.$v.$reset();
      this.showComment = false;
      this.userComment = "";
      if (isEmpty(this.inputTask)) {
        this.task = {
          name: "",
          description: "",
          category_secondary_id: null,
          task_type_id: null,
          is_favorite: false,
          is_need_output: false,
          start_time: this.istartTime,
          end_time: this.iendTime,
          files: [],
          //tags: [],
          comments: [],
          cost: null,
          count: null,
          category_primary_id: null,
          output_detail: "",
          is_split_task_completed: 1
        };
      } else {
        this.task.start_time = "";
        this.task.end_time = "";
      }
      this.actualId = this.iactualId;
      await this.getCategories();
    },
    istartTime: {
      handler(val) {
        this.startTime = val;
        //this.task.start_time = val;
      },
    },
    iendTime: {
      handler(val) {
        this.endTime = val;
        this.task.end_time = val;
      },
    },
    iactualId: {
      handler(val) {
        this.actualId = val;
      },
    },
    inputTask: {
      deep: true,
      handler(val) {
        this.task = {
          ...this.task,
          ...val,
          ...{
            is_favorite: false,
            comments: [],
            files: [],
            //tags: [],
          },
        };
        this.childCategories = this.parentCategories.find(
          (x) => x.id === this.task.category_primary_id
        )?.secondaries;
      },
    },
    task: {
      deep: true,
      handler(value) {
        // this.isEditing = true;
        this.isEditing =
          JSON.stringify({
            name: value.name,
            description: value.description,
            category_secondary_id: value.category_primary_id,
            category_primary_id: value.category_secondary_id,
            task_type_id: value.task_type_id,
            is_need_output: value.is_need_output,
            output_detail: value.output_detail,
            is_favorite: value.is_favorite,
            cost: value.cost,
            count: value.count,
            files: value.files,
            start_time: value.start_time,
          }) !==
          JSON.stringify({
            name: "",
            description: "",
            category_secondary_id: null,
            category_primary_id: null,
            task_type_id: null,
            is_need_output: false,
            output_detail: "",
            is_favorite: false,
            cost: null,
            count: null,
            files: [],
            start_time: isEmpty(this.inputTask) ? this.istartTime : "",
          });
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler: function (value) {
        this.isEditing = !isEmpty(value);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    taskTypeErrors() {
      const errors = [];
      if (this.$v.task.task_type_id.$dirty) {
        !this.$v.task.task_type_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "未分類";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    hasFile() {
      return this.task && this.task.files && this.task.files.length > 0;
    },
    /*hasTag() {
      return this.task && this.task.tags && this.task.tags.length > 0;
    },*/
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },

    // タスクは完了かどうか判断
    displayAsSplitTask(){
      return this.task.is_split_task_completed === 0;
    }
  },
  methods: {
    secondariesFilter(filteredKey){
      let items = this.childCategories.filter(item => {
        if(filteredKey){
          if(item.name.includes(filteredKey)){
            return item
          }
        } else {
          return item
        }
      })
      return items;
    },
    clearFilteredKey(){
      this.filteredKey = "";
    },
    async getCategories() {
      try {
        this.groupId = this.loginInfo.group_id;
        this.userId = this.loginInfo.user_id;
        const { data, status } = await axios.get(CATEGORIES_API(
          this.groupId,
          this.userId
        ));

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.childCategories = this.parentCategories.find(
            (x) => x.id === this.task.category_primary_id
          )?.secondaries;

        this.parentCategories.push({
          id: null,
          name: "未分類",
          secondaries: [],
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    changeCount() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    changeCost() {
      if (this.displayAsSplitTask){
        this.task.count = 1;
      }

      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    updateComment() {
      this.showComment = true;
    },

    /**
     * Check if monthlyTask
     * @return {boolean}
     */
    isMonthlyTask() {
      return this.task.schedule_month_task_id ? true : false;
    },

    /**
     * Check if changeCategory
     * @return {boolean}
     */
    isChangeCategory() {
      if (this.task.is_changeability != null){
        return this.task.is_changeability ? true : false;
      }
      return true;
    },

    createTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        await this.uploadFile();
        let params = [
          {
            name: this.task.name,
            description: this.task.description,
            category_secondary_id: this.task.category_secondary_id,
            task_type_id: this.task.task_type_id,
            cost: +this.task.cost,
            count: this.displayAsSplitTask ? 1 : this.task.count,
            is_need_output: this.task.is_need_output ? 1 : 0,
            output_detail: this.task.is_need_output ? this.task.output_detail : null,
            is_favorite: this.task.is_favorite ? 1 : 0,
            schedule_month_task_id: this.task.schedule_month_task_id ?? null,
            comments:
              !isEmpty(this.userComment) && this.showComment
                ? [
                    {
                      user_id: this.loginInfo.user_id,
                      comment: this.userComment,
                    },
                  ]
                : [],
            start_time: (this.task.start_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            end_time: (this.task.end_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            is_remand: 0,
            is_split_task: this.displayAsSplitTask ? 1 : 0,
            is_split_task_completed: this.task.is_split_task_completed,
            is_completed: 0,
            files: this.task.files.map((x) => {
              return { id: x.id };
            }),
          },
        ];
        if (params.category_primary_id === null)
          params.category_secondary_id = null;
        try {
          const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
            actual_tasks: params,
          });
          this.$store.dispatch("save/setChangeFlag", false);
          this.openFreeTask = false;
          this.task.id = data.result[0].id;
          this.task.actual_free_task_id = data.result[0].actual_free_task_id;
          this.task.favorite_task_id = data.result[0].favorite_task_id;
          this.task.category_secondary_name = data.result[0].category_secondary_name;
          this.$emit("createTaskSuccess", this.task);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC805, status);
        }
      }
    }),

    setFavorite() {
      if (this.task.is_favorite === 1) {
        this.task.is_favorite = 0;
      }
      else{
        this.task.is_favorite = 1;
      }
    },

    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    deleteAttachFile(item) {
      this.$confirm(this.$t("label.confirm.delete_attach"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(() => {
          this.task.files = this.task.files.filter((x) => x.name !== item.name);
        })
        .catch(() => {});
    },

    attachFile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    async uploadFile() {
      let formData = new FormData();
      for (var i = 0; i < this.task.files.length; i++) {
        formData = new FormData();
        formData.append("file", this.task.files[i].fileContent);
        try {
          const { data } = await axios.post(
            UPLOAD_ATTACH_FILE_API(),
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.task.files[i].id = data.result.file_id;
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC1004, status);
        }
      }
    },

    async fileChanged(e) {
      let countFileError = 0;
      let selectedFiles = e.target.files;
      if (this.task.files.length + selectedFiles.length > 10) {
        this.$alert(this.$t("label.error.max_attach"), {
          width: "500px",
        });
      } else {
        for (var i = 0; i < selectedFiles.length; i++) {
          let fileSize = selectedFiles[i].size / 1024 / 1024;
          if (fileSize > 10) {
            countFileError = countFileError + 1;
          } else {
            this.task.files.push({
              fileContent: selectedFiles[i],
              name: selectedFiles[i].name,
              id: i,
            });
          }
        }
        if (countFileError > 0) {
          this.$toast(
            this.$t("label.error.max_byte", { 0: `${countFileError}` }),
            3000
          );
        }
      }
      this.$refs.uploader.value = null;
    },

    primaryCategoryChange(primaryCategory) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === primaryCategory.id
      )?.secondaries;

      this.task.category_primary_id = primaryCategory.id;
      this.task.category_secondary_id = null;
      this.task.category_secondary_name  = null;
    },

    secondaryCategoryChange(secondCategory) {
      this.task.category_secondary_id = secondCategory.id;
      this.task.category_secondary_name = secondCategory.name;
      this.isPulldownopen = false;
      this.clearFilteredKey();
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openFreeTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openFreeTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    /**
     * Handle control icon View
     * @return {boolean}
     */
    showPulldownIcon(pulldownOption) {
      this.isPulldownIcon = pulldownOption;
    },

    hidePulldownIcon(){
      this.isPulldownIcon = "";
    },

    collectPulldownIcon(str1 , str2) {
      if(str1 === str2) return true;
      else return false;
    },

    /**
     * Handle show category pulldown
     * @return {boolean}
     */
    changeShowPopup(){
      this.isPulldownopen = !this.isPulldownopen;
      this.clearFilteredKey();
    },

    leaveSelect(event) {
      const popupContainer = this.$refs.popupContainer;
      
      // popupContainer が存在し、クリックされた要素が popupContainer の外側の場合
      if (popupContainer && !popupContainer.contains(event.target)) {
        this.isPulldownopen = false; // ポップアップを閉じる
        this.clearFilteredKey(); // フィルターをクリア

      }
    },

    /**
     * Handle update edit category name
     * @param {object} event
     * @return {boolean}
     */
    editCategory(event, item) {
      event.stopPropagation();
      this.updateCategoryPrimaryId = this.task.category_primary_id;
      this.updateSecondaryCategoryName = item.name;
      this.updateSecondaryCategoryId = item.id;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openEditCategory = !this.openEditCategory;
    },

    newCategory(categoryPrimaryId) {
      this.newLargeCategoryId = categoryPrimaryId;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openNewCategory = !this.openNewCategory;
    },

    /**
     * Handle delete category event
     * @param {object} event
     * @return {boolean}
     */
    deleteCategory(event, item) {
      event.stopPropagation();

      /** 登録されている小カテゴリの場合 */
      let selectcategory = this.task.category_secondary_id
      if (selectcategory === item.id) {
        this.notDeleteCategory();
      } else {
        this.$confirm(this.$t("label.confirm.delete_secondary_category"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        })
          .then(async () => {
            try {
              await axios.delete(
                PRIMARY_SUB_CATEGORY_API(
                  this.task.category_primary_id,
                  item.id,
                  item.user_id
                )
              );

              this.$toast(
                `${item.name}を削除しました。`,
                3000
              );
              this.categorySort()
            } catch (error) {
              const { status } = error.response;
              if (status === 404) {
                this.$alert(error.response.data.message, {
                  width: "820px",
                });
              } else {
                apiErrorMessages(this, FC704, status);
              }
            }
          })
          .catch(() => {});
      }
    },

    notDeleteCategory() {
      this.$alert([this.$t("label.confirm.not_delete_category.line1")])
        .then(() => {})
        .catch(() => {});
    },

    categorySort() {
      this.getCategories();
      this.changeShowPopup();
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
    window.removeEventListener("click", this.leaveSelect);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}

.align-items-baseline {
  align-items: baseline;
}

.w-120 {
  width: 100px;
}
.w-90 {
  width: 90px;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.category-pulldown {
  z-index:999;
}
.category-add{
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 15%;
  font-size : 15px;
}
.category-add:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.add-small-category{
  height: 48px;
  line-height: 48px;
  position: relative;
  color: gray;
  font-size : 15px;
}
.add-small-category:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.pulldown-right-element {
  position: absolute;
  right: 5%;
}
</style>
