<template>
  <v-dialog persistent :value="openDialog" max-width="300px">
    <v-card class="py-3">
      <v-card class="mx-5 py-0" color="primary" dark>
        <v-card-title>CSVエクスポート設定 </v-card-title>
      </v-card>
      <v-card-text class="mt-5">
        <v-radio-group v-model="radioExport">
          <v-radio value="1" :label="'全業務カテゴリ'"></v-radio>
          <v-radio value="0" :label="'表示されている業務カテゴリ'"></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn tile color="ligth darken-1" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          tile
          :disabled="isDisable"
          color="primary darken-1"
          @click="submit()"
          >エクスポート</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EXPORT_CATEGORY_API } from "../../../constants/api";
import { FC716 } from "../../../constants/fc";
import { apiErrorMessages } from "../../../helpers/messages";
import { debounce_leading, exportShiftJIS } from "../../../helpers/helpers";
import axios from "axios";
export default {
  props: {
    show: Boolean,
    inputCondition: Object,
  },
  watch: {
    show: {
      handler() {
        this.openDialog = true;
        this.radioExport = "3";
      },
    },
    inputCondition: {
      immediate: true,
      handler(val) {
        this.condition = val;
      },
    },
  },
  data() {
    return {
      openDialog: false,
      radioExport: "3",
      condition: {},
    };
  },
  computed: {
    isDisable() {
      return (
        +this.radioExport === 3 &&
        +this.radioExport !== 0 &&
        +this.radioExport !== 1
      );
    },
  },
  methods: {
    submit :debounce_leading(async function() {
      try {
        const { data, headers } = await axios.get(
          EXPORT_CATEGORY_API(this.radioExport, this.condition),
          {
            responseType: "blob",
          }
        );
        const headerval = headers["content-disposition"];
        const filename = headerval
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");
        exportShiftJIS(data, filename);
        this.openDialog = false;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC716, status);
      }
    }),
    close() {
      this.openDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
