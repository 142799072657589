<template>
  <v-navigation-drawer v-model="show" right width="auto">
    <v-list-item class="px-2 mt-4">
      <v-row>
        <v-col cols="10" class="v-center pl-5">
          未完了タスク
          </v-col>
        <v-col cols="2">
          <v-btn
            icon
            @click.stop="
              show = false;
              $emit('close');
            "
          >
            <v-icon small>$close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item>
    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="7">
          <v-text-field
            solo
            placeholder="検索"
            maxlength="32"
            @change="handleSearch"
            v-model="search"
          >
            <template>
              <v-icon small slot="prepend-inner"> $search </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <!--<v-col cols="5">
          <FilterOption
            @reset="resetFilter"
            @submit="submitFilter"
            :items="filters"
          ></FilterOption>
        </v-col>-->
      </v-row>
    </v-list-item>
    <v-divider></v-divider>

    <div
      class="text-center"
      v-if="
        !(todoTasks && todoTasks.length > 0) &&
        !(splitTasks && splitTasks.length > 0) &&
        !isEmptySearch(this.search)
      "
    >
      <p class="gray--text">検索条件に一致するタスクは</p>
      <p class="gray--text">見つかりませんでした。</p>
    </div>

    <div class="content overflow-y-auto">
      <div class="d-flex justify-center" v-if="isLoading">
        <svg
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          stroke="#000"
          class="sidebar-loader"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      <v-list class="custom-tree" v-else>
        <v-list-group
          v-if="splitTasks && splitTasks.length > 0"
          sub-group
          v-model="splitModel"
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-row>
              <v-col cols="12">
                <v-list-item-content>
                  <v-list-item-title>一部着手タスク</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="12" class="pd0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>

          <v-list-item class="justify-center" v-if="isSplitLoading"
            ><svg
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              stroke="#000"
              class="sidebar-loader"
            >
              <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                  <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="0.8s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g></svg
          ></v-list-item>
          <div v-else>
            <v-list-item
              :class="[{ 'todo-task': isAllowDrag }]"
              :name="item.id"
              v-for="(item, i) in splitTasks"
              data-type="split"
              :key="i"
              @click.stop
            >
              <v-list-item-icon>
                <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
                <v-icon small v-else>$favoriteinactive</v-icon>
              </v-list-item-icon>
              <DoubleClick
                tag="v-list-item-content"
                @single-click="editTodoTask(item)"
                @double-click="isAllowDrag() && registerTodo(item)"
              >
                <v-row style="width: 100%">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <div class="d-flex align-center flex-wrap">
                                <div class="text-truncate mr-1">
                                  {{ item.name }}
                                  {{ item.detail ? item.detail : ""}}
                                </div>
                                <BeginnerIcon v-if="isBeginnerSkill(item)" />
                                <ExpertIcon v-if="isExpertSkill(item)" />
                              </div>
                            </v-list-item-title>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <span>{{getCumulative(item)}}</span
                        ></v-col
                      >
                    </v-row>

                    <!--<v-row>
                      <v-col cols="12" class="text-right">
                        <v-list-item-title v-if="item.tags">
                          <v-chip
                            v-for="tag in item.tags.slice(0, 3)"
                            :key="tag.id"
                            x-small
                            depressed
                          >
                            {{ getFiveCharacters(tag.name) }}
                          </v-chip>
                        </v-list-item-title>
                      </v-col>
                    </v-row>-->
                  </v-col>
                </v-row>
              </DoubleClick>
            </v-list-item>
          </div>
        </v-list-group>

        <v-list-group
          v-if="isBreakTask || (todoTasks && todoTasks.length > 0)"
          sub-group
          v-model="todoModel"
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-row>
              <v-col cols="12">
                <v-list-item-content>
                  <v-list-item-title>その他タスク</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="12" class="pd0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
          <v-list-item class="justify-center" v-if="isTodoLoading"
            ><svg
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              stroke="#000"
              class="sidebar-loader"
            >
              <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                  <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="0.8s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g></svg
          ></v-list-item>
          <div v-else>
            <v-list-item
              :class="[{ 'todo-task': isAllowDrag }]"
              :name="item.id"
              v-for="(item, i) in todoTasks"
              data-type="todo"
              :key="i"
              @click.stop
            >
              <v-list-item-icon>
                <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
                <v-icon small v-else>$favoriteinactive</v-icon>
              </v-list-item-icon>
              <DoubleClick
                tag="v-list-item-content"
                @single-click="editTodoTask(item)"
                @double-click="isAllowDrag() && registerTodo(item)"
              >
                <v-row style="width: 100%">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <div class="d-flex align-center flex-wrap">
                                <div class="text-truncate mr-1">
                                  {{ item.name }}
                                  {{ item.detail ? item.detail : ""}}
                                </div>
                                <BeginnerIcon v-if="isBeginnerSkill(item)" />
                                <ExpertIcon v-if="isExpertSkill(item)" />
                              </div>
                            </v-list-item-title>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <span>{{ item.cost }}m/回</span></v-col
                      >
                    </v-row>

                    <!--<v-row>
                      <v-col cols="12" class="text-right">
                        <v-list-item-title v-if="item.tags">
                          <v-chip
                            v-for="tag in item.tags.slice(0, 3)"
                            :key="tag.id"
                            x-small
                            depressed
                          >
                            {{ getFiveCharacters(tag.name) }}
                          </v-chip>
                        </v-list-item-title>
                      </v-col>
                    </v-row>-->
                  </v-col>
                </v-row>
              </DoubleClick>
            </v-list-item>
            <v-list-item
              v-if="isBreakTask"
              @click.stop
            >
              <DoubleClick
                tag="v-list-item-content"
                @single-click="editBreakTodoTask()"
                @double-click="registerBreak()"
              >
                <v-row style="width: 100%">
                  <v-col cols="12">                    
                    <v-row>
                      <v-col cols="8">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <div class="d-flex align-center flex-wrap">
                                <div class="text-truncate mr-1">{{ "休憩" }}</div>
                              </div>
                            </v-list-item-title>
                          </template>
                          <span>{{ "休憩" }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" class="text-right">
                        <span>{{ 60 }}m/回</span></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </DoubleClick>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list>
    </div>

    <ActionTodoTask
      @updateTask="updateTask($event)"
      :taskId="taskId"
      :show="showToDoEdit"
      @deleteTodoTask="deleteTodoTask($event)"
      :isSplit="isSplit"
    ></ActionTodoTask>
  </v-navigation-drawer>
</template>

<script>
//import FilterOption from "../../schedule/modal/FilterOption";
import ActionTodoTask from "./ActionTodoTask";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { SPLIT_TASK_API, TODO_TASK_API } from "../../../constants/api";
import { mapGetters } from "vuex";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC807, FC808, FC823, FC824 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DoubleClick from "../../../components/DoubleClick";
import BeginnerIcon from "@/components/icons/BeginnerIcon.vue";
import ExpertIcon from "@/components/icons/ExpertIcon.vue";
import * as systemAuth from "@/constants/role_code";
export default {
  components: {
    //FilterOption,
    ActionTodoTask,
    DoubleClick,
    BeginnerIcon,
    ExpertIcon,
  },
  props: {
    userId: String,
    value: Boolean,
    isBreakTask: {type:[Boolean], default:false },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
    },
    show: {
      async handler(val) {
        this.$emit("input", val);
      },
    },
    splitModel: {
      async handler(val) {
        if (val) {
          this.getSplitData();
        }
      },
    },
    todoModel: {
      async handler(val) {
        if (val) {
          this.getData();
        }
      },
    },
  },

  methods: {
    registerTodo(task) {
      this.$root.$refs.actualComponent.addEventFromTodoList(task);
    },
    registerBreak() {
      this.$root.$refs.actualComponent.addEventFromTodoListbreak();
    },
    /*updateTag(item) {
      if (item.isSplit) {
        let task = this.splitTasks.filter((x) => x.id == item.id)[0];
        if (task) task.tags = item.tags;
        task = this.dbSplitTasks.filter((x) => x.id == item.id)[0];
        if (task) task.tags = item.tags;
        this.reloadFilter();
      } else {
        let task = this.todoTasks.filter((x) => x.id == item.id)[0];
        if (task) task.tags = item.tags;
        task = this.dbTodoTasks.filter((x) => x.id == item.id)[0];
        if (task) task.tags = item.tags;
      }
      this.reloadFilter();
    },*/

    isAllowDrag() {
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },

    setFavorite(item) {
      if (item.isSplit) {
        let task = this.splitTasks.filter((x) => x.id == item.id)[0];
        if (task) task.is_favorite = item.is_favorite;
        this.sortSplitTasks();
        task = this.dbTodoTasks.filter((x) => x.id == item.id)[0];
        //if (task) task.tags = item.tags;
      } else {
        let task = this.todoTasks.filter((x) => x.id == item.id)[0];
        if (task) task.is_favorite = item.is_favorite;
        this.sortTodoTasks();
        task = this.dbTodoTasks.filter((x) => x.id == item.id)[0];
        //if (task) task.tags = item.tags;
      }
    },

    registerTodoTask(task) {
      task.isSplit = false;
      task.free_task_id = task.free_task_id || task.actual_free_task_id;
      task.id = task.todoTaskId;
      this.todoTasks.push(task);
      this.dbTodoTasks.push(task);
      this.sortTodoTasks();
      //this.reloadFilter();
    },
    registerDbTodoTask(task) {
      task.isSplit = false;
      this.todoTasks.push(task);
      this.dbTodoTasks.push(task);
      this.sortTodoTasks();
      //this.reloadFilter();
    },

    updateTask(task) {
      if (!task.isSplit) {
        this.todoTasks = this.todoTasks.filter((x) => x.id != task.id);
        this.dbTodoTasks = this.dbTodoTasks.filter((x) => x.id != task.id);
        this.todoTasks.push(task);
        this.dbTodoTasks.push(task);
      } else {
        this.splitTasks = this.splitTasks.filter((x) => x.id != task.id);
        this.dbSplitTasks = this.dbSplitTasks.filter((x) => x.id != task.id);
        this.splitTasks.push(task);
        this.dbSplitTasks.push(task);
      }
      this.markAndSortFavoriteTodoTasks(task);
      this.markAndSortFavoriteSplitTasks(task);
      //this.reloadFilter();
    },

    deleteTodoTask(task) {
      if (!task.isSplit) {
        this.dbTodoTasks = this.dbTodoTasks.filter((x) => x.id != task.id);
        this.todoTasks = this.todoTasks.filter((x) => x.id != task.id);
        this.sortTodoTasks();
      } else {
        this.dbSplitTasks = this.dbSplitTasks.filter((x) => x.id != task.id);
        this.splitTasks = this.splitTasks.filter((x) => x.id != task.id);
        this.sortSplitTasks();
      }
      //this.reloadFilter();
      this.$root.$refs.actualComponent?.deleteTodoTask();
    },
    /*reloadFilter() {
      let tags = [];
      let tempData = [...this.dbTodoTasks, ...this.dbSplitTasks];
      tempData.forEach((dbTask) => {
        tags = tags.concat(
          dbTask.tags.map((x) => {
            return x.name;
          })
        );
      });
      this.filters = tags
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((name) => {
          return { check: false, tag_name: name };
        });
    },
    getFiveCharacters(name) {
      return !isEmpty(name) && name.length > 5
        ? name.substring(0, 5) + "..."
        : name;
    },*/
    markAndSortFavoriteTodoTasks(item) {
      this.todoTasks.map((task) => {
        if (
          task.id !== item.id &&
          task.standard_task_id === item.standard_task_id &&
          !task.free_task_id
        ) {
          task.is_favorite = item.is_favorite;
        }
      });
      this.sortTodoTasks();
    },
    markAndSortFavoriteSplitTasks(item) {
      this.splitTasks.map((task) => {
        if (
          task.id !== item.id &&
          task.standard_task_id === item.standard_task_id &&
          !task.free_task_id
        ) {
          task.is_favorite = item.is_favorite;
        }
      });
      this.sortSplitTasks();
    },
    sortSplitTasks() {
      this.splitTasks.sort(
        (a, b) =>{
          if (a.updated_at > b.updated_at){
            return -1;
          }
          return 1;
        } 
      );
    },
    sortTodoTasks() {
      this.todoTasks.sort(
        (a, b) =>
          Number(b.is_favorite) - Number(a.is_favorite) ||
          a.name.localeCompare(b.name) ||
          b.id - a.id
      );
    },
    async isFavorite(item) {
      if (!item.isSplit) {
        try {
          await axios.put(TODO_TASK_API(item.id), {
            tasks:[{
              is_favorite: +!item.is_favorite,
            }]
          });
          item.is_favorite = !item.is_favorite;
          this.markAndSortFavoriteTodoTasks(item);
          this.markAndSortFavoriteSplitTasks(item);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC808, status);
        }
      } else {
        try {
          await axios.put(SPLIT_TASK_API(item.id), {
            is_favorite: +!item.is_favorite,
          });
          item.is_favorite = !item.is_favorite;
          this.markAndSortFavoriteTodoTasks(item);
          this.markAndSortFavoriteSplitTasks(item);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC824, status);
        }
      }
    },

    handleSearch() {
      this.todoTasks = this.dbTodoTasks.filter(
        (y) =>
          isEmpty(this.search) ||
          y.name.includes(this.search) /*||
          (y.tags &&
            y.tags.some(
              (z) => isEmpty(this.search) || z.name.includes(this.search)
            ))*/
      );
      this.splitTasks = this.dbSplitTasks.filter(
        (y) =>
          isEmpty(this.search) ||
          y.name.includes(this.search) /*||
          (y.tags &&
            y.tags.some(
              (z) => isEmpty(this.search) || z.name.includes(this.search)
            ))*/
      );
      this.sortTodoTasks();
      this.sortSplitTasks();
    },

    isEmptySearch() {
      return isEmpty(this.search);
    },

    isBeginnerSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_BEGIN
    },

    isExpertSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_EXPERT
    },
    
    resetFilter() {
      this.todoTasks = this.dbTodoTasks;
      this.splitTasks = this.dbSplitTasks;
    },

    submitFilter(filters) {
      this.todoTasks = this.dbTodoTasks.filter(
        /*(y) =>
          (y.tags &&
            y.tags.some((z) =>
              filters.some((g) => g.check && g.tag_name === z.name)
            )) ||*/
          !filters.some((g) => g.check)
      );
      this.splitTasks = this.dbSplitTasks.filter(
        /*(y) =>
          (y.tags &&
            y.tags.some((z) =>
              filters.some((g) => g.check && g.tag_name === z.name)
            )) ||*/
          !filters.some((g) => g.check)
      );
      this.sortTodoTasks();
      this.sortSplitTasks();
    },

    editTodoTask(item) {
      this.taskId = item.id;
      this.isSplit = item.isSplit;
      this.showToDoEdit = !this.showToDoEdit;
    },

    getTodoList() {
      return this.dbTodoTasks;
    },
    getSplitTask() {
      return this.dbSplitTasks;
    },
    removeTodoTask(id) {
      this.todoTasks = this.todoTasks.filter((x) => x.id !== id);
      this.dbTodoTasks = this.dbTodoTasks.filter((x) => x.id !== id);
      // this.reloadFilter();
    },
    removeSplitTask(id) {
      this.splitTasks = this.splitTasks.filter((x) => x.id !== id);
      this.dbSplitTasks = this.dbSplitTasks.filter((x) => x.id !== id);
      // this.reloadFilter();
    },

    async getData() {
      //TODO reset search
      this.search = "";
      try {
        this.isTodoLoading = true;
        const { data, status } = await axios.get(
          `${TODO_TASK_API()}` + `?user_id=${this.user.user_id}`
        );
        let dbTasks = status === STATUS_NO_CONTENT ? [] : data.result;
        dbTasks.forEach((dbTask) => {
          if (dbTask.standard_task_id) {
            dbTask.count = 1;
          } 
          dbTask.isSplit = false;
        });
        this.todoTasks = dbTasks;
        this.sortTodoTasks();
        this.dbTodoTasks = deepClone(dbTasks);
        //this.reloadFilter();
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC807, status);
      } finally {
        this.isTodoLoading = false;
      }
    },
    async loadTodoListData() {
      this.isLoading = true;
      await this.getData();
      await this.getSplitData();
      this.isLoading = false;
    },
    async getSplitData() {
      //TODO reset search
      this.search = "";
      try {
        this.isSplitLoading = true;
        const { data, status } = await axios.get(`${SPLIT_TASK_API()}`);
        let dbTasks = status === STATUS_NO_CONTENT ? [] : data.result;
        dbTasks.forEach((dbTask) => {
          dbTask.isSplit = true;
          dbTask.count = 1;
        });
        this.splitTasks = dbTasks;
        this.sortSplitTasks();
        this.dbSplitTasks = deepClone(dbTasks);
        //this.reloadFilter();
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC823, status);
      } finally {
        this.isSplitLoading = false;
      }
    },
    /*removeTag(tag) {
      try {
        this.todoTasks = this.todoTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.dbTodoTasks = this.dbTodoTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.sortTodoTasks();
        this.splitTasks = this.splitTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.dbSplitTasks = this.dbSplitTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.sortSplitTasks();
        this.reloadFilter();
      } catch {
        return;
      }
    },
    editTag(tag) {
      const addTag = function (y) {
        if (y.tags.some((z) => +z.id === +tag.id)) {
          y.tags = y.tags.map((x) => {
            if (+tag.id === +x.id) {
              return tag;
            } else {
              return x;
            }
          });
        }
        return y.tags;
      };
      try {
        this.todoTasks = this.todoTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.dbTodoTasks = this.dbTodoTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.splitTasks = this.splitTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.dbSplitTasks = this.dbSplitTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.reloadFilter();
      } catch {
        return;
      }
    },*/
    getCumulative(item){
      if (item.free_task_id || item.actual_free_task_id){
        return '累計' + item.total_actual_cost + 'm';
      }
      return '累計' + item.total_actual_cost + '/' + item.total_cost + 'm';
    },
  },

  data() {
    return {
      search: "",
      show: false,
      showToDoEdit: false,
      freeTaskId: null,
      showDatabaseTask: false,
      todoTasks: [],
      dbTodoTasks: [],
      filters: [],
      taskId: null,
      isFreeTask: false,
      splitTasks: [],
      dbSplitTasks: [],
      isTodoLoading: false,
      isSplitLoading: false,
      isLoading: false,
      splitModel: true,
      todoModel: true,
      isSplit: false,
    };
  },

  created() {
    this.$root.$refs.actualTodoList = this;
  },
};
</script>
<style lang="scss" scoped >
.content {
  height: calc(
    (var(--vh, 1vh) * 100) - 50px - 60px - 48px - 66px - 37px - 24px
  );
  overflow-x: hidden;
  ::v-deep .v-list-group__header {
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.87);
    .v-list-group__header__prepend-icon {
      margin-right: 0;
    }
  }
  ::v-deep
    .v-list-group--sub-group
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(-90deg);
  }
  ::v-deep
    .v-list-group--sub-group.v-list-group--active
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(0deg);
  }
  ::v-deep .expertTaskIcon > img.v-icon__component {
    width: 30px;
  }
  ::v-deep .beginnerTaskIcon > img.v-icon__component {
    width: 38px;
    height: 28px;
  }
}
.pd0 {
  padding: 0;
}
.custom-tree {
  ::v-deep .v-list-group__items {
    width: 100%;
  }
  .v-list-item__content {
    align-items: start;
    flex-wrap: nowrap;
    flex: 1 1;
    overflow: hidden;
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid #e1e1e1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
  }
  .v-list-item__icon:first-child {
    margin-right: 2px;
  }
  .v-list-item__icon {
    align-self: center;
    margin: 0;
  }
}
.sidebar-loader {
  margin-top: 10px;
}
</style>
