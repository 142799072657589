<template>
  <v-navigation-drawer right :width="setWidth()">
    <v-list-item class="mt-4" id="taskChangeHeader">
      <v-row>
        <v-col class="title" cols="10">
          <div>
            {{ this.$t("label.monthly_data.change_history_title") }}
          </div>
          <div>
            {{ this.$t("label.monthly_data.change_history_create") }}
          </div>
        </v-col>
        <v-col cols="2">
          <v-btn icon @click="close()">
            <v-icon small>
              $close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item>

    <div class="main-scroll" :style="{ maxHeight: `${offset}px`}">
      <!-- 月次予定から予定工数/回を変更 -->
      <div class="explanation-border-block">
        <div @mousedown="showElementCost = !showElementCost;" class="explanation-toggle">
          <span class="icon-position">
            <v-icon v-if="showElementCost">
              mdi-chevron-down
            </v-icon>
            <v-icon v-else>
              mdi-chevron-right
            </v-icon>
          </span>
          <span>
            {{ this.$t("label.monthly_data.change_history_cost") }}
          </span>
        </div>

        <template v-if="showElementCost" class="col">
          <v-row v-for="(task, tasks) in changeFreeTasks" :key="tasks" class="explanation-border-top">
            <v-col class="explanation-click" cols="10" @click="scrollChangeTasks(task)">
              <div class="explanation-hidden">
                <div class="explanation-indent">
                  {{ task.indexNo }}.{{ task.categorySecondaryName }}_{{ task.taskName }}
                </div>
                <div class="explanation-content">
                  予定工数/回を{{ task.beforeCost }}mから{{ task.afterCost }}mに変更
                </div>
              </div>
            </v-col>

            <v-col class="explanation-delete" cols="2">
              <v-btn v-if="isSelf()" icon @click="returnChange(task)">
                <v-icon small>
                  $close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </div>

      <!-- 月次予定外でフリータスクを追加 -->
      <div class="explanation-border-block">
        <div @mousedown="showElementTask = !showElementTask;" class="explanation-toggle">
          <span class="icon-position">
            <v-icon v-if="showElementTask">
              mdi-chevron-down
            </v-icon>
            <v-icon v-else>
              mdi-chevron-right
            </v-icon>
          </span>
          <span>
            {{ this.$t("label.monthly_data.change_history_task") }}
          </span>
        </div>

        <template v-if="showElementTask" class="col">
          <v-row v-for="(task, tasks) in unplannedFreeTask" :key="tasks" class="explanation-border-top">
            <v-col class="explanation-click" cols="10" @click="scrollChangeTasks(task)">
              <div class="explanation-hidden">
                <div class="explanation-indent">
                  {{ task.indexNo }}.{{ task.categorySecondaryName }}_{{ task.taskName }}
                </div>
                <div class="explanation-content">
                  予定工数/回：{{ task.afterCost }}&emsp;回数：{{ task.afterCount }}
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    changeFreeTasks: {
      type: Array,
      required: true
    },
    unplannedFreeTask: {
      type: Array,
      required: true
    },
  },
  methods: {
    isSelf() {
      return this.$root.$refs.monthlyActualsComponent.isSelf();
    },
    /** クリックしたindex要素を削除 */
    returnChange(task){
      this.$emit('data-updated', task, false);
    },

    /** クリックしたindex箇所へスクロール */
    scrollChangeTasks(key){
      this.$emit('data-updated', key, true);
    },

    /** スクロール適用要素のheightを計算 */
    scroalHeight(){

      // headerの高さ
      let height1 = this.headerHeight;
      let height2 = document.getElementById("taskChangeHeader").offsetHeight;

      // heightの計算を実施
      let height = window.innerHeight - (height1 + height2);
      return height;
    },

    /** 画面を閉じる */
    close() {
      this.showElementCost = false;
      this.showElementTask = false;
      this.$emit("close");
    },

    setWidth() {
      return this.isSelf() ? "auto" : "368.62px";
    }
  },

  async mounted() {
    this.offset = this.scroalHeight();
  },

  data() {
    return {
      showElementCost: false,
      showElementTask: false,
      offset: 0, 
      headerHeight: 64,
    };
  },
};
</script>
<style lang="scss" scoped>
.explanation-task {
  font-size: 14px;
  border-block: solid 1px #7F7F7F;
}

.explanation-task:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.explanation-border-block {
  font-size: 14px;
  padding: 3%;
  border-block: solid 1px #7F7F7F;
  color: #7F7F7F;
}

.explanation-border-top {
  padding: 3%;
  border-top: solid 1px #7F7F7F;
  color: #7F7F7F;
}

.title {
  padding-left: 5%;
  color: #7F7F7F;
  font-size: 18px;
}

.explanation-hidden {
  width: 100%;
  overflow: hidden;
}

.explanation-indent {
  text-indent: -14px;
  margin-left: 14px;
}

.explanation-content {
  margin-left: 14px;
}

.explanation-delete {
  margin: auto;
}

.explanation-click:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.explanation-toggle {
  margin-block: 3%;
}
.explanation-toggle:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.main-scroll {
  height: calc( (var(--vh, 1vh) * 100) - 175px );
  overflow-y: auto;
}

.icon-position {
  position: relative;
  bottom: 2.6px;
}
</style>
