<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="aadbTask"
    v-dialog-drag="{
      dialog: 'aadbTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card class="hidden" :id="parentId">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="copyLink()"
              v-bind="attrs"
              v-on="on"
              class="ml-auto"
              icon
              color="gray"
            >
              <v-icon small>$link</v-icon>
            </v-btn>
          </template>
          <span>タスクのリンクをコピー</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && archiveTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$archive</v-icon>
            </v-btn>
          </template>
          <span>タスクをアーカイブ</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && duplicateTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$copy</v-icon>
            </v-btn>
          </template>
          <span>タスクを複製</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && deleteTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
          <span>タスクを削除</span>
        </v-tooltip>

        <v-btn @click="close()" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row
          class="py-4"
          :class="[{ 'mt-4': isSupervisor() || !isSpotTask() }]"
        >
          <span class="ml-9 mb-n4">
            <span v-if="task.category_secondary_name">
              {{ task.category_secondary_name }}
            </span>
            <span v-else-if="!task.category_primary_id">
              {{ "未分類" }}
            </span>
          </span>
          <v-col cols="12" class="py-0 d-flex align-center">
            <div v-if="isMonthlyTask()">
              <v-icon small>$favoriteinactive</v-icon>
            </div>
            <div v-else @click="setFavorite(task)">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <span class="py-2 ml-2 work-break">{{ task.name }}</span>
            <div
              v-if="!isSupervisor() && isSpotTask()"
              class="detailActualText"
            >
              <v-text-field
                maxlength="100"
                v-model="task.detail"
                placeholder="タスク詳細を追加"
              ></v-text-field>
            </div>
            <span v-else :class="[{ detailActualText: !isDetailEmpty() }]">{{
              task.detail
            }}</span>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="my-2">
          <v-col class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
        </v-row>

        <v-row v-if="!isSupervisor() && isSpotTask() && !isMonthlyTask() && isChangeCategory()" class="py-0 my-0">
          <v-col class="py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskcategory</v-icon>
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="primaryName || '大カテゴリ名'"
              :items="parentCategories"
              :type="'text'"
              :has-total="false"
              :showMenu="!isSupervisor()"
              @dropdownSelected="primaryCategoryChange($event)"
            ></dropdown-button>
            <div class="mt-2 ml-4" ref="popupContainer">
              <v-btn 
                text color="ligth"
                class="pt-0"
                v-bind="task.category_secondary_id"
                @click="changeShowPopup()"
              >
                <span>{{ task.category_secondary_name || '小カテゴリ名'}}</span>
                <v-icon large right>mdi-menu-down</v-icon>
              </v-btn>
              <category-pulldown v-if="isPulldownopen" class="category-pulldown"> 
                <div class="add-small-category">
                  <v-text-field
                    v-model="filteredKey"
                    placeholder="小カテゴリ名検索"
                    prepend-inner-icon="mdi-magnify"
                    variant="solo"
                    clearable
                    maxlength="100"
                  />
                </div>
                <div
                  class="add-small-category"
                  @click="newCategory(task.category_primary_id)"
                >
                  +新しい小カテゴリを作成する
                </div>
                <div
                  v-for="(item, index) in secondariesFilter(filteredKey)" :key="index"
                  class="category-add"
                  @click="secondaryCategoryChange(item)"
                  @mouseover="showPulldownIcon(item)"
                  @mouseleave="hidePulldownIcon()"
                >
                  <span>{{item.name}}</span>
                  <span
                    v-if="collectPulldownIcon(item , isPulldownIcon) && item.user_id"
                    class="pulldown-right-element"
                  >
                  <v-icon
                    small class="blue--text"
                    @click="editCategory($event, item)"
                  > 
                    $edit 
                  </v-icon>
                  <v-icon
                    small class="blue--text"
                    @click="deleteCategory($event, item)"
                  > 
                    $delete 
                  </v-icon>
                  </span>
                </div>
              </category-pulldown>
              <div
                v-if="
                  !this.$v.task.category_secondary_id.required &&
                  this.$v.task.category_secondary_id.$dirty
                "
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-else class="my-0">
          <v-col cols="12">
            <v-icon small>$taskcategory</v-icon>
            <span class="ml-2">{{ task.category_primary_name }}</span>
          </v-col>
          <v-col cols="12" class="pt-0">
            <span class="ml-7">{{ task.category_secondary_name }}</span>
          </v-col>
        </v-row>

        <v-row class="my-0" v-if="task.task_type_id">
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <v-row
          v-if="
            !isSupervisor() &&
            ((task.is_split_task === 0 && task.is_split_task_completed === 1) ||
              (task.is_split_task === 1 &&
                task.is_split_task_completed === 1 &&
                originalTask.total_actual_cost - originalTask.cost === 0))
          "
          class="my-0 py-0 align-baseline"
        >
          <v-col cols="3" class="py-0 align-self-start mt-5">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2">日次実績</span></v-col
          >
          <v-col cols="9" class="py-0 d-flex align-start align-self-start">
            <v-text-field
              type="number"
              max="999"
              min="1"
              class="w-90"
              :error-messages="costErrors"
              suffix="m/回"
              :readonly="isSupervisor()"
              @keydown="checkMaxLength('cost', $event)"
              @input.native="updateCost"
              @input="changeCost()"
              v-model="task.cost"
            >
            </v-text-field>
            <span class="mx-2 mt-5">×</span>
            <v-text-field
              :error-messages="countErrors"
              type="number"
              suffix="回"
              max="999"
              min="1"
              :readonly="isSupervisor()"
              @input.native="updateCount"
              @keydown="checkMaxLength('count', $event)"
              @input="changeCount()"
              v-model="task.count"
              class="w-60 align-self-start"
            >
            </v-text-field>
            <span class="mx-2 mt-5">=</span>
            <v-text-field
              readonly
              type="number"
              class="w-90 align-self-start"
              :value="task.cost * task.count"
              suffix="m"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 my-0 align-center">
            <div class="ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || "" }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (!isSupervisor() &&
              task.is_split_task === 1 &&
              task.is_split_task_completed === 0) ||
            (task.is_split_task === 1 &&
              task.is_split_task_completed === 1 &&
              originalTask.total_actual_cost - originalTask.cost !== 0) ||
            (task.is_split_task === 0 && task.is_split_task_completed === 0)
          "
          class="my-0"
        >
          <v-col class="py-0">
            <div class="d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次実績</span>
              <v-text-field
                class="w-130 ml-10"
                type="number"
                :error-messages="costErrors"
                @keydown="checkMaxLength('cost', $event)"
                @input.native="updateCost"
                @input="changeCost()"
                v-model="task.cost"
                max="999"
                min="1"
                suffix="m"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost || 0 }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost || originalTask.cost }} m</span>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || "" }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSupervisor() &&
            !(task.is_split_task === 1 && task.is_split_task_completed === 0)
          "
          class="my-0"
        >
          <v-col class="align-center">
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2 w-120">日次実績</span>
              <div class="ml-2 d-flex align-center">
                <span>{{ task.cost }}m/回</span>
                <span class="mx-2">×</span>
                <span>{{ task.count }}回</span>
                <span class="mx-2">=</span>
                <span>{{ task.cost * task.count }}m</span>
              </div>
            </div>
            <div class="mt-1 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSupervisor() &&
            task.is_split_task === 1 &&
            task.is_split_task_completed === 0
          "
          class="my-0"
        >
          <v-col class="align-center"
            ><div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次実績</span>
              <div class="text-number ml-7">
                <span>{{ task.cost * task.count }} m</span>
              </div>
            </div>
            <div class="d-flex mt-2">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost }} m</span>
              <span class="ml-1">/</span>
              <span class="ml-1">{{ task.total_costt }} m</span>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="!isSupervisor()" class="py-0 my-0">
          <v-col class="d-flex align-center py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <v-text-field
                v-model="task.start_time"
                :error-messages="startTimeErrors"
                :readonly="isSupervisor()"
                @input="!isSupervisor() && changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
                maxlength="5"
                type="text"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                v-model="task.end_time"
                :error-messages="endTimeErrors"
                readonly
                maxlength="5"
                type="text"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="py-2 my-0">
          <v-col class="d-flex align-center">
            <v-icon small>$taskstartendtime</v-icon>
            <div class="text-number ml-2 d-flex align-center">
              <span>{{ task.start_time }}</span>
              <span class="mx-2">~</span>
              <span>{{ task.end_time }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="d-flex align-center py-0">
            <v-radio-group
              :disabled="+task.count !== 1 || !canSplitCheck()"
              v-model="task.is_split_task_completed"
            >
              <v-radio
                v-bind:value="0"
                :label="'タスクを一部着手して後日継続予定'"
              >
              </v-radio>
              <span class="ml-8">
                ※チェックを付けて保存した場合、「未完了タスク」に登録されます
              </span>
              <br />
              <v-radio v-bind:value="1" :label="'タスクが全て完了'"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- <v-row class="py-0 my-0">
          <v-col class="py-0">
            <div class="d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="mx-2">アウトプット</span>
              <v-checkbox
                readonly
                v-model="task.is_need_output"
                :label="!!task.is_need_output ? '必要' : '不要'"
              ></v-checkbox>
            </div>
            <div class="ml-2 py-0" v-if="!!task.is_need_output">
              アウトプット内容： {{ task.output_detail }}
            </div>
          </v-col>
        </v-row> -->

        <!--<v-row class="py-2 my-0">
          <v-col class="d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="!hasTag">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet>
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>-->

        <!-- <v-row class="py-0 my-0">
          <v-col cols="12" class="py-0">
            <v-icon small>$outputsignoncalendar</v-icon>
            <v-btn
              v-if="!isSupervisor()"
              @click="!isSupervisor() && attachFile()"
              :loading="isSelecting"
              text
              color="ligth"
            >
              添付ファイルを追加
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              multiple
              @change="fileChanged"
            />
            <span :class="{ 'ml-2': isSupervisor() }"
              >添付ファイル
              <span class="ml-5">{{ task.files.length }}件</span></span
            >
          </v-col>
          <v-col v-if="hasFile" cols="12" class="py-0">
            <v-virtual-scroll :items="task.files" :item-height="40" height="80">
              <template v-slot:default="{ item }">
                <v-list-item class="ml-4">
                  <v-list-item-icon>
                    <v-icon small>$attachedfile</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content @click="viewAttachFile(item)">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="!isSupervisor()"
                    @click="!isSupervisor() && deleteAttachFile(item)"
                  >
                    <v-icon small>$delete</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row> -->

        <v-row class="py-0 my-0">
          <v-col class="py-0" v-if="!showComment">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              v-if="showComment"
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!isEditing"
          tile
          depressed
          class="ml-auto"
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <PreviewFile :show="showPreview" :src="src" :text="text"> </PreviewFile>
    <AddSmallCategory
      :show="openNewCategory"
      :newLargeCategoryId="newLargeCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @secondaryCategoryCreated="categorySort()"
    ></AddSmallCategory>
    <UpdateSmallCategory
      :show="openEditCategory"
      :largeCategoryId="updateCategoryPrimaryId"
      :smallCategoryName="updateSecondaryCategoryName"
      :smallCategoryId="updateSecondaryCategoryId"
      :userId="userId"
      :groupId="groupId.toString()"
      @closeEvent="categorySort()"
    ></UpdateSmallCategory>
  </v-dialog>
</template>

<script>
import CategoryPulldown from '../../schedule/modal/CategoryPulldown.vue';
import AddSmallCategory from '../../schedule/modal/AddSmallCategory.vue';
import UpdateSmallCategory from "../../schedule/modal/UpdateSmallCategory.vue";
import DropdownButton from "../../../components/DropdownButton.vue";
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import axios from "axios";
import {
  DELETE_COMMENT_API,
  GET_OR_DEL_ACTUAL_TASK_API,
  ACTUAL_TASK_API,
  UPLOAD_ATTACH_FILE_API,
  ATTACH_FILE_API,
  TODO_TASK_API,
  SPLIT_TASK_API,
  UPDATE_DB_TASKS_API,
  NOTIFICATION_API,
  CATEGORIES_API,
  PRIMARY_SUB_CATEGORY_API,
} from "../../../constants/api";
import {
  isEmpty,
  copyToClipboard,
  saveFile,
  deepClone,
  isSafari,
  debounce_leading,
  isEmptyNumber,
  orderBy,
} from "../../../helpers/helpers";
import { required, requiredIf} from "vuelidate/lib/validators";
import {
  formatTime,
  formatDateTime,
  parseDateTime,
  toMinute,
  toHHmm,
  toMinuteForHHmm,
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC1004,
  FC1005,
  FC804,
  FC806,
  FC808,
  FC820,
  FC805,
  FC1003,
  FC824,
  FC709,
  FC501,
  FC502,
  FC701,
  FC704,
} from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";
import PreviewFile from "./PreviewFile.vue";
import { taskMixin } from '@/mixins/task.js';
export default {
  components: { 
    PreviewFile,
    DropdownButton,
    CategoryPulldown,
    AddSmallCategory,
    UpdateSmallCategory,
  },
  props: {
    show: Boolean,
    actualTaskId: Number,
    actualId: String,
    planId: String,
    scheduleComments: {
      type: Array,
      default: () => [],
    },
    scheduleIdList: {
      type: Array,
      default: () => [],
    },
  },
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  mixins: [validationMixin, backMixin, taskMixin],
  validations: {
    task: {
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id !== null;
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      filteredKey: "",
      parentId: "actionDatabaseTaskId",
      openDbTask: false,
      showComment: false,
      userComment: "",
      isSelecting: false,
      isEditing: false,
      comments: this.scheduleComments,
      task: {
        comments: [],
        //tags: [],
        files: [],
      },
      src: "",
      text: "",
      showPreview: false,
      canSplit: false,
      listNotification: [],
      parentCategories: [],
      childCategories:[],
      isPulldownopen: false,
      isPulldownIcon: false,
      openNewCategory: false,
      openEditCategory: false,
      updateCategoryPrimaryId: 0,
      updateSecondaryCategoryName: "",
      updateSecondaryCategoryId: 0,
      newLargeCategoryId: 0,
      userId: "",
      groupId: "",
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
    // ポップアップ外をクリックした際にポップアップを閉じるイベント
    window.addEventListener("click", this.leaveSelect);
  },
  watch: {
    async show() {
      this.openDbTask = true;
      this.showComment = false;
      this.isEditing = false;
      this.userComment = "";
      this.$v.$reset();
      this.originalTask = {
        comments: [],
        //tags: [],
        files: [],
      };
      this.task = {
        comments: [],
        //tags: [],
        files: [],
      };

      await this.getTask();
      await this.getNotification();
    },
    task: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler(val) {
        this.isEditing = !isEmpty(val);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    scheduleComments: {
      handler(val) {
        this.comments = val;
      },
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },
    hasFile() {
      return this.task && this.task.files && this.task.files.length > 0;
    },
    /*hasTag() {
      return this.task && this.task.tags && this.task.tags.length > 0;
    },*/
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
  },
  methods: {
    secondariesFilter(filteredKey){
      let items = this.childCategories.filter(item => {
        if(filteredKey){
          if(item.name.includes(filteredKey)){
            return item
          }
        } else {
          return item
        }
      })
      return items;
    },
    clearFilteredKey(){
      this.filteredKey = "";
    },
    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    changeCount() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    changeCost() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
        this.task.total_actual_cost =
          this.originalTask.total_actual_cost - this.originalTask.cost === 0
            ? this.task.cost
            : isEmptyNumber(this.originalTask.total_actual_cost)
            ? 0
            : +this.task.cost +
              (this.originalTask.total_actual_cost - this.originalTask.cost);
      }
    },
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }

      this.task.total_actual_cost =
        this.originalTask.total_actual_cost - this.originalTask.cost === 0
          ? this.task.cost
          : isEmptyNumber(this.originalTask.total_actual_cost)
          ? 0
          : +this.task.cost +
            (this.originalTask.total_actual_cost - this.originalTask.cost);
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    /**
     * Check if monthlyTask
     * @return {boolean}
     */
    isMonthlyTask() {
      return this.task.schedule_month_task_id ? true : false;
    },

    /**
     * Check if changeCategory
     * @return {boolean}
     */
    isChangeCategory() {
      if (this.task.is_changeability != null){
        return this.task.is_changeability ? true : false;
      }
      return true;
    },

    isSupervisor() {
      return this.$root.$refs.actualComponent.isSupervisor();
    },

    /**
    * Handle change primary category event
    * @param {object} primaryCategory
    * @return {void}
    */
    primaryCategoryChange(primaryCategory) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === primaryCategory.id
      )?.secondaries;

      if (!isEmptyNumber(primaryCategory.id)) {
        this.parentCategories = this.parentCategories.filter(
          (x) => !isEmptyNumber(x.id)
        );
      }
      this.task.category_primary_id = primaryCategory.id;
      this.task.category_secondary_id = null;
      this.task.category_secondary_name  = null;
    },

    /**
    * Handle change secondary category event
    * @param {object} secondCategory
    * @return {void}
    */
    secondaryCategoryChange(secondCategory) {
      this.task.category_secondary_id = secondCategory.id;
      this.task.category_secondary_name = secondCategory.name;
      this.isPulldownopen = false;
      this.clearFilteredKey();
    },

    async viewAttachFile(item) {
      if (!item.isNew) {
        try {
          const { data, headers, status } = await axios.get(
            ATTACH_FILE_API(item.id),
            {
              responseType: "blob",
            }
          );

          if (status === STATUS_NO_CONTENT) return;
          const blob = new Blob([data], {
            type: contentType,
          });
          var contentType = headers["content-type"];
          if (
            contentType.startsWith("image/") ||
            contentType.startsWith("text/")
          ) {
            let that = this;
            if (isSafari() && typeof FileReader !== "undefined") {
              if (contentType.startsWith("image/")) {
                let reader = new FileReader();
                reader.onloadend = function () {
                  that.src = reader.result;
                  that.text = "";
                  that.showPreview = !that.showPreview;
                };
                reader.readAsDataURL(blob);
              } else {
                let reader = new FileReader();
                reader.onloadend = function () {
                  that.text = reader.result;
                  that.src = "";
                  that.showPreview = !that.showPreview;
                };
                reader.readAsText(blob);
              }
            } else {
              saveFile(data, headers, item.name);
            }
          } else {
            saveFile(data, headers, item.name);
          }
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC1003, status);
        }
      } else return;
    },

    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    updateComment() {
      this.showComment = true;
    },

    deleteAttachFile(item) {
      this.$confirm(this.$t("label.confirm.delete_attach"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          if (!item.isNew) {
            try {
              await axios.delete(ATTACH_FILE_API(item.id));
              this.task.files = this.task.files.filter(
                (x) => x.name !== item.name
              );
              this.originalTask.files = this.originalTask.files.filter(
                (x) => x.name !== item.name
              );
              this.$emit("updateTaskSuccess", this.task);
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC1005, status);
            }
          } else {
            this.task.files = this.task.files.filter(
              (x) => x.name !== item.name || !x.isNew
            );
          }
        })
        .catch(() => {});
    },
    isDetailEmpty() {
      return isEmpty(this.task.detail);
    },
    attachFile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    async uploadFile() {
      let formData = new FormData();
      for (var i = 0; i < this.task.files.length; i++) {
        if (!this.task.files[i].isNew) continue;
        formData = new FormData();
        formData.append("file", this.task.files[i].fileContent);
        try {
          const { data } = await axios.post(
            UPLOAD_ATTACH_FILE_API(),
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.task.files[i].id = data.result.file_id;
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC1004, status);
        }
      }
    },

    async fileChanged(e) {
      let countFileError = 0;
      let selectedFiles = e.target.files;
      if (this.task.files.length + selectedFiles.length > 10) {
        this.$alert(this.$t("label.error.max_attach"), {
          width: "500px",
        });
      } else {
        for (var i = 0; i < selectedFiles.length; i++) {
          let fileSize = selectedFiles[i].size / 1024 / 1024;
          if (fileSize > 10) {
            countFileError = countFileError + 1;
          } else {
            this.task.files.push({
              fileContent: selectedFiles[i],
              name: selectedFiles[i].name,
              id: i,
              isNew: true,
            });
          }
        }
        if (countFileError > 0) {
          this.$toast(
            this.$t("label.error.max_byte", { 0: `${countFileError}` }),
            3000
          );
        }
      }
      this.$refs.uploader.value = null;
    },

    deleteComment(item) {
      if (this.loginInfo.user_id !== item.user_id) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            this.$emit("deleteComment", {
              comment_id: item.id,
              schedule_task_id: this.task.schedule_task_id,
            });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    async getTask() {
      try {
        const { data, status } = await axios.get(
          GET_OR_DEL_ACTUAL_TASK_API(this.actualId, this.actualTaskId)
        );
        if (status === STATUS_NO_CONTENT) {
          this.openDbTask = false;
          this.$alert("タスクが見つかりません。");
        } else {
          let rdata = data.result[0];
          this.task = deepClone(rdata);
          this.originalTask = deepClone(rdata);
          this.originalTask.start_time = formatTime(rdata.start_time);
          this.task.start_time = formatTime(rdata.start_time);
          this.originalTask.end_time = formatTime(rdata.end_time);
          this.task.end_time = formatTime(rdata.end_time);
          if (this.task.is_split_task === 1) {
            //
          } else {
            this.task.is_split_task_completed = 1;
            this.originalTask.is_split_task_completed = 1;
          }

          await this.getCategories();

          if (+this.task.can_split === 1){
            this.canSplit = true;
          }
          else{
            this.canSplit = false;
          }

          let scheduleComments = this.comments.map((x) => {
            return {
              comment: x.comment,
              user_id: x.user_id,
              user_name: x.user_name,
              updated_at: x.updated_at,
              id: x.id,
            };
          });
          //Add schedule comment for task
          if (this.comments && this.comments.length > 0) {
            let comments = [];
            [...this.task.comments, ...scheduleComments].forEach((v) => {
              if (comments.some((x) => x.id === v.id)) {
                //
              } else {
                comments.push(v);
              }
            });
            this.task.comments = orderBy(comments, ["updated_at"], ["desc"]);
          }
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC804, status);
      }
    },

    /**
    * get categories data from API FC701
    * @return {void}
    */
    async getCategories() {
      try {
        this.groupId = this.loginInfo.group_id;
        this.userId = this.loginInfo.user_id;
        const { data, status } = await axios.get(CATEGORIES_API(
          this.groupId,
          this.userId
        ));

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.childCategories = this.parentCategories.find(
            (x) => x.id === this.task.category_primary_id
          )?.secondaries;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          if (this.isSupervisor()) {
            if (!isEmpty(this.userComment)) {
              await axios.put(ACTUAL_TASK_API(this.actualId), {
                actual_tasks: [
                  {
                    id: this.task.id,
                    comments: [
                      {
                        user_id: this.loginInfo.user_id,
                        comment: this.userComment,
                      },
                    ],
                  },
                ],
              });
              this.$store.dispatch("save/setChangeFlag", false);
              this.openDbTask = false;
            }
          } else {
            //await this.uploadFile();

            let params = [
              {
                ...this.task,
                start_time: (
                  this.task.start_time.replace(":", "") + "00"
                ).padStart(6, "0"),
                end_time: (this.task.end_time.replace(":", "") + "00").padStart(
                  6,
                  "0"
                ),
                // files: this.task.files.map((x) => {
                //   return { id: x.id };
                // }),
                count: +this.task.count,
                cost: +this.task.cost,
                category_secondary_id: this.task.category_secondary_id,
                schedule_month_task_id: this.task.schedule_month_task_id,
                is_completed: 1,
                comments:
                  !isEmpty(this.userComment) && this.showComment
                    ? [
                        {
                          user_id: this.loginInfo.user_id,
                          comment: this.userComment,
                        },
                      ]
                    : [],
                split_task_id:
                  +this.task.count === 1 &&
                  +this.task.is_split_task_completed === 0
                    ? null
                    : this.task.split_task_id,
                is_split_task_completed: this.task.is_split_task_completed,
                is_split_task:
                  +this.task.count === 1 &&
                  (+this.task.is_split_task_completed === 0 ||
                    (+this.task.is_split_task_completed === 1 &&
                      this.originalTask.total_actual_cost -
                        this.originalTask.cost >
                        0))
                    ? 1
                    : 0,
              },
            ];
            const { data } = await axios.put(ACTUAL_TASK_API(this.actualId), {
              actual_tasks: params,
            });
            this.$store.dispatch("save/setChangeFlag", false);
            this.openDbTask = false;
            this.task.is_split_task =
              +this.task.count === 1 &&
              (+this.task.is_split_task_completed === 0 ||
                (+this.task.is_split_task_completed === 1 &&
                  this.originalTask.total_actual_cost - this.originalTask.cost >
                    0))
                ? 1
                : 0;
            this.task.split_task_id =
              +this.task.count === 1 &&
              (+this.task.is_split_task_completed === 0 ||
                (+this.task.is_split_task_completed === 1 &&
                  this.originalTask.total_actual_cost - this.originalTask.cost >
                    0))
                ? this.task.split_task_id || this.task.id
                : null;
            data.result[0].start_time = formatTime(data.result[0].start_time);
            data.result[0].end_time = formatTime(data.result[0].end_time);
            this.$emit("updateTaskSuccess", data.result[0]);
          }
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC805, status);
        }
      }
    }),

    async deleteTask() {
      this.$confirm(
        this.task.is_split_task === 1
          ? `${this.$t(
              "label.confirm.delete_task"
            )} \r\nこのタスクは中断となり、生産性が評価できません。`
          : this.$t("label.confirm.delete_task"),
        {
          width: "600px",
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        }
      )
        .then(async () => {
          try {
            await axios.delete(
              GET_OR_DEL_ACTUAL_TASK_API(this.actualId, this.actualTaskId)
            );
            this.$emit("deleteTask", this.task);
            this.openDbTask = false;
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC806, status);
          }
        })
        .catch(() => {});
    },

    archiveTask: debounce_leading(async function () {
      if (this.task.is_split_task === 0) {
        try {
          let params = {
            name: this.task.name,
            description: this.task.description,
            detail: this.task.detail,
            cost: this.task.cost,
            count: this.task.count,
            category_secondary_id: this.task.category_secondary_id,
            schedule_month_task_id: this.task.schedule_month_task_id,
            is_need_output: this.task.is_need_output,
            output_detail:
              +this.task.is_need_output === 0 ? null : this.task.output_detail,
            is_favorite: this.task.is_favorite,
            //tags: this.task.tags,
            comments: this.task.comments,
            actual_task_id: this.task.id,
            standard_task_id: this.task.standard_task_id,
            standard_task_revision: this.task.standard_task_revision,
          };
          const { data } = await axios.post(TODO_TASK_API(), {tasks: [params]});
          this.openDbTask = false;
          this.task.todoTaskId = data.result.id[0];
          this.$emit("archiveTask", this.task);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC808, status);
        }
      } else {
        try {
          let params = {
            actual_task_id: this.originalTask.id,
          };
          const { data } = await axios.post(SPLIT_TASK_API(), params);
          this.openDbTask = false;
          this.originalTask.todoTaskId = data.result.id;
          this.$emit("archiveDbSplitTask", this.originalTask);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC824, status);
        }
      }
    }),

    async duplicateTask() {
      if (this.isEditing) {
        await this.updateTask();
      }
      this.openDbTask = false;
      this.$emit("duplicateTask", this.task);
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let comment = this.task.comments.find(
            x => +x.id === +item.comment_id && !!item.can_verified
          );
          if (comment){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: null,
            comment_notice_id: item.comment_notice_id,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },
    
    compareValue() {
      return (
        JSON.stringify({
          name: this.task.name,
          description: this.task.description,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          category_primary_id: this.task.category_primary_id,
          category_secondary_id: this.task.category_secondary_id,
          start_time: this.task.start_time,
          end_time: this.task.end_time,
          userComment: "",
          files: this.task.files,
          cost: this.task.cost,
          count: this.task.count,
          detail: this.task.detail,
          is_split_task_completed: this.task.is_split_task_completed,
        }) !==
        JSON.stringify({
          name: this.originalTask.name,
          description: this.originalTask.description,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          category_primary_id: this.originalTask.category_primary_id,
          category_secondary_id: this.originalTask.category_secondary_id,
          start_time: this.originalTask.start_time,
          end_time: this.originalTask.end_time,
          userComment: "",
          files: this.originalTask.files,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          detail: this.originalTask.detail,
          is_split_task_completed: this.originalTask.is_split_task_completed,
        })
      );
    },

    async setFavorite(item) {
      try {
        item.is_favorite = !item.is_favorite;
        await axios.put(
          UPDATE_DB_TASKS_API(item.user_id, item.standard_task_id),
          {
            is_favorite: +item.is_favorite,
          }
        );

        this.$emit("setFavorite", item);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },

    copyLink() {
      copyToClipboard(
        window.location.origin +
          `/actual/${this.actualId}?taskId=${this.task.id}&sid=${this.planId}`,
        this.parentId
      );
      this.$toast(this.$t("label.toast.copied"), 3000);
    },
    isSpotTask() {
      if (isEmptyNumber(this.task.schedule_task_id)) {
        return true;
      } else {
        return !this.scheduleIdList.some(
          (x) => x == this.task.schedule_task_id
        );
      }
    },

    canSplitCheck(){
      // 最後のタスクではない
      if (!this.task.can_split && +this.task.is_split_task === 1){
        return false;
      }
      return true;
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openDbTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openDbTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    /**
     * Handle control icon View
     * @return {boolean}
     */
    showPulldownIcon(pulldownOption) {
      this.isPulldownIcon = pulldownOption;
    },

    hidePulldownIcon(){
      this.isPulldownIcon = "";
    },

    collectPulldownIcon(str1 , str2) {
      if(str1 === str2) return true;
      else return false;
    },

    /**
     * Handle show category pulldown
     * @return {boolean}
     */
    changeShowPopup(){
      this.isPulldownopen = !this.isPulldownopen;
      this.clearFilteredKey();
    },

    leaveSelect(event) {
      const popupContainer = this.$refs.popupContainer;
      
      // popupContainer が存在し、クリックされた要素が popupContainer の外側の場合
      if (popupContainer && !popupContainer.contains(event.target)) {
        this.isPulldownopen = false; // ポップアップを閉じる
        this.clearFilteredKey(); // フィルターをクリア

      }
    },

    /**
     * Handle update edit category name
     * @param {object} event
     * @return {boolean}
     */
    editCategory(event, item) {
      event.stopPropagation();
      this.updateCategoryPrimaryId = this.task.category_primary_id;
      this.updateSecondaryCategoryName = item.name;
      this.updateSecondaryCategoryId = item.id;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openEditCategory = !this.openEditCategory;
    },

    newCategory(categoryPrimaryId) {
      this.newLargeCategoryId = categoryPrimaryId;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openNewCategory = !this.openNewCategory;
    },

    /**
     * Handle delete category event
     * @param {object} event
     * @return {boolean}
     */
    deleteCategory(event, item) {
      event.stopPropagation();

      /** 登録されている小カテゴリの場合 */
      let origincategory = this.originalTask.category_secondary_id
      let selectcategory = this.task.category_secondary_id
      if (origincategory === item.id || selectcategory === item.id) {
        this.notDeleteCategory();
      } else {
        this.$confirm(this.$t("label.confirm.delete_secondary_category"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        })
          .then(async () => {
            try {
              await axios.delete(
                PRIMARY_SUB_CATEGORY_API(
                  this.task.category_primary_id,
                  item.id,
                  item.user_id
                )
              );

              this.$toast(
                `${item.name}を削除しました。`,
                3000
              );
              this.categorySort()
            } catch (error) {
              const { status } = error.response;
              if (status === 404) {
                this.$alert(error.response.data.message, {
                  width: "820px",
                });
              } else {
                apiErrorMessages(this, FC704, status);
              }
            }
          })
          .catch(() => {});
      }
    },

    notDeleteCategory() {
      this.$alert([this.$t("label.confirm.not_delete_category.line1")])
        .then(() => {})
        .catch(() => {});
    },

    categorySort() {
      this.getCategories();
      this.changeShowPopup();
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
    window.removeEventListener("click", this.leaveSelect);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}

.w-120 {
  width: 80px;
}

.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-90 {
  width: 90px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.w-130 {
  width: 130px;
}
.detailActualText {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  white-space: pre-wrap;
}
.category-pulldown {
  z-index:999;
}

.category-add{
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 15%;
  font-size : 15px;
}
.category-add:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.add-small-category{
  height: 48px;
  line-height: 48px;
  position: relative;
  color: gray;
  font-size : 15px;
}
.add-small-category:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.pulldown-right-element {
  position: absolute;
  right: 5%;
}
</style>
