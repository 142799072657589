/**
 * 承認フェーズ
 */

//未提出
export const PHASE_ID_UNSUBMITTED = 10;
//提出取消
export const PHASE_ID_SUBMISSION_RESET = 15;
//1次確認待ち
export const PHASE_ID_PRIMARY_CONFIRMATION_PENDING = 20;
//1次確認取消
export const PHASE_ID_PRIMARY_CONFIRMATION_RESET = 23;
//1次差し戻し
export const PHASE_ID_PRIMARY_REJECT = 25;
//2次確認待ち
export const PHASE_ID_2_NEXT_CONFIRMATION_PENDING = 30;
//2次差し戻し
export const PHASE_ID_2_NEXT_REJECT = 35;
//確認済み
export const PHASE_ID_VERIFIED = 40;

//10
export const PHASE_ID_UNSUBMITTED_TEXT = '未提出';
//15
export const PHASE_ID_SUBMISSION_RESET_TEXT = '提出取消';
//20
export const PHASE_ID_PRIMARY_CONFIRMATION_PENDING_TEXT = '1次確認待ち';
//23
export const PHASE_ID_PRIMARY_CONFIRMATION_RESET_TEXT = '1次確認取消';
//25
export const PHASE_ID_PRIMARY_REJECT_TEXT = '1次差し戻し';
//30
export const PHASE_ID_2_NEXT_CONFIRMATION_PENDING_TEXT = '2次確認待ち';
//35
export const PHASE_ID_2_NEXT_REJECT_TEXT = '2次差し戻し';
//40
export const PHASE_ID_VERIFIED_TEXT = '確認済み';

/**
 * フェーズ種別(クライアント側で持つ定数値)
 */

// 未提出
export const PHASE_TYPE_UNSUBMITTED = 10;
// 提出
export const PHASE_TYPE_SUBMISSION = 20;
// 提出取消
export const PHASE_TYPE_SUBMISSION_RESET = 25;
// 承認
export const PHASE_TYPE_APPROVAL = 30;
// 承認取消
export const PHASE_TYPE_APPROVAL_RESET = 35;
// 差戻
export const PHASE_TYPE_REJECT = 40;
// 確認済み後更新
export const PHASE_TYPE_UPDATE_AFTER_APPROVAL = 45;

// 10
export const PHASE_TYPE_UNSUBMITTED_TEXT = '未提出';
// 20
export const PHASE_TYPE_SUBMISSION_TEXT = '提出';
// 25
export const PHASE_TYPE_SUBMISSION_RESET_TEXT = '提出取消';
// 30
export const PHASE_TYPE_APPROVAL_TEXT = '承認';
// 35
export const PHASE_TYPE_APPROVAL_RESET_TEXT = '承認取消';
// 40
export const PHASE_TYPE_REJECT_TEXT = '差戻';

/**
 * 承認権限
 */

// 権限無し
export const APPROVAL_AUTH_ID_NO_PERMISSION = 10;
// 一次承認
export const APPROVAL_AUTH_ID_PRIMARY_APPROVAL = 20;
// 二次承認
export const APPROVAL_AUTH_ID_SECONDARY_APPROVAL = 30;


export const ALL_TYPE = 'ALL';
export const ACTUAL_TYPE = 'ACTUAL';
export const PLAN_TYPE = 'PLAN';

/**DATE SETTING */
// export const MONTHLY = '毎月';
// export const ANY_MONTH = '随時';

// export const MONTHS = [
//   "1月"
//   , "2月"
//   , "3月"
//   , "4月"
//   , "5月"
//   , "6月"
//   , "7月"
//   , "8月"
//   , "9月"
//   , "10月"
//   , "11月"
//   , "12月"
// ];

// export const WEEKLY = '毎週';
// export const ANY_WEEK = '隔週';

// export const WEEKS = [
//   "第1週"
//   , "第2週"
//   , "第3週"
//   , "第4週"
// ];

// export const DAYINMONTHS = [
//   "1日"
//   , "2日"
//   , "3日"
//   , "4日"
//   , "5日"
//   , "6日"
//   , "7日"
//   , "8日"
//   , "9日"
//   , "10日"
//   , "11日"
//   , "12日"
//   , "13日"
//   , "14日"
//   , "15日"
//   , "16日"
//   , "17日"
//   , "18日"
//   , "19日"
//   , "20日"
//   , "21日"
//   , "22日"
//   , "23日"
//   , "24日"
//   , "25日"
//   , "26日"
//   , "27日"
//   , "28日"
//   , "29日"
//   , "30日"
//   , "31日"
// ];

// export const WORKINGDAYINMONTHS = [
//   "1営業日"
//   , "2営業日"
//   , "3営業日"
//   , "4営業日"
//   , "5営業日"
//   , "6営業日"
//   , "7営業日"
//   , "8営業日"
//   , "9営業日"
//   , "10営業日"
//   , "11営業日"
//   , "12営業日"
//   , "13営業日"
//   , "14営業日"
//   , "15営業日"
//   , "16営業日"
//   , "17営業日"
//   , "18営業日"
//   , "19営業日"
//   , "20営業日"
//   , "21営業日"
//   , "22営業日"
//   , "23営業日"
//   , "24営業日"
//   , "25営業日"
//   , "毎営業日"
//   , "月末1営業日"
//   , "月末2営業日"
//   , "月末3営業日"
//   , "月末4営業日"
//   , "月末5営業日"
//   , "月末6営業日"
//   , "月末7営業日"
//   , "月末8営業日"
//   , "月末9営業日"
//   , "月末10営業日"
// ];

// export const DAYOFWEEKS = [
//   "月曜日"
//   , "火曜日"
//   , "水曜日"
//   , "木曜日"
//   , "金曜日"
// ];

export const CSV_EXTENSION = 'csv';
export const TASK_TYPE = {
  NORMAL_BUSINESS: 10,
  WORK_NOT_SUBJECT_TO_PRODUCTIVITY_EVALUATION: 20,
  IRREGULAR_ATTENDANCE: 30,
}
export const TASK_TYPE_LIST = [
  {
    text: '通常業務',
    value: TASK_TYPE.NORMAL_BUSINESS,
    isDBTask: true
  },
  {
    text: '生産性評価対象外業務',
    value: TASK_TYPE.WORK_NOT_SUBJECT_TO_PRODUCTIVITY_EVALUATION,
    isDBTask: true
  },
  {
    text: 'イレギュラー勤怠',
    value: TASK_TYPE.IRREGULAR_ATTENDANCE,
    isDBTask: false
  },
]

/**
 * 実施頻度
 */
export const TASK_TIMING = {
  NO_SETTING: 10,
  MONTHLY: 20,
  WEEKLY: 30,
  DAILY: 40,
}

export const TASK_TIMING_LIST = [
  {
    text:'ー（設定なし）',
    value:TASK_TIMING.NO_SETTING
  },
  {
    text:'月次',
    value:TASK_TIMING.MONTHLY
  },
  {
    text:'週次',
    value:TASK_TIMING.WEEKLY
  },
  {
    text:'日次',
    value:TASK_TIMING.DAILY
  }
]

export const DB_TASK_TYPE_LIST = TASK_TYPE_LIST
  .filter(item => item.isDBTask)
  .map(item => item.value)

export const FREE_TASK_TYPE_LIST = TASK_TYPE_LIST
  .filter(item => item.isDBTask)

export const DEFAULT_START_BREAK_TIME = '120000';
export const DEFAULT_END_BREAK_TIME = '130000';
export const DEFAULT_START_BREAK_TIME_HH_MM = '12:00';
export const DEFAULT_END_BREAK_TIME_HH_MM = '13:00';
export const DEFAULT_START_TIME = '100000';
export const DEFAULT_END_TIME = '190000';
export const DEFAULT_START_TIME_HH_MM = '10:00';
export const DEFAULT_END_TIME_HH_MM = '19:00';
