<template>
  <div id="todolistDragments">
    <v-list-item class="px-2 mt-4">
      <v-row>
        <v-col cols="12" class="v-center pl-5">お気に入り</v-col>
      </v-row>
    </v-list-item>

    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="7">
          <v-text-field
            solo
            placeholder="検索"
            maxlength="32"
            @change="handleSearch"
            v-model="search"
          >
            <template>
              <v-icon @click="handleSearch" small slot="prepend-inner">
                $search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <!--<v-col cols="5">
          <FilterOption
            @reset="resetFilter"
            @submit="submitFilter"
            :items="filters"
          ></FilterOption>
        </v-col>-->
      </v-row>
    </v-list-item>
    <v-divider></v-divider>

    <div>
      <v-btn text @click="addFavoriteTask">
        <v-icon small class="blue--text" left> $addtodolist </v-icon>
        タスクの追加
      </v-btn>
    </div>

    <div
      class="text-center"
      v-if="
        !(dbTasks && dbTasks.length > 0) &&
        !(favoriteTasks && favoriteTasks.length > 0) &&
        !isEmptySearch(this.search)
      "
    >
      <p class="gray--text">検索条件に一致するタスクは</p>
      <p class="gray--text">見つかりませんでした。</p>
    </div>

    <div class="height-custom">
      <div class="d-flex justify-center" v-if="isLoading">
        <svg
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          stroke="#000"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      <v-list class="custom-tree" v-else>
        <v-list-group
          sub-group
          v-model="dbModel"
          v-if="dbTasks && dbTasks.length > 0"
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-row>
              <v-col cols="12">
                <v-list-item-content>
                  <v-list-item-title>標準工数タスク</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="12" class="pd0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
            <v-list-item
              :class="[{ 'todo-task': isAllowDrag() }]"
              :name="item.id"
              v-for="(item, i) in dbTasks"
              :key="i"
              @click.stop
            >
              <v-list-item-icon @click="isFavorite(item)">
                <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
                <v-icon small v-else>$favoriteinactive</v-icon>
              </v-list-item-icon>
              <v-list-item-content @click="editDbTask(item)">
                <v-row style="width: 100%">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <div class="d-flex align-center flex-wrap">
                                <div class="text-truncate mr-1">
                                  {{ item.name }}
                                </div>
                                <BeginnerIcon v-if="isBeginnerSkill(item)" />
                                <ExpertIcon v-if="isExpertSkill(item)" />
                              </div>
                            </v-list-item-title>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" class="text-left">
                        <span>{{ item.cost }}m/回</span></v-col
                      >
                    </v-row>
                    <!--<v-row>
                      <v-col cols="12" class="text-right">
                        <v-list-item-title v-if="item.tags">
                          <v-chip
                            v-for="tag in item.tags.slice(0, 3)"
                            :key="tag.id"
                            x-small
                            depressed
                          >
                            {{ getFiveCharacters(tag.name) }}
                          </v-chip>
                        </v-list-item-title></v-col
                      >
                    </v-row>-->
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-group
          sub-group
          v-model="favoriteModel"
          v-if="favoriteTasks && favoriteTasks.length > 0"
          prepend-icon="mdi-chevron-down"
        >
          <template v-slot:activator>
            <v-row>
              <v-col cols="12">
                <v-list-item-content>
                  <v-list-item-title>標準工数以外タスク</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="12" class="pd0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </template>
            <v-list-item
              :class="[{ 'todo-task': isAllowDrag() }]"
              :name="item.id"
              v-for="(item, i) in favoriteTasks"
              :key="i"
              @click.stop
            >
              <v-list-item-icon @click="isFavorite(item)">
                <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
                <v-icon small v-else>$favoriteinactive</v-icon>
              </v-list-item-icon>
              <v-list-item-content @click="editFavoriteTask(item)">
                <v-row style="width: 100%">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">
                              <div class="d-flex align-center flex-wrap">
                                <div class="text-truncate mr-1">
                                  {{ item.name }}
                                </div>
                                <BeginnerIcon v-if="isBeginnerSkill(item)" />
                                <ExpertIcon v-if="isExpertSkill(item)" />
                              </div>
                            </v-list-item-title>
                          </template>
                          <span>{{ item.name }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="4" class="text-left">
                        <span>{{ item.cost }}m/回</span></v-col
                      >
                    </v-row>
                    <!--<v-row>
                      <v-col cols="12" class="text-right">
                        <v-list-item-title v-if="item.tags">
                          <v-chip
                            v-for="tag in item.tags.slice(0, 3)"
                            :key="tag.id"
                            x-small
                            depressed
                          >
                            {{ getFiveCharacters(tag.name) }}
                          </v-chip>
                        </v-list-item-title></v-col
                      >
                    </v-row>-->
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
        </v-list-group>
      </v-list>
    </div>
    <FavoriteTask
      @registerFavoriteTask="registerFavoriteTask($event)"
      @deleteFavoriteTask="deleteFavoriteTask($event)"
      :show="showFavoriteCreate"
      :taskId="favoriteTaskId"
      :planId="planId"
      :isPlan="isPlan"
      :isAddFavorite="isAddFavorite"
      :isFavorite=true
      @addToCalendar="addToCalendar"
    ></FavoriteTask>
    <DatabaseTask
        :show="showDatabaseTask"
        :standarId="standarId"
        :isPlan="isPlan"
        @isFavorite="setFavorite($event)"
        @addToCalendar="addToCalendar($event)"
      ></DatabaseTask>
    <ActualDatabaseTask
        :show="showActualDatabaseTask"
        :standarId="standarId"
        :isPlan="isPlan"
        @isFavorite="setFavorite($event)"
        @addToCalendar="addToCalendar($event)"
      ></ActualDatabaseTask>
  </div>
</template>

<script>
//import FilterOption from "../modal/FilterOption";
import FavoriteTask from "../modal/FavoriteTask";
import DatabaseTask from "../modal/DatabaseTask";
import ActualDatabaseTask from "../../actual/modal/DatabaseTask";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { FAVORITE_TASK_API, UPDATE_DB_TASKS_API } from "../../../constants/api";
import { mapGetters } from "vuex";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC833, FC709, FC831 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import BeginnerIcon from "@/components/icons/BeginnerIcon.vue";
import ExpertIcon from "@/components/icons/ExpertIcon.vue";
import * as systemAuth from "@/constants/role_code";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: {
    //FilterOption,
    FavoriteTask,
    DatabaseTask,
    ActualDatabaseTask,
    BeginnerIcon,
    ExpertIcon,
  },
  mixins: [taskMixin],
  props: {
    value: Boolean,
    planId: String,
    actualId: {
      type: String,
      default: null
    },
    isPlan: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
    },
    show: {
      async handler(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    registerTask(task) {
      if (this.isPlan){
        this.$root.$refs.scheduleComponent.addEventFromDbList(task);
      }
      else{
        this.$root.$refs.actualComponent.addEventFromDbList(task);
      }
    },

    addToCalendar(item) {
      let time = item.start_time + ":00";
      this.$emit("drop-favorite-list-tab-item", { time, item });
      this.$emit("activeCalendarTab");
    },

    touchstart(event) {
      document.documentElement.style.overflow = "hidden";
      this.dragItem = event.target;
      this.originalTop = event.touches[0].clientY;
      this.dragItem.style.height = this.dragItem.clientHeight;
      this.dragItem.style.width = this.dragItem.clientWidth;
      this.dragItem.style.position = "fixed";
      this.dragItem.style.zIndex = "99999";
    },
    touchmove(event) {
      const x = event.changedTouches[0].clientX;
      const y = event.changedTouches[0].clientY;
      let target = document.elementsFromPoint(x, y);
      if (!target && !target.length <= 0) {
        // Mouse going off screen. Ignore event.
        return;
      }
      if (
        target
          .map((x) => {
            return x.id;
          })
          .some((x) => x === "calendarTab")
      ) {
        this.$emit("activeCalendarTab");
      }
      if (this.dragItem) {
        this.dragItem.style.top = event.changedTouches[0].clientY + "px";
      }
    },

    touchend(event, item) {
      const x = event.changedTouches[0].clientX; // (relative to the viewport)
      const y = event.changedTouches[0].clientY;
      const target = document.elementsFromPoint(x, y);
      if (!target && !target.length <= 0) {
        return;
      }
      const timeRowInCalendar = [].filter.call(target, (elements) =>
        [].includes.call(elements.classList, "fc-timegrid-slot-lane")
      );
      // Get time
      const time = timeRowInCalendar?.[0]?.dataset?.time;
      if (!isEmpty(time)) this.$emit("drop-todo-list-tab-item", { time, item });
      if (this.dragItem) {
        this.dragItem.style.top = this.originalTop;
        // reset our element
        this.dragItem.style.left = "";

        this.dragItem.style.height = "";
        this.dragItem.style.width = "";
        this.dragItem.style.position = "";
        this.dragItem.style.zIndex = "";

        this.dragItem = null;
      }
      document.documentElement.style.overflow = "auto";
    },

    /*updateTag(item) {
      let task = this.dbTasks.filter((x) => x.standard_task_id == item.id)[0];
      if (task) {
        task.tags = item.tags;
        task.tags.map((x) => {
          x.id = x.tag_id;
          x.name = x.tag_name;
        });
      }
      let originTask = this.originDbTasks.filter(
        (x) => x.standard_task_id == item.id
      )[0];
      if (originTask) {
        originTask.tags = item.tags;
        originTask.tags.map((x) => {
          x.id = x.tag_id;
          x.name = x.tag_name;
        });
      }
      this.reloadFilter();
    },*/

    setFavorite(item) {
      this.removeDbTask(item.id);
    },

    registerFavoriteTask(task) {
      task.isSplit = false;
      this.isAddFavorite = false;
      this.originFavoriteTasks = this.originFavoriteTasks.filter((x) => x.favorite_task_id != task.favorite_task_id);
      this.favoriteTasks = this.favoriteTasks.filter((x) => x.favorite_task_id != task.favorite_task_id);
      this.favoriteTasks.push(task);
      this.originFavoriteTasks.push(task);
      this.sortFavoriteTasks();
      //this.reloadFilter();
    },

    deleteFavoriteTask(task) {
      this.originFavoriteTasks = this.originFavoriteTasks.filter((x) => x.favorite_task_id != task.favorite_task_id);
      this.favoriteTasks = this.favoriteTasks.filter((x) => x.favorite_task_id != task.favorite_task_id);
      this.sortFavoriteTasks();
      //this.reloadFilter();
    },

    sortFavoriteTasks() {
      this.favoriteTasks.sort(
        (a, b) =>
          a.name.localeCompare(b.name) ||
          b.id - a.id
      );
    },
    sortDbTasks() {
      this.dbTasks.sort(
        (a, b) =>
          a.name.localeCompare(b.name) ||
          b.id - a.id
      );
    },

    async isFavorite(item) {
      if (!item.standard_task_id) {
        try {
            await axios.delete(FAVORITE_TASK_API(item.favorite_task_id));
            this.removeFavoriteTask(item.favorite_task_id);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC833, status);
          }
      } else {
        try {
          await axios.put(
            UPDATE_DB_TASKS_API(item.user_id, item.standard_task_id),
            {
              is_favorite: +!item.is_favorite,
            }
          );
          this.removeDbTask(item.standard_task_id);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC709, status);
        }
      }
    },

    handleSearch() {
      const searchConvert = this.convertSearchKey(this.search);
      this.dbTasks = this.originDbTasks.filter(
        (y) =>
          isEmpty(this.search) ||
          this.convertSearchKey(y.name).includes(searchConvert) /*||
          (y.tags &&
            y.tags.some(
              (z) => isEmpty(this.search) || this.convertSearchKey(z.name).includes(searchConvert)
            ))*/
      );
      this.favoriteTasks = this.originFavoriteTasks.filter(
        (y) =>
          isEmpty(this.search) ||
          this.convertSearchKey(y.name).includes(searchConvert) /*||
          (y.tags &&
            y.tags.some(
              (z) => isEmpty(this.search) || this.convertSearchKey(z.name).includes(searchConvert)
            ))*/
      );
    },

    isEmptySearch() {
      return isEmpty(this.search);
    },

    isBeginnerSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_BEGIN
    },

    isExpertSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_EXPERT
    },
    
    resetFilter() {
      this.dbTasks = this.originDbTasks;
      this.favoriteTasks = this.originFavoriteTasks;
    },

    submitFilter(filters) {
      this.dbTasks = this.originDbTasks.filter(
        /*(y) =>
          (y.tags &&
            y.tags.some((z) =>
              filters.some((g) => g.check && g.tag_name === z.name)
            )) ||*/
          !filters.some((g) => g.check)
      );
      this.favoriteTasks = this.originFavoriteTasks.filter(
        /*(y) =>
          (y.tags &&
            y.tags.some((z) =>
              filters.some((g) => g.check && g.tag_name === z.name)
            )) ||*/
          !filters.some((g) => g.check)
      );
    },

    addFavoriteTask() {
      this.isAddFavorite = true;
      this.showFavoriteCreate = !this.showFavoriteCreate;
    },

    editFavoriteTask(item) {
      this.favoriteTaskId = item.favorite_task_id;
      this.showFavoriteCreate = !this.showFavoriteCreate;
    },

    getFavoriteList() {
      return this.favoriteTasks;
    },

    getDbList() {
      return this.dbTasks;
    },

    editDbTask(item) {
      this.standarId = item.standard_task_id;
      if (this.isPlan){
        this.showDatabaseTask = !this.showDatabaseTask;
      }
      else{
        this.showActualDatabaseTask = !this.showActualDatabaseTask;
      }
    },

    removeDbTask(id) {
      this.dbTasks = this.dbTasks.filter((x) => x.standard_task_id !== id);
      this.originDbTasks = this.originDbTasks.filter((x) => x.standard_task_id !== id);
      //this.reloadFilter();
    },

    removeFavoriteTask(id) {
      this.favoriteTasks = this.favoriteTasks.filter((x) => x.favorite_task_id !== id);
      this.originFavoriteTasks = this.originFavoriteTasks.filter((x) => x.favorite_task_id !== id);
      //this.reloadFilter();
    },

    /*getFiveCharacters(name) {
      return !isEmpty(name) && name.length > 5
        ? name.substring(0, 5) + "..."
        : name;
    },*/

    async loadFavoriteListData () {
      // this.isLoading = true;
      await this.getData();
    },

    async getData() {
      this.search = "";
      try {
        this.dbTasks = [];
        this.favoriteTasks = [];
        const { data, status } = await axios.get(`${FAVORITE_TASK_API()}`);
        let resultTasks = status === STATUS_NO_CONTENT ? [] : data.result;
        resultTasks.forEach((dbTask) => {
          if (dbTask.standard_task_id){
            dbTask.count = 1;
            this.dbTasks.push(dbTask);
          }
          else{
            this.favoriteTasks.push(dbTask);
          }
        });
        this.originDbTasks = deepClone(this.dbTasks);
        this.originFavoriteTasks = deepClone(this.favoriteTasks);
        //this.reloadFilter();
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC831, status);
      } finally {
        this.isLoading = false;
      }
    },

    /*reloadFilter() {
      let tags = [];
      let tempData = [...this.originDbTasks, ...this.originFavoriteTasks];
      tempData.forEach((dbTask) => {
        tags = tags.concat(
          dbTask.tags.map((x) => {
            return x.name;
          })
        );
      });
      this.filters = tags
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((name) => {
          return { check: false, tag_name: name };
        });
    },*/

    isAllowDrag() {
      if (this.isPlan){
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },

    /*removeTag(tag) {
      try {
        this.dbTasks = this.dbTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.originDbTasks = this.originDbTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.favoriteTasks = this.favoriteTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.originFavoriteTasks = this.originFavoriteTasks.map((x) => {
          return {
            ...x,
            ...{ tags: x.tags.filter((z) => +z.id !== +tag.id) },
          };
        });
        this.reloadFilter();
      } catch {
        return;
      }
    },

    editTag(tag) {
      const addTag = function (y) {
        if (y.tags.some((z) => +z.id === +tag.id)) {
          y.tags = y.tags.map((x) => {
            if (+tag.id === +x.id) {
              return tag;
            } else {
              return x;
            }
          });
        }

        return y.tags;
      };
      try {
        this.dbTasks = this.dbTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.originDbTasks = this.originDbTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.favoriteTasks = this.favoriteTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.originFavoriteTasks = this.originFavoriteTasks.map((y) => {
          return {
            ...y,
            tags: addTag(y),
          };
        });
        this.reloadFilter();
      } catch {
        return;
      }
    },*/
  },

  data() {
    return {
      search: "",
      show: false,
      showFavoriteCreate: false,
      showDatabaseTask: false,
      showActualDatabaseTask: false,
      dbTasks: [], // Display task
      originDbTasks: [], // database search result
      filters: [],
      taskId: null,
      standarId: null,
      dragItem: null,
      originalTop: null,
      favoriteTasks: [],// Display task
      originFavoriteTasks: [],// database search result
      isLoading: false,
      dbModel: true,
      favoriteModel: true,
      isSplit: false,
      favoriteTaskId: null,
      isAddFavorite: false,
    };
  },
  async mounted() {
    //this.isLoading = true;
    await this.getData();
    //this.isLoading = false;
  },
  created() {
    this.$root.$refs.scheduleFavoriteTabList = this;
  },
};
</script>
<style lang="scss" scoped>
.height-custom {
  height: calc(
    (var(--vh, 1vh) * 100) - 64px - 72px - 60px - 48px - 66px - 13px
  );
  overflow-x: hidden;
  ::v-deep .v-list-group__header {
    padding-left: 12px;
    color: rgba(0, 0, 0, 0.87);
    .v-list-group__header__prepend-icon {
      margin-right: 0;
    }
  }
  ::v-deep
    .v-list-group--sub-group
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(-90deg);
  }
  ::v-deep
    .v-list-group--sub-group.v-list-group--active
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(0deg);
  }
}
.pd0 {
  padding: 0;
}
.custom-tree {
  ::v-deep .v-list-group__items {
    width: 100%;
  }
  .v-list-item__content {
    align-items: start;
    flex-wrap: nowrap;
    flex: 1 1;
    overflow: hidden;
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid #e1e1e1;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .v-list-item__icon:first-child {
    margin-right: 2px;
  }
  .v-list-item__icon {
    align-self: center;
    margin: 0;
  }
  ::v-deep .expertTaskIcon > img.v-icon__component {
    width: 30px;
  }
  ::v-deep .beginnerTaskIcon > img.v-icon__component {
    width: 38px;
    height: 28px;
  }
}
</style>
