<template>
  <v-dialog persistent :value="openDialog" max-width="500px">
    <v-card class="py-3">
      <v-card-title>
        <div>
          修正が必要です。<br>
          入力内容をご確認ください。
        </div>
      </v-card-title>
      <v-card-actions class="justify-end">
        <v-btn tile color="primary darken-1" @click="close()">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      openDialog: true,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>