<template>
  <v-dialog
    v-model="openTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="my-0">
          <v-col>
            <v-icon small>$filter</v-icon>
            <span class="ml-2 work-break">{{ task.name }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="my-0">
          <v-col>
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col>
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2">{{ task.category_primary_name }}</span>
            </div>
            <div
              v-if="task.category_secondary_id !== null"
              class="mt-2 ml-5 category"
            >
              <span class="ml-2">{{ task.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="align-center">
            <div>
              <v-icon small>$taskType</v-icon>
              <span class="ml-2">{{ labelType }}：</span>
              <span>{{ task.task_type_name || '' }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="align-center">
            <div>
              <span class="ml-6">定常/非定常 ：</span>
              <span>{{ task.task_usual ? "定常" : "非定常" }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="align-center">
            <div>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">標準工数：</span>
              <span>{{ task.cost }}m</span>
            </div>
            <div class="mt-2 ml-6 mb-4">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
            <div class="d-flex">
              <v-checkbox
                readonly
                label="ビギナー設定あり"
                v-model="task.is_target_beginner"
              >
              </v-checkbox>
              <v-checkbox class="ml-3"
                readonly
                label="エキスパート設定あり"
                v-model="task.is_target_expert"
              >
              </v-checkbox>
            </div>
            <div
              class="ml-6"
              v-if="!!task.is_target_expert || !!task.is_target_beginner"
            >
              <div v-if="!!task.is_target_beginner">
                <span>ビギナー</span>
                <span class="mr-1 ml-2">{{ task.cost_beginner }}</span
                >m
              </div>
              <div>
                <span>スタンダード</span>
                <span class="mr-1 ml-2">{{ task.cost_standard }} </span>m
              </div>
              <div v-if="!!task.is_target_expert">
                <span>エキスパート</span>
                <span class="mr-1 ml-2">{{ task.cost_expert }} </span>m
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="align-center">
            <div>
              <v-icon small>$calendar</v-icon>
              <span class="ml-2">実施頻度 ：</span>
              <span>{{ task.task_timing_name || '' }}</span>
            </div>
          </v-col>
        </v-row>

        <!--<v-row class="my-0" v-if="isMaster()">
          <v-col class="d-flex align-center">
            <v-icon small>$taskvisiblestatus</v-icon>
            <span class="ml-2">ステータス：</span>
            <span>{{ !!task.is_visible ? "表示" : " 非表示" }}</span>
          </v-col>
        </v-row>-->
      </v-card-text>
      <v-card-actions v-if="isMaster()" class="ml-auto">
        <v-btn depressed color="error" @click="deleteTask()"> 削除 </v-btn>
        <v-btn depressed color="primary" @click="updateTask()"> 編集 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DB_STANDARD_API } from "../../../constants/api";
import { FC705, FC707 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { apiErrorMessages } from "../../../helpers/messages";
import axios from "axios";
import { isEmpty } from "../../../helpers/helpers";
import { taskMixin } from '@/mixins/task.js';
import { dbMasterMixin } from "@/mixins/dbMaster.js";
export default {
  mixins: [taskMixin, dbMasterMixin],
  props: {
    show: Boolean,
  },
  watch: {
    openTask: {
      async handler() {
        this.task = {};
        if (this.openTask) {
          await this.getData();
        }
      },
    },
  },
  data() {
    return {
      task: {},
      openTask: false,
    };
  },
  methods: {
    showDialog(value, taskId) {
      this.openTask = value;
      this.taskId = taskId;
    },
    close() {
      this.openTask = false;
    },

    deleteTask() {
      this.$confirm(this.$t("label.confirm.delete_task"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DB_STANDARD_API(this.taskId));
            this.openTask = false;
            this.$emit("taskDeleted", this.task);
            this.$toast(`${this.task.name}を削除しました。`, 3000);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC707, status);
          }
        })
        .catch(() => {});
    },

    isMaster() {
      return this.$root.$refs.dbMasterComponent.isMaster();
    },

    updateTask() {
      this.openTask = false;
      this.$emit("updateTaskSuccess", this.task);
    },

    checkEmpty(value) {
      return isEmpty(value);
    },

    async getData() {
      try {
        const { data, status } = await axios.get(DB_STANDARD_API(this.taskId));
        if(status === STATUS_NO_CONTENT){
          this.task = {};
        }
        else {
          this.task = this.mapData(data.result)[0];
          this.resetSkillLevel();
        }
        if (isEmpty(this.task.history)) this.task.history = [];
        else this.task.history = this.task.history.slice(0, 3);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC705, status);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.work-break{
  word-break: break-all;
}
::v-deep .v-input__slot .v-input--selection-controls__input {
  margin-right: 5px;
}
</style>
