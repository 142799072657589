<template>
  <v-dialog
    v-model="openFreeTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="actualvTask"
    v-dialog-drag="{
      dialog: 'actualvTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card :id="parentId">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="isEditComment()"
              @click="copyLink()"
              class="ml-auto"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$link</v-icon>
            </v-btn>
          </template>
          <span>タスクのリンクをコピー</span>
        </v-tooltip>
        <v-btn
          @click="close()"
          :class="{ 'ml-auto': !isEditComment() }"
          icon
          color="gray"
        >
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <!-- <v-row class="py-4">
          <v-col class="d-flex align-center"> -->
        <v-row class="py-0 my-0">
          <span class="ml-9 mb-n4">{{ task.category_secondary_name || "未分類" }}</span>
          <v-col cols="12" class="py-4 d-flex align-center">
            <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
            <v-icon v-else small>$favoriteinactive</v-icon>
            <span class="ml-2 work-break">{{ task.name }}</span>
            <span :class="[{ detail: !isDetailEmpty() }]">{{
              task.detail
            }}</span>
          </v-col>
          <!-- </v-row> 
        <v-divider></v-divider> -->
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>

          <!-- <v-row class="py-3"> 
          <v-col class="d-flex align-center"> -->
          <v-col cols="12" class="py-4 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
          <!-- </v-row> -->

          <!-- <v-row class="my-0"> 
          <v-col> -->
          <v-col cols="12" class="pt-4 pb-0">
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2">{{ primaryCategoryName }}</span>
            </div>
            <div
              v-if="task.category_secondary_id !== null"
              class="mt-2 pb-0 ml-5 category"
            >
              <span class="ml-2">{{ task.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <template v-if="task.is_split_task === 1">
          <div>
            <v-row class="py-4 my-0">
              <v-col cols="12" class="d-flex align-center">
                <v-icon small class="yellow--text">$taskworktime</v-icon>
                <span class="ml-2">日次実績</span>
                <span class="mx-2">{{ task.cost }}m</span>
              </v-col>
              <v-col class="py-0 my-0">
                <div class="d-flex">
                  <span class="ml-7">累計実績</span>
                  <span class="ml-1">{{ task.total_actual_cost }} m</span>
                  <span class="ml-1 mr-1">/</span>
                  <span class="">{{ task.total_cost }} m</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
        <v-row class="pt-4 pb-0 my-0" v-else>
          <v-col class="align-center pb-0">
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2 w-120">日次実績</span>
              <div class="ml-2 d-flex align-center">
                <span>{{ task.cost }}m/回</span>
                <span class="mx-2">×</span>
                <span>{{ task.count }}回</span>
                <span class="mx-2">=</span>
                <span>{{ task.cost * task.count }}m</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!isFreeTask(task)">
          <v-col cols="12" class="py-0 my-0">
            <div class="ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || "" }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="pt-5 pb-3 my-0">
          <v-col class="d-flex align-center">
            <v-icon small>$taskstartendtime</v-icon>
            <div class="text-number ml-2 d-flex align-center">
              <span>{{ task.start_time }}</span>
              <span class="mx-2">~</span>
              <span>{{ task.end_time }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col class="py-0 my-0">
            <v-radio-group
              :disabled="!canChangeCompleted()"
              v-model="task.is_split_task_completed"
            >
              <v-radio
                v-bind:value="0"
                :label="'タスクを一部着手して後日継続予定'"
              >
              </v-radio>
              <span class="ml-8">
                ※チェックを付けて保存した場合、「未完了タスク」に登録されます
              </span>
              <br />
              <v-radio v-bind:value="1" :label="'タスクが全て完了'"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!--<v-row class="py-2 my-0" :class="isFreeTask(task) ? 'pt-4' : ''">
          <v-col class="py-0 my-0 d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="!hasTag">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet>
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>-->

        <v-row class="pt-4 my-0" v-if="isEditComment()">

          <v-col class="py-0" v-if="!showComment" cols="12">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}</p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col class="py-0 d-flex align-center" v-else cols="12">
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="isEditComment()"
          :disabled="!isEditing"
          tile
          depressed
          class="ml-auto"
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <PreviewFile :show="showPreview" :src="src" :text="text"> </PreviewFile>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {
  GET_OR_DEL_ACTUAL_TASK_API,
  ATTACH_FILE_API,
  DELETE_COMMENT_API,
  ACTUAL_TASK_API,
  NOTIFICATION_API
} from "../../../constants/api";
import {
  deepClone,
  isEmpty,
  copyToClipboard,
  saveFile,
  isEmptyNumber,
  isSafari,
  debounce_leading,
  orderBy,
} from "../../../helpers/helpers";
import {
  formatTime,
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC804, FC1003, FC820, FC805, FC501, FC502 } from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { backMixin } from "../../../mixins/back";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";
import PreviewFile from "./PreviewFile.vue";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: { PreviewFile },
  props: {
    show: Boolean,
    actualId: String,
    actualTaskId: Number,
    planId: String,
    scheduleComments: {
      type: Array,
      default: () => [],
    },
    viewType: String,
  },
  mixins: [backMixin, taskMixin],
  directives: {
    "dialog-drag": DialogDragDirective,
  },

  data() {
    return {
      openFreeTask: false,
      isEditing: false,
      showComment: false,
      userComment: "",
      comments: this.scheduleComments,
      parentId: "actionFreeTaskId",
      task: { name: "", files: [], /*tags: [],*/ comments: [] },
      originalTask: { name: "", files: [], /*tags: [],*/ comments: [] },
      src: "",
      text: "",
      showPreview: false,
      listNotification: [],
    };
  },

  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },

  watch: {
    async show() {
      this.task = {
        name: "",
        files: [],
        //tags: [],
        comments: [],
      };
      this.openFreeTask = true;
      this.userComment = "";
      await this.getTask();
      await this.getNotification();
      this.showComment = false;
    },
    userComment: {
      handler(val) {
        this.isEditing = !isEmpty(val);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    scheduleComments: {
      handler(val) {
        this.comments = val;
      },
    },
    task: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },

  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    hasFile() {
      return this.task && this.task.files && this.task.files.length > 0;
    },
    /*hasTag() {
      return this.task && this.task.tags && this.task.tags.length > 0;
    },*/
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
    primaryCategoryName() {
      return !isEmptyNumber(this.task.category_secondary_id)
        ? this.task.category_primary_name
        : "未分類";
    },
  },

  methods: {
    /**
     * 一度完了にしたタスクも未完了に戻せるようにする
     */
    canChangeCompleted() {
      if (this.viewType === "ACTUAL") {
        // 本人確認
        if (!this.$root.$refs.actualComponent.isSelf()) {
          return false;
        }

        // 確認済みのみで変更できる
        if (!this.$root.$refs.actualComponent.isVefified()) {
          return false;
        }

        if (this.originalTask.count > 1) {
          return false;
        }

        // 完了タスクではない場合
        if (this.originalTask.is_split_task_completed !== 1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    isDetailEmpty() {
      return isEmpty(this.task.detail);
    },
    isFreeTask() {
      return !isEmptyNumber(this.task.actual_free_task_id);
    },
    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    async getTask() {
      try {
        const { data } = await axios.get(
          GET_OR_DEL_ACTUAL_TASK_API(this.actualId, this.actualTaskId)
        );
        if (status === STATUS_NO_CONTENT) {
          this.openFreeTask = false;
          this.$alert("タスクが見つかりません。");
        } else {
          let rdata = data.result[0];
          this.task = deepClone(rdata);
          this.task.start_time = formatTime(rdata.start_time);
          this.task.end_time = formatTime(rdata.end_time);
          if (this.task.is_split_task === 1) {
            //
          } else {
            this.task.is_split_task_completed = 1;
          }

          if (+this.task.is_completed_change === 1){
            this.task.is_split_task_completed = 0;
            this.task.is_split_task == 1;
          }
          let scheduleComments = this.comments.map((x) => {
            return {
              comment: x.comment,
              user_id: x.user_id,
              user_name: x.user_name,
              updated_at: x.updated_at,
              id: x.id,
            };
          });
          //Add schedule comment for task
          if (this.comments && this.comments.length > 0) {
            let comments = [];
            [...this.task.comments, ...scheduleComments].forEach((v) => {
              if (comments.some((x) => x.id === v.id)) {
                //
              } else {
                comments.push(v);
              }
            });
            this.task.comments = orderBy(comments, ["updated_at"], ["desc"]);
          }
          this.originalTask = deepClone(this.task);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC804, status);
      }
    },

    isEditComment() {
      if (this.viewType === "ACTUAL") {
        return (
          this.$root.$refs.actualComponent.isSupervisor() ||
          this.$root.$refs.actualComponent.isSelf()
        );
      } else {
        return false;
      }
    },

    async viewAttachFile(item) {
      try {
        const { data, headers, status } = await axios.get(
          ATTACH_FILE_API(item.id),
          {
            responseType: "blob",
          }
        );

        if (status === STATUS_NO_CONTENT) return;
        const blob = new Blob([data], {
          type: contentType,
        });
        var contentType = headers["content-type"];
        if (
          contentType.startsWith("image/") ||
          contentType.startsWith("text/")
        ) {
          let that = this;
          if (isSafari() && typeof FileReader !== "undefined") {
            if (contentType.startsWith("image/")) {
              let reader = new FileReader();
              reader.onloadend = function () {
                that.src = reader.result;
                that.text = "";
                that.showPreview = !that.showPreview;
              };
              reader.readAsDataURL(blob);
            } else {
              let reader = new FileReader();
              reader.onloadend = function () {
                that.text = reader.result;
                that.src = "";
                that.showPreview = !that.showPreview;
              };
              reader.readAsText(blob);
            }
          } else {
            saveFile(data, headers, item.name);
          }
        } else {
          saveFile(data, headers, item.name);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC1003, status);
      }
    },

    copyLink() {
      copyToClipboard(
        window.location.origin +
          `/actual/${this.actualId}?taskId=${this.task.id}&sid=${this.planId}`,
        this.parentId
      );
      this.$toast(this.$t("label.toast.copied"), 3000);
    },

    deleteComment(item) {
      if (item.user_id !== this.loginInfo.user_id) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            // this.originalTask.comments = this.originalTask.comments.filter(
            //   (x) => x.id !== item.id
            // );
            this.$emit("deleteComment", {
              comment_id: item.id,
              schedule_task_id: this.task.schedule_task_id,
            });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    updateComment() {
      this.showComment = true;
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openFreeTask = false;
          }, 100);
        },
        () => {}
      );
    },
    updateTask: debounce_leading(async function () {
      let params = [];
      let hasCompletedChange = 0;
      if (
        this.canChangeCompleted() &&
        this.task.is_split_task_completed === 0
      ) {
        hasCompletedChange = 1;
        params = [
          {
            ...this.task,
            start_time: (this.task.start_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            end_time: (this.task.end_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            is_completed_change: 1,
            comments:
              !isEmpty(this.userComment) && this.showComment
                ? [
                    {
                      user_id: this.loginInfo.user_id,
                      comment: this.userComment,
                    },
                  ]
                : [],
            is_split_task_completed: this.task.is_split_task_completed,
            is_split_task: 1,
          },
        ];
      } else {
        params = [
          {
            id: this.task.id,
            comments:
              !isEmpty(this.userComment) && this.showComment
                ? [
                    {
                      user_id: this.loginInfo.user_id,
                      comment: this.userComment,
                    },
                  ]
                : [],
          },
        ];
      }

      try {
        const { data } = await axios.put(ACTUAL_TASK_API(this.actualId), {
          actual_tasks: params,
        });
        this.$store.dispatch("save/setChangeFlag", false);
        this.openFreeTask = false;
        data.result[0].start_time = formatTime(data.result[0].start_time);
        data.result[0].end_time = formatTime(data.result[0].end_time);
        data.result[0].has_completed_change = hasCompletedChange;
        this.$emit("updateTaskSuccess", data.result[0]);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC805, status);
      }
    }),
    
    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let comment = this.task.comments.find(
            x => +x.id === +item.comment_id && !!item.can_verified
          );
          if (comment){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: null,
            comment_notice_id: item.comment_notice_id,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openFreeTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    compareValue() {
      return (
        JSON.stringify({
          is_split_task_completed: this.task.is_split_task_completed,
        }) !==
        JSON.stringify({
          is_split_task_completed: this.originalTask.is_split_task_completed,
        })
      );
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.w-120 {
  width: 120px;
}
.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  white-space: pre-wrap;
}
</style>
