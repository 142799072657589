<template>
  <v-container fluid class="pa-0 pb-0">
    <v-row>
      <!-- Search field -->
      <v-col cols="6" md="4" class="pb-0 ml-auto">
        <v-text-field placeholder="検索" v-model="filterBy.searchText" @keydown.enter="search()" hide-details="auto">
          <template v-slot:append>
            <v-icon @click="search()" small>$search</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <!--. Search field -->
    </v-row>
    <v-row>
      <!-- Side bar -->
      <v-col cols="12" md="2" class="d-flex d-md-block justify-space-between align-center py-0">
        <v-list class="d-flex d-md-block" rounded>
          <v-subheader class="pl-0">お知らせ一覧</v-subheader>
          <v-list-item-group class="d-flex d-md-block" v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in notificationType" :key="i" :disabled="disabledLink(i)" :href="editLink(i)"
              :class="changeListItemClass(i)">
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="d-md-none">
          <v-menu left offset-x bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon class="grey--text">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <sort-by :sort="filterBy.sort" :sortByOld="noticeConfig.SORT_BY_OLD"
                  :sortByNew="noticeConfig.SORT_BY_NEW" @sortBy="sortBy($event)"></sort-by>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <filter-date :inputDate="filterDateChosen" @changeDate="filterDate($event)"
                  @deleteFilter="deleteFilter()"></filter-date>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <!-- .Side bar -->
      <!-- Main -->
      <v-col cols="12" md="10">
        <v-row>
          <v-col :class="[mobile ? 'py-0 my-0' : '']" md="6">
            <v-btn class="ml-2 rounded-15" outlined color="primary" v-if="
              notificationSelected.length > 0 &&
              notificationListChosen === 0 &&
              selectAll
            " @click="confirmed()">
              {{ notificationSelectedLength + dataTableConfig.counterText }}
            </v-btn>
            <v-btn class="ml-2 rounded-15" outlined color="primary" v-if="
              notificationItemSelected.length > 0 &&
              notificationListChosen === 0 &&
              !selectAll
            " @click="itemConfirmed()">
              {{ notificationItemSelectedLength + dataTableConfig.counterText }}
            </v-btn>
          </v-col>
          <v-col class="text-right" md="6" v-if="!mobile">
            <template>
              <div>
                <sort-by :sort="filterBy.sort" :sortByOld="noticeConfig.SORT_BY_OLD"
                  :sortByNew="noticeConfig.SORT_BY_NEW" @sortBy="sortBy($event)"></sort-by>
                <span class="ma-1"></span>
                <filter-date :inputDate="filterDateChosen" @changeDate="filterDate($event)"
                  @deleteFilter="deleteFilter()"></filter-date>
              </div>
            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" :class="{
            'height-custom-pc': $vuetify.breakpoint.md,
            'height-custom': !$vuetify.breakpoint.md && !isShowSelected(),
            'height-custom-selected':
              !$vuetify.breakpoint.md && isShowSelected(),
          }" class="pb-2">
            <v-data-table class="elevation-1 h-100" height="100%" v-model="notificationSelected" fixed-header
              item-key="status_id" :headers="dataTableConfig.headers"
              :items-per-page="dataTableConfig.options.itemsPerPage" :items="notificationList"
              :show-select="notificationListChosen === noticeConfig.UNCONFIRMED" :no-data-text="
                isFilter
                  ? dataTableConfig.noDataSeachText
                  : dataTableConfig.noDataText
              " :options.sync="dataTableConfig.options" :server-items-length="dataTableConfig.total" :footer-props="{
                'items-per-page-options': dataTableConfig.itemsPerPageOptions,
              }" @update:options="optionsChanged" mobile-breakpoint="0">
              <template v-slot:[`header.data-table-select`]="{}">
                <v-checkbox :input-value="notificationSelected.length > 0" :indeterminate="isSelectItem"
                  :color="toggleColor" hide-details @change="toggleSelectAll($event)"></v-checkbox>
              </template>

              <template v-slot:[`item.data-table-select`]="{ item, isSelected }">
                <v-checkbox :disabled="!isCanMark(item)" :input-value="isCanMark(item) && isCheck ? isSelected : null"
                  @change="select($event, item)" hide-details></v-checkbox>
              </template>
              <template v-slot:[`item.subject`]="{ item }">
                <a class="subject-name dt-comment text-decoration-none" :href="moveTo(item)">{{ item.subject_format
                }}</a>
              </template>

              <template v-slot:[`item.comment`]="{ item }">
                <td class="dt-comment">
                  <div v-if="
                    item.tasks &&
                    item.tasks.length > 0 &&
                    item.comment_notice_id
                  ">
                    <div v-for="(p, i) in item.tasks" :key="i">
                      【{{ p.name }}<span class="ml-3">{{ p.detail }}】</span>
                    </div>
                  </div>
                  <div>{{ item.comment }}</div>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <!-- .Main -->
    </v-row>
  </v-container>
</template>
<script>
import SortBy from "./modal/SortBy.vue";
import FilterDate from "./modal/FilterDate.vue";
import axios from "axios";
import { NOTIFICATION_API } from "../../constants/api";
import { FC501, FC502 } from "../../constants/fc";
import { apiErrorMessages } from "../../helpers/messages";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { mapGetters } from "vuex";
import { formatDateTime, parseDateTime } from "../../helpers/datetime-helpers";
import {
  HHmm2,
  MMddEEEEE,
  yyyyMMdd1,
  yyyyMMddHHmmss3,
} from "../../constants/df";
import { debounce_leading, deepClone, isEmpty } from "@/helpers/helpers";
import { taskMixin } from "@/mixins/task.js";
import { queryRouterMixin } from "@/mixins/queryRouter.js";

export default {
  components: {
    SortBy,
    FilterDate,
  },
  mixins: [taskMixin, queryRouterMixin],
  data: () => ({
    notificationType: ["未確認", "確認済み"],
    notificationListChosen: 0,
    notificationSelected: [],
    notificationSelectedLength: 0,
    notificationItemSelected: [],
    notificationItemSelectedLength: 0,
    selectAll: false,
    selectedList: [],
    isCheck: true,
    isSelectItem: false,
    selectedListOriginal: [],
    dataTableConfig: {
      noDataText: "お知らせは現在ありません。",
      noDataSeachText: "検索条件に一致するお知らせは見つかりませんでした。",
      counterText: "個のお知らせを確認済みにする",
      headers: [
        {
          text: "対象",
          value: "subject",
          sortable: false,
          width: "290px",
          class: "m250",
        },
        {
          text: "内容",
          value: "comment",
          sortable: false,
          class: "dt-comment",
        },
        {
          text: "名前",
          value: "process_user_name",
          sortable: false,
          width: "200px",
        },
        {
          text: "日時",
          value: "updated_at_format",
          sortable: false,
          width: "100px",
        },
      ],
      itemsPerPageOptions: [50],
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        pageStart: 1,
      },
    },
    noticeConfig: {
      UNCONFIRMED: 0,
      UNCONFIRMED_TEXT: "未確認",
      CONFIRMED: 1,
      CONFIRMED_TEXT: "確認済み",
      SORT_BY_OLD: "old",
      SORT_BY_NEW: "new",
    },
    notificationList: [],
    filterDateChosen: "",
    filterBy: {
      searchText: "",
      date: [],
      sort: "new",
    },
    isFilter: false,
    queryData: {},
    selectedItem: 0,
  }),

  mounted() {
    this.notificationListChosen = this.$route.query.type
      ? +this.$route.query.type
      : this.noticeConfig.UNCONFIRMED;
    this.selectedItem = this.notificationListChosen;
    this.queryData = this.setQuery(this.$route.query);
    this.resetFilterIgnore();
  },

  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("notification", ["getCount"]),
    mobile() {
      return ["sm", "xs"].includes(this.$vuetify.breakpoint.name);
    },
    toggleColor() {
      if (!this.isSelectItem) return "#1976d2";
      else return "gray";
    },
  },

  watch: {
    notificationItemSelectedLength: {
      immediate: true,
      handler(val) {
        if (
          (val !== 0 || this.notificationSelectedLength !== 0) &&
          this.notificationSelectedLength !== val
        ) {
          if (
            this.selectedListOriginal.length > 0 &&
            this.selectedListOriginal.length === val
          ) {
            this.isSelectItem = false;
          } else {
            this.isSelectItem = true;
          }
        } else {
          this.isSelectItem = false;
        }
      },
    },

    notificationSelectedLength: {
      immediate: true,
      handler(val) {
        if (
          (this.notificationItemSelectedLength !== 0 || val !== 0) &&
          val !== this.notificationItemSelectedLength
        ) {
          if (
            this.selectedListOriginal.length > 0 &&
            this.selectedListOriginal.length === val
          ) {
            this.isSelectItem = false;
          } else {
            this.isSelectItem = true;
          }
        } else {
          this.isSelectItem = false;
        }
      },
    },
  },

  methods: {
    changeList: debounce_leading(function (i) {
      this.notificationListChosen = i;
      this.isFilter = false;
      const setQueryType = this.checkExistQueryString(
        this.$route.query.type,
        `${this.notificationListChosen}`
      );
      if (setQueryType) {
        this.queryData["type"] = this.notificationListChosen;
        this.setQueryRouter();
      }
      this.resetFilterIgnore();
      this.getNotificationList();
    }, 500),

    editLink(type) {
      let routeData = this.$router.resolve({
        path: "/notification",
        query: { type: type }
      });
      return routeData.href;
    },

    changeListItemClass(type) {
      let selected = this.$route.query.type
        ? +this.$route.query.type
        : this.noticeConfig.UNCONFIRMED;
      if (selected == type) {
        return 'active-class';
      }
      else {
        return 'inactive-class';
      }
    },

    disabledLink(type) {
      let selected = this.$route.query.type
        ? +this.$route.query.type
        : this.noticeConfig.UNCONFIRMED;

      if (selected == type) {
        return true;
      }
      return false;
    },

    search: debounce_leading(function () {
      this.isFilter = true;
      this.resetFilterIgnore("searchText");
      this.getNotificationList();
    }),

    sortBy(type) {
      this.isFilter = false;
      this.filterBy.sort = type;

      const setQuerySortBy = this.checkExistQueryString(
        this.$route.query.sort,
        this.filterBy.sort
      );
      if (setQuerySortBy) {
        this.queryData["sort"] = this.filterBy.sort;
        this.setQueryRouter();
      }

      this.resetFilterIgnore("sort");
      this.getNotificationList();
    },

    filterDate(date) {
      this.isFilter = true;
      this.filterDateChosen = date;
      this.filterBy.date = formatDateTime(this.filterDateChosen, yyyyMMdd1);

      const setQueryDate = this.checkExistQueryString(
        this.$route.query.date,
        this.filterBy.date
      );
      if (setQueryDate) {
        this.queryData["date"] = this.filterBy.date;
        this.setQueryRouter();
      }
      this.resetFilterIgnore("date");
      this.getNotificationList();
    },

    setQueryRouter() {
      this.$router.replace({
        path: "/notification",
        query: this.queryData,
      });
    },

    async deleteFilter() {
      this.isFilter = false;
      this.filterDateChosen = "";
      this.filterBy.date = null;

      if (this.queryData["date"]) {
        delete this.queryData.date;
        this.setQueryRouter();
      }
      this.getNotificationList();
    },

    // Call Api notification
    async getNotificationList() {
      this.notificationItemSelected = []; // reset checkbox checked
      this.notificationItemSelectedLength = 0;
      this.notificationSelected = []; // reset checkbox checked
      this.notificationSelectedLength = 0;
      this.selectedList = [];
      this.selectedListOriginal = [];
      this.selectAll = false;
      this.isSelectItem = false;

      try {
        let { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: this.notificationListChosen,
            limit: this.dataTableConfig.options.itemsPerPage,
            offset:
              (this.dataTableConfig.options.page - 1) *
              this.dataTableConfig.options.itemsPerPage,
            sort: this.filterBy.sort,
            date: this.filterBy.date,
            comment: this.filterBy.searchText,
          },
        });
        if (status === STATUS_NO_CONTENT) {
          this.notificationList = [];
          this.dataTableConfig.total = 0;
        } else {
          this.notificationList = this.customerList(data.result.notices);
          this.dataTableConfig.total = data.result.total_count;
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      } finally {
        document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollTop = 0;
        document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollLeft = 0;
      }
    },

    // Customer data for list
    customerList(list) {
      list.map((item) => {
        item.updated_at_format = this.customerFormatDateTime(item.updated_at);
        if (item.actual_id || item.schedule_id){
          item.subject_format = item.subject
          ? this.formatSubject(item.subject)
          : "";
        } else{
          item.subject_format = item.subject
        }
      });

      return list;
    },

    // Format dateTime for list (dateTime : yyyyMMddHHmmss)
    customerFormatDateTime(dateTime) {
      let now = formatDateTime(new Date(), yyyyMMdd1);
      let compareDate = formatDateTime(
        parseDateTime(dateTime, yyyyMMddHHmmss3),
        yyyyMMdd1
      );
      let formatText = MMddEEEEE;

      // Compare date by date
      if (now == compareDate) {
        formatText = HHmm2;
      }
      return formatDateTime(
        parseDateTime(dateTime, yyyyMMddHHmmss3),
        formatText
      );
    },

    optionsChanged() {
      if (this.$route.query.type) {
        this.notificationListChosen = +this.$route.query.type;
      }
      if (this.$route.query.sort) {
        this.filterBy.sort = this.$route.query.sort;
      }
      if (this.$route.query.date) {
        this.filterBy.date = this.$route.query.date;
      }

      this.getNotificationList();
    },

    // Call Api confirm all notice
    async confirmed() {
      let confirmedList = [];
      this.selectedList.forEach((item) => {
        confirmedList.push({
          status_notice_id: item.status_notice_id || null,
          comment_notice_id: item.comment_notice_id || null,
          is_verified: 1,
        });
      });

      await this.apiConfirmedNotice(confirmedList);

      this.getNotificationList();
    },

    // Call Api confirm notice
    async itemConfirmed() {
      let confirmedList = [];
      this.notificationItemSelected.forEach((item) => {
        confirmedList.push({
          status_notice_id: item.status_notice_id || null,
          comment_notice_id: item.comment_notice_id || null,
          is_verified: 1,
        });
      });

      await this.apiConfirmedNotice(confirmedList);
      this.isCheck = false;
      this.getNotificationList();
    },

    async apiConfirmedNotice(notices) {
      try {
        await axios.put(NOTIFICATION_API(), { notices: notices });
        this.$store.dispatch(
          "notification/setCount",
          this.getCount - notices.length
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    // Redirect when clicked
    async redirectTo(item) {
      // Confirm this notice
      if (this.isCanMark(item)) {
        await this.apiConfirmedNotice([
          {
            status_notice_id: item.status_notice_id || null,
            comment_notice_id: item.comment_notice_id || null,
            is_verified: 1,
          },
        ]);
        this.$store.dispatch("notification/setCount", this.getCount);
      }

      this.show = false;
    },
    moveTo(item) {

      // Redicrect
      const statusNotice = !item.comment_notice_id;
      let query = {};
      if (this.isCanMark(item)) {
        query["is_verified"] = "1";
        if (item.status_notice_id) {
          query["status_notice_id"] = item.status_notice_id;
        }
        if (item.comment_notice_id) {
          query["comment_notice_id"] = item.comment_notice_id;
        }
      }

      let router = null;
      if (statusNotice && item.schedule_id && isEmpty(item.actual_id)){
        query["fromNotice"] = 1;
        router = this.$router.resolve({
          path: `/schedule/${item.schedule_id}`,
          query: this.setQuery(query),
        });

      } else if (statusNotice && item.actual_id) {
        query["sid"] = item.schedule_id;
        query["fromNotice"] = 1;
        router = this.$router.resolve({
          path: `/actual/${item.actual_id}`,
          query: this.setQuery(query),
        });

      } else if (statusNotice && item.schedule_month_id && isEmpty(item.actual_month_id)) {
        router = this.$router.resolve({
          path: `/monthly-schedules?user_id=${item.target_user_id}&target_month=${item.schedule_month}`,
          query: this.setQuery(query),
        });

      } else if (statusNotice && item.actual_month_id) {
        router = this.$router.resolve({
          path: `/monthly-actuals?user_id=${item.target_user_id}&target_month=${item.schedule_month}`,
          query: this.setQuery(query),
        });

      } else if (!statusNotice && item.schedule_id && isEmpty(item.actual_id)) {
        query["taskId"] = item.schedule_task_id;
        router = this.$router.resolve({
          path: `/schedule/${item.schedule_id}`,
          query: this.setQuery(query),
        });

      } else if (!statusNotice && item.actual_id) {
        query["sid"] = item.schedule_id;
        query["taskId"] = item.actual_task_id;
        router = this.$router.resolve({
          path: `/actual/${item.actual_id}`,
          query: this.setQuery(query),
        });

      } else {
        ///
      }

      if (router) {
        return router.href;
      }
      return router;
    },

    resetFilterIgnore(field = "") {
      this.dataTableConfig.options.pageStart = 1;
      this.dataTableConfig.options.page = 1;
      switch (field) {
        case "searchText":
          this.filterBy.sort = this.noticeConfig.SORT_BY_NEW;
          this.filterDateChosen = "";
          this.filterDateChosen
            ? formatDateTime(this.filterDateChosen, yyyyMMdd1)
            : "";
          break;
        case "sort":
          break;
        case "date":
          this.filterBy.searchText = "";
          this.filterBy.sort = this.noticeConfig.SORT_BY_NEW;
          break;
        default:
          this.filterBy.searchText = "";
          this.filterBy.sort =
            this.$route.query.sort || this.noticeConfig.SORT_BY_NEW;
          this.filterDateChosen = this.$route.query.date || "";
          this.filterBy.date = this.filterDateChosen
            ? formatDateTime(this.filterDateChosen, yyyyMMdd1)
            : "";
          break;
      }
    },

    toggleSelectAll(event) {
      this.notificationSelected = [];
      this.selectedList = [];
      if (event && this.notificationList.length > 0) {
        this.selectAll = true;
        this.isCheck = true;
        this.notificationList.forEach((item) => {
          if (this.isCanMark(item)) {
            this.notificationSelected.push(item);
          }
        });
        this.notificationSelectedLength =
          this.notificationSelected && this.notificationSelected.length;
        this.selectedList = deepClone(this.notificationSelected);
        this.selectedListOriginal = deepClone(this.notificationSelected);
      } else {
        this.selectAll = false;
        this.notificationSelected = [];
        this.selectedList = [];
        this.selectedListOriginal = [];
        this.notificationItemSelected = [];
        this.notificationSelectedLength = 0;
        this.notificationItemSelectedLength = 0;
      }
    },

    select(event, item) {
      if (!item) return;
      this.isCheck = true;
      if (this.selectAll) {
        if (!event) {
          this.selectedList = this.selectedList.filter(
            (x) =>
              x.comment_id !== item.comment_id || x.status_id !== item.status_id
          );
        } else {
          this.selectedList.push(item);
        }
        this.notificationSelectedLength =
          this.selectedList && this.selectedList.length;
      } else {
        if (event) {
          this.notificationItemSelected.push(item);
        } else {
          this.notificationItemSelected = this.notificationItemSelected.filter(
            (x) =>
              x.comment_id !== item.comment_id || x.status_id !== item.status_id
          );
        }
        this.notificationItemSelectedLength =
          this.notificationItemSelected && this.notificationItemSelected.length;
      }
    },

    // Notify can mark read
    isCanMark(item) {
      // return !!item.can_verified && item.status_notice_id
      return !!item.can_verified;
    },

    isShowSelected() {
      return (
        this.notificationListChosen === 0 &&
        ((this.notificationSelected.length > 0 && this.selectAll) ||
          (this.notificationItemSelected.length > 0 && !this.selectAll))
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-data-table .v-data-footer {
  display: none;
}

.rounded-15 {
  border-radius: 15px;
}

::v-deep .m250 {
  min-width: 250px !important;
}

.subject-name {
  color: #007bff !important;
  max-width: 900px;
  overflow-wrap: break-word;
}

.subject-name:hover {
  cursor: pointer;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}

::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}

::v-deep .dt-comment {
  min-width: 200px;
  word-break: break-word;
}

.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 64px - 12px);
}

.height-custom-selected {
  height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 64px - 36px);
}

.height-custom-pc {
  height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 64px - 36px - 12px);
}

.h-100 {
  height: calc(100% - 36px);
}

.active-class {
  color: #1976d2;
  background-color: #1976d212;
}

.inactive-class {
  color: rgb(0, 0, 0);
}

a.inactive-class:hover {
  background: rgba(0, 0, 0, 0.12);
}

.v-list-item--link:before {
  background-color: transparent;
}
</style>
