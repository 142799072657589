<template>
  <v-card color="grey lighten-4" flat tile>
    <v-toolbar>
      <v-app-bar-nav-icon @click="$emit('click')">
        <v-icon small>$hamburgermenu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-breadcrumbs large :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon small>$bradcrumbtrail</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <a
              class="font-weight-bold black--text text-decoration-none"
              :class="[$vuetify.breakpoint.mobile ? 'title-size' : '']"
              :href="item.href"
              :disabled="item.disabled"
            >
              {{ item.text }}
            </a>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="isCalendar">
        <div>
          <v-btn
            v-if="isSchedule"
            text
            class="ml-auto"
            @click="toggleScheduleExpand()"
            small
          >
            <v-icon v-if="isScheduleExpand !== 0"> mdi-chevron-down </v-icon>
            <v-icon v-else> mdi-chevron-up </v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            class="ml-auto"
            @click="toggleActualExpand()"
            small
          >
            <v-icon v-if="isActualExpand !== 0"> mdi-chevron-down </v-icon>
            <v-icon v-else> mdi-chevron-up </v-icon>
          </v-btn>
        </div>
      </template>

      <v-menu
        content-class="pdf"
        bottom
        offset-y
        left
        v-if="!$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-4" icon v-bind="attrs" v-on="on">
            <v-icon small>$tool</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="print()">
            <v-list-item-title>印刷</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <Notification ref="notifications"></Notification>

      <v-menu bottom offset-y left rounded="custom">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" color="grey">
            <v-img :src="profileImage"></v-img>
          </v-avatar>
        </template>

        <v-card class="mx-auto rounded-b-xl rounded-t-xl pa-5" max-width="344">
          <div style="text-align: center" justify="center">
            <v-avatar class="profile" color="grey" size="100">
              <v-img :src="profileImage"></v-img>
            </v-avatar>
          </div>

          <div style="text-align: center" justify="center">
            <h3>{{ profile.name }}</h3>
            <span>{{ profile.mail }} </span>
          </div>

          <div style="text-align: center" justify="center" class="mt-5">
            <v-btn @click="$router.push({ name: 'profile' })" text>
              プロフィール情報を見る
            </v-btn>
          </div>
          <div style="text-align: center" justify="center" class="mt-15">
            <v-btn @click="logout" text c> ログアウト </v-btn>
          </div>
        </v-card>
      </v-menu>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOGOUT_API,
  GET_PROFILE_IMAGE_API,
  GET_PROFILE_API,
  NOTIFICATION_API,
} from "../constants/api";
import { isEmpty } from "../helpers/helpers";
import axios from "axios";
import { apiErrorMessages } from "../helpers/messages";
import { FC02, FC1001, FC101, FC501 } from "../constants/fc";
import { blobToBase64 } from "../helpers/helpers";
import { STATUS_NO_CONTENT } from "../constants/status";
import Notification from "./Notification.vue";

const DEFAULT_IMAGE = "/assets/images/account_for_no_image.png";
export default {
  components: { Notification },
  name: "Navbar",
  data: () => ({
    profileImage: "",
    profile: {},
    notificationUnconfirmList: [],
    isCalendar: false,
    isSchedule: false,
    breadcrumbs: [],
    breadcrumbsList : [
      {
        text : '日次実績',
        name: 'actual',
      },
      {
        text : '日次実績',
        name: 'my-actual',
      },
      {
        name: 'my-plan',
        text : '日次予定',
      },
      {
        name: 'plan',
        text : '日次予定',
      },
      {
        name : 'tasks',
        text : '標準工数一覧',
      },
      {
        name : 'notification',
        text : 'お知らせ一覧',
      },
      {
        name : 'profile',
        text : 'プロフィール情報',
      },
      {
        name : 'productivity',
        text : '各種レポート',
      },
      {
        name : 'department',
        text : '部署・Gr一覧',
      },
      {
        name : 'profiles',
        text : 'プロフィール一覧',
      },
      {
        name : 'categories',
        text : '業務カテゴリ一覧',
      },
      {
        name : 'status-logs',
        text : '初回提出・承認日時一覧',
      },
      {
        name : 'user-skills',
        text : 'ビギナー・スタンダード・エキスパート管理',
      },
      {
        name : 'weekly-schedules',
        text : '週次予定',
      },
      {
        name : 'weekly-actuals',
        text : '週次実績',
      },
      {
        name : 'status-schedules',
        text : '予定　提出・承認状況一覧',
      },
      {
        name : 'status-actuals',
        text : '実績　提出・承認状況一覧',
      },
      {
        name : 'monthly-schedules',
        text : '月次予定',
      },
      {
        name : 'monthly-actuals',
        text : '月次実績',
      },
    ]
  }),

  watch: {
    deep: true,
    loginInfo: {
      async handler(val) {
        if (isEmpty(val) || isEmpty(this.profile)) return;
        if (val.file_id && val.file_id !== this.profile.file_id);
        {
          this.profile.file_id = val.file_id;
          await this.getImage();
        }
      },
    },
    $route() {
      this.setScheduleExpand()
      this.setBreadcrumbs()
    },
  },

  async created() {
    await this.getProfile();
    await this.getImage();
    await this.getNotification();
    this.setScheduleExpand()
    this.setBreadcrumbs()
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    ...mapGetters("common", [
      "loginInfo",
      "isScheduleExpand",
      "isActualExpand",
    ]),
  },

  methods: {
    toggleScheduleExpand() {
      this.$store.dispatch(
        "common/isScheduleExpand",
        this.isScheduleExpand === 0 ? 1 : 0
      );
    },
    toggleActualExpand() {
      this.$store.dispatch(
        "common/isActualExpand",
        this.isActualExpand === 0 ? 1 : 0
      );
    },
    async logout() {
      let label =  this.$t("label.confirm.logout");
      let button = this.$t("label.buttons.logout");

      // 月次予定ログアウトの文言を設定
      if (this.$root.$refs.monthlySchedulesComponent) {
        if (this.$root.$refs.monthlySchedulesComponent.storageFrag) {
          label = this.$t("label.confirm.logout_no_save");
          button = this.$t("label.buttons.ok");
        }
      }

      // 月次実績ログアウトの文言を設定
      if (this.$root.$refs.monthlyActualsComponent) {
        if (this.$root.$refs.monthlyActualsComponent.storageFrag) {
          label = this.$t("label.confirm.logout_no_save");
          button = this.$t("label.buttons.ok");
        }
      }

      this.$confirm(label, {
        buttons: {
          ok: {
            text: button,
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
      })
        .then(async () => {
          // Log out the user.
          try {
            await axios.post(LOGOUT_API(), null, {
              headers: { "Content-Type": "application/json" },
            });
            this.$store.dispatch("auth/logout");
            this.$store.dispatch("common/removeLoginInfo");
            this.$store.dispatch("common/removeSelectMember");

            // Redirect to login.
            this.$router.push({ name: "login" });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC02, status);
          }
        })
        .catch(() => {});
    },

    async getProfile() {
      try {
        const { data } = await axios.get(GET_PROFILE_API());
        this.profile = data.result;
        this.$store.dispatch("common/saveLoginInfo", {
          loginInfo: data.result,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC101, status);
      }
    },
    async getNotification() {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new",
          },
        });
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    },

    async getImage() {
      try {
        if (isEmpty(this.profile.file_id)) {
          this.profileImage = DEFAULT_IMAGE;
        } else {
          const { data, status } = await axios.get(
            GET_PROFILE_IMAGE_API(this.profile.file_id),
            {
              responseType: "blob",
            }
          );

          this.profileImage =
            status === STATUS_NO_CONTENT
              ? DEFAULT_IMAGE
              : await blobToBase64(data);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC1001, status);
      }
    },
    print() {
      if(['monthly-schedules'].includes(this.$route.name) || ['monthly-actuals'].includes(this.$route.name)){
        this.$root.$emit('printStarted');
        setTimeout(() => {
          window.print();
        }, 1000);
      }else window.print();
    },
    setBreadcrumbs(){
      this.breadcrumbs = []
      this.breadcrumbsList.forEach(item => {
        if (item.name === this.$route.name) {
          this.breadcrumbs = [
            {
              text: "",
              href: "",
              disabled: true,
            },
            {
              text: item.text,
              href: "",
              disabled: true,
            },
          ]
        }
      })
    },
    setScheduleExpand() {
      this.isCalendar = [
        'actual', 'my-actual', 'plan', 'my-plan', 'weekly-schedules', 'weekly-actuals', 'monthly-schedules', 'monthly-actuals'
      ].includes(this.$route.name)
      this.isSchedule = ['plan', 'my-plan', 'weekly-schedules', 'monthly-schedules', 'monthly-actuals'].includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.rounded-custom {
  border-radius: 37px;
}
.v-toolbar__title {
  flex-grow: 1;
}
.v-breadcrumbs {
  a {
    max-width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (min-width: 767px) {
  .is-menu-collapsed {
    .v-toolbar__title {
      margin-left: 200px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .v-toolbar__title {
    padding-left: 0px !important;
  }
  .title-size {
    font-size: 1.175rem
  }
}
</style>
