<template>
  <v-navigation-drawer right width="auto" class="MonthlyRemandTaskMain">
    <v-list-item class="px-2 mt-4 title-container">
      <v-row>
        <v-col cols="12" class="icon-title">
          <v-btn icon>
            <v-icon large>$remandtask</v-icon>
          </v-btn>
          <div class="title">月次予定の差し戻し</div>
        </v-col>
      </v-row>
    </v-list-item>
    
    <div class="scroll">
      <div class="checklist-container">
        <ul>
          <div>
            <div class="comment-title">▼全体に対する差し戻し</div>
          </div>
        </ul>
      </div>
      <div class="comment-container">
        <v-textarea
          v-model="remandTask.remand_comment"
          class="comment"
          clearable
          no-resize
          clear-icon="mdi-close"
          placeholder="コメントを追加"
          rows="4"
          maxlength="512"
          counter
          @input="setNewInput()" 
        ></v-textarea>
      </div>

      <template v-if="remandTask.individual_tasks_comments && remandTask.individual_tasks_comments.length !== 0">
        <div class="checklist-container">
          <ul>
            <div>
              <div class="comment-title">▼個別タスクに対する差し戻し</div>
            </div>
          </ul>
        </div>

        <template v-for="(individualTask) in remandTask.individual_tasks_comments">
          <div v-for="(ScheduleTask) in individualTask.ScheduleTaskMonth" :key="ScheduleTask.index_no">
            <ul>
              {{ ScheduleTask.index_no }}.{{ ScheduleTask.category_secondary_name }}_{{ ScheduleTask.name }}
              <div class="comment-container">
                <v-textarea
                  v-model="individualTask.comment"
                  class="comment"
                  clearable
                  no-resize
                  clear-icon="mdi-close"
                  placeholder="コメントを追加"
                  rows="1"
                  maxlength="512"
                  counter
                  @input="setNewInput()" 
                ></v-textarea>
              </div>
            </ul>
          </div>
        </template>
      </template>
    </div>
    <div class="button-container">
      <button @click="confirm()">キャンセル</button>
      <button class="bold" @click="submit(1)">下書きとして保存</button>
      <button @click="submit(0)">
        <v-icon class="blue--text">$return</v-icon>

        <span v-if="remandTask.individual_tasks_comments && remandTask.individual_tasks_comments.length !== 0">
          上記{{ remandTask.individual_tasks_comments.length }}件を
        </span>
        差し戻し
      </button>
    </div>
  </v-navigation-drawer>
</template>

<script>
import * as SystemConstant from "../../../constants/system";
export default {
  props: {
    remandTask: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newInput: false,
      originalcomment: null,
    };
  },
  updated() {
    if (!this.originalcomment) {
      this.originalcomment = this.remandTask;
    }
  },
  methods: {
    setNewInput() {
      this.newInput = false;

      if ((this.remandTask.remand_comment !== this.originalcomment.remand_comment
           || this.remandTask.remand_comment === this.originalcomment.remand_comment)
           && this.remandTask.remand_comment) {
        this.newInput = true;
      } else if (this.remandTask.individual_tasks_comments.length > 0) {
        const comments = this.remandTask.individual_tasks_comments.map(e => e.comment);

        if (this.originalcomment.individual_tasks_comments.length > 0) {
          const originalComments = this.originalcomment.individual_tasks_comments.map(e => e.comment);
          this.newInput = JSON.stringify(comments) !== JSON.stringify(originalComments);
        }
      } else {
        this.newInput = false;
      }
      
      this.remandTask = { ...this.remandTask };
    },
    confirm() {
      if(this.newInput){
        this.$confirm(this.$t("label.confirm.unsave"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.discard"),
              type: "primary",
            },
          },
        })
        .then(() => {
          this.close();
        })
        .catch(() => {});
      } else {
        this.close();
      }
    },
    close() {
      this.newInput = false;
      this.originalcomment = null;
      this.$emit("close");
    },
    submit(draft_flag) {
      const individual_tasks_comments = this.remandTask.individual_tasks_comments.map(task => {
        const individual_tasks = {
          schedule_task_month_id: task.schedule_month_task_id,
          comment: task.comment, 
          draft_flag: draft_flag
        };
        if(task.individual_task_id){
          individual_tasks.individual_task_id = task.individual_task_id;
        }
        return individual_tasks
      }) ?? []

      let params = {
        phase_type: SystemConstant.PHASE_TYPE_REJECT,
        remand_comment: this.remandTask.remand_comment,
        draft_flag: draft_flag,
        individual_tasks_comments : individual_tasks_comments
      };
      this.newInput = false;
      this.originalcomment = null;
      this.$emit("submit", params);
    },
  }
};
</script>

<style>
.MonthlyRemandTaskMain {
  font-size: 14px;
  padding: 0 10px;
}
.MonthlyRemandTaskMain .title {
  font-size: 16px;
}
.MonthlyRemandTaskMain .title-container {
  margin-bottom: 15px;
}
.MonthlyRemandTaskMain .icon-title {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.MonthlyRemandTaskMain .container {
  display: flex;
  color: #7F7F7F;
  column-gap: 15px;
}
.MonthlyRemandTaskMain .body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.MonthlyRemandTaskMain .comment-title {
  color: black;
  font-weight: 700;
}
.MonthlyRemandTaskMain .user {
  display: flex;
  flex-direction: column;
  color: black;
}
.MonthlyRemandTaskMain .comment {
  margin-left: 24px;
  resize: none;
}
.MonthlyRemandTaskMain .comment-container {
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.MonthlyRemandTaskMain .button-container {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
}
.MonthlyRemandTaskMain .button-container > button {
  border: solid 1px #7F7F7F;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 12px;
}
.MonthlyRemandTaskMain .bold {
  font-weight: 700;
}
.MonthlyRemandTaskMain .scroll {
  height: calc(
    (var(--vh, 1vh) * 100) - 64px - 72px - 60px - 48px - 30px
  );
  overflow-x: hidden;
}
</style>
