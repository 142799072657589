<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="sadbTask"
    v-dialog-drag="{
      dialog: 'sadbTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card class="hidden" :id="parentId">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="copyLink()"
              class="ml-auto"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$link</v-icon>
            </v-btn>
          </template>
          <span>タスクのリンクをコピー</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && archiveTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$archive</v-icon>
            </v-btn>
          </template>
          <span>タスクをアーカイブ</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && duplicateTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$copy</v-icon>
            </v-btn>
          </template>
          <span>タスクを複製</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && deleteTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
          <span>タスクを削除</span>
        </v-tooltip>

        <v-btn @click="close()" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-0 my-0">
          <span class="ml-9 mb-n4 mt-1">
            <span v-if="task.category_secondary_name">
              {{ task.category_secondary_name }}
            </span>
            <span v-else-if="!task.category_primary_id">
              {{ "未分類" }}
            </span>
          </span>
          <v-col cols="12" class="py-0 d-flex align-center">
            <div v-if="isMonthlyTask()">
              <v-icon small>$favoriteinactive</v-icon>
            </div>
            <div v-else @click="setFavorite(task)">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <span class="py-4 ml-2 work-break">{{ task.name }}</span>
            <div v-if="!isSupervisor()" class="detailText">
              <v-text-field
                maxlength="100"
                v-model="task.detail"
                placeholder="タスク詳細を追加"
              ></v-text-field>
            </div>
            <span v-else :class="[{ detailText: !isDetailEmpty() }]">{{
              task.detail
            }}</span>
          </v-col>
          <v-col cols="12" :class="[isSupervisor() ? 'py-2 my-0' : 'py-0 my-0']">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row class="py-0 my-2">
          <v-col class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
        </v-row>

        <v-row v-if="!isSupervisor() && !isMonthlyTask() && isChangeCategory() && (isSplitTask() || isStandardTask())" class="py-0 my-0">
          <v-col class="py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskcategory</v-icon>
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="primaryName || '大カテゴリ名'"
              :items="parentCategories"
              :type="'text'"
              :has-total="false"
              :showMenu="!isSupervisor()"
              @dropdownSelected="primaryCategoryChange($event)"
            ></dropdown-button>
            <div class="mt-2 ml-4" ref="popupContainer">
              <v-btn 
                text color="ligth"
                class="pt-0 transform"
                v-bind="task.category_secondary_id"
                @click="changeShowPopup()"
              >
                <span>{{ task.category_secondary_name || '小カテゴリ名'}}</span>
                <v-icon large right>mdi-menu-down</v-icon>
              </v-btn>
              <category-pulldown v-if="isPulldownopen" class="category-pulldown"> 
                <div class="add-small-category">
                  <v-text-field
                    v-model="filteredKey"
                    placeholder="小カテゴリ名検索"
                    prepend-inner-icon="mdi-magnify"
                    variant="solo"
                    clearable
                    maxlength="100"
                  />
                </div>
                <div
                  class="add-small-category"
                  @click="newCategory(task.category_primary_id)"
                >
                  +新しい小カテゴリを作成する
                </div>
                <div
                  v-for="(item, index) in secondariesFilter(filteredKey)" :key="index"
                  class="category-add"
                  @click="secondaryCategoryChange(item)"
                  @mouseover="showPulldownIcon(item)"
                  @mouseleave="hidePulldownIcon()"
                >
                  <span>{{item.name}}</span>
                  <span
                    v-if="collectPulldownIcon(item , isPulldownIcon) && item.user_id"
                    class="pulldown-right-element"
                  >
                  <v-icon
                    small class="blue--text"
                    @click="editCategory($event, item)"
                  > 
                    $edit 
                  </v-icon>
                  <v-icon
                    small class="blue--text"
                    @click="deleteCategory($event, item)"
                  > 
                    $delete 
                  </v-icon>
                  </span>
                </div>
              </category-pulldown>
              <div
                v-if="
                  !this.$v.task.category_secondary_id.required &&
                  this.$v.task.category_secondary_id.$dirty
                "
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        
        <v-row v-else class="py-0">
          <v-col>
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="mx-2">{{ task.category_primary_name }}</span>
            </div>
            <div class="mt-2 ml-5 category">
              <span class="mx-2">{{ task.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="!isSupervisor() && !displaySplit" class="py-0">
            <v-row class="py-0 my-0 align-baseline">
              <v-col cols="3" class="py-0 align-self-start mt-5">
                <span style="margin-left: -16px" class="red--text mr-2">*</span>
                <v-icon small>$taskworktime</v-icon>
                <span class="ml-2">日次予定</span>
              </v-col>
              <v-col cols="9" class="py-0 d-flex align-start align-self-start">
                <span
                  class="w-95 align-self-start mt-5"
                  :class="[$vuetify.breakpoint.mobile ? 'pl-0' : '']"
                  >{{ task.cost }}m/回</span
                >
                <span class="mx-2 mt-5">×</span>
                <v-text-field
                  suffix="回"
                  type="number"
                  max="999"
                  min="1"
                  :error-messages="countErrors"
                  :readonly="isSupervisor()"
                  v-model="task.count"
                  @keydown="checkMaxLength('count', $event)"
                  @input.native="updateCount"
                  @input="changeCount()"
                  class="w-60"
                >
                </v-text-field>
                <span class="mx-2 mt-5">=</span>
                <v-text-field
                  readonly
                  class="w-90"
                  type="number"
                  :value="task.cost * task.count"
                  suffix="m"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0 my-0 align-center">
                <div class="ml-7 ">
                  <span>※標準工数/回の業務量定義（件数など）：</span>
                  <span class="work-break">{{ task.unit || '' }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="!isSupervisor() && displaySplit" class="py-0">
            <div class="d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次予定</span>
              <span class="ml-10 align-baseline">
                <v-text-field
                  class="w-130"
                  type="number"
                  max="999"
                  min="1"
                  :error-messages="costErrors"
                  @keydown="checkMaxLength('cost', $event)"
                  @input.native="updateCost"
                  @input="changeCost()"
                  v-model="task.cost"
                  suffix="m"
                ></v-text-field>
              </span>
            </div>
            <div class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost || 0 }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost || originalTask.cost }} m</span>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
          <v-col
            class="align-center py-2"
            v-if="isSupervisor() && !displaySplit"
          >
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次予定</span>
              <div class="text-number ml-2 d-flex align-center">
                <span>{{ task.cost }} m/回</span>
                <span class="mx-2">×</span>
                <span>{{ task.count }} 回</span>
                <span class="mx-2">=</span>
                <span>{{ task.cost * task.count }} m</span>
              </div>
            </div>

            <div class="mt-1 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
          <v-col
            class="align-center py-2"
            v-if="isSupervisor() && displaySplit"
          >
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次予定</span>
              <div class="text-number ml-7">
                <span>{{ task.cost * task.count }} m</span>
              </div>
            </div>
            <div class="d-flex mt-2">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost }} m</span>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0 d-flex align-center" v-if="!isSupervisor()">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <v-text-field
                maxlength="5"
                type="text"
                :readonly="isSupervisor()"
                :error-messages="startTimeErrors"
                @input="!isSupervisor() && changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
                v-model="task.start_time"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                maxlength="5"
                class="align-self-start"
                readonly
                :error-messages="endTimeErrors"
                type="text"
                v-model="task.end_time"
              ></v-text-field>
            </div>
          </v-col>
          <v-col v-else class="d-flex align-center">
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <span>{{ task.start_time }}</span>
              <span class="mx-3"> ~ </span>
              <span>{{ task.end_time }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <div class="d-flex align-center">
              <v-checkbox
                class="mt-0"
                hide-details
                :disabled="!canSplitCheck || +this.task.count !== 1"
                v-model="splitCheck"
                @change="updateSplitCheck"
              >
              </v-checkbox>
              <span class="mx-2"> タスクを分割実施（一部のみ実施） </span>
            </div>
            <div class="d-flex align-center">
              <span class="mx-2 ml-10"
                >※標準工数60分のうち30分作業する場合など</span
              >
            </div>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="py-0">
            <div class="header d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="ml-2">アウトプット</span>
              <v-checkbox
                class="ml-3"
                :label="!!task.is_need_output ? '必要' : '不要'"
                v-model="task.is_need_output"
                readonly
              ></v-checkbox>
            </div>
            <div v-if="!!task.is_need_output" class="ml-2 category">
              アウトプット内容： {{ task.output_detail }}
            </div>
          </v-col>
        </v-row> -->
        <!--<v-row>
          <v-col class="d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="task.tags.length === 0">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet>
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col v-if="!showComment" class="py-0">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>             
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!isEditing"
          tile
          class="ml-auto"
          color="primary"
          @click="isCanSubmit() && updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
      <AddSmallCategory
        :show="openNewCategory"
        :newLargeCategoryId="newLargeCategoryId"
        :userId="userId"
        :groupId="groupId.toString()"
        @secondaryCategoryCreated="categorySort()"
      ></AddSmallCategory>
      <UpdateSmallCategory
        :show="openEditCategory"
        :largeCategoryId="updateCategoryPrimaryId"
        :smallCategoryName="updateSecondaryCategoryName"
        :smallCategoryId="updateSecondaryCategoryId"
        :userId="userId"
        :groupId="groupId.toString()"
        @closeEvent="categorySort()"
      ></UpdateSmallCategory>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryPulldown from './CategoryPulldown.vue';
import AddSmallCategory from './AddSmallCategory.vue';
import UpdateSmallCategory from "./UpdateSmallCategory.vue";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import DropdownButton from "../../../components/DropdownButton.vue";
import { mapGetters } from "vuex";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { apiErrorMessages } from "../../../helpers/messages";
import {
  GET_SCHEDULE_TASK_API,
  CATEGORIES_API,
  PRIMARY_SUB_CATEGORY_API,
  DELETE_COMMENT_API,
  TODO_TASK_API,
  SPLIT_TASK_API,
  UPDATE_DB_TASKS_API,
  NOTIFICATION_API
} from "../../../constants/api";
import axios from "axios";
import {
  deepClone,
  copyToClipboard,
  isEmpty,
  debounce_leading,
  isEmptyNumber,
} from "../../../helpers/helpers";
import {
  formatDateTime,
  parseDateTime,
  formatTime,
  toMinute,
  toHHmm,
  toMinuteForHHmm,
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import {
  compareDateValidator,
  timeValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import {
  FC701,
  FC801,
  FC704,
  FC802,
  FC803,
  FC808,
  FC820,
  FC824,
  FC709,
  FC501,
  FC502
} from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";
export default {
  components: { 
    DropdownButton,
    CategoryPulldown,
    AddSmallCategory,
    UpdateSmallCategory,
  },
  directives: {
    "dialog-drag": DialogDragDirective,
  },

  props: {
    show: Boolean,
    planId: String,
    standarId: Number,
  },
  mixins: [validationMixin, backMixin, taskMixin],
  validations: {
    task: {
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id !== null;
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      filteredKey: "",
      parentId: "actionDatabaseTaskId",
      openDbTask: false,
      showComment: false,
      isEditing: false,
      userComment: "",
      task: {
        //tags: [],
        comments: [],
      },
      originalTask: {},
      splitCheck: false, // 標準タスク、フリータスクはfalse  最後の分割タスクもFalse
      originSplitCheck: false,
      canSplit: false,
      listNotification: [],
      parentCategories: [],
      childCategories:[],
      isPulldownopen: false,
      isPulldownIcon: false,
      openNewCategory: false,
      openEditCategory: false,
      updateCategoryPrimaryId: 0,
      updateSecondaryCategoryName: "",
      updateSecondaryCategoryId: 0,
      newLargeCategoryId:  0,
      userId: "",
      groupId: "",
    };
  },
  watch: {
    show: {
      async handler() {
        this.openDbTask = true;
        this.$v.$reset();
        this.task = {
          //tags: [],
          comments: [],
        };
        await this.getData();
        await this.getNotification();
        this.userComment = "";
        this.showComment = false;
      },
    },
    task: {
      deep: true,
      handler: function () {
        this.isEditing = this.compareValue();

        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler: function (value) {
        this.isEditing = value !== null || value !== "";
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    splitCheck: {
      handler: function () {
        this.isEditing = this.splitCheck !== this.originSplitCheck;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  methods: {
    secondariesFilter(filteredKey){
      let items = this.childCategories.filter(item => {
        if(filteredKey){
          if(item.name.includes(filteredKey)){
            return item
          }
        } else {
          return item
        }
      })
      return items;
    },
    clearFilteredKey(){
      this.filteredKey = "";
    },
    compareValue() {
      return (
        JSON.stringify({
          name: this.task.name,
          description: this.task.description,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          category_primary_id: this.task.category_primary_id,
          category_secondary_id: this.task.category_secondary_id,
          start_time: this.task.start_time,
          end_time: this.task.end_time,
          userComment: "",
          files: this.task.files,
          cost: this.task.cost,
          count: this.task.count,
          detail: this.task.detail,
          is_split_task_completed: this.task.is_split_task_completed,
        }) !==
        JSON.stringify({
          name: this.originalTask.name,
          description: this.originalTask.description,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          category_primary_id: this.originalTask.category_primary_id,
          category_secondary_id: this.originalTask.category_secondary_id,
          start_time: this.originalTask.start_time,
          end_time: this.originalTask.end_time,
          userComment: "",
          files: this.originalTask.files,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          detail: this.originalTask.detail,
          is_split_task_completed: this.originalTask.is_split_task_completed,
        })
      );
    },

    /**
     * Check if task detail is empty
     * @return {boolean}
     */
    isDetailEmpty() {
      return isEmpty(this.task.detail);
    },

    /**
     * set task favorite
     * @return {void}
     */
     async setFavorite(item) {
      try {
        item.is_favorite = !item.is_favorite;
        await axios.put(
          UPDATE_DB_TASKS_API(item.user_id, item.standard_task_id),
          {
            is_favorite: +item.is_favorite,
          }
        );

        this.$emit("setFavorite", item);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },

    /**
     * Check max length
     * @return {boolean}
     */
    checkMaxLength(type, $event) {
      if ($event.keyCode === 8 || $event.keyCode === 46) return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    /**
     * Handle update cost event
     * @return {void}
     */
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    /**
     * Handle update count event
     * @return {void}
     */
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    /**
     * Handle change count event
     * @return {void}
     */
    changeCount() {
      if (!this.$v.task.count.$invalid && !this.$v.task.start_time.$invalid) {
        this.calculateTime();
      }
    },

    /**
     * Handle change cost event
     * @return {void}
     */
    changeCost() {
      if (!this.$v.task.cost.$invalid) {
        this.calculateTime();
      }
    },

    /**
     * Handle change start time event
     * @return {void}
     */
    changeStartTime() {
      if (!this.$v.task.count.$invalid && !this.$v.task.start_time.$invalid) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    /**
     * Format start time
     * @param {object} [value]
     * @return {void}
     */
    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    /**
     * Check if monthlyTask
     * @return {boolean}
     */
    isMonthlyTask() {
      return this.task.schedule_month_task_id ? true : false;
    },

    /**
     * Check if changeCategory
     * @return {boolean}
     */
    isChangeCategory() {
      if (this.task.is_changeability != null){
        return this.task.is_changeability ? true : false;
      }
      return true;
    },

    /**
     * Check if suppervisor
     * @return {boolean}
     */
    isSupervisor() {
      return this.$root.$refs.scheduleComponent.isSupervisor();
    },

    /**
     * Handle delete task event
     * @return {void}
     */
    async deleteTask() {
      this.$confirm(
        this.task.is_split_task
          ? `${this.$t(
              "label.confirm.delete_task"
            )} \r\nこのタスクは中断となり、生産性が評価できません。`
          : this.$t("label.confirm.delete_task"),
        {
          width: "600px",
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        }
      )
        .then(async () => {
          try {
            await axios.delete(
              GET_SCHEDULE_TASK_API(this.planId, this.standarId)
            );
            this.openDbTask = false;
            this.$emit("deleteDbTask", {
              planId: this.planId,
              standarId: this.standarId,
              changeTodoList: this.originSplitCheck,
            });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC803, status);
          }
        })
        .catch(() => {});
    },

    /**
     * Handle archive task event
     * @return {void}
     */
    archiveTask: debounce_leading(async function () {
      if (!this.task.is_split_task) {
        try {
          let params = {
            name: this.originalTask.name,
            description: this.originalTask.description,
            cost: this.originalTask.cost,
            count: this.originalTask.count,
            category_secondary_id: this.originalTask.category_secondary_id,
            is_need_output: this.originalTask.is_need_output,
            output_detail: this.originalTask.output_detail,
            is_favorite: this.originalTask.is_favorite,
            //tags: this.originalTask.tags,
            comments: this.originalTask.comments,
            schedule_task_id: this.originalTask.id,
            detail: this.originalTask.detail,
            schedule_month_task_id: this.originalTask.schedule_month_task_id,
          };
          const { data } = await axios.post(TODO_TASK_API(), {tasks: [params]});
          this.openDbTask = false;
          this.originalTask.todoTaskId = data.result.id[0];
          this.originalTask.is_split_task = this.task.is_split_task ? 1 : 0;
          this.$emit("archiveDbTask", this.originalTask);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC808, status);
        }
      } else {
        try {
          let params = {
            schedule_task_id: this.originalTask.id,
          };
          const { data } = await axios.post(SPLIT_TASK_API(), params);
          this.openDbTask = false;
          this.originalTask.todoTaskId = data.result.id;
          this.$emit("archiveDbSplitTask", this.originalTask);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC824, status);
        }
      }
    }),

    /**
     * Handle dupplicate task
     * @return {void}
     */
    async duplicateTask() {
      if (this.isEditing) {
        await this.updateTask();
      }
      this.openDbTask = false;
      this.isDivided = false;
      this.checkDivided = false;
      this.$emit("duplicateTask", this.task);
    },

    /**
     * Handle close popup event
     * @return {void}
     */
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openDbTask = false;
            this.isDivided = false;
            this.checkDivided = false;
          }, 100);
        },
        () => {}
      );
    },

    /**
     * Handle copy link event
     * @return {void}
     */
    copyLink() {
      copyToClipboard(
        window.location.origin +
          `/schedule/${this.planId}?taskId=${this.task.id}`,
        this.parentId
      );
      this.$toast(this.$t("label.toast.copied"), 3000);
    },

    /**
     * Get comment date
     * @return {string}
     */
    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    /**
     * Check if is owner
     * @return {boolean}
     */
    isOwner(item) {
      return item.user_id === this.loginInfo?.user_id;
    },

    /**
     * Check if is before twelve
     * @return {boolean}
     */
    isBeforeTwelve() {
      return this.$root.$refs.scheduleComponent.isBeforeTwelve();
    },

    /**
     * Check if can submit
     * @return {boolean}
     */
    isCanSubmit() {
      return this.$root.$refs.scheduleComponent.isCanSubmit();
    },

    /**
    * Handle change primary category event
    * @param {object} primaryCategory
    * @return {void}
    */
    primaryCategoryChange(primaryCategory) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === primaryCategory.id
      )?.secondaries;

      if (!isEmptyNumber(primaryCategory.id)) {
        this.parentCategories = this.parentCategories.filter(
          (x) => !isEmptyNumber(x.id)
        );
      }
      this.task.category_primary_id = primaryCategory.id;
      this.task.category_secondary_id = null;
      this.task.category_secondary_name  = null;
    },

    /**
    * Handle change secondary category event
    * @param {object} secondCategory
    * @return {void}
    */
    secondaryCategoryChange(secondCategory) {
      this.task.category_secondary_id = secondCategory.id;
      this.task.category_secondary_name = secondCategory.name;
      this.isPulldownopen = false;
      this.clearFilteredKey();
    },

    /**
     * Handle delete comment event
     * @param {object}
     * @return {void}
     */
    deleteComment(item) {
      if (!this.isOwner(item)) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            this.originalTask.comments = this.originalTask.comments.filter(
              (x) => x.id !== item.id
            );
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    /**
     * Handle update comment event
     * @return {void}
     */
    updateComment() {
      this.showComment = true;
    },

    /**
     * Handle update task event
     * @return {void}
     */
    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        //logic processing
        try {
          if (this.isSupervisor()) {
            if (!isEmpty(this.userComment) && this.showComment) {
              await axios.put(GET_SCHEDULE_TASK_API(this.planId), {
                schedule_tasks: [
                  {
                    id: this.task.id,
                    comments: [
                      {
                        user_id: this.loginInfo.user_id,
                        comment: this.userComment,
                      },
                    ],
                  },
                ],
              });
              this.openDbTask = false;
            }
          } else {
            const { data } = await axios.put(
              GET_SCHEDULE_TASK_API(this.planId),
              {
                schedule_tasks: [
                  {
                    name: this.task.name,
                    description: this.task.description,
                    detail: this.task.detail,
                    cost: +this.task.cost,
                    count: +this.task.count,
                    is_need_output: +this.task.is_need_output,
                    //total_cost: +this.task.count === 1 && this.task.is_split_task ? this.task.total_cost : this.task.cost,
                    is_split_task:
                      +this.task.count === 1 && this.splitCheck ? 1 : 0,
                    split_task_id:
                      +this.task.count === 1 && this.task.is_split_task
                        ? null
                        : this.task.split_task_id,
                    output_detail:
                      +this.task.is_need_output === 0
                        ? null
                        : this.task.output_detail,
                    category_secondary_id: +this.task.category_secondary_id,
                    is_favorite: +this.task.is_favorite,
                    start_time: (
                      this.task.start_time.replace(":", "") + "00"
                    ).padStart(6, "0"),
                    standard_task_id: this.task.standard_task_id,
                    standard_task_revision: this.task.standard_task_revision,
                    //tags: this.task.tags,
                    comments:
                      !isEmpty(this.userComment) && this.showComment
                        ? [
                            {
                              user_id: this.loginInfo.user_id,
                              comment: this.userComment,
                            },
                          ]
                        : [],
                    id: this.task.id,
                    schedule_month_task_id: this.task.schedule_month_task_id,
                    skill_level_id: this.task.skill_level_id || null,
                  },
                ],
              }
            );
            this.openDbTask = false;

            if (+this.task.count === 1 && this.task.is_split_task) {
              this.task.is_split_task = 1;
              this.task.split_task_id = this.task.id;
            } else {
              this.task.is_split_task = 0;
              this.task.split_task_id = null;
            }
            data.result[0].start_time = formatTime(data.result[0].start_time);
            data.result[0].end_time = formatTime(data.result[0].end_time);
            data.result[0].changeTodoList = this.splitCheck !== this.originSplitCheck;
            this.$emit("editDbTask", data.result[0]);
          }
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC802, status);
        }
        //close modal
        this.openDbTask = false;
      } else {
        this.openDbTask = true;
      }
    }),

    /**
     * Get data from API FC801
     * @return {void}
     */
    async getData() {
      try {
        const { data, status } = await axios.get(
          `${GET_SCHEDULE_TASK_API(this.planId, this.standarId)}`
        );
        if (status === STATUS_NO_CONTENT) {
          this.openDbTask = false;
          this.$alert("タスクが見つかりません。");
        } else {
          this.task = deepClone(data.result[0]);
          this.originalTask = deepClone(data.result[0]);
          this.task.is_split_task == !!data.result[0].is_split_task;
          this.originalTask.is_split_task == !!data.result[0].is_split_task;
          this.task.end_time = formatTime(data.result[0].end_time);
          this.task.start_time = formatTime(data.result[0].start_time);
          this.originalTask.start_time = formatTime(data.result[0].start_time);
          this.originalTask.end_time = formatTime(data.result[0].end_time);

          await this.getCategories();

          if (this.task.split_task_id && this.task.is_last_split_task === 0){
            this.splitCheck = true;
          }
          else if (this.task.split_task_id && this.task.is_last_split_task === 1 && this.task.is_first_split_task === 1){
            this.splitCheck = true;
          }
          else{
            this.splitCheck = false;
          }
          this.originSplitCheck = this.splitCheck;

          if (+this.task.can_split === 1){
            this.canSplit = true;
          }
          else{
            this.canSplit = false;
          }
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC801, status);
      }
    },

    /**
    * get categories data from API FC701
    * @return {void}
    */
    async getCategories() {
      try {
        this.groupId = this.loginInfo.group_id;
        this.userId = this.loginInfo.user_id;
        const { data, status } = await axios.get(CATEGORIES_API(
          this.groupId,
          this.userId
        ));

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.childCategories = this.parentCategories.find(
            (x) => x.id === this.task.category_primary_id
          )?.secondaries;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let comment = this.task.comments.find(
            x => +x.id === +item.comment_id && !!item.can_verified
          );
          if (comment){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: null,
            comment_notice_id: item.comment_notice_id,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    /**
     * Calculate time
     * @return {void}
     */
    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    /**
     * Calculate time for HHmm
     * @return {void}
     */
    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    /**
     * handble update split check event
     * @param {object} event
     * @return {void}
     */
    updateSplitCheck(event) {
      if (!event && this.originalTask.is_first_split_task === 1) {
        this.task.cost = this.task.total_cost;
      }
    },

    /**
     * Handle control icon View
     * @return {boolean}
     */
    showPulldownIcon(pulldownOption) {
      this.isPulldownIcon = pulldownOption;
    },

    hidePulldownIcon(){
      this.isPulldownIcon = "";
    },

    collectPulldownIcon(str1 , str2) {
      if(str1 === str2) return true;
      else return false;
    },

    /**
     * Handle show category pulldown
     * @return {boolean}
     */
    changeShowPopup(){
      this.isPulldownopen = !this.isPulldownopen;
      this.clearFilteredKey();
    },

    leaveSelect(event) {
      const popupContainer = this.$refs.popupContainer;
      
      // popupContainer が存在し、クリックされた要素が popupContainer の外側の場合
      if (popupContainer && !popupContainer.contains(event.target)) {
        this.isPulldownopen = false; // ポップアップを閉じる
        this.clearFilteredKey(); // フィルターをクリア

      }
    },

    /**
     * Handle update edit category name
     * @param {object} event
     * @return {boolean}
     */
    editCategory(event, item) {
      event.stopPropagation();
      this.updateCategoryPrimaryId = this.task.category_primary_id;
      this.updateSecondaryCategoryName = item.name;
      this.updateSecondaryCategoryId = item.id;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openEditCategory = !this.openEditCategory;
    },

    newCategory(categoryPrimaryId) {
      this.newLargeCategoryId = categoryPrimaryId;
      this.userId = this.loginInfo.user_id;
      this.groupId = this.loginInfo.group_id;
      this.openNewCategory = !this.openNewCategory;
    },

    /**
     * Handle delete category event
     * @param {object} event
     * @return {boolean}
     */
    deleteCategory(event, item) {
      event.stopPropagation();

      /** 登録されている小カテゴリの場合 */
      let origincategory = this.originalTask.category_secondary_id
      let selectcategory = this.task.category_secondary_id
      if (origincategory === item.id || selectcategory === item.id) {
        this.notDeleteCategory();
      } else {
        this.$confirm(this.$t("label.confirm.delete_secondary_category"), {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          rtl: true,
        })
          .then(async () => {
            try {
              await axios.delete(
                PRIMARY_SUB_CATEGORY_API(
                  this.task.category_primary_id,
                  item.id,
                  item.user_id
                )
              );

              this.$toast(
                `${item.name}を削除しました。`,
                3000
              );
              this.categorySort()
            } catch (error) {
              const { status } = error.response;
              if (status === 404) {
                this.$alert(error.response.data.message, {
                  width: "820px",
                });
              } else {
                apiErrorMessages(this, FC704, status);
              }
            }
          })
          .catch(() => {});
      }
    },

    notDeleteCategory() {
      this.$alert([this.$t("label.confirm.not_delete_category.line1")])
        .then(() => {})
        .catch(() => {});
    },

    categorySort() {
      this.getCategories();
      this.changeShowPopup();
    },
    
    isStandardTask() {
      return !this.task.split_task_id && this.task.standard_task_id ? true : false;
    },

    isSplitTask() {
      //分割元は編集可能
      return this.task.split_task_no === 1 ? true : false
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },

    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },

    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },

    // display as split task
    displaySplit(){
      // Free or db task
      if (this.originalTask.is_split_task === 0){
        if (this.splitCheck){
          return true;
        }
        return false;
      }

      // First Split task
      if (this.originalTask.is_first_split_task === 1){
        if (this.splitCheck){
          return true;
        }
        return false;
      }
      return true;
    },

    isCanSplit() {
      return this.canSplit;
    },
    canSplitCheck(){
      // タスクが実装した場合、分割できない
      if (+this.task.is_completed === 1){
        return false;
      }

      // 一部実装した場合、分割できない
      if (this.task.total_actual_cost > 0 && +this.task.is_last_split_task !== 1){
        return false;
      }

      // 最後のタスクではない
      if (!this.task.can_split && +this.task.is_split_task === 1){
        return false;
      }
      return true;
    },
  },

  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
    // ポップアップ外をクリックした際にポップアップを閉じるイベント
    window.addEventListener("click", this.leaveSelect);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.leaveSelect);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.detailText {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.w-120 {
  width: 100px;
}

.w-80 {
  width: 80px;
}

.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-90 {
  width: 90px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.work-break {
  white-space: pre-wrap;
}

.category-pulldown {
  z-index:999;
}

.category-add{
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 15%;
  font-size : 15px;
}
.category-add:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.add-small-category{
  height: 48px;
  line-height: 48px;
  position: relative;
  color: gray;
  font-size : 15px;
}
.add-small-category:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.pulldown-right-element {
  position: absolute;
  right: 5%;
}
.transform {
  text-transform: none;
}

</style>
