<template>
  <v-dialog persistent :value="openDialog" max-width="500px">
    <v-card class="py-3">
      <v-card-title>実績を保存しますか？ </v-card-title>
      <v-card-actions class="justify-end">
        <v-btn tile color="ligth darken-1" @click="close()">
          {{ $t("label.buttons.cancel") }}
        </v-btn>
        <v-btn tile color="primary darken-1" @click="submit()">
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  data() {
    return {
      openDialog: true,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    }
  },
};
</script>