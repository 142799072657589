<template>
  <v-dialog
    scrollable
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="dialog = false" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" cols="3">業務カテゴリ</v-col>
          <v-col class="text-left py-0" cols="9">
            <v-autocomplete
              v-model="condition.primary_id"
              item-text="name"
              item-value="id"
              :items="parentCategories"
              @change="changePrimaryCategory($event)"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col v-if="condition.primary_id" class="ml-auto py-0" cols="9">
            <v-autocomplete
              v-model="condition.secondary_id"
              item-text="name"
              item-value="id"
              :items="childCategories"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">タスク名</v-col>
          <v-col class="text-left" md="9">
            <v-text-field v-model="condition.name" maxlength="100" clearable>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">{{ labelType }}</v-col>
          <v-col class="text-left" md="9">
            <v-select
              no-data-text="空欄"
              :items="taskTypeItems"
              v-model="condition.task_type_id"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">定常/非定常</v-col>
          <v-col class="text-left" md="9">
            <v-select
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="taskUsual"
              v-model="condition.task_usual"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" cols="3">工数</v-col>
          <v-col class="text-left" cols="3">
            <v-text-field
              v-model="condition.cost_start"
              min="1"
              max="999"
              @keydown="checkMaxLength('cost_start', $event)"
              @input.native="updateCostStart"
              :error-messages="costStartErrors"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" class="text-center">~</v-col>
          <v-col class="text-left" cols="3">
            <v-text-field
              v-model="condition.cost_end"
              min="1"
              max="999"
              @keydown="checkMaxLength('cost_end', $event)"
              @input.native="updateCostEnd"
              :error-messages="costEndErrors"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">m</v-col>
        </v-row>
        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">実施頻度</v-col>
          <v-col class="text-left" md="9">
            <v-select
              no-data-text="空欄"
              :items="taskTiming"
              v-model="condition.task_timing_id"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="form-item" align="baseline" justify="center">
          <v-col class="text-left" cols="3">年次依存</v-col>
          <v-col class="text-left" cols="9">
            <v-select
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="dependents"
              v-model="condition.is_target_skill_level"
              clearable
            >
            </v-select>
            <div class="d-flex" v-if="condition.is_target_skill_level === 1">
              <v-checkbox
                label="ビギナー設定あり"
                v-model="condition.is_target_beginner"
                @change="reloadTarget"
              >
              </v-checkbox>
              <v-checkbox class="ml-3"
                label="エキスパート設定あり"
                v-model="condition.is_target_expert"
                @change="reloadTarget"
              >
              </v-checkbox>
            </div>
          </v-col>
        </v-row>
        <!--<v-row
          v-if="isMaster"
          class="form-item"
          align="center"
          justify="center"
        >
          <v-col class="text-left" cols="3">ステータス</v-col>
          <v-col class="text-left" cols="9">
            <v-select
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="statusList"
              v-model="condition.is_visible"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>-->
      </v-card-text>
      <v-card-actions class="ml-auto">
        <v-btn depressed color="primary" @click="submit()">検索</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as systemAuth from "../../../constants/role_code";
import { CATEGORIES_API } from "../../../constants/api";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC701 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { mapGetters } from "vuex";
import { /*deepClone,*/ isEmpty } from "../../../helpers/helpers";
import { validationMixin } from "vuelidate";
import { taskMixin } from '@/mixins/task.js';
import { requiredIf } from "vuelidate/lib/validators";
import { fromValidator, toValidator } from "../../../helpers/validator";
export default {
  props: {
    show: Boolean,
    taskTypeItems : Array,
    taskTiming : Array,
  },
  mixins: [validationMixin, taskMixin],
  validations: {
    condition: {
      cost_start: {
        required: requiredIf((condition) => {
          let required = !isEmpty(condition.cost_end);
          return required;
        }),
        compare: toValidator("cost_end"),
      },
      cost_end: {
        required: requiredIf((condition) => {
          let required = !isEmpty(condition.cost_start);
          return required;
        }),
        compare: fromValidator("cost_start"),
      },
    },
  },
  data() {
    return {
      dialog: false,
      condition: {},
      parentCategories: [],
      childCategories: [],
      taskUsual: [
        { id: 1, name: "定常" },
        { id: 0, name: "非定常" },
      ],
      outputs: [
        { id: 1, name: "要" },
        { id: 0, name: "不要" },
      ],
      dependents: [
        { id: 1, name: "対象" },
        { id: 0, name: "非対象" },
      ],
    };
  },
  watch: {
    dialog: {
      async handler() {
        // this.dialog = true;
        this.condition = {
          cost_start: "",
          cost_end: "",
          is_target_beginner: true,
          is_target_expert: true,
          is_target_skill_level: null,
        };
        await this.getCategories();
        this.$v.$reset();
      },
    },
    condition: {
      deep: true,
      handler() {
        
      },
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    isMaster() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MASTER;
    },
    costStartErrors() {
      const errors = [];
      if (this.$v.condition.cost_start.$dirty) {
        !this.$v.condition.cost_start.required &&
          errors.push(this.$t("label.error.required"));
      }
      return errors;
    },
    costEndErrors() {
      const errors = [];
      if (this.$v.condition.cost_end.$dirty) {
        !this.$v.condition.cost_end.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.condition.cost_end.compare &&
          errors.push("開始値より大きい数字を入力してください");
      }
      return errors;
    },
  },
  methods: {
    showDialog(value) {
      this.dialog = value;
    },
    reloadTarget(){
      if (
          !this.condition.is_target_beginner &&
          !this.condition.is_target_expert
        )
          this.condition.is_target_skill_level = 0;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        this.dialog = false;
        this.$emit("search", this.condition);
      }
    },

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());
        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    changePrimaryCategory(value) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === value
      )?.secondaries;
      if (!this.childCategories) {
        this.condition.secondary_id = null;
      }
    },
    updateCostStart(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost_start = value;
      } else {
        this.task.cost_start = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCostEnd(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost_end = value;
      } else {
        this.task.cost_end = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.w-180 {
  width: 208px;
}
.w-100 {
  width: 100px;
}
::v-deep .v-input__slot .v-input--selection-controls__input {
  margin-right: 5px;
}
</style>

